import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };


class SendWhatsAppMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Voter Name : {this.props.stateData.selectedVoterObj.voter_Name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Row>
                        <Col>
                            {
                                this.props.stateData.mobileNoValue == "" ?
                                (<h6 className='f-12 color_red ln-25 fw-bold'>Note : ఓటర్ యొక్క మొబైల్ నెంబర్ తెలుసుకొని - వారి మొబైల్ నెంబర్ ని క్రింది text బాక్స్ నందు ఎంటర్ చేసి  ఓటర్ స్లిప్ పంపించగలరు </h6>)
                                :
                                (<h6 className='f-12 color_red ln-25 fw-bold'>Note : ఈ కింద మొబైల్ నెంబర్ ఓటర్ ది అవునో / కాదో తెలుసుకోండి , ఆ మొబైల్ నెంబర్ వారిది కానీ ఎడల - వారి నెంబర్ తెలుసుకొని - వారి మొబైల్ నెంబర్ ని క్రింది text బాక్స్ నందు ఎంటర్ చేసి  ఓటర్ స్లిప్ పంపించగలరు </h6>)
                            }
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-2'>
                        <h6 className="form-label f-12 fw-bold">Mobile No <span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.props.stateData.validateMobileNo}</span></h6>
                        <Form.Control type="text" name="mobileNoValue" defaultValue={this.props.stateData.selectedVoterObj.mobileNo} maxLength={10}  className='form-control-sm'  placeholder="Enter Mobile No" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                      
                      <Row>
                            <Col>
                                <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-3 mb-3' disabled={this.props.stateData.sendButtonDisabled} onClick={() => this.props.sendVoterSlipViaWhatsAppMessage()}>SEND VOTER SLIP</Button>
                                    {
                                        this.props.stateData.voterSlipLoading == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                    }
                            </Col>
                      </Row>
                      <Row>
                            <Col>
                                <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.voterSlipMessage}</h6>
                            </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default SendWhatsAppMessage;
  

