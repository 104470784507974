
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import "./VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import MF_logo1 from '../../images/MF_logo1.png';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import HouseWiseVoterList from "./HouseWiseVoterList";
import MaxLimitEnrollChecking from './MaxLimitEnrollChecking';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class FamilyVoterSearch extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props.userAccessList,

            constituencyValue:"",
            constituencyId:"",
            constituencyListArr:[],

            boothValue:"",
            boothListArr:[],
            boothId:"",
            validateboothId:"",
            getBoothsLoadingSymbol:"hide",

            boothWiseHouseListForFVArr:[],
            boothWiseHouseListCheckForFV:0,
            boothWiseHouseListLoaderForFV:"hide",

            houseWiseVotersDisplayModal:false,

            houseWiseVoterListForFVArr:[],
            houseWiseVoterListArrForFVLoader:"hide",
            houseWiseVoterListArrForFVCheck:0,

            houseNoValue:"",

            checkingEnrollsSymbol:"hide",
            checkingEnrollMessage:"",
            
            maxEnrollModal:false,

            enrollCheckVal:"N",

            checkOTPImageValidate:"mobile"
 
            
        }
    }

    componentDidMount() {
        console.log("userAccessListdddd",this.state.chatID)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
                
                
            }
           
            this.setState({ constituencyListArr:consArr });
            this.setState({
                constituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },constituencyId:this.state.userAccessList.constituencyList[0].id
        });
           // this.getBoothsByConstituencyId(this.state.userAccessList.constituencyList[0].id);
            if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
                let arr1=[];
                this.state.userAccessList.boothList.map(item => {
                    const obj = {
                        value: item.id,
                        label: "Booth No -"+item.name,
                    }
                    arr1.push(obj);
                    
                });
                this.setState({ boothListArr: arr1 });
                
                
            }
    
        }
    }

   

    handleOnchangeSelectBoxForFamily = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "constituencyId"){
            let getConsIdName = this.state.constituencyListArr.find((a) => a.value == value);
                this.setState({
                    constituencyValue: {
                    value: getConsIdName.value,
                    label: getConsIdName.label
                }
            });
            //this.getBoothsByConstituencyId(value);
        }else if(name == "boothId"){
            let getBoothIdName = this.state.boothListArr.find((a) => a.value == value);
            this.setState({
                boothValue: {
                value: getBoothIdName.value,
                label: getBoothIdName.label
            }
        });

            this.checkBoothForCondition(value);
           
        }
          
    
        this.setState({ [name]: value});
      }

      checkBoothForCondition = (value) => {
             const obj =  
                JSON.stringify({ 
                    "chatId" :this.state.chatID,
                    "boothId" : value,
                    "conditionName" : "BG_NO_OTP"
                });
            getAPICallData(obj,"checkBoothForCondition")
                .then((res) => res.json())
                .then((content) => {
                   if(content !=null){
                    if(content.name == 'Y'){
                        this.setState({checkOTPImageValidate:content.name})
                    }else{
                        this.setState({checkOTPImageValidate:"mobile"})
                    }
                   
                   }else{
                    this.setState({checkOTPImageValidate:"mobile"})
                   }
            });
    }

      
      getBoothsByConstituencyId = (value) => {
    this.setState({ boothListArr: [],getBoothsLoadingSymbol:"show"});

    fetch(`/bot_api/v1/booth?constituency_id=${value}`, {
        method: "GET", 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'user_data':'user_data',
            "test_chat_id" :"5129193582",
        },
      })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ getBoothsLoadingSymbol:"hide"});
          if(json !=null && json.length>0){
            var boothArr=[];
            for(var i in  json){
                var obj={'value':json[i].boothId,'label':"Booth"+json[i].partNo};
                boothArr.push(obj)
                
            }
           
            this.setState({ boothListArr:boothArr });
          }
        
      });
  }

  
  getHouseWiseVoters = () => {
    var houselistArr=[];
    this.setState({validateboothId:""});
    if(this.state.boothId == ""){
        this.setState({validateboothId:"Please Select Booth" });
        return;
    }

    this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:0,boothWiseHouseListLoaderForFV:"show"});
    

    const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "boothId" : this.state.boothId,
                "isVisited" : '' // Y/N/null
            });
        
        

        getAPICallData(obj,"FTRGRT/getHouseDetailsInABooth")
            .then((res) => res.json())
            .then((content) => {
              this.setState({boothWiseHouseListLoaderForFV:"hide"});  
                if(content !=null && content.length>0){
                    if(this.state.enrollCheckVal == ""){
                        houselistArr = content;
                    }else{
                        houselistArr = content.filter((item) => item.isEnrolled == this.state.enrollCheckVal );
                    }
                    if(houselistArr !=null && houselistArr.length>0){
                        this.setState({boothWiseHouseListForFVArr:houselistArr,boothWiseHouseListCheckForFV:0});
                    }else{
                        this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:1});
                    }

                }else{
                    this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:1});
                }
            });
}


houseListDisplayColumnsForFV() {
    let columns = [
        
        {
            dataField: "house_no",
            text: "House No",
            sort: true,
            formatter: this.actionsFormatterForECHouseNoForFV.bind(this),
            headerStyle: {
                width: "15%"
            }
            
            
        },
        {
            dataField: "elder_person",
            text: "Elder Person",
           // sort: true,
            formatter: this.actionsFormatterForECElderPersonForFV.bind(this),
            headerStyle: {
                width: "28%"
            }
            
            
        },
        {
            dataField: "elder_age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECElderAgeForFV.bind(this),
            
        },
       
        {
            dataField: "younger_person",
            text: "Younger Person",
            formatter: this.actionsFormatterForECYoungerPersopnForFV.bind(this),
            headerStyle: {
                width: "28%"
            }
           
            
        },
        {
            dataField: "younger_age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECYoungerAgeForFV.bind(this),
        }
    
    ];
    return columns;
}

actionsFormatterForECElderPersonForFV = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.elder_person)} 
            {/*<><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.elder_age)}</span></>*/}
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.elder_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.elder_age)}</span></>
        </span>
     );
    
}

actionsFormatterForECYoungerPersopnForFV = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.younger_person)} 
           {/* <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.younger_age)}</span></>*/}
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.younger_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.younger_age)}</span></>
        </span>
     );
    
} 

actionsFormatterForECElderAgeForFV = (cell, row, rowIndex, formatExtraData) => {
    return (row.elderVoterCardNo)
}
actionsFormatterForECYoungerAgeForFV = (cell, row, rowIndex, formatExtraData) => {
    return (row.youngerVoterCardNo)
}
actionsFormatterForECHouseNoForFV = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            {
                row.isEnrolled == 'Y' ?
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold color_green text-decoration-underline' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no} <br/> Enrolled</span>)
                :
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold  text-decoration-underline' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no}</span>)
        }
            
        </>
          
     );
}

houseListDisplayDataForFV = () =>{
    let houseData = [];
        if(this.state.boothWiseHouseListForFVArr.length>0){
            
            for (let i = 0; i < this.state.boothWiseHouseListForFVArr.length; i++) {

                const house_no = (this.state.boothWiseHouseListForFVArr[i].houseNo);
                const elder_person = (this.state.boothWiseHouseListForFVArr[i].elderName);
                const younger_person = (this.state.boothWiseHouseListForFVArr[i].youngerName);
                const elder_age = (this.state.boothWiseHouseListForFVArr[i].elderAge);
                const elder_gender = (this.state.boothWiseHouseListForFVArr[i].elderGender);
                const younger_age = (this.state.boothWiseHouseListForFVArr[i].youngerAge);
                const younger_gender = (this.state.boothWiseHouseListForFVArr[i].youngerGender);
                const house_id = (this.state.boothWiseHouseListForFVArr[i].boothHouseId);
                const booth_house_id = (this.state.boothWiseHouseListForFVArr[i].boothHouseId);
                const elderVoterCardNo = (this.state.boothWiseHouseListForFVArr[i].elderVoterCardNo);
                const youngerVoterCardNo = (this.state.boothWiseHouseListForFVArr[i].youngerVoterCardNo);
                const isEnrolled = (this.state.boothWiseHouseListForFVArr[i].isEnrolled);
                
                const obj = {
                    house_no:(house_no),
                    elder_person:elder_person,
                    younger_person: younger_person,
                    elder_age: elderVoterCardNo,
                    elder_gender: elder_gender,
                    younger_age:youngerVoterCardNo,
                    younger_gender:younger_gender,
                    house_id:house_id,
                    booth_house_id:booth_house_id,
                    elderVoterCardNo:elder_age,
                    youngerVoterCardNo:younger_age,
                    isEnrolled:isEnrolled
                    
                }
                houseData.push(obj);
            } 

            
            
            return houseData;
        }
}


closeLimitModal = () => {
    this.setState({maxEnrollModal:false});
}

getEnrollmentsCountByUserByDate = (data) => {

    this.enrollUserViewDtClickForFV(data);
       
    /*this.setState({checkingEnrollsSymbol:"show",checkingEnrollMessage:""});
    const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
            });

        getAPICallData(obj,"FTRGRT/getEnrollmentsCountByUserByDate")
            .then((res) => res.json())
            .then((content) => {
                this.setState({checkingEnrollsSymbol:"hide"});
                if(content !=null && content.id > 39){
                    this.setState({maxEnrollModal:true});
                    this.setState({checkingEnrollMessage:"గమనిక !! ప్రతి రోజు భవిష్యతుకు గ్యారంటీ కార్యక్రమం లో గరిష్టంగా 40 కుటంబాలను మాత్రమే నమోదు చేయగలము. మీరు భవిష్యతుకు గ్యారంటీ కార్యక్రమాన్ని రేపు కొనసాగించి ఈ కార్యక్రమాన్ని విజయవంతం చేయగలరు."
                    });
                }else{
                    this.setState({checkingEnrollMessage:""});
                    this.enrollUserViewDtClickForFV(data);
                }
            });*/
}


getVotersInAHouseForFV = (houseId,house_no) => {
    var houseWiseDataArr=[];
    this.setState({checkingEnrollMessage:""});
    this.setState({houseWiseVotersDisplayModal:true,houseNoValue:house_no});
    this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVLoader:"show",houseWiseVoterListArrForFVCheck:0});
    const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "boothHouseId" : houseId,
        });
        getAPICallData(obj,"FTRGRT/getVotersInAHouse")
            .then((res) => res.json())
            .then((content) => {
                this.setState({houseWiseVoterListArrForFVLoader:"hide"});
                if(content !=null && content.length>0){
                   /* console.log(this.state.enrollCheckVal)
                    if(this.state.enrollCheckVal == ""){
                        houseWiseDataArr = content;
                    }else{
                        houseWiseDataArr = content.filter((item) => item.isEnrolled == this.state.enrollCheckVal );
                    }
                    if(houseWiseDataArr !=null && houseWiseDataArr.length>0){
                        this.setState({houseWiseVoterListForFVArr:houseWiseDataArr,houseWiseVoterListArrForFVCheck:0});
                    }else{
                        this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVCheck:1});
                    }*/
                    this.setState({houseWiseVoterListForFVArr:content,houseWiseVoterListArrForFVCheck:0});

                }else{
                    this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVCheck:1});
                }
        });
}
    
enrollUserViewDtClickForFV = (data) => {
   // setTimeout(() => {   
        this.props.history.push({
            pathname: "/voter_search_enroll_save",
            params: {
                navbarDisplay:"hide",
                userAccessList:this.props?.userAccessList == undefined ? "" : this.props?.userAccessList,
                volunteerDts:"",
                volunteerName:data[0].voterName,
                volunteerGender:data[0].gender,
                volunteerAge:data[0].age, 
                volunteerVoterCard:data[0].voterCardNo,
                ap_listArr:[{'value':this.state.constituencyValue.value,'label':this.state.constituencyValue.label}],
                volunteerConstituencyName:this.state.constituencyValue.value,
                SelfconstituencyValue:{'value':this.state.constituencyValue.value,'label':this.state.constituencyValue.label},
                save_booth_id:this.state.boothId,
                save_voter_id:data[0].voterId,
                checkOTPImageValidate:this.state.checkOTPImageValidate
              },
          });
      // }, 1000);
    
}

checkEnrolled = (e,type) => {
    this.setState({enrollCheckVal:type});
}

    render() {
        return (
               <Container >
                    <Row>
                        <Col className='mt-3'>
                        <label className="form-label f-12 fw-bold">Constituency<span className='color_red f-14 ms-1'>*</span></label>
                        <SelectBox
                                stateData={this.state.constituencyValue}
                                handleChange={this.handleOnchangeSelectBoxForFamily}
                                name="constituencyId"
                                options={this.state.constituencyListArr}
                                placeholderName={'Constituency'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                    <Col className='mt-3'>
                        <label className="form-label f-12 fw-bold">Booths<span className='color_red f-14 ms-1'>*</span><span className='f-12 color_red'>{this.state.validateboothId}</span>
                            {
                                this.state.getBoothsLoadingSymbol == "show" ?
                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                :
                                ("")
                            }
                            
                        </label>
                        <SelectBox
                                stateData={this.state.boothValue}
                                handleChange={this.handleOnchangeSelectBoxForFamily}
                                name="boothId"
                                options={this.state.boothListArr}
                                placeholderName={'Booth'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Form>
                        <div className='mt-3 text-center'>
                        <Form.Check
                            inline
                            label={`All`}
                            name="enrolledName"
                            type="radio"
                            id={`allenrolledId`}
                            onChange={(e)=>this.checkEnrolled(e,"")}
                            className="f-14 fw-bold color_black"
                            value=""
                        />
                        <Form.Check
                            inline
                            label={`Enrolled`}
                            name="enrolledName"
                            type="radio"
                            id={`enrolledId`}
                            onChange={(e)=>this.checkEnrolled(e,"Y")}
                            className="f-14 fw-bold color_black"
                            value="Y"
                        />
                        <Form.Check
                            inline
                            label={`Not Enrolled`}
                            name="enrolledName"
                            type="radio"
                            id={`notEnrolledId`}
                            onChange={(e)=>this.checkEnrolled(e,"N")}
                            className="f-14 fw-bold color_black"
                            value="N"
                            defaultChecked={true}
                        />
                        </div>
                    </Form>   
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getHouseWiseVoters()}>GET HOUSE DETAILS</Button> 
                    </Col>
                </Row>

                {
                    this.state.boothWiseHouseListLoaderForFV == "show" ?
                    (
                        <Row>
                            <Col sm={12}>
                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                            </Col>
                        </Row>
                    )
                    :
                    ("")
                }
                {
                    this.state.boothWiseHouseListForFVArr.length>0 ?
                    (
                        
                        <>
                        <Row>
                            <Col sm={12}>
                            <h6 className="f-14 fw-bold mt-4">{this.state.boothValue.label} Voter Details : </h6>
                            
                            <div className='bg_light_blue mt-3'>
                            <CardLessDataTableWithSearch 
                                parentData={this.houseListDisplayDataForFV()}
                                particlecolumns={this.houseListDisplayColumnsForFV()}
                                defaultSorted={[{
                                    dataField: "house_no",
                                    order: "asc"
                                }]}
                                tableHeading=""
                                />
                                
                                    
                            </div>
                            
                            </Col>
                        </Row>
                        </>
                    )
                    :
                    (<>
                        {
                        this.state.boothWiseHouseListCheckForFV == 1 ?
                        (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                        :
                        ("")
                    }
                    </>)
                }

                <HouseWiseVoterList 
                        show={this.state.houseWiseVotersDisplayModal}
                        onHide={() => this.setState({ houseWiseVotersDisplayModal: false })}
                        stateData={this.state}
                        getEnrollmentsCountByUserByDate={this.getEnrollmentsCountByUserByDate}
                />
                <MaxLimitEnrollChecking 
                        show={this.state.maxEnrollModal}
                        stateData={this.state}
                        closeLimitModal={this.closeLimitModal}
                        modalViewType=""
                />
                
               </Container>
               
        )
    }
  }
  
  export default withRouter(FamilyVoterSearch);
  