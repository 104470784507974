import React, { useState, useEffect } from "react";
import { getAPICallData } from '../../services/MemberService';
import "../BoothAndHouseMapping/BoothAndHouseMapping.css"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { useHistory } from "react-router-dom";


const HouseVisitedComponentData = withScriptjs(
  withGoogleMap(props => {


    let history = useHistory();
    const [selectedPark, setSelectedPark] = useState(null);
    const [users, setUsers] = useState([]);
    const [defaultLat, setDefaultLat] = useState(0);
    const [defaultLang, setDefaultLang] = useState(0); 
    const [votersList, setvotersList] = useState([]); 

    useEffect(() => {
      if(props.distanceCalLatLngDataArr !=null && props.distanceCalLatLngDataArr.length>0){
        setUsers(props.distanceCalLatLngDataArr);
        if(props.userLatitudeValue == 0 && props.userLongitudeValue == 0){
          setDefaultLat(parseFloat(props.distanceCalLatLngDataArr[0].latitude));
          setDefaultLang(parseFloat(props.distanceCalLatLngDataArr[0].longitude));
        }else{
          setDefaultLat(parseFloat(props.userLatitudeValue));
          setDefaultLang(parseFloat(props.userLongitudeValue));
        }
       
      }else{
        setUsers([]);
         history.push("/dashboard");
      }
    }, []);


      useEffect(() => {
        const listener = (e) => {
          if (e.key === "Escape") {
            setSelectedPark(null);
          }
        };
        window.addEventListener("keydown", listener);

        return () => {
          window.removeEventListener("keydown", listener);
        };
      }, []);

      const getVotersList = (boothHouseId) => {
        const votersObj =  
              JSON.stringify({ 
                "username" : "bsa_api",
                "password" : "9D#Jcx!i5B$z@ym4d7K2f",
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "boothHouseId" : boothHouseId
              }
              ); 
    
              getAPICallData(votersObj,"getVotersByBoothHouseId")
              .then((res) => res.json())
              .then((data) => {
                if(data !=null && data.status == "Success"){
                  if(data.votersList !=null && data.votersList.length>0){
                    setvotersList(data.votersList);
                  }else{
                    setvotersList([]);
                  }
                }else{
                  setvotersList([]);
                }
                
                
              });
      }
     
      const google = window.google;
      let mapOptions = {
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_CENTER,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT,
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: true,
      };
      

    if((users !=null && users.length>0) && (defaultLat !=0 && defaultLang !=0)){
      console.log("props.distanceCalLatLngDataArr",users)
      return (
        <GoogleMap
              defaultZoom={15} 
              defaultCenter={{ lat: defaultLat, lng: defaultLang}}
              options={mapOptions}

            >
              <Marker
                  icon={{
                    path: 'M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
                    fillColor: "blue",
                    fillOpacity: 0.6,
                    strokeWeight: 0,
                    rotation: 0,
                    scale: 2,
                    anchor: new google.maps.Point(0, 20),

                    }}
                  position={{
                    lat: parseFloat(props.userLatitudeValue),
                    lng: parseFloat(props.userLongitudeValue)
                  }}
                 
                />

              {users.map((park,index) => (
                <Marker
                  position={{
                    lat: parseFloat(park.latitude),
                    lng: parseFloat(park.longitude)
                  }}
                  onClick={() => {
                    setSelectedPark(park);getVotersList(park.houseId);
                  }}
                  
                
                />
              ))}
        
              {selectedPark && (
                <InfoWindow
                  onCloseClick={() => {
                    setSelectedPark(null);
                  }}
                  position={{
                    lat: parseFloat(selectedPark.latitude),
                    lng: parseFloat(selectedPark.longitude)
                  }}
    
                  
                >
                  <div>
                    <h6 className="f-14">House No : {selectedPark.houseNo}</h6> 
                    <h6 className="f-14">Street : {selectedPark.street}</h6> 
                    <h6 className="f-14">Voters in house:</h6>
                    <table className='table table_custom mb-2 table-bordered'>
                        <thead>
                            <tr>
                              <th>Name</th>
                              <th>Gender</th>
                              <th>Age</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                              votersList.map((item,index) => {
                              return(
                                
                                <tr>
                                  <td >{item.voterName} </td>
                                  <td>{item.gender}</td>
                                  <td>{item.age}</td>
                                  
                                </tr>
                              )
                              
                            })
                          }
                          
                        </tbody>
                    </table>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
      );
    }
    
  })
);

export default HouseVisitedComponentData;
