
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,InputGroup,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import MF_logo1 from '../../images/MF_logo1.png';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';
import EnabledLocationPopup from "../Volunteer/EnabledLocationPopup"
import BSBGHouseWiseVoterListNew from "./BSBGHouseWiseVoterListNew";
import QuestionsSavingPart from "./QuestionsSavingPart";
import jwt_decode from "jwt-decode";
import { scroller } from "react-scroll";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

  function haversineNew(lat1, lon1, lat2, lon2) {
    // Convert latitude and longitude from degrees to radians
    const degToRad = (angle) => angle * (Math.PI / 180);
    const R = 6371000; // Radius of Earth in meters

    const dLat = degToRad(lat2 - lat1);
    const dLon = degToRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in meters

    return distance;
  }

class ExitPollFamilyVoterSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            webcamRef : React.createRef(),
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props.userAccessList,
            campaignId:props.campaignId,
            
            constituencyValue:"",
            constituencyId:"",
            constituencyListArr:[],

            boothValue:"",
            boothListArr:[],
            boothId:"",
            validateboothId:"",
            getBoothsLoadingSymbol:"hide",

            boothWiseHouseListForFVArr:[],
            boothWiseHouseListCheckForFV:0,
            boothWiseHouseListLoaderForFV:"hide",

            houseWiseVotersDisplayModal:false,

            houseWiseVoterListForFVArr:[],
            houseWiseVoterListArrForFVLoader:"hide",
            houseWiseVoterListArrForFVCheck:0,

            houseNoValue:"",
            houseNoIdStr:"",

            checkingEnrollsSymbol:"hide",
            checkingEnrollMessage:"",
            
            maxEnrollModal:false,

            enrollCheckVal:"N",

            voterCheckedCond:true,
            locationCheckedCond:false,
            distanceValue:"100000",
            fetchHouseValue:"0",
            validateLocations:"",
            userLatitude:0,
            userLongitude:0,
            tableHeadingName:"Booth Wise Nearest All House In 1000 Meters",
            houseLocationArr:[],
            houseLocationDataCheck:0,
            houseLocationLoading:"hide",

            getCurrentLocationCoordinatesObj:{},
            checkingEnabledLocationModal:false,
            delaylocationModal:false,

            houseVisitedImageCaptureModal:false,
            
            captureMobileNo:"",
            validateCaptureMobileNo:"",
            validateCapturePhoto:"",

            
            savaCampaignLoadingSymbol:"hide",
            savaCampaignLoadingSuccessMsg:"",

            
            //checkingLocationServiceEnabledObj:props?.location?.params?.getlocationCoordinates == undefined ? {} : props?.location?.params?.getlocationCoordinates,
            getHouseWiseLatitudeLongitudeObj:{},
            useHouseWiseCurrentLocationSymbol:"hide",
            useLocationEnabledMessage:"",
            
            selectedHouseVisitVoterId:{},

            photoCaptureArr:[],
            showImgCapture:true,
            imageAddedMsg:"",

            locationEnrolledFilterValue:"N",

            searchNameHouseLocationValue:"",

            captureOTPNO:"",
            validateCaptureOTP:"",

            mobileNoDisabled:false,

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",
            
            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",

            minutes:1,
            seconds:0,

            validateCaptureMobileNoInAbove:"",
            validateCaptureOTPInAbove:"",

            checkOTPImageValidate:"Y",
            submitButtonDisabled:false,
            OTPVerifiedDisabledButton:true,

            campaignSurveyQuestionnaireArr:null,
            answerDateTemplateArr:[],
            checkValidationsArr:[],
            checkQuestionsMessage:""
        }
    }

    componentDidMount() {
        console.log("userAccessListdddd",this.state.chatID)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
                
                
            }
           
            this.setState({ constituencyListArr:consArr });
            this.setState({
                constituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },constituencyId:this.state.userAccessList.constituencyList[0].id
        });
           // this.getBoothsByConstituencyId(this.state.userAccessList.constituencyList[0].id);
            if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
                let arr1=[];
                this.state.userAccessList.boothList.map(item => {
                    const obj = {
                        value: item.id,
                        label: "Booth No -"+item.name,
                    }
                    arr1.push(obj);
                    
                });
                this.setState({ boothListArr: arr1 });
                
                
            }

            this.getCampaignSurveyQuestionnaire();
    
        }
    }

    getCampaignSurveyQuestionnaire = () => {
        const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "campaignId" : this.state.campaignId,
        });
    
    

    getAPICallData(obj,"CampaignEnrollment/getCampaignSurveyQuestionnaire")
        .then((res) => res.json())
        .then((content) => {
            if(content !=null && content.length>0){
                this.setState({campaignSurveyQuestionnaireArr:content});
            }else{
                this.setState({campaignSurveyQuestionnaireArr:[]});
            }
        });
    }
   

    handleOnchangeSelectBoxForFamily = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "constituencyId"){
            let getConsIdName = this.state.constituencyListArr.find((a) => a.value == value);
                this.setState({
                    constituencyValue: {
                    value: getConsIdName.value,
                    label: getConsIdName.label
                }
            });
            //this.getBoothsByConstituencyId(value);
        }else if(name == "boothId"){
            let getBoothIdName = this.state.boothListArr.find((a) => a.value == value);
            this.setState({
                boothValue: {
                value: getBoothIdName.value,
                label: getBoothIdName.label
            }
        });
        //this.checkBoothForCondition(value);
        }
          
    
        this.setState({ [name]: value});
      }

      checkBoothForCondition = (value) => {
        const obj =  
           JSON.stringify({ 
               "chatId" :this.state.chatID,
               "boothId" : value,
               "conditionName" : "BG_NO_OTP"
           });
       getAPICallData(obj,"checkBoothForCondition")
           .then((res) => res.json())
           .then((content) => {
              if(content !=null){
               if(content.name == 'Y'){
                   this.setState({checkOTPImageValidate:content.name})
               }else{
                   this.setState({checkOTPImageValidate:"mobile"})
               }
              
              }else{
               this.setState({checkOTPImageValidate:"mobile"})
              }
       });
    }

      getBoothsByConstituencyId = (value) => {
    this.setState({ boothListArr: [],getBoothsLoadingSymbol:"show"});

    fetch(`/bot_api/v1/booth?constituency_id=${value}`, {
        method: "GET", 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'user_data':'user_data',
            "test_chat_id" :"5129193582",
        },
      })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ getBoothsLoadingSymbol:"hide"});
          if(json !=null && json.length>0){
            var boothArr=[];
            for(var i in  json){
                var obj={'value':json[i].boothId,'label':"Booth"+json[i].partNo};
                boothArr.push(obj)
                
            }
           
            this.setState({ boothListArr:boothArr });
          }
        
      });
  }

  
  getHouseWiseVoters = () => {
   
    var houselistArr=[];
    this.setState({validateboothId:""});
    if(this.state.boothId == ""){
        this.setState({validateboothId:"Please Select Booth" });
        return;
    }

    this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:0,boothWiseHouseListLoaderForFV:"show"});
    

    const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "boothId" : this.state.boothId,
                "campaignId" : this.state.campaignId,
            });
        
        

        getAPICallData(obj,"CampaignEnrollment/getHouseDetailsInABoothByCampaignId")
            .then((res) => res.json())
            .then((content) => {
              this.setState({boothWiseHouseListLoaderForFV:"hide"});  
                if(content !=null && content.length>0){
                    if(this.state.enrollCheckVal == ""){
                        houselistArr = content;
                    }else{
                        houselistArr = content.filter((item) => item.isEnrolled == this.state.enrollCheckVal );
                    }
                    if(houselistArr !=null && houselistArr.length>0){
                        this.setState({boothWiseHouseListForFVArr:houselistArr,boothWiseHouseListCheckForFV:0});
                    }else{
                        this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:1});
                    }

                }else{
                    this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:1});
                }
            });
}


houseListDisplayColumnsForFV() {
    let columns = [
        
        {
            dataField: "house_no",
            text: "House No",
            sort: true,
            formatter: this.actionsFormatterForECHouseNoForFV.bind(this),
            headerStyle: {
                width: "15%"
            }
            
            
        },
        {
            dataField: "elder_person",
            text: "Elder Person",
           // sort: true,
            formatter: this.actionsFormatterForECElderPersonForFV.bind(this),
            headerStyle: {
                width: "28%"
            }
            
            
        },
        {
            dataField: "elder_age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECElderAgeForFV.bind(this),
            
        },
       
        {
            dataField: "younger_person",
            text: "Younger Person",
            formatter: this.actionsFormatterForECYoungerPersopnForFV.bind(this),
            headerStyle: {
                width: "28%"
            }
           
            
        },
        {
            dataField: "younger_age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECYoungerAgeForFV.bind(this),
        }
    
    ];
    return columns;
}

actionsFormatterForECElderPersonForFV = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.elder_person)} 
            {/*<><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.elder_age)}</span></>*/}
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.elder_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.elder_age)}</span></>
        </span>
     );
    
}

actionsFormatterForECYoungerPersopnForFV = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.younger_person)} 
           {/* <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.younger_age)}</span></>*/}
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.younger_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.younger_age)}</span></>
        </span>
     );
    
} 

actionsFormatterForECElderAgeForFV = (cell, row, rowIndex, formatExtraData) => {
    return (row.elderVoterCardNo)
}
actionsFormatterForECYoungerAgeForFV = (cell, row, rowIndex, formatExtraData) => {
    return (row.youngerVoterCardNo)
}
actionsFormatterForECHouseNoForFV = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            {
                row.isEnrolled == 'Y' ?
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold  text-decoration-underline color_green' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no} <br/> Visited </span>)
                :
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold  text-decoration-underline' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no}</span>)
            }
            
            
        </>
          
     );
}

houseListDisplayDataForFV = () =>{
    let houseData = [];
        if(this.state.boothWiseHouseListForFVArr.length>0){
            
            for (let i = 0; i < this.state.boothWiseHouseListForFVArr.length; i++) {

                const house_no = (this.state.boothWiseHouseListForFVArr[i].houseNo);
                const elder_person = (this.state.boothWiseHouseListForFVArr[i].elderName);
                const younger_person = (this.state.boothWiseHouseListForFVArr[i].youngerName);
                const elder_age = (this.state.boothWiseHouseListForFVArr[i].elderAge);
                const elder_gender = (this.state.boothWiseHouseListForFVArr[i].elderGender);
                const younger_age = (this.state.boothWiseHouseListForFVArr[i].youngerAge);
                const younger_gender = (this.state.boothWiseHouseListForFVArr[i].youngerGender);
                const house_id = (this.state.boothWiseHouseListForFVArr[i].boothHouseId);
                const booth_house_id = (this.state.boothWiseHouseListForFVArr[i].boothHouseId);
                const elderVoterCardNo = (this.state.boothWiseHouseListForFVArr[i].elderVoterCardNo);
                const youngerVoterCardNo = (this.state.boothWiseHouseListForFVArr[i].youngerVoterCardNo);
                const isEnrolled = (this.state.boothWiseHouseListForFVArr[i].isEnrolled);
                
                const obj = {
                    house_no:(house_no),
                    elder_person:elder_person,
                    younger_person: younger_person,
                    elder_age: elderVoterCardNo,
                    elder_gender: elder_gender,
                    younger_age:youngerVoterCardNo,
                    younger_gender:younger_gender,
                    house_id:house_id,
                    booth_house_id:booth_house_id,
                    elderVoterCardNo:elder_age,
                    youngerVoterCardNo:younger_age,
                    isEnrolled:isEnrolled
                    
                }
                houseData.push(obj);
            } 

            
            
            return houseData;
        }
}


getVotersInAHouseForFV = (houseId,house_no) => {
   
    var houseWiseDataArr=[];
    this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVLoader:"hide",houseWiseVoterListArrForFVCheck:0});
    this.setState({checkingEnrollMessage:""});
    this.setState({houseNoValue:house_no,houseNoIdStr:houseId});
    setTimeout(() => this.setState({houseWiseVotersDisplayModal:true}), 1000);
    

    

    this.getHouseVoterDetailsAfterLocationChecking(houseId,house_no);
}
 



getHouseVoterDetailsAfterLocationChecking = (houseId,house_no) => {
    this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVLoader:"show",houseWiseVoterListArrForFVCheck:0});
    const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "boothHouseId" : houseId,
            "campaignId":this.state.campaignId
        });
        getAPICallData(obj,"CampaignEnrollment/getBSBGVotersInAHouseByCampaignId")
            .then((res) => res.json())
            .then((content) => {
                this.setState({houseWiseVoterListArrForFVLoader:"hide"});
                if(content !=null && content.length>0){
                    this.setState({houseWiseVoterListForFVArr:content,houseWiseVoterListArrForFVCheck:0});

                }else{
                    this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVCheck:1});
                }
        });
}


checkEnrolled = (e,type) => {
    this.setState({enrollCheckVal:type});
}

handleOnchangeInputBox = (e) => {
    console.log("handleInputChange", e.target.name, e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    this.setState({validateCaptureMobileNo:"",validateCaptureMobileNoInAbove:"",validateCaptureOTP:"",validateCaptureOTPInAbove:""});

    if(name == "captureOTPNO"){
        this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
        if(value.length >= 6){
            this.validateMobileOtp(value);
           
        }
        
    }else if(name == "captureMobileNo"){
        if(value.length >= 10){
           // this.checkMobileNumberAlreadyExistForCampaignEnrollment(value);

            
        }
    }

    this.setState({ [name]: value });
    
  }

  checkMobileNumberAlreadyExistForCampaignEnrollment = (value) => {
    this.setState({mobileNoLoadingSymbol:"show",mobileNoSuccessMsg:""});

    const mapObj =  
    JSON.stringify({ 
        "chatId" : this.state.chatID,
        "campaignId" : this.state.campaignId,
        "mobileNo" : value
    }); 
        

    getAPICallData(mapObj,"CampaignEnrollment/checkMobileNumberAlreadyExistForCampaignEnrollment")
      .then((response) => {
        this.setState({mobileNoLoadingSymbol:"hide"});
        // 1. check response.ok
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response); // 2. reject instead of throw
      })
      .then((json) => {
        // all good, token is ready
        if(json.status == "Success"){
            this.setState({mobileNoSuccessMsg:"SUCCESS"});
        }else{
            this.setState({mobileNoSuccessMsg:"FAIL"});
        }
      })
      .catch((response) => {
        this.setState({mobileNoSuccessMsg:"Something Wrong,Please Try Again..."});
        response.json().then((json) => {
            this.setState({mobileNoSuccessMsg:"Something Wrong,Please Try Again..."});
        })
      });

   
  }

  handleOnchangeSelectBox = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    

    this.setState({ [name]: value });
}

checksearchParameterByValue = (e,type) => {
    if(type == "voter"){
        this.setState({voterCheckedCond:true,locationCheckedCond:false})
    }else if(type == "location"){
       // this.getCurrentLocation();
        this.setState({voterCheckedCond:false,locationCheckedCond:true})
    }
}

getHousesLocations = () => {
    this.setState({houseLocationArr:[],houseLocationDataCheck:0,houseLocationLoading:"hide"});

    this.setState({validateboothId:"",validateLocations:""});
    if(this.state.boothId == ""){
        this.setState({validateboothId:"Select Booth" });
        return;
    }
    /*if((this.state.userLatitude == "" || this.state.userLatitude == 0) || (this.state.userLongitude == "" || this.state.userLongitude == 0)){
        this.setState({validateLocations:"Please Select Locations" });
        return;
    }*/
    
    if(this.state.fetchHouseValue == 0){
        this.setState({tableHeadingName:'Booth Wise Nearest All House In '+this.state.distanceValue+' Meters'})
    }else{
        this.setState({tableHeadingName:'Booth Wise Nearest '+this.state.fetchHouseValue+' House In '+this.state.distanceValue+' Meters'})
    }

    

    this.getMappedHousesLocationInABoothData();
    
}

	
getHouseWiseGeoLocation = (position) => {
    const getCoordinates =  
        { 
        "latitude" : position.coords.latitude,
        "longitude" : position.coords.longitude,
        "accuracy":position.coords.accuracy
        }
        
    this.setState({getHouseWiseLatitudeLongitudeObj:getCoordinates});
   // this.setState({houseLocationLoading:"hide"});

    /*if(position.coords.latitude != "" && position.coords.longitude != ""){
        this.setState({useLocationEnabledMessage:"enabled"});
        this.getMappedHousesLocationInABoothData();
    }else{
        this.setState({useLocationEnabledMessage:"Your Mobile GeoLocation is OFF...Please Enable"})
    }*/
    
    
}


handlesearchNameHouseLocationValueChange = (event) => {
    this.setState({
        searchNameHouseLocationValue: event.target.value
    })
}

checkEnrolledForLocationBSBG = (e,value) => {
    this.setState({locationEnrolledFilterValue:value})
}

getMappedHousesLocationInABoothData() {
    var houselistLoctionsFilterArr=[];

    this.setState({houseLocationArr:[],houseLocationDataCheck:0,houseLocationLoading:"show"});
    const mapObj =  
          JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothId" : this.state.boothId,
          "campaignId":this.state.campaignId
          }
      ); 

          getAPICallData(mapObj,"CampaignEnrollment/getDistanceWiseHouseDetailsInABoothByCampaignId")
          .then((res) => res.json())
          .then((data) => {
            this.setState({houseLocationLoading:"hide"});
            if(data !=null && data.length>0){
               const removeEmptyLatLongData = data.filter((item) => item.latitude !="" && item.longitude !="" );

                if(this.state.locationEnrolledFilterValue == ""){
                    houselistLoctionsFilterArr = removeEmptyLatLongData;
                }else{
                    houselistLoctionsFilterArr = removeEmptyLatLongData.filter((item) => item.isEnrolled == this.state.locationEnrolledFilterValue );
                }

                console.log("this.state.locationEnrolledFilterValue",houselistLoctionsFilterArr)
                setTimeout(() => this.setState({houseLocationArr:houselistLoctionsFilterArr,houseLocationDataCheck:1}), 500);
                
            }else{
                this.setState({houseLocationArr:[],houseLocationDataCheck:1});
            }
          
          });
    
  }

  sortFuncDistance(a, b, order, dataField, rowA, rowB)  { 
    if (order == 'desc') {
        
        return parseInt(rowB.Distance)  > parseInt(rowA.Distance) ? 1 : -1;

    } else {
        return parseInt(rowA.Distance)  > parseInt(rowB.Distance) ? 1 : -1;
    }
}

  boothNearestLocationsDataColumns() {

    let columns = [

        {
            dataField: "house_no",
            text: "House No",
            sort: true,
            formatter: this.actionsFormatterForBSBGLocationHouse.bind(this),
            headerStyle: {
                width: "15%"
            }
            
            
        },
        {
            dataField: "elder_person",
            text: "Elder Person",
            formatter: this.actionsFormatterForBSBGLocationElderPerson.bind(this),
            headerStyle: {
                width: "28%"
            }
            
            
        },
        {
            dataField: "younger_person",
            text: "Younger Person",
            formatter: this.actionsFormatterForBSBGLocationYoungerPerson.bind(this),
            headerStyle: {
                width: "28%"
            }
           
            
        },
        
        {
            dataField: "Distance",
            text: "Distance (mts)",
            sort: true,
            formatter: this.actionsFormatterForCoordinates.bind(this),
            sortFunc: this.sortFuncDistance.bind(this)
            
        },
        
    
    ];
    return columns;
}

actionsFormatterForBSBGLocationHouse = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
        {
            row.isEnrolled == 'Y' ?
            (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold color_green text-decoration-underline' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no} <br/> Visited</span>)
            :
            (
                <>
                <span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold  text-decoration-underline' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no}</span>
                
                </>
            )
        }
        </>
          
     );
}

actionsFormatterForBSBGLocationYoungerPerson = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.younger_person)} 
           <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.younger_age)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.younger_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.younger_voterCardNo)}</span></>
        </span>
     );
    
} 


actionsFormatterForBSBGLocationElderPerson = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.elder_person)} 
           <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.elder_age)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.elder_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.elder_voterCardNo)}</span></>
        </span>
     );
    
} 

actionsFormatterForCoordinates = (cell, row, rowIndex, formatExtraData) => {
   
    return (
        <h6 className='f-12'> {(row.Distance)} 
            <br/>
            <OverlayTrigger key={"top"} placement={"top"}
                overlay={
                    <Tooltip id={`tooltio-Filter`}>
                        <h6 className='f-14'>Get Direction</h6>
                    </Tooltip>
                }
                >
                    <a style={{position:"relative",top:"10px"}} target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${row.latitude},${row.longitude}`}><Button variant="outline-danger" className='f-10  pad_3 ms-2 btn-sm'> <i class="fa-solid fa-map-location-dot"></i> </Button></a>
                </OverlayTrigger>
            
            
        </h6>
     );
    
}


boothNearestLocationsData = (houseListArrWithSearch) => {
    let locationsData = [];
    let noOfHouseDisplayData=[];
    const currentLatitude =  this.state.userLatitude;
    const currentLongitude  = this.state.userLongitude;
    const distanceValue = parseInt(this.state.distanceValue);
    const noOfHouseDisplay = parseInt(this.state.fetchHouseValue);

    
    if(houseListArrWithSearch.length>0){

        houseListArrWithSearch.forEach( (data) => {
                if((this.state.userLatitude == "" || this.state.userLatitude == 0) || (this.state.userLongitude == "" || this.state.userLongitude == 0)){
                    const obj = {
                        House_No:(data.houseNo),
                        Location: data.street,
                        Distance: 0,
                        latitude:data.latitude,
                        longitude:data.longitude,
                        house_no : data.houseNo,
                        house_id : data.houseId,

                        elder_person:data.elderVoterName,
                        elder_age:data.elderAge,
                        elder_gender:data.elderGender,
                        elder_voterCardNo:data.elderVoterIdCardNo,
                        elder_voterId:data.elderVoterId,

                        younger_person:data.youngerVoterName,
                        younger_age:data.youngerAge,
                        younger_gender:data.youngerGender,
                        younger_voterCardNo:data.youngerVoterIdCardNo,
                        younger_voterId:data.youngerVoterId,

                        isEnrolled:data.isEnrolled,
                        visited:data.visited
                    
                    }
                    
                    if(noOfHouseDisplay == 0){
                        locationsData.push(obj);
                    }else{
                        noOfHouseDisplayData.push(obj);
                        locationsData = noOfHouseDisplayData.slice(0, noOfHouseDisplay)
                    }
            }else{
                const distance = haversineNew(currentLatitude,currentLongitude,data.latitude,data.longitude);
                console.log("distance",distanceValue)
                    if(distance !=0 && distance <= distanceValue){
                        const obj = {
                            House_No:(data.houseNo),
                            Location: data.street,
                            Distance: distance.toFixed(2),
                            latitude:data.latitude,
                            longitude:data.longitude,
                            house_no : data.houseNo,
                            house_id : data.houseId,

                            elder_person:data.elderVoterName,
                            elder_age:data.elderAge,
                            elder_gender:data.elderGender,
                            elder_voterCardNo:data.elderVoterIdCardNo,
                            elder_voterId:data.elderVoterId,

                            younger_person:data.youngerVoterName,
                            younger_age:data.youngerAge,
                            younger_gender:data.youngerGender,
                            younger_voterCardNo:data.youngerVoterIdCardNo,
                            younger_voterId:data.youngerVoterId,

                            isEnrolled:data.isEnrolled,
                            visited:data.visited
                        
                        }
                        
                        if(noOfHouseDisplay == 0){
                            locationsData.push(obj);
                        }else{
                            noOfHouseDisplayData.push(obj);
                            locationsData = noOfHouseDisplayData.slice(0, noOfHouseDisplay)
                        }
                    }
            }
            
        });


        if(locationsData.length == 0){
            this.setState({houseLocationArr:[],houseLocationDataCheck:1});
        }

        return locationsData;
    }
}

getCurrentLocation = () => {
    if(this.state.chatID != "1048868711"){
        if(navigator.geolocation){
            this.setState({useCurrentLocationSymbol:"show"})
            navigator.geolocation.getCurrentPosition(this.getCurrentLocationCoordinates,this.checkingLocationServiceEnabledError)
        }else{
          /* <EnabledLocationPopup 
                show={() => this.setState({ checkingEnabledLocationModal: true })}
                onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                closeEnabledModal={this.closeEnabledModal}
            />*/
        }
        //setTimeout(() => this.setState({delaylocationModal:true}), 2000);
    }
   
}

getCurrentLocationCoordinates = (position) => {
    const getCoordinates =  
        { 
        "latitude" : position.coords.latitude,
        "longitude" : position.coords.longitude,
        "accuracy":position.coords.accuracy
        }
        
    this.setState({getCurrentLocationCoordinatesObj:getCoordinates});
    this.setState({userLatitude:position.coords.latitude,userLongitude:position.coords.longitude});
    this.setState({useCurrentLocationSymbol:"hide"})
    
}

checkingLocationServiceEnabledError = (error) => {
    return(
      <EnabledLocationPopup 
              show={() => this.setState({ checkingEnabledLocationModal: true })}
              onHide={() => this.setState({ checkingEnabledLocationModal: false })}
              closeEnabledModal={this.closeEnabledModal}
       />
    )
      
  }

  closeEnabledModal = () => {
    if(this.state.campaignId == 4){
        this.props.history.push({
            pathname: "/voter_slip_campaign",
            params: {
                navbarDisplay:"show",
                userAccessList:this.state.userAccessList,
                campaignId:4
            
            },
        });
    }else if(this.state.campaignId == 5){
        this.props.history.push({
            pathname: "/voter_status_campaign",
            params: {
                navbarDisplay:"show",
                userAccessList:this.state.userAccessList,
                campaignId:5
            
            },
        });
    }else if(this.state.campaignId == 6){
        this.props.history.push({
            pathname: "/exit_poll_campaign",
            params: {
                navbarDisplay:"show",
                userAccessList:this.state.userAccessList,
                campaignId:6
            
            },
        });
    }
    
}

  houseVisitCaptureClick = (data) => {
    console.log("voterData",data)
    this.setState({savaCampaignLoadingSuccessMsg:"",captureMobileNo:""
    });
    this.setState({photoCaptureArr:[],showImgCapture:true,
        imageAddedMsg:"",captureOTPNO:"",
        validateCaptureOTP:"",
        validateCaptureMobileNoInAbove:"",
        validateCaptureOTPInAbove:"",

        mobileNoDisabled:false,

        smsSentMessage:"",
        otpVerifiedMessageStatus:"",
        otpLoadingSymbol:"hide",
        getOTPLoadingSymbol:"hide",
        OTPSentStatus:"",
        resendOTPLoadingSymbol:"hide",
        resendSmsSentMessage:"",
        mobileNoLoadingSymbol:"hide",
        mobileNoSuccessMsg:"",
        
        minutes:1,
        seconds:0,OTPVerifiedDisabledButton:true,validateCapturePhoto:"",answerDateTemplateArr:[],checkValidationsArr:[],checkQuestionsMessage:""});

        
      this.setState({houseVisitedImageCaptureModal:true,selectedHouseVisitVoterId:data})
}



saveCampaignEnrollment = () => {
   
    this.setState({validateCaptureMobileNo:"",validateCapturePhoto:"",validateCaptureOTP:"",validateCaptureMobileNoInAbove:"",validateCaptureOTPInAbove:"",savaCampaignLoadingSuccessMsg:""});

    if(this.state.checkOTPImageValidate == "mobile"){
        if(this.state.captureMobileNo == ""){
            this.setState({validateCaptureMobileNo:"Please Enter Mobile No",validateCaptureMobileNoInAbove:"Please Scroll to Above To Enter Mobile No" });
            return;
        }
        if(!this.state.captureMobileNo.match(/^[0-9]+$/)){
            this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No",validateCaptureMobileNoInAbove:"Please Scroll to Above To Enter Valid Mobile No" });
            return;
        }
    
        if(this.state.captureMobileNo.length < 10){
            this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No",validateCaptureMobileNoInAbove:"Please Scroll to Above To Enter Mobile No" });
            return;
        }
    
        if(this.state.captureMobileNo != ""){
            if(this.state.captureOTPNO == ""){
                this.setState({validateCaptureOTP:"Please Enter OTP",validateCaptureOTPInAbove:"Please Scroll to Above To Enter OTP" });
                return;
            }
    
            if(this.state.captureOTPNO.length < 6){
                this.setState({validateCaptureOTP:"Please Enter Valid OTP",validateCaptureOTPInAbove:"Please Scroll to Above To Enter Valid OTP" });
                return;
            }
    
            if(this.state.otpVerifiedMessageStatus == "NotVerified"){
                this.setState({validateCaptureOTP:"Please Enter Valid OTP",validateCaptureOTPInAbove:"Please Scroll to Above To Enter Valid OTP" });
                return;
            }
        }
    }else if(this.state.checkOTPImageValidate == "Y"){

        let finalMergeKPIAnswerDts = this.state.answerDateTemplateArr;

        //clear 
            for(var i in this.state.campaignSurveyQuestionnaireArr){
                var errorQuestion = "errorMsgDisplay"+this.state.campaignSurveyQuestionnaireArr[i].questionId+"";
                document.getElementById(errorQuestion).innerHTML = "";
            }

        const validationsArr =  this.state.campaignSurveyQuestionnaireArr.filter(item => !finalMergeKPIAnswerDts.some(finalMergeKPIAnswerDts => finalMergeKPIAnswerDts.questionId == item.questionId))
        this.setState({checkValidationsArr:validationsArr})  

        if(validationsArr.length > 0){
            this.setState({checkQuestionsMessage:"Please Answer All Questions In Above Block"})
            for(var i in validationsArr){
                var errorQuestion = "errorMsgDisplay"+validationsArr[i].questionId+"";
                
                if(validationsArr[i].answerType == "Single Option"){
                    document.getElementById(errorQuestion).innerHTML = "Please Check Option";
                    return;
                }else if(validationsArr[i].answerType == "Multi Option"){
                    document.getElementById(errorQuestion).innerHTML = "Please Check Atleast one Option";
                    return;
                }else if(validationsArr[i].answerType == "Number"){
                    document.getElementById(errorQuestion).innerHTML = "Please Enter Number";
                    return;
                }else if(validationsArr[i].answerType == "Text"){
                    document.getElementById(errorQuestion).innerHTML = "Please Enter Text";
                    return;
                }else if(validationsArr[i].answerType == "Percentage"){
                    document.getElementById(errorQuestion).innerHTML = "Please Enter Percentage";
                    return;
                }
                
            }
            
        }

        

       /* if(this.state.photoCaptureArr.length == 0){
            this.setState({validateCapturePhoto:"Please Take A Photo Atleast One..."});
            return;
        }*/
    }
    
    
    /*if(this.state.photoCaptureArr.length > 0){
        for(var i in this.state.photoCaptureArr){
            base64ImgListArr.push(this.state.photoCaptureArr[i].data.replace("data:image/jpeg;base64,",""));
        }
    }else{
        //this.setState({disabledUploadButton:false});
        this.setState({validateCapturePhoto:"Please Take A Photo Atleast One..."});
        return;
    }*/

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.getHouseWiseGeoLocationsaveCampaignEnrollment,this.checkingLocationServiceEnabledError)
    }else{
        <EnabledLocationPopup 
            show={() => this.setState({ checkingEnabledLocationModal: true })}
            onHide={() => this.setState({ checkingEnabledLocationModal: false })}
            closeEnabledModal={this.closeEnabledModal}
        />
    }

     
    this.setState({savaCampaignLoadingSymbol:"show"});
    
}



getOnchangeSelectedKPIAnswer = (e,data,mainlist) => {
    const name = e.target.name;
    const value = e.target.value;

    var newItem = {};
    var tempArr = this.state.answerDateTemplateArr;

    this.setState({checkQuestionsMessage:""});
    for(var i in this.state.campaignSurveyQuestionnaireArr){
        var errorQuestion = "errorMsgDisplay"+this.state.campaignSurveyQuestionnaireArr[i].questionId+"";
        document.getElementById(errorQuestion).innerHTML = "";
    }

    const objWithIdIndex = tempArr.findIndex((obj) => obj.questionId == mainlist.questionId);

    if (objWithIdIndex > -1) {
        tempArr.splice(objWithIdIndex, 1);
        this.setState({answerTemplateArr:tempArr})
    }


    if (mainlist.answerType == "Single Option") {
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            optionId: value, 
        };
    }else if(mainlist.answerType == "Number"){
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            answerNumber: value, 
        };
    }else if(mainlist.answerType == "Text"){
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            answerText: value, 
        };
    }else if(mainlist.answerType == "Percentage"){
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            answerPercentage: value, 
        };
    }else if(mainlist.answerType == "Multi Option"){
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            optionId: value, 
        };
    }

    this.setState({answerDateTemplateArr:[...this.state.answerDateTemplateArr, newItem]}); 
}



getHouseWiseGeoLocationsaveCampaignEnrollment = (position) => {
    var base64ImgListArr=[];

    if(this.state.photoCaptureArr.length > 0){
        for(var i in this.state.photoCaptureArr){
            base64ImgListArr.push(this.state.photoCaptureArr[i].data.replace("data:image/jpeg;base64,",""));
        }
    }

     

    setTimeout(() => {
    const obj =  
    JSON.stringify({ 
        "chatId" :this.state.chatID,
        "mobileNo" : this.state.captureMobileNo,
        "campaignId" : this.state.campaignId,
        "voterName" : this.state.selectedHouseVisitVoterId[0].voterName,
        "voterId" : this.state.selectedHouseVisitVoterId[0].voterId,
        "gender" : this.state.selectedHouseVisitVoterId[0].gender,
        "age" : this.state.selectedHouseVisitVoterId[0].age,
        "houseId" : this.state.houseNoIdStr,
        "boothId" : this.state.boothId,
        "constituencyId" : this.state.constituencyValue.value,
        "latitude":position.coords.latitude,
        "longitude":position.coords.longitude,
        "base64StrList" : base64ImgListArr,
        "answerList":this.state.answerDateTemplateArr
    });
    
    

getAPICallData(obj,"CampaignEnrollment/saveCampaignEnrollmentNew")
    .then((res) => res.json())
    .then((content) => {
        this.setState({savaCampaignLoadingSymbol:"hide"});  

        if(content !=null && content.status == "Success"){
            this.setState({savaCampaignLoadingSuccessMsg:"Visited SuccessFully..."});
             setTimeout(() => {
                this.setState({houseVisitedImageCaptureModal:false});
                this.setState({savaCampaignLoadingSuccessMsg:"",captureMobileNo:""});
                this.setState({photoCaptureArr:[],showImgCapture:true,
                                imageAddedMsg:"",captureOTPNO:"",
                                validateCaptureOTP:"",
                                validateCaptureMobileNoInAbove:"",
                                validateCaptureOTPInAbove:"",
                    
                                mobileNoDisabled:false,
                    
                                smsSentMessage:"",
                                otpVerifiedMessageStatus:"",
                                otpLoadingSymbol:"hide",
                                getOTPLoadingSymbol:"hide",
                                OTPSentStatus:"",
                                resendOTPLoadingSymbol:"hide",
                                resendSmsSentMessage:"",

                                mobileNoLoadingSymbol:"hide",
                                mobileNoSuccessMsg:"",

                                getHouseWiseLatitudeLongitudeObj:{},
                                
                                minutes:1,
                                seconds:0,
                                OTPVerifiedDisabledButton:true});
                    
                const visitedHouseWiseVoterListArr = this.state.houseWiseVoterListForFVArr.map((item) => {
                    if (item.voterId == this.state.selectedHouseVisitVoterId[0].voterId) {
                        return { ...item, isEnrolled:'Y' }
                    }
                    return { ...item }
                });
        
                console.log("houseWiseVoterListForFVArr",visitedHouseWiseVoterListArr)
                this.setState({houseWiseVoterListForFVArr:visitedHouseWiseVoterListArr});

                if(this.state.locationCheckedCond == true){
                    const locationHouseWiseArr = this.state.houseLocationArr.map((item) => {
                        if (item.houseId == this.state.houseNoIdStr) {
                            return { ...item, isEnrolled:'Y' }
                        }
                        return { ...item }
                    });
    
                    this.setState({houseLocationArr:locationHouseWiseArr});
                }else if(this.state.voterCheckedCond == true){
                    const normalHouseWiseArr = this.state.boothWiseHouseListForFVArr.map((item) => {
                        if (item.boothHouseId == this.state.houseNoIdStr) {
                            return { ...item, isEnrolled:'Y' }
                        }
                        return { ...item }
                    });

                    console.log("normalHouseWiseArr",normalHouseWiseArr)
    
                    this.setState({boothWiseHouseListForFVArr:normalHouseWiseArr});
                }
                

            }, 1000);
        }else if(content !=null && content.message == "Minimum 3 Mins Required"){
            this.setState({savaCampaignLoadingSuccessMsg:" ప్రతి ఓటర్ దగ్గర కొంత సమయం గడిపి వారికీ బాబు సూపర్ సిక్స్ ధ్వారా వారికి వచ్చే ఎక్కువ ప్రయోజనాలు తెలియజేయగలరు. ప్రతి ఇంటి వద్ద 3  నిముషాలు గడిపిన తరువాత మరొకరి రిజిస్ట్రేషన్ చేయగలరు" });
    
        }else if(content !=null  && content.message == "Same Location Detected"){
            this.setState({savaCampaignLoadingSuccessMsg:"ఓకే ఇంటి వద్ద రెండు కన్నా ఎక్కువ రెజిస్ట్రేషన్స్ చేస్తున్నారు , దయవుంచి ప్రక్క ఇంటివద్ద రెజిస్ట్రేషన్స్ చేయగలరు"});
    
        }else if(content !=null && content.status == "Fail"){
            this.setState({savaCampaignLoadingSuccessMsg:content.message});
    
        }else{
            this.setState({savaCampaignLoadingSuccessMsg:"Some Thing Went Wrong,Please Try Again..."});
    
        }
    });
 }, 1200);   
}

removeCapturePhotoFromBSBG = (fileId) => {
    var tempArr = this.state.photoCaptureArr;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);
  
    if (objWithIdIndex > -1) {
      tempArr.splice(objWithIdIndex, 1);
    }
  
    this.setState({photoCaptureArr:tempArr})
  
  }
  
    
captureForHouseVisit = () => {
    this.setState({showImgCapture:true,imageAddedMsg:""});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        data : this.state.webcamRef.current.getScreenshot(),
      }

      this.setState({imageAddedMsg:"Capture Photo added successfully in below..."});
      setTimeout(() => {
        this.setState({photoCaptureArr:[...this.state.photoCaptureArr, obj]}); 
        this.setState({imageAddedMsg:"",validateCapturePhoto:""});
    }, 1000);
};

    getMobileNoOTP = () => {
        this.setState({validateCaptureMobileNo:"" });
        var expr = /^(0|91)?[6-9][0-9]{9}$/;

        if(this.state.captureMobileNo == ""){
            this.setState({validateCaptureMobileNo:"Please Enter Mobile No" });
            return;
        }

        if(!expr.test(this.state.captureMobileNo)){
            this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
            return;
        }

        if(this.state.captureMobileNo.length < 10){
            this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
            return;
        }


        this.setState({smsSentMessage:"",getOTPLoadingSymbol:"show",OTPSentStatus:"",otpVerifiedValue:0});
        
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.captureMobileNo,
            "campaignId":this.state.campaignId
            
            }); 

            getAPICallData(obj,"sendOTPToMobileNumberForCampaignEnrollment")
            .then((res) => res.json())
            .then((data) => {
            this.setState({getOTPLoadingSymbol:"hide"});
            if(data !=null && data.status == "Success"){
                console.log(jwt_decode(data.reference).sub)
                this.setState({smsSentMessage:"OTP Sent Your Mobile No",OTPSentStatus:data.status,otpVerifiedValue:jwt_decode(data.reference).sub});
                this.setState({mobileNoDisabled:true,OTPVerifiedDisabledButton:false})
                this.OTPTimerFunction();
            }else{
                this.setState({smsSentMessage:"Some Thing Went Wrong,Please Try Again...",OTPSentStatus:"Fail",otpVerifiedValue:0});
                this.setState({mobileNoDisabled:false,OTPVerifiedDisabledButton:true})
            }
            });
    }

    resendOTPTimer = () => {
        this.setState({minutes:1});
        this.setState({seconds:0});

        this.OTPTimerFunction();
    };

    OTPTimerFunction = () => {
        const interval = setInterval(() => {
            if (this.state.seconds > 0) {
            this.setState({seconds:this.state.seconds - 1});
            }
    
            if (this.state.seconds === 0) {
            if (this.state.minutes === 0) {
                clearInterval(interval);
            } else {
                this.setState({seconds:59});
                this.setState({minutes:this.state.minutes - 1});

            }
            }
        }, 1000);
    
        return () => {
            clearInterval(interval);
        };
    }

    resendOTPNew = () => {
        this.setState({validateCaptureMobileNo:"",OTPVerifiedDisabledButton:false });
        if(this.state.captureMobileNo == ""){
            this.setState({validateCaptureMobileNo:"Please Enter Mobile No" });
            return;
        }
        var expr = /^(0|91)?[6-9][0-9]{9}$/;
        if(!expr.test(this.state.captureMobileNo)){
            this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
            return;
        }

        

        if(this.state.captureMobileNo.length < 10){
            this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
            return;
        }

        this.setState({resendOTPLoadingSymbol:"show",otpVerifiedValue:0});
    
        const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "mobileNo" : this.state.captureMobileNo,
                "campaignId":this.state.campaignId
            
            }); 

            getAPICallData(obj,"sendOTPToMobileNumberForCampaignEnrollment")
            .then((res) => res.json())
            .then((data) => {
            this.setState({resendOTPLoadingSymbol:"hide"});
            if(data !=null && data.status == "Success"){
                console.log(jwt_decode(data.reference).sub)
                this.setState({otpVerifiedValue:jwt_decode(data.reference).sub});
                this.resendOTPTimer();
            }else{
                this.setState({otpVerifiedValue:0});
            }
            });
        
    }

    validateMobileOtp = (otp) => {
        this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.captureMobileNo,
            "otp" : otp
            

            }); 

            getAPICallData(obj,"validateMobileOtp")
            .then((res) => res.json())
            .then((data) => {
            this.setState({otpLoadingSymbol:"hide"});
            if(data !=null && data.status == "Success"){
                this.setState({otpVerifiedMessageStatus:"Verified",OTPVerifiedDisabledButton:true});
            }else{
                this.setState({otpVerifiedMessageStatus:"NotVerified",OTPVerifiedDisabledButton:false});
            }
            });
    }

    reloadCam = () => {
        const constraints = {
            audio: false,
            video: true,
          };
          
        this.setState({showImgCapture:true})
        //this.setState({videoConstraints:{facingMode: "environment"}});
        navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
            const video = document.querySelector("video");
            video.srcObject = mediaStream;
            video.onloadedmetadata = () => {
            video.play();
            };
        })
        .catch((err) => {
            // always check for errors at the end.
            console.error(`${err.name}: ${err.message}`);
        });
          
    }


    reloadPageForHouseVisit = () => {
        this.setState({houseVisitedImageCaptureModal:false});

        setTimeout(() => this.setState({houseWiseVotersDisplayModal:false}), 500);
        
    }
    render() {
        let houseLocationManualSearchArr=[];
        if(this.state.locationCheckedCond == true){
            let keyword = this.state.searchNameHouseLocationValue.toString()
            if (keyword !== ''){
                houseLocationManualSearchArr = this.state.houseLocationArr.filter(  (item) => {
                    if (item.houseNo.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.elderVoterIdCardNo.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.elderVoterName.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.elderGender.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.elderAge.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.youngerVoterIdCardNo.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.youngerVoterName.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.youngerGender.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.youngerAge.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    
                } );
            }else{
                houseLocationManualSearchArr = this.state.houseLocationArr;
                
            }
            
            
        }

        console.log("houseWiseVoterListForFVArr",this.state.houseLocationManualSearchArr)
        return (
               <Container>
                    <Row>
                        <Col className='mt-3'>
                        <label className="form-label f-12 fw-bold">Constituency<span className='color_red f-14 ms-1'>*</span></label>
                        <SelectBox
                                stateData={this.state.constituencyValue}
                                handleChange={this.handleOnchangeSelectBoxForFamily}
                                name="constituencyId"
                                options={this.state.constituencyListArr}
                                placeholderName={'Constituency'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                    <Col className='mt-3'>
                        <label className="form-label f-12 fw-bold">Booth<span className='color_red f-14 ms-1'>*</span><span className='f-12 color_red'>{this.state.validateboothId}</span>
                            {
                                this.state.getBoothsLoadingSymbol == "show" ?
                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                :
                                ("")
                            }
                            
                        </label>
                        <SelectBox
                                stateData={this.state.boothValue}
                                handleChange={this.handleOnchangeSelectBoxForFamily}
                                name="boothId"
                                options={this.state.boothListArr}
                                placeholderName={'Booth'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                </Row>

                {/*<Row>
                    <Col>
                        <h6 className='mt-3'>
                            <span className="form-label f-14 fw-bold ml-0">
                                Search By : 
                            </span>
                            <span className='me-4'>
                                <Form.Check
                                    inline
                                    label={"Voter"}
                                    name="searchParameterByVoters"
                                    type="radio"
                                    id="VoterSearchId"
                                    onChange={(e)=>this.checksearchParameterByValue(e,'voter')}
                                    className="color_black f-14 pos_name_align fw-bold"
                                    checked={this.state.voterCheckedCond}
                                />
                                <Form.Check
                                    inline
                                    label={"Location"}
                                    name="searchParameterByVoters"
                                    type="radio"
                                    id="locationSearchId"
                                    onChange={(e)=>this.checksearchParameterByValue(e,'location')}
                                    className="color_black f-14 pos_name_align fw-bold"
                                    checked={this.state.locationCheckedCond}
                                />
                            </span>
                        </h6>
                    </Col>
                        </Row>*/}

                    {
                        this.state.voterCheckedCond == true ?
                        (
                            <>
                            <Row>
                                <Col>
                                <Form>
                                    <div className='text-center mt-3'>
                                    <Form.Check
                                        inline
                                        label={`All`}
                                        name="enrolledName"
                                        type="radio"
                                        id={`allenrolledId`}
                                        onChange={(e)=>this.checkEnrolled(e,"")}
                                        className="f-14 fw-bold color_black"
                                        value=""
                                    />
                                    <Form.Check
                                        inline
                                        label={`Visited`}
                                        name="enrolledName"
                                        type="radio"
                                        id={`enrolledId`}
                                        onChange={(e)=>this.checkEnrolled(e,"Y")}
                                        className="f-14 fw-bold color_black"
                                        value="Y"
                                    />
                                    <Form.Check
                                        inline
                                        label={`Not Visited`}
                                        name="enrolledName"
                                        type="radio"
                                        id={`notEnrolledId`}
                                        onChange={(e)=>this.checkEnrolled(e,"N")}
                                        className="f-14 fw-bold color_black"
                                        value="N"
                                        defaultChecked={true}
                                    />
                                     </div>
                                </Form>   
                                </Col>
                            </Row>
                            </>
                        )
                        :
                        (
                            this.state.locationCheckedCond == true ? 
                            (
                                <>
                                <fieldset class="mt-2"><legend>Location Filters</legend>
                                <Row>
                                    <Col className='w_60'>
                                        <InputGroup className="">
                                            <InputGroup.Text id="basic-addon1" className='f-12 pad_5' style={{textAlign:"left",marginLeft:"0px"}}>Distance</InputGroup.Text>
                                            <Form.Control type="text" name="distanceValue" className='form-control-sm' value={this.state.distanceValue}  placeholder="Distance" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                        </InputGroup>
                                    </Col>
                                    <Col className='w_40'>
                                        <InputGroup className="">
                                            <InputGroup.Text id="basic-addon2" className='f-12 pad_5' style={{textAlign:"left",marginLeft:"0px"}}>Houses</InputGroup.Text>
                                            <select id="fetchHouseValueId" name="fetchHouseValue" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                                        <option value="">Select Count</option>
                                                        <option value="0" selected>All</option>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                        <option value="40">40</option>
                                                        <option value="50">50</option>
                                                    </select>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col>
                                        <Form.Group className="mt-2" >
                                            <Form.Label className='f-12 fw-bold'>Locations :<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateLocations}</span> </Form.Label>
                                            <Row>
                                                <Col>
                                                    <InputGroup className="">
                                                        <InputGroup.Text id="basic-addon3" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>lat</InputGroup.Text>
                                                        <Form.Control type="text" name="userLatitude" className='form-control-sm' value={this.state.userLatitude}  placeholder="Latitude" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup className="">
                                                        <InputGroup.Text id="basic-addon4" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>lang</InputGroup.Text>
                                                        <Form.Control type="text" name="userLongitude" className='form-control-sm' value={this.state.userLongitude}   placeholder="Longitude" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <h6 className='f-12 mt-2 fw-bold'>
                                            Note : <span className='ml_5 mr_2 color_red'>Distance In Meters,</span>
                                                    
                                        </h6>
                                    </Col>
                                    <Col>
                                        <Button variant="outline-danger" className='f-10  mt-2 pad_3 float-end fw-bold' onClick={() => this.getCurrentLocation()}> <i class="fa-solid fa-location-dot"></i> Use current location
                                            {
                                                this.state.useCurrentLocationSymbol == "show" ?
                                                (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                                :
                                                ("") 
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                                </fieldset>  

                                <Row>
                                    <Col>
                                        <Form>
                                            <div className='text-center mt-3'>
                                            <Form.Check
                                                inline
                                                label={`All`}
                                                name="enrolledName"
                                                type="radio"
                                                id={`allBSBGLocationenrolledId`}
                                                onChange={(e)=>this.checkEnrolledForLocationBSBG(e,"")}
                                                className="f-14 fw-bold color_black"
                                                value=""
                                            />
                                            <Form.Check
                                                inline
                                                label={`Visited`}
                                                name="enrolledName"
                                                type="radio"
                                                id={`enrolledBSBGLocationId`}
                                                onChange={(e)=>this.checkEnrolledForLocationBSBG(e,"Y")}
                                                className="f-14 fw-bold color_black"
                                                value="Y"
                                            />
                                            <Form.Check
                                                inline
                                                label={`Not Visited`}
                                                name="enrolledName"
                                                type="radio"
                                                id={`notEnrolledBSBGLocationId`}
                                                onChange={(e)=>this.checkEnrolledForLocationBSBG(e,"N")}
                                                className="f-14 fw-bold color_black"
                                                value="N"
                                                defaultChecked={true}
                                            />
                                            </div>
                                        </Form>   
                                        </Col>
                                    </Row>

                                </>
                            )
                            :
                            ("")
                        )
                    } 



                {
                    this.state.voterCheckedCond == true ? 
                    (
                        <>
                        <Row>
                            <Col>
                                <Button variant="dark" className='f-12 center-auto mt-4 mb-3' onClick={() => this.getHouseWiseVoters()}>GET HOUSE DETAILS</Button> 
                            </Col>
                        </Row>


                        {
                            this.state.boothWiseHouseListLoaderForFV == "show" ?
                            (
                                <Row>
                                    <Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        }
                        {
                            this.state.boothWiseHouseListForFVArr.length>0 ?
                            (
                                
                                <>
                                <Row>
                                    <Col sm={12}>
                                    <h6 className="f-14 fw-bold mt-4">{this.state.boothValue.label} Voter Details : </h6>
                                    
                                    <div className='bg_light_blue mt-3'>
                                    <CardLessDataTableWithSearch 
                                        parentData={this.houseListDisplayDataForFV()}
                                        particlecolumns={this.houseListDisplayColumnsForFV()}
                                        defaultSorted={[{
                                            dataField: "house_no",
                                            order: "asc"
                                        }]}
                                        tableHeading=""
                                        />
                                        
                                            
                                    </div>
                                    
                                    </Col>
                                </Row>
                                </>
                            )
                            :
                            (<>
                                {
                                this.state.boothWiseHouseListCheckForFV == 1 ?
                                (<h6 className='f-14 text-center mt-4'>No Enrollments Done With This Criteria. Change the options and try...</h6>)
                                :
                                ("")
                            }
                            </>)
                        }
                        </>
                    )
                    :
                    (
                        this.state.locationCheckedCond == true ? 
                        (
                            <>
                                <Row>
                                    <Col>
                                        <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getHousesLocations()}>GET HOUSE DETAILS</Button> 
                                    </Col>
                                </Row>

                                {
                                    this.state.houseLocationLoading == "show" ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    ("")
                                }

                                    {
                                        this.state.houseLocationDataCheck == 1 ?
                                    (
                                        <>
                                        <Row>
                                            <Col>
                                            {
                                                ((this.state.boothId != 0))?
                                                (
                                                    <Link to={{
                                                        pathname: "/map_view_houseVisited" ,
                                                        params: {
                                                            boothId:this.state.boothId,
                                                            userLatitudeValue:this.state.userLatitude,
                                                            userLongitudeValue:this.state.userLongitude,
                                                            distanceCalLatLngDataArr:this.state.houseLocationArr,
                                                            userAccessList:this.state.userAccessList,
                                                            campaignId:this.state.campaignId
                                                        }
                                                    }} >
                                                    <h6 className='f-12 float-end map_view_cls mt_1_9'>MAP VIEW <i class="fa-solid fa-map-location-dot"></i></h6>
                                                </Link>
                                                    
                                                )
                                                :
                                                ("")
                                            }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                        
                                            <div className='bg_light_blue mt-3'>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <input
                                                                className='float-end search_bar mt-2'
                                                                type="search"
                                                                placeholder='Search'
                                                                value={this.state.searchNameHouseLocationValue}
                                                                onChange={this.handlesearchNameHouseLocationValueChange}
                                                            />
                                                            <i className='fa fa-search search-icon'></i>
                                                        </Col>
                                                    </Row>

                                                    {
                                                        this.state.useLocationEnabledMessage == "" ?
                                                        (
                                                            <>
                                                            {
                                                                houseLocationManualSearchArr.length>0 ?
                                                                (
                                                                    
                                                                
                                                                    <CardLessDataTableWithOutSearch 
                                                                        parentData={this.boothNearestLocationsData(houseLocationManualSearchArr)}
                                                                        particlecolumns={this.boothNearestLocationsDataColumns()}
                                                                        defaultSorted={[{
                                                                            dataField: "Distance",
                                                                            order: "desc"
                                                                        }]}
                                                                        tableHeading={this.state.tableHeadingName}
                                                                        />
                                                                                    
                                                                                        
                                                                    
                                                                )
                                                                :
                                                                (<h6 className='f-14 text-center mt-4'>No Enrollments Done With This Criteria. Change the options and try...</h6>)
                                                             }
                                                            </>
                                                        )
                                                        :
                                                        (<h6 className='f-14 text-center mt-4'>{this.state.useLocationEnabledMessage}</h6>)
                                                    }

                                                        
                                                </div>
                                                                        
                                            </Col>
                                        </Row>
                                        </>
                                    )
                                    :
                                    ("")
                                    
                                    
                                }
                            </>
                        )
                        :
                        ("")
                    )
                }

                <BSBGHouseWiseVoterListNew 
                        show={this.state.houseWiseVotersDisplayModal}
                        onHide={() => this.setState({ houseWiseVotersDisplayModal: false })}
                        stateData={this.state}
                        houseVisitCaptureClick={this.houseVisitCaptureClick}
                        campaignId={this.state.campaignId}
                />

                <QuestionsSavingPart
                        show={this.state.houseVisitedImageCaptureModal}
                        onHide={() => this.setState({ houseVisitedImageCaptureModal: false })}
                        stateData={this.state}
                        saveCampaignEnrollment={this.saveCampaignEnrollment}
                        handleOnchangeInputBox={this.handleOnchangeInputBox}
                        captureForHouseVisit={this.captureForHouseVisit}
                        removeCapturePhotoFromBSBG={this.removeCapturePhotoFromBSBG}
                        resendOTPNew={this.resendOTPNew}
                        getMobileNoOTP={this.getMobileNoOTP}
                        reloadCam={this.reloadCam}
                        reloadPageForHouseVisit={this.reloadPageForHouseVisit}
                        getOnchangeSelectedKPIAnswer={this.getOnchangeSelectedKPIAnswer}
                />

               
                
               </Container>
               
        )
    }
  }
  
  export default withRouter(ExitPollFamilyVoterSearch);
  