
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import { getAPICallData } from '../../services/MemberService';
import "./VotersListRevision.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class VisitedHousesCount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            boothIdsList:[],
            boothId:"0",
            boothNo:"",
            boothIdValue:"",

            loadingSymbol:"hide",
            successMsg:"",

            verifiedHouses:"",
            verifiedVoters:0,
            form6Collected:0,
            form7Collected:0,
            form8Collected:0,
            fromDate:moment().format("YYYY-MM-DD"),

            validateboothId:"",
            validateVerifyHouse:"",
            
           
        }
        
    }

    componentDidMount() {

        const userAccessObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID
        }
      ); 

      getAPICallData(userAccessObj,"getUserAccessLocationDetails")
          .then((res) => res.json())
          .then((userAccessList) => {
              if(userAccessList != null && userAccessList.status === "Success"){
                if(userAccessList.boothList != null && userAccessList.boothList.length>0){
                    let boothListArr=[];
                    userAccessList.boothList.map(item => {
                        const obj = {
                            value: item.id,
                            label: "Booth No -"+item.name,
                            attr_mapped:item.isMapped
                        }
                        boothListArr.push(obj);
                        
                    });
                    this.setState({ boothIdsList: boothListArr });
                    
                    
                }
            }else{
                this.props.history.push({pathname:"/dashboard"});
                
            }
              

      }); 

    }

    

    handleOnchangeInputBox = (e) => {
        this.setState({validateVerifyHouse:""});
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        
      }

      handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "boothId"){
          let getBoothIdName = this.state.boothIdsList.find((a) => a.value == value);
            this.setState({
              boothIdValue: {
                  value: getBoothIdName.value,
                  label: getBoothIdName.label
              }
          });
    
          this.setState({ boothId: getBoothIdName.value,boothNo:"Booth "+getBoothIdName.label});
        
        }
      }

      handleEvent = (event, picker) => {
        console.log("mmmmmmmmm",moment(picker.startDate).format("YYYY-MM-DD"))
        this.setState({ fromDate: moment(picker.startDate).format("YYYY-MM-DD")});
        
      }

    
      handleOnchangeAllowNumbersInputBox = (e) => {
        this.setState({validateVerifyHouse:""});
        const name = e.target.name;
        const value = e.target.value;
        const re = /^[0-9\b]+$/;

        if (!re.test(e.target.value)) {
            this.setState({validateVerifyHouse:"Please Enter Numbers Only...  ",verifiedHouses:""});
            return;
        }else{
            this.setState({validateVerifyHouse:""});
        }

        
      }


      saveVLRForm = () => {
        this.setState({validateVerifyHouse:"",validateForm7collected:"",validateForm6collected:"",
                        validateVerifyVoters:"",validateboothId:"",validateForm8collected:""
        });
      
        if(this.state.boothId == "" || this.state.boothId == 0){
            this.setState({validateboothId:"Please Select Booth" });
            return;
        }
        if(this.state.verifiedHouses == "" ){
            this.setState({validateVerifyHouse:"Please Enter Verify Houses" });
            return;
        }

        if(this.state.verifiedHouses != ""){
            if(this.state.verifiedHouses > 500){
                this.setState({validateVerifyHouse:"Verified Houses should not more than 500" });
                return;
            }
        }

       

        this.setState({loadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "boothId" : this.state.boothId,
            "date":this.state.fromDate,
            "verifiedHouses" : this.state.verifiedHouses,
            "verifiedVoters" : this.state.verifiedVoters,
            "form6Collected" : this.state.form6Collected,
            "form7Collected" : this.state.form7Collected,
            "form8Collected":this.state.form8Collected

            }); 

            getAPICallData(obj,"updateBoothVerificationStatus")
            .then((res) => res.json())
            .then((data) => {
              this.setState({loadingSymbol:"hide"});  
                if(data !=null && data.status == "Success"){
                    this.setState({successMsg:"Saved SuccessFully..."}); 
                    setTimeout(() => { 
                        this.setState({  
                            verifiedHouses:"",
                            verifiedVoters:"",
                            form6Collected:"",
                            form7Collected:"",boothId:"0",
                            boothNo:"",
                            boothIdValue:"",form8Collected:"",fromDate:moment().format("YYYY-MM-DD"),successMsg:""});     
                       
                    }, 1500);
                }else{
                    this.setState({successMsg:"Some Thing Wrong,Please Check once"});  
                   
                }
          });
      }

      gotoHelpPageForEC = () => {
        this.props.history.push({
            pathname:"/help_page_form",
            params: {
                navBarBottom:"hide",
                userAccessList:this.state.userAccessList
            }
        });
      }
    
  
    render() {

        return (
            <section className='visited_block_css mb-4'>
               <Container className=''>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'>బాబుతో నేను - కార్యక్రమ వివరాలు</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="form-label f-12 fw-bold">Booth<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-12'>{this.state.validateboothId}</span></h6>
                            <SelectBox
                                stateData={this.state.boothIdValue}
                                handleChange={this.handleOnchangeSelectBoxNew}
                                name="boothId"
                                options={this.state.boothIdsList}
                                placeholderName={'Select Booth'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                        </Col>
                    </Row>
                    <Form>
                    <Row>
                        <Col>
                        <h6 className="form-label f-12 fw-bold mt-2">Date <span className='color_red f-9'>{this.state.validateDate}</span></h6>
                        <DateRangePicker
                                singleDatePicker={true}
                                alwaysShowCalendars={true}
                                onEvent={this.handleEvent}
                                className="w-100"
                            >   
                            <div class="input-group">
                                    <div class="input-group-prepend bgColorYash">
                                     <i class="fa fa-calendar pad_5_15"></i>
                                    </div>
                                    <input type="text" className="form-control" value={moment(this.state.fromDate).format("YYYY-MM-DD")}/>
                                </div>
                            </DateRangePicker>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='width_90_static mt-3'> 
                            <h6 className="form-label f-12 fw-bold">Visited Houses Count </h6>
                            <Form.Control type="text" name="verifiedHouses" value={this.state.verifiedHouses}   className='form-control-sm'  placeholder="Enter Visited Houses" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                            <span className='color_red f-9 fw-bold'>{this.state.validateVerifyHouse}</span>
                        </Col>
                        <Col className='width_10_static mt-3'>
                            <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-3 mb-3' onClick={() => this.saveVLRForm()}>SUBMIT
                                {
                                    this.state.loadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                }
                            </Button>
                        </Col>
                    </Row>
                    </Form>
                      <Row>
                            <Col>
                                <h6 className='f-14 fw-bold text-center mt-2'>{this.state.successMsg}</h6>
                            </Col>
                      </Row>
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(VisitedHousesCount);
  