import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,InputGroup } from "react-bootstrap";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };


class VoterIdNotAvailableModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Save Voter</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                        <Row>
                         <Col className='mt-2'>
                            <h6 className="form-label f-12 fw-bold">Voter Name<span className='color_red f-12 ms-1'>*</span> <span className='color_red f-12'>{this.props.stateData.ValidateVoterName}</span></h6>
                            <Form.Control type="text" name="VoterName" value={this.props.stateData.VoterName}  className='form-control-sm'  placeholder="Enter Voter Name" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                         </Col>
                      </Row>
                      <Row>
                            <Col className='mt-2'>
                                <h6 className="form-label f-12 fw-bold">Reference Id<span className='color_red f-12 ms-1'>*</span> <span className='color_red f-12'>{this.props.stateData.ValidateVoterReference}</span></h6>
                                <Form.Control type="text" name="VoterReference" value={this.props.stateData.VoterReference}  className='form-control-sm'  placeholder="Enter Relative Name" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                         </Col>
                         
                         
                      </Row>
                      <Row>
                        {/*<Col className='mt-3'>
                            <h6 className="form-label f-12 fw-bold">Relative Name<span className='color_red f-12 ms-1'>*</span> <span className='color_red f-12'>{this.props.stateData.ValidateVoterRelativeName}</span></h6>
                            <Form.Control type="text" name="VoterRelativeName" value={this.props.stateData.VoterRelativeName}  className='form-control-sm'  placeholder="Enter Relative Name" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
        </Col>*/}
                        <Col className="mt-2">
                            <Form.Group  >
                                <Form.Label className='f-12 fw-bold'>Gender: <span className='color_red f-12 ms-1'>*</span> <span className='color_red f-12 fw-bold'>{this.props.stateData.validateVoterGender}</span></Form.Label>
                                    <select  name="VoterGender"  className='form-select form-select-sm' onChange={(e) => this.props.handleOnchangeInputBox(e)}>
                                        <option value="">Select Gender</option>
                                        <option value="M" >Male</option>
                                        <option value="F">Female</option>
                                    
                                    </select>
                                    
                            </Form.Group>
                        </Col>
                        
                      </Row>

                      <Row>
                    <Col className='mt-2'>
                        <Form>
                            <Form.Group className="" controlId="formMobileId">
                                <Form.Label className='f-12 fw-bold'>Mobile No:<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.props.stateData.validateVolunteerMobile}</span>
                                    {
                                        this.props.stateData.mobileNoLoadingSymbol == "show" ?
                                        (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls1'></Image>)
                                        :
                                        (
                                            ""
                                        )
                                    }
                                    {
                                        this.props.stateData.mobileNoSuccessMsg == "SUCCESS"  ?
                                        (<i class="fa-regular fa-circle-check color_green f-14"></i>)
                                        :
                                        (
                                            this.props.stateData.mobileNoSuccessMsg == "FAIL"  ?
                                            (<i class="fa-regular fa-circle-xmark color_red f-14"></i>)
                                            :
                                            ("")
                                            
                                        )
                                    }
                                    
                                </Form.Label>
                                <Row>
                                    <Col className='width_90_static'> 
                                        <Form.Control type="text" name="volunteerMobile" disabled={this.props.stateData.mobileNoDisabled} className='form-control-sm' maxLength={10} value={this.state.volunteerMobile}  placeholder="Enter Mobile No" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                    </Col>
                                    <Col className='width_10_static'>
                                        
                                        {
                                            this.props.stateData.mobileNoSuccessMsg == "SUCCESS" || this.props.stateData.mobileNoSuccessMsg == ""  ?
                                            (
                                                <>
                                                {
                                                    this.props.stateData.OTPSentStatus == "Fail" || this.props.stateData.OTPSentStatus == "" ?
                                                    (
                                                        <>
                                                        <span className='f-12 color_red'>{this.props.stateData.smsSentMessage}</span>
                                                            <Button variant="dark" className='f-12 float-right  btn-sm' onClick={() => this.props.getMobileNoOTP()}>GET OTP
                                                                {
                                                                    this.props.stateData.getOTPLoadingSymbol == "show" ?
                                                                    (<Image src={loader} alt="loader" className='get_otp_loading_symbol_cls22'></Image>)
                                                                    :
                                                                    ("")
                                                                }  
                                                            </Button> 
                                                        </>
                                                    )
                                                    :
                                                    ("")
                                                }
                                                </>
                                            )
                                            :
                                            (
                                                ""
                                            )
                                        }
                                    </Col>
                                </Row>
                                {
                                    this.props.stateData.mobileNoSuccessMsg == "SUCCESS" || this.props.stateData.mobileNoSuccessMsg == ""  ?
                                    (
                                        ""
                                    )
                                    :
                                    (
                                        <Row>
                                            <Col>
                                                <span className='mt-3 color_red fw-bold f-12 pos_top_m'>This mobile no already registered, use another mobile no</span>
                                            </Col>
                                        </Row>
                                        
                                    )
                                }
                                
                                
                                    
                                    
                            </Form.Group>  
                        </Form>
                        <Form>
                            <Row>
                                
                                <Col className=''>
                                <InputGroup className="mt-3">
                                            <InputGroup.Text id="basic-addon1" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>OTP No<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.props.stateData.validateVolunteerOTP}</span> </InputGroup.Text>
                                            <Form.Control type="text" name="volunteerOTPNO" maxLength={6} className='form-control-sm' value={this.props.stateData.volunteerOTPNO}  placeholder="Enter OTP No" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                 </InputGroup>
                                    
                                </Col>
                                
                            </Row>
                            
                                <Row>
                                    <Col>
                                        {
                                            this.props.stateData.OTPSentStatus == "Success"?
                                            (
                                                <>
                                                    <h6 className='mt-2 f-12 color_green line_height_20'>{this.props.stateData.smsSentMessage} : <b >{this.props.stateData.volunteerMobile}</b></h6>
                                                    
                                                    {
                                                        this.props.stateData.otpVerifiedMessageStatus != "Verified" || this.props.stateData.otpVerifiedMessageStatus == "" ?
                                                        (
                                                            <div className="countdown-text">
                                                                {this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0 ? (
                                                                <h6 className='mt-2 f-14'>
                                                                    Time Remaining: {this.props.stateData.minutes < 10 ? `0${this.props.stateData.minutes}` : this.props.stateData.minutes}:
                                                                    {this.props.stateData.seconds < 10 ? `0${this.props.stateData.seconds}` : this.props.stateData.seconds}
                                                                </h6>
                                                                ) : (
                                                                <h6 className='mt-2 f-12'>Didn't recieve code?</h6>
                                                                )}
                                                                {
                                                                    this.props.stateData.resendOTPLoadingSymbol == "show" ?
                                                                    (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                    :
                                                                    ("")
                                                                } 
                                                                <Button variant="dark" className='f-12 float-right mt-2' 
                                                                disabled={this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0}
                                                                style={{
                                                                    color: this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0 ? "#DFE3E8" : "#fff",
                                                                }} onClick={this.props.resendOTPNew}  >Resend OTP</Button>
                                                                
                                                            </div>
                                                        )
                                                        :
                                                        ("")
                                                    }
                                                
                                                    
                                                </>
                                            )
                                            :
                                            ("")
                                        }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        {
                                                this.props.stateData.otpLoadingSymbol == "show" ?
                                                (<Image src={loader} alt="loader" className='loader_cls_otp ms-2'></Image>)
                                                :
                                                (
                                                    this.props.stateData.otpVerifiedMessageStatus == "Verified" ?
                                                    (

                                                        <h6 class="color_green fw-bold f-14  text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>OTP verified successfully</h6>
                                                        
                                                    )
                                                    :
                                                    (
                                                        this.props.stateData.otpVerifiedMessageStatus == "NotVerified" ?
                                                        (<h6 class="color_red fw-bold f-14 text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>Not Verified,Please Enter Valid OTP</h6>)
                                                        :
                                                        ("")
                                                        
                                                    
                                                    )
                                            )
                                        }
                                    </Col>
                                </Row>
                            
                        </Form>    
                    </Col>
                </Row> 

                      
                      <Row>
                            <Col>
                            {
                                this.props.stateData.mobileNoSuccessMsg == "SUCCESS" && this.props.stateData.otpVerifiedMessageStatus == "Verified" ? 
                                (<Button variant="success" className='center-auto mt-4 f-14 fw-bold btn-sm' disabled={this.props.stateData.disabledButtonBG} onClick={() => this.props.saveVoterIdNotAvilableEnroll()}>SUBMIT
                                    {
                                        this.props.stateData.saveNALoadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                    }

                                </Button>)
                                :
                                (<Button variant="success" className='center-auto mt-4 f-14 fw-bold btn-sm' disabled onClick={() => this.props.saveVoterIdNotAvilableEnroll()}>SUBMIT
                                        {
                                        this.props.stateData.saveNALoadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                        }

                                </Button>)
                            }
                               
                            </Col>
                      </Row>

                      <Row>
                            <Col>
                                <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.successMsgVN}</h6>
                            </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default VoterIdNotAvailableModal;
  

