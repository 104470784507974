
import React ,{ Component } from 'react';
import {Container, Row, Col,Card,Button,Form, FormGroup,InputGroup  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CardLessDataTableWithSearchLimit10 from '../datatables/CardLessDataTableWithSearchLimit10';
  
class VotersFormDashboard extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,

            constituencyValue:"",
            constituencyId:"",
            constituencyListArr:[],

            formsReportArr:[],
            formsReportLoadingSymbol:"hide",
            formsReportDataCheck:0,

            overAllFromDate:"2023-09-01",
            overAllToDate:moment().format("YYYY-MM-DD"),
            pickerChosenLabel:"OverAll",

            overAllFormsBlock:true,
            dateWiseFormsBlock:false,

            dateWiseFromDate:"2023-09-01",
            dateWiseToDate:moment().format("YYYY-MM-DD"),

            dateWiseFormsLoadingSymbol:"hide",
            dateWiseFormsReportArr:[],
            dateWiseformsReportDataCheck:0,
            formTypeFilter:"All"
        }
    }

    componentDidMount() {
        console.log("userAccessListdddd",this.state.chatID)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
            }
           
            this.setState({ constituencyListArr:consArr });
            this.setState({
                constituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },constituencyId:this.state.userAccessList.constituencyList[0].id
        });
          
        this.getFormsDataValidationReport(this.state.userAccessList.constituencyList[0].id,"2023-09-01",moment().format("YYYY-MM-DD"));
        
        }
    }

   

    getFormsDataValidationReport = (value,fromDate,toDate) => {
        this.setState({formsReportLoadingSymbol:"show"})
        const obj =  
            JSON.stringify({ 
                "chatId" : this.state.chatID,
                "constituencyId" : value,
                "fromDate" : fromDate,
                "toDate" : toDate,
                
            }
        ); 
        getAPICallData(obj,"getFormsDataValidationReport")
            .then((res) => res.json())
            .then((data) => {
                this.setState({formsReportLoadingSymbol:"hide"})
                if(data !=null && data.length>0){
                    this.setState({formsReportArr:data,formsReportDataCheck:0})
                }else{
                    this.setState({formsReportArr:[],formsReportDataCheck:1})
                }
            });
    }
    
    handleEventForCubs1 = (event, picker) => {
        this.setState({formsReportArr:[],formsReportDataCheck:0,pickerChosenLabel:picker.chosenLabel})
        console.log(event)
        console.log("start: ",  moment(picker.startDate._d).format('YYYY-MM-DD'));
        console.log("end: ",  moment(picker.endDate._d).format('YYYY-MM-DD'));

        if(picker.chosenLabel == "OverAll"){
            this.setState({ overAllFromDate: "2023-09-01",overAllToDate:moment().format('YYYY-MM-DD')});
            this.getFormsDataValidationReport(this.state.constituencyValue.value,"2023-09-01",moment().format('YYYY-MM-DD'));
        }else{
            this.setState({ overAllFromDate: moment(picker.startDate._d).format('YYYY-MM-DD'),overAllToDate:moment(picker.endDate._d).format('YYYY-MM-DD')});
            this.getFormsDataValidationReport(this.state.constituencyValue.value,moment(picker.startDate._d).format('YYYY-MM-DD'),moment(picker.endDate._d).format('YYYY-MM-DD'));
        }

        

      };

      dateWiseOnchangeEvent = (event, picker) => {
        this.setState({dateWiseFormsReportArr:[],dateWiseformsReportDataCheck:0,pickerChosenLabel:picker.chosenLabel,formTypeFilter:"All"})

        console.log(picker)
        console.log("start: ",  moment(picker.startDate._d).format('YYYY-MM-DD'));
        console.log("end: ",  moment(picker.endDate._d).format('YYYY-MM-DD'));

        if(picker.chosenLabel == "OverAll"){
            this.setState({ dateWiseFromDate: "2023-09-01",dateWiseToDate:moment().format('YYYY-MM-DD')});
            this.getDateWiseFormsStatus("All","2023-09-01",moment().format('YYYY-MM-DD'));
        }else{
            this.setState({ dateWiseFromDate: moment(picker.startDate._d).format('YYYY-MM-DD'),dateWiseToDate:moment(picker.endDate._d).format('YYYY-MM-DD')});
            this.getDateWiseFormsStatus("All",moment(picker.startDate._d).format('YYYY-MM-DD'),moment(picker.endDate._d).format('YYYY-MM-DD'));
        }
       
        

      }
     

      showFromsBlockType = (type) => {
        if(type == "overAll"){
            this.setState({overAllFormsBlock:true,dateWiseFormsBlock:false});
            this.setState({ overAllFromDate: "2023-09-01",overAllToDate:moment().format('YYYY-MM-DD')});
            
            setTimeout(() => { 
                this.getFormsDataValidationReport(this.state.constituencyValue.value,"2023-09-01",moment().format('YYYY-MM-DD'));
            }, 500);


        }else{
            this.setState({overAllFormsBlock:false,dateWiseFormsBlock:true})
            this.setState({ dateWiseFromDate: "2023-09-01",dateWiseToDate:moment().format('YYYY-MM-DD')});

            setTimeout(() => { 
                this.getDateWiseFormsStatus("All","2023-09-01",moment().format('YYYY-MM-DD'));
            }, 500);
        }
      }

      handleOnchangeSelectBox = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });

        this.getDateWiseFormsStatus(value,this.state.dateWiseFromDate,this.state.dateWiseToDate);
    }

      getDateWiseFormsStatus = (filterVal,fromDate,toDate) => {
        this.setState({dateWiseFormsLoadingSymbol:"show"})
        const obj =  
            JSON.stringify({ 
                "chatId" : this.state.chatID,
                "constituencyId" : this.state.constituencyValue.value,
                "fromDate" : fromDate,
                "toDate" : toDate,
                
            }
        ); 
        getAPICallData(obj,"getDateWiseFormsStatus")
            .then((res) => res.json())
            .then((data) => {
                this.setState({dateWiseFormsLoadingSymbol:"hide"})
                if(data !=null && data.length>0){
                    
                    if(filterVal == "All"){
                        this.setState({dateWiseFormsReportArr:data,dateWiseformsReportDataCheck:0})
                    }else{
                        let result = data.filter(item => item.formType == filterVal);
                        this.setState({dateWiseFormsReportArr:result,dateWiseformsReportDataCheck:0})
                    }
                    
                }else{
                    this.setState({dateWiseFormsReportArr:[],dateWiseformsReportDataCheck:1})
                }
            });
      }

      sortFuncDateVF(a, b, order, dataField, rowA, rowB)  { 

        if (order == 'desc') {

            return moment(rowB.Date, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.Date, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.Date, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.Date, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
        
       
    }

      dateWiseformsReporColumns() {
        let columns = [
            
            {
                dataField: "Date",
                text: "Date",
                sort: true,
                sortFunc: this.sortFuncDateVF.bind(this),
                headerStyle: {
                    width: "20%"
                }
            },
            {
                dataField: "formType",
                text: "Form",
                sort: true,
                headerStyle: {
                    width: "20%"
                }
            },
            {
                dataField: "Total",
                text: "T",
                sort: true,
            },
            {
                dataField: "Pending",
                text: "P",
                sort: true,
            },
           
            {
                dataField: "Updated",
                text: "U",
                sort: true,
            },
           
            {
                dataField: "Valid",
                text: "V",
                sort: true,
            },
           
            {
                dataField: "InValid",
                text: "In-V",
                sort: true,
            }
         
        ];
        return columns;
    }

    dateWiseformsReportData = () =>{
        let dataArr = [];
            if(this.state.dateWiseFormsReportArr.length>0){
                
                for (let i = 0; i < this.state.dateWiseFormsReportArr.length; i++) {
    
                    const Date = (this.state.dateWiseFormsReportArr[i].receiptDate);
                    const Total = (this.state.dateWiseFormsReportArr[i].total);
                    const Pending = (this.state.dateWiseFormsReportArr[i].pending);
                    const Updated = (this.state.dateWiseFormsReportArr[i].updated);
                    const Valid = (this.state.dateWiseFormsReportArr[i].valid);
                    const InValid = (this.state.dateWiseFormsReportArr[i].invalid);
                    const formType = (this.state.dateWiseFormsReportArr[i].formType);
                    
                    const obj = {
                        Date:Date ,
                        Total:Total ,
                        Pending:Pending  ,
                        Updated:Updated ,
                        Valid:Valid ,
                        InValid: InValid,
                        formType:formType
                       
                        
                    }
                    dataArr.push(obj);
                } 
    
                
                
                return dataArr;
            }
    }

   

    rangeForForms =  {
        "Today": [moment(), moment()],
        "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "OverAll": [moment("20230901"), moment()],
      };

    render() {
        return (
            <section>
               <Container>
                    <Row>
                        <Col>
                            <h6 className='text-center fw-bold ln-28 f-14'>Forms Data Validation Report : <br/>{this.state.constituencyValue.label} Assembly</h6>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} className='mt-4'>
                            <div className='flex_programs_block pos_top_cls'>
                                <div className={this.state.overAllFormsBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.showFromsBlockType("overAll")}><h6 className='fw-bold  text-center'><i class="fa-solid fa-gauge"></i> <span className='ms-2'>OVERALL</span></h6></div>
                                <div className={this.state.dateWiseFormsBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.showFromsBlockType("date")}><h6 className='fw-bold text-center'><i class="fa-solid fa-calendar-days"></i><span className='ms-2'>DATE WISE</span></h6></div>
                            </div>
                        </Col>
                    </Row>

                
                    {
                        this.state.dateWiseFormsBlock == true ? 
                        (
                            <>
                            <Form>
                                <FormGroup controlId='dateRangeFormsId1'>
                                    <Row>
                                        <Col>
                                            <h6 className="form-label f-12 fw-bold mt-1">Date Range :</h6>
                                                <DateRangePicker
                                                    alwaysShowCalendars={true}
                                                    ranges = {this.rangeForForms}
                                                    onEvent={this.dateWiseOnchangeEvent}
                                                    className="w-100"
                                                >   
                                                <div class="input-group">
                                                    <div class="input-group-prepend bgColorYash">
                                                        <i class="fa fa-calendar pad_5_15"></i>
                                                    </div>
                                                    <input type="text" className="form-control" id="dateRangeFormsId1" value={moment(this.state.dateWiseFromDate).format("YYYY-MM-DD") +" to "+ moment(this.state.dateWiseToDate).format("YYYY-MM-DD")} />
                                                </div>
                                                </DateRangePicker>
                                            </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <InputGroup className="mt-3">
                                            <InputGroup.Text id="basic-addon1" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>Select Form Type: </InputGroup.Text>
                                            <select id="formTypeFilterId" name="formTypeFilter" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                                        {
                                                            this.state.formTypeFilter =="All" ?
                                                            (
                                                                <>
                                                                <option value="All" selected>All</option>
                                                                <option value="Form-6">Form-6</option>
                                                                <option value="Form-7">Form-7</option>
                                                                <option value="Form-8">Form-8</option>
                                                                <option value="Form-8A">Form-8A</option>
                                                                <option value="Form-8B">Form-8B</option></>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                <option value="All">All</option>
                                                                <option value="Form-6">Form-6</option>
                                                                <option value="Form-7">Form-7</option>
                                                                <option value="Form-8">Form-8</option>
                                                                <option value="Form-8A">Form-8A</option>
                                                                <option value="Form-8B">Form-8B</option>
                                                                </>
                                                            )
                                                        }
                                                        
                                                </select>
                                        </InputGroup>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.dateWiseFormsReportArr.length == 0 ?
                                        (
                                            this.state.dateWiseformsReportDataCheck == 1 ?
                                            (<Col className='f-14 text-center'>No Data Available</Col>)
                                            :
                                            (<Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>)
                                        ):
                                        (
                                            this.state.dateWiseFormsReportArr.length>0 ?
                                                    (
                                                        <>
                                                        <Row>
                                                            <Col >
                                                            <h6 className='f-12 mt-3 fw-bold color_red'>
                                                                Note : <span className='ml_5 mr_2'>T - Total,</span>
                                                                        <span className='ml_5 mr_2'>P - Pending,</span>
                                                                        <span className='ml_5 mr_2'>U - Updated,</span>
                                                                        <span className='ml_5 mr_2'>V - Valid</span>
                                                                        <span className='ml_5 mr_2'>In-V - InValid</span>
                                                            </h6>
                                                        
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                            <div className='p-0' style={{position: "relative",top: "-16px"}}>
                                                                
                                                                <CardLessDataTableWithSearchLimit10 
                                                                    parentData={this.dateWiseformsReportData()}
                                                                    particlecolumns={this.dateWiseformsReporColumns()}
                                                                    defaultSorted={[{
                                                                        dataField: "Updated",
                                                                        order: "desc"
                                                                    }]}
                                                                    tableHeading={<><h6 className='f-14 fw-bold'>Date Wise Report -  {this.state.formTypeFilter == "All" ? "All Forms" : this.state.formTypeFilter}</h6></>} 
                                                                    />
                                                                    
                                                                    
                                                            </div>
                                                            
                                                            </Col>
                                                        </Row>
                                                        </>
                                            ):("")
                                        )
                                    }
                                </FormGroup>
                            </Form>
                           
                            

                           

                            </>
                           
                        )
                        :
                        (
                            this.state.overAllFormsBlock == true ? 
                            (
                                <> 
                                
                                <Row>
                                    <Col>
                                    <h6 className="form-label f-12 fw-bold mt-1">Date Range :</h6>
                                        <DateRangePicker
                                            alwaysShowCalendars={true}
                                            ranges = {this.rangeForForms}
                                            onEvent={this.handleEventForCubs1}
                                            className="w-100"
                                        >   
                                        <div class="input-group">
                                            <div class="input-group-prepend bgColorYash">
                                                <i class="fa fa-calendar pad_5_15"></i>
                                            </div>
                                            <input type="text" className="form-control" id="dateRangeFormsId" value={moment(this.state.overAllFromDate).format("YYYY-MM-DD") +" to "+ moment(this.state.overAllToDate).format("YYYY-MM-DD")} />
                                        </div>
                                        </DateRangePicker>
                                    </Col>
                                </Row>
                                
                                
                                {
                                    this.state.formsReportLoadingSymbol == "show" ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    ("")
                                }
                                {
                                this.state.formsReportArr.length>0 ? 
                                (
                                    <Row>
                                        <Col>
                                            {
                                                this.state.pickerChosenLabel == "OverAll" || this.state.pickerChosenLabel == "Today" || this.state.pickerChosenLabel == "Yesterday" || this.state.pickerChosenLabel == "Last 7 Days" ?
                                                ( <h6 className='f-14 fw-bold mt-3'>{this.state.pickerChosenLabel} Status : </h6>)
                                                :
                                                (
                                                    <h6 className='f-14 fw-bold mt-3'>Custom Range Date : {this.state.fromDateDateRange} to {this.state.toDateDateRange} </h6>
                                                )
                                            }
                                        
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }
    
                                {
                                    this.state.formsReportArr.length>0 ? 
                                    (
                                        this.state.formsReportArr.map((item,index) => {
                                            return(
                                                <Row >
                                                    <Col >
                                                        <Card className='mt-2 border_black'>
                                                            <Card.Body className='p-1'>
                                                                <h6 className='f-12 fw-bold mt-2 mb-2 ms-2'>{item.formType} :</h6>
                                                                
                                                                <Row>
                                                                <Col>
                                                                        <div className=''>
                                                                            <h6 className='f-12 text-center'>Pending</h6>
                                                                            <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>{item.pending}</h6>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className=''>
                                                                            <h6 className='f-12 text-center'>Updated</h6>
                                                                            <h6 className='f-14 mt-2 text-center color_green fw-bold'>{item.updated}</h6>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className=''>
                                                                            <h6 className='f-12 text-center'>Valid</h6>
                                                                            <h6 className='f-14 mt-2 text-center color_green fw-bold'>{item.valid}</h6>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className=''>
                                                                            <h6 className='f-12 text-center'>Not Valid</h6>
                                                                            <h6 className='f-14 mt-2 text-center color_red fw-bold'>{item.invalid}</h6>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                            
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                this.state.formsReportDataCheck == 1 ?
                                                (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                                :
                                                ("")
                                            }
                                        </>
                                    )
                                }
    
    
                                </>
                                    
                            )
                            :
                            ("")
                        )
                    }
                    
                    
               </Container>
               </section>
               
        )
    }
  }
  
  export default withRouter(VotersFormDashboard);
  