import React, { Component } from 'react'
import { Modal,Row,Col } from "react-bootstrap";
import moment from 'moment';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';


class ViewInfluencer extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    viewinfluencersDisplayColumns() {
        let columns = [
            
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                formatter: this.actionsFormatterForVoewInfName.bind(this),
                headerStyle: {
                    width: "50%"
                }
                
                
            },
            {
                dataField: "influencer_votes",
                text: "Influencer Votes",
                sort: true,
                
            },
            {
                dataField: "inserted_time",
                text: "Inserted Time",
                sort: true,
                
            }
        
        ];
        return columns;
    }

    
    viewinfluencersDisplayData = () =>{
    let viewInfData = [];
        if(this.props.stateData.viewinfluencersArr.length>0){
            
            for (let i = 0; i < this.props.stateData.viewinfluencersArr.length; i++) {

                const Name = (this.props.stateData.viewinfluencersArr[i].personName);
                const influencer_votes = (this.props.stateData.viewinfluencersArr[i].influencingVotes);
                const inserted_time = moment(this.props.stateData.viewinfluencersArr[i].insertedTime).format('MMM Do YYYY');
                const partyName = (this.props.stateData.viewinfluencersArr[i].partyName);
                const contactNo = (this.props.stateData.viewinfluencersArr[i].contactNo);
                const influencingTypeName = (this.props.stateData.viewinfluencersArr[i].influencingTypeName);
                const influencingLevelValue = (this.props.stateData.viewinfluencersArr[i].influencingLevelValue);
                const description = (this.props.stateData.viewinfluencersArr[i].description);
                const partyId = (this.props.stateData.viewinfluencersArr[i].partyId);
                
                const obj = {
                    Name:(Name),
                    influencer_votes:influencer_votes,
                    inserted_time: inserted_time,
                    partyName: partyName,
                    contactNo: contactNo,
                    influencingTypeName:influencingTypeName,
                    influencingLevelValue:influencingLevelValue,
                    description:description,
                    partyId:partyId
                    
                }
                viewInfData.push(obj);
            } 

            
            
            return viewInfData;
        }
}

actionsFormatterForVoewInfName = (cell, row, rowIndex, formatExtraData) => {
    let partyval = this.props.stateData.party_ids_Arr.filter(item => item.value == row.partyId).map(item2 => item2.label)
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Name)} 
           <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Party - {(partyval)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Influencing Type - {(row.influencingTypeName)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Influencing Value - {(row.influencingLevelValue)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Mobile - {(row.contactNo)}</span></>
        </span>
     );
}
    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>View Influencer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">

                        {
                            this.props.stateData.viewinfluencersArr.length == 0 ?
                            (
                                this.props.stateData.viewinfluencersCheck == 1 ?
                                (<Col className='f-14 text-center'>No Data Available</Col>)
                                :
                                (<Col sm={12}>
                                  <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>)
                            )
                            :
                            (
                              this.props.stateData.viewinfluencersArr.length>0 ?
                                (
                                    <Row>
                                        <Col sm={12}>
                                        
                                        <div className='bg_light_blue mt-3 p-0'>
                                    
                                        <CardLessDataTableWithSearch 
                                            parentData={this.viewinfluencersDisplayData()}
                                            particlecolumns={this.viewinfluencersDisplayColumns()}
                                            defaultSorted={[{
                                                dataField: "influencer_votes",
                                                order: "asc"
                                            }]}
                                            tableHeading=""
                                        />
                                                
                                        </div>
                                        
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            )
                          }
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default ViewInfluencer;
  

