import React, {Component } from "react";
import Webcam from "react-webcam";
import {Container, Row, Col,Form,Button,Image,Card  } from 'react-bootstrap';
import { getCampaignAPICallData } from '../../services/MemberService';
import loader from '../../images/loader.gif';
import { upload } from "@testing-library/user-event/dist/upload";
import { withRouter,Link } from "react-router-dom";
import events_logo from '../../images/events_logo.png';
import events_name from '../../images/events_name.png';
import { scroller } from "react-scroll";
import moment from 'moment';
import { reactLocalStorage } from "reactjs-localstorage";

class LiveEventPhotosCapture extends Component {
  constructor(props) {
      super(props)
      this.webcamRef = React.createRef();
      this.state = {
        showImgCapture:true,
        successMsg:"",
        loaderSymbol:"hide",
        theArray:[],
        videoConstraints:{facingMode: "environment"},
        selectedTypePhoto:"camara",
        uploadType:this.props?.location?.params?.uploadType == undefined ? "" : this.props?.location?.params?.uploadType,
        imageAddedMsg:"",
        checkNoOfCaptureImagesArr:[],
        CptureImageCountMsg:"",
        selectedCapturePhotoArr:[],
        disabledUploadButton:false
        
      }
      this.uploadMultipleFilesForPhotos = this.uploadMultipleFilesForPhotos.bind(this)
      
  }

  componentDidMount() {
    if(this.props?.location?.params?.event == undefined){
        this.props.history.push({pathname:"/events",params: { navbarDisplay:"hide"}});
    }
  }

  selectCaptureType = (type) => {
    this.setState({theArray:[],checkNoOfCaptureImagesArr:[],selectedCapturePhotoArr:[]});
    
    if(type == "upload"){
      this.setState({showImgCapture:false});
    }else{
      this.setState({showImgCapture:true});
    }
    
    this.setState({selectedTypePhoto:type});
  }

   capture = () => {
      this.setState({showImgCapture:true,imageAddedMsg:"",CptureImageCountMsg:""});
      const getUID = (length=8) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

        var obj={
          fileId:0,
          fileName : getUID(),
          fileType : "capture",
          data : this.webcamRef.current.getScreenshot(),
          checked:false
        }

        this.setState({theArray:[...this.state.theArray, obj]});    
        scroller.scrollTo("your_css_class_here", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
      });
        this.setState({imageAddedMsg:"Capture Photo added successfully in below..."});
        setTimeout(() => {
          this.setState({imageAddedMsg:""});
      }, 2000);
};


    toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

    uploadMultipleFilesForPhotos(e) {
      this.setState({disabledUploadButton:true });

      let fileObj=[];
      fileObj.push(e.target.files);
          for (let i = 0; i < fileObj[0].length; i++) {
              this.toDataURL(URL.createObjectURL(fileObj[0][i]))
              .then(dataUrl => {
                  var obj ={
                      fileId:i,
                      fileName:fileObj[0][i].name,
                      fileType:fileObj[0][i].type,
                      data:dataUrl,
                      checked:false
                  }
                  this.setState({theArray:[...this.state.theArray, obj]});
              });
          }
    }

    
    saveLiveEventPhotos = () => {
      this.setState({disabledUploadButton:false });
      setTimeout(() => {
          if(this.state.selectedTypePhoto == "camara"){
            this.setState({CptureImageCountMsg: ""});
            var currentTimeStamp = moment().unix();
            console.log(reactLocalStorage.getObject("isUserUploaded"));
            if(reactLocalStorage.getObject("isUserUploaded") != ""){
              if(currentTimeStamp < reactLocalStorage.getObject("isUserUploaded"))
                {   //console.log("your condition is meeted. Do your ACTION");
                    this.setState({CptureImageCountMsg: "PLease Upload Photos After 4 Hours..."});
                    return;
                }
            }
            
            if(this.state.checkNoOfCaptureImagesArr.length == 0 ){
              this.setState({CptureImageCountMsg: "Please Select Atleast One Photos..."});
              return;
            }
          }
        
          
          this.setState({ loaderSymbol: "show" });
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.ShowSaveLiveEventPhotosPosition,this.ShowLocationPermissionError)
            }else{
                alert("An unknown error occurred")
            }
      }, 500); 
      
        
        
    }

    ShowSaveLiveEventPhotosPosition = (position) =>{
      
      
      let uploadFormsArr=[];
      let eventTypePhotos="";
        if(this.state.uploadType == "media"){
          eventTypePhotos = "Media Coverage"
        }else{
          eventTypePhotos = "Photos"
        }

        if(this.state.selectedCapturePhotoArr.length >0 ){
          for (let i = 0; i < this.state.selectedCapturePhotoArr.length; i++) {
            if(this.state.selectedCapturePhotoArr[i].fileType == "capture"){
              var obj={
                  "fileName" : this.state.selectedCapturePhotoArr[i].fileName,
                  "fileType" : "jpeg",
                  "eventType" : eventTypePhotos,
                  "latitude":position.coords.latitude,
                  "longitude":position.coords.longitude,
                  "base64Str" :this.state.selectedCapturePhotoArr[i].data.replace("data:image/jpeg;base64,","")
              }
              uploadFormsArr.push(obj);
            }
          }
        }else{
          for (let i = 0; i < this.state.theArray.length; i++) {
              if(this.state.theArray[i].fileType != "capture"){
                var imageBase64 = this.state.theArray[i].data.replace("data:"+this.state.theArray[i].fileType+";base64,","");
                var splitFileType = this.state.theArray[i].fileType.split("/");
                var obj={
                  "fileName" : this.state.theArray[i].fileName,
                  "fileType" : splitFileType[1],
                  "eventType" : eventTypePhotos,
                  "latitude":position.coords.latitude,
                  "longitude":position.coords.longitude,
                  "base64Str" :imageBase64
              }
                uploadFormsArr.push(obj);
              }
          }
        }
        

       
      setTimeout(() => {
        if(this.state.selectedTypePhoto == "camara"){
         // reactLocalStorage.clear();
        }
          if(uploadFormsArr.length>0){
              const obj =  
                  JSON.stringify({ 
                      "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                      "campaignDetailsId":this.props?.location?.params?.campaignDetailsId == undefined ? "" : this.props?.location?.params?.campaignDetailsId,
                      "files":uploadFormsArr
                      
                  });
              getCampaignAPICallData(obj,"uploadCampaignMedia")
                  .then((res) => res.json())
                  .then((data) => {
                      this.setState({ loaderSymbol: "hide" });
                      if(data !=null && data.status == "Success"){
                          this.setState({successMsg:"Uploaded SuccessFully..."});
                          if(this.state.selectedTypePhoto == "camara"){
                            let utcDate = moment().add(4, 'hours').format('LLL');
                            var After2MinTimeStamp = moment(utcDate, "MMM DD YYYY hh:mm:ss a").unix();
                            
                            reactLocalStorage.setObject("isUserUploaded", After2MinTimeStamp);
                          }
                          setTimeout(() => {
                              this.setState({successMsg:"",disabledUploadButton:false});
                              this.props.history.push({
                                pathname: "/ongoing_ready_event",
                                params: {
                                    navbarDisplay:"hide",
                                    event:this.props?.location?.params?.event == undefined ? "" : this.props?.location?.params?.event,
                                    eventStatus:this.props?.location?.params?.eventStatus == undefined ? "" : this.props?.location?.params?.eventStatus,
                                    campaignDetailsId:this.props?.location?.params?.campaignDetailsId == undefined ? "" : this.props?.location?.params?.campaignDetailsId,
                                    eventType:this.props?.location?.params?.eventType == undefined ? "" : this.props?.location?.params?.eventType,
                                    isAttended:this.props?.location?.params?.isAttended == undefined ? "" : this.props?.location?.params?.isAttended,
                                    surveyType:this.props?.location?.params?.surveyType == undefined ? "" : this.props?.location?.params?.surveyType,
                                    pollManagementPage:this.props?.location?.params?.pollManagementPage == undefined ? "" : this.props?.location?.params?.pollManagementPage,
                                    MLCPage:this.props?.location?.params?.MLCPage == undefined ? "" : this.props?.location?.params?.MLCPage
                          
                                  },
                              });
                          }, 2000);
                      }else{
                          this.setState({successMsg:"Something Wrong,Please Try Again..."});
                      }
              });
            }
      }, 500);
    }

    ShowLocationPermissionError = (error) => {
      switch (error.code){
          case error.PERMISSION_DENIED:
            alert("User Location Permission is Denied,Please Enable Location Services...(Path:Settings > Privacy > Location Services)");
            break;

            case error.POSITION_UNAVAILABLE:
            alert("User Postion Unavailable Denied");
            break;

            default:
              alert("An unknown error occurred")
      }
  }

    switchCamara = () => {
      this.setState({videoConstraints:{facingMode: "environment"}});
    }
    frontCamara = () => {
      this.setState({videoConstraints:{facingMode: "user"}});
    }


    validateTwoImagesForUpload = (e,selectedCaptureItem) => {
      var value = e.target.value;
      
      if (e?.target?.checked == true) {
          if(this.state.checkNoOfCaptureImagesArr.length == 1){
            this.setState({CptureImageCountMsg: "Maximum Images Selected Reachecd..."});
            return;
        }
        this.setState({disabledUploadButton:true});
        const index = this.state.theArray.findIndex(item => item.fileName == selectedCaptureItem.fileName);
        this.state.theArray[index].checked = true;
        const selectedImageArr = this.state.theArray.filter(item => item.fileName == selectedCaptureItem.fileName);
        this.setState({selectedCapturePhotoArr: selectedImageArr});
        this.state.checkNoOfCaptureImagesArr.push(value);
        console.log("this.state.theArray",selectedImageArr)
        
      }else{
        const index = this.state.theArray.findIndex(item => item.fileName == selectedCaptureItem.fileName);
        this.state.theArray[index].checked = false;
        this.setState({theArray: this.state.theArray});

        for( var i in this.state.checkNoOfCaptureImagesArr){ 
            if ( this.state.checkNoOfCaptureImagesArr[i] == value) { 
                this.state.checkNoOfCaptureImagesArr.splice(i, 1);
                this.setState({checkNoOfCaptureImagesArr: this.state.checkNoOfCaptureImagesArr}); 
            }
        
        } 
      }

      console.log("checkNoOfCaptureImagesArr",this.state.checkNoOfCaptureImagesArr)
    }


  render() {
    console.log("disabledUploadButton",this.state.disabledUploadButton)
      return (
        <section>
           <div className=''>
                <div className='flex_event_name_top'>
                  <Link to={{pathname: "/ongoing_ready_event",
                        params: { 
                              navbarDisplay:"hide",
                              event:this.props?.location?.params?.event == undefined ? "" : this.props?.location?.params?.event,
                              eventStatus:this.props?.location?.params?.eventStatus == undefined ? "" : this.props?.location?.params?.eventStatus,
                              campaignDetailsId:this.props?.location?.params?.campaignDetailsId == undefined ? "" : this.props?.location?.params?.campaignDetailsId,
                              eventType:this.props?.location?.params?.eventType == undefined ? "" : this.props?.location?.params?.eventType,
                              isAttended:this.props?.location?.params?.isAttended == undefined ? "" : this.props?.location?.params?.isAttended,
                              surveyType:this.props?.location?.params?.surveyType == undefined ? "" : this.props?.location?.params?.surveyType,
                              pollManagementPage:this.props?.location?.params?.pollManagementPage == undefined ? "" : this.props?.location?.params?.pollManagementPage,
                              MLCPage:this.props?.location?.params?.MLCPage == undefined ? "" : this.props?.location?.params?.MLCPage
                    
                        }}}> <i class="fa-solid fa-arrow-left color_black"></i></Link>
                  <Image src={events_logo} alt="event logo" ></Image>
                  <Image src={events_name} alt="event Name" ></Image>
                    
              </div>
            </div>
            <div className='bg_red_events color_white fw-bold f-12'>
              {
                this.state.uploadType == "media" ?
                (<span>Upload Media Coverage Photos</span>)
                :
                (<span>Upload Event Photos</span>)
              }
              
            </div>
            <Container>
                <Row>
                  <Col sm={12}>
                      <Form className="text-center mt-3">
                          {
                              this.state.uploadType == "media" ?
                              (<Form.Check
                                inline
                                label="Upload Media Coverage Photos"
                                name="image_capture"
                                type="radio"
                                id="upload_photos"
                                onChange={()=>this.selectCaptureType("upload")}
                                className="f-14 fw-bold"
                              />)
                              :
                              ("")
                            }
                            
                              <Form.Check
                                inline
                                label={this.state.uploadType == "photos" ? "Take a photo of Upload Program" : "Take a photo of Media Coverage"}
                                name="image_capture"
                                type="radio"
                                id="camara_photos"
                                defaultChecked={true}
                                onChange={()=>this.selectCaptureType("camara")}
                                className="f-14 fw-bold"
                              />
                      </Form>
                  </Col>
                </Row>
                {
                  this.state.selectedTypePhoto == "camara" ?
                  (
                    <Row>
                      <Col className="mt-2">
                        <div className="showImgCaptureDiv">{this.state.showImgCapture && (
                        <> 
                          <Webcam
                            audio={false}
                            ref={this.webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={this.state.videoConstraints}
                          /> 
                          
                          
                          <div className="row mt-2">
                              <div className="col">
                                <h6 onClick={() => this.capture()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                              </div>
                              <div className="col">
                                {
                                  this.state.videoConstraints.facingMode == "user" ?
                                  (<h6 className="switch_camara text-center" onClick={() => this.switchCamara()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                  :
                                  ( <h6 className="switch_camara text-center" onClick={() => this.frontCamara()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                }
                              </div>
                          </div>
                          {
                            this.state.imageAddedMsg != "" ?
                            (<h6 className="f-14 fw-bold text-center color_green">{this.state.imageAddedMsg}</h6>)
                            :
                            ("")
                          }
                          </>
                        
                        )}
                        </div>
                      </Col>
                  </Row>
                  )
                  :
                  (
                    <Row>
                        <Col className="mt-2">
                          <div className="form-group">
                            <h6 className='f-12 color_red'></h6>
                            <label for="uploadfilesForLive" class="drop-container">
                                <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                <input type="file" id="uploadfilesForLive" className='f-14'  multiple onChange={this.uploadMultipleFilesForPhotos}/>
                            </label>
                        </div>
                        </Col>
                    </Row>
                  )
                }
                <div className="your_css_class_here">

               
                {
                  this.state.theArray.length>0 ?
                  (
                    <Container>
                      <Row>
                        <Col sm={12} className="mt-2">
                            {
                              this.state.selectedTypePhoto == "camara" ? 
                              (<h6>Capture Live Photos : </h6>)
                              :
                              (<h6>Upload Files: </h6>)
                            }
                            
                        </Col>
                        <Col sm={12} className="">
                            <div className='flex_img'>
                                {(this.state.theArray || []).map((url,index) => (
                                    <div className='ms-2'>
                                        {
                                          this.state.selectedTypePhoto == "camara" ? 
                                          (
                                            <>
                                              <Card className="bg-dark text-white width_100 mt-2">
                                                <Card.Img src={url.data} alt="Card image" />
                                                <Card.ImgOverlay className="pos_capture_image">
                                                    <Form className="">
                                                          <Form.Check
                                                            name="capture_check_photo"
                                                            type="checkbox"
                                                            id={`default-${index}`}
                                                            className="fw-bold float-end border_red_dark"
                                                            onChange={(e)=>this.validateTwoImagesForUpload(e,url)}
                                                            value={`image-${index}`}
                                                            checked={url.checked}
                                                          />
                                                    </Form>
                                                </Card.ImgOverlay>
                                              </Card>
                                            </>
                                          )
                                          :
                                          (<object type={url.fileType} data={url.data} alt="image" className='img_width_height' />)
                                        }
                                        
                                        
                                    </div>
                                ))} 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                            <h6 className="text-center color_red f-14 mt-2">{this.state.CptureImageCountMsg}</h6>
                      </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                          {
                            this.state.disabledUploadButton == true ?
                            (<Button variant="danger" className="red_button_event center-auto mt-3" onClick={() => this.saveLiveEventPhotos()}>Upload </Button>)
                            :
                            (<Button variant="danger" disabled className="red_button_event center-auto mt-3" onClick={() => this.saveLiveEventPhotos()}>Upload </Button>)
                          }
                            
                            {
                                this.state.loaderSymbol == "show" ? 
                                (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                :
                                ("")
                            }
                            <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.state.successMsg}</h6>
                        </Col>
                    </Row>
                  </Container>
                  )
                  :
                  ("")
                }
                </div>
                
            </Container>
        </section>
      )
  }
}

export default withRouter(LiveEventPhotosCapture);
