
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import { getAPICallData } from '../../services/MemberService';
import "./VotersListRevision.css";
import { withRouter,Link } from "react-router-dom";



class HelpPageForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
           
           
        }
        
    }

    componentDidMount() {
        let userAccessList = this.state.userAccessList;
        if(userAccessList == null || userAccessList == undefined  ){
            this.props.history.push({pathname:"/dashboard"});
        }
    }

    
    render() {

        return (
            <section>
                 
               <Container className=''>
               
                <Row>
                    <Col>
                        <Link to={{pathname: "/voters_list_revision",
                                    params:{
                                        userAccessList:this.state.userAccessList
                                    }}}> 
                            <Button variant="outline-dark pad5_14 color_black float-right" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Link>
                    </Col>
                </Row>
               <Row>
                    
                    <Col>
                        <div className='bg_red_logo mt-3 p-3'>
                            <h6 className='color_white'>General instructions in respect of </h6>
                                <ul className='instructions_list'>
                                    <li>Form 6 For Additions</li>
                                    <li>Form 7 For Deletion</li>
                                    <li>Form 8 For Modification</li>
                                </ul>
                            <h6 className='color_white'>for guidance of Registration Authorities.</h6>
                            <Row>
                                <Col>
                                    
                                    <h6 className="ms-4 f-14 color_white mt-4">View PDF :
                                        <Link to={{
                                                    pathname: "/pdf_viewer" ,
                                                    params: {
                                                        navbarDisplay:"hide",
                                                        pdf_url:"https://www.mypartydashboard.com/DocFiles/Guidance_of_Registration_Form.pdf",
                                                        navigate:"help_form",
                                                        userAccessList:this.state.userAccessList,
                                                    }
                                                }} className="color_white fw-bold ms-2 hover_color">
                                                Guidance of Registration
                                            </Link>
                                            
                                    </h6>

                                </Col>
                            </Row>
                            
                        </div>
                            
                    </Col>
                </Row>
                   
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(HelpPageForm);
  