import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';


class HouseWiseVoterListModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
      
    }

    
    hoseWiseVoterListDisplayColumns() {
    let columns = [
        
        {
            dataField: "serial_no",
            text: "Serial No",
            sort: true,
            
            
            
        },
        {
            dataField: "voter_name",
            text: "Voter Name",
           // sort: true,
            formatter: this.actionsFormatterForECVoterName.bind(this),
            headerStyle: {
                width: "55%"
            }
            
            
        },
        {
            dataField: "Age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECAgeNameModified.bind(this),
            
        },
       
        {
            dataField: "Action",
            text: "Sel ect",
            formatter: this.actionsFormatterForECUpdatedVoter.bind(this),
        }
     
    ];
    return columns;
}

actionsFormatterForECVoterName = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.voterName)} 
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.age)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Rel_Name - {(row.relativeName)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Relation - {(row.relation)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>House_No - {(row.houseNo)}</span></>
        </span>
     );
    
}
actionsFormatterForECAgeNameModified = (cell, row, rowIndex, formatExtraData) =>{
    return (
        (row.voterCardNo)
        
      );
}

actionsFormatterForECUpdatedVoter = (cell, row, rowIndex, formatExtraData) => {
    return(
        <>
        {
        
            row.isVisited == 'Y' ?
            (
                <>
                <Form.Check
                    disabled
                    inline
                    label={""}
                    name="rowIndex"
                    type="checkbox"
                    id={`checkbox-${rowIndex}`}
                    onChange={(e)=>this.props.getHouseValue(e,row.electionCampaignVoterId)}
                    className="f-12 fw-bold color_black pos_center"
                    defaultChecked={true}
                 />
                 <span style={{textAlign:"left",marginLeft:"0px"}} className='color_green fw-bold'>visited</span>
                 </>
            )
            :
            (
                <Form.Check
                    inline
                    label={""}
                    name="rowIndex"
                    type="checkbox"
                    id={`checkbox-${rowIndex}`}
                    onChange={(e)=>this.props.getHouseValue(e,row.electionCampaignVoterId)}
                    className="f-12 fw-bold color_black pos_center input_size"
                    checked={row.checkedType}
                />
            )

        
        }
        </>
       
    )
}

hoseWiseVoterListDisplayData = () =>{
    let houseWiseVoterData = [];
        if(this.props.stateData.houseWiseVoterListArr.length>0){
            
            for (let i = 0; i < this.props.stateData.houseWiseVoterListArr.length; i++) {

                const serial_no = (this.props.stateData.houseWiseVoterListArr[i].serialNo);
                const voterName = (this.props.stateData.houseWiseVoterListArr[i].voterName);
                const gender = (this.props.stateData.houseWiseVoterListArr[i].gender);
                const age = (this.props.stateData.houseWiseVoterListArr[i].age);
                const relativeName = (this.props.stateData.houseWiseVoterListArr[i].relativeName);
                const relation = (this.props.stateData.houseWiseVoterListArr[i].relation);
                const houseNo = (this.props.stateData.houseWiseVoterListArr[i].houseNo);
                const electionCampaignVoterId = (this.props.stateData.houseWiseVoterListArr[i].electionCampaignVoterId);
                const voterCardNo = (this.props.stateData.houseWiseVoterListArr[i].voterCardNo);
                const checkedType = (this.props.stateData.houseWiseVoterListArr[i].checked);
                const isVisited = (this.props.stateData.houseWiseVoterListArr[i].isVisited);
               
                
                const obj = {
                    serial_no:(serial_no),
                    voterName:voterName,
                    gender: gender,
                    age: voterCardNo,
                    relativeName: relativeName,
                    relation:relation,
                    houseNo:houseNo,
                    electionCampaignVoterId:electionCampaignVoterId,
                    voterCardNo:age,
                    checkedType:checkedType,
                    isVisited:isVisited
                   
                    
                }
                houseWiseVoterData.push(obj);
            } 

            
            
            return houseWiseVoterData;
        }
}



    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>House Wise Voter List : </Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                {
                            this.props.stateData.houseWiseVoterListArr.length == 0 ?
                            (
                                this.props.stateData.houseWiseVoterListArrCheck == 1 ?
                                (<Col className='f-14 text-center'>No Data Available</Col>)
                                :
                                (<Col sm={12}>
                                  <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>)
                            )
                            :
                            (
                              this.props.stateData.houseWiseVoterListArr.length>0 ?
                                (
                                    <>
                                    <Row>
                                        <Col sm={12}>
                                        
                                        <div className='bg_light_blue mt-3 p-0'>
                                            <Row>
                                                <Col>
                                                    <h6 className='p-2 float-right'>
                                                    
                                                    <span className=''>
                                                        <Form.Check
                                                            inline
                                                            label={"Select All"}
                                                            name="AllcheckboxSelected"
                                                            type="checkbox"
                                                            id="allcheckboxIds"
                                                            onChange={(e)=>this.props.checkAllCheckBoxsForVoters(e)}
                                                            className="color_black f-14 pos_name_align input_size"
                                                            defaultChecked={false}
                                                        />
                                                    </span>
                                                    <Button variant="success" className='f-12 fw-bold' onClick={() => this.props.saveECHouseVisit()}>Update House Visit</Button>
                                                    {
                                                        this.props.stateData.saveECHouseVisitLoadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                                    } 
                                                </h6>
                                                </Col>
                                            </Row>
                                            {
                                                this.props.stateData.successMessage != "" ?
                                                ( <h6 className='f-14 fw-bold text-center'>{this.props.stateData.successMessage}</h6>)
                                                :
                                                ("")
                                            }
                                        <CardLessDataTableWithSearch 
                                            parentData={this.hoseWiseVoterListDisplayData()}
                                            particlecolumns={this.hoseWiseVoterListDisplayColumns()}
                                            defaultSorted={[{
                                                dataField: "serial_no",
                                                order: "asc"
                                            }]}
                                            tableHeading=""
                                            />
                                            
                                                
                                        </div>
                                        
                                        </Col>
                                    </Row>
                                    </>
                                )
                                :
                                ("")
                            )
                          }

                </Modal.Body>
            </Modal>
            
        )
    }
}
export default HouseWiseVoterListModal;
  

