
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import MF_logo1 from '../../images/MF_logo1.png';
import QrReader from "react-qr-reader";

class QrReaderCode extends Component {
    constructor(props) {
        super(props)
        this.state = {
          volunteerDts:props?.location?.params?.volunteerDts == undefined ? "" : props?.location?.params?.volunteerDts,
          userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
          scannerConstraints:{facingMode: "environment"},
          loadingScan:true,
          scanData:"",
          clickType:props?.location?.params?.clickType == undefined ? "" : props?.location?.params?.clickType,
          searchOptionTypeVal:props?.location?.params?.searchOptionTypeVal == undefined ? "" : props?.location?.params?.searchOptionTypeVal,
        }
    }

    componentDidMount() {
        
    }


    handleScan = async (data) => {
     
      if (data && data !== "") {
        console.log(`loaded >>>`, data);
        this.setState({ scanData:data });
        this.setState({ loadingScan:false });
    }
  }

  handleError = (err) => {
    console.error(err);
  };
    
  
    render() {
      console.log("ddddddddddddddddddwwwd",this.state.volunteerDts)
        return (
            <section>
               <div className="pos_relative">
                    <div class="text-white">
                    <img  class="bg_css_volunter_logo" />
                    <div class="card-img-overlay">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="flex-header mt-1">
                                <img className="center-auto w-80P" src={MF_logo1} />
                                </div>
                            </div>
                        </div>
                        <Link to={{pathname: "/volunteer_page",
                                  params: {
                                    navbarDisplay:"hide",
                                    volunteerDts:this.state.volunteerDts,
                                    userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList
                                }}}> 
                            <Button variant="outline-light ms-3 mt-3 pad5_14 back_button" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Link>
                    </div>
                    </div>
                </div>
                
                <Container>
                    <Row>
                        <Col className='mt-3 center-auto'>
                          {this.state.loadingScan && (
                          <>
                            
                            <QrReader
                              constraints={ {facingMode: 'environment'} }
                             // facingMode={this.state.scannerConstraints}
                              delay={1000}
                              onError={this.handleError}
                              onScan={this.handleScan}
                              // chooseDeviceId={()=>selected}
                              style={{ width: "300px" }}
                            />
                          </>
                        )}
                        {/*this.state.loadingScan && <p>Loading...</p>*/}
                        {
                          this.state.clickType == "MahaShakti" ?
                          (
                            <>
                            {
                              this.state.scanData !== "" && 
                              this.props.history.push({
                                pathname: this.state.searchOptionTypeVal == "self" ?  "/volunteer_page" : "/voter_search_enroll_save",
                                params: {
                                    navbarDisplay:"hide",
                                    volunteerDts:this.state.volunteerDts,
                                    mahaShaktiCardId:this.state.scanData,
                                    userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                    searchOptionTypeVal:this.state.searchOptionTypeVal
                                   
                                  },
                              })
                              
                            }
                            </>
                          )
                          :
                          (
                            this.state.clickType == "YuvaGalam" ?
                            (
                              <>
                              {
                                this.state.scanData !== "" && 
                                this.props.history.push({
                                  pathname: this.state.searchOptionTypeVal == "self" ?  "/volunteer_page" : "/voter_search_enroll_save",
                                  params: {
                                      navbarDisplay:"hide",
                                      volunteerDts:this.state.volunteerDts,
                                      yuvaGalamCardId:this.state.scanData,
                                      userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                      searchOptionTypeVal:this.state.searchOptionTypeVal
                                    },
                                })
                                
                              }
                              </>
                            )
                            :
                            (
                              this.state.clickType == "AnnaData" ?
                              (
                                <>
                                {
                                  this.state.scanData !== "" && 
                                  this.props.history.push({
                                    pathname: this.state.searchOptionTypeVal == "self" ?  "/volunteer_page" : "/voter_search_enroll_save",
                                    params: {
                                        navbarDisplay:"hide",
                                        volunteerDts:this.state.volunteerDts,
                                        annaDataCardId:this.state.scanData,
                                        userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                        searchOptionTypeVal:this.state.searchOptionTypeVal
                                       
                                      },
                                  })
                                  
                                }
                                </>
                              )
                              :
                              ("")
                            )
                          )
                        }
                        
                         
                        </Col>
                    </Row>  
                </Container>
            
            </section>
        )
    }
  }
  
  export default withRouter(QrReaderCode);
  