import React, { useState,useEffect,useId } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { getAPICallData } from '../../services/MemberService';
import "./VoiceRecorder.css";
import {Container, Row, Col,Image,Button} from 'react-bootstrap';
import axios from 'axios'

export default function VoiceRecorder(props) {
    const [audioUrl, setaudioUrl] = useState([]);
    const [audioUrlq, setaudioUrlq] = useState("");
   

  const recorderControls = useAudioRecorder();
  //const [audioSourceURL, setAudioSourceURL] = React.useState("");

  return (
    
    <section>
         <div className="recorded_block">
                <Row>
                    <Col>
                        
                            
                            <div className="flex-audio-voice">
                                <AudioRecorder 
                                   // downloadOnSavePress={(data) => downloadOnSavePress(data)}
                                    onRecordingComplete={(blob) => props.addAudioElement(blob)}
                                    recorderControls={recorderControls}
                                    audioTrackConstraints={{
                                        noiseSuppression: true,
                                        echoCancellation: true,
                                    }} 
                                    downloadFileExtension="mp3"
                                    
                                    />
                                    <h6 className="f-12 fw-bold text-center mt-2">Press the microphone to start record</h6>
                            </div>
                            

                            
                    </Col>
                </Row> 
                <Row>
                    <Col>
                        <Button variant="outline-danger" className='float-end f-12  fw-bold' onClick={recorderControls.stopRecording}>Stop recording</Button>                
                    </Col>
                </Row> 
            </div>
            <Row>
                    <Col className="mt-3">
                        {
                            props.stateData.audioSourceURL != "" ?
                            (
                                <audio
                                    controls
                                    src={props.stateData.audioSourceURL}
                                    type="audio/mpeg"
                                    preload="metadata"
                                />
                            )
                            :
                            ("")
                            
                        }
                    </Col>
                </Row>
    </section>
    
  );
}

  