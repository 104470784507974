
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,InputGroup,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import { getAPICallData } from '../../services/MemberService';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';


class VotersModificationDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessListArr:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            boothIdsList:[],
            boothId:"",
            boothWiseSelectBoxVM:"",
            VMStatusDashboardCheck: 0,
            VMStatusDashboardArr:[]
           
        }
        
    }

    componentDidMount() {
        let userAccessList = this.state.userAccessListArr;
        if(userAccessList != null && userAccessList.status == "Success" ){
            if(userAccessList.boothList != null && userAccessList.boothList.length>0){
                this.setState({ boothIdsList: userAccessList.boothList });
                this.getVoterModificationStatusDashboard('',"All");
            }
        }else{
            this.props.history.push({pathname:"/dashboard"});
            
        }
    }

    getVoterModificationType = (type) => {
        this.setState({ boothWiseSelectBoxVM: type });
        if(type == "All"){
            this.getVoterModificationStatusDashboard('',"All");
        }else{
            this.getVoterModificationStatusDashboard('',"Booth");
        }
       
    }

    boothNoHandleChangeVM = (e) =>{
        const value = e.target.value;

        if(e.target.value == "0"){
            this.getVoterModificationStatusDashboard('',"Booth");
        }else{
            this.getVoterModificationStatusDashboard(value,"Booth");
        }
    }

    getVoterModificationStatusDashboard = (boothId,type) => {
        this.setState({ VMStatusDashboardCheck: 0,VMStatusDashboardArr:[]});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "type" : type,
            "boothId" : boothId 
            }
        ); 

        getAPICallData(obj,"attributes/getVoterModificationStatus")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.length>0){
                    this.setState({ VMStatusDashboardCheck: 0,VMStatusDashboardArr:data});
                }else{
                    this.setState({ VMStatusDashboardCheck: 1,VMStatusDashboardArr:[]});
                }
            });
  }

  sortFuncSerialNoVM(a, b, order, dataField, rowA, rowB)  { 
    if (order == 'desc') {
        
        return parseInt(rowB.voterCardNo)  > parseInt(rowA.voterCardNo) ? 1 : -1;

    } else {
        return parseInt(rowA.voterCardNo)  > parseInt(rowB.voterCardNo) ? 1 : -1;
    }
}
  
  votersVMDashboardDisplayColumns() {

    let columns = [
        {
            dataField: "Booth",
            text: "Booth",
            sort: true,
            sortFunc: this.sortFuncSerialNoVM.bind(this)
            
            
        },
        {
            dataField: "Total_Voters",
            text: "Total",
            sort: true,
            
        },
        {
            dataField: "verified",
            text: "V",
            sort: true,
            formatter: this.actionsFormatterForVerifiedDeletions.bind(this),
            
        },
        {
            dataField: "verified_perc",
            text: "%",
            sort: true,
            formatter: this.actionsFormatterForVerifiedPercDeletions.bind(this),
            
        },
        {
            dataField: "Pending",
            text: "P",
            sort: true,
            formatter: this.actionsFormatterForPendingDeletions.bind(this),
            
        },
        {
            dataField: "Pending_perc",
            text: "%",
            sort: true,
            formatter: this.actionsFormatterForPendingPercDeletions.bind(this),
        },
        /*{
            dataField: "Added",
            text: "Additions",
            formatter: this.actionsFormatterForAdditions.bind(this),
            sort: true,
            sortFunc: this.sortFuncAdded.bind(this),
            headerStyle: {
                width: "30%"
            }
            
        },
        {
            dataField: "Deleted",
            text: "Deletions",
            sort: true,
            sortFunc: this.sortFuncDeletion.bind(this),
            formatter: this.actionsFormatterForDeletions.bind(this),
            headerStyle: {
                width: "30%"
            }
            
        }*/
    
    ];
    return columns;
}


actionsFormatterForVerifiedDeletions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_green' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    row.verified >0 ?
                    (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold text-decoraion-underline cursor_pointer' onClick={() => this.getBoothWiseVotersUpdateDetila(row.BoothId,row.Booth,"Deletions","2","Y")}>{row.verified}</span></span>)
                    :
                    (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold'>0</span>)
                }
                
            </span>
           
         </>

        
     );
    
}


actionsFormatterForVerifiedPercDeletions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_green ' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    row.verified >0 ?
                    (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold' >{row.verified_perc}</span> </span>)
                    :
                    (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold'>0</span>)
                }
            </span>
           
         </>

        
     );
    
}



actionsFormatterForPendingDeletions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_red ' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    row.Pending >0 ?
                    (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold text-decoraion-underline cursor_pointer' onClick={() => this.getBoothWiseVotersUpdateDetila(row.BoothId,row.Booth,"Deletions","2","N")}>{row.Pending}</span> </span>)
                    :
                    (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold'>{row.Pending}</span>)
                }
            </span>
           
         </>

        
     );
    
}


actionsFormatterForPendingPercDeletions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_red ' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    row.Pending >0 ?
                    (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold' >{row.Pending_perc}</span> </span>)
                    :
                    (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold'>0</span>)
                }
            </span>
           
         </>

        
     );
    
}

votersVMDashboardDisplayData = () => {
    let votersData = [];
    if(this.state.VMStatusDashboardArr.length>0){
        
        for (let i = 0; i < this.state.VMStatusDashboardArr.length; i++) {

            const locationName = this.state.VMStatusDashboardArr[i].locationName;
            const locationId = this.state.VMStatusDashboardArr[i].locationId;
            const totalVoters = (this.state.VMStatusDashboardArr[i].totalVoters);
            const addedUpdatedCnt = (this.state.VMStatusDashboardArr[i].addedStatus.updatedCnt);
            const addedPendingCnt = (this.state.VMStatusDashboardArr[i].addedStatus.pendingCnt);
            const addedUpdatedPercentage = (this.state.VMStatusDashboardArr[i].addedStatus.updatedPercentage);
            const addedPendingPercentage = (this.state.VMStatusDashboardArr[i].addedStatus.pendingPercentage);
            const deletedUpdatedCnt = (this.state.VMStatusDashboardArr[i].deletedStatus	.updatedCnt);
            const deletedPendingCnt = (this.state.VMStatusDashboardArr[i].deletedStatus.pendingCnt);
            const deletedUpdatedPercentage = (this.state.VMStatusDashboardArr[i].deletedStatus.updatedPercentage);
            const deletedPendingPercentage = (this.state.VMStatusDashboardArr[i].deletedStatus.pendingPercentage);
            const votersCnt = (this.state.VMStatusDashboardArr[i].deletedStatus.votersCnt);

            const obj = {
                Booth:locationName ==undefined ? "All Booths" : locationName,
                BoothId: locationId,
                /*Total_Voters: totalVoters,
                addedUpdatedCnt: addedUpdatedCnt,
                addedPendingCnt:addedPendingCnt,
                addedUpdatedPercentage:addedUpdatedPercentage,
                addedPendingPercentage:addedPendingPercentage,
                deletedUpdatedCnt:deletedUpdatedCnt,
                deletedPendingCnt:deletedPendingCnt,
                deletedUpdatedPercentage:deletedUpdatedPercentage,
                deletedPendingPercentage:deletedPendingPercentage,*/
                Total_Voters:votersCnt,
                verified:deletedUpdatedCnt,
                verified_perc:deletedUpdatedPercentage,
                Pending:deletedPendingCnt,
                Pending_perc:deletedPendingPercentage
            }
            votersData.push(obj);
        } 

        
        
        return votersData;
    }
}


    render() {
      
        return (
            <section>
               <Container>
                        <Row>
                            <Col sm={12} className=''>
                                <Form className="text-center">
                                        <Form.Check
                                            inline
                                            label="ALL"
                                            name="getAttrCollData"
                                            type="radio"
                                            id="vmAllId"
                                            defaultChecked={true}
                                            onChange={()=>this.getVoterModificationType("All")}
                                            className="f-14 fw-bold color_black"
                                        />
                                        <Form.Check
                                            inline
                                            label="Booth Wise"
                                            name="getAttrCollData"
                                            type="radio"
                                            id="vmBoothWiseId"
                                            onChange={()=>this.getVoterModificationType("Booth")}
                                            className="f-14 fw-bold color_black"
                                        />
                                        
                                    
                                </Form>
                            </Col>
                        </Row>
                        <div className='bg_light_blue mt-2'> 
                        {
                            this.state.boothWiseSelectBoxVM == "Booth" ?
                            (
                                <Row>
                                    <Col className='w_80'>
                                        <h6 className="form-label f-14 fw-bold">Booth</h6>
                                        <select id="booth_id" name="boothId" className='form-select form-select-sm' onChange={(e) => this.boothNoHandleChangeVM(e)}>
                                            {
                                                this.state.boothIdsList.length>0 ? 
                                                (
                                                    <>
                                                    <option value="0" selected>All</option>
                                                    {
                                                        this.state.boothIdsList.map((item,index) => {
                                                            return(
                                                                <option value={item.id}>Booth No - {item.name}</option>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                
                                                )
                                                :
                                                (<option value="">Select Booth</option>)
                                                
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        }

                        {
                        this.state.VMStatusDashboardArr.length == 0 ?
                        (
                            this.state.VMStatusDashboardCheck == 1 ?
                            (<Col className='f-14 text-center mt-3'>No Data Available</Col>)
                            :
                            (<Col sm={12}>
                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                            </Col>)
                        )
                        :
                        (
                            this.state.VMStatusDashboardArr.length>0 ? 
                            (
                                <Row>
                                    <Col sm={12}>
                                        <div className='bg_light_blue mt-2'>
                                            <h6 className='f-12 fw-bold'>
                                                Note : <span className='ml_5 mr_2'>V - Verified,</span>
                                                        <span className='ml_5 mr_2'>P - Pending</span>
                                            </h6>
                                            <CardLessDataTableWithOutSearch 
                                                parentData={this.votersVMDashboardDisplayData()}
                                                particlecolumns={this.votersVMDashboardDisplayColumns()}
                                                defaultSorted={[{
                                                    dataField: "Deleted",
                                                    order: "desc"
                                                }]}
                                            tableHeading={this.state.boothWiseSelectBoxVM == "Booth" ? "Booth Wise Status Counts" :  "All Booth Wise Status Counts" } 
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        )
                        
                        }

                            
                        </div>
               </Container>
            
            </section>
        )
    }
  }
  
  export default withRouter(VotersModificationDashboard);
  