import React, { Component } from 'react'
import { Modal, Button,Row,Col } from "react-bootstrap";
import SelectBox from '../components/SelectDropDown/SelectDropDown';
import { components } from 'react-select';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class UpdateLocationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>{this.props.stateData.mappingTypeLocation == "house" ? "House Mapping Location Update" : "Booth Mapping Location Update"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                        <Row>
                            <Col sm={12}>
                                <h6>Latitude : <b>{this.props.stateData.getCurrentLocationCoordinates.latitude}</b></h6>
                                <h6>Longitude : <b>{this.props.stateData.getCurrentLocationCoordinates.longitude}</b></h6>
                                <h6>Accuracy : <b>{this.props.stateData.getCurrentLocationCoordinates.accuracy}</b></h6>
                            </Col> 
                            {
                                this.props.stateData.mappingTypeLocation == "house" ?
                                (
                                    <>
                                    <Col sm={12} className='mt-2'>
                                        <h6 className='float-end add-street-span' onClick={() => this.props.pstreetBlockShow()}><i class="fa-solid fa-plus"></i> Add Street</h6>
                                    </Col> 
                                        {

                                            this.props.stateData.addNewStreetBlock === "show" ?
                                            (
                                                <Col sm={12} className=''>
                                                    <label className="form-label form-label-block f-14 fw-bold">Create New Street: <span className='f-12 color_red'>{this.props.stateData.validateStreet}</span></label>
                                                    <input type="text" className='form-control'name="addStreetName"  placeholder='Enter Street Name' value={this.props.stateData.addStreetName} onChange={(e) => this.props.phandleOnchangeInputBox(e)}></input> 
                                                    <Button type="button" variant="outline-success" className='fw-bold f-12 float-end mt-2' onClick={() => this.props.paddNewStreet()}>Add Street</Button> 
                                                    <h6 className='mt-2 fw-bold text-center f-12'>{this.props.stateData.streetSuccessMsg}</h6>
                                                </Col> 
                                            
                                            )
                                            :
                                            ("")
                                        }
                                        <Col sm={12} className=''>
                                            <label className="form-label form-label-block f-14 fw-bold">Street: <span className='f-12 color_red'>{this.props.stateData.validateStreet}</span></label>
                                            <SelectBox
                                                stateData={this.props.stateData.getStreetNameValue}
                                                handleChange={this.props.phandleOnchangeSelectBox}
                                                name="getStreetName"
                                                options={this.props.stateData.streetArr}
                                                placeholderName={'Select Street'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                            
                                        </Col>
                                        <Col sm={12} className='mt-2'>
                                            <Button type="button" className='btn btn-info fw-bold f-14 float-end m_top50' onClick={() => this.props.phandleGetLocation()}>Submit</Button>    
                                        </Col> 
                                        <Col sm={12}>
                                            <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.boothHouseLocationMsg}</h6>
                                        </Col>
                                        </>
                                )
                                :
                                (
                                    <>
                                    <Col sm={12} className='mt-2'>
                                        <Button type="button" className='btn btn-info fw-bold f-14 float-end m_top50' onClick={() => this.props.phandleGetBoothLocation()}>Submit</Button>    
                                    </Col> 
                                    <Col sm={12}>
                                        <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.boothLocationMsg}</h6>
                                    </Col>
                                    </>
                                )
                            } 
                              
                        </Row>    
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default UpdateLocationModal;
  

