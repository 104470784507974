
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,OverlayTrigger,Tooltip } from 'react-bootstrap';
import "./Dashboard.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import moment from 'moment';


class ProgramsDashboard extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
          accessNameNavigation:props?.location?.params?.accessNameNavigation == undefined ? "" : props?.location?.params?.accessNameNavigation,
          eventDetailsList:[],
          verifyHouseAccess:"",
          eventDetailsListCheck:0,
          chatID: window.User !=undefined ? window.User.chatID : "943827154"
          
        }
    }
  
    componentDidMount() {
      
        this.getCampaignsOverview();
        
    }

    
      getCampaignsOverview = () => {
        this.setState({ eventDetailsListCheck:0 });
        const campaignObj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID
            });
            
        //getAllBoothVerificationStatus
        getCampaignAPICallData(campaignObj,"getCampaignsOverview").then((res) => res.json())
        .then((json) => {
            if(json ==null || json.length == 0){
                this.setState({ eventDetailsList: [],eventDetailsListCheck:1 });
            }else{
                this.setState({ eventDetailsList: json,eventDetailsListCheck:0 });
            }
            
        });
        }

       


    render() {
        return (
            <section>
                
                <Container fluid>
                   
                    {/* Events Overview Code*/}

                    {
                         this.state.accessNameNavigation != "NoAccess" ? 
                         (
                            <>
                            
                            <Row>
                                <Col sm={12}>
                                    <div className='bg_light_blue '> 
                                        <div className=''>
                                            <div className='table-responsive'>
                                                <table className='table table_custom_programs table-condensed w-100'>
                                                    <thead>
                                                        <tr>
                                                            <th>Programs Overview</th>
                                                            <th ><i class="fa-solid fa-play color_green f-18"></i></th>
                                                            <th ><i class="fa-solid fa-pause color_yellow f-18"></i></th>
                                                            <th ><i class="fa-solid fa-stop color_red f-18"></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.eventDetailsList.length == 0 ?
                                                        (
                                                        
                                                        this.state.eventDetailsListCheck == 1 ?
                                                        (<Col className='f-14 text-center'>No Programs Available</Col>)
                                                        :
                                                        (
                                                            <Col sm={12}>
                                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                            </Col>
                                                        )
                                                        )
                                                        :
                                                        (
                                                            this.state.eventDetailsList.length>0?
                                                                (
                                                                    this.state.eventDetailsList.map(item => {
                                                                        return(
                                                                            <tr className='solid'>
                                                                                <td>
                                                                                    <h6 className='f-12'>{item.campaignName}</h6>
                                                                                        {
                                                                                            item.subType != "" ?
                                                                                            (<h6 className='f-12 color_red'>{item.subType}</h6>)
                                                                                            :
                                                                                            ("")
                                                                                        }
                                                                                    <h6 className='f-12 color_yash'>{moment(item.fromDate).format('MMM Do YYYY') } - {moment(item.todate).format('MMM Do YYYY')}</h6>
                                                                                    </td>
                                                                                    <td className='fw-bold'>
                                                                                    {
                                                                                        item.pending>0 ?
                                                                                        (
                                                                                            <Link to={{
                                                                                                pathname: "/events" ,
                                                                                                params: {
                                                                                                    navbarDisplay:"hide",
                                                                                                    selectedEventDisplay: 1,
                                                                                                }
                                                                                            }} className="">
                                                                                                {item.pending}
                                                                                            </Link>
                                                                                        )
                                                                                        :
                                                                                        (0)
                                                                                    }
                                                                                    
                                                                                    </td>
                                                                                    <td className='fw-bold'>
                                                                                    {
                                                                                        item.ongoing>0 ?
                                                                                        (
                                                                                            <Link to={{
                                                                                                pathname: "/events" ,
                                                                                                params: {
                                                                                                    navbarDisplay:"hide",
                                                                                                    selectedEventDisplay: 2,
                                                                                                }
                                                                                            }} className="">
                                                                                                {item.ongoing}
                                                                                            </Link>
                                                                                        )
                                                                                        :
                                                                                        (0)
                                                                                    }
                                                                                    
                                                                                    </td>
                                                                                    <td className='fw-bold'>
                                                                                    {
                                                                                        item.completed>0 ?
                                                                                        (
                                                                                            <Link to={{
                                                                                                pathname: "/events" ,
                                                                                                params: {
                                                                                                    navbarDisplay:"hide",
                                                                                                    selectedEventDisplay: 3,
                                                                                                }
                                                                                            }} className="">
                                                                                            {item.completed}
                                                                                            </Link>
                                                                                        )
                                                                                        :
                                                                                        (0)
                                                                                    }
                                                                                    
                                                                                    
                                                                                    </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                )
                                                                :
                                                                (
                                                                    <tr>
                                                                        <td>-</td>
                                                                        <td>-</td>
                                                                        <td>-</td>
                                                                        <td>-</td>
                                                                    </tr>
                                                                )
                                                        )

                                                        
                                                    }
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                            <h6 className='f-14'>
                                                Note : <span className='ml_5 mr_2'><i class="fa-solid fa-play color_green"></i> Pending,</span>
                                                        <span className='ml_5 mr_2'><i class="fa-solid fa-pause color_yellow"></i> OnGoing,</span>
                                                        <span className='ml_5 mr_2'><i class="fa-solid fa-stop color_red"></i> Completed</span>
                                            </h6>
                                        
                                        </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    </>
                         )
                         :
                         ("")
                    }
                  

                </Container> 
                
            </section>
        )
    }
  }
  
  export default withRouter(ProgramsDashboard);
  