
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import { getAPICallData } from '../../services/MemberService';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class WardCampaignStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            panchayatWardListArr:[],
            panchayatWardId:"",
            panchayatWardValue:"",

            loadingSymbol:"hide",
            successMsg:"",

            verifiedHouses:"",
            verifiedVoters:"",
            fromDate:moment().format("YYYY-MM-DD"),

            validatePanchayatWardId:"",
            validateVerifyHouse:"",
            validateVerifyVoters:"",
           
        }
        
    }

    componentDidMount() {
        this.getPanchayatWards();
    }

    getPanchayatWards = () => {
        const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
            });
        getAPICallData(obj,"getPanchayatWards")
            .then((res) => res.json())
            .then((json) => {
                if(json !=null && json.length>0){
                    var wardsArr=[];
                    for(var i in  json){
                        var obj={'value':json[i].id,'label':json[i].name};
                        wardsArr.push(obj)
                        
                    }
                   
                    this.setState({ panchayatWardListArr:wardsArr});
                  }else{
                    this.setState({ panchayatWardListArr:[]});
                  }
            });
    }

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        
      }

      handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "panchayatWardId"){
          let getPanIdName = this.state.panchayatWardListArr.find((a) => a.value == value);
            this.setState({
                panchayatWardValue: {
                  value: getPanIdName.value,
                  label: getPanIdName.label
              }
          });
    
          this.setState({ panchayatWardId: getPanIdName.value});
        
        }
      }

      handleEvent = (event, picker) => {
        console.log("mmmmmmmmm",moment(picker.startDate).format("YYYY-MM-DD"))
        this.setState({ fromDate: moment(picker.startDate).format("YYYY-MM-DD")});
        
      }

    

      updateWardVisitStatus = () => {
        this.setState({validateVerifyHouse:"",
                        validateVerifyVoters:"",validatePanchayatWardId:""
        });
      
        if(this.state.panchayatWardId == "" || this.state.panchayatWardId == 0){
            this.setState({validatePanchayatWardId:"Please Select Ward" });
            return;
        }
        if(this.state.verifiedHouses == "" ){
            this.setState({validateVerifyHouse:"Please Enter Verify Houses" });
            return;
        }

        if(this.state.verifiedHouses != ""){
            if(this.state.verifiedHouses > 500){
                this.setState({validateVerifyHouse:"Verified Houses should not more than 500" });
                return;
            }
        }

        if(this.state.verifiedVoters == "" ){
            this.setState({validateVerifyVoters:"Please Enter Verify VOters" });
            return;
        }

        if(this.state.verifiedVoters != ""){
            if(this.state.verifiedVoters > 1900){
                this.setState({validateVerifyVoters:"Verified Houses should not more than 1900" });
                return;
            }
        }
        
        this.setState({loadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "panchayatWardId" : this.state.panchayatWardId,
            "date":this.state.fromDate,
            "visitedHouses" : this.state.verifiedHouses,
            "visitedVoters" : this.state.verifiedVoters,
            }); 

            getAPICallData(obj,"updateWardVisitStatus")
            .then((res) => res.json())
            .then((data) => {
              this.setState({loadingSymbol:"hide"});  
                if(data !=null && data.status == "Success"){
                    this.setState({successMsg:"Saved SuccessFully..."}); 
                    setTimeout(() => { 
                        this.setState({  
                            verifiedHouses:"",
                            verifiedVoters:"",
                            panchayatWardId:"",
                            panchayatWardValue:"",fromDate:moment().format("YYYY-MM-DD"),successMsg:""});     
                       
                    }, 1500);
                }else{
                    this.setState({successMsg:"Some Thing Wrong,Please Check once"});  
                   
                }
          });
      }
  
    render() {

        return (
            <section>
               <Container className=''>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'>Ward Campaign Status Update</h6>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <h6 className="form-label f-12 fw-bold">Panchayats<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-12'>{this.state.validatePanchayatWardId}</span></h6>
                            <SelectBox
                                stateData={this.state.panchayatWardValue}
                                handleChange={this.handleOnchangeSelectBoxNew}
                                name="panchayatWardId"
                                options={this.state.panchayatWardListArr}
                                placeholderName={'Select Panchayat'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                        </Col>
                    </Row>
                    <Form>
                    <Row>
                        <Col>
                        <h6 className="form-label f-12 fw-bold mt-3">Date <span className='color_red f-9'>{this.state.validateDate}</span></h6>
                        <DateRangePicker
                                singleDatePicker={true}
                                alwaysShowCalendars={true}
                                onEvent={this.handleEvent}
                                className="w-100"
                            >   
                            <div class="input-group">
                                    <div class="input-group-prepend bgColorYash">
                                     <i class="fa fa-calendar pad_5_15"></i>
                                    </div>
                                    <input type="text" className="form-control" value={moment(this.state.fromDate).format("YYYY-MM-DD")}/>
                                </div>
                            </DateRangePicker>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                        <h6 className="form-label f-12 fw-bold">visited Houses <span className='color_red f-9'>{this.state.validateVerifyHouse}</span></h6>
                        <Form.Control type="text" name="verifiedHouses" value={this.state.verifiedHouses}   className='form-control-sm'  placeholder="Enter Verified Houses" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    <Row>
                        <Col className='mt-3'>
                        <h6 className="form-label f-12 fw-bold">visited Voters <span className='color_red f-9'>{this.state.validateVerifyVoters}</span></h6>
                        <Form.Control type="text" name="verifiedVoters"className='form-control-sm' value={this.state.verifiedVoters}    placeholder="Enter Verified Voters"  onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    
                    </Form>

                      <Row>
                            <Col>
                                <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-3 mb-3' onClick={() => this.updateWardVisitStatus()}>SUBMIT</Button>
                                    {
                                        this.state.loadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                    }
                            </Col>
                      </Row>
                      <Row>
                            <Col>
                                <h6 className='f-14 fw-bold text-center mt-2'>{this.state.successMsg}</h6>
                            </Col>
                      </Row>
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(WardCampaignStatus);
  