
import React ,{ Component,createRef  } from 'react';
import {Container, Row, Col,Image,Button,Card  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import QRCode from "react-qr-code";

class GenerateQRCodeSection extends Component {
    constructor(props) {
        super(props)
        this.myref = createRef();
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            constituencyValue:"",
            constituencyName:"",
            ac_listArr:[]
        }
    }

    componentDidMount() {
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
                for(var i in  this.state.userAccessList.constituencyList){
                    var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                    consArr.push(obj);
                }
            
                this.setState({ ap_listArr:consArr });
                this.setState({
                    constituencyValue: {
                    value: this.state.userAccessList.constituencyList[0].id,
                    label: this.state.userAccessList.constituencyList[0].name
                },constituencyName:this.state.userAccessList.constituencyList[0].id
            });



        }
    }


   

    render() {
        return (
            <section>
                <Container>
                <Row>
                    <Col>
                        <Link to={{pathname: "/dashboard"}}> 
                            <Button variant="outline-dark pad5_14 ms-2 float-right" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Link>
                    </Col>
                </Row>
                    <Row>
                        <Col sm={12}>
                            <h6 className='text_center fw-bold f-18'>My QR CODE</h6>
                            {
                               this.state.userAccessList == "" ?
                               (
                                <Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls1'></Image>
                               )
                               :
                               (
                                <>
                                <div className='' > 
                                    <Card className="text-white border-none mt-4">
                                            <div className='center-auto'>
                                                <QRCode
                                                    size={200}
                                                    viewBox={`0 0 256 256`}
                                                    value={`${this.state.constituencyValue.value == undefined  ? "" : this.state.constituencyValue.value} - ${this.state.chatID}`}
                                                />
                                            </div> 
                                    
                                    </Card>

                                    <Row>
                                        <Col>
                                            <h5 className='color_red fw-bold mt-4 f-14 ln-28 text-center'>మీరు శంఖారావం కార్యక్రమానికి హాజరైనట్లు గుర్తించడానికి ఈ  QR కోడ్ చూపించి - వాలంటీర్లు వద్ద స్కాన్ చేయంచుకోండి</h5>
                                        </Col>
                                    </Row>
                                </div>
                                </>
                               )
                            }
                            
                            
                            
                        </Col>
                    </Row>
                </Container>
            
            </section>
        )
    }
  }
  
  export default withRouter(GenerateQRCodeSection);
  