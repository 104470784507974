import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

class ViewIssueDescription extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                        <Row>
                            <Col>
                               <p>
                                    {this.props.stateData.showFullIssueDes}
                               </p>
                            </Col>
                        </Row>
                      
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default ViewIssueDescription;
  

