import React ,{ Component,createRef  } from 'react';
import {Container, Row, Col,Image,Button,Card  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import QrReader from "react-qr-reader";
import EnabledLocationPopup from "../Volunteer/EnabledLocationPopup"
import { getAPICallDataForOnlyBSA } from '../../services/MemberService'
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';


const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class AttendenceSection extends Component {
    constructor(props) {
        super(props)
        this.myref = createRef();
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            constituencyValue:"",
            constituencyName:"",
            ac_listArr:[],
            loadingScan:true,
            scanData:"",
            saveAttendanceLoadingSymbol:"hide",
            saveAttendanceMessage:"",

            getAttendenceLatitudeLongitudeObj:{},
            checkingEnabledLocationModal:false,

            eventLoadingSymbol:"hide",
            event_listArr:[],
            eventValue:"",
            eventName:"",
            validateEvent:""

        }
    }

    componentDidMount() {
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
           
            

            setTimeout(() => {
                if(navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(this.getAttendanceGeoLocation,this.checkingLocationServiceEnabledError)
                }
            }, 3000);

           

            this.getActiveEventsForAttendance();
        }
        

       
        
    }

    getActiveEventsForAttendance = () => {
        this.setState({eventLoadingSymbol:"show"});
        const obj =  {}

        getAPICallDataForOnlyBSA(obj,"Event/getActiveEventsForAttendance")
        .then((res) => res.json())
        .then((data) => {
            this.setState({eventLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let arr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                arr.push(obj);
                
            });
            this.setState({ event_listArr: arr});
            this.setState({
                    eventValue: {
                    value: data[0].id,
                    label: data[0].name
                },eventName:data[0].id
            });

          }else{
            this.setState({ event_listArr:[]});
          }
        });
    }

    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;

        if(name == "professionIdStr"){
            this.setState({validateEvent:""});
            let getEventIdName = this.state.event_listArr.find((a) => a.value == value);
            this.setState({
                eventValue: {
                value: getEventIdName.value,
                label: getEventIdName.label
            }
        });
    
           
        }
          
    
        this.setState({ [name]: value});
      }

    getAttendanceGeoLocation = (position) => {
        const getCoordinates =  
            { 
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude,
            "accuracy":position.coords.accuracy
            }
            
        this.setState({getAttendenceLatitudeLongitudeObj:getCoordinates});
        
    }
    
    checkingLocationServiceEnabledError = (error) => {
        return(
          <EnabledLocationPopup 
                  show={() => this.setState({ checkingEnabledLocationModal: true })}
                  onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                  closeEnabledModal={this.closeEnabledModal}
           />
        )
          
      }
    
      
      closeEnabledModal = () => {
        this.props.history.push({
            pathname: "/qr_attendence",
            params: {
                navbarDisplay:"show",
                userAccessList:this.state.userAccessList,
            
            },
        });
        
    }

    handleScan = async (data) => {
     console.log(`loaded >>>`, data);
        if (data && data !== "") {
            this.setState({validateScanData:""})
          console.log(`loaded >>>`, data);
          this.setState({ scanData:data });
          this.setState({ loadingScan:false });
      }
    }
  
    handleError = (err) => {
      console.error(err);
    };

    retakeScan = () => {
        this.setState({ scanData:"" });
        this.setState({ loadingScan:true });
    }

    saveUserAttendance = () => {
        this.setState({validateScanData:""})
        if(this.state.scanData == ""){
            this.setState({validateScanData:"Please Read Your QRCODE...."})
        }

        var combinedConsIdUserId="",constituencyId=0,fromUserId=0;

        

        this.setState({saveAttendanceLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            "chatId" : this.state.chatID,
            "eventId" : this.state.eventValue.value,
            "latitude" : this.state.getAttendenceLatitudeLongitudeObj.latitude,
            "longitude" : this.state.getAttendenceLatitudeLongitudeObj.longitude,
            "scanTxt":this.state.scanData,
            "uniqueKey" : crypto.randomUUID()
            
        }); 

        getAPICallDataForOnlyBSA(obj,"Event/saveEventAttendanceByBotWithQRCode")
        .then((res) => res.json())
        .then((data) => {
            this.setState({saveAttendanceLoadingSymbol:"hide"});
            if(data !=null && data.status == "Success"){
            
                this.setState({ saveAttendanceMessage: "Attended SuccessFully..." });

                /*setTimeout(() => {
                    this.setState({saveAttendanceMessage:"",
                                  
                            });
    
                }, 1000);*/

            }else if(data !=null && data.status == "Fail"){
                this.setState({saveAttendanceMessage:data.message});
        
            }else{
                this.setState({ saveAttendanceMessage: "SomeThing Went Wrong,Please Try Agian..."});
            }
        });

    }

    reloadCam = () => {
        const constraints = {
            audio: false,
            video: true,
          };
          
        this.setState({loadingScan:true})
        navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
            const video = document.querySelector("video");
            video.srcObject = mediaStream;
            video.onloadedmetadata = () => {
            video.play();
            };
        })
        .catch((err) => {
            // always check for errors at the end.
            console.error(`${err.name}: ${err.message}`);
        });
          
    }

    
    render() {
        return (
            <section>
                <Container>
                    <Row>
                        <Col>
                            <Link to={{pathname: "/dashboard"}}> 
                                <Button variant="outline-dark pad5_14 ms-2 float-right" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <h6 className='text_center fw-bold f-18'>Scan Your QR CODE</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3' >
                            <label className="form-label f-12 fw-bold">Event <span className='color_red f-14 ms-1'>*</span> <span className='color_red f-12 fw-bold'>{this.state.validateEvent}</span>
                                {
                                    this.state.eventLoadingSymbol == "show" ?
                                    (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                    :
                                    ("")
                                }
                            </label>
                            
                            <SelectBox
                                    stateData={this.state.eventValue}
                                    handleChange={this.handleOnchangeSelectBoxNew}
                                    name="eventName"
                                    options={this.state.event_listArr}
                                    placeholderName={'Event'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                />
                                
                        </Col>
                    </Row>
                    {
                        this.state.scanData == "" ?
                        (
                            <Row>
                                <Col className='mt-3'>
                                    <h6 className='f-12 color_red'>Note : Camara is Not Available,Please Click on Reload Button</h6>
                                    <Button variant="danger" className='f-12 float-right float-right btn-sm' onClick={() => this.reloadCam()}> Reload Webcam</Button>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                    
                    
                    <Row>
                        <Col className='mt-3 center-auto'>
                            {this.state.loadingScan && (
                            <>
                                
                                <QrReader
                                 constraints={ {facingMode: 'environment'} }
                                 //facingMode={{facingMode: 'user'}}
                                delay={1000}
                                onError={this.handleError}
                                onScan={this.handleScan}
                                // chooseDeviceId={()=>selected}
                                //style={{ width: "300px" }}
                                />
                            </>
                            )}
                            {
                                this.state.loadingScan == true ?
                                (<h6 className='f-12 color_red mt-3'>Note : Scan CUBS QR Code from BOT , then click on "Submit" button below.</h6>)
                                :
                                ("")
                            }

                            {
                                this.state.loadingScan == true ? 
                                (
                                    <h6 className='f-14 text-center mt-3'>
                                        Please Show Your QR Code  <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>
                                    </h6>
                                )
                                :
                                ("")
                            }

                            {
                                this.state.scanData !="" ?
                                (
                                    <>
                                    <h6 className='f-18 color_green text-center mt-1'>Reading Completed</h6>

                                    <Row>
                                        <Col>
                                            <Button variant="warning" className='f-14 btn-md float-right mt-2 fw-bold' onClick={() => this.retakeScan()}>RE-TAKE SCAN</Button> 
                                        </Col>
                                    </Row>
                                    </>
                                )
                                :
                                ("")
                            }
                            
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <h6 className='f-12 color_red mb-2'>{this.state.validateScanData}</h6>
                            <Button variant="success" className='f-18 center-auto mt-4 fw-bold' onClick={() => this.saveUserAttendance()}>SUBMIT
                                {
                                    this.state.saveAttendanceLoadingSymbol == "show" ?
                                    (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                    :
                                    ("")
                                }
                            </Button> 
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h6 className='f-16 text-center color_green mt-3'>{this.state.saveAttendanceMessage}</h6>
                        </Col>
                    </Row>
                </Container>
            
            </section>
        )
    }
  }
  
  export default withRouter(AttendenceSection);
  