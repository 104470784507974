import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';


class LanguageSelectionPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="top100">
                <Modal.Body className="p-3">
                      <Row>
                         <Col>
                            <h4 className='text-center color_red'>Please Select Language</h4>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Form>
                                <div className='mt-4 mb-4 text-center'>
                                <Form.Check
                                    inline
                                    label={`English Version`}
                                    name="languageSelection"
                                    type="radio"
                                    id={`englishVersionId`}
                                    onChange={(e)=>this.props.typeOfLanguageSelection(e,"english")}
                                    className="f-14 fw-bold color_black cursor_pointer"
                                    value="english"
                                />
                                <Form.Check
                                    inline
                                    label={`తెలుగు వెర్షన్`}
                                    name="languageSelection"
                                    type="radio"
                                    id={`teleguVersionId`}
                                    onChange={(e)=>this.props.typeOfLanguageSelection(e,"telegu")}
                                    className="f-14 fw-bold color_black cursor_pointer"
                                    value="telegu"
                                />
                                </div>
                            </Form>
                                
                        </Col>
                    </Row>
                            
                        
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default LanguageSelectionPopup;
  

