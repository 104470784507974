import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import {Container, Row, Col,Image,Button,Card,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "./electionActivities.css";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import moment from "moment";
import Webcam from "react-webcam";
import AddPartySeniorOrInfluencers from "./AddPartySeniorOrInfluencers";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
var captureQuestionsImagesArr=[];
var uploadQuestionsImagesArr=[];


const colourStylesValEA = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let colorCode="";
      if(data.isVisited == "N"){
        colorCode =  '#b03a2e';
      }else{
        colorCode =  "#229954";
      }
      return {
        ...styles,
        color: colorCode
    }
    },
  }


class ElectionActivitiesSaving extends Component {
  constructor(props) {
    super(props)

      this.state = {
        webcamRef : React.createRef(),
        chatID: window.User !=undefined ? window.User.chatID : "943827154",
        userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
        
        haveSurvey:props?.location?.params?.haveSurvey == undefined ? "" : props?.location?.params?.haveSurvey,
        activityLocationId:props?.location?.params?.activityLocationId == undefined ? "" : props?.location?.params?.activityLocationId,
        subActivityName:props?.location?.params?.subActivityName == undefined ? "" : props?.location?.params?.subActivityName,
        subActivityId:props?.location?.params?.subActivityId == undefined ? "" : props?.location?.params?.subActivityId,

        mainActivityIdName:props?.location?.params?.mainActivityIdName == undefined ? "" : props?.location?.params?.mainActivityIdName,
        activityDayCodeIdName:props?.location?.params?.activityDayCodeIdName == undefined ? "" : props?.location?.params?.activityDayCodeIdName,
        activityPanchayatIdName:props?.location?.params?.activityPanchayatIdName == undefined ? "" : props?.location?.params?.activityPanchayatIdName,

        getCurrentLocationCoordinatesForEAImages:props?.location?.params?.getCurrentLocationCoordinatesForEAImages == undefined ? "" : props?.location?.params?.getCurrentLocationCoordinatesForEAImages,


        videoConstraints:{facingMode: "environment"},
        videoConstraintsQuestion:{facingMode: "environment"},

        validateActivityImageUpload:"",
        showActivityImgCapture:false,
        imageAddedMsgForActivity:"",
        photoCaptureForActivityArr:[],
        submitButtonDisabledForActivity:true,
        savaActivityImageLoadingSymbol:"hide",
        saveActivityImagesSuccessMsg:"",
        selectedTypePhoto:"upload",
        manualUploadImage:"",
        camaraRadioButton:false,
        uploadImageRadioButton:true,

        


        activitySurveyDetailsArr:null,

        savaActivityQuestionsLoadingSymbol:"hide",
        saveActivityQuestionSuccessMsg:"",

        answerDateTemplateArr:[],
        alreadyUpdatedKPIsArr:[],

        questionDisabledButton:false,
        questionCameraId:"",
        photoCaptureQuestionImages:[],

        openCameraForImages:false,

        influencerTypeVal:"PS",
        partyPerson:"",
        partyPersonValue:"",
        partyPersonArr:[],
        partyPersonIsVisitedType:"",

        ValidatePersonName:"",
        partyPersonName:"",
        ValidatePartyPersonMobileNo:"",
        partyPersonMobileNo:"",

        party_ids_Arr:[{'value':872,'label':'TDP'},{'value':1117,'label':'YSRC'},{'value':1892,'label':'JSP'},{'value':163,'label':'BJP'},{'value':1887,'label':'Others'}],
        partyIdStr:"872",
        partyIdStrValue:{'value':872,'label':'TDP'},
        
        ValidatepartyPersonParty:"",
        partyPersonDetails:"",
        ValidatepartyPersonDetails:"",

        camaraRadioButtonActivityInfluencer:true,
        uploadImageRadioButtonActivityInfluencer:false,

        selectedTypePhotoActivityInfluencer:"camara",
        validateImageUploadActivityInfluencer:"",
        showActivityImgCaptureActivityInfluencer:true,
        validateUploadImageActivityInfluencer:"",
        photoCaptureForActivityArrActivityInfluencer:[],

        submitButtonDisabledForActivityActivityInfluencer:false,
        savaLoadingSymbolActivityInfluencer:"hide",
        saveSuccessMsgActivityInfluencer:"",

        activityInfluencerModalShow:false,

        openCameraForImages:false,


        openCameraForPersonChecking:true,
        camaraRadioButtonForPersonChecking:true,
        uploadImageRadioButtonForPersonChecking:false,
        selectedTypePhotoForPersonChecking:"camara",
        showActivityImgCaptureForPersonChecking:true,
        manualUploadImageForPersonChecking:"",
        photoCaptureForActivityForPersonCheckingArr:[],
        submitButtonDisabledForActivityForPersonChecking:true,
        savaActivityImageLoadingSymbolForPersonChecking:"hide",
        saveActivityImagesSuccessMsgForPersonChecking:"",
        validateActivityImageUploadforCheckingPerson:""
        
        

      }

      this.uploadMultipleFilesForPhotos = this.uploadMultipleFilesForPhotos.bind(this);
      this.uploadMultipleFilesForPhotosActivityInfluencer = this.uploadMultipleFilesForPhotosActivityInfluencer.bind(this);
      this.uploadMultipleFilesForPhotosForPersonChecking = this.uploadMultipleFilesForPhotosForPersonChecking.bind(this);
      //this.uploadMultipleFilesForPhotosForQuestions = this.uploadMultipleFilesForPhotosForQuestions.bind(this)
  }


  componentDidMount() {

    if(this.state.userAccessList == "" || this.state.campaignId == ""){
        this.props.history.push("/dashboard");
    }else{
        
        setTimeout(() => {
           
        }, 2000);
        

        if(this.state.haveSurvey != "N"){
            this.getActivitySurveyDetails();
        }else{
            this.setState({activitySurveyDetailsArr:[]})
        }

        if(this.state.haveSurvey == "N"){
            this.setState({openCameraForImages:true})
        }

        this.setState({partyPersonIsVisitedType:""})
        if(this.state.subActivityId == 9){
            this.setState({influencerTypeVal:"AO"})
            this.getActivityInfluencersByConstituencyId('AO',this.state.userAccessList.constituencyList[0].id);
        }else if(this.state.subActivityId == 15){
            this.setState({influencerTypeVal:"NI"})
            this.getActivityInfluencersByConstituencyId('NI',this.state.userAccessList.constituencyList[0].id);
        }else{
            this.getActivityInfluencersByConstituencyId('PS',this.state.userAccessList.constituencyList[0].id);
        }
        
       
    }
  }

  
   
switchCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "environment"}});
  }
  frontCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "user"}});
  }

getActivitySurveyDetails = () => {
  
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "activityLocationId" : this.state.activityLocationId
        }); 

        getAPICallDataForOnlyBSA(obj,"Activity/getActivitySurveyDetails")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){

                const modifiedActivitySurveyDtsArr =  json.map((item) => {
                    return { ...item, camaraRadioButtonForQuestion:false,uploadRadioButtonForQuestion:true,questionImagesArr:[],webcamRefQ : React.createRef() }
                });

                this.setState({ activitySurveyDetailsArr:modifiedActivitySurveyDtsArr});

                const updatedKPIS = modifiedActivitySurveyDtsArr.filter(a => typeof(a.activityAnswer) != 'undefined').map((item => {
                    if(item.answerType == "Single Option"){
                        if(item.imageStatus == "Mandatory"){
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,optionId:item.activityAnswer.optionId,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,base64StrList:[],imageStatus:item.imageStatus}
                        }else{
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,optionId:item.activityAnswer.optionId,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,imageStatus:item.imageStatus}
                        }
                        
                    }else if(item.answerType == "Multi Option"){
                        if(item.imageStatus == "Mandatory"){
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,optionId:item.activityAnswer.optionId,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,base64StrList:[],imageStatus:item.imageStatus}
                        }else{
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,optionId:item.activityAnswer.optionId,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,imageStatus:item.imageStatus}
                        }
                        
                    }else if(item.answerType == "Number"){
                        if(item.imageStatus == "Mandatory"){
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,answerNumber:item.activityAnswer.answerNumber,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,base64StrList:[],imageStatus:item.imageStatus}
                        }else{
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,answerNumber:item.activityAnswer.answerNumber,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,imageStatus:item.imageStatus}
                        }
                        
                    }else if(item.answerType == "Text"){
                        if(item.imageStatus == "Mandatory"){
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,answerText:item.activityAnswer.answerText,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,base64StrList:[],imageStatus:item.imageStatus}
                        }else{
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,answerText:item.activityAnswer.answerText,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,imageStatus:item.imageStatus}
                        }
                        
                    }else if(item.answerType == "Percentage"){
                        if(item.imageStatus == "Mandatory"){
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,answerPercentage:item.activityAnswer.answerPercentage,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,base64StrList:[],imageStatus:item.imageStatus}
                        }else{
                            return {activityLocationId:this.state.activityLocationId,questionId:item.questionId,answerPercentage:item.activityAnswer.answerPercentage,latitude:item.activityAnswer.latitude,longitude:item.activityAnswer.longitude,imageStatus:item.imageStatus}
                        }
                        
                    }
                    
                }))
                
        
                console.log("updatedKPIS",updatedKPIS)
                this.setState({alreadyUpdatedKPIsArr:updatedKPIS});

            }else{
                 this.setState({activitySurveyDetailsArr:[]});
            }
          
        });
}


removeCapturePhotoFromActivity = (fileId) => {
    var tempArr = this.state.photoCaptureForActivityArr;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);
  
    if (objWithIdIndex > -1) {
      tempArr.splice(objWithIdIndex, 1);
    }
  
    this.setState({photoCaptureForActivityArr:tempArr})
  
  }
  
    
  captureForActivityImageUpload = () => {
    this.setState({showActivityImgCapture:true,imageAddedMsgForActivity:""});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        data : this.state.webcamRef.current.getScreenshot(),
      }

      this.setState({imageAddedMsgForActivity:"Capture Photo added successfully in below..."});
      setTimeout(() => {
        this.setState({photoCaptureForActivityArr:[...this.state.photoCaptureForActivityArr, obj]}); 
        this.setState({imageAddedMsgForActivity:"",validateActivityImageUpload:""});
    }, 1000);
    this.setState({submitButtonDisabledForActivity:false});
};

reloadCam = (questionId) => {
    const constraints = {
        audio: false,
        video: true,
      };
    
      const cameraAccessDataArr =  this.state.activitySurveyDetailsArr.map((data) => {
        if(data.questionId == questionId){
            return { ...data, questionWiseCamaraAccess:true  }
        }else{
            return {...data}
        }
    });
    this.setState({ activitySurveyDetailsArr:cameraAccessDataArr});
    
    //this.setState({videoConstraints:{facingMode: "environment"}});
    navigator.mediaDevices
    .getUserMedia(constraints)
    .then((mediaStream) => {
        const video = document.querySelector("video");
        video.srcObject = mediaStream;
        video.onloadedmetadata = () => {
        video.play();
        };
    })
    .catch((err) => {
        // always check for errors at the end.
        console.error(`${err.name}: ${err.message}`);
    });
      
}



saveActivityImages = () => {
    this.setState({validateActivityImageUpload:""});
    
    if(this.state.selectedTypePhoto == "camara"){
        if(this.state.photoCaptureForActivityArr.length == 0){
            this.setState({validateActivityImageUpload:"Please Take A Photo Atleast One..."});
            return;
        }
    }else if(this.state.selectedTypePhoto == "upload"){
        if(this.state.photoCaptureForActivityArr.length == 0){
            this.setState({validateActivityImageUpload:"Please Upload Atleast One..."});
            return;
        }
    }
    
    this.getsaveActivityImagesBelowBlock();
    this.setState({savaActivityImageLoadingSymbol:"show"});  

    
}



getsaveActivityImagesBelowBlock = () => {
    var base64ImgListArr=[];

    if(this.state.photoCaptureForActivityArr.length > 0){
        for(var i in this.state.photoCaptureForActivityArr){
            if(this.state.selectedTypePhoto == "upload"){
                base64ImgListArr.push(this.state.photoCaptureForActivityArr[i].data.replace("data:"+this.state.photoCaptureForActivityArr[i].fileType+";base64,",""));
            }else{
                base64ImgListArr.push(this.state.photoCaptureForActivityArr[i].data.replace("data:image/jpeg;base64,",""));
            }
            
        }
    }

    setTimeout(() => {
    const obj =  
    JSON.stringify({ 
        "chatId" :this.state.chatID,
        "activityLocationId" : this.state.activityLocationId,
        "activityPanchayatId":this.state.activityPanchayatIdName.value,
        "latitude":this.state.getCurrentLocationCoordinatesForEAImages.latitude,
        "longitude":this.state.getCurrentLocationCoordinatesForEAImages.longitude,
        "base64StrList" : base64ImgListArr
    });
    
    

    getAPICallDataForOnlyBSA(obj,"Activity/saveActivityImages")
    .then((res) => res.json())
    .then((content) => {
        this.setState({savaActivityImageLoadingSymbol:"hide"});  

        if(content !=null && content.status == "Success"){
            this.setState({saveActivityImagesSuccessMsg:"Submitted SuccessFully..."});
             setTimeout(() => {
                this.setState({submitButtonDisabledForActivity:true});
                this.setState({saveActivityImagesSuccessMsg:""});
                this.setState({photoCaptureForActivityArr:[],showActivityImgCapture:true,
                                 imageAddedMsgForActivity:""});

                this.props.history.push({
                    pathname: "/election_activities",
                    params: {
                        navbarDisplay:"show",
                        userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                        reDirectMainActivityId:this.state.mainActivityIdName.value,
                        activityDayCodeIdName:this.state.activityDayCodeIdName.value,
                        activityPanchayatIdName:this.state.activityPanchayatIdName.value
                    },
                }); 

            }, 1500);

        }else if(content !=null && content.status == "Fail"){
            this.setState({saveActivityImagesSuccessMsg:content.message});
    
        }else{
            this.setState({saveActivityImagesSuccessMsg:"Some Thing Went Wrong,Please Try Again..."});
    
        }
    });
 }, 1200);   
}




selectCaptureType = (type) => {
    this.setState({photoCaptureForActivityArr:[],manualUploadImage:""});
   
        
    if(type == "upload"){
      this.setState({ camaraRadioButton:false,uploadImageRadioButton:true});
      this.setState({showActivityImgCapture:false});
    }else{
      this.setState({showActivityImgCapture:true})
      this.setState({ camaraRadioButton:true,uploadImageRadioButton:false});
    }
    

    this.setState({selectedTypePhoto:type});
  }

  toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

  uploadMultipleFilesForPhotos = (e) => {
    this.setState({manualUploadImage:""});
    const getUID = (length=8) => {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

    let fileObj=[];
      fileObj.push(e.target.files);
          for (let i = 0; i < fileObj[0].length; i++) {
            if(fileObj[0][i].type == "image/jpeg" || fileObj[0][i].type == "image/png" || fileObj[0][i].type == "image/jpg"){
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    var obj ={
                        fileId:getUID(),
                        fileType:fileObj[0][i].type,
                        data:dataUrl,
                    }
                    this.setState({photoCaptureForActivityArr:[...this.state.photoCaptureForActivityArr, obj]});
                    this.setState({submitButtonDisabledForActivity:false});
                });
            }else{
                this.setState({manualUploadImage:"Please Upload Only JPG/PNG/JPEG Files..."});
                return;
            }

              
          }

          
  }


  
handleOnchangeInputBox = (e) => {
    console.log("handleInputChange", e.target.name, e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    
    
    this.setState({ [name]: value });
    
}

handleOnchangeAllowAlphabiticalInputBox = () => {
    this.setState({ValidatepartyPersonDetails:""});
    var regex =  /^[a-zA-Z ]*$/;
    if (!regex.test(this.state.partyPersonDetails)) {
        this.setState({ValidatepartyPersonDetails:"Please Enter Only Alphabets...",partyPersonDetails:"" });
        return true;
    } 
  }

  handleOnchangeAllowNumbersInputBox = (e) => {
    this.setState({ValidatePartyPersonMobileNo:""});
    const name = e.target.name;
    const value = e.target.value;
    const re = /^[0-9\b]+$/;

    if(name == "partyPersonMobileNo"){
        if (!re.test(e.target.value)) {
            this.setState({ValidatePartyPersonMobileNo:"Enter Numbers Only..",partyPersonMobileNo:""});
            return;
        }else{
            this.setState({ValidatePartyPersonMobileNo:""});
        }
    }
  }

  showImagesWiseCameraOpenForPersonChecking = () => {
    this.setState({questionCameraId:""});
    this.setState({openCameraForPersonChecking:!this.state.openCameraForPersonChecking });
    this.setState({openCameraForImages:!this.state.openCameraForImages });
  }
  showImagesWiseCameraOpen = () => {
    this.setState({questionCameraId:""});
    this.setState({openCameraForImages:!this.state.openCameraForImages });
    this.setState({openCameraForPersonChecking:!this.state.openCameraForPersonChecking });
  }

showQuestionWiseCameraOpen = (questionId) => {
    this.setState({openCameraForImages:!this.state.openCameraForImages });
    this.setState({openCameraForPersonChecking:!this.state.openCameraForPersonChecking });

    var imageValidationStr = "imageValidation"+questionId+"";
    document.getElementById(imageValidationStr).innerHTML = "";

    if(questionId == this.state.questionCameraId){
        this.setState({questionCameraId:""});
    }else{
        this.setState({questionCameraId:questionId});
    }

    setTimeout(() => {
        //this.reloadCam(questionId);
    }, 2000);
    
    //this.setState({filterBlockOpenVAC:!this.state.filterBlockOpenVAC });
}

selectCaptureTypeForQuestion = (type,questionId) => {

    if(type == "camara"){
        const radioButtonQuestionArr =  this.state.activitySurveyDetailsArr.map((data) => {
            if(data.questionId == questionId){
                return { ...data, camaraRadioButtonForQuestion:true,uploadRadioButtonForQuestion:false,questionImagesArr:[]  }
            }else{
                return {...data}
            }
        });
        this.setState({ activitySurveyDetailsArr:radioButtonQuestionArr});
        
    }else{
        const radioButtonQuestionArr1 =  this.state.activitySurveyDetailsArr.map((data) => {
            if(data.questionId == questionId){
                return { ...data, camaraRadioButtonForQuestion:false,uploadRadioButtonForQuestion:true,questionImagesArr:[]  }
            }else{
                return {...data}
            }
        });
        this.setState({ activitySurveyDetailsArr:radioButtonQuestionArr1});

    }
}

uploadMultipleFilesForPhotosForQuestions = (e,item) => {
   // uploadQuestionsImagesArr
   var imageValidationStr = "imageValidation"+item.questionId+"";
   document.getElementById(imageValidationStr).innerHTML = "";

   let fileObj=[];
      fileObj.push(e.target.files);
          for (let i = 0; i < fileObj[0].length; i++) {
            if(fileObj[0][i].type == "image/jpeg" || fileObj[0][i].type == "image/png" || fileObj[0][i].type == "image/jpg"){
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    var obj ={
                        fileType:fileObj[0][i].type,
                        data:dataUrl,

                    }
                    const uploadArr =  this.state.activitySurveyDetailsArr.map((data) => {
                        if(data.questionId == item.questionId){
                            //uploadQuestionsImagesArr.push(obj)
                            return { ...data,questionImagesArr:[...data.questionImagesArr,obj]}
                        }else{
                            return {...data}
                        }
                    });
                    this.setState({activitySurveyDetailsArr:uploadArr})

                    
                });
            }else{
                var imageValidationStr = "imageValidation"+item.questionId+"";
                document.getElementById(imageValidationStr).innerHTML = "Please Upload Only JPG/PNG/JPEG Files...";
                return;
                
            }

              
          }

}


captureForActivityQuestionImage = (item) => {
    
    const getUID = (length=8) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }
  
      var obj={
          //fileId : getUID(),
          //questionId:item.questionId,
          fileType:"image/jpeg",
          data : item.webcamRefQ.current.getScreenshot(),
        }

        const imagesArr =  this.state.activitySurveyDetailsArr.map((data) => {
            if(data.questionId == item.questionId){
                //captureQuestionsImagesArr.push(obj)
                return { ...data,questionImagesArr:[...data.questionImagesArr,obj]}
            }else{
                return {...data}
            }
        });
       // setTimeout(() => {
            this.setState({activitySurveyDetailsArr:imagesArr})
        // }, 500);
       
        /*setTimeout(() => {
          this.setState({photoCaptureQuestionImages:[...this.state.photoCaptureQuestionImages, obj]}); 
        }, 1000);*/
}

getOnchangeSelectedKPIAnswer = (e,data,mainlist) => {
    const name = e.target.name;
    const value = e.target.value;

    var newItem = {};
    var tempArr = this.state.answerDateTemplateArr;
    var tempArr1 = this.state.alreadyUpdatedKPIsArr;

    const objWithIdIndex1 = tempArr1.findIndex((obj) => obj.questionId == mainlist.questionId);

    if (objWithIdIndex1 > -1) {
        tempArr1.splice(objWithIdIndex1, 1);
        this.setState({alreadyUpdatedKPIsArr:tempArr1})
    }

    const objWithIdIndex = tempArr.findIndex((obj) => obj.questionId == mainlist.questionId);

    if (objWithIdIndex > -1) {
        tempArr.splice(objWithIdIndex, 1);
        this.setState({answerTemplateArr:tempArr})
    }


    if (mainlist.answerType == "Single Option") {
        newItem = {
            activityLocationId:this.state.activityLocationId,
            questionId: mainlist.questionId,
            optionId: value, 
            latitude:this.state.getCurrentLocationCoordinatesForEAImages.latitude,
            longitude:this.state.getCurrentLocationCoordinatesForEAImages.longitude,
            imageStatus:mainlist.imageStatus,
            base64StrList:[]
        };
    }else if(mainlist.answerType == "Number"){
        newItem = {
            activityLocationId:this.state.activityLocationId,
            questionId: mainlist.questionId,
            answerNumber: value, 
            latitude:this.state.getCurrentLocationCoordinatesForEAImages.latitude,
            longitude:this.state.getCurrentLocationCoordinatesForEAImages.longitude,
            imageStatus:mainlist.imageStatus,
            base64StrList:[]
        };
    }else if(mainlist.answerType == "Text"){
        newItem = {
            activityLocationId:this.state.activityLocationId,
            questionId: mainlist.questionId,
            answerText: value, 
            latitude:this.state.getCurrentLocationCoordinatesForEAImages.latitude,
            longitude:this.state.getCurrentLocationCoordinatesForEAImages.longitude,
            imageStatus:mainlist.imageStatus,
            base64StrList:[]
        };
    }else if(mainlist.answerType == "Percentage"){
        newItem = {
            activityLocationId:this.state.activityLocationId,
            questionId: mainlist.questionId,
            answerPercentage: value, 
            latitude:this.state.getCurrentLocationCoordinatesForEAImages.latitude,
            longitude:this.state.getCurrentLocationCoordinatesForEAImages.longitude,
            imageStatus:mainlist.imageStatus,
            base64StrList:[]
        };
    }else if(mainlist.answerType == "Multi Option"){
        newItem = {
            activityLocationId:this.state.activityLocationId,
            questionId: mainlist.questionId,
            optionId: value, 
            latitude:this.state.getCurrentLocationCoordinatesForEAImages.latitude,
            longitude:this.state.getCurrentLocationCoordinatesForEAImages.longitude,
            imageStatus:mainlist.imageStatus,
            base64StrList:[]
        };
    }

    this.setState({answerDateTemplateArr:[...this.state.answerDateTemplateArr, newItem]}); 
}

saveActivityQuestions = () => {
   

    
    

  let finalMergeKPIAnswerDts = [...this.state.answerDateTemplateArr,...this.state.alreadyUpdatedKPIsArr];

  

  //clear 
    for(var i in this.state.activitySurveyDetailsArr){
        var errorQuestion = "errorMsgDisplay"+this.state.activitySurveyDetailsArr[i].questionId+"";
        document.getElementById(errorQuestion).innerHTML = "";

        if(this.state.activitySurveyDetailsArr[i].imageStatus == "Mandatory"){
            var imageValidationStr = "imageValidation"+this.state.activitySurveyDetailsArr[i].questionId+"";
            document.getElementById(imageValidationStr).innerHTML = "";
        }
        
    }

   const validationsArr =  this.state.activitySurveyDetailsArr.filter(item => !finalMergeKPIAnswerDts.some(finalMergeKPIAnswerDts => finalMergeKPIAnswerDts.questionId == item.questionId))
    
   if(validationsArr.length > 0){
    for(var i in validationsArr){
        var errorQuestion = "errorMsgDisplay"+validationsArr[i].questionId+"";
        
        if(validationsArr[i].answerType == "Single Option"){
            document.getElementById(errorQuestion).innerHTML = "Please Check Option";
            return;
        }else if(validationsArr[i].answerType == "Multi Option"){
            document.getElementById(errorQuestion).innerHTML = "Please Check Atleast one Option";
            return;
        }else if(validationsArr[i].answerType == "Number"){
            document.getElementById(errorQuestion).innerHTML = "Please Enter Number";
            return;
        }else if(validationsArr[i].answerType == "Text"){
            document.getElementById(errorQuestion).innerHTML = "Please Enter Text";
            return;
        }else if(validationsArr[i].answerType == "Percentage"){
            document.getElementById(errorQuestion).innerHTML = "Please Enter Percentage";
            return;
        }
        
    }
    
   }

   if(this.state.activitySurveyDetailsArr.length > 0){
    for(var i in finalMergeKPIAnswerDts){
            for(var j in this.state.activitySurveyDetailsArr){
                if(finalMergeKPIAnswerDts[i].questionId == this.state.activitySurveyDetailsArr[j].questionId){
                    for(var k in this.state.activitySurveyDetailsArr[j].questionImagesArr){
                        finalMergeKPIAnswerDts[i].base64StrList.push(this.state.activitySurveyDetailsArr[j].questionImagesArr[k].data.replace("data:"+this.state.activitySurveyDetailsArr[j].questionImagesArr[k].fileType+";base64,",""));
                    }
                    
                    //finalMergeKPIAnswerDts[i].base64StrList = this.state.activitySurveyDetailsArr[j].questionImagesArr
                }
            }
        }
    }

    /*finalMergeKPIAnswerDts.filter(a => {
       return a.base64StrList = [...new Set(a.base64StrList)]
    });*/


   
   for(var i in finalMergeKPIAnswerDts){ 
        if(finalMergeKPIAnswerDts[i].imageStatus == "Mandatory"){
            if(finalMergeKPIAnswerDts[i].base64StrList.length == 0){
                var imageValidationStr = "imageValidation"+finalMergeKPIAnswerDts[i].questionId+"";
                document.getElementById(imageValidationStr).innerHTML = "Please Capture Atleast One Image";
                return;
            }
           
        }
     
    }

   

    //console.log("photoCaptureQuestionImages1",validationsArr)
    console.log("photoCaptureQuestionImages1",finalMergeKPIAnswerDts)

    this.setState({savaActivityQuestionsLoadingSymbol:"show"});
    this.setState({questionDisabledButton:true})

    getAPICallDataForOnlyBSA(JSON.stringify(finalMergeKPIAnswerDts),"Activity/saveActivityAnswer")
        .then((res) => res.json())
        .then((data) => {
            this.setState({savaActivityQuestionsLoadingSymbol:"hide"});
            this.setState({successMessageModal:true})
            if(data !=null && data.status == "Success"){
            
                this.setState({ saveActivityQuestionSuccessMsg: "Saved SuccessFully..." });

                setTimeout(() => {
                    this.setState({questionDisabledButton:false})
                    this.setState({ saveActivityQuestionSuccessMsg: "" });
                    this.props.history.push({
                        pathname: "/election_activities",
                        params: {
                            navbarDisplay:"show",
                            userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                            reDirectMainActivityId:this.state.mainActivityIdName.value,
                            activityDayCodeIdName:this.state.activityDayCodeIdName.value,
                            activityPanchayatIdName:this.state.activityPanchayatIdName.value
                          },
                      }); 
    
                }, 2000);

            }else if(data !=null && data.status == "Fail"){
                this.setState({saveActivityQuestionSuccessMsg:data.message});
        
            }else{
                this.setState({ saveActivityQuestionSuccessMsg: "SomeThing Went Wrong,Please Try Agian..."});
            }
        });
}


getOnchangeRadioPSNI = (e,type) => {
    this.setState({
        partyPerson:"",
        partyPersonValue:"",
        partyPersonArr:[],
        partyPersonIsVisitedType:""
    });

    this.setState({influencerTypeVal:type})
    this.getActivityInfluencersByConstituencyId(type,this.state.userAccessList.constituencyList[0].id);
}


handleOnchangeSelectBox = (ename, evalue) =>{
    const name = ename;
    const value = evalue;


    if(name == "partyPerson"){
      let getIdName = this.state.partyPersonArr.find((a) => a.value == value);
        this.setState({
            partyPersonValue: {
              value: getIdName.value,
              label: getIdName.label
          },partyPersonIsVisitedType:getIdName.isVisited
      });


      
    }else if(name == "partyIdStr"){
        let getIdName = this.state.party_ids_Arr.find((a) => a.value == value);
        this.setState({
            partyIdStrValue: {
              value: getIdName.value,
              label: getIdName.label
          }
      });
    }

    this.setState({ [name]: value});
  }


  getActivityInfluencersByConstituencyId = (type,constituencyId) => {
    const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "constituencyId" : constituencyId,
            "type" : type
        }); 

        getAPICallDataForOnlyBSA(obj,"Activity/getActivityInfluencersByConstituencyId")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){
                var arr=[];
                for(var i in  json){
                    var obj={'value':json[i].activityInfluencerId,'label':json[i].personName,'isVisited':json[i].isVisited};
                    arr.push(obj)
                    
                }
                this.setState({ partyPersonArr:arr});
            }else{
                 this.setState({partyPersonArr:[]});
            }
          
        });
  }

  saveActivityInfluencerModal = () => {
    this.setState({
        ValidatePersonName:"",
        partyPersonName:"",
        ValidatePartyPersonMobileNo:"",
        partyPersonMobileNo:"",

        partyIdStr:"872",
        partyIdStrValue:{'value':872,'label':'TDP'},
        
        ValidatepartyPersonParty:"",
        partyPersonDetails:"",
        ValidatepartyPersonDetails:"",

        camaraRadioButtonActivityInfluencer:true,
        uploadImageRadioButtonActivityInfluencer:false,

        selectedTypePhotoActivityInfluencer:"camara",
        validateImageUploadActivityInfluencer:"",
        showActivityImgCaptureActivityInfluencer:true,
        validateUploadImageActivityInfluencer:"",
        photoCaptureForActivityArrActivityInfluencer:[],

        submitButtonDisabledForActivityActivityInfluencer:false,
        savaLoadingSymbolActivityInfluencer:"hide",
        saveSuccessMsgActivityInfluencer:"",
    });

    this.setState({activityInfluencerModalShow:true});
  }

  
  selectCaptureTypeForActivityInfluencer = (type) => {
    this.setState({photoCaptureForActivityArrActivityInfluencer:[],validateUploadImageActivityInfluencer:"",validateImageUploadActivityInfluencer:""});
   
        
    if(type == "upload"){
      this.setState({ camaraRadioButtonActivityInfluencer:false,uploadImageRadioButtonActivityInfluencer:true});
      this.setState({showActivityImgCaptureActivityInfluencer:false});
    }else{
      this.setState({ camaraRadioButtonActivityInfluencer:true,uploadImageRadioButtonActivityInfluencer:false});
      this.setState({showActivityImgCaptureActivityInfluencer:true});
    }
    

    this.setState({selectedTypePhotoActivityInfluencer:type});
  }

  
  removeCapturePhotoFromActivityActivityInfluencer = (fileId) => {
    var tempArr = this.state.photoCaptureForActivityArrActivityInfluencer;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);
  
    if (objWithIdIndex > -1) {
      tempArr.splice(objWithIdIndex, 1);
    }
  
    this.setState({photoCaptureForActivityArrActivityInfluencer:tempArr})
  
  }
  
    
  captureForActivityImageUploadActivityInfluencer = () => {
    this.setState({showActivityImgCaptureActivityInfluencer:true});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        data : this.state.webcamRef.current.getScreenshot(),
      }

      setTimeout(() => {
        this.setState({photoCaptureForActivityArrActivityInfluencer:[...this.state.photoCaptureForActivityArrActivityInfluencer, obj]}); 
        this.setState({validateImageUploadActivityInfluencer:""});
    }, 1000);
    //this.setState({submitButtonDisabledForActivityActivityInfluencer:false});
};



uploadMultipleFilesForPhotosActivityInfluencer = (e) => {
    this.setState({validateUploadImageActivityInfluencer:""});
    const getUID = (length=8) => {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

    let fileObj=[];
      fileObj.push(e.target.files);
          for (let i = 0; i < fileObj[0].length; i++) {
            if(fileObj[0][i].type == "image/jpeg" || fileObj[0][i].type == "image/png" || fileObj[0][i].type == "image/jpg"){
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    var obj ={
                        fileId:getUID(),
                        fileType:fileObj[0][i].type,
                        data:dataUrl,
                    }
                    this.setState({photoCaptureForActivityArrActivityInfluencer:[...this.state.photoCaptureForActivityArrActivityInfluencer, obj]});
                    //this.setState({submitButtonDisabledForActivityActivityInfluencer:false});
                });
            }else{
                this.setState({validateUploadImageActivityInfluencer:"Please Upload Only JPG/PNG/JPEG Files..."});
                return;
            }

              
          }

          
  }

  
  saveActivityInfluencer = () => {
    this.setState({validateUploadImageActivityInfluencer:"",validateImageUploadActivityInfluencer:"",ValidatePersonName:"",ValidatePartyPersonMobileNo:"",ValidatepartyPersonParty:""});
    
    if(this.state.partyPersonName == "" || this.state.partyPersonName == 0){
        this.setState({ValidatePersonName:"Please Enter Name"});
        return;
    }

    if(this.state.partyPersonMobileNo == ""){
        this.setState({ValidatePartyPersonMobileNo:"Please Mobile No" });
        return;
    }  

    if(!this.state.partyPersonMobileNo.match(/^[0-9]+$/)){
        this.setState({ValidatePartyPersonMobileNo:"Please Enter Valid Mobile No" });
        return;
        }

    if(this.state.partyPersonMobileNo.length < 10){
        this.setState({ValidatePartyPersonMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    if(this.state.influencerTypeVal == "PS"){
        if(this.state.partyIdStr == ""){
            this.setState({ValidatepartyPersonParty:"Please Select Party..." });
            return;
        }
    }
    

    if(this.state.selectedTypePhotoActivityInfluencer == "camara"){
        if(this.state.photoCaptureForActivityArrActivityInfluencer.length == 0){
            this.setState({validateImageUploadActivityInfluencer:"Please Take A Photo Atleast One..."});
            return;
        }
    }else if(this.state.selectedTypePhotoActivityInfluencer == "upload"){
        if(this.state.photoCaptureForActivityArrActivityInfluencer.length == 0){
            this.setState({validateUploadImageActivityInfluencer:"Please Upload Atleast One..."});
            return;
        }
    }
    
    
    this.getsaveActivityInfluencer();
    this.setState({savaLoadingSymbolActivityInfluencer:"show"});

    
}



getsaveActivityInfluencer = () => {
    var base64ImgListArr=[];

    if(this.state.photoCaptureForActivityArrActivityInfluencer.length > 0){
        for(var i in this.state.photoCaptureForActivityArrActivityInfluencer){
            if(this.state.selectedTypePhotoActivityInfluencer == "upload"){
                base64ImgListArr.push(this.state.photoCaptureForActivityArrActivityInfluencer[i].data.replace("data:"+this.state.photoCaptureForActivityArr[i].fileType+";base64,",""));
            }else{
                base64ImgListArr.push(this.state.photoCaptureForActivityArrActivityInfluencer[i].data.replace("data:image/jpeg;base64,",""));
            }
            
        }
    }
    this.setState({submitButtonDisabledForActivityActivityInfluencer:true});
    setTimeout(() => {
    const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "activityLocationId" : this.state.activityLocationId,
            "constituencyId" : this.state.userAccessList.constituencyList[0].id,
            "partyId" : this.state.partyIdStr,
            "influencerType" : this.state.influencerTypeVal,
            "personName" : this.state.partyPersonName,
            "mobileNo" : this.state.partyPersonMobileNo,
            "details" : this.state.partyPersonDetails,
            "latitude":this.state.getCurrentLocationCoordinatesForEAImages.latitude,
            "longitude":this.state.getCurrentLocationCoordinatesForEAImages.longitude,
            "base64StrList" : base64ImgListArr
    });
    
    

    getAPICallDataForOnlyBSA(obj,"Activity/saveActivityInfluencer")
    .then((res) => res.json())
    .then((content) => {
        this.setState({savaLoadingSymbolActivityInfluencer:"hide"});  

        if(content !=null && content.status == "Success"){
            if(this.state.subActivityId == 9){
                this.setState({saveSuccessMsgActivityInfluencer:"Association added successfully..."});
            }else{
                this.setState({saveSuccessMsgActivityInfluencer:"Person added successfully..."});
            }
           
             setTimeout(() => {
                this.setState({submitButtonDisabledForActivityActivityInfluencer:false,activityInfluencerModalShow:false});
                this.setState({saveSuccessMsgActivityInfluencer:""});
                this.setState({photoCaptureForActivityArrActivityInfluencer:[],showActivityImgCapture:true});
                this.setState({partyPersonIsVisitedType:"Y"});
                this.setState({
                    openCameraForPersonChecking:true,
                    camaraRadioButtonForPersonChecking:true,
                    uploadImageRadioButtonForPersonChecking:false,
                    selectedTypePhotoForPersonChecking:"camara",
                    showActivityImgCaptureForPersonChecking:true,
                    manualUploadImageForPersonChecking:"",
                    photoCaptureForActivityForPersonCheckingArr:[],
                    submitButtonDisabledForActivityForPersonChecking:true,
                    savaActivityImageLoadingSymbolForPersonChecking:"hide",
                    saveActivityImagesSuccessMsgForPersonChecking:"",
                    validateActivityImageUploadforCheckingPerson:""
                })
                this.getActivityInfluencersByConstituencyId(this.state.influencerTypeVal,this.state.userAccessList.constituencyList[0].id);
            }, 1500);

        }else if(content !=null && content.status == "Fail"){
            this.setState({saveSuccessMsgActivityInfluencer:content.message});
    
        }else{
            this.setState({saveSuccessMsgActivityInfluencer:"Some Thing Went Wrong,Please Try Again..."});
    
        }
    });
 }, 1200);   
}




selectCaptureTypeForPersonChecking = (type) => {
    this.setState({photoCaptureForActivityForPersonCheckingArr:[],manualUploadImageForPersonChecking:"",validateActivityImageUploadforCheckingPerson:""});
   
        
    if(type == "upload"){
      this.setState({ camaraRadioButtonForPersonChecking:false,uploadImageRadioButtonForPersonChecking:true});
      this.setState({showActivityImgCaptureForPersonChecking:false});
    }else{
      this.setState({showActivityImgCaptureForPersonChecking:true})
      this.setState({ camaraRadioButtonForPersonChecking:true,uploadImageRadioButtonForPersonChecking:false});
    }
    

    this.setState({selectedTypePhotoForPersonChecking:type});
  }

removeCapturePhotoFromActivityForPersonChecking = (fileId) => {
    var tempArr = this.state.photoCaptureForActivityForPersonCheckingArr;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);
  
    if (objWithIdIndex > -1) {
      tempArr.splice(objWithIdIndex, 1);
    }
  
    this.setState({photoCaptureForActivityForPersonCheckingArr:tempArr})
  
  }
  
    
  captureForActivityImageUploadForPersonChecking = () => {
    this.setState({showActivityImgCaptureForPersonChecking:true,validateActivityImageUploadforCheckingPerson:""});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        data : this.state.webcamRef.current.getScreenshot(),
      }

      setTimeout(() => {
        this.setState({photoCaptureForActivityForPersonCheckingArr:[...this.state.photoCaptureForActivityForPersonCheckingArr, obj]}); 
        this.setState({validateActivityImageUploadforCheckingPerson:""});
    }, 1000);
    this.setState({submitButtonDisabledForActivityForPersonChecking:false});
};
  


uploadMultipleFilesForPhotosForPersonChecking = (e) => {
    this.setState({manualUploadImageForPersonChecking:""});
    const getUID = (length=8) => {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

    let fileObj=[];
      fileObj.push(e.target.files);
          for (let i = 0; i < fileObj[0].length; i++) {
            if(fileObj[0][i].type == "image/jpeg" || fileObj[0][i].type == "image/png" || fileObj[0][i].type == "image/jpg"){
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    var obj ={
                        fileId:getUID(),
                        fileType:fileObj[0][i].type,
                        data:dataUrl,
                    }
                    this.setState({photoCaptureForActivityForPersonCheckingArr:[...this.state.photoCaptureForActivityForPersonCheckingArr, obj]});
                    //this.setState({submitButtonDisabledForActivityActivityInfluencer:false});
                });
            }else{
                this.setState({manualUploadImageForPersonChecking:"Please Upload Only JPG/PNG/JPEG Files..."});
                return;
            }

              
          }

          
  }


  
saveActivityImagesForPersonChecking = () => {

    if(this.state.selectedTypePhotoForPersonChecking == "camara"){
        if(this.state.photoCaptureForActivityForPersonCheckingArr.length == 0){
            this.setState({validateActivityImageUploadforCheckingPerson:"Please Take A Photo Atleast One..."});
            return;
        }
    }else if(this.state.selectedTypePhotoForPersonChecking == "upload"){
        if(this.state.photoCaptureForActivityForPersonCheckingArr.length == 0){
            this.setState({manualUploadImageForPersonChecking:"Please Upload Atleast One..."});
            return;
        }
    }
    
    
    

    var base64ImgListArr=[];

    if(this.state.photoCaptureForActivityForPersonCheckingArr.length > 0){
        for(var i in this.state.photoCaptureForActivityForPersonCheckingArr){
            if(this.state.selectedTypePhotoForPersonChecking == "upload"){
                base64ImgListArr.push(this.state.photoCaptureForActivityForPersonCheckingArr[i].data.replace("data:"+this.state.photoCaptureForActivityArr[i].fileType+";base64,",""));
            }else{
                base64ImgListArr.push(this.state.photoCaptureForActivityForPersonCheckingArr[i].data.replace("data:image/jpeg;base64,",""));
            }
            
        }
    }
    this.setState({savaActivityImageLoadingSymbolForPersonChecking:"show"});

    //this.setState({submitButtonDisabledForActivityForPersonChecking:true});
    setTimeout(() => {
    const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "activityLocationId" : this.state.activityLocationId,
            "activityInfluencerId" : this.state.partyPerson,
            "latitude":this.state.getCurrentLocationCoordinatesForEAImages.latitude,
            "longitude":this.state.getCurrentLocationCoordinatesForEAImages.longitude,
            "base64StrList" : base64ImgListArr
    });
    
    

    getAPICallDataForOnlyBSA(obj,"Activity/saveActivityInfluencer")
    .then((res) => res.json())
    .then((content) => {
        this.setState({savaActivityImageLoadingSymbolForPersonChecking:"hide"});  

        if(content !=null && content.status == "Success"){
            this.setState({saveActivityImagesSuccessMsgForPersonChecking:"Visited successfully..."});
             setTimeout(() => {
                this.setState({submitButtonDisabledForActivityForPersonChecking:false});
                //this.setState({saveActivityImagesSuccessMsgForPersonChecking:""});
                this.setState({photoCaptureForActivityForPersonCheckingArr:[]});
                this.getActivityInfluencersByConstituencyId(this.state.influencerTypeVal,this.state.userAccessList.constituencyList[0].id);
                this.setState({partyPersonIsVisitedType:"Y"})
            }, 1200);

        }else if(content !=null && content.status == "Fail"){
            this.setState({saveActivityImagesSuccessMsgForPersonChecking:content.message});
    
        }else{
            this.setState({saveActivityImagesSuccessMsgForPersonChecking:"Some Thing Went Wrong,Please Try Again..."});
    
        }
    });
 }, 1200);   
}


  render() {
    return (
      <section>
        <Container fluid>
            
            <Row>
                <Col>
                    <Link to={{pathname: "/election_activities",
                                params: {
                                navbarDisplay:"show",
                                userAccessList:this.state.userAccessList,
                                reDirectMainActivityId:this.state.mainActivityIdName.value,
                                activityDayCodeIdName:this.state.activityDayCodeIdName.value,
                                activityPanchayatIdName:this.state.activityPanchayatIdName.value
                            }}}> 
                        <Button variant="outline-dark pad5_14 ms-2 float-right" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                    </Link>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p class="text-muted f-16 fw-bold  mt-2">Main Activity -  <small className='color_black'> {this.state.mainActivityIdName.label}</small></p>        
                    <p class="text-muted f-14  fw-bold  mt-1">Sub Activity -  <small className='color_black'> {this.state.subActivityName} </small></p>        
                </Col>
            </Row>
                {
                    this.state.subActivityId == 7 || this.state.subActivityId == 15 || this.state.subActivityId == 9 ?
                    (
                        <Row>
                            <Col>
                                <fieldset class="mt-3"><legend className="f-14">
                                    {
                                        this.state.subActivityId == 7 ?
                                        ("Party Seniors Or Neutral Influencers")
                                        :
                                        (
                                            this.state.subActivityId == 9 ?
                                            ("Associations Outreach")
                                            :
                                            ("Neutral Influencers")
                                        )
                                    }
                                    
                                    </legend>
                                    <div className="text-center">
                                        <Form>
                                            {
                                                this.state.subActivityId == 7 ?
                                                (
                                                    <>
                                                    <Form.Check
                                                        inline
                                                        label={`Party Seniors`}
                                                        name={`influencersName`}
                                                        id={`partySeniorsId`}
                                                        type="radio"
                                                        className="f-14"
                                                        value='PS'
                                                        onChange={(e)=>this.getOnchangeRadioPSNI(e,'PS')}
                                                        defaultChecked={true}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label={`Neutral Influencers`}
                                                        name={`influencersName`}
                                                        id={`neutralInfluencersId`}
                                                        type="radio"
                                                        className="f-14"
                                                        value='NI'
                                                        onChange={(e)=>this.getOnchangeRadioPSNI(e,'NI')}
                                                        defaultChecked={false}
                                                    />
                                                    </>
                                                )
                                                :
                                                (
                                                   this.state.subActivityId == 9 ?
                                                   (
                                                        <>
                                                            <Form.Check
                                                            inline
                                                            label={`Associations Outreach`}
                                                            name={`influencersName`}
                                                            id={`association OutreachId`}
                                                            type="radio"
                                                            className="f-14"
                                                            value='AO'
                                                            onChange={(e)=>this.getOnchangeRadioPSNI(e,'AO')}
                                                            defaultChecked={true}
                                                        />
                                                        </>
                                                   )
                                                   :
                                                   (
                                                        <>
                                                        <Form.Check
                                                        inline
                                                        label={`Neutral Influencers`}
                                                        name={`influencersName`}
                                                        id={`neutralInfluencersId`}
                                                        type="radio"
                                                        className="f-14"
                                                        value='NI'
                                                        onChange={(e)=>this.getOnchangeRadioPSNI(e,'NI')}
                                                        defaultChecked={true}
                                                    />
                                                    </>
                                                   )
                                                )
                                            }
                                           
                                        </Form>
                                    </div>

                                    <Row>
                                        <Col>
                                            <Form.Group  controlId="formPersonId">
                                                <Form.Label className='f-12 fw-bold'>{this.state.subActivityId == 9 ? "Association Name" : "Person"} </Form.Label>
                                                <SelectBox
                                                    stateData={this.state.partyPersonValue}
                                                    handleChange={this.handleOnchangeSelectBox}
                                                    name="partyPerson"
                                                    options={this.state.partyPersonArr}
                                                    placeholderName={this.state.subActivityId == 9 ? "Select  Name" : "Select Person"}
                                                    dropdownIndicator={{ DropdownIndicator }}
                                                    styles={colourStylesValEA}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Button type="button" className="btn btn-sm btn-danger fw-bold f-12 mt-top-30" onClick={() => this.saveActivityInfluencerModal()}><i class="fa-solid fa-circle-plus"></i> {this.state.subActivityId == 9 ? "ADD New Association" : "ADD New Person"} </Button>
                                        </Col>
                                    </Row>

                                    {
                                        this.state.partyPersonIsVisitedType == 'Y' ?
                                        (
                                            <>
                                            <Row>
                                                <Col>
                                                    <h6 className="mt-3 f-14">{this.state.subActivityId == 9 ? "Association Name" : "Person Name"} : <b>{this.state.partyPersonValue.label}</b></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="text-center f-16 color_green fw-bold mt-3">{this.state.subActivityId == 9 ? "This Association Already Visited..." : "This Person Already Visited..."} </h6>
                                                </Col>
                                            </Row>
                                            </>
                                        )
                                        :
                                        (
                                            this.state.partyPersonIsVisitedType == 'N' ? 
                                            (
                                                <>
                                                <Row>
                                                    <Col>
                                                        <h6 className="mt-3 f-14">{this.state.subActivityId == 9 ? "Association Name" : "Person Name"} : <b>{this.state.partyPersonValue.label}</b></h6>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                <Col>
                                                   {/* <fieldset class="mt-3"><legend className="f-14">లైవ్ ఫోటో తీయటం లేదా ఫోటో అప్లోడ్ చేయండి</legend>*/}
                                                        <Row>
                                                            <Col>
                                                                <h6 className="color_red f-14 fw-bold mt-3">లైవ్ ఫోటో తీయండి  <span className="color_red f-12 text-decoration-underline cursor_pointer" onClick={() => this.showImagesWiseCameraOpenForPersonChecking()}>{this.state.openCameraForPersonChecking == true ? "Hide Camera" : "Show camara"}</span> </h6>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            this.state.openCameraForPersonChecking == true ?
                                                            (
                                                                <>
                                                                <Row>
                                                                <Col sm={12}>
                                                                    <Form className="mt-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label={"లైవ్ ఫోటో తీయటం"}
                                                                                name="image_capture"
                                                                                type="radio"
                                                                                id="image_capture_upload"
                                                                                onChange={()=>this.selectCaptureTypeForPersonChecking("camara")}
                                                                                className="f-12 fw-bold"
                                                                                checked={this.state.camaraRadioButtonForPersonChecking}
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="ఫోటో అప్లోడ్ చేయండి"
                                                                                name="image_capture_upload"
                                                                                type="radio"
                                                                                id="upload_photos"
                                                                                onChange={()=>this.selectCaptureTypeForPersonChecking("upload")}
                                                                                className="f-12 fw-bold"
                                                                                checked={this.state.uploadImageRadioButtonForPersonChecking}
                                                                            />
                                                                    </Form>
                                                                </Col>
                                                                </Row>
                                    
                                                                {
                                                                    this.state.selectedTypePhotoForPersonChecking == "camara" ?
                                                                    (
                                                                        <Row>
                                                                            <Col className="mt-2">
                                                                                    <h6 className="f-12 color_red">{this.state.validateActivityImageUploadforCheckingPerson}</h6>
                                                                                    <div className="showImgCaptureDivBSBG">{this.state.showActivityImgCaptureForPersonChecking && (
                                                                                    <> 
                                                                                    <Webcam
                                                                                        audio={false}
                                                                                        ref={this.state.webcamRef}
                                                                                        screenshotFormat="image/jpeg"
                                                                                        videoConstraints={this.state.videoConstraints}
                                                                                    /> 
                                                                                    
                                                                                    
                                                                                    <div className="row mt-2">
                                                                                        <div className="col">
                                                                                            <h6 onClick={() => this.captureForActivityImageUploadForPersonChecking()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            {
                                                                                            this.state.videoConstraints.facingMode == "user" ?
                                                                                            (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                                                                            :
                                                                                            ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                    
                                                                                    
                                                                                    </>
                                                                                    
                                                                                    )}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                    )
                                                                    :
                                                                    (
                                                                        <Row>
                                                                            <Col className="mt-2">
                                                                            <div className="form-group">
                                                                                <h6 className='f-12 color_red'></h6>
                                                                                <label for="uploadfilesForLive" class="drop-container">
                                                                                    <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                                                                    <input type="file" id="uploadfilesForLive" className='f-14'  multiple onChange={this.uploadMultipleFilesForPhotosForPersonChecking}/>
                                                                                </label>
                                                                            </div>
                                                                            <h6 className='f-12 color_red mt-2'>{this.state.manualUploadImageForPersonChecking}</h6>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }
                                    
                                                                {
                                                                    this.state.photoCaptureForActivityForPersonCheckingArr.length>0 ?
                                                                    (
                                                                    <>
                                                                        <Row>
                                                                        <Col sm={12} className="mt-2">
                                                                            {
                                                                                this.state.selectedTypePhotoForPersonChecking == "camara" ? 
                                                                                (<h6>Capture Photos : </h6>)
                                                                                :
                                                                                (<h6>Upload Images: </h6>)
                                                                            }
                                                                        
                                                                        </Col>
                                                                        <Col sm={12} className="">
                                                                            <div className='flex_img'>
                                                                                {(this.state.photoCaptureForActivityForPersonCheckingArr || []).map((url,index) => (
                                                                                    <div className='ms-1 border_img_pad'>
                                                                                        <i class="fa-solid fa-circle-xmark" onClick={() => this.removeCapturePhotoFromActivityForPersonChecking(url.fileId)}></i>
                                                                                        <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                                                    </div>
                                                                                ))} 
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    </>
                                                                    
                                                                    
                                                                    )
                                                                    :
                                                                    ("")
                                                                }
                                    
                                                                
                                                                </>
                                                            )
                                                            :
                                                            ("")
                                                        }

                                                        <Row>
                                                                <Col sm={12}>
                                                                
                                                                    <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.state.submitButtonDisabledForActivityForPersonChecking} onClick={() => this.saveActivityImagesForPersonChecking()}>SUBMIT </Button>
                                                                    
                                                                    {
                                                                        this.state.savaActivityImageLoadingSymbolForPersonChecking == "show" ? 
                                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                                                        :
                                                                        ("")
                                                                    }
                                                                    <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.state.saveActivityImagesSuccessMsgForPersonChecking}</h6>
                                                                </Col>
                                                            </Row>
                                                    
                                                        
                                                      {/*  </fieldset>*/}
                                                </Col>
                                            </Row>
                                            </>
                                            )
                                            :
                                            ("")
                                            
                                        )
                                    }
                                    
                                </fieldset>
                            </Col>
                        </Row>
                    )
                    :
                    ("")
                }
                
                            {
                                this.state.activitySurveyDetailsArr == null ?
                                (
                                    <Row>
                                        <Col sm={12}>
                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>
                                    </Row>
                                )
                                :
                                (
                                    this.state.activitySurveyDetailsArr.length == 0 ? 
                                    (
                                        <Row>
                                            <Col>
                                                <h6 className='f-14 text-center mt-4'></h6>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    (
                                        <>
                                        {
                                            this.state.activitySurveyDetailsArr !=null &&  this.state.activitySurveyDetailsArr.length > 0 ?
                                            (
                                                <Row>
                                                    <Col>
                                                        <fieldset class="mt-3"><legend className="f-14">ఈ కార్యక్రమం జరిగిన విధానం గురించి కొన్ని ప్రశ్నలు</legend>
                                                        {
                                                             this.state.activitySurveyDetailsArr.map((item,index) => {
                                                                return(
                                                                    <div className='border-dashed mt-2'>
                                                                    <Row>
                                                                        <Col>
                                                                            <h6 className='f-14'><span className='f-12 bg_round'>{index+1}</span> {item.question} <span className='color_red f-12 ml-22'>*</span></h6>
                                                                            {
                                                                                item.answerType == "Single Option" ?
                                                                                (
                                                                                    <>
                                                                                    <Form>
                                                                                        <div className=''>
                                                                                            {
                                                                                                item.optionsList.map((item2,indexValue) => {
                                                                                                    return(
                                                                                                        
                                                                                                        <Form.Check
                                                                                                            inline
                                                                                                            label={`${item2.name}`}
                                                                                                            name={`${item.questionId}-${item.answerType.trim()}`}
                                                                                                            id={`id-${item.questionId}-${item.answerType.trim()}`}
                                                                                                            type="radio"
                                                                                                            className="f-16"
                                                                                                            value={item2.id}
                                                                                                            onChange={(e)=>this.getOnchangeSelectedKPIAnswer(e,item2,item)}
                                                                                                            defaultChecked={typeof(item.activityAnswer) == "undefined" ? false : item.activityAnswer.optionId == item2.id ? true : false}
                                                                                                        />
                                                                                                       
                                                                                                            
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                        </Form>
                                                                                        <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                        </>
                                                                                    
                                                                                )
                                                                                :
                                                                                (
                                                                                    item.answerType == "Multi Option" ?  
                                                                                    (
                                                                                        <>
                                                                                        <Form>
                                                                                            <div className=''>
                                                                                                {
                                                                                                    item.optionsList.map((item2,indexValue) => {
                                                                                                        return(
                                                                                                           
                                                                                                            <Form.Check
                                                                                                                inline
                                                                                                                label={`${item2.name}`}
                                                                                                                id={`idCheckbox-${item.questionId}-${item.answerType.trim()}`}
                                                                                                                type="checkbox"
                                                                                                                className="f-16"
                                                                                                                value={item2.id}
                                                                                                                onChange={(e)=>this.getOnchangeSelectedKPIAnswer(e,item2,item)}
                                                                                                                defaultChecked={typeof(item.activityAnswer) == "undefined" ? false : item.activityAnswer.optionId == item2.id ? true : false}
                                                                                                            />  
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </Form>
                                                                                        <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                        </>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        item.answerType == "Text" ?  
                                                                                        (
                                                                                            <>
                                                                                                {
                                                                                                    typeof(item.activityAnswer) == "undefined" ?
                                                                                                    (
                                                                                                        <>
                                                                                                        <Form.Control type="text" name="answerText"  id={`stringAll${item.questionId}`}  className='form-control-sm' defaultValue=""   placeholder="Enter Text" onChange={(e)=>{this.getOnchangeSelectedKPIAnswer(e,'',item);this.handleOnchangeInputBox(e)}} />
                                                                                                        <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (
                                                                                                        <>
                                                                                                        <Form.Control type="text" name={item.activityAnswer.answerText}  id={`stringAll${item.questionId}`}  className='form-control-sm' defaultValue={item.activityAnswer.answerText}   placeholder="Enter Text" onChange={(e)=>{this.getOnchangeSelectedKPIAnswer(e,'',item);this.handleOnchangeInputBox(e)}} />
                                                                                                        <h6 className='color_red f-12 mt-2 fw-bold' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            </>
                                                                                            
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            item.answerType == "Number" ?  
                                                                                            (
                                                                                                <>
                                                                                                {
                                                                                                    typeof(item.activityAnswer) == "undefined" ?
                                                                                                    (
                                                                                                        <>
                                                                                                        <Form.Control type="text" name="answerNumber"   id={`NumberAll${item.questionId}`}  className='form-control-sm' defaultValue=""   placeholder="Enter Number" onChange={(e)=>{this.getOnchangeSelectedKPIAnswer(e,'',item);this.handleOnchangeInputBox(e)}} />
                                                                                                        <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (
                                                                                                        <>
                                                                                                        <Form.Control type="text" name={item.activityAnswer.answerNumber}   id={`NumberAll${item.questionId}`}  className='form-control-sm' defaultValue={item.activityAnswer.answerNumber}   placeholder="Enter Number" onChange={(e)=>{this.getOnchangeSelectedKPIAnswer(e,'',item);this.handleOnchangeInputBox(e)}} />
                                                                                                        <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                                </>
                                                                                                
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                item.answerType == "Percentage" ?  
                                                                                                (
                                                                                                    <>
                                                                                                        {
                                                                                                            typeof(item.activityAnswer) == "undefined" ?
                                                                                                            (
                                                                                                                <>
                                                                                                                <Form.Control type="text" name="answerPercentage"  id={`percentageAll${item.questionId}`}  className='form-control-sm' defaultValue=""   placeholder="Enter Percentage" onChange={(e)=>{this.getOnchangeSelectedKPIAnswer(e,'',item);this.handleOnchangeInputBox(e)}} />
                                                                                                                <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                                </>
                                                                                                            )
                                                                                                            :
                                                                                                            (
                                                                                                                <>
                                                                                                                <Form.Control type="text" name={item.activityAnswer.answerPercentage}  id={`percentageAll${item.questionId}`}  className='form-control-sm' defaultValue={item.activityAnswer.answerPercentage}   placeholder="Enter Percentage" onChange={(e)=>{this.getOnchangeSelectedKPIAnswer(e,'',item);this.handleOnchangeInputBox(e)}} />
                                                                                                                <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    </>

                                                                                                )
                                                                                                :
                                                                                                ("")
                                                                                            )
                                                                                        )
                                                                                    ) 
                                                                                )
                                                                                
                                                                            }

                                                                            {
                                                                                item.imageStatus == "Mandatory" || item.imageStatus == "Optional"  ?
                                                                                (
                                                                                    <Row>
                                                                                        <Form.Label className='f-12 fw-bold mt-2'>లైవ్ ఫోటో తీయండి
                                                                                        {
                                                                                            item.imageStatus == "Mandatory" ?
                                                                                            (<span className='color_red f-12 ml-22'>*</span>)
                                                                                            :
                                                                                            ("")
                                                                                        }

                                                                                        <span className="color_red f-12 text-decoration-underline cursor_pointer" onClick={() => this.showQuestionWiseCameraOpen(item.questionId)}>{item.questionId == this.state.questionCameraId ? "Hide Camera" : "Show camara"} </span>
                                                                                        
                                                                                        </Form.Label>
                                                                                        <span className='color_red f-12 fw-bold mt-2' id={`imageValidation${item.questionId}`}></span>

                                                                                        {
                                                                                            item.questionId == this.state.questionCameraId  ?
                                                                                            (
                                                                                                <>
                                                                                                <Row>
                                                                                                    <Col sm={12}>
                                                                                                        <Form className="">
                                                                                                            <Form.Check
                                                                                                                    inline
                                                                                                                    label={"లైవ్ ఫోటో"}
                                                                                                                    name={`image_capture-${item.questionId}}`}
                                                                                                                    type="radio"
                                                                                                                    id={`image_capture_upload-${item.questionId}`}
                                                                                                                    onChange={()=>this.selectCaptureTypeForQuestion("camara",item.questionId)}
                                                                                                                    className="f-12 fw-bold"
                                                                                                                    checked={item.camaraRadioButtonForQuestion}
                                                                                                                />
                                                                                                                <Form.Check
                                                                                                                    inline
                                                                                                                    label="ఫోటో అప్లోడ్"
                                                                                                                    name={`image_capture-${item.questionId}}`}
                                                                                                                    type="radio"
                                                                                                                    id={`upload_photos-${item.questionId}`}
                                                                                                                    onChange={()=>this.selectCaptureTypeForQuestion("upload",item.questionId)}
                                                                                                                    className="f-12 fw-bold"
                                                                                                                    checked={item.uploadRadioButtonForQuestion}
                                                                                                                />
                                                                                                        </Form>
                                                                                                    </Col>
                                                                                                    </Row>

                                                                                                    {
                                                                                                        item.camaraRadioButtonForQuestion == true ?
                                                                                                        (
                                                                                                            <div className="showImgCaptureDivBSBG">
                                                                                                                <> 
                                                                                                                <Webcam
                                                                                                                    audio={false}
                                                                                                                    ref={item.webcamRefQ}
                                                                                                                    screenshotFormat="image/jpeg"
                                                                                                                    videoConstraints={this.state.videoConstraintsQuestion}
                                                                                                                />
                                                                                                                <div className="row mt-3">
                                                                                                                    <div className="col">
                                                                                                                        <h6 onClick={() => this.captureForActivityQuestionImage(item)} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            
                                                                                                               
                                                                                                                    </>
                                                                                                                     
                                                                                                            </div>
                                                                                                        )
                                                                                                        :
                                                                                                        ("")
                                                                                                    }

                                                                                                    {
                                                                                                        item.uploadRadioButtonForQuestion == true ?
                                                                                                        (
                                                                                                            <Row>
                                                                                                                <Col className="mt-2">
                                                                                                                <div className="form-group">
                                                                                                                    <h6 className='f-12 color_red'></h6>
                                                                                                                    <label for="uploadfilesForLive" class="drop-container">
                                                                                                                        <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                                                                                                        <input type="file"  id={`uploadfilesForQuestions${item.questionId}`} className='f-14'  multiple onChange={(e) => this.uploadMultipleFilesForPhotosForQuestions(e,item)}/>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        )
                                                                                                        :
                                                                                                        ("")
                                                                                                    }

                                                                                                    {
                                                                                                        item.questionImagesArr.length>0 ?
                                                                                                        (
                                                                                                        <>
                                                                                                            <Row>
                                                                                                            
                                                                                                            <Col sm={12} className="">
                                                                                                                <div className='flex_img'>
                                                                                                                    {(item.questionImagesArr || []).map((url,index) => (
                                                                                                                        <div className='ms-1 border_img_pad'>
                                                                                                                            <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                                                                                        </div>
                                                                                                                    ))} 
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        </>
                                                                                                        
                                                                                                        
                                                                                                        )
                                                                                                        :
                                                                                                        ("")
                                                                                                    }
                                                                                                    
                                                                                                </>
                                                                                            )
                                                                                            :
                                                                                            ("")
                                                                                        }

                                                                                    </Row>
                                                                                )
                                                                                :
                                                                                ("")
                                                                            }
                                                                            
                                                                        
                                                                            
                                                                        </Col>
                                                                    </Row>
                                                                    </div>
                                                                )

                                                             })
                                                        }

                                                        <Row>
                                                                    <Col sm={12}>
                                                                    
                                                                        <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.state.questionDisabledButton}  onClick={() => this.saveActivityQuestions()}>SUBMIT </Button>
                                                                        
                                                                        {
                                                                            this.state.savaActivityQuestionsLoadingSymbol == "show" ? 
                                                                            (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                                                            :
                                                                            ("")
                                                                        }
                                                                        <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.state.saveActivityQuestionSuccessMsg}</h6>
                                                                    </Col>
                                                                </Row>

                                                    </fieldset>
                                                </Col>
                                            </Row>  
                                            )
                                            :
                                            ("")
                                        }
                                        </>
                                    )
                                )
                            }
                            
                                
            <Row>
                    <Col>
                        <fieldset class="mt-3"><legend className="f-14">లైవ్ ఫోటో తీయటం లేదా ఫోటో అప్లోడ్ చేయండి</legend>
                            <Row>
                                <Col>
                                    <h6 className="color_red f-14 fw-bold text-center">లైవ్ ఫోటో తీయండి  <span className="color_red f-12 text-decoration-underline cursor_pointer" onClick={() => this.showImagesWiseCameraOpen()}>{this.state.openCameraForImages == true ? "Hide Camera" : "Show camara"}</span> </h6>
                                </Col>
                            </Row>
                            {
                                this.state.openCameraForImages == true ?
                                (
                                    <>
                                    <Row>
                                    <Col sm={12}>
                                        <Form className="">
                                                <Form.Check
                                                    inline
                                                    label={"లైవ్ ఫోటో తీయటం"}
                                                    name="image_capture"
                                                    type="radio"
                                                    id="image_capture_upload"
                                                    onChange={()=>this.selectCaptureType("camara")}
                                                    className="f-12 fw-bold"
                                                    checked={this.state.camaraRadioButton}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="ఫోటో అప్లోడ్ చేయండి"
                                                    name="image_capture_upload"
                                                    type="radio"
                                                    id="upload_photos"
                                                    onChange={()=>this.selectCaptureType("upload")}
                                                    className="f-12 fw-bold"
                                                    checked={this.state.uploadImageRadioButton}
                                                />
                                        </Form>
                                    </Col>
                                    </Row>
        
                                    {
                                        this.state.selectedTypePhoto == "camara" ?
                                        (
                                            <Row>
                                                <Col className="mt-2">
                                                        <div className="showImgCaptureDivBSBG">{this.state.showActivityImgCapture && (
                                                        <> 
                                                        <Webcam
                                                            audio={false}
                                                            ref={this.state.webcamRef}
                                                            screenshotFormat="image/jpeg"
                                                            videoConstraints={this.state.videoConstraints}
                                                        /> 
                                                        
                                                        
                                                        <div className="row mt-2">
                                                            <div className="col">
                                                                <h6 onClick={() => this.captureForActivityImageUpload()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                                            </div>
                                                            <div className="col">
                                                                {
                                                                this.state.videoConstraints.facingMode == "user" ?
                                                                (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                                                :
                                                                ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                                                }
                                                            </div>
                                                        </div>
        
                                                        
                                                        </>
                                                        
                                                        )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                        )
                                        :
                                        (
                                            <Row>
                                                <Col className="mt-2">
                                                <div className="form-group">
                                                    <h6 className='f-12 color_red'></h6>
                                                    <label for="uploadfilesForLive" class="drop-container">
                                                        <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                                        <input type="file" id="uploadfilesForLive" className='f-14'  multiple onChange={this.uploadMultipleFilesForPhotos}/>
                                                    </label>
                                                </div>
                                                <h6 className='f-12 color_red mt-2'>{this.state.manualUploadImage}</h6>
                                                </Col>
                                            </Row>
                                        )
                                    }
        
                                    {
                                        this.state.photoCaptureForActivityArr.length>0 ?
                                        (
                                        <>
                                            <Row>
                                            <Col sm={12} className="mt-2">
                                                {
                                                    this.state.selectedTypePhoto == "camara" ? 
                                                    (<h6>Capture Photos : </h6>)
                                                    :
                                                    (<h6>Upload Images: </h6>)
                                                }
                                               
                                            </Col>
                                            <Col sm={12} className="">
                                                <div className='flex_img'>
                                                    {(this.state.photoCaptureForActivityArr || []).map((url,index) => (
                                                        <div className='ms-1 border_img_pad'>
                                                            <i class="fa-solid fa-circle-xmark" onClick={() => this.removeCapturePhotoFromActivity(url.fileId)}></i>
                                                            <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                        </div>
                                                    ))} 
                                                </div>
                                            </Col>
                                        </Row>
                                        </>
                                        
                                        
                                        )
                                        :
                                        ("")
                                    }
        
                                    
                                    </>
                                )
                                :
                                ("")
                            }

                            <Row>
                                    <Col sm={12}>
                                    
                                        <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.state.submitButtonDisabledForActivity} onClick={() => this.saveActivityImages()}>UPLOAD </Button>
                                        
                                        {
                                            this.state.savaActivityImageLoadingSymbol == "show" ? 
                                            (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                            :
                                            ("")
                                        }
                                        <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.state.saveActivityImagesSuccessMsg}</h6>
                                    </Col>
                                </Row>
                           
                            
                            </fieldset>
                    </Col>
                </Row>
                
                
                <AddPartySeniorOrInfluencers
                    show={this.state.activityInfluencerModalShow}
                    onHide={() => this.setState({ activityInfluencerModalShow: false })}
                    stateData={this.state}
                    handleOnchangeInputBox={this.handleOnchangeInputBox}
                    handleOnchangeAllowNumbersInputBox={this.handleOnchangeAllowNumbersInputBox}
                    handleOnchangeAllowAlphabiticalInputBox={this.handleOnchangeAllowAlphabiticalInputBox}
                    selectCaptureTypeForActivityInfluencer={this.selectCaptureTypeForActivityInfluencer}
                    captureForActivityImageUploadActivityInfluencer={this.captureForActivityImageUploadActivityInfluencer}
                    uploadMultipleFilesForPhotosActivityInfluencer={this.uploadMultipleFilesForPhotosActivityInfluencer}
                    removeCapturePhotoFromActivityActivityInfluencer={this.removeCapturePhotoFromActivityActivityInfluencer}
                    saveActivityInfluencer={this.saveActivityInfluencer}
                    handleOnchangeSelectBox={this.handleOnchangeSelectBox}
                />

                  
                    
        </Container>
        
    </section>
    )
  }
}

export default withRouter(ElectionActivitiesSaving);
