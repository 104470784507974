import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import {Container, Row, Col,Image,Button,Card,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "./electionActivities.css";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import moment from "moment";
import CardLessDataTableWithSearchLimit5 from '../datatables/CardLessDataTableWithSearchLimit5';
import ShowUploadImages from './ShowUploadImages';
import ActivityImageUpload from "./ActivityImageUpload";
import EnabledLocationPopup from "../Volunteer/EnabledLocationPopup";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class ElectionActivitiesSection extends Component {
  constructor(props) {
    super(props)

      this.state = {
        webcamRef : React.createRef(),
        chatID: window.User !=undefined ? window.User.chatID : "943827154",
        userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
        reDirectMainActivityId:props?.location?.params?.reDirectMainActivityId == undefined ? "" : props?.location?.params?.reDirectMainActivityId,
        activityDayCodeIdName:props?.location?.params?.activityDayCodeIdName == undefined ? "" : props?.location?.params?.activityDayCodeIdName,
        activityPanchayatIdName:props?.location?.params?.activityPanchayatIdName == undefined ? "" : props?.location?.params?.activityPanchayatIdName,

        ap_listArr:[],
        activityConstituencyValue:"",
        activityConstituency:"",


        mainActiveListArr:[],
        mainActivityValue:"",
        mainActivity:"",
        mainActivityType:"",

        subActivitiesByActivityIdArr:[],
        subActivitiesByActivityIdCheck:0,
        subActivityLoadingSymbol:"hide",

        
        issueFileDetailsArr:[],
        issueFileDetailsCheck:0,
        showUploadImagesModal:false,

        dayWiseSubActivitiesByActivityIdArr:null,
        dayWiseDataArr:[],

        daysLoadingSymbol:"hide",
        dayWiseSelectBoxArr:[],
        dayWiseSelectBoxValue:"",
        dayWiseSelectBox:"",
        validatedayCode:"",

        panchayatSelectBoxValue:"",
        panchayatSelectBox:"",
        panchayatSelectBoxArr:[],
        villageLoadingSymbol:"hide",
        validatePanchayatName:"",

        activityImageUploadModalNew:false,

        camaraRadioButtonNew:true,
        uploadImageRadioButtonNew:false,
        selectedTypePhotoNew:"camara",
        showActivityImgCaptureNew:true,
        manualUploadImageNew:"",
        photoCaptureForActivityArrNew:[],
        submitButtonDisabledForActivityNew:false,
        savaActivityImageLoadingSymbolNew:"hide",
        saveActivityImagesSuccessMsgNew:"",

        prajaGalamActivityColors:{'0':'#1d4999','1':'#d29500','2':'#4a8522','3':'#be5108','21':'#1d4999','22':'#d29500','23':'#4a8522'},

        getCurrentLocationCoordinatesForEAImages:{},

        checkingEnabledLocationModal:false,
        
      }
      this.uploadMultipleFilesForPhotosNew = this.uploadMultipleFilesForPhotosNew.bind(this)

      
  }


  componentDidMount() {

    if(this.state.userAccessList == "" ){
        this.props.history.push("/dashboard");
    }else{

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.getActivityImagesGeoLocations,this.checkingLocationServiceEnabledError)
            }else{
                <EnabledLocationPopup 
                    show={() => this.setState({ checkingEnabledLocationModal: true })}
                    onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                    closeEnabledModal={this.closeEnabledModal}
                />
            }
            
            var consArr=[];
                for(var i in  this.state.userAccessList.constituencyList){
                    var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                    consArr.push(obj);
                }
                this.setState({ ap_listArr:consArr });
                this.setState({
                    activityConstituencyValue: {
                    value: this.state.userAccessList.constituencyList[0].id,
                    label: this.state.userAccessList.constituencyList[0].name
                },activityConstituency:this.state.userAccessList.constituencyList[0].id
            });

            this.getActiveMainActivities();
        
            

            
            
    }
  }

  getActivityImagesGeoLocations = (position) => {
    const getCoordinates =  
        { 
        "latitude" : position.coords.latitude,
        "longitude" : position.coords.longitude,
        "accuracy":position.coords.accuracy
        }
        
    this.setState({getCurrentLocationCoordinatesForEAImages:getCoordinates});

}



checkingLocationServiceEnabledError = (error) => {
    return(
      <EnabledLocationPopup 
              show={() => this.setState({ checkingEnabledLocationModal: true })}
              onHide={() => this.setState({ checkingEnabledLocationModal: false })}
              closeEnabledModal={this.closeEnabledModal}
       />
    )
      
  }

closeEnabledModal = () => {
    this.props.history.push({
        pathname: "/election_activities",
        params: {
            navbarDisplay:"show",
            userAccessList:this.state.userAccessList,
        
        },
    });
    
}

  getPanchayatsByTehsilIdEA = (locationId) => {
    this.setState({villageLoadingSymbol:"show"});
    const obj =  
    JSON.stringify({ 
        "activityId" : 2,
        "constituencyId" : locationId
    }); 

    getAPICallDataForOnlyBSA(obj,"Activity/getActivityPanchayatsByConstituencyId")
    .then((res) => res.json())
    .then((data) => {
        this.setState({villageLoadingSymbol:"hide"});
      if(data !=null && data.length > 0){
        let parlArr=[];
        data.map(item => {
            const obj = {
                value: item.activityPanchayatId,
                label: item.panchayatName,
            }
            parlArr.push(obj);
            
        });
        this.setState({ panchayatSelectBoxArr: parlArr });

        if(this.state.reDirectMainActivityId != "" ){
            setTimeout(() => {
                let getIdName = this.state.panchayatSelectBoxArr.find((a) => a.value == this.state.activityPanchayatIdName);
                    this.setState({
                        panchayatSelectBoxValue: {
                        value: getIdName.value,
                        label: getIdName.label
                    },panchayatSelectBox:getIdName.value
                });
            }, 500);
         }

      }else{
        this.setState({ panchayatSelectBoxArr:[]});
      }
    });
}

  getActiveMainActivities = () => {
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID
        }); 

        getAPICallDataForOnlyBSA(obj,"Activity/getActiveMainActivities")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){
                var arr=[];
                var k =0;
                for(var i in  json){
                    k= k+1;
                    var obj={'value':json[i].activityId,'label':"STEP-"+k+" - "+json[i].activityName,'activityType':json[i].activityType};
                    arr.push(obj)
                    
                }
                this.setState({ mainActiveListArr:arr});
                
                if(this.state.reDirectMainActivityId != "" ){
                    this.setState({subActivitiesByActivityIdArr:[],subActivitiesByActivityIdCheck:0,subActivityLoadingSymbol:"hide"});
                    setTimeout(() => {
                        let getIdName = this.state.mainActiveListArr.find((a) => a.value == this.state.reDirectMainActivityId);
                            this.setState({
                                mainActivityValue: {
                                value: getIdName.value,
                                label: getIdName.label
                            },mainActivity:getIdName.value,mainActivityType:getIdName.activityType
                        });
                        
                        if(this.state.reDirectMainActivityId == 1 || this.state.reDirectMainActivityId == 4 ){
                            this.getSubActivitiesByActivityId(this.state.reDirectMainActivityId,"Activity/getSubActivitiesByActivityId");
                        }else if(this.state.reDirectMainActivityId == 2 ){
                            this.getDayWiseSubActivitiesByActivityId(this.state.reDirectMainActivityId);
                            this.getPanchayatsByTehsilIdEA(this.state.activityConstituency);
                        }else if(this.state.reDirectMainActivityId == 3 ){
                            this.getSubActivitiesByActivityId(this.state.reDirectMainActivityId,"Activity/getLocationWiseSubActivitiesByActivityId");
                        }
                    }, 500);
                }
               

            }else{
                 this.setState({mainActiveListArr:[]});
            }
          
        });
}

handleOnchangeSelectBox = (ename, evalue) =>{
    const name = ename;
    const value = evalue;

    this.setState({validatedayCode:""});

    if(name == "mainActivity"){
      let getIdName = this.state.mainActiveListArr.find((a) => a.value == value);
        this.setState({
            mainActivityValue: {
              value: getIdName.value,
              label: getIdName.label
          },mainActivity:getIdName.label,mainActivityType:getIdName.activityType
      });

      

      this.setState({subActivitiesByActivityIdArr:[],subActivitiesByActivityIdCheck:0,subActivityLoadingSymbol:"hide"});
      //getIdName.activityType == "One Time"

      if(getIdName.activityType == "One Time"){
        if (value == 3){
            this.getSubActivitiesByActivityId(value,"Activity/getLocationWiseSubActivitiesByActivityId");
          }else{
             this.getSubActivitiesByActivityId(value,"Activity/getSubActivitiesByActivityId");
          }
        
      }else if (value == 2){
        this.getDayWiseSubActivitiesByActivityId(value);
        this.getPanchayatsByTehsilIdEA(this.state.activityConstituency);
      }
      
    }else if(name == "dayWiseSelectBox"){
        this.setState({subActivitiesByActivityIdArr:[],subActivitiesByActivityIdCheck:0,subActivityLoadingSymbol:"hide"})
        let getIdName = this.state.dayWiseSelectBoxArr.find((a) => a.value == value);
        this.setState({
            dayWiseSelectBoxValue: {
              value: getIdName.value,
              label: getIdName.label
          }
      });
        const data = this.state.dayWiseSubActivitiesByActivityIdArr.filter(a => a.dayCode == value);
        this.setState({subActivitiesByActivityIdArr:data[0].activityList});
    }else if(name == "panchayatSelectBox"){
        this.setState({validatePanchayatName:""})
        let getIdName = this.state.panchayatSelectBoxArr.find((a) => a.value == value);
        this.setState({
            panchayatSelectBoxValue: {
              value: getIdName.value,
              label: getIdName.label
          }
      });
        
    }

    this.setState({ [name]: value});
  }


  getDayWiseSubActivitiesByActivityId = (activityId) => {
    this.setState({daysLoadingSymbol:"show"})
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "activityId" : activityId,
        "locationScopeId" : 4,
        "locationId" : this.state.activityConstituency
        }); 

        getAPICallDataForOnlyBSA(obj,"Activity/getDayWiseSubActivitiesByActivityId")
        .then((res) => res.json())
        .then((json) => {
            this.setState({daysLoadingSymbol:"hide"})
            if(json !=null && json.length>0){
                var arr=[];
                for(var i in  json){
                    var obj={'value':json[i].dayCode,'label':json[i].dayCode};
                    arr.push(obj)
                    
                }
                this.setState({ dayWiseSelectBoxArr:arr,dayWiseSubActivitiesByActivityIdArr:json});

                console.log("this.state.reDirectMainActivityId",this.state.activityDayCodeIdName)

                if(this.state.reDirectMainActivityId != "" ){
                    setTimeout(() => {
                        let getIdName = this.state.dayWiseSelectBoxArr.find((a) => a.value == this.state.activityDayCodeIdName);
                            this.setState({
                                dayWiseSelectBoxValue: {
                                value: getIdName.value,
                                label: getIdName.label
                            },dayWiseSelectBox:getIdName.value
                        });
                        const data = this.state.dayWiseSubActivitiesByActivityIdArr.filter(a => a.dayCode == this.state.activityDayCodeIdName);
                        this.setState({subActivitiesByActivityIdArr:data[0].activityList});

                    }, 500);
                 }

            }else{
                 this.setState({dayWiseSelectBoxArr:[],dayWiseSubActivitiesByActivityIdArr:[]});
            }
          
        });
  }

  getSubActivitiesByActivityId = (activityId,url) => {
    
    this.setState({subActivitiesByActivityIdArr:[],subActivitiesByActivityIdCheck:0,subActivityLoadingSymbol:"show"})
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "activityId" : activityId,
            "locationScopeId" : 4,
            "locationId" : this.state.activityConstituency
            }); 

            getAPICallDataForOnlyBSA(obj,url)
            .then((res) => res.json())
            .then((json) => {
                this.setState({subActivityLoadingSymbol:"hide"})
                if(json !=null && json.length>0){
                    this.setState({ subActivitiesByActivityIdArr:json,subActivitiesByActivityIdCheck:0});
                }else{
                    this.setState({subActivitiesByActivityIdArr:[],subActivitiesByActivityIdCheck:1});
                }
            
            });
    
  }

  
  columnselectionActivityDetails(activityId) {
    let columns = [
        
        {
            dataField: "Activity_Name",
            text: activityId == 3 ? "Location Name" : "Activity Name",
            sort: true,
            formatter: this.actionsFormatterForElectionActiName.bind(this),
            
        },
        /*{
            dataField: "Date",
            text: "Date",
            sort: true,
            formatter: this.actionsFormatterForElectionActDates.bind(this),
             
        }*/,
       
        {
            dataField: "Action",
            text: "Action",
            formatter: this.actionsFormatterForElectionActions.bind(this),
            
        }
    
    ];
    return columns;
}



actionsFormatterForElectionActiName = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            {
                this.state.mainActivity == 1 ?
                (
                    <h6 className="text-center f-11 fw-bold" style={{color:this.state.prajaGalamActivityColors[rowIndex]}}>STEP - {rowIndex+1}</h6>
                )
                :
                (
                    this.state.mainActivity == 2 ?
                    (
                        <h6 className="text-center f-12 fw-bold" style={{color:this.state.prajaGalamActivityColors[rowIndex]}}>Activity - {rowIndex+1}</h6>
                    )
                    :
                    ("")
                )
            }
             {
                this.state.mainActivity == 4 ?
                (
                    row.subActivityId == 21 ?
                    (
                        <img src={require('../../images/youth_meeting.png')} alt="cbn_support" className='width_80 center-auto'></img>
                    )
                    :
                    (
                        row.subActivityId == 22 ?
                        (
                            <img src={require('../../images/road_show.png')} alt="cbn_support" className='width_80 center-auto'></img> 
                        )
                        :
                        (
                            row.subActivityId == 23 ? 
                            (
                                <img src={require('../../images/booth_meeting.png')} alt="cbn_support" className='width_80 center-auto'></img>
                            )
                            :
                            ("")
                        )
                    )
                )
                :
                ("")   
            }

            {
                this.state.mainActivity == 4 ? 
                (<h6 className="text-center f-14 fw-bold mt-3" style={{color:this.state.prajaGalamActivityColors[row.subActivityId]}}>{row.Activity_Name}</h6>)
                :
                (
                    this.state.mainActivity == 3 ?
                    (<h6 className="text-center f-12">{row.locationName}</h6>)
                    :
                    (<h6 className="text-center f-12">{row.Activity_Name}</h6>)
                )
            }
            
           
            {
                row.Status == "Pending" ?
                (<h6 className="text-center mt-2 f-12 color_red">Status : {row.Status}</h6>)
                :
                ("")
            }
            
        </>
        
     );
    
}


actionsFormatterForElectionActDates = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <h6 className="text-center f-11">{moment(row.fromDate,"DD-MM-YYYY").format('MMMM Do YYYY')}</h6>
            <span>To</span>
            <h6 className="text-center mt-2 f-11">{moment(row.toDate,"DD-MM-YYYY").format('MMMM Do YYYY')}</h6>
        </>
        
     );
    
}


actionsFormatterForElectionActions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <h6 className="f-12 text-decoration-underline cursor_pointer color_red" onClick={() => this.saveActivityImagesModal(row.activityLocationId,row.Activity_Name,row.haveSurvey,row.subActivityId)}><i class="fa-solid fa-cloud-arrow-up"></i> జరిగిన వివరాలు తెలపండి</h6>
            {
                row.Status == "Pending" ?
                ("")
                :
                (<h6  className="f-12 mt-3 text-decoration-underline cursor_pointer" onClick={() => this.getUploadedImageInAActivityLocationModal(row.activityLocationId)} ><i class="fa-solid fa-image"></i> Show Image</h6>)
            }
            
        </>
        
     );
    
}

parentDataElectionActivityDetails = (data) =>{
    let dataArr = [];
        if(data.length>0){
            
            for (let i = 0; i < data.length; i++) {

                const Activity_Name = (data[i].activityName);
                const Status = (data[i].status);
                const subActivityId = (data[i].activityId);
                const fromDate = (data[i].fromDate);
                const toDate = (data[i].toDate);
                const activityLocationId = (data[i].activityLocationId);
                const haveSurvey = (data[i].haveSurvey);
                const locationName = typeof(data[i].locationName) == "undefined" ? "" : data[i].locationName;
                const locationScopeId = typeof(data[i].locationScopeId) == "undefined" ? "" : data[i].locationScopeId;
               
                
                const obj = {
                    Activity_Name:(Activity_Name),
                    Status:Status,
                    subActivityId: subActivityId,
                    fromDate: fromDate,
                    toDate: toDate,
                    activityLocationId:activityLocationId,
                    haveSurvey:haveSurvey,
                    locationName:locationName,
                    locationScopeId:locationScopeId
                    
                }
                dataArr.push(obj);
            } 

            
            
            return dataArr;
        }
}

saveActivityImagesModal = (activityLocationId,subActivityName,haveSurvey,subActivityId) => {

    if(this.state.mainActivity == 2 && this.state.panchayatSelectBoxArr.length > 0){
        if(this.state.panchayatSelectBox == "" || this.state.panchayatSelectBox == 0){
            this.setState({validatePanchayatName:"Please Select Activity Conducted Village"})
            return;
        }
    }

    this.props.history.push({
        pathname: "/election_activity_saving",
        params: {
            navbarDisplay:"show",
            userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
            activityLocationId:activityLocationId,
            subActivityName:subActivityName,
            mainActivityIdName:this.state.mainActivityValue,
            activityDayCodeIdName:this.state.dayWiseSelectBoxValue,
            activityPanchayatIdName:this.state.mainActivity == 2 ? this.state.panchayatSelectBoxValue : "",
            haveSurvey:haveSurvey,
            subActivityId:subActivityId,
            getCurrentLocationCoordinatesForEAImages:this.state.getCurrentLocationCoordinatesForEAImages,
          },
      });    
}

getUploadedImageInAActivityLocationModal = (activityLocationId) => {
    this.setState({showUploadImagesModal:true});

    this.getUploadedImageInAActivityLocation(activityLocationId);
}


getUploadedImageInAActivityLocation = (activityLocationId) =>{
    this.setState({issueFileDetailsArr:[],issueFileDetailsCheck:0});
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "activityLocationId" : activityLocationId
        }); 

        getAPICallDataForOnlyBSA(obj,"Activity/getUploadedImageInAActivityLocation")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){
                this.setState({ issueFileDetailsArr:json,issueFileDetailsCheck:0});
            }else{
                 this.setState({issueFileDetailsArr:[],issueFileDetailsCheck:1});
            }
          
        });
}


reloadCam = () => {
    const constraints = {
        audio: false,
        video: true,
      };
      
    this.setState({showActivityImgCapture:true})
    //this.setState({videoConstraints:{facingMode: "environment"}});
    navigator.mediaDevices
    .getUserMedia(constraints)
    .then((mediaStream) => {
        const video = document.querySelector("video");
        video.srcObject = mediaStream;
        video.onloadedmetadata = () => {
        video.play();
        };
    })
    .catch((err) => {
        // always check for errors at the end.
        console.error(`${err.name}: ${err.message}`);
    });
      
}

saveActivityImagesModalNextLevel = () => {
    this.setState({submitButtonDisabledForActivityNew:true});
    this.setState({saveActivityImagesSuccessMsgNew:""});
    this.setState({photoCaptureForActivityArrNew:[],showActivityImgCaptureNew:true,
        camaraRadioButtonNew:true,
        uploadImageRadioButtonNew:false,
        selectedTypePhotoNew:"camara"});
                        
    this.setState({activityImageUploadModalNew:true});
}



removeCapturePhotoFromActivity = (fileId) => {
    var tempArr = this.state.photoCaptureForActivityArrNew;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);
  
    if (objWithIdIndex > -1) {
      tempArr.splice(objWithIdIndex, 1);
    }
  
    this.setState({photoCaptureForActivityArrNew:tempArr})
  
  }
  
    
  captureForActivityImageUploadNew = () => {
    this.setState({showActivityImgCaptureNew:true});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        data : this.state.webcamRef.current.getScreenshot(),
      }

      setTimeout(() => {
        this.setState({photoCaptureForActivityArrNew:[...this.state.photoCaptureForActivityArrNew, obj]}); 
        this.setState({validateActivityImageUpload:""});
    }, 1000);
    this.setState({submitButtonDisabledForActivityNew:false});
};




saveActivityImagesNew = () => {
    this.setState({validateActivityImageUpload:""});
    
    if(this.state.selectedTypePhotoNew == "camara"){
        if(this.state.photoCaptureForActivityArrNew.length == 0){
            this.setState({validateActivityImageUpload:"Please Take A Photo Atleast One..."});
            return;
        }
    }else if(this.state.selectedTypePhotoNew == "upload"){
        if(this.state.photoCaptureForActivityArrNew.length == 0){
            this.setState({manualUploadImageNew:"Please Upload Atleast One..."});
            return;
        }
    }
    
    this.getsaveActivityImagesGeoLocationsNew();
   

    this.setState({savaActivityImageLoadingSymbol:"show"});  

    
}



getsaveActivityImagesGeoLocationsNew = () => {

    this.setState({savaActivityImageLoadingSymbol:"hide"});  

    this.setState({saveActivityImagesSuccessMsgNew:"Submitted SuccessFully..."});

             setTimeout(() => {
                this.setState({activityImageUploadModalNew:false});
                this.setState({submitButtonDisabledForActivityNew:true});
                this.setState({saveActivityImagesSuccessMsgNew:""});
                this.setState({photoCaptureForActivityArrNew:[],showActivityImgCaptureNew:true,
                                 });

            }, 2000);

     
}



selectCaptureTypeNew = (type) => {
    this.setState({photoCaptureForActivityArrNew:[],manualUploadImageNew:""});
   
        
    if(type == "upload"){
      this.setState({ camaraRadioButtonNew:false,uploadImageRadioButtonNew:true});
      this.setState({showActivityImgCaptureNew:false});
    }else{
      this.setState({ camaraRadioButtonNew:true,uploadImageRadioButtonNew:false});
      this.setState({showActivityImgCaptureNew:true});
    }
    

    this.setState({selectedTypePhotoNew:type});
  }

  toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

    uploadMultipleFilesForPhotosNew = (e) => {
    this.setState({manualUploadImageNew:""});
    const getUID = (length=8) => {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

    let fileObj=[];
      fileObj.push(e.target.files);
          for (let i = 0; i < fileObj[0].length; i++) {
            if(fileObj[0][i].type == "image/jpeg" || fileObj[0][i].type == "image/png" || fileObj[0][i].type == "image/jpg"){
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    var obj ={
                        fileId:getUID(),
                        fileType:fileObj[0][i].type,
                        data:dataUrl,
                    }
                    this.setState({photoCaptureForActivityArrNew:[...this.state.photoCaptureForActivityArrNew, obj]});
                    this.setState({submitButtonDisabledForActivityNew:false});
                });
            }else{
                this.setState({manualUploadImageNew:"Please Upload Only JPG/PNG/JPEG Files..."});
                return;
            }

              
          }

          
  }


  render() {
    console.log("photoCaptureForActivityArr",this.state.photoCaptureForActivityArr)
    return (
      <section>
        <Container fluid>
                <Row>
                    <Col>
                        <h6 className="f-14 fw-bold text-center"><span className="color_red">4</span> Steps to be completed by 20th April</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mt-2" controlId="formIssueTypeId">
                            <Form.Label className='f-12 fw-bold'>Activity/కార్యక్రమం పేరు <span className='color_red f-12 m-l-0'>*</span> <span className='color_red f-9'>{this.state.validateMainactivity}</span></Form.Label>
                            <SelectBox
                                stateData={this.state.mainActivityValue}
                                handleChange={this.handleOnchangeSelectBox}
                                name="mainActivity"
                                options={this.state.mainActiveListArr}
                                placeholderName={'Select Activity'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                        </Form.Group>
                    </Col>
                    {
                        this.state.mainActivityType == "Day Wise" && this.state.mainActivity == 2 ?
                        (
                            <Col>
                            <Form.Group className="mt-2" controlId="formDayCodeId">
                                <Form.Label className='f-12 fw-bold'>Select Day <span className='color_red f-12 m-l-0'>*</span> <span className='color_red f-9'>{this.state.validatedayCode}</span>
                                {
                                    this.state.daysLoadingSymbol == "show" ?
                                    (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                    :
                                    ("")
                                }
                                </Form.Label>
                                <SelectBox
                                    stateData={this.state.dayWiseSelectBoxValue}
                                    handleChange={this.handleOnchangeSelectBox}
                                    name="dayWiseSelectBox"
                                    options={this.state.dayWiseSelectBoxArr}
                                    placeholderName={'Select Day'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                />
                            </Form.Group>
                            </Col>
                        )
                        :
                        ("")
                    }
                </Row>
                {
                    this.state.panchayatSelectBoxArr.length > 0 ?
                    (
                        <>
                            {
                                this.state.mainActivityType == "Day Wise"   && this.state.mainActivity == 2? 
                                (
                                    <Row>
                                        <Col>
                                        <Form.Group className="mt-2" controlId="formDayCodeId">
                                            <Form.Label className='f-12 fw-bold'>Activity Conducted Village - కార్యక్రమం జరిగిన గ్రామం / వార్డ్ / డివిజన్ పేరు <span className='color_red f-12 m-l-0'>*</span> <span className='color_red f-9'>{this.state.validatePanchayatName}</span>
                                            {
                                                this.state.villageLoadingSymbol == "show" ?
                                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                :
                                                ("")
                                            }
                                            </Form.Label>
                                            <SelectBox
                                                stateData={this.state.panchayatSelectBoxValue}
                                                handleChange={this.handleOnchangeSelectBox}
                                                name="panchayatSelectBox"
                                                options={this.state.panchayatSelectBoxArr}
                                                placeholderName={'Select Village'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                )
                                :
                                ("")
                            }
                        </>
                    )
                    :
                    ("")
                }
                

                {
                    this.state.subActivityLoadingSymbol == "show" ?
                    (
                        <Row>
                            <Col sm={12}>
                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                            </Col>
                        </Row>
                    )
                    :
                    ("")
                }
                {
                    this.state.subActivitiesByActivityIdArr.length>0 ?
                    (
                        
                        <>
                        <Row>
                            <Col sm={12}>
                        
                                <div className='p-5222 mt-4'>
                                    
                                    {
                                        this.state.mainActivityValue.value == 1 ?
                                        (
                                            <>
                                                <h6 class="f-16 text-uppercase fw-bold text-center color-ea-blue"> {this.state.mainActivityValue.label} </h6>
                                                <h6 class="f-16 text-uppercase fw-bold text-center color-ea-blue"> 7 Days | 24th Mar-30th Mar  </h6>
                                            </>
                                        )
                                        :
                                        (
                                            this.state.mainActivityValue.value == 2 ? 
                                            (
                                                <>
                                                    <h6 class="f-16 text-uppercase fw-bold text-center color-ea-yelllow"> {this.state.mainActivityValue.label} </h6>
                                                    <h6 class="f-16 text-uppercase fw-bold text-center"> 12 Days | 31st Mar-11th Apr   </h6>
                                                </>
                                            )
                                            :
                                            (
                                                this.state.mainActivityValue.value == 3 ? 
                                                (
                                                    <>
                                                        <h6 class="f-16 text-uppercase fw-bold text-center color-ea-green"> {this.state.mainActivityValue.label} </h6>
                                                        <h6 class="f-16 text-uppercase fw-bold text-center"> 6 Days | 12th Apr-18th Apr  </h6>
                                                    </>
                                                )
                                                :
                                                (
                                                    this.state.mainActivityValue.value == 4 ? 
                                                    (
                                                        <>
                                                            <h6 class="f-16 text-uppercase fw-bold text-center color-ea-orange"> {this.state.mainActivityValue.label} </h6>
                                                            <h6 class="f-16 text-uppercase fw-bold text-center"> 2 Days | 19th Apr-20th Apr  </h6>
                                                        </>
                                                    )
                                                    :
                                                    ("")
                                                )
                                            )
                                        )
                                    }
                                    {
                                        this.state.mainActivityType == "Day Wise" ? 
                                        (<h6 class="text-muted f-12 fw-bold ln-22">మీరు పేర్కొన్న గ్రామం / వార్డ్ / డివిజన్ లో  కార్యక్రమంలో జరిగిన వివరాలు , కార్యక్రమం వారీగా వివరాలు తెలపండి</h6>)
                                        :
                                        (<h6 class="text-muted f-12 fw-bold ln-22 mt-3">ఈ కార్యక్రమాలలో జరిగిన వివరాలను క్రింద ఇచ్చిన ఫోరంలో అప్డేట్ చేసి సబ్మిట్ చేయగలరు</h6>)
                                    }
                                    
                                    {
                                        this.state.mainActivity == 1 || this.state.mainActivity == 2 || this.state.mainActivity == 4 ?
                                        (
                                            <CardLessDataTableWithSearchLimit5 
                                                parentData={this.parentDataElectionActivityDetails(this.state.subActivitiesByActivityIdArr)}
                                                particlecolumns={this.columnselectionActivityDetails(this.state.mainActivity)}
                                                defaultSorted={[{
                                                    dataField: "",
                                                    order: "desc"
                                                }]}
                                                tableHeading=""
                                                />
                                        )
                                        :
                                        (
                                            this.state.mainActivity == 3 ?
                                            (
                                                <>
                                                    {
                                                        this.state.subActivitiesByActivityIdArr.map((item,index) => {
                                                            return(
                                                                <Row>
                                                                    <Col>
                                                                        <Card className="border_yash_block1 mt-3">
                                                                            <Card.Header>
                                                                                {
                                                                                    item.activityId == 18 ?
                                                                                    (<img src={require('../../images/jaiho_bc.png')} alt="cbn_support" className='width_80 center-auto'></img>)
                                                                                    :
                                                                                    (
                                                                                        item.activityId == 19 ?
                                                                                        (<img src={require('../../images/mahaShakti_sam.png')} alt="cbn_support" className='width_80 center-auto'></img>)
                                                                                        :
                                                                                        (
                                                                                            item.activityId == 20 ?
                                                                                            (<img src={require('../../images/Dalit_Galam.png')} alt="cbn_support" className='width_80 center-auto'></img>)
                                                                                            :
                                                                                            (<img src={require('../../images/jaiho_bc.png')} alt="cbn_support" className='width_80 center-auto'></img>)
                                                                                        )
                                                                                    )
                                                                                }
                                                                                
                                                                                <h6 className="text-center f-16 fw-bold mt-2">{item.activityName}</h6>
                                                                            </Card.Header>
                                                                           
                                                                            <Card.Body>
                                                                                {
                                                                                    item.locationsList.map((item2,indexvalue) => {
                                                                                        return(
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <h6 className="f-12 fw-bold">{item2.locationName} Level Meetings</h6>
                                                                                                    {
                                                                                                        item2.activityList.length > 10 ?
                                                                                                        (
                                                                                                            <CardLessDataTableWithSearchLimit5 
                                                                                                                parentData={this.parentDataElectionActivityDetails(item2.activityList)}
                                                                                                                particlecolumns={this.columnselectionActivityDetails(3)}
                                                                                                                defaultSorted={[{
                                                                                                                    dataField: "",
                                                                                                                    order: "desc"
                                                                                                                }]}
                                                                                                                tableHeading=""
                                                                                                                />
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <table className="table table-bordered table_custom">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>Location Name</th>
                                                                                                                        <th>Action</th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        item2.activityList.map((item3,indexAL) => {
                                                                                                                            return(
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <h6 className="text-center f-12">{item3.locationName}</h6>
                                                                                                                                        {
                                                                                                                                            item3.status == "Pending" ?
                                                                                                                                            (<h6 className="text-center mt-2 f-12 color_red">Status : {item3.status}</h6>)
                                                                                                                                            :
                                                                                                                                            ("")
                                                                                                                                        }
                                                                                                                                        
                                                                                                                                        </td>
                                                                                                                                    <td>
                                                                                                                                    <>
                                                                                                                                        <h6 className="f-12 text-decoration-underline cursor_pointer color_red" onClick={() => this.saveActivityImagesModal(item3.activityLocationId,item3.activityName,item3.haveSurvey,item.activityId)}><i class="fa-solid fa-cloud-arrow-up"></i> జరిగిన వివరాలు తెలపండి</h6>
                                                                                                                                        {
                                                                                                                                            item3.status == "Pending" ?
                                                                                                                                            ("")
                                                                                                                                            :
                                                                                                                                            (<h6  className="f-12 mt-3 text-decoration-underline cursor_pointer" onClick={() => this.getUploadedImageInAActivityLocationModal(item3.activityLocationId)} ><i class="fa-solid fa-image"></i> Show Image</h6>)
                                                                                                                                        }
                                                                                                                                        
                                                                                                                                    </>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                <tr>
                                                                                                                   
                                                                                                                </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        )
                                                                                                    }

                                                                                                    
                                                                                                    
                                                                                                </Col>
                                                                                            </Row>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                    </>
                                               
                                            )
                                            :
                                            (
                                                ""
                                            )
                                        )
                                    }
                                    
                                
                                    
                                </div>
                            
                            </Col>
                        </Row>
                        </>
                    )
                    :
                    (<>
                        {
                        this.state.subActivitiesByActivityIdCheck == 1 ?
                        (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                        :
                        ("")
                    }
                    </>)
                }

                
                 <ShowUploadImages 
                        show={this.state.showUploadImagesModal}
                        onHide={() => this.setState({ showUploadImagesModal: false })}
                        stateData={this.state}
                />   

                <ActivityImageUpload
                    show={this.state.activityImageUploadModalNew}
                    onHide={() => this.setState({ activityImageUploadModalNew: false })}
                    stateData={this.state}
                    selectCaptureTypeNew={this.selectCaptureTypeNew}
                    captureForActivityImageUploadNew={this.captureForActivityImageUploadNew}
                    uploadMultipleFilesForPhotosNew={this.uploadMultipleFilesForPhotosNew}
                    removeCapturePhotoFromActivity={this.removeCapturePhotoFromActivity}
                    saveActivityImagesNew={this.saveActivityImagesNew}
                    reloadCam={this.reloadCam}
                />    
                    
        </Container>
        
    </section>
    )
  }
}

export default withRouter(ElectionActivitiesSection);
