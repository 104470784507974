
import React ,{ Component } from 'react';
import {Linking,Platform,TouchableOpacity,Text} from "react-native";
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import '../Volunteer/VolunteerSection.css';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';
import LOKESH from '../../images/Lokesh.png';


class PublicOutReachDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            accessName:props?.location?.params?.accessName == undefined ? "" : props?.location?.params?.accessName,
            roleName:props?.location?.params?.roleName == undefined ? "" : props?.location?.params?.roleName,
            VolunterFromDate:"2024-02-01",
            VolunterToDate:moment().format('YYYY-MM-DD'),
            totalEnrollCountsArr:"",
            totalEnrollCountsCheck:0,
            dateWiseEnrollCountsArr:[],
            dateWiseEnrollCountsCheck:0,

            
        }
    }

    componentDidMount() {
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            this.getOutreachEnrollmentCountByAUser();
            this.getDateWiseOutreachEnrollmentCountByAUser();
            
        }
        
    }
    

    //Date Wise //
    getOutreachEnrollmentCountByAUser = () => {
        this.setState({totalEnrollCountsArr:"",totalEnrollCountsCheck:0});
        const obj =  
                JSON.stringify({ 
                    "chatId" : this.state.chatID,
                    "fromDate" :this.state.VolunterFromDate,
                    "toDate" : this.state.VolunterToDate,
                });
        getAPICallData(obj,"getOutreachEnrollmentCountByAUser")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null){
                    
                    this.setState({totalEnrollCountsArr:content,totalEnrollCountsCheck:0});
                }else{
                    this.setState({totalEnrollCountsArr:"",totalEnrollCountsCheck:1});
                }
            });
    }

    getDateWiseOutreachEnrollmentCountByAUser = () => {
        this.setState({dateWiseEnrollCountsArr:[],dateWiseEnrollCountsCheck:0});
        const obj =  
                JSON.stringify({ 
                    "chatId" : this.state.chatID,
                    "fromDate" :this.state.VolunterFromDate,
                    "toDate" : this.state.VolunterToDate,
                });
        getAPICallData(obj,"getDateWiseOutreachEnrollmentCountByAUser")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    
                    this.setState({dateWiseEnrollCountsArr:content,dateWiseEnrollCountsCheck:0});
                }else{
                    this.setState({dateWiseEnrollCountsArr:[],dateWiseEnrollCountsCheck:1});
                }
            });
    }

    getDateWiseEnrollCounts = () => {
        this.getOutreachEnrollmentCountByAUser();
        this.getDateWiseOutreachEnrollmentCountByAUser();
    }

    totalEnrollCountsColumns() {
        let columns = [
            
            {
                dataField: "Total",
                text: "Total Enrolls",
                sort: true,
            },
            {
                dataField: "BOT",
                text: "BOT",
                sort: true,
                
                
                
            },
            {
                dataField: "WEB",
                text: "WEB",
                sort: true,
                
            },
           
            {
                dataField: "OWN",
                text: "OWN",
                sort: true,
            }
         
        ];
        return columns;
    }

    
    totalEnrollCountsData = () =>{
    let dataArr = [];
        if(this.state.totalEnrollCountsArr.length>0){
            
            for (let i = 0; i < this.state.totalEnrollCountsArr.length; i++) {

                const totalEnrolled = (this.state.totalEnrollCountsArr[i].totalEnrolled);
                const botEnrolled = (this.state.totalEnrollCountsArr[i].botEnrolled);
                const webEnrolled = (this.state.totalEnrollCountsArr[i].webEnrolled);
                const ownEnrolled = (this.state.totalEnrollCountsArr[i].ownEnrolled);
                const thirdPartyEnrolled = (this.state.totalEnrollCountsArr[i].thirdPartyEnrolled);
                
                
                const obj = {
                    Total: <span className='f-14 fw-bold'>{totalEnrolled}</span>,
                    BOT: <span className='f-14 fw-bold'>{botEnrolled}</span>,
                    WEB:  <span className='f-14 fw-bold'>{webEnrolled+thirdPartyEnrolled}</span>,
                    OWN: <span className='f-14 fw-bold'>{ownEnrolled}</span>,
                   
                    
                }
                dataArr.push(obj);
            } 

            
            
            return dataArr;
        }
}



dateWiseVisitedPORCountsColumns() {
    let columns = [
        
        {
            dataField: "Date",
            text: "Date",
            sort: true,
        },
        {
            dataField: "Total",
            text: "Visited Houses",
            sort: true,
           
        }
    ];
    return columns;
}


dateWiseVisitedPORCountsData = () =>{
let dataArr1 = [];
    if(this.state.dateWiseEnrollCountsArr.length>0){
        
        for (let i = 0; i < this.state.dateWiseEnrollCountsArr.length; i++) {

            const Date = (this.state.dateWiseEnrollCountsArr[i].date);
            const count = (this.state.dateWiseEnrollCountsArr[i].count);
            
            
            const obj = {
                Date: Date,
                Total: count
               
                
            }
            dataArr1.push(obj);
        } 

        
        
        return dataArr1;
    }
}
//Date Wise End//


  handleEventForDateStartDate = (event, picker) => {
    this.setState({ VolunterFromDate: moment(picker.startDate._d).format('YYYY-MM-DD')});
  }

  handleEventForDateEndDate = (event, picker) => {
    this.setState({ VolunterToDate: moment(picker.endDate._d).format('YYYY-MM-DD')});
  }

      
    render() {
        console.log("roleName",this.state.roleName)
        return (
            <section className='mb-4'>
                 <div className="header_img_p">
                  <div className="flex-header mandal_scroll">
                    <Link to={{pathname: "/dashboard"}}> 
                        <Button variant="outline-light pad5_14 back_button1 mt-45 ms-2" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                    </Link>
                      
                     <h5 className="logo_font_size1 color_white f-18">Public OutReach</h5>
                      
                      <img className="center-auto" src={LOKESH} />
                    </div>
                    
                </div>
                <div className="boder_pad_yellow fw-bold f-14">{this.state.accessName} Constituency : </div>
                
                <Container fluid>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center mt-4'> Public OutReach - Dashboard </h6>
                        </Col>
                    </Row> 
                   
                    <>
                                <Row>
                                    <Col>
                                    <h6 className="form-label f-12 fw-bold mt-3">From Date : <span className='color_red f-9'>{this.state.validateFromDate}</span></h6>
                                    <DateRangePicker
                                            singleDatePicker={true}
                                            alwaysShowCalendars={true}
                                            onEvent={this.handleEventForDateStartDate}
                                            className="w-100"
                                        >   
                                        <div class="input-group">
                                            <div class="input-group-prepend bgColorYash">
                                                <i class="fa fa-calendar pad_5_15"></i>
                                            </div>
                                            <input type="text" className="form-control" value={moment(this.state.VolunterFromDate).format("YYYY-MM-DD")}/>
                                        </div>
                                        </DateRangePicker>
                                    </Col>
                                    <Col>
                                    <h6 className="form-label f-12 fw-bold mt-3">To Date : <span className='color_red f-9'>{this.state.validateToDate}</span></h6>
                                    <DateRangePicker
                                            singleDatePicker={true}
                                            alwaysShowCalendars={true}
                                            onEvent={this.handleEventForDateEndDate}
                                            className="w-100"
                                        >   
                                        <div class="input-group">
                                            <div class="input-group-prepend bgColorYash">
                                                <i class="fa fa-calendar pad_5_15"></i>
                                            </div>
                                            <input type="text" className="form-control" value={moment(this.state.VolunterToDate).format("YYYY-MM-DD")}/>
                                        </div>
                                        </DateRangePicker>
                                    </Col>
                                </Row>      
                                <Row>
                                    <Col>
                                        <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getDateWiseEnrollCounts()}>GET DETAILS</Button> 
                                    </Col>
                                </Row> 

                                    {
                                        this.state.totalEnrollCountsArr.length == 0 ?
                                        (
                                            this.state.totalEnrollCountsCheck == 1 ?
                                            (<Col className='f-14 text-center'>No Data Available</Col>)
                                            :
                                            (<Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>)
                                        )
                                        :
                                        (
                                            this.state.totalEnrollCountsArr != ""  ?
                                            (
                                                <>
                                                {
                                                    this.state.totalEnrollCountsArr.count > 0 ?
                                                    (
                                                        <Row>
                                                            <Col sm={12}>
                                                                <h6 className='fw-bold mt-3 mb-0'>Total Visited Houses : {this.state.totalEnrollCountsArr.count}</h6>
                                                            </Col>
                                                        </Row>
                                                    )
                                                    :
                                                    (<Row><Col className='f-14 text-center mt-3'>No Data Available</Col></Row>)
                                                }
                                               
                                                </>
                                            )
                                            :
                                            ("")
                                        )
                                        }  

                                        {
                                            this.state.dateWiseEnrollCountsArr.length>0 ?
                                            (
                                                <>
                                                <Row>
                                                    <Col sm={12}>
                                                    
                                                    <div className='mt-1 pagination_none'>
                                                        
                                                        <CardLessDataTableWithOutSearch 
                                                            parentData={this.dateWiseVisitedPORCountsData()}
                                                            particlecolumns={this.dateWiseVisitedPORCountsColumns()}
                                                            defaultSorted={[{
                                                                dataField: "Total",
                                                                order: "desc"
                                                            }]}
                                                            tableHeading={<span class='f-12' style={{textAlign:"left",marginLeft:"0px"}}>Date Wise Visited Houses : {this.state.VolunterFromDate} To {this.state.VolunterToDate}</span>}
                                                            />
                                                            
                                                            
                                                    </div>
                                                    
                                                    </Col>
                                                </Row>
                                                </>
                                            )
                                            :
                                            ("")
                                        } 
                            </>                 
                         
                </Container>
                
            
            </section>
        )
    }
  }
  
  export default withRouter(PublicOutReachDashboard);
  