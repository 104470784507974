
import React ,{ Component } from 'react';
import {Container, Row, Col } from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService'
import "../BoothAndHouseMapping/BoothAndHouseMapping";

class BoothWiseAllHouses extends Component {
  constructor(props) {
      super(props)
      this.state = {
        chatID: window.User !=undefined ? window.User.chatID : "943827154" ,
        boothId:props?.location?.params?.boothIdFromDashBoard == undefined ? "" : props?.location?.params?.boothIdFromDashBoard.toString(),
        boothNo:props?.location?.params?.boothNoFromDashBoard == undefined ? "" : props?.location?.params?.boothNoFromDashBoard,
        houseNoList:[],
        boothHouseId:0,
        houseWiseVoterList:[],
        votersListCheck:0
      }
  }

  componentDidMount() {

    if(this.state.boothId == ""){
      this.props.history.push("/dashboard");
    }else{
      const getHousesObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "locationId" : parseInt(this.state.boothId),
          "verifiedStatus" : "All",
          "mappingStatus" : "All"
        }
      ); 

      //gethousesList
      getAPICallData(getHousesObj,"getHousesByBoothId")
          .then((res) => res.json())
          .then((data) => {
            if(data.housesList !=null && data.housesList.length>0){
              this.setState({ houseNoList: data.housesList });
              this.getAllHouseWiseVoterDetails(0,"All");
            }else{
              this.setState({ houseNoList: [] });
            }
      }); 
    }
  }

  getAllHouseWiseVoterDetails = async(e,type) => {
    this.setState({houseWiseVoterList:[],votersListCheck:0,boothHouseId:0});
    if(type == "All"){
      this.setState({ boothHouseId: 0 });
    }else{
      this.setState({ boothHouseId:e.target.value});
    }

    const delay = (ms) => new Promise((r) => setTimeout(r, ms));
    await delay(500);
    const allHousesObj =  
      JSON.stringify({ 
        "username" : "bsa_api",
        "password" : "9D#Jcx!i5B$z@ym4d7K2f",
        "chatId" :this.state.chatID,
        "boothId" : parseInt(this.state.boothId),
        "boothHouseId":parseInt(this.state.boothHouseId)
      }
    ); 

    //getHouseWiseAllVotersInABooth
    getAPICallData(allHousesObj,"getHouseWiseAllVotersInABooth")
        .then((res) => res.json())
        .then((data) => {
          if(data.status == "Success"){
            if(data.housesList !=null && data.housesList.length>0){
              this.setState({houseWiseVoterList:data.housesList,votersListCheck:0 });
            }else{
              this.setState({houseWiseVoterList:[],votersListCheck:1});
            }
            
          }else{
            this.setState({houseWiseVoterList:[],votersListCheck:1});
          }
    });
  }
  
  checkAllVerified = () => {
    const arrayOfObject = this.state.houseWiseVoterList;
    if(arrayOfObject.some(person => person.verificationStatus == "Pending")){
        return "Pending"
    } else{
      return "Completed"
    }      
}
 
  
  render() {
    console.log("votersList",this.state.houseWiseVoterList)
      return (
        <section>
            <Container fluid>
                <Row>
                  <Col sm={6} className='mt-2'>
                            <label class="form-label f-14 fw-bold">House No </label>
                            <select id="house_no_id" name="house_no" value={this.state.boothHouseId}  className='form-select form-select-sm' onChange={(e) => this.getAllHouseWiseVoterDetails(e,"selected")} >
                                {
                                      this.state.houseNoList.length>0  ? 
                                      (
                                          <>
                                          <option value="0" selected>All</option>
                                          {
                                              this.state.houseNoList.map((item,index) => {
                                                  return(
                                                    <>
                                                    <option key={index} value={item.boothHouseId}>House No - {item.houseNo}</option>
                                                    </>
                                                  )
                                              })
                                          }
                                          </>
                                        
                                      )
                                      :
                                      (<option value="0">All</option>)
                                      
                                  }
                            </select>
                          </Col>
                </Row>
                {
                  this.state.houseWiseVoterList.length == 0 ?
                  (
                    <Row>
                      <Col sm={12}>
                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                      </Col>
                    </Row>
                  )
                  :
                  ("")
                }
                {
                    this.state.houseWiseVoterList.length>0 ?
                      (

                        <>
                          {
                            this.state.houseWiseVoterList.map((item,index) => {
                                return(
                                  <Link to={{
                                        pathname: "/verify_house" ,
                                        params: {
                                            boothIdFromDashBoard: this.state.boothId,
                                            verifiedStatusFromDashboard: item.verificationStatus,
                                            mappingStatusFromDashboard: item.mappedStatus,
                                            boothHouseIdFromAllHouse:item.boothHouseId
                                        }
                                    }} className='text-decoration-none'>
                                      <Row>
                                        <Col sm={12}>
                                            <div className='bg_light_blue mt-3'>
                                                <h6 className='f-14'>House No - {item.houseNo} Details : 
                                                  {
                                                    item.mappedStatus == "Completed" ?
                                                    (<span className='float-end'><i className="fa-solid fa-location-dot f-i-22 color_green"></i></span>)
                                                    :
                                                    (<span className='float-end'><i className="fa-solid fa-location-dot f-i-22 color_red"></i></span> )
                                                  }
                                                  {
                                                    item.verificationStatus == "Completed" ?
                                                    (<span className='verify-not-All-cls color_green border_green float-end' >Verifyed <i class="fa-solid fa-square-check"></i></span>)
                                                    :
                                                    (<span className='verify-not-All-cls color_red border_red float-end'>Not Verified <i class="fa-sharp fa-solid fa-circle-xmark"></i></span>)
                                                  }
                                                </h6>
                                                <table className='table table_custom mb-2'>
                                                    <thead>
                                                        <tr>
                                                          <th style={{minWidth:"40%"}}>Name</th>
                                                          <th style={{minWidth:"40%"}}>Voter Card No</th>
                                                          <th>Age</th>
                                                          <th>Gender</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                      {
                                                          item.votersList.map((vlist,index) => {
                                                          return(
                                                            
                                                            <tr>
                                                              <td style={{textAlign:"left"}}>{vlist.voterName}</td>
                                                              <td>{vlist.voterCardNo}</td>
                                                              <td>{vlist.age}</td>
                                                              <td>{vlist.gender} </td>
                                                            </tr>
                                                          )
                                                          
                                                        })
                                                      }
                                                      
                                                    </tbody>
                                                </table>
                                                
                                            </div>     
                                        </Col> 
                                  </Row>
                                </Link>
                                  
                                )
                            })
                          }
                        </>
                      )
                      :
                      (
                        <>
                          {
                          this.state.votersListCheck == 1 ?
                          (<h6 className='f-14 text-center mt-4'>No Houses Availble in this Booth</h6>)
                          :
                          ("")
                        }
                        </>
                      )
                        
                    }
            </Container>
        </section>
      )
  }
}

export default withRouter(BoothWiseAllHouses);
