
import React ,{ Component } from 'react';
import {Container, Row, Col,Accordion  } from 'react-bootstrap';
import "../Dashboard/Dashboard.css";
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService';
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';

class BoothWiseDashboard extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            boothWiseData:[],
            viewStatus:props?.location?.params?.viewStatusStr == undefined ? "house_verification" : props?.location?.params?.viewStatusStr,
            boothId:props?.location?.params?.boothId == undefined ? "" : props?.location?.params?.boothId,
            boothNo:props?.location?.params?.boothNo == undefined ? "" : props?.location?.params?.boothNo,
            accessName:props?.location?.params?.accessName == undefined ? "" : props?.location?.params?.accessName,
            user_data:window.Telegram.WebApp.initData,
            verifyHouseAccess:props?.location?.params?.verifyHouseAccess == undefined ? "" : props?.location?.params?.verifyHouseAccess,
          
        }
    }
  
    componentDidMount() {
        this.getBoothWiseVerificationAnalysis(0);
    }

    getBoothWiseVerificationAnalysis = (boothId) => {
        var boothIdStr =0;
        if(this.state.boothId == ""){
            boothIdStr =0;
        }else{
            boothIdStr =this.state.boothId;
        }
        const boothWiseDtsObj =  
            JSON.stringify({ 
            "username" : "bsa_api",
            "password" : "9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :this.state.chatID,
            "boothId" : parseInt(boothIdStr)
            }
        ); 

        //getBooths
        getAPICallData(boothWiseDtsObj,"getBoothWiseVerificationAnalysis")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.length>0){
                    this.setState({ boothWiseData: data });
                }else{
                    this.setState({ boothWiseData: [] });
                }
            }); 
    }

    
    actionsFormatterForVerified = (cell, row, rowIndex, formatExtraData) => {
        const booth_id = this.state.boothWiseData.filter((item,index) => item.locationName == row.Booth).map(blist => blist.locationId);
        if(this.state.verifyHouseAccess == 'yes' && row.Verified>0){
            return (
                <Link to={{
                    pathname: "/verify_house" ,
                        params: {
                            boothIdFromDashBoard: booth_id,
                            verifiedStatusFromDashboard: "Completed",
                        }
                    }} >
                {row.Verified}
            </Link>
            );
        }else{
            return (
                row.Verified
            );
        }
        
    }
    actionsFormatterForPending = (cell, row, rowIndex, formatExtraData) => {
        const booth_id = this.state.boothWiseData.filter((item,index) => item.locationName == row.Booth).map(blist => blist.locationId);
        if(this.state.verifyHouseAccess == 'yes' && row.Pending>0){
            return (
                <Link to={{
                    pathname: "/verify_house" ,
                        params: {
                            boothIdFromDashBoard: booth_id,
                            verifiedStatusFromDashboard: "Pending",
                        }
                    }} >
                {row.Pending}
            </Link>
            );
        }else{
            return (row.Pending);
        }
        
    }
    houseVerificationColumns() {

        let columns = [
            {
                dataField: "Booth",
                text: "Booth",
                sort: true,
                
            },
            {
                dataField: "TotalVoters",
                text: "Voters",
                sort: true,
                
            },

            {
                dataField: "Verified",
                text: "Verified",
                sort: true,
                formatter: this.actionsFormatterForVerified.bind(this),
               
            },
            /*{
                dataField: "verified_percentage",
                text: "%",
                sort: true,
               
            }*/,
            {
                dataField: "Pending",
                text: "Pending",
                sort: true,
                formatter: this.actionsFormatterForPending.bind(this),
                
            },
            /*{
                dataField: "pending_percentage",
                text: "%",
                sort: true,
               
            },*/
            
            
        ];
        return columns;
    }

    houseVerificationData = () => {
        let houseVerificationData = [];
        if(this.state.boothWiseData.length>0){
            for (let i = 0; i < this.state.boothWiseData.length; i++) {
                const obj = {
                    Booth: this.state.boothWiseData[i].locationName,
                    TotalVoters: this.state.boothWiseData[i].totalHouses,
                    Verified: this.state.boothWiseData[i].verifiedHouses,
                    Pending: this.state.boothWiseData[i].notVerifiedHouses,
                }
                houseVerificationData.push(obj);
            } 

            
            
            return houseVerificationData;
        }
    }

    actionsFormatterForMapped = (cell, row, rowIndex, formatExtraData) => {
        const booth_id = this.state.boothWiseData.filter((item,index) => item.locationName == row.Booth).map(blist => blist.locationId);
        if(this.state.verifyHouseAccess == 'yes' && row.Mapped>0){
            return (
                <Link to={{
                    pathname: "/verify_house" ,
                        params: {
                            boothIdFromDashBoard: booth_id,
                            mappingStatusFromDashboard: "Completed",
                        }
                    }} >
                {row.Mapped}
            </Link>
            );
        }else{
            return (row.Mapped);
        }
        
    }
    actionsFormatterForNotMapped = (cell, row, rowIndex, formatExtraData) => {
        const booth_id = this.state.boothWiseData.filter((item,index) => item.locationName == row.Booth).map(blist => blist.locationId);
       if(this.state.verifyHouseAccess == 'yes' && row.NotMapped>0){
            return (
                <Link to={{
                    pathname: "/verify_house" ,
                        params: {
                            boothIdFromDashBoard: booth_id,
                            mappingStatusFromDashboard: "Pending",
                        }
                    }} >
                {row.NotMapped}
            </Link>
            );
       }else{
        return (row.NotMapped);  
       }
        
    }
    
    houseMappingColumns() {

        let columns = [
            {
                dataField: "Booth",
                text: "Booth",
                sort: true,
                
            },
            {
                dataField: "TotalHouses",
                text: "Houses",
                sort: true,
                
            },

            {
                dataField: "Mapped",
                text: "Mapped",
                sort: true,
                formatter: this.actionsFormatterForMapped.bind(this),
               
            },
            {
                dataField: "NotMapped",
                text: "Not Mapped",
                sort: true,
                formatter: this.actionsFormatterForNotMapped.bind(this),
                
            }
            
            
        ];
        return columns;
    }

    
    houseMappingData = () => {
        let houseMappingData = [];
        if(this.state.boothWiseData.length>0){
            for (let i = 0; i < this.state.boothWiseData.length; i++) {
                const obj = {
                    Booth: this.state.boothWiseData[i].locationName,
                    TotalHouses: this.state.boothWiseData[i].totalHouses,
                    Mapped: this.state.boothWiseData[i].mappedHouses,
                    NotMapped: this.state.boothWiseData[i].unMappedHouses,
                }
                houseMappingData.push(obj);
            } 
            return houseMappingData;
        }
    }


    votersVerificationColumns() {

        let columns = [
            {
                dataField: "Booth",
                text: "Booth",
                sort: true,
                
            },
            {
                dataField: "TotalVoters",
                text: "Voters",
                sort: true,
                
            },

            {
                dataField: "Verified",
                text: "Verified",
                sort: true,
               
            },
            {
                dataField: "Pending",
                text: "Pending",
                sort: true,
                
            }
            
            
        ];
        return columns;
    }

    
    votersVerificationData = () => {
        let votersArr = [];
        if(this.state.boothWiseData.length>0){
            for (let i = 0; i < this.state.boothWiseData.length; i++) {
                const obj = {
                    Booth: this.state.boothWiseData[i].locationName,
                    TotalVoters: this.state.boothWiseData[i].totalVoters,
                    Verified: this.state.boothWiseData[i].verifiedVoters,
                    Pending: this.state.boothWiseData[i].pendingVoters,
                }
                votersArr.push(obj);
            } 
            return votersArr;
        }
    }


    
    detachedVotersColumns() {

        let columns = [
            {
                dataField: "Booth",
                text: "Booth",
                sort: true,
                
            },
            /*{
                dataField: "Detached",
                text: "Detached",
                sort: true,
                
            },*/

            {
                dataField: "Death",
                text: "Death",
                sort: true,
               
            },
            {
                dataField: "House_Shifting",
                text: "House Shifting",
                sort: true,
                
            },
            {
                dataField: "moved_per",
                text: "Moved Permanently",
                sort: true,
                
            }
            
            
        ];
        return columns;
    }

    
    detachedVotersData = () => {
        let detachedVotersArr = [];
        if(this.state.boothWiseData.length>0){
            for (let i = 0; i < this.state.boothWiseData.length; i++) {
                const obj = {
                    Booth: this.state.boothWiseData[i].locationName,
                   // Detached:this.state.boothWiseData[i].detachedVoters,
                    Death: this.state.boothWiseData[i].deathVoters,
                    House_Shifting: this.state.boothWiseData[i].houseShiftingVoters,
                    moved_per: this.state.boothWiseData[i].movedPermanentlyVoters,
                }
                detachedVotersArr.push(obj);
            } 
            return detachedVotersArr;
        }
    }

    newVotersColumns() {

        let columns = [
            {
                dataField: "Booth",
                text: "Booth",
                sort: true,
                
            },
           
            {
                dataField: "Total",
                text: "Total",
                sort: true,
               
            },
            {
                dataField: "House_Shifted",
                text: "House Shifted",
                sort: true,
                
            },
            {
                dataField: "new_voters",
                text: "New Voters",
                sort: true,
                
            }
            
            
        ];
        return columns;
    }

    
    newVotersData = () => {
        let newVotersArr = [];
        if(this.state.boothWiseData.length>0){
            for (let i = 0; i < this.state.boothWiseData.length; i++) {
                const totalVoters = this.state.boothWiseData[i].houseShiftedVoters+this.state.boothWiseData[i].newVoters;
                const obj = {
                    Booth: this.state.boothWiseData[i].locationName,
                    Total:totalVoters,
                    House_Shifted: this.state.boothWiseData[i].houseShiftedVoters,
                    new_voters: this.state.boothWiseData[i].newVoters,
                }
                newVotersArr.push(obj);
            } 
            return newVotersArr;
        }
    }

    handleOnchangeSelectBox = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
      }

      

    render() {
        return (
            <>
            <Container fluid>
                <Row>
                    <Col className='mt-2 mb-3'>
                        <label className="form-label f-14 fw-bold">View Booth Wise Status Overview </label>
                        <select id="view_status_id" name="viewStatus" className='form-select form-select-sm' onChange={(e)=> this.handleOnchangeSelectBox(e)}>
                            <option value="">Select Status</option>
                            {
                                this.state.viewStatus == "house_verification" ? 
                                (
                                    <>
                                         <option value="house_verification" selected>House Verification</option>
                                        <option value="house_mapping">House Mapping</option>
                                        <option value="voters_verification">Voters Verification</option>
                                        <option value="detached_voters">Detached Voters</option>
                                        <option value="new_voters">New Voters & House Shifted</option>
                                    </>
                                )
                                :
                                (
                                    this.state.viewStatus == "house_mapping" ? 
                                    (
                                        <>
                                            <option value="house_verification">House Verification</option>
                                            <option value="house_mapping" selected>House Mapping</option>
                                            <option value="voters_verification">Voters Verification</option>
                                            <option value="detached_voters">Detached Voters</option>
                                            <option value="new_voters">New Voters & House Shifted</option>
                                        </>
                                    )
                                    :
                                    (
                                        this.state.viewStatus == "voters_verification" ?
                                        (
                                            <>
                                                <option value="house_verification">House Verification</option>
                                                <option value="house_mapping">House Mapping</option>
                                                <option value="voters_verification" selected>Voters Verification</option>
                                                <option value="detached_voters">Detached Voters</option>
                                                <option value="new_voters">New Voters & House Shifted</option>
                                            </>
                                        )
                                        :
                                        (
                                            this.state.viewStatus == "detached_voters" ?
                                            (
                                                <>
                                                    <option value="house_verification">House Verification</option>
                                                    <option value="house_mapping">House Mapping</option>
                                                    <option value="voters_verification">Voters Verification</option>
                                                    <option value="detached_voters" selected>Detached Voters</option>
                                                    <option value="new_voters">New Voters & House Shifted</option>
                                                </>
                                            )
                                            :
                                            (
                                                this.state.viewStatus == "new_voters" ?
                                                (
                                                    <>
                                                        <option value="house_verification" >House Verification</option>
                                                        <option value="house_mapping">House Mapping</option>
                                                        <option value="voters_verification">Voters Verification</option>
                                                        <option value="detached_voters">Detached Voters</option>
                                                        <option value="new_voters" selected>New Voters & House Shifted</option>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <option value="house_verification" selected>House Verification</option>
                                                        <option value="house_mapping">House Mapping</option>
                                                        <option value="voters_verification">Voters Verification</option>
                                                        <option value="detached_voters">Detached Voters</option>
                                                        <option value="new_voters">New Voters & House Shifted</option>
                                                    </>
                                                )
                                            )
                                        )
                                    )
                                )
                            }
                           
                        </select>
                    </Col>
                </Row>
                {
                    this.state.boothWiseData.length == 0 ?
                    (
                    <Row>
                        <Col sm={12}>
                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                        </Col>
                    </Row>
                    )
                    :
                    ("")
                }
                {
                    this.state.boothWiseData.length>0 ?
                    (
                        <>
                        {
                            this.state.viewStatus == "house_verification" ?
                            (
                                <Row>
                                    <Col className='mt-2'>
                                    <CardLessDataTableWithSearch 
                                        parentData={this.houseVerificationData()}
                                        particlecolumns={this.houseVerificationColumns()}
                                        defaultSorted={[{
                                            dataField: "Verified",
                                            order: "desc"
                                        }]}
                                        tableHeading="Booth Wise House Verification Details"
                                        />
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        }
                        
                        {
                             this.state.viewStatus == "house_mapping" ? 
                             (
                                <Row>
                                    <Col className='mt-2'>
                                    <CardLessDataTableWithSearch 
                                        parentData={this.houseMappingData()}
                                        particlecolumns={this.houseMappingColumns()}
                                        defaultSorted={[{
                                            dataField: "Mapped",
                                            order: "desc"
                                        }]}
                                        tableHeading="Booth Wise House Mapping Details"
                                        />
                                    </Col>
                                </Row>
                             )
                             :
                             ("")
                        }

{
                             this.state.viewStatus == "voters_verification" ? 
                             (
                                <Row>
                                    <Col className='mt-2'>
                                    <CardLessDataTableWithSearch 
                                        parentData={this.votersVerificationData()}
                                        particlecolumns={this.votersVerificationColumns()}
                                        defaultSorted={[{
                                            dataField: "verified",
                                            order: "desc"
                                        }]}
                                        tableHeading="Booth Wise Voters Verification Details"
                                        />
                                    </Col>
                                </Row>
                             )
                             :
                             ("")
                        }

                        {
                            this.state.viewStatus == "detached_voters" ? 
                            (
                                <Row>
                                    <Col className='mt-2'>
                                    <CardLessDataTableWithSearch 
                                        parentData={this.detachedVotersData()}
                                        particlecolumns={this.detachedVotersColumns()}
                                        defaultSorted={[{
                                            dataField: "House_Shifting",
                                            order: "desc"
                                        }]}
                                        tableHeading="Booth Wise Detached Voters Details"
                                        />
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        }
                        
                        {
                            this.state.viewStatus == "new_voters" ? 
                            (
                                
                            <Row>
                                <Col className='mt-2'>
                                <CardLessDataTableWithSearch 
                                    parentData={this.newVotersData()}
                                    particlecolumns={this.newVotersColumns()}
                                    defaultSorted={[{
                                        dataField: "new_voters",
                                        order: "desc"
                                    }]}
                                    tableHeading="Booth Wise New Voters & House Shifted Details"
                                    />
                                </Col>
                            </Row>
                            )
                            :
                            ('')
                        }
                        


                        </>


                    )
                    :
                    ("")
                }
                
            </Container>
            </>
        )
    }
  }
  
  export default withRouter(BoothWiseDashboard);
  