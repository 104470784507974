
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,InputGroup,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import { withRouter,Link } from "react-router-dom";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import jwt_decode from "jwt-decode";
import CardLessDataTableWithSearchLimit10 from '../datatables/CardLessDataTableWithSearchLimit10';
import loader from '../../images/loader.gif';
import { scroller } from "react-scroll";
import UpdateVoterMigration from './UpdateVoterMigration';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class VotersMigration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessListArr:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            boothIdsList:[],
            boothId:"0",
            boothNo:"",
            boothIdValue:{value: "All",label: "All"},
            votersUpdatesArr:[],
            votersUpdateCheck:0,
            VMDetailsInABoothArr:[],
            VMDetailsInABoothCheck:0,
            VMTableHeading:"",
            VMDetailsBlockShow:false,

            voterIdDeocodeValSet:"",
            voterIdEncodeValSet:"",
            VMMobileNo:"",
            isUpdated:"",

            ValidateVMParty:"",
            ValidateVMMobileNo:"",
            ValidateVMReason:"",

            party_ids_Arr:[{'value':872,'label':'TDP'},{'value':1117,'label':'YSRC'},{'value':1892,'label':'JSP'},{'value':1887,'label':'Others'}],
            partyIdStr:"",
            partyIdStrValue:"",
            

            is_nonLocal_Arr:[{'value':'Y','label':'Y'},{'value':'N','label':'N'}],
            is_nonLocalVoter:"",
            is_nonLocalVoter_value:"",

            updateVoterVerificationModel:false,
            updateVoterModifiLoadingSymbol:"hide",
            updateVoterModifiMsg:"",
            getCurrentLocationCoordinatesForVV:{},
            filterBlockOpenVAC:false,

            casteCheckedCondAll:true,
            casteCheckedCondYes:false,
            casteCheckedCondNo:false,

            nonLocalCheckedCondYes:false,nonLocalCheckedCondNo:false,nonLocalCheckedCondAll:true,
            mobileCheckedCondYes:false,mobileCheckedCondNo:false,mobileCheckedCondAll:true,
            partyCheckedCondYes:false,partyCheckedCondNo:false,partyCheckedCondAll:true,
            residenceStatusCheckedCondYes:false,residenceStatusCheckedCondNo:false,residenceStatusCheckedCondAll:true,

            casteUpdatedVal:"",
            partyUpdatedVal:"",
            mobileUpdatedVal:"",
            residenceStatusUpdatedVal:"",
            nonLocalUpdatedVal:"",

            boothIdForGetVoters:"",
            isUpdatedForGetVoters:"",
            getVoterFilesUpdatedSymbol:"hide",


            locationUpdated:"",
            validateLocationUpdated:""
            
        }
        
    }

    componentDidMount() {
        let userAccessList = this.state.userAccessListArr;
        if(userAccessList != null && userAccessList.status == "Success" ){
            if(userAccessList.boothList != null && userAccessList.boothList.length>0){
                let boothListArr=[];
                const obj1 = {
                    value: "All",
                    label: "All",
                }
                boothListArr.push(obj1);
                userAccessList.boothList.map(item => {
                    const obj = {
                        value: item.id,
                        label: "Booth No -"+item.name,
                        attr_mapped:item.isMapped
                    }
                    boothListArr.push(obj);
                    
                });
                this.setState({ boothIdsList: boothListArr });
                this.getVoterVerificationUpdationStatus('',"Booth");
                
            }
        }else{
            this.props.history.push({pathname:"/dashboard"});
        }
    }
    isFilterBlockOpenVAC = () => {
        this.setState({filterBlockOpenVAC:!this.state.filterBlockOpenVAC });
    }

    checkParameterValue = (e,value,checkType) => {
        if(checkType == "party"){
            if(value == 'Y'){
                this.setState({ partyCheckedCondYes:true,partyCheckedCondNo:false,partyCheckedCondAll:false});
            }else if(value == 'N'){
                this.setState({ partyCheckedCondYes:false,partyCheckedCondNo:true,partyCheckedCondAll:false});
            }else if (value == ''){
                this.setState({ partyCheckedCondYes:false,partyCheckedCondNo:false,partyCheckedCondAll:true});
            }

            if (e.target.checked == true) {
                this.setState({ partyUpdatedVal:value});
            }
        }else if(checkType == "mobile"){
            if(value == 'Y'){
                this.setState({ mobileCheckedCondYes:true,mobileCheckedCondNo:false,mobileCheckedCondAll:false});
            }else if(value == 'N'){
                this.setState({ mobileCheckedCondYes:false,mobileCheckedCondNo:true,mobileCheckedCondAll:false});
            }else if (value == ''){
                this.setState({ mobileCheckedCondYes:false,mobileCheckedCondNo:false,mobileCheckedCondAll:true});
            }

            if (e.target.checked == true) {
                this.setState({ mobileUpdatedVal:value});
            }
        }else if(checkType == "nonLocal"){
            if(value == 'Y'){
                this.setState({ nonLocalCheckedCondYes:true,nonLocalCheckedCondNo:false,nonLocalCheckedCondAll:false});
            }else if(value == 'N'){
                this.setState({ nonLocalCheckedCondYes:false,nonLocalCheckedCondNo:true,nonLocalCheckedCondAll:false});
            }else if (value == ''){
                this.setState({ nonLocalCheckedCondYes:false,nonLocalCheckedCondNo:false,nonLocalCheckedCondAll:true});
            }

            if (e.target.checked == true) {
                this.setState({ nonLocalUpdatedVal:value});
            }
        }
       
    }

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        
      }

      handleOnchangeAllowAlphabiticalInputBox = () => {
        this.setState({validateLocationUpdated:""});
        var regex =  /^[a-zA-Z ]*$/;
        if (!regex.test(this.state.locationUpdated)) {
            this.setState({validateLocationUpdated:"Please Enter Only Alphabets...",locationUpdated:"" });
            return true;
        } 
      }

      handleOnchangeAllowNumbersInputBox = (e) => {
        this.setState({ValidateVMMobileNo:""});
        const name = e.target.name;
        const value = e.target.value;
        const re = /^[0-9\b]+$/;

        if(name == "VMMobileNo"){
            if (!re.test(e.target.value)) {
                this.setState({ValidateVMMobileNo:"Enter Numbers Only..",VMMobileNo:""});
                return;
            }else{
                this.setState({ValidateVMMobileNo:""});
            }
        }
      }

    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "boothId"){
          this.setState({ VMDetailsInABoothCheck: 0,VMDetailsInABoothArr:[],VMTableHeading:"",VMDetailsBlockShow:false});

          let getBoothIdName = this.state.boothIdsList.find((a) => a.value == value);
            this.setState({
              boothIdValue: {
                  value: getBoothIdName.value,
                  label: getBoothIdName.label
              }
          });
    
          this.setState({ boothId: getBoothIdName.value,boothNo:"Booth "+getBoothIdName.label});
          if(value == "All"){
            this.getVoterVerificationUpdationStatus('',"Booth");
          }else{
            this.getVoterVerificationUpdationStatus(value,"Booth");
          }
         
        
        }else if(name == "partyIdStr"){
            let partyIdName = this.state.party_ids_Arr.find((a) => a.value == value);
            this.setState({
                partyIdStrValue: {
                  value: partyIdName.value,
                  label: partyIdName.label
              }
          });

    
          this.setState({ partyIdStr: value});
        }else if(name == "is_nonLocalVoter"){
            let nonLocalIdName = this.state.is_nonLocal_Arr.find((a) => a.value == value);
            this.setState({
                is_nonLocalVoter_value: {
                  value: nonLocalIdName.value,
                  label: nonLocalIdName.label
              }
          });

    
          this.setState({ is_nonLocalVoter: value});
        }
    }
  
    getVoterVerificationUpdationStatus = (boothId,type) => {
        this.setState({ votersUpdateCheck: 0,votersUpdatesArr:[]});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "type" : type,
            "boothId" : boothId 
            }
        ); 

        getAPICallDataForOnlyBSA(obj,"verification/getVoterMigrationUpdationStatus")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.length>0){
                    this.setState({ votersUpdateCheck: 0,votersUpdatesArr:data});
                }else{
                    this.setState({ votersUpdateCheck: 1,votersUpdatesArr:[]});
                }
            });
  }


votersUpdatesDisplayColumns() {

    let columns = [
        {
            dataField: "Booth",
            text: "Booth",
            sort: true,
            
            
        },
        {
            dataField: "Total_Voters",
            text: "Total",
            sort: true,
            
        },
        {
            dataField: "verified",
            text: "V",
            sort: true,
            formatter: this.actionsFormatterForVerifiedDeletions.bind(this),
            
        },
        {
            dataField: "verified_perc",
            text: "%",
            sort: true,
            formatter: this.actionsFormatterForVerifiedPercDeletions.bind(this),
            
        },
        {
            dataField: "Pending",
            text: "P",
            sort: true,
            formatter: this.actionsFormatterForPendingDeletions.bind(this),
            
        },
        {
            dataField: "Pending_perc",
            text: "%",
            sort: true,
            formatter: this.actionsFormatterForPendingPercDeletions.bind(this),
        },
        
    
    ];
    return columns;
}


actionsFormatterForVerifiedDeletions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_green' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    row.verified >0 ?
                    (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold text-decoraion-underline cursor_pointer' onClick={() => this.getBoothWiseVotersUpdateDetila(row.BoothId,row.Booth,"Y","withOutFilters","verified")}>{row.verified}</span></span>)
                    :
                    (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold'>0</span>)
                }
                
            </span>
           
         </>

        
     );
    
}


actionsFormatterForVerifiedPercDeletions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_green ' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    row.verified >0 ?
                    (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold' >{row.verified_perc}</span> </span>)
                    :
                    (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold'>0</span>)
                }
            </span>
           
         </>

        
     );
    
}



actionsFormatterForPendingDeletions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_red ' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    row.Pending >0 ?
                    (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold text-decoraion-underline cursor_pointer' onClick={() => this.getBoothWiseVotersUpdateDetila(row.BoothId,row.Booth,"N","withOutFilters","Pending")}>{row.Pending}</span> </span>)
                    :
                    (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold'>{row.Pending}</span>)
                }
            </span>
           
         </>

        
     );
    
}


actionsFormatterForPendingPercDeletions = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_red ' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    row.Pending >0 ?
                    (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold' >{row.Pending_perc}</span> </span>)
                    :
                    (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold'>0</span>)
                }
            </span>
           
         </>

        
     );
    
}


votersUpdatesDisplayData = () => {
    let votersData = [];
    if(this.state.votersUpdatesArr.length>0){
        
        for (let i = 0; i < this.state.votersUpdatesArr.length; i++) {

            const locationName = (this.state.votersUpdatesArr[i].partNo);
            const locationId = (this.state.votersUpdatesArr[i].boothId);
            const deletedUpdatedCnt = (this.state.votersUpdatesArr[i].updated);
            const deletedPendingCnt = (this.state.votersUpdatesArr[i].pending);
            const deletedUpdatedPercentage = (this.state.votersUpdatesArr[i].updatedPercentage);
            const deletedPendingPercentage = (this.state.votersUpdatesArr[i].pendingPercentage);
            const votersCnt = (this.state.votersUpdatesArr[i].totalVoters);
            
            const obj = {
                Booth:(locationName),
                BoothId: locationId,
                Total_Voters:votersCnt,
                verified:deletedUpdatedCnt,
                verified_perc:deletedUpdatedPercentage,
                Pending:deletedPendingCnt,
                Pending_perc:deletedPendingPercentage
            }
            votersData.push(obj);
        } 

        
        
        return votersData;
    }
}

getBoothWiseVotersUpdateDetila = (boothId,boothName,isUpdated,filterType,statusName) => {
    scroller.scrollTo("scroll_top_VM", { 
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
    });
    this.setState({ VMDetailsBlockShow:true});
    this.setState({ VMDetailsInABoothCheck: 0,VMDetailsInABoothArr:[]});
    if(filterType == "withOutFilters"){
        this.setState({ boothIdForGetVoters: boothId,isUpdatedForGetVoters:isUpdated,VMTableHeading: "Booth-"+boothName+" - "+statusName+" Voter Details"});
    }
    
    setTimeout(() => {
    
    const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "boothId" : this.state.boothIdForGetVoters,
            "isUpdated" : this.state.isUpdatedForGetVoters,
            "isPartyUpdated" : this.state.partyUpdatedVal,
            "isMobileUpdated" : this.state.mobileUpdatedVal,
            "isNonLocalUpdated" : this.state.nonLocalUpdatedVal
            }
        ); 

        getAPICallDataForOnlyBSA(obj,"verification/getVoterDetailsForMigrationUpdateInABooth")
            .then((res) => res.json())
            .then((data) => {
                this.setState({filterBlockOpenVAC:false });
                if(data !=null && data.length>0){
                    console.log("ddddddd",data)
                    const decodeVoterDataVM = data.map((item,index) => {

                        var voterIdDecode = item.voterIdStr == "" ? "" : jwt_decode(item.voterIdStr).sub;
                        var voterIdEncoded = (item.voterIdStr);
                        var serialNoStr = item.serialNoStr == "" ? "" : jwt_decode(item.serialNoStr).sub;
                        var voterCardNo = item.voterCardNo == "" ? "" : jwt_decode(item.voterCardNo).sub;
                        var voterName = item.voterName == "" ? "" : jwt_decode(item.voterName).sub;
                        var relation = item.relation == "" ? "" : jwt_decode(item.relation).sub;
                        var relativeName = item.relativeName == "" ? "" : jwt_decode(item.relativeName).sub;
                        var ageStr = item.ageStr == "" ? "" : jwt_decode(item.ageStr).sub;
                        var gender = item.gender == "" ? "" : jwt_decode(item.gender).sub;
                        var houseNo = item.houseNo == "" ? "" : jwt_decode(item.houseNo).sub;
                        var isUpdated = item.isUpdated == "" ? "" : jwt_decode(item.isUpdated).sub;
                        var isMobileUpdated = item.isMobileUpdated == "" ? "" : jwt_decode(item.isMobileUpdated).sub;
                        var isPartyUpdated = item.isPartyUpdated == "" ? "" : jwt_decode(item.isPartyUpdated).sub;
                        var isCasteUpdated = "";
                        var isNonLocalUpdated = item.isNonLocalUpdated == "" ? "" : jwt_decode(item.isNonLocalUpdated).sub;
                        var isStatusUpdated = ""
                        
                        return { voterIdDecode,voterIdEncoded,serialNoStr,voterCardNo,voterName,relation,
                            relativeName,ageStr,gender,houseNo,isMobileUpdated,isPartyUpdated,isCasteUpdated,
                            isNonLocalUpdated,isStatusUpdated,isUpdated }
                    });

                    console.log("ddddddd",decodeVoterDataVM)

                    this.setState({ VMDetailsInABoothCheck: 0,VMDetailsInABoothArr:decodeVoterDataVM});
                }else{
                    this.setState({ VMDetailsInABoothCheck: 1,VMDetailsInABoothArr:[]});
                }
            });
        }, 500);
}


sortFuncSerialNoVM(a, b, order, dataField, rowA, rowB)  { 
    if (order == 'desc') {
        
        return parseInt(rowB.voterCardNo)  > parseInt(rowA.voterCardNo) ? 1 : -1;

    } else {
        return parseInt(rowA.voterCardNo)  > parseInt(rowB.voterCardNo) ? 1 : -1;
    }
}

VMDetailsDisplayColumns() {

    let columns = [
        {
            dataField: "serialNo",
            text: "SNO",
            sort: true,
            sortFunc: this.sortFuncSerialNoVM.bind(this),
            formatter: this.actionsFormatterForVMDetailsSerialNo.bind(this),
            
        },
        
        {
            dataField: "voterName",
            text: "voter Name",
            sort: true,
            formatter: this.actionsFormatterForVMDetails.bind(this),
            headerStyle: {
                width: "45%"
            }
        },
        {
            dataField: "Age",
            text: "Age",
            sort: true,
           
            
        },
        {
            dataField: "Gender",
            text: "Gen der",
            sort: true,
        },{
            dataField: "Actions",
            text: "A/c",
            formatter: this.actionsFormatterForUpdateVM.bind(this),
            
        },
    
    ];
    return columns;
}
actionsFormatterForVMDetailsSerialNo = (cell, row, rowIndex, formatExtraData) => {
    return (
       (row.voterCardNo)
       
     );
}

actionsFormatterForVMDetails = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.voterName)} 
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>V - {(row.serialNo)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>HouseNo - {(row.houseNo)}</span></>
           {/* <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>R - {(row.relativeName)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Rel - {(row.relation)}</span></>*/}

            <><br/><span className='f-10' style={{textAlign:"left",marginLeft:"0px"}}>
            P - {(row.isPartyUpdated) == 'Y' ? <b className='color_green'>Y</b> : <b className='color_red'>N</b>},
            M - {(row.isMobileUpdated) == 'Y' ? <b className='color_green'>Y</b> : <b className='color_red'>N</b>},
            N - {(row.isNonLocalUpdated) == 'Y' ? <b className='color_green'>Y</b> : <b className='color_red'>N</b>},
            
            </span></>
        </span>
     );
    
}

actionsFormatterForUpdateVM = (cell, row, rowIndex, formatExtraData) => {
      

    return (
        <>
        {
            row.isUpdated == 'Y'  ?
            (
                <OverlayTrigger key={"top"} placement={"top"}
                    overlay={
                        <Tooltip id={`tooltio-Filter-vm`}>
                        <h6 className='f-14'>Update Verification Status</h6>
                        </Tooltip>
                    }
                    >
                     <span className='cursor_pointer fw-bold color_green' onClick={() => this.updateVoterModificationDts(row)}><i class="fa-solid fa-pen-to-square"></i></span>
                </OverlayTrigger>
               
            )
            :
            (
                <OverlayTrigger key={"top"} placement={"top"}
                    overlay={
                        <Tooltip id={`tooltio-Filter-vm1`}>
                        <h6 className='f-14'>Update Verification Status</h6>
                        </Tooltip>
                    }
                    >
                     <span className='cursor_pointer fw-bold color_red' onClick={() => this.updateVoterModificationDts(row)}><i class="fa-solid fa-pen-to-square"></i></span>
                </OverlayTrigger>
            )
        }
        
        </>
          
     );
}

VMDetailsDisplayData = () => {
    let votersData = [];
    if(this.state.VMDetailsInABoothArr.length>0){
        
        for (let i = 0; i < this.state.VMDetailsInABoothArr.length; i++) {

            const voterIdEncoded = (this.state.VMDetailsInABoothArr[i].voterIdEncoded);
            const serialNo = (this.state.VMDetailsInABoothArr[i].serialNoStr);
            const voterCardNo = (this.state.VMDetailsInABoothArr[i].voterCardNo);
            const voterName = (this.state.VMDetailsInABoothArr[i].voterName);
            const relation = (this.state.VMDetailsInABoothArr[i].relation);
            const relativeName = (this.state.VMDetailsInABoothArr[i].relativeName);
            const ageStr = (this.state.VMDetailsInABoothArr[i].ageStr);
            const gender = (this.state.VMDetailsInABoothArr[i].gender);
            const houseNo = (this.state.VMDetailsInABoothArr[i].houseNo);
            const isUpdated = (this.state.VMDetailsInABoothArr[i].isUpdated);
            const isMobileUpdated = (this.state.VMDetailsInABoothArr[i].isMobileUpdated);
            const isPartyUpdated = (this.state.VMDetailsInABoothArr[i].isPartyUpdated);
            const isCasteUpdated = (this.state.VMDetailsInABoothArr[i].isCasteUpdated);
            const isNonLocalUpdated = (this.state.VMDetailsInABoothArr[i].isNonLocalUpdated);
            const isStatusUpdated = (this.state.VMDetailsInABoothArr[i].isStatusUpdated);
            const voterIdDecode = (this.state.VMDetailsInABoothArr[i].voterIdDecode);
            
            const obj = {
                serialNo:voterCardNo,
                voterName: voterName,
                Age: ageStr,
                Gender: gender,
                voterCardNo:serialNo,
                voterIdEncoded:voterIdEncoded,
                voterIdDecode:voterIdDecode,
                isUpdated:isUpdated,
                houseNo:houseNo,
                relativeName:relativeName,
                relation:relation,
                isMobileUpdated:isMobileUpdated,
                isPartyUpdated:isPartyUpdated,
                isCasteUpdated:isCasteUpdated,
                isNonLocalUpdated:isNonLocalUpdated,
                isStatusUpdated:isStatusUpdated,
                Actions:"Update"
            }
            votersData.push(obj);
        } 

        
        
        return votersData;
    }
}

updateVoterModificationDts = (row) => {
    this.setState({updateVoterVerificationModel:true});
    this.setState({getVoterFilesUpdatedSymbol:"show"});
    this.setState({updateVoterModifiMsg:"",voterIdDeocodeValSet:"",getCurrentLocationCoordinatesForVV:{}});  
    this.setState({partyIdStr:"",mobileNoStr:"",casteIdStr:"",partyIdStrValue:"",casteIdStrValue:"",
    reasonAddIdStr:"",reasonAddStrValue:"",is_nonLocalVoter:"",is_nonLocalVoter_value:"",voterIdEncodeValSet:""
    });

    setTimeout(() => {
        this.setState({voterIdDeocodeValSet:row.voterIdDecode,voterIdEncodeValSet:row.voterIdEncoded});
        navigator.geolocation.getCurrentPosition(this.showLocationVMMappingPostions,this.ShowLocationPermissionError)
       


        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "voterIdStr" : row.voterIdEncoded,
            
            }); 
        

    
        getAPICallDataForOnlyBSA(obj,"verification/getVoterMgrationDetailsByVoterId")
        .then((res) => res.json())
        .then((data) => {
            this.setState({getVoterFilesUpdatedSymbol:"hide"});  
            if(data !=null){
                if(data.partyId !=""){
                    const decodePartyId = jwt_decode(data.partyId).sub;
                    let partyIdName = this.state.party_ids_Arr.find((a) => a.value == decodePartyId);
                        this.setState({
                            partyIdStrValue: {
                            value: partyIdName.value,
                            label: partyIdName.label
                        },partyIdStr:decodePartyId
                    });
                }
                
                if(data.mobileNo != ""){
                    this.setState({VMMobileNo:jwt_decode(data.mobileNo).sub});
                }
                
                
                if(data.isNonLocal !=""){
                    const decodeisNonLocal = jwt_decode(data.isNonLocal).sub;
                    let outsidevoterIdName = this.state.is_nonLocal_Arr.find((a) => a.value == decodeisNonLocal);
                        this.setState({
                            is_nonLocalVoter_value: {
                            value: outsidevoterIdName.value,
                            label: outsidevoterIdName.label
                        },is_nonLocalVoter:decodeisNonLocal
                    });
                }

                if(data.location != ""){
                    this.setState({locationUpdated:jwt_decode(data.location).sub});
                }

                
                
            }else{
                this.setState({updateVoterVerificationModel:false});
            }
        });
    }, 500);

    
    
}

showLocationVMMappingPostions = (position) => {
    const getCoordinates =  
        { 
          "latitude" : position.coords.latitude,
          "longitude" : position.coords.longitude,
          "accuracy":position.coords.accuracy
        }
        
    this.setState({getCurrentLocationCoordinatesForVV:getCoordinates});
  }

  ShowLocationPermissionError = (error) => {
    switch (error.code){
        case error.PERMISSION_DENIED:
          alert("User Location Permission is Denied,Please Enable Location Services...(Path:Settings > Privacy > Location Services)");
          break;

          case error.POSITION_UNAVAILABLE:
          alert("User Postion Unavailable Denied");
          break;

          default:
            alert("An unknown error occurred")
    }
}

updateVoterModifiFields = () => {
    this.setState({ValidateVMMobileNo:"",validateLocationUpdated:"",ValidateVMParty:""});

    if(this.state.partyIdStr == ""){
        this.setState({ValidateVMParty:"Please Select Party..." });
        return;
    }


    if(this.state.VMMobileNo == ""){
        this.setState({ValidateVMMobileNo:"Please Mobile No" });
        return;
    }

    if(!this.state.VMMobileNo.match(/^[0-9]+$/)){
        this.setState({ValidateVMMobileNo:"Please Enter Valid Mobile No" });
        return;
        }

    if(this.state.VMMobileNo.length < 10){
        this.setState({ValidateVMMobileNo:"Please Enter Valid Mobile No" });
        return;
    }
   

    if(this.state.is_nonLocalVoter == 'Y'){
        if(this.state.locationUpdated == ""){
            this.setState({validateLocationUpdated:"Please Enter Location..." });
            return;
        }
    }
    
   

    this.setState({updateVoterModifiLoadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "voterIdStr" : this.state.voterIdEncodeValSet,
            "partyId" : this.state.partyIdStr,
            "mobileNo" : this.state.VMMobileNo,
            "isNonLocal" : this.state.is_nonLocalVoter,
            "latitude": this.state.getCurrentLocationCoordinatesForVV.latitude,
            "longitude": this.state.getCurrentLocationCoordinatesForVV.longitude,
            "location":this.state.locationUpdated,

            }); 

            getAPICallDataForOnlyBSA(obj,"verification/updateVoterMigration")
            .then((res) => res.json())
            .then((data) => {
              this.setState({updateVoterModifiLoadingSymbol:"hide"});  
                if(data !=null && data.status == "Success"){
                    this.setState({updateVoterModifiMsg:"Updated SuccessFully..."}); 

                    this.change_status_VV(this.state.VMDetailsInABoothArr)   

                    setTimeout(() => { 
                        this.setState({updateVoterVerificationModel:false});
                        
                        this.setState({updateVoterFldMsg:"",getCurrentLocationCoordinatesForVV:{}});  
                        this.setState({partyIdStr:"",mobileNoStr:"",casteIdStr:"",partyIdStrValue:"",casteIdStrValue:"",
                        reasonAddIdStr:"",reasonAddStrValue:"",is_nonLocalVoter:"",is_nonLocalVoter_value:"",locationUpdated:""
                        });
                        
                        
                    }, 1500);
                }else{
                    this.setState({updateVoterModifiMsg:"Some Thing Wrong,Please Check once"});  
                   
                }
          });
}

change_status_VV = (_data) => {
    for(var key in _data){
        if(_data[key].voterIdDecode == this.state.voterIdDeocodeValSet){
            if(this.state.partyIdStr != ""){
                _data[key].isPartyUpdated = 'Y'
            }else{
                _data[key].isPartyUpdated = 'N'
            }

            

            if(this.state.VMMobileNo != ""){
                _data[key].isMobileUpdated = 'Y'
            }else{
                _data[key].isMobileUpdated = 'N'
            }

            
            if(this.state.is_nonLocalVoter != ""){
                _data[key].isNonLocalUpdated = 'Y'
            }else{
                _data[key].isNonLocalUpdated = 'N'
            }

            _data[key].isUpdated = 'N'

        }
    }
    console.log("_data",_data) ;
    this.setState({VMDetailsInABoothArr:_data});
}


  refreshTable2023Oct = () => {
    this.getVoterVerificationUpdationStatus('',"Booth");
  }
    render() {
      
        return (
            <section>
               <Container>
               <Row>
                    <Col>
                        <h6 className='text-center fw-bold text-decoration-underline'>2024 JAN Migrated Voters</h6>
                    </Col>
                </Row>  

                {
                        this.state.votersUpdatesArr.length == 0 ?
                        (
                            this.state.votersUpdateCheck == 1 ?
                            (<Col className='f-14 text-center mt-3'>No Data Available</Col>)
                            :
                            (<Col sm={12}>
                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                            </Col>)
                        )
                        :
                        (
                            this.state.votersUpdatesArr.length>0 ? 
                            (
                                <Row>
                                    <Col sm={12}>
                                        <div className='bg_light_blue mt-2'>
                                        <h6 className='f-12 mt-3 fw-bold'>
                                                Note : <span className='ml_5 mr_2'>V - Verified,</span>
                                                        <span className='ml_5 mr_2'>P - Pending</span>
                                                        <OverlayTrigger key={"top"} placement={"top"}
                                                            overlay={
                                                            <Tooltip id={`tooltio-Filter`}>
                                                                <h6 className='f-14'>Refresh</h6>
                                                            </Tooltip>
                                                            }
                                                        >
                                                        <i class="fa-solid fa-arrows-rotate float-end cursor_pointer f-14" onClick={() => this.refreshTable2023Oct()}></i>     
                                                        </OverlayTrigger>
                                            </h6>
                                            <CardLessDataTableWithSearchLimit10 
                                                parentData={this.votersUpdatesDisplayData()}
                                                particlecolumns={this.votersUpdatesDisplayColumns()}
                                                defaultSorted={[{
                                                    dataField: "Deleted",
                                                    order: "desc"
                                                }]}
                                            tableHeading=""
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        )
                        
                    }

                    {
                        this.state.VMDetailsBlockShow == true  ?
                        (
                            <div className='scroll_top_VM'>
                        <div className='bg_light_blue mt-2'>
                                <Row>
                                    <Col className='w_80'>
                                        <h6 className="form-label f-14 fw-bold mt-4">{this.state.VMTableHeading}</h6>
                                    </Col>
                                    <Col className='w_20'>
                                        <OverlayTrigger key={"top"} placement={"top"}
                                            overlay={
                                                <Tooltip id={`tooltio-Filter`}>
                                                <h6 className='f-14'>Show Filter</h6>
                                                </Tooltip>
                                            }
                                            >
                                            <h6 className='float-right cursor_pointer color_red filter_pos' onClick={() => this.isFilterBlockOpenVAC()}><i class="fa-solid fa-filter f-28" ></i></h6>   
                                        </OverlayTrigger>
                                        
                                    </Col>
                                </Row>
                                {
                                    this.state.filterBlockOpenVAC == true ? 
                                    (
                                        <>
                                        
                                        
                                        <Row>
                                            <Col>
                                                <h6 className='mt-2'>
                                                    <span className="form-label f-14 fw-bold ml-0">
                                                        Party Updated : 
                                                    </span>
                                                    <span className='me-4'>
                                                        <Form.Check
                                                            inline
                                                            label={"All"}
                                                            name="voterParameterParty"
                                                            type="radio"
                                                            id="partyAllId"
                                                            onChange={(e)=>this.checkParameterValue(e,'','party')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.partyCheckedCondAll}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label={"Yes"}
                                                            name="voterParameterParty"
                                                            type="radio"
                                                            id="partyYesId"
                                                            onChange={(e)=>this.checkParameterValue(e,'Y','party')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.partyCheckedCondYes}
                                                        />
                                                        
                                                        <Form.Check
                                                            inline
                                                            label={"No"}
                                                            name="voterParameterParty"
                                                            type="radio"
                                                            id="partyNoId"
                                                            onChange={(e)=>this.checkParameterValue(e,'N','party')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.partyCheckedCondNo}
                                                        />
                                                    </span>
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h6 className='mt-2'>
                                                    <span className="form-label f-14 fw-bold ml-0">
                                                        Mobile Updated:
                                                    </span>
                                                    <span className='me-4'>
                                                        <Form.Check
                                                            inline
                                                            label={"All"}
                                                            name="voterParameterMobile"
                                                            type="radio"
                                                            id="mobileAllId"
                                                            onChange={(e)=>this.checkParameterValue(e,'','mobile')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.mobileCheckedCondAll}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label={"Yes"}
                                                            name="voterParameterMobile"
                                                            type="radio"
                                                            id="mobileYesId"
                                                            onChange={(e)=>this.checkParameterValue(e,'Y','mobile')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.mobileCheckedCondYes}
                                                        />
                                                        
                                                        <Form.Check
                                                            inline
                                                            label={"No"}
                                                            name="voterParameterMobile"
                                                            type="radio"
                                                            id="mobileNoId"
                                                            onChange={(e)=>this.checkParameterValue(e,'N','mobile')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.mobileCheckedCondNo}
                                                        />
                                                    </span>
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h6 className='mt-2'>
                                                    <span className="form-label f-14 fw-bold ml-0">
                                                        Is Non Local : 
                                                    </span>
                                                    <span className='me-4'>
                                                        <Form.Check
                                                            inline
                                                            label={"All"}
                                                            name="voterParameterNonLocal"
                                                            type="radio"
                                                            id="nonLocalAllId"
                                                            onChange={(e)=>this.checkParameterValue(e,'','nonLocal')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.nonLocalCheckedCondAll}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label={"Yes"}
                                                            name="voterParameterNonLocal"
                                                            type="radio"
                                                            id="nonLocalYesId"
                                                            onChange={(e)=>this.checkParameterValue(e,'Y','nonLocal')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.nonLocalCheckedCondYes}
                                                        />
                                                        
                                                        <Form.Check
                                                            inline
                                                            label={"No"}
                                                            name="voterParameterNonLocal"
                                                            type="radio"
                                                            id="nonLocalNoId"
                                                            onChange={(e)=>this.checkParameterValue(e,'N','nonLocal')}
                                                            className="color_black f-14 pos_name_align"
                                                            checked={this.state.nonLocalCheckedCondNo}
                                                        />
                                                    </span>
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getBoothWiseVotersUpdateDetila(0,'',"","withFilters","")}>SUBMIT</Button> 
                                            </Col>
                                        </Row>
                                        </>


                                    )
                                    :
                                    ("")
                                } 

                                {
                                    this.state.VMDetailsInABoothArr.length == 0 ?
                                    (
                                        this.state.VMDetailsInABoothCheck == 1 ?
                                        (<Col className='f-14 text-center mt-3'>No Data Available</Col>)
                                        :
                                        (<Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>)
                                    )
                                    :
                                    (
                                        this.state.VMDetailsInABoothArr.length>0 ? 
                                        (
                                            <>
                                            
                                            <Row>
                                                <Col sm={12}>
                                                    <div className='bg_light_blue mt-2'>
                                                    

                                                    
                                                    <hr className='mb-0 mt-0'/>
                                                    <h6 className='f-12 mt-3 fw-bold'>
                                                        Note : <span className='ml_5 mr_2'>P - Party,</span>
                                                                <span className='ml_5 mr_2'>M - Mobile,</span>
                                                                <span className='ml_5 mr_2'>N - Non Local</span>
                                                    </h6>
                                                    <h6 className='f-12 mt-2 fw-bold' style={{marginLeft:"40px"}}>
                                                                <span className='ml_5 mr_2 color_green'>Y - Updated,</span>
                                                                <span className='ml_5 mr_2 color_red'>N - Not Updated,</span>
                                                                
                                                    </h6>
                                                        <CardLessDataTableWithSearchLimit10 
                                                            parentData={this.VMDetailsDisplayData()}
                                                            particlecolumns={this.VMDetailsDisplayColumns()}
                                                            defaultSorted={[{
                                                                dataField: "serialNo",
                                                                order: "asc"
                                                            }]}
                                                        tableHeading={""}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            </>
                                        )
                                        :
                                        ("")
                                    )
                                    
                            }  
                        </div>
                    </div>
                        )
                        :
                        ("")
                    }

                    

                        
                <UpdateVoterMigration 
                        show={this.state.updateVoterVerificationModel}
                        onHide={() => this.setState({ updateVoterVerificationModel: false })}
                        stateData={this.state}
                        phandleOnchangeSelectBoxNew={this.handleOnchangeSelectBoxNew}
                        phandleOnchangeInputBox={this.handleOnchangeInputBox}
                        pupdateVoterModifiFields={this.updateVoterModifiFields}
                        handleOnchangeAllowAlphabiticalInputBox={this.handleOnchangeAllowAlphabiticalInputBox}
                        handleOnchangeAllowNumbersInputBox={this.handleOnchangeAllowNumbersInputBox}
                />
               </Container>
            </section>
        )
    }
  }
  
  export default withRouter(VotersMigration);
  