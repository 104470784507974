import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import {Container, Row, Col,Image,Button,Card  } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import "./ManifestDetails.css";

import TDPLOGO from '../../images/tdp_logo.png';
import HEADER from '../../images/header.png';

import MF_logo1 from '../../images/MF_logo1.png';

import MS_logo from '../../images/MahaShakti/MS_logo.jpg';
import MS_1 from '../../images/MahaShakti/MS_1.jpeg';
import MS_2 from '../../images/MahaShakti/MS_2.jpeg';
import MS_3 from '../../images/MahaShakti/MS_3.jpeg';
import MS_4 from '../../images/MahaShakti/MS_4.jpeg';


import AD_logo from '../../images/AnnaData/AD_logo.jpg';
import AD_1 from '../../images/AnnaData/AD_1.jpeg';


import BC_logo from '../../images/BC/BC_logo.jpg';
import BC_1 from '../../images/BC/BC_1.jpg';

import HW_logo from '../../images/HouseWater/HW_logo.jpg';
import HW_1 from '../../images/HouseWater/HW_1.jpeg';

import PTR_logo from '../../images/PoorToRich/PTR_logo.jpg';
import PTR_1 from '../../images/PoorToRich/PTR_1.jpeg';

import YG_logo from '../../images/Yuvagalam/YG_logo.jpg';
import YG_1 from '../../images/Yuvagalam/YG_1.jpg';
import YG_2 from '../../images/Yuvagalam/YG_2.jpeg';



class ManifestDetails extends Component {
  constructor(props) {
    super(props)

      this.state = {
        
        
      }
  }


  componentDidMount() {
        
  }


  render() {
    return (
      <section>
      <div className="">
            <div class="text-white">
              <img  class="bg_css_logo" />
              <div class="card-img-overlay">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="flex-header mt-1">
                          <img className="center-auto w-80P" src={MF_logo1} />
                          
                        </div>
                    </div>
                </div>
                <Link to={{pathname: "/dashboard"}}> 
                    <Button variant="outline-light ms-3 mt-3 pad5_14 back_button" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                </Link>
              </div>
              <div className='bg_red color_white fw-bold f-16'>TDP Manifest Phase1 Details :</div>
            </div>
          </div>
          <Container>
            <Row>
                    <Col className="mb-0">
                        <Card>
                            <Card.Header className="p-0"><img className="center-auto w-100" src={MS_logo} /> </Card.Header>
                            <Card.Body className="p-1">
                                <Card.Text>
                                <Carousel prevIcon={<i class="fa-solid fa-angle-left color_red f-35"></i>} nextIcon={<i class="fa-solid fa-angle-right color_red f-35"></i>} >
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={MS_1} alt="First slide" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={MS_2} alt="second slide" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={MS_3} alt="third slide" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={MS_4} alt="four slide" />
                                    </Carousel.Item>
                                    
                                    </Carousel>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3">
                        <Card>
                            <Card.Header className="p-0"><img className="center-auto w-100" src={YG_logo} /> </Card.Header>
                            <Card.Body className="p-2">
                                <Card.Text>
                                <Carousel prevIcon={<i class="fa-solid fa-angle-left color_red f-35"></i>} nextIcon={<i class="fa-solid fa-angle-right color_red f-35"></i>} >
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={YG_1} alt="First slide" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={YG_2} alt="second slide" />
                                    </Carousel.Item>
                                    </Carousel>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3">
                        <Card>
                            <Card.Header className="p-0"><img className="center-auto w-100" src={BC_logo} /> </Card.Header>
                            <Card.Body className="p-2">
                                <Card.Text>
                                <Carousel controls={false}>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={BC_1} alt="First slide" />
                                    </Carousel.Item>
                                    </Carousel>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3">
                        <Card>
                            <Card.Header className="p-0"><img className="center-auto w-100" src={AD_logo} /> </Card.Header>
                            <Card.Body className="p-2">
                                <Card.Text>
                                <Carousel controls={false} >
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={AD_1} alt="First slide" />
                                    </Carousel.Item>
                                    </Carousel>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3">
                        <Card>
                            <Card.Header className="p-0"><img className="center-auto w-100" src={HW_logo} /> </Card.Header>
                            <Card.Body className="p-2">
                                <Card.Text>
                                <Carousel controls={false} >
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={HW_1} alt="First slide" />
                                    </Carousel.Item>
                                    </Carousel>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3">
                        <Card>
                            <Card.Header className="p-0"><img className="center-auto w-100" src={PTR_logo} /> </Card.Header>
                            <Card.Body className="p-2">
                                <Card.Text>
                                <Carousel controls={false} >
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={PTR_1} alt="First slide" />
                                    </Carousel.Item>
                                    </Carousel>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
          </Container>
        
    </section>
    )
  }
}

export default withRouter(ManifestDetails);
