
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button  } from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService';
import HouseVisitedComponentData from "./HouseVisitedComponentData";

class HouseVisitedDisplayGoogleMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
          chatID: window.User !=undefined ? window.User.chatID : "943827154",
          boothId:props?.location?.params?.boothId == undefined ? 0 : props?.location?.params?.boothId,
          userLatitudeValue:props?.location?.params?.userLatitudeValue == undefined ? "" : props?.location?.params?.userLatitudeValue,
          userLongitudeValue:props?.location?.params?.userLongitudeValue == undefined ? "" : props?.location?.params?.userLongitudeValue,
          distanceCalLatLngDataArr:props?.location?.params?.distanceCalLatLngDataArr == undefined ? "" : props?.location?.params?.distanceCalLatLngDataArr,
          userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
          campaignId:props?.location?.params?.campaignId == undefined ? "" : props?.location?.params?.campaignId
          
        }
    }
  
    componentDidMount() {
      if(this.state.userAccessList == "" || this.state.campaignId == ""){
        this.props.history.push("/dashboard");
    }
  }


    render() {
        return (
            <>
            
            <Container fluid>
                <Row>
                  <Col>
                    <h6 className='text-center fw-bold'>House Visited Locations</h6>
                  </Col>
                </Row>
                {
                  this.state.campaignId == 1 ?
                  (
                    <Row>
                      <Col>
                          <Link to={{pathname: "/bsbg_two",
                                      params:{
                                        navbarDisplay:"hide",
                                        userAccessList:this.state.userAccessList,
                                      }}}> 
                              <Button variant="outline-dark pad5_14 color_black float-right" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                          </Link>
                      </Col>
                  </Row>
                  )
                  :
                  (
                    this.state.campaignId == 2 ?
                    (
                      <Row>
                        <Col>
                            <Link to={{pathname: "/bsbg_cadre",
                                        params:{
                                          navbarDisplay:"hide",
                                          userAccessList:this.state.userAccessList,
                                        }}}> 
                                <Button variant="outline-dark pad5_14 color_black float-right" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                            </Link>
                        </Col>
                    </Row>
                    )
                    :
                    (
                      this.state.campaignId == 3 ?
                      (
                        <Row>
                          <Col>
                              <Link to={{pathname: "/bsbg_shankharavam",
                                          params:{
                                            navbarDisplay:"hide",
                                            userAccessList:this.state.userAccessList,
                                          }}}> 
                                  <Button variant="outline-dark pad5_14 color_black float-right" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                              </Link>
                          </Col>
                      </Row>
                      )
                      :
                      ("")
                    )
                  )
                }
                
                <Row>
                        <Col sm={12} className='mt-4 mb-3'>
                           <div style={{height: "100vh" }}>
                                <HouseVisitedComponentData
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC5LChQlgAYuNsJra-_oUlpluSRyokMOLs&v=3.exp&libraries=geometry,drawing,places}`}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `100%` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    boothId={this.state.boothId}
                                    userLatitudeValue={this.state.userLatitudeValue}
                                    userLongitudeValue={this.state.userLongitudeValue}
                                    distanceCalLatLngDataArr={this.state.distanceCalLatLngDataArr}
                                  />
                                  </div>
                        </Col>
                        
                        
                    </Row>
            </Container>
            </>
        )
    }
  }
  
  export default withRouter(HouseVisitedDisplayGoogleMap);
  