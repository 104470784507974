
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';
import { getAPICallDataForCardDisplay } from '../../services/MemberService';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import { scroller } from "react-scroll";

class ReferralRegSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            referralFromDate:"2024-10-26",
            referralToDate:moment().format('YYYY-MM-DD'),
            referralLoadingSymbol:"show",
            referralDtsArr:[],
            referralDataCheck:0,
            referalMembershipId:"",
            referralMembersLoadingSymbol:"show",
            referralMembersArr:[],
            referralMembersDataCheck:0,
            dateSelectedValue : "",
            referralMemberBlockShow:false
        }
    }

    componentDidMount() {
        this.getMembershipIdByChatId();
        
    }

    getMembershipIdByChatId = () => {
        const BGObj =  
              JSON.stringify({ 
                "chatId" :this.state.chatID
              }); 
              getAPICallDataForOnlyBSA(BGObj,"getMembershipIdByChatId")
              .then((res) => res.json())
              .then((data) => {
                if(data !=null){
                    this.getDateWiseRegMembersCountByMembershipId(data.membershipId);
                  this.setState({ referalMembershipId:data.membershipId}); 
                }else{
                  this.setState({ referalMembershipId:""}); 
                }
                
              }); 
      }

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });
      }

      range = {
        "Today": [moment(), moment()],
        "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "OverAll": [moment("20241026"), moment()],
      };

      handleEventForCubs = (event, picker) => {
        console.log("start: ",  moment(picker.startDate._d).format('YYYY-MM-DD'));
        console.log("end: ",  moment(picker.endDate._d).format('YYYY-MM-DD'));
        
        this.setState({ referralFromDate: moment(picker.startDate._d).format('YYYY-MM-DD'),referralToDate:moment(picker.endDate._d).format('YYYY-MM-DD')});
      };


      

      getDateWiseRegMembersCountByMembershipId = (membershipId) => {
        this.setState({referralLoadingSymbol:"show",referralDtsArr:{},referralDataCheck:0});
        const obj =  
                JSON.stringify({ 
                    "membershipId" : membershipId,
                    "fromDate" : this.state.referralFromDate,
                    "toDate" : this.state.referralToDate
                    
                });
                
        getAPICallDataForCardDisplay(obj,"getDateWiseRegMembersCountByMembershipId")
            .then((res) => res.json())
            .then((content) => {
                this.setState({referralLoadingSymbol:"hide"});
                if(content !=null && content.length>0){
                    this.setState({referralDtsArr:content,referralDataCheck:0});
                   
                }else{
                    this.setState({referralDtsArr:[],referralDataCheck:1});
                }
            });
    }


    
    actionsFormatterForReferralDts = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
            <span className='f-12 color_green' style={{textAlign:"left",marginLeft:"0px"}}>
                {
                   row.Registrations>0 ?
                   (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold text-decoraion-underline cursor_pointer' onClick={() => this.getRegMemberDetailsByMembershipId(row.Date)}>{row.Registrations}</span></span>)
                   :
                   (<span style={{textAlign:"left",marginLeft:"0px"}} ><span style={{textAlign:"left",marginLeft:"0px"}}  className='fw-bold' >0</span></span>)
                   
                }
                
            </span>
           
         </>

        
     );
    
}

referralColumns() {
        let columns = [
            
            {
                dataField: "Date",
                text: "Date",
                sort: true,
            },
            {
                dataField: "Registrations",
                text: "Registrations",
                sort: true,
                formatter: this.actionsFormatterForReferralDts.bind(this),
                
            }
            
        
        ];
        return columns;
    }

    
    
    referralData = () =>{
    let dataArr2 = [];
        if(this.state.referralDtsArr.length>0){
            
            for (let i = 0; i < this.state.referralDtsArr.length; i++) {

                const date = (this.state.referralDtsArr[i].date);
                const count = (this.state.referralDtsArr[i].count);
                
               
                
                const obj = {
                    Date: date ,
                    Registrations: count
                }
                dataArr2.push(obj);
            } 

            
            
            return dataArr2;
        }
    }

    getRegMemberDetailsByMembershipId = (date) => {
        this.setState({dateSelectedValue:"Member Details - "+date,referralMemberBlockShow:true})
        scroller.scrollTo("scroll_top_VM", { 
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });

        var splitDate = date.split("-")
        this.setState({referralMembersLoadingSymbol:"show",referralMembersArr:{},referralMembersDataCheck:0});
        const obj =  
                JSON.stringify({ 
                    "membershipId" : this.state.referalMembershipId,
                    "fromDate" : splitDate[2]+"-"+splitDate[1]+"-"+splitDate[0],
                    "toDate" : splitDate[2]+"-"+splitDate[1]+"-"+splitDate[0]
                    
                });
                
        getAPICallDataForCardDisplay(obj,"getRegMemberDetailsByMembershipId")
            .then((res) => res.json())
            .then((content) => {
                this.setState({referralMembersLoadingSymbol:"hide"});
                if(content !=null && content.length>0){
                    this.setState({referralMembersArr:content,referralMembersDataCheck:0});
                   
                }else{
                    this.setState({referralMembersArr:[],referralMembersDataCheck:1});
                }
            });
    }

    
    actionsFormatterForReferralMemBind = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Name)} 
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>MID - {(row.MembershipId)}</span></>
            

            
        </span>
     );
    
}

    referralMembersColumns() {
        let columns = [
            
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                formatter: this.actionsFormatterForReferralMemBind.bind(this),
            },
            /*{
                dataField: "MembershipId",
                text: "MembershipId",
                sort: true,
            },*/
            {
                dataField: "MobileNo",
                text: "MobileNo",
                sort: true,
            },
            {
                dataField: "Assembly",
                text: "Assembly",
                sort: true,
            },
           /* {
                dataField: "Payment",
                text: "Payment",
                sort: true,
            },
            {
                dataField: "Date",
                text: "Date",
                sort: true,
            }*/
            
        
        ];
        return columns;
    }

    
    
    referralMembersDataDisplay = () =>{
    let dataArr2 = [];
        if(this.state.referralMembersArr.length>0){
            
            for (let i = 0; i < this.state.referralMembersArr.length; i++) {

                const cname = (this.state.referralMembersArr[i].cname);
                const mid = (this.state.referralMembersArr[i].mid);
                const mobileNo = (this.state.referralMembersArr[i].mobileNo);
                const constituencyName = (this.state.referralMembersArr[i].constituencyName);
                const paymentType = (this.state.referralMembersArr[i].paymentType);
                const regTime = (this.state.referralMembersArr[i].regTime);
                
               
                
                const obj = {
                    Name: cname ,
                    MembershipId: mid,
                    MobileNo: mobileNo ,
                    Assembly: constituencyName,
                    Payment: paymentType ,
                    Date: regTime
                }
                dataArr2.push(obj);
            } 

            
            
            return dataArr2;
        }
    }

    
      
    render() {
        console.log("roleName",this.state.roleName)
        return (
            <section className="mb-4">
           <div className='p-1'>
                    <Container>
                        <Row>
                            <Col>
                                <div className="media">
                                    <Image variant="top" src={require('../../images/tdp_new.png')} className='mr-3' />
                                    <div className="media-body mt-4">
                                        <h6 className="color_red fw-bold">TELUGU DESAM PARTY</h6>
                                        <h6 className='fw-bold f-14'>Dare To Dream - Strive To Achieve</h6>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className='bg_yellow_cadre'>   
            
                    <Container className="mb-6">
                        <Row>
                            <Col sm={12} className='mt-3'>
                            <Link to={{pathname: "/dashboard"}}> 
                                <Button variant="outline-danger pad5_14" className='f-12  fw-bold'> <i class="fa-solid fa-house"></i> Home</Button> 
                            </Link>
                            </Col>
                        </Row>
                        <Row>
                        <Col>
                            <h6 className='text-center color_green fw-bold  mt-3'><span className='border-bottom-double'>REFERRAL REG DETAILS</span> </h6>
                        </Col>
                    </Row>

                    {
                        this.state.referalMembershipId != "" ? 
                        (
                            <>
                            <Row>
                                <Col>
                                <h6 className="form-label f-12 fw-bold mt-3">Date Range :</h6>
                                <DateRangePicker
                                        alwaysShowCalendars={true}
                                        ranges = {this.range}
                                        onEvent={this.handleEventForCubs}
                                        className="w-100"
                                    >   
                                    <div class="input-group">
                                        <div class="input-group-prepend bgColorYash">
                                            <i class="fa fa-calendar pad_5_15"></i>
                                        </div>
                                        <input type="text" className="form-control" value={moment(this.state.referralFromDate).format("YYYY-MM-DD") +" to "+ moment(this.state.referralToDate).format("YYYY-MM-DD")} />
                                    </div>
                                    </DateRangePicker>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Button variant="dark" className='f-12 center-auto mt-3' onClick={() => this.getDateWiseRegMembersCountByMembershipId(this.state.referalMembershipId)}>GET DETAILS</Button> 
                                </Col>
                            </Row>

                            {
                                this.state.referralDtsArr.length == 0 ?
                                (
                                    this.state.referralDataCheck == 1 ?
                                    (<Col className='f-14 text-center'>No Data Available</Col>)
                                    :
                                    (<Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>)
                                )
                                :
                                (
                                    this.state.referralDtsArr.length>0 ?
                                    (
                                        <>
                                        <Row>
                                        
                                            <Col sm={12}>
                                            <div className='mt-2 p-0 bg_light_blue'>
                                                
                                                <CardLessDataTableWithOutSearch 
                                                    parentData={this.referralData()}
                                                    particlecolumns={this.referralColumns()}
                                                    defaultSorted={[{
                                                        dataField: "Date",
                                                        order: "desc"
                                                    }]}
                                                    tableHeading=""
                                                    />
                                                    
                                                    
                                            </div>
                                            
                                            </Col>
                                        </Row>
                                        </>
                                    )
                                    :
                                    ("")
                                )
                            }

                                <div className='scroll_top_VM'>
                                    {
                                    this.state.referralMemberBlockShow == true &&  this.state.referralMembersArr.length == 0 ?
                                    (
                                        this.state.referralMembersDataCheck == 1 ?
                                        (<Col className='f-14 text-center mt-3'>No Data Available</Col>)
                                        :
                                        (<Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>)
                                    )
                                    :
                                    (
                                        this.state.referralMembersArr.length>0 ? 
                                        (
                                            <Row>
                                                <Col sm={12}>
                                                    <div className='bg_light_blue mt-2'>
                                                    
                                                        <CardLessDataTableWithSearch 
                                                            parentData={this.referralMembersDataDisplay()}
                                                            particlecolumns={this.referralMembersColumns()}
                                                            defaultSorted={[{
                                                                dataField: "name",
                                                                order: "asc"
                                                            }]}
                                                        tableHeading={this.state.dateSelectedValue}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                    )
                                    
                                    }
                                </div>
                                </>
                        )
                        :
                        (
                            <>
                            <div className="border_yash_pad mt-3">
                            <Row>
                                <Col className='fw-bold text_css mt-3'>
                                    మీరు మీ రెఫరల్ లింక్ ఉపయోగించి తెలుగుదేశం అభిమానులు సభ్యత్వం తీసుకొనేలాగా  చేసారు, ధన్యవాదాలు. మీరు క్రియాశీలకంగా మారి ప్రతి రోజు 10 మంది సభ్యుల్ని మీ రెఫరల్ లింక్ ద్వారా చేర్పించగలరు.  మీ రెఫరల్ ద్వారా చేరిన వారి వివరాలు మీరు సభ్యత్వం చేసుక్కనపుడు sms వచ్చిన లింక్ ద్వారా చూడగలరు మరియు ఈక్రింద ఇచ్చిన లింక్ ద్వారా మీ రిజిస్టర్ మొబైల్ కు వచ్చే OTP ఎంటర్ చేసి తెలుసుకోగలరు 
                                </Col>
                            </Row>

                            <Row>
                            <Col className='fw-bold text_css mt-3'>
                               <a target='_blank' href='https://tdp1.in/CD/csm' className='color_red text-decoration-underline float-end f-14'>Click To View Details</a>
                            </Col>
                            </Row>
                            </div>
                            </>
                        )
                    }

                    

            </Container>
        </div>  

            
            </section>
        )
    }
  }
  
  export default withRouter(ReferralRegSection);
  