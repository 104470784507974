
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,Card } from 'react-bootstrap';
import "./cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import { getAPICallDataForCadreSaving } from '../../services/MemberService';
import LanguageSelectionPopup from "./LanguageSelectionPopup";
import AOS from 'aos';
import 'aos/dist/aos.css';

class CadreRegistrationSelectSection extends Component {
    constructor(props) {
        super(props)
        

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            //userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            
            selectMemberTypeBlock:true,

            lifeTimeEnrollCheck:false,
            generalEnrollCheck:false,
            donationEnrollCheck:false,

            languageSelectionModal:false,
            englishSelected:false,
            teleguSelected:true,
            volunteerMobileNoInput:"",
            volunteerCouponsCount:"",
            isVolunteer:"",
            volunterConstituencyName:"",

            authenticationMsg:""
 
        }
    }

    componentDidMount() {
        AOS.init();
        
       // console.log("userAccessList",this.state)
			
        this.setState({languageSelectionModal:false});
        this.getMobileNoByChatId();
    }

    
    typeOfLanguageSelection = (e,languageType) => {
        this.setState({languageSelectionModal:false});

        if(languageType == "english"){
            this.setState({englishSelected:true,teleguSelected:false})
        }else{
            this.setState({englishSelected:false,teleguSelected:true})
        }
    }

    typeOfLanguageSelectionNew = (e,languageType) => {
    
        if(languageType == "english"){
            this.setState({englishSelected:true,teleguSelected:false})
        }else{
            this.setState({englishSelected:false,teleguSelected:true})
        }
    }

    getMobileNoByChatId = () => {
        
        const obj =  
        JSON.stringify({ 
           "chatId" :this.state.chatID,
        }); 
    
        getAPICallDataForOnlyBSA(obj,"getMobileNoByChatId")
        .then((res) => res.json())
        .then((data) => {
            this.setState({authenticationMsg:""})
           if(data !=null && data.mobileNo !=null){
            this.getVolunteerDetailsByMobileNo(data.mobileNo);
           }else if(data !=null && data.status == "Fail"){
            this.setState({authenticationMsg:data.message})
           }
        });
    }

    
    getVolunteerDetailsByMobileNo = (mobileNo) => {
    const obj =  
        JSON.stringify({ 
            "volunteerMobileNo" : mobileNo
        });

        getAPICallDataForCadreSaving(obj,"getVolunteerDetailsByMobileNo")
        .then((res) => res.json())
        .then((content) => {
            if(content !=null ){
                this.setState({isVolunteer:content.isVolunteer,volunterConstituencyName:content.constituencyName});
                if(content.isVolunteer == "Y"){
                    this.setState({volunteerCouponsCount:content.couponsCount,volunteerMobileNoInput:content.mobileNo});
                }else{
                    this.setState({volunteerCouponsCount:"",volunteerMobileNoInput:""});
                }
               }else{
                this.setState({volunteerCouponsCount:"",volunteerMobileNoInput:""});
               }
        });
}


    lifeTimeMemberShipCheck = (e,type) => {
        if(type == "lifeTimeMem"){
            this.setState({lifeTimeEnrollCheck:true,generalEnrollCheck:false,donationEnrollCheck:false})
        }else if(type == "generalMem"){
            this.setState({lifeTimeEnrollCheck:false,generalEnrollCheck:true,donationEnrollCheck:false})
        }else{
            this.setState({lifeTimeEnrollCheck:false,generalEnrollCheck:false,donationEnrollCheck:true})
        }
    }
   
    
    render() {
        
       
        return (
            <section>
                <div className='p-1'>
                    <Container>
                        <Row>
                            <Col>
                                <div className="media">
                                    <Image variant="top" src={require('../../images/tdp_new.png')} className='mr-3' />
                                    <div className="media-body mt-4">
                                        <h6 className="color_red fw-bold">TELUGU DESAM PARTY</h6>
                                        <h6 className='fw-bold f-14'>Dare To Dream - Strive To Achieve</h6>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {
                    this.state.authenticationMsg == "Authentication Failed" || this.state.isVolunteer != "Y" ?
                    (
                        <div className="bg_red_ass_coup1"></div>
                    )
                    :
                    (
                        <div className="bg_red_ass_coup color_white fw-bold f-14"> 
                            {this.state.volunterConstituencyName} ASSEMBLY
                            {
                                this.state.volunteerCouponsCount == 0 ?
                                (<span className='float-right'>Coupons Not Available</span>)
                                :
                                (<span className='float-right'>{this.state.volunteerCouponsCount} Coupons </span>)
                            }
                        </div>
                    )
                }
                
                {
                    this.state.authenticationMsg == "Authentication Failed" ? 
                    (
                        <Row>
                            <Col>
                                <h4 className='color_red mt-3'>Session Expired...Please Close Then Reopen Telegram Bot</h4>
                            </Col>
                        </Row>
                    )
                    :
                    (
                        <div className='bg_yellow_cadre'>
                            <Container>
                                <Row>
                                    <Col sm={12} className='mt-2'>
                                        <div className='flex-back-lang'>
                                            <div>
                                                <Link to={{pathname: "/dashboard"}}> 
                                                    <Button variant="outline-danger pad5_14" className='f-12  fw-bold'> <i class="fa-solid fa-house"></i> Home</Button> 
                                                </Link>
                                            </div>
                                            <div>
                                                <Form>
                                                    <div className='float-end'>
                                                    <Form.Check
                                                        inline
                                                        label={`English Version`}
                                                        name="languageSelectionNew"
                                                        type="radio"
                                                        id={`englishddVersionId`}
                                                        onChange={(e)=>this.typeOfLanguageSelectionNew(e,"english")}
                                                        className="f-12 fw-bold color_red cursor_pointer"
                                                        value="english"
                                                        checked={this.state.englishSelected}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label={`తెలుగు వెర్షన్`}
                                                        name="languageSelectionNew"
                                                        type="radio"
                                                        id={`teleguddVersionId`}
                                                        onChange={(e)=>this.typeOfLanguageSelectionNew(e,"telegu")}
                                                        className="f-12 fw-bold color_red cursor_pointer"
                                                        value="telegu"
                                                        checked={this.state.teleguSelected}
                                                    />
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h6 class="text-center bg_red_pad f-16 mt-2">{this.state.englishSelected== true ? "SELECT MEMBERSHIP TYPE" : "ఈ  క్రింది  సభ్యత్వ  నమోదు  ప్రక్రియను  ఎంచుకోండి "}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className='card_yellow_custom'>
                                            <Row>
                                                <Col>
                                                    <div className='border_bottom_red cursor_pointer' onClick={(e)=>this.lifeTimeMemberShipCheck(e,"generalMem")}>
                                                        <Image variant="top" src={require('../../images/general_icon.png')} alt="General" className='w-res-80 center-auto' />
                                                        <h4 class="f-16 text-center fw-bold text-uppercase">{this.state.englishSelected== true ? 
                                                            <span>General <br/> Membership</span> : `సాధారణ సభ్యత్వం`}
                                                        </h4>
                                                        <h6 className='color_red f-22 text-center mt-3 mb-3'><i class="fa-solid fa-circle-chevron-right"></i></h6>
                                                    </div>
                                                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className='border_bottom_red mt-3 cursor_pointer' onClick={(e)=>this.lifeTimeMemberShipCheck(e,"lifeTimeMem")}>
                                                        <Image variant="top" src={require('../../images/life_time_icon.png')} alt="life_time_icon" className='w-res-80 center-auto' />
                                                        <h4 class="f-16 text-center fw-bold text-uppercase">{this.state.englishSelected== true ? 
                                                            <span>Life Time <br/>Membership</span> : `జీవితకాల సభ్యత్వం `}
                                                        </h4>
                                                        <h6 className='color_red f-22 text-center mt-3 mb-3'><i class="fa-solid fa-circle-chevron-right"></i></h6>
                                                    </div>
                                                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className='mt-3 cursor_pointer' onClick={(e)=>this.lifeTimeMemberShipCheck(e,"donation")}>
                                                        <Image variant="top" src={require('../../images/donation_icon.png')} alt="donation_icon" className='w-res-80 center-auto' />
                                                        <h4 class="f-16 text-center fw-bold">{this.state.englishSelected== true ? `DONATION` : `డొనేషన్`}</h4>
                                                        <h6 className='color_red f-22 text-center mt-3 mb-3'><i class="fa-solid fa-circle-chevron-right"></i></h6>
                                                    </div>
                                                    
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>

                                {
                                this.state.lifeTimeEnrollCheck == true ?
                                (
                                    this.props.history.push({
                                        pathname: "/cadre_general_reg",
                                        params: {
                                            navbarDisplay:"hide",
                                            //userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                            englishSelected:this.state.englishSelected,
                                            teleguSelected:this.state.teleguSelected,
                                            registrationType:"lifeTime",
                                            isVolunteerReloadCheck:this.state.isVolunteer
                                        
                                        },
                                    })
                                    
                                )
                                :
                                (
                                    this.state.generalEnrollCheck == true ? 
                                    (
                                        this.props.history.push({
                                            pathname: "/cadre_general_reg",
                                            params: {
                                                navbarDisplay:"hide",
                                                //userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                                englishSelected:this.state.englishSelected,
                                                teleguSelected:this.state.teleguSelected,
                                                registrationType:"general",
                                                isVolunteerReloadCheck:this.state.isVolunteer
                                            
                                            },
                                        })
                                    )
                                    :
                                    (
                                        this.state.donationEnrollCheck == true ?
                                        (
                                            this.props.history.push({
                                                pathname: "/donation_page",
                                                params: {
                                                    navbarDisplay:"hide",
                                                    //userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                                    englishSelected:this.state.englishSelected,
                                                    teleguSelected:this.state.teleguSelected,
                                                    isVolunteerReloadCheck:this.state.isVolunteer
                                                
                                                },
                                            })
                                        )
                                        :
                                        ("")
                                    )
                                )     
                            }
                            </Container>
                        </div>
                    )
                }

                

                
                
            </section>
        )
    }
  }
  
  export default withRouter(CadreRegistrationSelectSection);
  