
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import UpdateVoterForms from './UpdateVoterForms';
  
var party_ids_name={'872':'TDP','1117':'YSRC','1892':'JSP','1887':'Others'};


export async function getAPICallsForVoterFormsData(data,url) {
    
    const response  =    fetch('https://www.mypartydashboard.com/BSA/WebService/'+url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'user_data':"query_id=AAHSqEE4AAAAANKoQTiG5lGu&user=%7B%22id%22%3A943827154%2C%22first_name%22%3A%22Aravindpp%22%2C%22last_name%22%3A%22%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1704293188&hash=2f0cbef71c8908e34020dee36e8e543f3c290dc058829d1fa099d7b02327e1ea"
            //'user_data':window.Telegram.WebApp.initData
        },
     body: data
  })

   return response;
}

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class VotersFormsVerification extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,

            constituencyValue:"",
            constituencyId:"",
            constituencyListArr:[],

            formTypeListArr:[{'value':'Form-6','label':'Form-6'},{'value':'Form-7','label':'Form-7'},{'value':'Form-8','label':'Form-8'},{'value':'Form-8A','label':'Form-8A'},{'value':'Form-8B','label':'Form-8B'}],
            formTypeValue:{'value':'Form-7','label':'Form-7'},
            formType:"Form-7",
            validateformType:"",

            validatefromDate:"",
            validatetoDate:"",
            fromDate:"2023-09-01",
            toDate:moment().format("YYYY-MM-DD"),

            mandalListArr:[],
            mandalValue:"",
            mandal:"",
            validatemandal:"",
            mandalLodaingSymbol:"hide",

            villageListArr:[],
            villageValue:"",
            village:"",
            validatevillage:"",
            villageLodaingSymbol:"hide",

            voterSearchNameValue:"",
            objectorNameValue:"",
            isValidValue:"",

            formsDataArr:[],
            formsDataLoadingSymbol:"hide",
            formsDataCheck:0,

            party_ids_Arr:[{'value':872,'label':'TDP'},{'value':1117,'label':'YSRC'},{'value':1892,'label':'JSP'},{'value':1887,'label':'Others'}],
            partyIdStr:"",
            partyIdStrValue:"",
            validatepartyIdStr:"",

            is_valid_Arr:[{'value':'Y','label':'Yes'},{'value':'N','label':'No'}],
            is_valid_value:"",
            is_valid:"",
            validateis_valid:"",

            updateVoterFormsLoadingSymbol:"hide",
            updateVoterFormsMsg:"",
            UpdateVoterFormModal:false,
            selectedVoterUpdate:"",
            searchNameValue:"",
            firstTimeVotersSerach:0

        }
        this.handleSearchNameChange = this.handleSearchNameChange.bind(this);
    }

    componentDidMount() {
        console.log("userAccessListdddd",this.state.chatID)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
            }
           
            this.setState({ constituencyListArr:consArr });
            this.setState({
                constituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },constituencyId:this.state.userAccessList.constituencyList[0].id
        });
          
        
        }
    }

    handleSearchNameChange = (event) => {
        this.setState({
            searchNameValue: event.target.value
        })
    }

    getMandalsInAConstituencyFromFormsData = (value) => {
        this.setState({mandalLodaingSymbol:"show"})
        const obj =  
            JSON.stringify({ 
                "chatId" : this.state.chatID,
                "constituencyId" : this.state.constituencyValue.value,
                "formType" : value
            }
        ); 
        getAPICallData(obj,"getMandalsInAConstituencyFromFormsData")
            .then((res) => res.json())
            .then((data) => {
                this.setState({mandalLodaingSymbol:"hide"})
                if(data !=null && data.length>0){
                    var arr=[];
                    for(var i in  data){
                        var obj={'value':data[i].name,'label':data[i].name};
                        arr.push(obj);
                    }
                   
                    this.setState({ mandalListArr:arr });
                }else{
                    this.setState({ mandalListArr:[] });
                }
            });
  }

  getVillagesInAMandalFromFormsData = (value) => {
    this.setState({villageLodaingSymbol:"show"})
    const obj =  
        JSON.stringify({ 
            "chatId" : this.state.chatID,
            "constituencyId" : this.state.constituencyValue.value,
            "formType" : this.state.formType,
            "mandal" : value
        }
    ); 
    getAPICallData(obj,"getVillagesInAMandalFromFormsData")
        .then((res) => res.json())
        .then((data) => {
            this.setState({villageLodaingSymbol:"hide"})
            if(data !=null && data.length>0){
                var arr=[];
                for(var i in  data){
                    var obj={'value':data[i].name,'label':data[i].name};
                    arr.push(obj);
                }
               
                this.setState({ villageListArr:arr });
            }else{
                this.setState({ villageListArr:[] });
            }
        });
}
   
handleEventFormData = (event, picker) => {
    console.log("mmmmmmmmm",moment(picker.startDate).format("YYYY-MM-DD"))
    this.setState({ fromDate: moment(picker.startDate).format("YYYY-MM-DD")});
    
  }

     
handleEventToData = (event, picker) => {
    console.log("mmmmmmmmm",moment(picker.startDate).format("YYYY-MM-DD"))
    this.setState({ toDate: moment(picker.startDate).format("YYYY-MM-DD")});
    
  }


  handleOnchangeSelectBox = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "constituencyId"){
            let getConsIdName = this.state.constituencyListArr.find((a) => a.value == value);
                this.setState({
                    constituencyValue: {
                    value: getConsIdName.value,
                    label: getConsIdName.label
                }
            });
        }else if(name == "formType"){
            this.setState({firstTimeVotersSerach:0,searchNameValue:"",mandalListArr:[],mandalValue:"",mandal:"",villageListArr:[],villageValue:"",village:"",formsDataArr:[],voterSearchNameValue:"",
            objectorNameValue:""})
            let getIdName = this.state.formTypeListArr.find((a) => a.value == value);
            this.setState({
                formTypeValue: {
                value: getIdName.value,
                label: getIdName.label
            }
        });
            this.getMandalsInAConstituencyFromFormsData(value);
        }else if(name == "mandal"){
            this.setState({villageListArr:[],villageValue:"",village:""})
            let getIdName = this.state.mandalListArr.find((a) => a.value == value);
            this.setState({
                mandalValue: {
                value: getIdName.value,
                label: getIdName.label
            }
        });
            this.getVillagesInAMandalFromFormsData(value);
        }else if(name == "village"){
                let getIdName = this.state.villageListArr.find((a) => a.value == value);
                this.setState({
                    villageValue: {
                    value: getIdName.value,
                    label: getIdName.label
                }
            });
        }else if(name == "is_valid"){
                let getIdName = this.state.is_valid_Arr.find((a) => a.value == value);
                this.setState({
                    is_valid_value: {
                    value: getIdName.value,
                    label: getIdName.label
                }
            });
        }else if(name == "partyIdStr"){
            let getIdName = this.state.party_ids_Arr.find((a) => a.value == value);
            this.setState({
                partyIdStrValue: {
                value: getIdName.value,
                label: getIdName.label
            }
        });
    }
          
    
        this.setState({ [name]: value});
      }

      checkVerifiedStatus = (e,value) => {
        this.setState({isValidValue:value})
      }

      handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });
        
      }

    
      getFormsDataForVerfication = () => {
        this.setState({ formsDataArr:[],formsDataCheck:0,searchNameValue:"",firstTimeVotersSerach:0 });
        this.setState({validateformType:"" });
        if(this.state.formType == "" || this.state.formType == 0){
            this.setState({validateformType:"Please Select Form Type" });
            return;
        }
        
        this.setState({formsDataLoadingSymbol:"show",formsDataCheck:0})
            const obj =  
                JSON.stringify({ 
                    "chatId" : this.state.chatID,
                    "constituencyId" : this.state.constituencyValue.value,
                    "formType" : this.state.formType,
                    "mandal" : this.state.mandal,
                    "fromDate" : this.state.fromDate,
                    "toDate" : this.state.toDate,
                    "village" : this.state.village,
                    "voterName" : this.state.voterSearchNameValue,
                    "objectorName" : this.state.objectorNameValue,
                    "isValid" : this.state.isValidValue
                }
            ); 
            getAPICallsForVoterFormsData(obj,"getFormsDataForVerfication")
                .then((res) => res.json())
                .then((data) => {
                    this.setState({formsDataLoadingSymbol:"hide"})
                    if(data !=null && data.length>0){
                       
                        this.setState({ formsDataArr:data,formsDataCheck:0,firstTimeVotersSerach:1 });
                    }else{
                        this.setState({ formsDataArr:[],formsDataCheck:1,firstTimeVotersSerach:1 });
                    }
                });
      }

      sortFuncDateNo(a, b, order, dataField, rowA, rowB)  { 
        if (order == 'desc') {
            
            return parseInt(rowB.Date)  > parseInt(rowA.Date) ? 1 : -1;

        } else {
            return parseInt(rowA.Date)  > parseInt(rowB.Date) ? 1 : -1;
        }
    }

    formsDataDisplayColumnsForm7() {

        let columns = [
            {
                dataField: "booth",
                text: "Booth",
                sort: true,
            },
            {
                dataField: "Date",
                text: "Receipt Date",
                sort: true,
                sortFunc: this.sortFuncDateNo.bind(this)
            },
            {
                dataField: "Name",
                text: "Objector Name",
                formatter: this.actionsFormatterForform7sDataName.bind(this),
                sort: true,
                headerStyle: {
                    width: "40%"
                }
            },
            
            {
                dataField: "Actions",
                text: "A/c",
                formatter: this.actionsFormatterForformsUpdate.bind(this),
                
            },
        
        ];
        return columns;
    }

      formsDataDisplayColumns() {

        let columns = [
            {
                dataField: "Date",
                text: "Receipt Date",
                sort: true,
                sortFunc: this.sortFuncDateNo.bind(this)
            },
            {
                dataField: "Name",
                text: "Applicant Name",
                formatter: this.actionsFormatterForformsDataName.bind(this),
                sort: true,
                headerStyle: {
                    width: "55%"
                }
            },
            
            {
                dataField: "Actions",
                text: "A/c",
                formatter: this.actionsFormatterForformsUpdate.bind(this),
                
            },
        
        ];
        return columns;
    }

    actionsFormatterForform7sDataName = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.objectorName)} 
                    <>
                    <h6 className='f-10 text-center fw-normal mt-2' >Voter SerialNo - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.voterSerialNo)}</span></h6>
                    <h6 className='f-10 text-center fw-normal'>Voter&nbsp;Name - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.voterName)}</span></h6>
                    </>
                
                <>
                    <h6 className='f-12 text-center' >
                    Party - {(row.partyId) != '' ? <b className='color_green'>{party_ids_name[row.partyId]}</b> : <b className='color_red'>Not Updated</b>},
                </h6>
                </>
            </span>
         );
    }

    actionsFormatterForformsDataName = (cell, row, rowIndex, formatExtraData) => {

      
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Name)} 
                {
                    this.state.formType == "Form-6" ?
                    (
                        <><h6 className='f-10 text-center mt-1 fw-normal'>Re Name - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.relativeName)}</span></h6>
                        <h6 className='f-10 text-center fw-normal'>Relation - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.relation)}</span></h6>
                        <h6 className='f-10 text-center fw-normal'>Mandal - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.cityMandal)}</span></h6>
                        <h6 className='f-10 text-center fw-normal'>Village - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.villageArea)}</span></h6></>
                        
                    )
                    :
                    (
                        this.state.formType == "Form-7" ?
                        (
                            <><h6 className='f-10 text-center mt-1 fw-normal' >Booth - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.booth)}</span></h6>
                            <h6 className='f-10 text-center fw-normal' >Voter SerialNo - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.voterSerialNo)}</span></h6>
                            <h6 className='f-10 text-center fw-normal'>Voter&nbsp;Name - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.voterName)}</span></h6>
                            <h6 className='f-10 text-center fw-normal' >objector&nbsp;Name - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.objectorName)}</span></h6></>
                        )
                        :
                        (
                            this.state.formType == "Form-8" ?
                            (
                                <><h6 className='f-10 text-center mt-1 fw-normal'>Connection&nbsp;Entry - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.correctionEntry)}</span></h6>
                                <h6 className='f-10 text-center fw-normal'>epicReplacement - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.epicReplacement)}</span></h6>
                                <h6 className='f-10 text-center  fw-normal'>PwdMarking - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.pwdMarking)}</span></h6></>
                            )
                            :
                            (
                                this.state.formType == "Form-8A" || this.state.formType == "Form-8B" ?
                                (
                                    <><h6 className='f-10 text-center fw-normal'>Mandal - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.cityMandal)}</span></h6>
                                    <h6 className='f-10 text-center fw-normal'>Village - <span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>{(row.villageArea)}</span></h6>
                                    </>
                                )
                                :
                                ("")
                            )
                        )
                       
                    )
                }
                
                <>
                    <h6 className='f-12 text-center' >
                    Party - {(row.partyId) != '' ? <b className='color_green'>{party_ids_name[row.partyId]}</b> : <b className='color_red'>Not Updated</b>},
                </h6>
                </>
            </span>
         );
        
    }

    actionsFormatterForformsUpdate = (cell, row, rowIndex, formatExtraData) => {
      
        console.log("row.isValid",row.isValid)
        return (
            <>
            {
                row.isValid == '' ?
                (<span className='cursor_pointer fw-bold color_red' style={{textAlign:"left",marginLeft:"0px"}} onClick={() => this.updateFormsDataStatusModal(row.formsDataId,row.partyId,row.isValid)}><i class="fa-solid fa-pen-to-square"></i></span>)
                :
                (
                    row.isValid == 'N' ?
                    (
                        <span className='cursor_pointer fw-bold color_red' style={{textAlign:"left",marginLeft:"0px"}}>Not Valid <br/><i class="fa-solid fa-pen-to-square" onClick={() => this.updateFormsDataStatusModal(row.formsDataId,row.partyId,row.isValid)}></i></span>
                    )
                    :
                    (
                        <span className='fw-bold color_green' style={{textAlign:"left",marginLeft:"0px"}}>Valid</span>
                    )
                )
            }
            
            </>
              
         );
    }

    formsDataDisplayData = (formsDataSearchArr) => {
        let votersData = [];
        if(formsDataSearchArr.length>0){
            
            for (let i = 0; i < formsDataSearchArr.length; i++) {

                const Serial_No = (formsDataSearchArr[i].pdfSerialNo);
                const Date = (formsDataSearchArr[i].receiptDate);
                const Name = (formsDataSearchArr[i].applicantName);
                const formsDataId = (formsDataSearchArr[i].formsDataId);
                const relativeName = (formsDataSearchArr[i].relativeName);
                const relation = (formsDataSearchArr[i].relation);
                const villageArea = (formsDataSearchArr[i].villageArea);
                const cityMandal = (formsDataSearchArr[i].cityMandal);
                const partyId = (formsDataSearchArr[i].partyId);
                const isValid = (formsDataSearchArr[i].isValid);
                const booth = (formsDataSearchArr[i].booth);
                const voterSerialNo = (formsDataSearchArr[i].voterSerialNo);
                const voterName = (formsDataSearchArr[i].voterName);
                const objectorName = (formsDataSearchArr[i].objectorName);
                const correctionEntry = (formsDataSearchArr[i].correctionEntry);
                const epicReplacement = (formsDataSearchArr[i].epicReplacement);
                const pwdMarking = (formsDataSearchArr[i].pwdMarking);
                
                const obj = {
                    //Serial_No:(Serial_No),
                    Date: Date,
                    Name: Name,
                    formsDataId: formsDataId,
                    relativeName:relativeName,
                    relation:relation,
                    villageArea:villageArea,
                    cityMandal:cityMandal,
                    partyId:partyId,
                    isValid:isValid,
                    booth:booth,
                    voterSerialNo:voterSerialNo,
                    voterName:voterName,
                    objectorName:objectorName,
                    correctionEntry:correctionEntry,
                    epicReplacement:epicReplacement,
                    pwdMarking:pwdMarking,
                    Actions:"Update"
                }
                votersData.push(obj);
            } 

            
            
            return votersData;
        }
    }

    updateFormsDataStatusModal = (formsDataId,partyIdValue,isValidStatus) => {
        console.log("formsDataId",partyIdValue,isValidStatus);
        
        if(partyIdValue !=0){
            let partyIdName = this.state.party_ids_Arr.find((a) => a.value == partyIdValue);
                this.setState({
                    partyIdStrValue: {
                    value: partyIdName.value,
                    label: partyIdName.label
                },partyIdStr:partyIdValue
            });
        }
        if(isValidStatus !=""){
            let IdName = this.state.is_valid_Arr.find((a) => a.value == isValidStatus);
                this.setState({
                    is_valid_value: {
                    value: IdName.value,
                    label: IdName.label
                },is_valid:isValidStatus
            });
        }

        this.setState({UpdateVoterFormModal:true,selectedVoterUpdate:formsDataId});

    }
      
    updateVoterFormsFields = () => {
        this.setState({validatepartyIdStr:"",validateis_valid:"" });
        if(this.state.partyIdStr == "" || this.state.partyIdStr == 0){
            this.setState({validatepartyIdStr:"Please Select Party" });
            return;
        }
        if(this.state.is_valid == "" || this.state.is_valid == 0){
            this.setState({validateis_valid:"Please Select Status" });
            return;
        }

        console.log("formsDataId",this.state.selectedVoterUpdate)

        this.setState({updateVoterFormsLoadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "formsDataId" : this.state.selectedVoterUpdate,
            "isValid" : this.state.is_valid,
            "partyId" : this.state.partyIdStr,
           

            }); 

            getAPICallData(obj,"updateFormsDataStatus")
            .then((res) => res.json())
            .then((data) => {
              this.setState({updateVoterFormsLoadingSymbol:"hide"});  
                if(data !=null && data.status == "Success"){
                    this.setState({updateVoterFormsMsg:"Updated SuccessFully..."}); 

                    this.change_status_voter_forms(this.state.formsDataArr)   

                    setTimeout(() => { 
                        this.setState({UpdateVoterFormModal:false});
                        this.setState({partyIdStr:"",partyIdStrValue:"",
                        is_valid:"",is_valid_value:"",updateVoterFormsMsg:""});
                    }, 1000);
                }else{
                    this.setState({updateVoterFormsMsg:"Some Thing Wrong,Please Check once"});  
                   
                }
          });
            
    }

    change_status_voter_forms = (_data) => {
        for(var key in _data){
            if(_data[key].formsDataId == this.state.selectedVoterUpdate){
                if(this.state.partyIdStr != ""){
                    _data[key].partyId = this.state.partyIdStr
                }else{
                    _data[key].partyId = 0
                }

                if(this.state.is_valid == "Y"){
                    _data[key].isValid = 'Y'
                }else{
                    _data[key].isValid = 'N'
                }

               


            }
        }
        console.log("_data",_data) ;
        this.setState({formsDataArr:_data});
    }


    render() {

        let formsDataSearchArr = [];
        
        if (this.state.formType == "Form-7") {
            let keyword = this.state.searchNameValue.toString()
            if (keyword !== ''){
                formsDataSearchArr = this.state.formsDataArr.filter(  (item) => {
                    if (item.voterName.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.objectorName.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.booth.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.voterSerialNo.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                } );
            }else{
                formsDataSearchArr = this.state.formsDataArr;
                
            }
            
            
        }else if (( this.state.formType == "Form-6") || (this.state.formType == "Form-8A") || ( this.state.formType == "Form-8B")) {
            let keyword = this.state.searchNameValue.toString();
            if (keyword !== ''){
                formsDataSearchArr = this.state.formsDataArr.filter(  (item) => {
                    if (item.applicantName.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.villageArea.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.cityMandal.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.relativeName.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                } );
            }else{
                formsDataSearchArr = this.state.formsDataArr;
            }
            
        }else if ((this.state.formType == "Form-8") ) {
            let keyword = this.state.searchNameValue.toString();
            if (keyword !== ''){
                formsDataSearchArr = this.state.formsDataArr.filter(  (item) => {
                    if (item.applicantName.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                } );
        }else{
            formsDataSearchArr = this.state.formsDataArr;
        }
            
        }

        console.log("formsDataSearchArr",formsDataSearchArr)

        return (
               <Container>
                    <Row>
                        <Col>
                            <h6 className='text-center fw-bold ln-28 f-14'>Applicants / Objecters Details : <br/>{this.state.constituencyValue.label}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr className='mb-0 mt-0'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row} className="mt-4 mb-0" controlId="formTypeId">
                                <Form.Label column className="form-label f-14 fw-bold w_2">
                                    Form Type : <span className='color_red f-14 ms-1'>*</span> 
                                </Form.Label>
                                <Col className='w_8'>
                                    <SelectBox
                                        stateData={this.state.formTypeValue}
                                        handleChange={this.handleOnchangeSelectBox}
                                        name="formType"
                                        options={this.state.formTypeListArr}
                                        placeholderName={'Select Form'}
                                        dropdownIndicator={{ DropdownIndicator }}
                                    />
                                    <span className='color_red f-12'>{this.state.validateformType}</span>
                                </Col>
                            </Form.Group>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <h6 className="form-label f-12 fw-bold">Form Date : <span className='color_red f-9'>{this.state.validatefromDate}</span></h6>
                        <DateRangePicker
                                singleDatePicker={true}
                                alwaysShowCalendars={true}
                                onEvent={this.handleEventFormData}
                                className="w-100"
                            >   
                            <div class="input-group">
                                    <div class="input-group-prepend bgColorYash">
                                     <i class="fa fa-calendar pad_5_15"></i>
                                    </div>
                                    <input type="text" className="form-control f-12" value={moment(this.state.fromDate).format("YYYY-MM-DD")}/>
                                </div>
                            </DateRangePicker>
                        </Col>
                        <Col>
                        <h6 className="form-label f-12 fw-bold">To Date : <span className='color_red f-9'>{this.state.validatetoDate}</span></h6>
                        <DateRangePicker
                                singleDatePicker={true}
                                alwaysShowCalendars={true}
                                onEvent={this.handleEventToDate}
                                className="w-100"
                            >   
                            <div class="input-group">
                                    <div class="input-group-prepend bgColorYash">
                                     <i class="fa fa-calendar pad_5_15"></i>
                                    </div>
                                    <input type="text" className="form-control f-12" value={moment(this.state.toDate).format("YYYY-MM-DD")}/>
                                </div>
                            </DateRangePicker>
                        </Col>
                    </Row>
                    {
                        this.state.formType == "Form-6" || this.state.formType == "Form-8A" ||  this.state.formType == "Form-8B" ?
                        (
                            <Row>
                                <Col className='mt-4'>
                                    <h6 className="form-label f-12 fw-bold">Mandal/Town : 
                                        {
                                            this.state.mandalLodaingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                        }
                                    </h6>
                                        <SelectBox
                                            stateData={this.state.mandalValue}
                                            handleChange={this.handleOnchangeSelectBox}
                                            name="mandal"
                                            options={this.state.mandalListArr}
                                            placeholderName={'Mandal'}
                                            dropdownIndicator={{ DropdownIndicator }}
                                        />
                                </Col>
                                <Col className='mt-4'>
                                    <h6 className="form-label f-12 fw-bold">Village/Ward: 
                                        {
                                            this.state.villageLodaingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                        }
                                    </h6>
                                        <SelectBox
                                            stateData={this.state.villageValue}
                                            handleChange={this.handleOnchangeSelectBox}
                                            name="village"
                                            options={this.state.villageListArr}
                                            placeholderName={'Village'}
                                            dropdownIndicator={{ DropdownIndicator }}
                                        />
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                    
                    <Row>
                        <Col>
                            <Form.Group className="mt-2" controlId="formNameId">
                                <Form.Label className='f-12 fw-bold'>Voter Name </Form.Label>
                                <Form.Control type="text" name="voterSearchNameValue" className='form-control-md'  placeholder="Enter Voter Name" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mt-2" controlId="formNameId">
                                <Form.Label className='f-12 fw-bold'>objector Name </Form.Label>
                                <Form.Control type="text" name="objectorNameValue" className='form-control-md'  placeholder="Enter objector Name" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                        <Form>
                            <div className='text-center'>
                            <Form.Check
                                inline
                                label={`All`}
                                name="voterFormVerifiedStatus"
                                type="radio"
                                id={`allVerifiedStatusId`}
                                onChange={(e)=>this.checkVerifiedStatus(e,"All")}
                                className="f-14 fw-bold color_black"
                                value="All"
                                defaultChecked={true}
                            />
                            <Form.Check
                                inline
                                label={`Valid`}
                                name="voterFormVerifiedStatus"
                                type="radio"
                                id={`allVerifiedStatusValidId`}
                                onChange={(e)=>this.checkVerifiedStatus(e,"Y")}
                                className="f-14 fw-bold color_black"
                                value="Y"
                            />
                            <Form.Check
                                inline
                                label={`InValid`}
                                name="voterFormVerifiedStatus"
                                type="radio"
                                id={`allVerifiedStatusInavalidId`}
                                onChange={(e)=>this.checkVerifiedStatus(e,"N")}
                                className="f-14 fw-bold color_black"
                                value="N"
                            />
                            <Form.Check
                                inline
                                label={`Pending`}
                                name="voterFormVerifiedStatus"
                                type="radio"
                                id={`allVerifiedStatusPendingId`}
                                onChange={(e)=>this.checkVerifiedStatus(e,"P")}
                                className="f-14 fw-bold color_black"
                                value="P"
                            />
                            </div>
                        </Form>   
                        </Col>
                            </Row>
                    <Row>
                        <Col>
                            <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getFormsDataForVerfication()}>GET FORMS DATA</Button> 
                        </Col>
                    </Row>
                    {
                        this.state.formsDataLoadingSymbol == "show" ?
                        (
                            <Row>
                                <Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.firstTimeVotersSerach == 1 ?
                        (
                            <Row>
                                <Col sm={12}>
                            
                                <div className='bg_light_blue'>
                                    <Row>
                                        <Col sm={6}>
                                            <input
                                                className='float-end search_bar mt-2'
                                                type="search"
                                                placeholder='Search'
                                                value={this.state.searchNameValue}
                                                onChange={this.handleSearchNameChange}
                                            />
                                            <i className='fa fa-search search-icon'></i>
                                        </Col>
                                    </Row>
                                    {
                                        formsDataSearchArr.length>0 ? 
                                        (
                                            this.state.formType == "Form-7" ?
                                            (
                                                <CardLessDataTableWithOutSearch 
                                                    parentData={this.formsDataDisplayData(formsDataSearchArr)}
                                                    particlecolumns={this.formsDataDisplayColumnsForm7()}
                                                    defaultSorted={[{
                                                        dataField: "Booth",
                                                        order: "asc"
                                                    }]}
                                                    tableHeading={<><h6 className='f-14'><b>Applicants Forms Data :</b> <span className=''>Total Count : <b>{this.state.formsDataArr.length}</b></span></h6></>}
                                                    />
                                            )
                                            :
                                            (
                                                <CardLessDataTableWithOutSearch 
                                                    parentData={this.formsDataDisplayData(formsDataSearchArr)}
                                                    particlecolumns={this.formsDataDisplayColumns()}
                                                    defaultSorted={[{
                                                        dataField: "Date",
                                                        order: "asc"
                                                    }]}
                                                    tableHeading={<><h6 className='f-14'><b>Applicants Forms Data :</b> <span className=''>Total Count : <b>{this.state.formsDataArr.length}</b></span></h6></>}
                                                    />
                                            )
                                            
                                        )
                                        :
                                        (
                                            <h6 className='f-14 text-center mt-4'>No Data Availble...</h6>
                                        )
                                    }

                                </div>
                            </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                    
                    <UpdateVoterForms 
                        show={this.state.UpdateVoterFormModal}
                        onHide={() => this.setState({ UpdateVoterFormModal: false })}
                        stateData={this.state}
                        phandleOnchangeSelectBox={this.handleOnchangeSelectBox}
                        pupdateVoterFormsFields={this.updateVoterFormsFields}
                />
               </Container>
               
        )
    }
  }
  
  export default withRouter(VotersFormsVerification);
  