
import React ,{ Component } from 'react';
import {Container, Row, Col,Button } from 'react-bootstrap';
import "./Dashboard.css";
import { withRouter,Link } from "react-router-dom";


class ViewDescription extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
         
          
        }
    }
  
    componentDidMount() {
      
       
        
    }

    


    render() {
        return (
            <section>
                
                <Container fluid>
                    <Row>
                        <Col>
                           <Link to={{pathname: "/dashboard"}}> 
                             <Button variant="outline-dark ms-3 pad5_14 float-end" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                           </Link>
                        </Col>
                    </Row>
                
                    <Row>
                        <Col>
                            <h6 className='text-center fw-bold ln_32 color_red mt-2'>తెలుగు దేశం పార్టీ ప్రధాన కార్యదర్శి మీ నారా లోకేశ్‌ సందేశం</h6>
                        </Col>
                    </Row>
                     <hr className='mb-0 mt-1'/>
                    <Row>
                        <Col>
                            <p className='ln_32 f-14 text-center mt-2'>
                            <h6 className='f-14 fw-bold mt-3 mb-2'>🙏🙏Nara Lokesh🙏🙏</h6>
                            బాధతో బరువెక్కిన హృదయంతో, కన్నీళ్లతో తడిసిన కళ్లతో ఈరోజు మీకు రాస్తున్నాను. ఆంధ్ర ప్రదేశ్ మరియు తెలుగు ప్రజల అభ్యున్నతి కోసం మా నాన్న తన హృదయాన్ని మరియు ఆత్మను ధారపోస్తూ నేను పెరిగాను. లక్షలాది జీవితాలను మార్చడానికి అవిశ్రాంతంగా ప్రయత్నిస్తున్న అతనికి విశ్రాంతి రోజు తెలియదు. అతని రాజకీయాలు ఎల్లప్పుడూ గౌరవం మరియు నిజాయితీతో గుర్తించబడ్డాయి మరియు అతను సేవ చేసిన వారి ప్రేమ మరియు కృతజ్ఞత నుండి అతను పొందిన లోతైన ప్రేరణను నేను చూశాను. వారి హృదయపూర్వక కృతజ్ఞతలు అతనిని స్వచ్ఛమైన ఆనందంతో నింపింది, ఇది పిల్లల ఆనందానికి సమానం.

                            నేను కూడా అతని గొప్ప మార్గం నుండి ప్రేరణ పొందాను మరియు అతని అడుగుజాడలను అనుసరించాను, అమెరికాలో సౌకర్యవంతమైన ఉద్యోగాన్ని వదిలి భారతదేశానికి తిరిగి వచ్చాను. ఇది చాలా కఠినమైన నిర్ణయం, కానీ నాకు మన దేశం, మన వ్యవస్థలు, మన పునాది సూత్రాలు మరియు అన్నింటికంటే మించి మన రాజ్యాంగంపై నమ్మకం ఉంది.

                             అయినప్పటికీ, ఈ రోజు, మా నాన్న ఎప్పుడూ చేయని నేరానికి అన్యాయంగా రిమాండ్‌కు వెళ్లడం చూస్తుంటే, నా కోపం ఉప్పొంగింది మరియు నా రక్తం ఉడికిపోతుంది. రాజకీయ పగ ముంచే లోతులకు హద్దులు లేవా? తన దేశం కోసం, రాష్ట్రం కోసం, తెలుగు ప్రజల కోసం ఇంతటి ఘనకార్యం చేసిన నాన్నగారి స్థాయి వ్యక్తి ఇంత అన్యాయాన్ని ఎందుకు భరించాలి? ఎందుకంటే అతను ఎప్పుడూ పగ లేదా విధ్వంసక రాజకీయాలకు దిగలేదు? అతను ఇతరుల కంటే చాలా కాలం ముందు మన ప్రజలకు అభివృద్ధి, సంక్షేమం మరియు అవకాశాలను ఊహించినందుకా?

                           ఈరోజు నమ్మకద్రోహంలా అనిపిస్తుంది. కానీ, మా నాన్న పోరాట యోధుడు, నేనూ అలాగే.. ఆంధ్ర ప్రదేశ్ మరియు ప్రపంచవ్యాప్తంగా ఉన్న తెలుగు ప్రజల కోసం అచంచలమైన సంకల్పంతో మార్గనిర్దేశం చేస్తూ తిరుగులేని శక్తితో ఎదుగుతాం. ఈ యుద్ధంలో నాతో కలిసి రావాలని నేను మిమ్మల్ని అడుగుతున్నాను.

                            <br/>🙏🙏Nara Lokesh🙏🙏
                            </p>
                        </Col>
                    </Row>

                </Container> 
                
            </section>
        )
    }
  }
  
  export default withRouter(ViewDescription);
  