
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button  } from 'react-bootstrap';
import "./EventsAndActivities.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import ShowAllPhotos from './ShowAllPhotos';

class EventPhotosSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ShowAllPhotosModal:false
        }
    }

    
    showAllPhotosBlock = () => {
        this.setState({ShowAllPhotosModal:true});
    }

  
    render() {
        return (
            <>
            <Row>
                <Col sm={12} className="">
                    <h6 className='f-14  fw-bold mb-0'>PROGRAM PHOTOS {this.props.stateData.eventPhotosList.length == 0 ? "" : <b className='event_headings_color'> : {this.props.stateData.eventPhotosList.length}</b>}</h6>
                        {
                            this.props.stateData.eventPhotosList.length == 0 ?
                            (
                                this.props.stateData.eventPhotosCheck == 1 ?
                                    (<h6 className='f-14 text-center mt-2'>Photos Not Available...</h6>)
                                    :
                                    (
                                    <Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>
                                    )
                                
                            )
                            :
                            (
                                this.props.stateData.eventPhotosList.length>0 ?
                                (

                                    <Col sm={12}>
                                        <div className='photos_gallery_flex'>
                                            {
                                                this.props.stateData.eventPhotosList.slice(0, 3).map((item,index) => {
                                                return(
                                                        <div onClick={() => this.showAllPhotosBlock()} className="cursor_pointer"><Image src={item.filePath} alt={item.fileName} className="width_height_photo"></Image></div>
                                                )
                                                })
                                                
                                            }
                                        </div>
                                        {
                                            this.props.stateData.eventPhotosList.length>3 ?
                                            (<div className='f-14 fw-bold cursor_pointer float-end ' onClick={() => this.showAllPhotosBlock()}>View More...</div>)
                                            :
                                            ("")
                                        }
                                    </Col>

                                )
                                :
                                ("")
                            )
                        }

    
                </Col>
            </Row>
            {
                this.props.stateData.eventPhotosList.length>0 ?
                (
                    <ShowAllPhotos 
                        show={this.state.ShowAllPhotosModal}
                        onHide={() => this.setState({ ShowAllPhotosModal: false })}
                        getAllPhotosList={this.props.stateData.eventPhotosList}
                        modalTitle="Event"
                    />
                )
                :
                ("")
            }
            
            </>
        )
    }
  }
  
  export default withRouter(EventPhotosSection);
  