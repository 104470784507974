import React, { Component } from 'react'
import { Modal, Row,Col,Button } from "react-bootstrap";
import "./BoothAndHouseMapping.css";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class AddNewHouseModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
      
    }

    
    render() {
        return (
            <Modal {...this.props} 
                size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                    <Modal.Header closeButton className='p-3'>
                        <Modal.Title className='f-14 fw-bold'>Add New House</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                           <Row>
                               
                                <Col className='' sm={12}>
                                    <label className="form-label f-14 fw-bold">Booth <span className='color_red f-9'>{this.props.stateData.validateBoothForHouse}</span></label>
                                    <SelectBox
                                            stateData={this.props.stateData.boothIdForHouseCreateValue}
                                            handleChange={this.props.phandleOnchangeSelectBox}
                                            name="boothIdForHouseCreate"
                                            options={this.props.stateData.boothIdsList}
                                            placeholderName={'Select Booth'}
                                            dropdownIndicator={{ DropdownIndicator }}
                                        />
                                    
                                </Col>
                                <Col sm={12} className='mt-2'>
                                    <label className="form-label form-label-block f-14 fw-bold">Create New House: <span className='color_red f-9'>{this.props.stateData.validateForNewHouse}</span></label>
                                    <input type="text" className='form-control'name="addHouseNo"  placeholder='Enter House No' value={this.props.stateData.addHouseNo} onChange={(e) => this.props.phandleOnchangeInputBox(e)}></input> 
                                </Col>
                                
                                <Col sm={12} className='mt-2'>
                                    <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-1' onClick={() => this.props.psaveNewHouse()}>Save</Button>
                                </Col>
                                {
                                    this.props.stateData.newHouseSuccessMsg != "" ?
                                    (
                                        <Col sm={12} className='mt-2'>
                                            <h6 className='text-center fw-bold'>{this.props.stateData.newHouseSuccessMsg}</h6>
                                        </Col>
                                    )
                                    :
                                    (
                                       ""
                                    )
                                }
                            </Row>  
                    </Modal.Body>
                </Modal>
            
        )
    }
}
export default AddNewHouseModal;
  

