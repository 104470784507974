
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import { getAPICallData } from '../../services/MemberService';
import "./VotersListRevision.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class VotersListRevision extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            boothIdsList:[],
            boothId:"0",
            boothNo:"",
            boothIdValue:"",

            loadingSymbol:"hide",
            successMsg:"",

            verifiedHouses:"",
            verifiedVoters:"",
            form6Collected:"",
            form7Collected:"",
            form8Collected:"",
            fromDate:moment().format("YYYY-MM-DD"),

            validateboothId:"",
            validateVerifyHouse:"",
            validateForm7collected:"",
            validateForm6collected:"",
            validateVerifyVoters:"",
            validateForm8Collected:""
           
        }
        
    }

    componentDidMount() {
        let userAccessList = this.state.userAccessList;
        if(userAccessList != null && userAccessList.status == "Success" ){
            if(userAccessList.boothList != null && userAccessList.boothList.length>0){
                let boothListArr=[];
                userAccessList.boothList.map(item => {
                    const obj = {
                        value: item.id,
                        label: "Booth No -"+item.name,
                        attr_mapped:item.isMapped
                    }
                    boothListArr.push(obj);
                    
                });
                this.setState({ boothIdsList: boothListArr });
                
                
            }
        }else{
            this.props.history.push({pathname:"/dashboard"});
            
        }
    }

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        
      }

      handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "boothId"){
          let getBoothIdName = this.state.boothIdsList.find((a) => a.value == value);
            this.setState({
              boothIdValue: {
                  value: getBoothIdName.value,
                  label: getBoothIdName.label
              }
          });
    
          this.setState({ boothId: getBoothIdName.value,boothNo:"Booth "+getBoothIdName.label});
        
        }
      }

      handleEvent = (event, picker) => {
        console.log("mmmmmmmmm",moment(picker.startDate).format("YYYY-MM-DD"))
        this.setState({ fromDate: moment(picker.startDate).format("YYYY-MM-DD")});
        
      }

    

      saveVLRForm = () => {
        this.setState({validateVerifyHouse:"",validateForm7collected:"",validateForm6collected:"",
                        validateVerifyVoters:"",validateboothId:"",validateForm8collected:""
        });
      
        if(this.state.boothId == "" || this.state.boothId == 0){
            this.setState({validateboothId:"Please Select Booth" });
            return;
        }
        if(this.state.verifiedHouses == "" ){
            this.setState({validateVerifyHouse:"Please Enter Verify Houses" });
            return;
        }

        if(this.state.verifiedHouses != ""){
            if(this.state.verifiedHouses > 500){
                this.setState({validateVerifyHouse:"Verified Houses should not more than 500" });
                return;
            }
        }

        if(this.state.verifiedVoters == "" ){
            this.setState({validateVerifyVoters:"Please Enter Verify VOters" });
            return;
        }

        if(this.state.verifiedVoters != ""){
            if(this.state.verifiedVoters > 1900){
                this.setState({validateVerifyVoters:"Verified Houses should not more than 1900" });
                return;
            }
        }

        if(this.state.form6Collected == "" ){
            this.setState({validateForm6collected:"Please Enter Form 6 Collected" });
            return;
        }

        if(this.state.form6Collected != ""){
            if(this.state.form6Collected > 99){
                this.setState({validateForm6collected:"Form 6 should not more than 99" });
                return;
            }
        }

        if(this.state.form7Collected == "" ){
            this.setState({validateForm7collected:"Please Enter Form 7 Collected" });
            return;
        }

        if(this.state.form7Collected != ""){
            if(this.state.form7Collected > 99){
                this.setState({validateForm7collected:"Form 7 should not more than 99" });
                return;
            }
        }

        if(this.state.form8Collected == ""){
            this.setState({validateForm8collected:"Please Enter Form 8 Collected" });
            return;
        }

        if(this.state.form8Collected != ""){
            if(this.state.form8Collected > 99){
                this.setState({validateForm8collected:"Form 8 should not more than 99" });
                return;
            }
        }

        this.setState({loadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "boothId" : this.state.boothId,
            "date":this.state.fromDate,
            "verifiedHouses" : this.state.verifiedHouses,
            "verifiedVoters" : this.state.verifiedVoters,
            "form6Collected" : this.state.form6Collected,
            "form7Collected" : this.state.form7Collected,
            "form8Collected":this.state.form8Collected

            }); 

            getAPICallData(obj,"updateBoothVerificationStatus")
            .then((res) => res.json())
            .then((data) => {
              this.setState({loadingSymbol:"hide"});  
                if(data !=null && data.status == "Success"){
                    this.setState({successMsg:"Saved SuccessFully..."}); 
                    setTimeout(() => { 
                        this.setState({  
                            verifiedHouses:"",
                            verifiedVoters:"",
                            form6Collected:"",
                            form7Collected:"",boothId:"0",
                            boothNo:"",
                            boothIdValue:"",form8Collected:"",fromDate:"",successMsg:""});     
                       
                    }, 1500);
                }else{
                    this.setState({successMsg:"Some Thing Wrong,Please Check once"});  
                   
                }
          });
      }

      gotoHelpPageForEC = () => {
        this.props.history.push({
            pathname:"/help_page_form",
            params: {
                navBarBottom:"hide",
                userAccessList:this.state.userAccessList
            }
        });
      }
    
  
    render() {

        return (
            <section>
               <Container className=''>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'>EC House to House Voters - Revision - Status Update</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className='float-right text-decoration-underline cursor_pointer' onClick={() => this.gotoHelpPageForEC()}>Help</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="form-label f-12 fw-bold">Booth<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-12'>{this.state.validateboothId}</span></h6>
                            <SelectBox
                                stateData={this.state.boothIdValue}
                                handleChange={this.handleOnchangeSelectBoxNew}
                                name="boothId"
                                options={this.state.boothIdsList}
                                placeholderName={'Select Booth'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                        </Col>
                    </Row>
                    <Form>
                    <Row>
                        <Col>
                        <h6 className="form-label f-12 fw-bold mt-3">Date <span className='color_red f-9'>{this.state.validateDate}</span></h6>
                        <DateRangePicker
                                singleDatePicker={true}
                                alwaysShowCalendars={true}
                                onEvent={this.handleEvent}
                                className="w-100"
                            >   
                            <div class="input-group">
                                    <div class="input-group-prepend bgColorYash">
                                     <i class="fa fa-calendar pad_5_15"></i>
                                    </div>
                                    <input type="text" className="form-control" value={moment(this.state.fromDate).format("YYYY-MM-DD")}/>
                                </div>
                            </DateRangePicker>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                        <h6 className="form-label f-12 fw-bold">Verified Houses <span className='color_red f-9'>{this.state.validateVerifyHouse}</span></h6>
                        <Form.Control type="text" name="verifiedHouses" value={this.state.verifiedHouses}   className='form-control-sm'  placeholder="Enter Verified Houses" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    <Row>
                        <Col className='mt-3'>
                        <h6 className="form-label f-12 fw-bold">Verified Voters <span className='color_red f-9'>{this.state.validateVerifyVoters}</span></h6>
                        <Form.Control type="text" name="verifiedVoters"className='form-control-sm' value={this.state.verifiedVoters}    placeholder="Enter Verified Voters"  onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    <Row>
                        <Col className='mt-3'>
                        <h6 className="form-label f-12 fw-bold">Form 6 Collected <span className='color_red f-9'>{this.state.validateForm6collected}</span></h6>
                        <Form.Control type="text" name="form6Collected"  value={this.state.form6Collected}  className='form-control-sm'  placeholder="Enter Form 6" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    <Row>
                        <Col className='mt-3'>
                        <h6 className="form-label f-12 fw-bold">Form 7 Collected <span className='color_red f-9'>{this.state.validateForm7collected}</span></h6>
                        <Form.Control type="text" name="form7Collected" value={this.state.form7Collected}  className='form-control-sm'   placeholder="Enter Form 7"   onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    <Row>
                        <Col className='mt-3'>
                        <h6 className="form-label f-12 fw-bold">Form 8 Collected <span className='color_red f-9'>{this.state.validateForm8Collected}</span></h6>
                        <Form.Control type="text" name="form8Collected" value={this.state.form8Collected}  className='form-control-sm'   placeholder="Enter Form 8"   onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    </Form>

                      <Row>
                            <Col>
                                <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-3 mb-3' onClick={() => this.saveVLRForm()}>SUBMIT</Button>
                                    {
                                        this.state.loadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                    }
                            </Col>
                      </Row>
                      <Row>
                            <Col>
                                <h6 className='f-14 fw-bold text-center mt-2'>{this.state.successMsg}</h6>
                            </Col>
                      </Row>
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(VotersListRevision);
  