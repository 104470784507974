
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,Card,InputGroup,Collapse  } from 'react-bootstrap';
import "./cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import MobileNoOTPValidation from './MobileNoOTPValidation';


//const fetch = require('node-fetch');

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class DonationAmount extends Component {
    constructor(props) {
        super(props)
        

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
        }
    }

    componentDidMount() {
        
       
    }
    render() {
        
       
        return (
           <>
           {
            this.props.stateData.donationFirstPage == true ?
            (
                <>
                <Row>
                        <Col>
                            <div className='bg_white_shadow_border mt-3'>
                                <h6 className='text-center'>{this.props.stateData.englishSelected== true ? "Do You Have MemberShip Id?" : "మీకు సభ్యత్వం ఉందా ?"}</h6>
                                <Row >
                                    <Col className='text-center mt-2'>
                                        <Button variant="outline-success" className={this.props.stateData.haveMemberShipIdCheck == true ? "f-14 fw-bold active_checked" : "f-14 fw-bold"} onClick={() => this.props.haveMemberShipIdClick("yes")}> {this.props.stateData.englishSelected== true ? "YES" : "వుంది" } <i class="fa-solid fa-circle-check m_left_8"></i></Button>
                                        <Button variant="outline-success" className={this.props.stateData.noMemberShipIdCheck == true ? "f-14 mr_10 fw-bold active_checked" : "f-14 mr_10 fw-bold"} onClick={() => this.props.haveMemberShipIdClick("no")}> {this.props.stateData.englishSelected== true ? "NO" : "లేదు" } <i class="fa-solid fa-circle-xmark m_left_8"></i></Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {
                        this.props.stateData.haveMemberShipIdCheck == true ?
                        (
                            
                            <fieldset class=""><legend>{this.props.stateData.englishSelected== true ? "Membership Is Available" : "సభ్యత్వం వుంది :"}  </legend> 
                            <Row >
                                <Col >
                                    <div className='mt-2'>
                                        <Col sm={12} className='mt-2'>
                                            <label className="form-label form-label-block f-12 fw-bold">{this.props.stateData.englishSelected== true ? "MEMBERSHIP ID" : "సభ్యత్వ నెంబర్"} </label>
                                            <input type="text" className='form-control' name="searchRenewalMembershipId" placeholder='Enter MEMBERSHIP ID' maxLength={8} value={this.props.stateData.searchRenewalMembershipId} onChange={(e) => this.props.handleOnchangeInputBox(e)}></input>
                                        </Col>
                                        <Col sm={12} className='mt-2'>
                                            <label className="form-label form-label-block f-12 fw-bold">(OR) {this.props.stateData.englishSelected== true ? "MOBILE NO" : "మొబైల్ నెంబర్"} </label>
                                            <input type="text" className='form-control' name="searchRenewalMobileNo" placeholder='Enter MOBILE NO'maxLength={10} value={this.props.stateData.searchRenewalMobileNo} onChange={(e) => this.props.handleOnchangeInputBox(e)}></input>
                                        </Col>
                                        <Col sm={12} className='mt-2'>
                                            <label className="form-label form-label-block f-12 fw-bold">(OR) {this.props.stateData.englishSelected== true ? "VOTER NO" : "ఓటరు నెంబర్"} </label>
                                            <input type="text" className='form-control' name="searchRenewalVoterId" placeholder='Enter VOTER ID' value={this.props.stateData.searchRenewalVoterId} onChange={(e) => this.props.handleOnchangeInputBox(e)}></input>
                                        </Col>
                                        <p className='mt-1 f-12 error_cls_new'>{this.props.stateData.userDetailsError}</p>
                                        <Button variant="success" className='f-12 fw-bold center-auto mt-2' onClick={(e) => this.props.submitUserIds(e)} >{this.props.stateData.englishSelected== true ? "SUBMIT" : "SUBMIT"} </Button>

                                    </div>
                                </Col>
                            </Row>
                            
                                {
                                    this.props.stateData.renewalDetailsLoading == "show" ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    ("")
                                }
                                {
                                    this.props.stateData.renewalDetailsArr.length > 0 ?
                                    (
                                        
                                        <>
                                        
                                            {
                                                this.props.stateData.renewalDetailsArr.map((item,index) => {
                                                    return(
                                                        <Row>
                                                            <Col>
                                                                <div className="border_yash_pad mt-2">
                                                                    <h6 className='f-12'>{this.props.stateData.englishSelected== true ? "Name" : "పేరు"} : {item.cname}</h6>
                                                                    <h6 className='f-12'>{this.props.stateData.englishSelected== true ? "Membership No" : "సభ్యత్వం నెంబర్"}  : {item.mid}</h6>
                                                                    <h6 className='f-12 mt-2'>{this.props.stateData.englishSelected== true ? "Voter No" : "ఓటరు నెంబర్"} : {item.vno}</h6>
                                                                    <h6 className='f-12 mt-2'>{this.props.stateData.englishSelected== true ? "Relative Name " : "బంధువు పేరు"}  : {item.relativeName} |  {this.props.stateData.englishSelected== true ? "Relation" : "సంబంధం"}  : {item.relation}</h6>
                                                                    <h6 className='f-12 mt-2'>{this.props.stateData.englishSelected== true ? "H.NO " : "ఇంటి నెంబర్"} :{item.houseNo}  |  {this.props.stateData.englishSelected== true ? "Gender " : "లింగం"} : {item.gender}  |  {this.props.stateData.englishSelected== true ? "Age " : "వయస్సు"} :{item.age}</h6>

                                                                    <Row>
                                                                        <Col>
                                                                            <h6 className='f-14 fw-bold float-end text-decoration-underline color_red cursor_pointer' onClick={() => this.props.donationSecondPageView()}>Use This Member To Donation Now</h6>
                                                                        </Col>
                                                                    </Row>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )
                                                    
                                                })
                                            }
                                            
                                        </>
                                    )
                                    :
                                    (<>
                                        {
                                        this.props.stateData.renewalDetailsDataCheck == 1 ?
                                        (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                        :
                                        ("")
                                    }
                                    </>)
                                }
                                </fieldset>
                                        
                                
                        )
                        :
                        (
                            
                            this.props.stateData.noMemberShipIdCheck == true ?
                            (
                                <fieldset class=""><legend>{this.props.stateData.englishSelected== true ? "Membership Is Not Available" : "సభ్యత్వం లేదు :"}  </legend> 
                                    <Row>
                                        <Col className='mt-2'>
                                            <h6 className="form-label f-14 fw-bold"> {this.props.stateData.englishSelected== true ? "Name" : "పేరు"} <span className='color_red f-14 ms-1'>*</span></h6>
                                            <Form.Control type="text" name="donationName"  className='form-control-sm'  placeholder="Enter Name" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                        </Col>
                                    </Row>

                                    

                                    <Row>
                                        <Col className='mt-2'>
                                            <h6 className="form-label f-14 fw-bold"> {this.props.stateData.englishSelected== true ? "Gender" : "లింగం"} <span className='color_red f-14 ms-1'>*</span></h6>
                                            <SelectBox
                                                stateData={this.props.stateData.donationGenderValue}
                                                handleChange={this.props.handleOnchangeSelectBoxNew}
                                                name="donationGender"
                                                options={this.props.stateData.donationGenderArr}
                                                placeholderName={'Gender'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />

                                        
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='mt-2'>
                                            <h6 className="form-label f-14 fw-bold"> {this.props.stateData.englishSelected== true ? "Age" : "వయస్సు"} <span className='color_red f-14 ms-1'>*</span></h6>
                                            <Form.Control type="text" name="donationAge"  className='form-control-sm'  placeholder="Enter Age" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='mt-2'>
                                        <label className="form-label f-12 fw-bold">{this.props.stateData.englishSelected== true ? "Constituency" : "నియోజకవర్గం"} <span className='color_red f-14 ms-1'>*</span></label>
                                        <SelectBox
                                            stateData={this.props.stateData.constituencyValue}
                                            handleChange={this.props.handleOnchangeSelectBoxNew}
                                            name="constituencyName"
                                            options={this.props.stateData.ap_listArr}
                                            placeholderName={'Select Constituency'}
                                            dropdownIndicator={{ DropdownIndicator }}
                                        /> 

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <h6 className='f-14 fw-bold mt-3 float-end text-decoration-underline color_red cursor_pointer' onClick={() => this.props.donationSecondPageView()}>Continue <i class="fa-solid fa-arrow-right"></i></h6>
                                        </Col>
                                    </Row>
                                    </fieldset>
                            )
                            :
                            ("")
                            
                            
                        )
                    }
                </>
            )
            :
            (
                this.props.stateData.donationSecondPage == true ? 
                (
                    <>

                    <Row>
                        <Col>
                            <Button variant="outline-dark pad5_14" className='f-12 fw-bold float-end mt-2' onClick={() => this.props.donationPreviousPage()}> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Col>
                    </Row>

                    <MobileNoOTPValidation 
                        stateData={this.props.stateData}
                        handleOnchangeInputBox={this.handleOnchangeInputBox}
                        getMobileNoOTP={this.props.getMobileNoOTP}
                        resendOTPNew={this.props.resendOTPNew}
                    />
                    


                    <fieldset class=""><legend>{this.props.stateData.englishSelected== true ? "Payment" : "చెల్లింపు మొత్తం"}</legend>  

                        <h6 className='mt-3 f-14 ln-25'>{this.props.stateData.englishSelected== true ? "You Can Specify The Amount You Wish To Donate :" : "మీరు విరాళం (డొనేషన్) చేయదలచుకున్న మొత్తం ని తెలియజేయగలరు ?"}</h6>

                            <Row>
                                <Col>
                                    <div className="mt-1">
                                        <Form.Check
                                                inline
                                                label={"100"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationOneId`}
                                                className="f-14 fw-bold color_black cursor_pointer"
                                                value="100"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"100")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"1000"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationtwoId`}
                                                className="f-14 fw-bold color_black cursor_pointer"
                                                value="1000"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"1000")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"5000"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationThreeId`}
                                                className="f-14 fw-bold color_black cursor_pointer"
                                                value="5000"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"5000")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"100000"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationFourId`}
                                                className="f-14 fw-bold color_black cursor_pointer"
                                                value="100000"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"100000")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"500000"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationFiveId`}
                                                className="f-14 fw-bold color_black cursor_pointer"
                                                value="500000"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"500000")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"Other Amount"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationSixId`}
                                                className="f-14 fw-bold color_black cursor_pointer"
                                                value="other_amount"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"other_amount")}
                                        ></Form.Check>
                                    
                                    </div>
                                </Col>
                            </Row>

                            {
                                this.props.stateData.otherDonationAmount == true ?
                                (
                                    <Row>
                                        <Col className='mt-2'>
                                            <h6 className="form-label f-14 fw-bold"> {this.props.stateData.englishSelected== true ? "Enter Amount" : "Enter Amount"} <span className='color_red f-14 ms-1'>*</span></h6>
                                            <Form.Control type="text" name="donationAmountValue" value={this.props.stateData.donationAmountValue}  className='form-control-sm'  placeholder="Enter Amount" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }

                        <Row>
                            <Col sm={12}>
                            
                                <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.props.stateData.paynowDonationButtonDisabled} onClick={() => this.props.paymentSuccessMessage()}>PAY NOW </Button>
                                {
                                    this.props.stateData.paynowDonationSuccessMessage == "Success" ?
                                    (
                                        <>
                                            <h6 className='text-center f-14 color_green mt-3 fw-bold'>Amount : {this.props.stateData.donationAmountValue}</h6>
                                            <h6 className='text-center f-14 color_green mt-2 fw-bold'>Thanks For Donating In Telegu Desam Party</h6>
                                        </>
                                    )
                                    :
                                    (
                                    ""
                                        
                                    )
                                }

                                
                                
                            </Col>
                        </Row>
                                        
                        </fieldset>


                    

                    </>

                )
                :
                ("")
            )
            }
           </>
        )
    }
  }
  
  export default withRouter(DonationAmount);
  