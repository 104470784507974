
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import "./EventsAndActivities.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import arrowLeft from '../../images/arrow-left.png';
import users from '../../images/users.png';
import image from '../../images/image.png';
import file_image from '../../images/file-image.png';
import file_pdf from '../../images/file-pdf.png';
import loader from '../../images/loader.gif';
import { saveAs } from "file-saver";
import axios from 'axios'

let pushQuestionDataArr=[];
class QuestionerFormSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            event:props?.location?.params?.event == undefined ? "" : props?.location?.params?.event,
            eventStatus:props?.location?.params?.eventStatus == undefined ? "" : props?.location?.params?.eventStatus,
            campaignDetailsId:props?.location?.params?.campaignDetailsId == undefined ? "" : props?.location?.params?.campaignDetailsId,
            eventType:props?.location?.params?.eventType == undefined ? "" : props?.location?.params?.eventType,
            isAttended:props?.location?.params?.isAttended == undefined ? "" : props?.location?.params?.isAttended,
            surveyType:props?.location?.params?.surveyType == undefined ? "" : props?.location?.params?.surveyType,
            pollManagementPage:props?.location?.params?.pollManagementPage == undefined ? "" : props?.location?.params?.pollManagementPage,
            MLCPage:props?.location?.params?.MLCPage == undefined ? "" : props?.location?.params?.MLCPage,
            questionnaireData:[],
            questionnaireDataCheck:0,
            numberValue:'',
            selectedQuestionId:0,
            textValue:"",
            questionData:[],
            successMsg:"",
            loaderSymbol:"hide",
           
            
        }
    }

    componentDidMount() {
        pushQuestionDataArr=[];
        this.getCampaignQuestionnaire();

    }

    getCampaignQuestionnaire = () => {
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignId":this.state.event.campaignId,
                
            });
             

        getCampaignAPICallData(userAccessObj,"getCampaignQuestionnaire")
            .then((res) => res.json())
            .then((data) => { 
                if(data !=null && data.length>0){
                    this.setState({questionnaireData:data,questionnaireDataCheck:0});
                }else{
                    this.setState({questionnaireData:[],questionnaireDataCheck:1});
                }
             });
                
           
    }

    validateNumber = (e,questionId) => {
        this.setState({selectedQuestionId: questionId});

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
           this.setState({numberValue: e.target.value})
        }
     }

     validateText = (e,questionId) => {
        this.setState({selectedQuestionId: questionId});
        this.setState({textValue: e.target.value})
     }

     selectedOptions = (e,optionType,questionId,optionId) => {
        
            if(optionType == "singleOption" || optionType == "multiOption"){
                if(e?.target?.checked == true){
                    var obj={
                        "campaignDetailsId" : this.state.campaignDetailsId,
                        "questionId" : questionId,
                        "optionId" : optionId,
                        "answerText" : null,
                        "answerNumber" : null,
                        "answerPercentage" : null
                    }
                    pushQuestionDataArr.push(obj);
                }else{
                    for( var i in pushQuestionDataArr){ 
                        if ( pushQuestionDataArr[i].questionId == questionId) { 
                            pushQuestionDataArr.splice(i, 1);
                        }
                    
                    }
                } 
            }else if(optionType == "text"){
                this.setState({selectedQuestionId: questionId});
                this.setState({textValue: e.target.value})
                var obj={
                    "campaignDetailsId" : this.state.campaignDetailsId,
                    "questionId" : questionId,
                    "optionId" : null,
                    "answerText" : e.target.value,
                    "answerNumber" :null,
                    "answerPercentage" : null
                }
                pushQuestionDataArr.push(obj);

            }else if(optionType == "number"){
                    this.setState({selectedQuestionId: questionId});
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        this.setState({numberValue: e.target.value});
                        var obj={
                            "campaignDetailsId" : this.state.campaignDetailsId,
                            "questionId" : questionId,
                            "optionId" : null,
                            "answerText" : null,
                            "answerNumber" : e.target.value,
                            "answerPercentage" : null
                        }
                        pushQuestionDataArr.push(obj);
                    } 
                }

                setTimeout(() => {
                    this.setState({questionData: pushQuestionDataArr})
                }, 1500);

            }
            
        saveQuestionnaire = () => {
            this.setState({ loaderSymbol: "show" });
            console.log("questionData",this.state.questionData)
            const userAccessObj =  
                JSON.stringify(this.state.questionData);
                

            getCampaignAPICallData(userAccessObj,"saveCampaignSurveyAnswer")
                .then((res) => res.json())
                .then((data) => { 
                    this.setState({ loaderSymbol: "hide" });
                    if(data !=null && data.status == "Success"){
                        this.setState({successMsg:"Questionnaire Saved SuccessFully..."});
                        setTimeout(() => {
                            this.setState({successMsg:""});
                            this.props.history.push({
                              pathname: "/ongoing_ready_event",
                              params: {
                                  navbarDisplay:"hide",
                                  event:this.props?.location?.params?.event == undefined ? "" : this.props?.location?.params?.event,
                                  eventStatus:this.props?.location?.params?.eventStatus == undefined ? "" : this.props?.location?.params?.eventStatus,
                                  campaignDetailsId:this.props?.location?.params?.campaignDetailsId == undefined ? "" : this.props?.location?.params?.campaignDetailsId,
                                  eventType:this.props?.location?.params?.eventType == undefined ? "" : this.props?.location?.params?.eventType,
                                  isAttended:this.props?.location?.params?.isAttended == undefined ? "" : this.props?.location?.params?.isAttended,
                                  surveyType:this.props?.location?.params?.surveyType == undefined ? "" : this.props?.location?.params?.surveyType,
                        
                                },
                            });
                        }, 2000);
                    }else{
                        this.setState({successMsg:"Something Wrong,Please Try Again..."});
                    }
                });
        }
        

    render() {
        return (
            <section >
                <div className='bg_red_event'>
                    <div className='flex_event_name_top'>
                         <div>
                         <Link to={{pathname: "/events",params: {navbarDisplay:"hide"}}}> <Image src={arrowLeft} alt="Arrow" ></Image></Link>
                      
                    
                         </div>  
                         <div>
                            <h6 className='color_white fw-bold mt-3'>Today Party Event & Activities</h6>
                         </div> 
                    </div>
                    <div className='white_block_with_left_border mt-2'>
                        <h6 className='f-14 fw-bold'>{this.state.event.campaignName} <span className='float-end'><Image src={users} alt="users" ></Image> {this.state.campaignAttendedCount}</span></h6>
                        <div className='flex_PA mt-2'>
                            <div>
                                <h6 className='f-12'>Location</h6>
                                <h6 className='f-12'>{this.state.event.level} - {this.state.event.location}</h6>
                            </div>
                            
                        </div>
                        <div className='mt-1'>
                            <h6 className='f-12'>Date</h6>
                            <h6 className='f-12'>{this.state.event.fromDate} To {this.state.event.todate}</h6>
                        </div>
                    
                    </div>
                </div>
                <Container>
                    <Row>
                        <Col sm={12} className="mt-4">
                            <h6 className='f-14 fw-bold'>QUESTIONNAIRE FORM : </h6>
                            {
                                this.state.questionnaireData.length == 0?
                                (
                                    this.state.questionnaireDataCheck == 1 ?
                                    (<Col className='f-14 text-center'>Questionnaire Not Available in this campaign...</Col>)
                                    :
                                    (
                                        <Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>
                                    )
                                )
                                :
                                (
                                    <>
                                    <ul class="list-group mt-3 questionsListCls pad515 list-inline">
                                        {
                                            this.state.questionnaireData.map((item,index) => {
                                                return(
                                                    <li class="list-group-item f-14">
                                                        <b class="font14">Q-{index+1}.</b>  {item.question}
                                                        <Row>
                                                            <Col>
                                                                <div className='flex-box-options'>
                                                                    <h6 className={item.answerTypeId==1 || item.answerTypeId==2 ? "f-12 questionTypePos" : "f-12 mt-3"} >{item.answerType} Type : </h6>
                                                                        {
                                                                            item.answerTypeId == 1 ?
                                                                            (
                                                                                <Form className="mt-3 optionTypeCls">
                                                                                    {
                                                                                        item.optionsList.map((options,index1)=> {
                                                                                            return(
                                                                                                <Form.Check
                                                                                                    inline
                                                                                                    label={options.name}
                                                                                                    name="single_options"
                                                                                                    type="radio"
                                                                                                    id={`radioButtons-${index1}`}
                                                                                                    className="f-14"
                                                                                                    onChange={(e) => this.selectedOptions(e,"singleOption",item.questionId,options.id)}
                                                                                                />
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form>
                                                                            )
                                                                            :
                                                                            (
                                                                                item.answerTypeId == 2 ?
                                                                                (
                                                                                    <Form className="mt-3 optionTypeCls">
                                                                                        {
                                                                                            item.optionsList.map((options,index1)=> {
                                                                                                return(
                                                                                                    <Form.Check
                                                                                                        inline
                                                                                                        label={options.name}
                                                                                                        name="multi_options"
                                                                                                        type="checkbox"
                                                                                                        id={`checkBoxButtons-${index1}`}
                                                                                                        className="f-14"
                                                                                                        onChange={(e) => this.selectedOptions(e,"multiOption",item.questionId,options.id)}
                                                                                                    />
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Form>
                                                                                )
                                                                                :
                                                                                (
                                                                                    item.answerTypeId == 3 ?
                                                                                    (
                                                                                        <>
                                                                                            {
                                                                                                item.questionId == this.state.selectedQuestionId ? 
                                                                                                (<input type="text" className='form-control mt-3' name="Text"  placeholder='Enter Text' value={this.state.textValue} onChange={(e) => this.selectedOptions(e,"text",item.questionId,0)}></input> )
                                                                                                :
                                                                                                (<input type="text" className='form-control mt-3' name="Text"  placeholder='Enter Text' onChange={(e) => this.selectedOptions(e,"text",item.questionId,0)}></input> )
                                                                                            }
                                                                                        
                                                                                            </>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        item.answerTypeId == 4 ?
                                                                                        (

                                                                                            <>
                                                                                            {
                                                                                                item.questionId == this.state.selectedQuestionId ? 
                                                                                                (<input type="text" className='form-control mt-3' name="number"  placeholder='Enter Number' value={this.state.numberValue} onChange={(e) => this.selectedOptions(e,"number",item.questionId,0)}></input> )
                                                                                                :
                                                                                                (<input type="text" className='form-control mt-3' name="number"  placeholder='Enter Number' onChange={(e) => this.selectedOptions(e,"number",item.questionId,0)}></input> )
                                                                                            }
                                                                                        
                                                                                            </>
                                                                                            
                                                                                        )
                                                                                        :
                                                                                        ("")
                                                                                    )
                                                                                )
                                                                            )
                                                                        }
                                                                </div>
                                                                
                                                                
                                                               
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <Button variant="danger" className="red_button_event center-auto mt-4" onClick={() => this.saveQuestionnaire()}>SAVE </Button>
                                    {
                                        this.state.loaderSymbol == "show" ? 
                                        (<Image src={loader} alt="loader" className='loader_cls center-auto mt-2'></Image>)
                                        :
                                        ("")
                                    }
                                    <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.state.successMsg}</h6>
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(QuestionerFormSection);
  