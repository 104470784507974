import React, { Component } from 'react'
import { Container, Button,Row,Col,Card,Form,InputGroup,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import { withRouter,Link } from "react-router-dom";
import "./cadreRegistration.css";
import { getAPICallDataForCadreSaving } from '../../services/MemberService';
import QRCode from "react-qr-code";

class PaymentStatus extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            
            statusMsg:"",
            txnIdNumber:"",
            transactionMsg:"",
            paymentModuleIdVal:0,

            cardDisplayDataLoadingSymbol:"hide",
            cardDisplayDataObj:"",
            cardDisplayDataCheck:0,

        }
      
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        console.log("queryParams",queryParams)
        let status = queryParams.get('status');
        let txnId = queryParams.get('txnId');
        let msg = queryParams.get('msg');
        let mainNavBar = queryParams.get('mainNavBar');
        let cid = queryParams.get('cid');
        let paymentModuleId = queryParams.get('paymentModuleId');

        this.setState({ statusMsg:status,
            txnIdNumber:txnId,
            transactionMsg:msg,paymentModuleIdVal:paymentModuleId})

        this.getCitVlorCPDetails(cid);
    }


      getCitVlorCPDetails = (cid) => {
        this.setState({cardDisplayDataLoadingSymbol:"show",cardDisplayDataObj:"",cardDisplayDataCheck:0});

        const obj =  
            JSON.stringify({ 
                "tcid" : cid,
                
            });
    
            getAPICallDataForCadreSaving(obj,"getCitVlorCPDetails")
            .then((res) => res.json())
            .then((content) => {
                this.setState({cardDisplayDataLoadingSymbol:"hide"})
                if(content !=null){
                    this.setState({cardDisplayDataObj:content,cardDisplayDataCheck:0});
                }else{
                    this.setState({cardDisplayDataObj:"",cardDisplayDataCheck:1});
                }
            });
    }

    backToNewCadreEnrollPage = () => {
      window.location.href = 'https://ui.projectkv.com/cadre_registration?navbarDisplayPay=hide'
      /*this.props.history.push({
          pathname: "/cadre_registration",
          params: {
              navbarDisplay:"hide",
              //userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList
            },
        });*/
  }

  backToPendingRenewalPage = () => {
    window.location.href = 'https://ui.projectkv.com/pending_renewal_details?navbarDisplayPay=hide'
  }

  
  navigateToWhatsApp = (membershipId) => {
    // Construct the WhatsApp sharing URL
    const whatsappURL = `https://wa.me/919053419999?text=TDP_RI_${membershipId}`;

    // Open the WhatsApp sharing link in a new tab
    window.open(whatsappURL, '_blank');
    
  }

  navigateToWhatsApp2 = (membershipId) => {
    // Construct the WhatsApp sharing URL
    const whatsappURL = `https://wa.me/919134100009?text=TDP_RI_${membershipId}`;

    // Open the WhatsApp sharing link in a new tab
    window.open(whatsappURL, '_blank');
    
  }

  navigateToWhatsApp3 = (membershipId) => {
    // Construct the WhatsApp sharing URL
    const whatsappURL = `https://wa.me/?text=https://tdp1.in/membership2024.action?RI=${membershipId}`;

    // Open the WhatsApp sharing link in a new tab
    window.open(whatsappURL, '_blank');
    
  }
   
    render() {
        

        return (
            <section className="mb-4">
              <div className='p-1'>
                    <Container>
                        <Row>
                            <Col>
                                <div className="media">
                                    <Image variant="top" src={require('../../images/tdp_new.png')} className='mr-3' />
                                    <div className="media-body mt-4">
                                        <h6 className="color_red fw-bold">TELUGU DESAM PARTY</h6>
                                        <h6 className='fw-bold f-14'>Dare To Dream - Strive To Achieve</h6>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            <div className='bg_yellow_cadre'>  
              <Container>

                {
                    this.state.paymentModuleIdVal == "5" ?
                    (
                        this.state.statusMsg == "Success" ?
                        (
                          <>
                            <Row>
                                <Col sm={12}>
                                    <h6 className='text_center fw-bold f-14 mt-3'>Thanks For Donating In Telegu Desam Party</h6>
                                    <h6 className='text_center mt-1 color_green fw-bold f-14 mt-2'>Transaction Id : <span className='f-18'>{this.state.txnIdNumber}</span></h6>
                                </Col>
                            </Row>

                          <Row>                                               
                            <Col>
                                <Button variant="warning" className='mt-3 f-14 fw-bold color_white center-auto mt-3' onClick={() => this.backToNewCadreEnrollPage()}>Back To Home</Button>
                            </Col>
                          </Row>
                          </>
                        )
                        :
                        (
                            <>
                                <Row>
                                    <Col sm={12}>
                                        <h6 className='text_center fw-bold f-16 color_red mt-4'>{this.state.transactionMsg}</h6>
                                    </Col>
                                </Row>
        
                                <Row>                                               
                                    <Col>
                                        <Button variant="warning" className='mt-3 f-14 fw-bold color_white center-auto' onClick={() => this.backToNewCadreEnrollPage()}>Back To Home</Button>
                                    </Col>
                                </Row>
                                </>
                        )
                    )
                    :
                    (
                        <>
                        {
                            this.state.statusMsg == "Success" ?
                            (
                                <Row>
                                    <Col sm={12}>
                                        
                                        {
                                            this.state.cardDisplayDataLoadingSymbol == "show" ?
                                            (
                                                <Row>
                                                    <Col sm={12}>
                                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ("")
                                        }
                                        {
                                            this.state.cardDisplayDataObj != "" ?
                                            (
                                                
                                                <>
                                                    <h6 className='text_center fw-bold f-14 mt-4'>Your Application submitted Successfully</h6>
                                                    <h6 className='text_center mt-1 color_green fw-bold f-14 mt-2'>Transaction Id : <span className='f-18'>{this.state.txnIdNumber}</span></h6>
                                                    <h6 className='text_center mt-1 color_green fw-bold f-14 mt-2'>MemberShip No : <span className='f-18'>{this.state.cardDisplayDataObj.mid}</span></h6>
                                    
                                                    {/*<div className='htmlToImageVis' ref={this.myref}> 
                                                        <Card className="text-white border-0 mt-2">
                                                            <Card.Img src={require('../../images/front_mc.jpeg')} alt="Card image"  />
                                                               
                                                            <Card.ImgOverlay className=''>
                                                                <Row>
                                                                    <Col>
                                                                        <div className='qr_code_pos_card'>
                                                                            <QRCode
                                                                                size={60}
                                                                                viewBox={`0 0 200 200`}
                                                                                value={`${this.state.cardDisplayDataObj.mid}`}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <div className='flex-card'>
                                                                            <div>
                                                                                {
                                                                                    this.state.cardDisplayDataObj.imageUrl == "" || this.state.cardDisplayDataObj.imageUrl == undefined ?
                                                                                    (<Card.Img src={require('../../images/human.jpg')} alt="Card image" className="member_img"  />)
                                                                                    :
                                                                                    (<Card.Img src={this.state.cardDisplayDataObj.imageUrl} alt="Card image" className="member_img"  />)
                                                                                }
                                                                                
                                                                            </div>
                                                                            <div>
                                                                                <div className='bg_red_card'>
                                                                                    <Card.Title className='mb-1 fw-bold f-12'>{this.state.cardDisplayDataObj.mid}</Card.Title>
                                                                                    {
                                                                                        this.state.cardDisplayDataObj.membershipType == "General" ?
                                                                                        (<Card.Title className='mb-0  fw-bold f-12'>2024-26</Card.Title>)
                                                                                        :
                                                                                        ("")
                                                                                    }
                                                                                   
                                                                                </div>
                                                                                <div className='mt-1 ml-2'>
                                                                                    <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.cname}</Card.Title>
                                                                                    {
                                                                                        this.state.cardDisplayDataObj.villageName == "" && this.state.cardDisplayDataObj.mandalName == "" ?
                                                                                        (
                                                                                            <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.townName} - మున్సిపాలిటీ </Card.Title>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            this.state.cardDisplayDataObj.townName == ""  ?
                                                                                            (
                                                                                                <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.villageName} - గ్రామం , {this.state.cardDisplayDataObj.mandalName} - మండలం</Card.Title>
                                                                                            )
                                                                                            :
                                                                                            ("")
                                                                                        )
                                                                                    }
                                                                                    
                                                                                    <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.constituencyName} - నియోజకవర్గం , {this.state.cardDisplayDataObj.districtName} - జిల్లా </Card.Title>
                                                                                    <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.stateName}</Card.Title>
                                                                                </div>
                                                                            </div>
                                                                            
        
                                                                            
        
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            
                                                                
                                                                
                                                            </Card.ImgOverlay>
                                                        </Card>
        
                                                        <Card className="text-white mt-2">
                                                            <Card.Img src={require('../../images/back_mc.jpeg')} alt="Card image"  />
                                                        </Card>
        
        
                                                        </div>*/}

                                                        <div class="row justify-content-center mt-3">
                                                            <div class="col-sm-12">
                                                                    <div class="bg_yash_pad">
                                                                        <h6 className='title_css'><b>Refer A Friend / Family Member</b> -  Share your personalised referral link with friends and family. Every new member you bring in helps our Telugu Desam Party grow stronger together. As your network expands by adding more members with your unique link, so does your influence within our Telugu Desam Party.</h6>
                                                                            <div class="row">
                                                                                <div class="col-sm-12">
                                                                                    <h6 class="f-14 text-muted mt-4">share on </h6>
                                                                                    <div class="flex-align-share mt-2">
                                                                                        
                                                                                        <div >
                                                                                            <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" onClick={() => this.navigateToWhatsApp(this.state.referalMembershipId)}><i class="fa-brands fa-whatsapp color_red"></i> WhatsApp URL 1 </button>
                                                                                        </div>
                                                                                        <div >
                                                                                           <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" onClick={() => this.navigateToWhatsApp2(this.state.referalMembershipId)}><i class="fa-brands fa-whatsapp color_red" ></i> WhatsApp URL 2 </button>
                                                                                        </div>
                                                                                        <div >
                                                                                           <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" onClick={() => this.navigateToWhatsApp3(this.state.referalMembershipId)}><i class="fa-solid fa-globe color_red"></i> Web URL </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div class="row">
                                                                                <div class="col-sm-12 mt-3">
                                                                                    <h6 class="f-14 text-muted">Click To Below URLs To Copy </h6>
                                                                                    <div class="flex-align-share mt-2">
                                                                                        <div >
                                                                                            <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" onClick={() => {navigator.clipboard.writeText(`https://wa.me/919053419999?text=TDP_RI_${this.state.cardDisplayDataObj.mid}`)}}><i class="fa-solid fa-copy color_red"></i> WhatsApp URL 1 </button>
                                                                                        </div>
                                                                                        <div >
                                                                                            <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" onClick={() => {navigator.clipboard.writeText(`https://wa.me/919134100009?text=TDP_RI_${this.state.cardDisplayDataObj.mid}`)}}><i class="fa-solid fa-copy color_red"></i> WhatsApp URL 2 </button>
                                                                                        </div>
                                                                                        <div >
                                                                                            <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12"  onClick={() => {navigator.clipboard.writeText(`https://wa.me/?text=https://tdp1.in/membership2024.action?RI=${this.state.cardDisplayDataObj.mid}`)}}><i class="fa-solid fa-copy color_red"></i> Web URL </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                            </div> 

                                                            <div className='text-center'>
                                                                <Row>
                                                                    
                                                                    <Col>
                                                                        <Button variant="warning" className='mt-4 f-14 fw-bold color_white' onClick={() => this.backToNewCadreEnrollPage()}>Start New Enroll</Button>
                                                                    </Col>
                                                                </Row>

                                                                <Row>                                               
                                                                    <Col>
                                                                        <Button variant="warning" className='mt-3 f-14 fw-bold color_white center-auto' onClick={() => this.backToPendingRenewalPage()}>Start New Pending Renewal Enroll</Button>
                                                                    </Col>
                                                                </Row>
                                                                
                                                            </div>
                                                </>
                                            )
                                            :
                                            (<>
                                                {
                                                this.state.cardDisplayDataCheck == 1 ?
                                                (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                                :
                                                ("")
                                            }
                                            </>)
                                        }
                                    
                                    
                                    
                                        
                                    </Col>
                                </Row> 
                            )
                            :
                            (
                                <>
                                <Row>
                                    <Col sm={12}>
                                        <h6 className='text_center fw-bold f-16 color_red mt-4'>{this.state.transactionMsg}</h6>
                                    </Col>
                                </Row>
        
                                <Row>                                               
                                    <Col>
                                        <Button variant="warning" className='mt-3 f-14 fw-bold color_white center-auto' onClick={() => this.backToNewCadreEnrollPage()}>Back To Home</Button>
                                    </Col>
                                </Row>

                                <Row>                                               
                                    <Col>
                                        <Button variant="warning" className='mt-3 f-14 fw-bold color_white center-auto' onClick={() => this.backToPendingRenewalPage()}>Back To Pending Renewal Page</Button>
                                    </Col>
                                </Row>
                                
                                </>
                            )
                        }
                        </>
                    )
                }

                
              
              </Container>
              </div>
            </section>
                
            
        )
    }
}
export default withRouter(PaymentStatus);
  

