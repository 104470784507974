
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Button,Image,Card,Table,InputGroup} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif'; 
import './LeaderSearch.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { scroller } from "react-scroll";
import Correct_WrongPersonModal from './Correct_WrongPersonModal';
import { findAllByTestId } from '@testing-library/react';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService'

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };


class LeaderSearchSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
           mainLogoShow:true,
           typeOfLeaderValue:"partyCommittee",
           typeOfLeaderName:"Party Committee",
           typeOfLeaderBlock:true,

           leadersBlock:false,
           electionCommitteeBlock:false,

           committeeTypeBlock:false,
           committeeAffilatedNamesBlock:false,

            affilatedValue:"",
            affilatedName:"",
            af_listArr:[{'value':2,'label':'Telugu Yuvatha'},{'value':3,'label':'Telugu Mahila'},{'value':4,'label':'Telugu Rythu'}],

            committeeTypeValue:"1",

            committeeLevelBlock:false,

            committeeLevelValue:"",

            parliamentValue:"",
            parliamentName:"",
            pc_listArr:[],

            mandalValue:"",
            mandalName:"",
            mc_listArr:[],

            villageValue:"",
            villageName:"",
            vc_listArr:[],

            constituencyValue:"",
            constituencyName:"",
            ac_listArr:[],

            consLoadingSymbol:"hide",
            mandalLoadingSymbol:"hide",
            villageLoadingSymbol:"hide",

            committeeLevelId : 0,
            locationId : 0,

            mainPhotosWithNamesArr:[],
            photoWithNameLoadingSymbol:"hide",
            mainPhotosWithNamesCheck:0,
            photosShowBlock:false,
            next5RecordNamesArr:[],
            namesShowBlock:false,

            correctPersonNameWithImage:{},
            nameSelectedNameWithImage:{},
            photoSelectedTDPCadreId:"",
            nameSelectedTDPCadreId:"",
            rightCheckColor:"",

            saveLeaderSearchAnswerLoadingSymbol:"hide",
            saveLeaderSearchAnswerResonse:"",
            showCorrectPersonInModalBlock:false,

            checkCorrectPersonModal:false,

            electionCommitteeTypeValue:1
            
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
  
    componentDidMount() {
        AOS.init();
        this.getParliamentConstituencies();
        this.getAssemblyConstituenciesByParliamentId();
    }

    getParliamentConstituencies = () => {
        const obj =  
        JSON.stringify({ 
            "constituencyId" : 0,
        }); 

        getAPICallDataForOnlyBSA(obj,"getParliamentConstituencies")
        .then((res) => res.json())
        .then((data) => {
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ pc_listArr: parlArr });

          }else{
            this.setState({ pc_listArr:[]});
          }
        });
    }

    getAssemblyConstituenciesByParliamentId = () => {
        this.setState({consLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            
        }); 

        getAPICallDataForOnlyBSA(obj,"getAssemblyConstituencies")
        .then((res) => res.json())
        .then((data) => {
            this.setState({consLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ ac_listArr: parlArr });

          }else{
            this.setState({ ac_listArr:[]});
          }
        });
    }

    getMandalsAndTownsByConstituencyId = (locationId) => {
        this.setState({mandalLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            "locationId" : locationId,
        }); 

        getAPICallDataForOnlyBSA(obj,"getMandalsAndTownsByConstituencyId")
        .then((res) => res.json())
        .then((data) => {
            this.setState({mandalLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                    typeId:item.typeId
                }
                parlArr.push(obj);
                
            });
            this.setState({ mc_listArr: parlArr });

          }else{
            this.setState({ mc_listArr:[]});
          }
        });
    }

    getPanchayatsByTehsilId = (locationId) => {
        this.setState({villageLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            "locationId" : locationId,
        }); 

        getAPICallDataForOnlyBSA(obj,"getPanchayatsByTehsilId")
        .then((res) => res.json())
        .then((data) => {
            this.setState({villageLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ vc_listArr: parlArr });

          }else{
            this.setState({ vc_listArr:[]});
          }
        });
    }

    typeOfLeaderChange = (e,value,name) => {
        this.setState({typeOfLeaderValue:value,typeOfLeaderName:name})
    }

    nextSlideBlockLeaderType = () => {
        
        this.setState({mainLogoShow:false,typeOfLeaderBlock:false,committeeTypeBlock:true});

        if(this.state.typeOfLeaderValue == "partyCommittee"){
            this.setState({committeeTypeBlock:true,leadersBlock:false,electionCommitteeBlock:false});
        }else if(this.state.typeOfLeaderValue == "leader"){
            this.setState({committeeTypeBlock:false,leadersBlock:true,electionCommitteeBlock:false});
        }else if(this.state.typeOfLeaderValue == "election"){
            this.setState({committeeTypeBlock:false,leadersBlock:false,electionCommitteeBlock:true});

            this.setState({photosShowBlock:true});
            this.getElectionCommitteeMembersForLeaderSearch(1,"");

            
        }
    }
   
    committeeTypeBlockOnchange = (e,name,value) => {
        this.setState({committeeTypeValue:value});
    }

    nextSlideBlockCommitteeType = () => {
        this.setState({typeOfLeaderBlock:false,committeeTypeBlock:false,mainLogoShow:false})
        if(this.state.committeeTypeValue == "1"){
            this.setState({committeeLevelBlock:true})
        }else{
            this.setState({committeeAffilatedNamesBlock:true})
        }
        
    }
    previousSlideBlockTypeOfLeader = () => {
        this.setState({typeOfLeaderBlock:true,mainLogoShow:true});
        this.setState({committeeTypeBlock:false,committeeTypeValue:"1"});
       
    }

    previousSlideBlockCommitteeAff = () => {
        this.setState({committeeTypeBlock:true,committeeTypeValue:"1"});
        this.setState({committeeAffilatedNamesBlock:false})
    }

    nextSlideBlockCommitteeAffilatedType = () => {
        this.setState({committeeAffilatedNamesBlock:false})
        this.setState({committeeLevelBlock:true});
        this.setState({committeeTypeValue:"2"});
    }

    committeeLevelChange = (e,value) => {
        this.setState({photosShowBlock:false,parliamentValue:"",parliamentName:"",constituencyName:"",constituencyValue:"",mandalValue:"",mandalName:"",villageValue:"",villageName:""});
        
        this.setState({rightCheckColor:""});
        this.setState({namesShowBlock:false});
        this.setState({ mainPhotosWithNamesArr:[]});

        this.setState({committeeLevelValue:value});

        if(value == "state"){
            this.setState({ committeeLevelId : 10,  locationId : 2});
            this.setState({photosShowBlock:true});
            this.getPartyCommitteeMembersForLeaderSearch(10,2);
        }
    }

    previousSlideBlockCommitteeType = () => {
        
        this.setState({parliamentValue:"",parliamentName:"",constituencyName:"",constituencyValue:"",mandalValue:"",mandalName:"",villageValue:"",villageName:""});

        this.setState({committeeLevelBlock:false});
        if(this.state.committeeTypeValue == 1){
            this.setState({committeeTypeBlock:true,committeeTypeValue:"1"});
        }else{
            this.setState({committeeAffilatedNamesBlock:true,committeeTypeValue:"2"});
        }
        
        this.setState({committeeLevelValue:""});
        this.setState({photosShowBlock:false})
    }

    previousSlideBlockElectionType = () => {
        this.setState({rightCheckColor:""});
        this.setState({namesShowBlock:false});
        this.setState({ mainPhotosWithNamesArr:[]});

        this.setState({electionCommitteeTypeValue:1});
        this.setState({electionCommitteeBlock:false});
        this.setState({typeOfLeaderBlock:true,mainLogoShow:true,typeOfLeaderValue:"partyCommittee",
        typeOfLeaderName:"Party Committee"});

    }

    electionCommitteeTypeBlockOnchange = (e,name,value) => {
        this.setState({rightCheckColor:""});
        this.setState({namesShowBlock:false});
        this.setState({ mainPhotosWithNamesArr:[]});
        
        this.setState({electionCommitteeTypeValue:value});
        if(name == "constituency"){
            this.setState({photosShowBlock:true});
            this.getElectionCommitteeMembersForLeaderSearch(value,"");
        }
    }

    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;

        if(name == "parliamentName"){
            this.setState({constituencyName:"",constituencyValue:""});
            let getParIdName = this.state.pc_listArr.find((a) => a.value == value);
                this.setState({
                    parliamentValue: {
                    value: getParIdName.value,
                    label: getParIdName.label
                }
            });

            if(this.state.committeeLevelValue == "assembly"){
                this.getAssemblyConstituenciesByParliamentId();
            }else if(this.state.committeeLevelValue == "parliament"){
                this.setState({rightCheckColor:""});
                this.setState({namesShowBlock:false});
                this.setState({ mainPhotosWithNamesArr:[]});

                this.setState({ committeeLevelId : 13,  locationId : value});
                this.setState({photosShowBlock:true});
                this.getPartyCommitteeMembersForLeaderSearch(13,value);
            }
            

        }else if(name == "constituencyName"){
            this.setState({mandalValue:"",mandalName:"",villageValue:"",villageName:""});

            let getConsIdName = this.state.ac_listArr.find((a) => a.value == value);
                this.setState({
                    constituencyValue: {
                    value: getConsIdName.value,
                    label: getConsIdName.label
                }
            });
            if(this.state.committeeLevelValue == "assembly"){
                this.setState({rightCheckColor:""});
                this.setState({namesShowBlock:false});
                this.setState({ mainPhotosWithNamesArr:[]});

                this.setState({ committeeLevelId : 14,  locationId : value});
                this.setState({photosShowBlock:true});
                this.getPartyCommitteeMembersForLeaderSearch(14,value);
            }else if(this.state.committeeLevelValue == "mandal" || this.state.committeeLevelValue == "village"){
                this.getMandalsAndTownsByConstituencyId(value)
            }else if(this.state.electionCommitteeTypeValue == 2 || this.state.electionCommitteeTypeValue == 3 || this.state.electionCommitteeTypeValue == 4){
                this.getElectionCommitteeMembersForLeaderSearch(this.state.electionCommitteeTypeValue,value);
            }
            
           
        }else if(name == "affilatedName"){
            let getAffIdName = this.state.af_listArr.find((a) => a.value == value);
            this.setState({
                affilatedValue: {
                value: getAffIdName.value,
                label: getAffIdName.label
            }
        });
           
        }else if(name == "mandalName"){
            this.setState({villageValue:"",villageName:""});

            let getManIdName = this.state.mc_listArr.find((a) => a.value == value);
            console.log("getManIdName",getManIdName)
                this.setState({
                    mandalValue: {
                    value: getManIdName.value,
                    label: getManIdName.label,
                    typeId:getManIdName.typeId
                }
            });

            if(this.state.committeeLevelValue == "mandal"){
                this.setState({rightCheckColor:""});
                this.setState({namesShowBlock:false});
                this.setState({ mainPhotosWithNamesArr:[]});

                this.setState({ committeeLevelId : getManIdName.typeId,  locationId : value});
                this.setState({photosShowBlock:true});
                this.getPartyCommitteeMembersForLeaderSearch(getManIdName.typeId,value);
            }else if(this.state.committeeLevelValue == "village"){
                this.getPanchayatsByTehsilId(value)
            }

        }else if(name == "villageName"){
            let getVillIdName = this.state.vc_listArr.find((a) => a.value == value);
                this.setState({
                    villageValue: {
                    value: getVillIdName.value,
                    label: getVillIdName.label
                }
            });

            this.setState({rightCheckColor:""});
            this.setState({namesShowBlock:false});
            this.setState({ mainPhotosWithNamesArr:[]});

            this.setState({ committeeLevelId : 6,  locationId : value});
            this.setState({photosShowBlock:true});
            this.getPartyCommitteeMembersForLeaderSearch(6,value);
           
        }
          
    
        this.setState({ [name]: value});
      }

      getElectionCommitteeMembersForLeaderSearch = (roleId,constituencyId) => {
        this.setState({photoWithNameLoadingSymbol:"show"});

        
        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            "roleId" : roleId,
            "constituencyId" : constituencyId
        }); 

        getAPICallDataForOnlyBSA(obj,"getElectionCommitteeMembersForLeaderSearch")
        .then((res) => res.json())
        .then((data) => {
            this.setState({photoWithNameLoadingSymbol:"hide"});
            if(data !=null && data.length > 0){
                const dataArr = data.map((item,index) => {
                    return {...item,indexValue:index}
                });
                /*const newList = [...dataArr];
                newList.sort(() => Math.random() - 0.5);
                console.log("newList",newList);*/

                //Promise.all(data.map((item,index) =>  this.testImage(item.imageUrl))).then(console.log);
                
                this.setState({mainPhotosWithNamesArr:dataArr,mainPhotosWithNamesCheck:0});
            }else{
                this.setState({ mainPhotosWithNamesArr:[],mainPhotosWithNamesCheck:1});
            }
        });
    }



      getPartyCommitteeMembersForLeaderSearch = (committeeLevelId,locationId) => {
        this.setState({photoWithNameLoadingSymbol:"show"});

        var basicCommitteeId = "";

        if(this.state.committeeTypeValue == "1"){
            basicCommitteeId = 1;
        }else{
            basicCommitteeId = this.state.affilatedValue.value
        }

        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            "basicCommitteeId" : basicCommitteeId,
            "committeeLevelId" : committeeLevelId,
            "locationId" : locationId
        }); 

        getAPICallDataForOnlyBSA(obj,"getPartyCommitteeMembersForLeaderSearch")
        .then((res) => res.json())
        .then((data) => {
            this.setState({photoWithNameLoadingSymbol:"hide"});
            if(data !=null && data.length > 0){
                const dataArr = data.map((item,index) => {
                    return {...item,indexValue:index}
                });
                const newList = [...dataArr];
                newList.sort(() => Math.random() - 0.5);
                console.log("newList",newList);

                //Promise.all(data.map((item,index) =>  this.testImage(item.imageUrl))).then(console.log);
                
                this.setState({mainPhotosWithNamesArr:newList,mainPhotosWithNamesCheck:0});
            }else{
                this.setState({ mainPhotosWithNamesArr:[],mainPhotosWithNamesCheck:1});
            }
        });
    }

     /*testImage = (url) => {
        const img = new Image();
        img.src = url;
        return new Promise((resolve) => {
            img.onerror = () => resolve(false);
            img.onload = () => resolve(true);
        });
    }*/

    next() {
        this.setState({rightCheckColor:""});
        this.setState({namesShowBlock:false})
        this.slider.slickNext();
      }
      previous() {
        this.setState({rightCheckColor:""});
        this.setState({namesShowBlock:false})
        this.slider.slickPrev();
      }

    
    onError = (e,id) => {
       //document.getElementById(id).remove() 
        e.target.src = require("../../images/human.jpg");
    }

    getNext5Records = (startingId, dataArray) => {
        // Find the index of the object with the specified id
        const startIndex = dataArray.findIndex(item => item.indexValue === startingId);
      
        // If the startingId is not found or is the last element, return an empty array
        if (startIndex === -1 || startIndex === dataArray.length - 1) {
          return [];
        }
      
        // Extract the next 5 records
        const next5Records = dataArray.slice(startIndex + 0, startIndex + 6);
      
        return next5Records;
      }

    getImageCheckDetails = (data) => {
        scroller.scrollTo("scroll_top", { 
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });

        this.setState({rightCheckColor:""})
        this.setState({correctPersonNameWithImage:data})
        const arr = this.getNext5Records(data.indexValue, this.state.mainPhotosWithNamesArr);
        const sortDataArr = arr.sort((a, b) => b.tdpCadreId - a.tdpCadreId)
        this.setState({next5RecordNamesArr:sortDataArr});
        this.setState({namesShowBlock:true})
        this.setState({photoSelectedTDPCadreId:data.tdpCadreId});
    }
    getNameCheckDetails = (data) => {
        this.setState({rightCheckColor:""})
        this.setState({showCorrectPersonInModalBlock:false}); 
        this.setState({checkCorrectPersonModal:true});
        this.setState({nameSelectedTDPCadreId:data.tdpCadreId});
        this.setState({nameSelectedNameWithImage:data});
        this.saveLeaderSearchAnswer(data);
    }

    closePersonModal = () => {
        if(this.state.saveLeaderSearchAnswerResonse == "Success"){
            this.setState({rightCheckColor:"updated"})
        }
        this.setState({checkCorrectPersonModal:false});
    }

    selectCorrectName = () => {
        this.setState({rightCheckColor:""})
        this.setState({checkCorrectPersonModal:false});
    }

    showCorrectPersonInModal = () => {
        this.setState({showCorrectPersonInModalBlock:true})
    }

    saveLeaderSearchAnswer = (nameSelectData) => {
        this.setState({saveLeaderSearchAnswerLoadingSymbol:"show"});
        let obj="";

        if(this.state.photoSelectedTDPCadreId == nameSelectData.tdpCadreId){
            obj =  
                JSON.stringify({ 
                    chatID: window.User !=undefined ? window.User.chatID : "943827154",
                    "tdpCadreId" : nameSelectData.tdpCadreId,
                    "memberName" : this.state.correctPersonNameWithImage.memberName,
                    "designation" : this.state.correctPersonNameWithImage.designation,
                    "imagePath": this.state.correctPersonNameWithImage.imagePath,
                    "isCorrect" : "Y"
                });
        }else{
            obj =  
                JSON.stringify({ 
                    chatID: window.User !=undefined ? window.User.chatID : "943827154",
                    "tdpCadreId" : nameSelectData.tdpCadreId,
                    "memberName" : nameSelectData.memberName,
                    "designation" : nameSelectData.designation,
                    "imagePath": this.state.correctPersonNameWithImage.imagePath,
                    "isCorrect" : "N",
                    "correctTdpCadreId" : this.state.correctPersonNameWithImage.tdpCadreId,
                    "correctMemberName" : this.state.correctPersonNameWithImage.memberName,
                    "correctDesignation" : this.state.correctPersonNameWithImage.designation
                });
        }

        getAPICallDataForOnlyBSA(obj,"saveLeaderSearchAnswer")
        .then((res) => res.json())
        .then((data) => {
            this.setState({saveLeaderSearchAnswerLoadingSymbol:"hide"});
            if(data !=null && data.status == "Success"){
                this.setState({saveLeaderSearchAnswerResonse:"Success"})
            }else{
                this.setState({saveLeaderSearchAnswerResonse:"Some Thing Went Wrong...Please Try Again"})
            }
        });
    }

    gotoHomeDashBoard = () => {
        this.props.history.push({
            pathname: '/',
            
        }); 
    }

    gotoHome = () => {
        this.setState({
            mainLogoShow:true,
           typeOfLeaderValue:"partyCommittee",
           typeOfLeaderName:"Party Committee",
           typeOfLeaderBlock:true,

           leadersBlock:false,
           electionCommitteeBlock:false,

           committeeTypeBlock:false,
           committeeAffilatedNamesBlock:false,

            affilatedValue:"",
            affilatedName:"",

            committeeTypeValue:"1",

            committeeLevelBlock:false,

            committeeLevelValue:"",

            parliamentValue:"",
            parliamentName:"",

            mandalValue:"",
            mandalName:"",

            villageValue:"",
            villageName:"",

            constituencyValue:"",
            constituencyName:"",

            consLoadingSymbol:"hide",
            mandalLoadingSymbol:"hide",
            villageLoadingSymbol:"hide",

            committeeLevelId : 0,
            locationId : 0,

            mainPhotosWithNamesArr:[],
            photoWithNameLoadingSymbol:"hide",
            mainPhotosWithNamesCheck:0,
            photosShowBlock:false,
            next5RecordNamesArr:[],
            namesShowBlock:false,

            correctPersonNameWithImage:{},
            photoSelectedTDPCadreId:"",
            nameSelectedTDPCadreId:"",
            rightCheckColor:"",

            saveLeaderSearchAnswerLoadingSymbol:"hide",
            saveLeaderSearchAnswerResonse:"",
            showCorrectPersonInModalBlock:false,

            checkCorrectPersonModal:false,

            electionCommitteeTypeValue:1
        })
      }

      leadersDashboardPage = () => {
        this.props.history.push({
            pathname: `/leader_dashboard`,
            params: {
                navbarDisplay:"hide",
              }
        }); 
      }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            //slidesToShow: 3,
            //slidesToScroll: 3,
            rows: 2,
            slidesPerRow: 3,
            arrows:false,
            variableWidth: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  //slidesToShow: 3,
                  //slidesToScroll: 3,
                  dots: false,
                  infinite: false,
                  rows: 2,
                  slidesPerRow: 3,
                  arrows:false,
                  variableWidth: true,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  //slidesToShow: 3,
                  //slidesToScroll: 2,
                  initialSlide: 3,
                  dots: false,
                  infinite: false,
                  rows: 2,
                  slidesPerRow: 3,
                  arrows:false,
                  variableWidth: true,
                }
              },
              {
                breakpoint: 480,
                settings: {
                  //slidesToShow: 3,
                  //slidesToScroll: 3,
                  dots: false,
                  infinite: false,
                  rows: 2,
                  slidesPerRow: 3,
                  arrows:false,
                  variableWidth: true,
                }
              }
            ]
          };
        return (
            <section className='leader_bg_color'>
                <Container fluid className='red_bg_color'>
                    {
                        this.state.mainLogoShow == true ?
                        (
                            <Row>
                                <Col>
                                    <img src={require('../../images/leadership-icon.png')} alt="leader-ship" className='w_80 center-auto mt-2'></img>
                                    <h3 className='text-center color_white fw-bold font-style'>LEADERS SEARCH</h3>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <Row>
                                <Col className='mt-3'>
                                    <h3 className='text-center color_white fw-bold font-style'>LEADERS SEARCH</h3>
                                    <h6 className='text-center color_white fw-bold'>({this.state.typeOfLeaderName})</h6>
                                </Col>
                            </Row>
                        )
                    }

                    <Row className='justify-content-center'>
                        <Col sm={4}>
                                {
                                    this.state.mainLogoShow == true  ?
                                    (<Button variant="outline-light" className='f-12 mt-3 ml-2 fw-bold' onClick={() => this.gotoHomeDashBoard()}> Home <i class="fa-solid fa-house"></i> </Button>)
                                    :
                                    (<Button variant="outline-light" className='f-12 mt-3 ml-2 fw-bold' onClick={() => this.gotoHome()}> Home <i class="fa-solid fa-house"></i> </Button>)
                                }
                                
                                <Button variant="outline-light" className='f-12 float-end fw-bold mt-3' onClick={() => this.leadersDashboardPage()}><i class="fa-solid fa-gauge"></i> Dashboard</Button>
                                
                        </Col>
                    </Row>
                   
                    {
                        this.state.typeOfLeaderBlock == true ?
                        (
                            <>
                                <div data-aos="slide-right" data-aos-delay="100">
                                    <Row className='justify-content-center'>
                                        <Col sm={4}>
                                            <Card className='mt-2'>
                                                <Card.Body className='pad_20_40'>
                                                    <h6 className='text-center f-18 fw-bold'>Type Of Leader</h6>
                                                    <div className=" mt-3">
                                                        <Form.Check
                                                                inline
                                                                label={"Party Committee"}
                                                                name="typeofLeaderValue"
                                                                type="radio"
                                                                id={`typeofLeaderValueCM`}
                                                                className="input_style"
                                                                defaultChecked={true}
                                                                value="committeeMember"
                                                                onChange={(e)=>this.typeOfLeaderChange(e,"partyCommittee","Party Committee")}
                                                        ></Form.Check>
                                                        {/*<Form.Check
                                                                inline
                                                                label={"Leaders"}
                                                                name="typeofLeaderValue"
                                                                type="radio"
                                                                id={`typeofLeaderValueL`}
                                                                className="input_style mt-1"
                                                                value="leader"
                                                                onChange={(e)=>this.typeOfLeaderChange(e,"leader","Leader")}
                                                            ></Form.Check>*/}
                                                        <Form.Check
                                                                inline
                                                                label={"Election Committee"}
                                                                name="typeofLeaderValue"
                                                                type="radio"
                                                                id={`typeofLeaderValueE`}
                                                                className="input_style mt-1"
                                                                value="election"
                                                                onChange={(e)=>this.typeOfLeaderChange(e,"election","Election Committee")}
                                                        ></Form.Check>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                                
                                <Row className='justify-content-center'>
                                    <Col sm={4}>
                                        <Button variant="warning" className='fw-bold float-end mt-2 f-12' onClick={() => this.nextSlideBlockLeaderType()}>NEXT <i class="fa-solid fa-arrow-right"></i></Button>
                                    </Col>
                                </Row>
                                </>
                        )
                        :
                        ("")
                    }

                    {
                        this.state.leadersBlock == true ?
                        (
                            <div  data-aos="slide-left" data-aos-delay="100">
                                    <Row className='justify-content-center'>
                                            <Col sm={4}>
                                                <Card className='mt-2'>
                                                    <Card.Body className='pad_20_40'>
                                                        <h6 className='f-18 fw-bold'>AC Incharges / Observers</h6>
                                                        <Row>
                                                            <Col className='mt-1 mb-2'>
                                                                Coming Soon...
                                                             </Col>
                                                         </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                    </Row>
                            </div>
                        )
                        :
                        ("")
                    }

                    {
                        this.state.electionCommitteeBlock == true ?
                        (
                            <div  >
                                    <Row className='justify-content-center'>
                                            <Col sm={4}>
                                                <Card className='mt-2'>
                                                    <Card.Body className='pad_20_40'>
                                                        <Row>
                                                            <Col>
                                                                <h6 className='f-14 fw-bold text-center'>Election Committee Type <Button variant="outline-dark" className='f-12 float-right btn btn-sm' onClick={() => this.previousSlideBlockElectionType()}><i class="fa-solid fa-arrow-left"></i> BACK </Button></h6>
                                                            </Col>
                                                        </Row>
                                                        
                                                        <Row>
                                                            <Col className=''>
                                                                <Form.Check
                                                                        inline
                                                                        label={"Constituency Incharge"}
                                                                        name="electionCommitteeTypeName"
                                                                        type="radio"
                                                                        id={`constituencyInchargeId`}
                                                                        className="input_style"
                                                                        defaultChecked={true}
                                                                        value="constituency"
                                                                        onChange={(e)=>this.electionCommitteeTypeBlockOnchange(e,"constituency",1)}
                                                                ></Form.Check>
                                                                <Form.Check
                                                                        inline
                                                                        label={"Booth"}
                                                                        name="electionCommitteeTypeName"
                                                                        type="radio"
                                                                        id={`boothInchargeId`}
                                                                        className="input_style mt-1"
                                                                        value="booth"
                                                                        onChange={(e)=>this.electionCommitteeTypeBlockOnchange(e,"booth",4)}
                                                                ></Form.Check>
                                                                <Form.Check
                                                                        inline
                                                                        label={"Unit"}
                                                                        name="electionCommitteeTypeName"
                                                                        type="radio"
                                                                        id={`unitInchargeId`}
                                                                        className="input_style mt-1"
                                                                        value="unit"
                                                                        onChange={(e)=>this.electionCommitteeTypeBlockOnchange(e,"unit",3)}
                                                                ></Form.Check>
                                                                <Form.Check
                                                                        inline
                                                                        label={"Cluster"}
                                                                        name="electionCommitteeTypeName"
                                                                        type="radio"
                                                                        id={`clusterInchargeId`}
                                                                        className="input_style mt-1"
                                                                        value="cluster"
                                                                        onChange={(e)=>this.electionCommitteeTypeBlockOnchange(e,"cluster",2)}
                                                                ></Form.Check>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            this.state.electionCommitteeTypeValue != 1 ?
                                                            (
                                                                <div  className=''>
                                                                    <Row className='justify-content-center'>
                                                                        <Col sm={4}>
                                                                            <Row>
                                                                            
                                                                                <Col className='mt-1' >
                                                                                    <label className="form-label f-12 fw-bold">Constituency
                                                                                            {
                                                                                                this.state.consLoadingSymbol == "show" ?
                                                                                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                                                                :
                                                                                                ("")
                                                                                            }
                                                                                    </label>
                                                                                    <SelectBox
                                                                                            stateData={this.state.constituencyValue}
                                                                                            handleChange={this.handleOnchangeSelectBoxNew}
                                                                                            name="constituencyName"
                                                                                            options={this.state.ac_listArr}
                                                                                            placeholderName={'Constituency'}
                                                                                            dropdownIndicator={{ DropdownIndicator }}
                                                                                        />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        
                                                                        
                                                                    </Row>
                                                                </div>
                                                            )
                                                            :
                                                            ("")
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                    </Row>

                            </div>
                        )
                        :
                        ("")
                    }
                    

                        {
                            this.state.committeeTypeBlock == true ? 
                            (
                                <>
                                <div  data-aos="slide-left" data-aos-delay="100">
                                    <Row className='justify-content-center'>
                                            <Col sm={4}>
                                                <Card className='mt-2'>
                                                    <Card.Body className='pad_20_40'>
                                                        <h6 className='text-center f-18 fw-bold'>Committee Type</h6>
                                                        <Row>
                                                            <Col className=''>
                                                                <Form.Check
                                                                        inline
                                                                        label={"Main"}
                                                                        name="committeeType"
                                                                        type="radio"
                                                                        id={`committeeTypeMain`}
                                                                        className="input_style"
                                                                        defaultChecked={true}
                                                                        value="main"
                                                                        onChange={(e)=>this.committeeTypeBlockOnchange(e,"main",1)}
                                                                ></Form.Check>
                                                                <Form.Check
                                                                        inline
                                                                        label={"Affilated"}
                                                                        name="committeeType"
                                                                        type="radio"
                                                                        id={`committeeTypeAff`}
                                                                        className="input_style mt-1"
                                                                        value="affilated"
                                                                        onChange={(e)=>this.committeeTypeBlockOnchange(e,"affilated",2)}
                                                                ></Form.Check>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                </div>
                                <Row className='justify-content-center'>
                                    <Col sm={4}>
                                        <Button variant="warning" className='fw-bold float-end mt-2 f-12' onClick={() => this.nextSlideBlockCommitteeType()}>NEXT <i class="fa-solid fa-arrow-right"></i></Button>
                                        <Button variant="light" className='fw-bold float-end mt-2 ml-2 f-12' onClick={() => this.previousSlideBlockTypeOfLeader()}><i class="fa-solid fa-arrow-left"></i> BACK </Button>
                                    </Col>
                                </Row>
                                </>
                            )
                            :
                            ("")
                        }

{
                        this.state.committeeAffilatedNamesBlock == true ?
                        (
                            <>
                            <div data-aos="slide-left" data-aos-delay="100">
                                <Row className='justify-content-center'>
                                        <Col sm={4}>
                                            <Card className='mt-2'>
                                                <Card.Body className='pad_20_40'>
                                                    <Row>
                                                        <Col className='mt-3 mb-2'>
                                                            <label className="form-label f-12 fw-bold">Select Affilated Committees</label>
                                                            <SelectBox
                                                                    stateData={this.state.affilatedValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="affilatedName"
                                                                    options={this.state.af_listArr}
                                                                    placeholderName={'Affilated'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                </Row>
                            </div>
                            <Row className='justify-content-center'>
                            <Col sm={4}>
                                <Button variant="warning" className='fw-bold float-end mt-2 f-12' onClick={() => this.nextSlideBlockCommitteeAffilatedType()}>NEXT <i class="fa-solid fa-arrow-right"></i></Button>
                                <Button variant="light" className='fw-bold float-end mt-2 ml-2 f-12' onClick={() => this.previousSlideBlockCommitteeAff()}><i class="fa-solid fa-arrow-left"></i> BACK </Button>
                            </Col>
                        </Row>
                        </>
                        )
                        :
                        ("")
                    }

                    {
                        this.state.committeeLevelBlock == true ? 
                        (
                            <>
                             
                            <div  data-aos="slide-left" data-aos-delay="100">
                                <Row className='justify-content-center'>
                                        <Col sm={4}>
                                            <Card className='mt-2'>
                                                
                                                <Card.Body className='pad_20_13'>
                                                    <h6 className='f-18 fw-bold'>Committee Level: <Button variant="outline-dark" className='f-12 float-right btn btn-sm' onClick={() => this.previousSlideBlockCommitteeType()}><i class="fa-solid fa-arrow-left"></i> BACK </Button></h6>
                                                    <Row>
                                                        <Col className=''>
                                                            <Form.Check
                                                                    inline
                                                                    label={"State"}
                                                                    name="committeeLevel"
                                                                    type="radio"
                                                                    id={`stateCommitteeLevelId`}
                                                                    className="input_style"
                                                                    onChange={(e)=>this.committeeLevelChange(e,"state")}
                                                                    value="state"
                                                            ></Form.Check>
                                                            <Form.Check
                                                                    inline
                                                                    label={"Parliament"}
                                                                    name="committeeLevel"
                                                                    type="radio"
                                                                    id={`parliamentCommitteeLevelId`}
                                                                    className="input_style mt-1"
                                                                    onChange={(e)=>this.committeeLevelChange(e,"parliament")}
                                                                    value="parliament"
                                                            ></Form.Check>
                                                            <Form.Check
                                                                    inline
                                                                    label={"Assembly"}
                                                                    name="committeeLevel"
                                                                    type="radio"
                                                                    id={`assemblyCommitteeLevelId`}
                                                                    className="input_style mt-1"
                                                                    onChange={(e)=>this.committeeLevelChange(e,"assembly")}
                                                                    value="assembly"
                                                            ></Form.Check>
                                                            <Form.Check
                                                                inline
                                                                label={"Mandal/Town/Division"}
                                                                name="committeeLevel"
                                                                type="radio"
                                                                id={`mandalCommitteeLevelId`}
                                                                className="input_style mt-1"
                                                                onChange={(e)=>this.committeeLevelChange(e,"mandal")}
                                                                value="mandal"
                                                        ></Form.Check>
                                                        <Form.Check
                                                                inline
                                                                label={"Village/Ward"}
                                                                name="committeeLevel"
                                                                type="radio"
                                                                id={`villageCommitteeLevelId`}
                                                                className="input_style mt-1"
                                                                onChange={(e)=>this.committeeLevelChange(e,"village")}
                                                                value="village"
                                                        ></Form.Check>
                                                        </Col>
                                                    </Row>
                                                    
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                            </div>
                            {
                                this.state.committeeLevelValue == "parliament" ?
                                (
                                    <div className='zIndexCls'>
                                        <Row className='justify-content-center'>
                                            <Col className='mt-2 mb-2' sm={4}>
                                                <label className="form-label f-12 fw-bold color_white">Parliament</label>
                                                <SelectBox
                                                        stateData={this.state.parliamentValue}
                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                        name="parliamentName"
                                                        options={this.state.pc_listArr}
                                                        placeholderName={'Parliament'}
                                                        dropdownIndicator={{ DropdownIndicator }}
                                                    />
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                )
                                :
                                (
                                    this.state.committeeLevelValue == "assembly" ?
                                    (
                                        <div  className='zIndexCls'>
                                            <Row className='justify-content-center'>
                                                <Col sm={4}>
                                                    <Row>
                                                      
                                                        <Col className='mt-1' >
                                                            <label className="form-label f-12 fw-bold color_white">Constituency
                                                                    {
                                                                        this.state.consLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                                        :
                                                                        ("")
                                                                    }
                                                            </label>
                                                            <SelectBox
                                                                    stateData={this.state.constituencyValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="constituencyName"
                                                                    options={this.state.ac_listArr}
                                                                    placeholderName={'Constituency'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                
                                                
                                            </Row>
                                        </div>
                                    )
                                    :
                                    (
                                        this.state.committeeLevelValue == "mandal"  ? 
                                            (
                                                <div  className='zIndexCls'>
                                                    <Row className='justify-content-center'>
                                                        <Col sm={4}>
                                                            <Row>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Constituency
                                                                     {
                                                                        this.state.consLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                                        :
                                                                        ("")
                                                                    }
                                                                </label>
                                                                <SelectBox
                                                                        stateData={this.state.constituencyValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="constituencyName"
                                                                        options={this.state.ac_listArr}
                                                                        placeholderName={'Constituency'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Mandal/Town
                                                                    {
                                                                        this.state.mandalLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                                        :
                                                                        ("")
                                                                    }
                                                                </label>
                                                                <SelectBox
                                                                        stateData={this.state.mandalValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="mandalName"
                                                                        options={this.state.mc_listArr}
                                                                        placeholderName={'Mandal/Town'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            </Row>
                                                        </Col>
                                                        
                                                    </Row>
                                                   
                                                </div>
                                            )
                                            :
                                            (
                                                this.state.committeeLevelValue == "village" ? 
                                                (
                                                    <div  className='zIndexCls'>
                                                    <Row className='justify-content-center'>
                                                        <Col sm={4}>
                                                            <Row>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Constituency 
                                                                    {
                                                                        this.state.consLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                                        :
                                                                        ("")
                                                                    }
                                                                </label>
                                                                <SelectBox
                                                                        stateData={this.state.constituencyValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="constituencyName"
                                                                        options={this.state.ac_listArr}
                                                                        placeholderName={'Constituency'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Mandal/Town
                                                                    {
                                                                        this.state.mandalLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                                        :
                                                                        ("")
                                                                    }
                                                                </label>
                                                                <SelectBox
                                                                        stateData={this.state.mandalValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="mandalName"
                                                                        options={this.state.mc_listArr}
                                                                        placeholderName={'Mandal/Town'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            </Row>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row className='justify-content-center'>
                                                        <Col sm={4}>
                                                            <Row>
                                                           
                                                            <Col className='mt-1' >
                                                                <label className="form-label f-12 fw-bold color_white">Village
                                                                    {
                                                                        this.state.villageLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                                        :
                                                                        ("")
                                                                    }
                                                                </label>
                                                                <SelectBox
                                                                        stateData={this.state.villageValue}
                                                                        handleChange={this.handleOnchangeSelectBoxNew}
                                                                        name="villageName"
                                                                        options={this.state.vc_listArr}
                                                                        placeholderName={'Village'}
                                                                        dropdownIndicator={{ DropdownIndicator }}
                                                                    />
                                                            </Col>
                                                            </Row>
                                                        </Col>
                                                        
                                                    </Row>
                                                </div>
                                                )
                                                :
                                                ("")
                                            ) 
                                    )
                                )
                            }
                           
                           
                            
                            </>
                        )
                        :
                        ("")
                    }

                    {
                        this.state.photosShowBlock == true ?
                        (
                            <>
                            {
                                this.state.photoWithNameLoadingSymbol == "show" ?
                                (
                                    <Row>
                                        <Col sm={12}>
                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }

                            {
                                    this.state.mainPhotosWithNamesArr.length>0 ?
                                    (
                                        
                                        <>
                                            <Row className='justify-content-center'>
                                                <Col className='mt-2 mb-2'>
                                                    <div className= "bg_white_color">
                                                        <h6 className='f-12'>Please Check Photo To Get Names:</h6>
                                                         <Row>
                                                            <Col>
                                                                    <Slider ref={c => (this.slider = c)} {...settings}>
                                                                    {
                                                                        this.state.mainPhotosWithNamesArr.map((item,index) => {
                                                                            return(
                                                                                item.imagePath != "" ?
                                                                                ( <div id={`img-${item.tdpCadreId}`} class={item.tdpCadreId == this.state.photoSelectedTDPCadreId ? "border_thick" :""} >
                                                                                        {
                                                                                            item.tdpCadreId == this.state.photoSelectedTDPCadreId ?
                                                                                            (<i class="fa-solid fa-circle-check color_green f-25 pos_icon_check"></i>)
                                                                                            :
                                                                                            ("")
                                                                                        }
                                                                                       
                                                                                        <img src={item.imageUrl} className='width_height_img img-thumbnail' onClick={() => this.getImageCheckDetails(item)} onError={(e) => this.onError(e,`img-${item.tdpCadreId}`)} alt="..." />
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                ("")
                                                                            
                                                                            )
                                                                        })
                                                                    }
                                                                </Slider>
                                                            </Col>
                                                         </Row>
                                                        
                                                        
                                                        <Row>
                                                            <Col className=''>
                                                                <Button variant="outline-secondary" className='f-14 mt-3 fw-bold' onClick={this.previous}><i class="fa-solid fa-arrow-left"></i> Previous </Button>
                                                                <Button variant="outline-success" className='f-14 mt-3 fw-bold float-right' onClick={this.next} > Next <i class="fa-solid fa-arrow-right"></i></Button>
                                                            </Col>
                                                        </Row>

                                                        
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                    :
                                    (<>
                                        {
                                        this.state.mainPhotosWithNamesCheck == 1 ?
                                        (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                        :
                                        ("")
                                    }
                                    </>)
                                }

                            </>
                        )
                        :
                        ("")
                    }
                    <div class="scroll_top">

                    
                    {
                        this.state.namesShowBlock == true ?
                        (
                                <Row className='justify-content-center'>
                                        <Col sm={4}>
                                        <div className='bg_white_color1 mt-3' data-aos="fade-up" data-aos-delay="100">
                                        <h6 className='f-14'>{this.state.onChangeECName} </h6>
                                            <Table striped bordered hover className="table_custom_css">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Designation</th>
                                                        <th>Correct Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.next5RecordNamesArr.map(item => {
                                                            return(
                                                                <tr>
                                                                    <td style={{textTransform:"uppercase"}}>{item.memberName}</td>
                                                                    <td>{item.designation}</td>
                                                                    <td>
                                                                        {
                                                                            this.state.rightCheckColor == "updated" ?
                                                                            (
                                                                                this.state.photoSelectedTDPCadreId == item.tdpCadreId ?
                                                                                (
                                                                                   <> <i className="fa-solid fa-square-check color_green f-14  mt-2"></i>
                                                                                    <br/>
                                                                                    <h6 className='f-12 color_green'>Correct</h6></>
                                                                                )
                                                                                :
                                                                                (
                                                                                    this.state.nameSelectedTDPCadreId == item.tdpCadreId ?
                                                                                    (
                                                                                        <><i class="fa-solid fa-circle-xmark color_red f-14 cursor_pointer mt-2" onClick={() => this.getNameCheckDetails(item)}></i>
                                                                                        <br/>
                                                                                        <h6 className='f-12 color_red'>Wrong</h6></>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <i className="fa-solid fa-square-check f-14 color_black cursor_pointer" onClick={() => this.getNameCheckDetails(item)}></i>
                                                                                    )
                                                                                )
                                                                            )
                                                                            :
                                                                            (<i className="fa-solid fa-square-check f-14 color_black cursor_pointer" onClick={() => this.getNameCheckDetails(item)}></i>)
                                                                        }
                                                                        
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                        )
                        :
                        ("")
                    }
                    </div>
                    <Correct_WrongPersonModal 
                        show={this.state.checkCorrectPersonModal}
                        onHide={() => this.setState({ checkCorrectPersonModal: false })}
                        stateData={this.state}
                        closePersonModal={this.closePersonModal}
                        showCorrectPersonInModal={this.showCorrectPersonInModal}
                        selectCorrectName={this.selectCorrectName}
                    />
                    
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(LeaderSearchSection);
  