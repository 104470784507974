
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,InputGroup,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import { getAPICallData } from '../../services/MemberService';
import "./VotersCollections.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';


class VotersCollectionDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessListArr:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            attrVerfiDtsCheck:props?.location?.params?.attrVerfiDtsCheck == undefined ? "" : props?.location?.params?.attrVerfiDtsCheck,
            boothIdsList:[],
            boothId:"",
            typeOfValuesShow:"All",
            attributesCollDashboard:[],
            tableDisPlay:""
           
        }
        
    }

    componentDidMount() {
        let userAccessList = this.state.attrVerfiDtsCheck;
        if(userAccessList != null && userAccessList.length > 0 ){
            this.setState({ boothIdsList: userAccessList });
            this.getAttributesUpdationStatus("All","");
        }else{
            this.props.history.push({pathname:"/dashboard"});
            
        }
    }


    boothNoHandleChange = (e) =>{
        const value = e.target.value;

        if(e.target.value == "0"){
            this.setState({ boothId: "",tableDisPlay: "allBooths"});
        }else{
            this.setState({ boothId: value,tableDisPlay: ""});
        }
    
       

        this.getAttributesUpdationStatus(this.state.typeOfValuesShow,value);
    }

    getVoterAttCollShowType = (type) => {
        this.setState({boothId:"" });
        if(type == "Booth" && this.state.boothId == ""){
            this.setState({ tableDisPlay: "allBooths"});
        }else{
            this.setState({ tableDisPlay: ""});
        }
        this.setState({ typeOfValuesShow: type });
        this.getAttributesUpdationStatus(type,"");
    }

    getAttributesUpdationStatus = (type,boothId) => {
        this.setState({ attributesCollDashboard: [] });
        const obj =  
        JSON.stringify({ 
          "chatId" :this.state.chatID,
          "type" : type,
          "boothId": boothId
        }
      ); 

      getAPICallData(obj,"attributes/getAttributesVerificationUpdationStatus")
          .then((res) => res.json())
          .then((data) => {
            if(data !=null && data.length>0){
                this.setState({ attributesCollDashboard:data  });
            }else{
                this.setState({ attributesCollDashboard: [] });
            }
        });
    }
  
    columnsForDashboard() {

        let columns = [
            {
                dataField: "Booth",
                text: "Booth",
                sort: true,
                headerStyle: {
                    width: "20%"
                }
                
                
            },
            {
                dataField: "totalVoters",
                text: "V",
               
                
            },
            {
                dataField: "Party",
                text: "P",
                formatter: this.actionsFormatterForParty.bind(this),
                
            },

            {
                dataField: "Caste",
                text: "C",
                formatter: this.actionsFormatterForCaste.bind(this),
            
            },
            {
                dataField: "Mobile",
                text: "M",
                formatter: this.actionsFormatterForMobile.bind(this),
            
            },
            {
                dataField: "Occupation",
                text: "O",
                formatter: this.actionsFormatterForOcc.bind(this),
            
            },
            
            
            
        
        ];
        return columns;
    }

    actionsFormatterForParty = (cell, row, rowIndex, formatExtraData) => {
        return (
                row.Party != 0 ?
                (<h6 className='f-13'>{row.Party}<br/><small className='color_green ml-2'>{row.partyPerc}%</small></h6> )
                :
                (<h6 className='f-13'>0</h6>)
              
         );
    }
    actionsFormatterForCaste = (cell, row, rowIndex, formatExtraData) => {
        return (
                row.Caste != 0 ?
                (<h6 className='f-13'>{row.Caste}<br/><small className='color_green ml-2'>{row.castePerc}%</small></h6> )
                :
                (<h6 className='f-13'>0</h6>)
              
         );
    }
    actionsFormatterForMobile = (cell, row, rowIndex, formatExtraData) => {
        return (
                row.Mobile != 0 ?
                (<h6 className='f-13'>{row.Mobile}<br/><small className='color_green ml-2'>{row.mobilePerc}%</small></h6> )
                :
                (<h6 className='f-13'>0</h6>)
              
         );
    }
    actionsFormatterForOcc = (cell, row, rowIndex, formatExtraData) => {
        return (
                row.Occupation != 0 ?
                (<h6 className='f-13'>{row.Occupation}<br/><small className='color_green ml-2'>{row.occPerc}%</small></h6> )
                :
                (<h6 className='f-13'>0</h6>)
              
         );
    }

    dataForDashboard = () => {
        let votersData = [];
        if(this.state.attributesCollDashboard !=undefined && this.state.attributesCollDashboard.length>0){
            
            for (let i = 0; i < this.state.attributesCollDashboard.length; i++) {

                const booth = (this.state.attributesCollDashboard[i].partNo);
                const totalVoters = (this.state.attributesCollDashboard[i].totalVoters);
                const party = this.state.attributesCollDashboard[i].partyUpdated;
                const caste = (this.state.attributesCollDashboard[i].casteUpdated);
                const mobile = (this.state.attributesCollDashboard[i].mobileUpdated);
                const occ = (this.state.attributesCollDashboard[i].occupationUpdated);
                const residence = (this.state.attributesCollDashboard[i].residenceUpdated);
                const education = (this.state.attributesCollDashboard[i].educationUpdated);
                const religion = (this.state.attributesCollDashboard[i].religionUpdated);

                const partyPerc = this.state.attributesCollDashboard[i].partyUpdatedPercentage;
                const castePerc = this.state.attributesCollDashboard[i].casteUpdatedPercentage;
                const mobilePerc = this.state.attributesCollDashboard[i].mobileUpdatedPercentage;
                const occPerc = this.state.attributesCollDashboard[i].occupationUpdatedPercentage;

                const residencePerc = this.state.attributesCollDashboard[i].residenceUpdatedPercentage;
                const educationPerc = this.state.attributesCollDashboard[i].educationUpdatedPercentage  ;
                const religionPerc = this.state.attributesCollDashboard[i].religionUpdatedPercentage;
                
                
                const obj = {
                    Booth:parseInt(booth),
                    totalVoters: totalVoters,
                    Party: party,
                    Caste: caste,
                    Mobile:mobile,
                    Occupation:occ,
                    partyPerc:partyPerc,
                    castePerc:castePerc,
                    mobilePerc:mobilePerc,
                    occPerc:occPerc,
                    residence: residence,
                    education: education,
                    religion:religion,
                    residencePerc: residencePerc,
                    educationPerc: educationPerc,
                    religionPerc:religionPerc,

                    
                }
                votersData.push(obj);
            } 

            
            
            return votersData;
        }
    }

    render() {
      
        return (
            <section>
               <Container>
                        <Row>
                            <Col sm={12} className=''>
                                <Form className="text-center">
                                        <Form.Check
                                            inline
                                            label="ALL"
                                            name="getAttrCollData"
                                            type="radio"
                                            id="attrAllId"
                                            defaultChecked={true}
                                            onChange={()=>this.getVoterAttCollShowType("All")}
                                            className="f-14 fw-bold color_black"
                                        />
                                        <Form.Check
                                            inline
                                            label="Booth Wise"
                                            name="getAttrCollData"
                                            type="radio"
                                            id="attrBoothWiseId"
                                            onChange={()=>this.getVoterAttCollShowType("Booth")}
                                            className="f-14 fw-bold color_black"
                                        />
                                        
                                    
                                </Form>
                            </Col>
                        </Row>
                        <div className='bg_light_blue mt-2'> 
                        {
                            this.state.typeOfValuesShow == "Booth" ?
                            (
                                <Row>
                                    <Col className='w_80'>
                                        <h6 className="form-label f-14 fw-bold">Booth</h6>
                                        <select id="booth_id" name="boothId" className='form-select form-select-sm' onChange={(e) => this.boothNoHandleChange(e)}>
                                            {
                                                this.state.boothIdsList.length>0 ? 
                                                (
                                                    <>
                                                    <option value="0" selected>All</option>
                                                    {
                                                        this.state.boothIdsList.map((item,index) => {
                                                            return(
                                                                <option value={item.id}>Booth No - {item.name}</option>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                
                                                )
                                                :
                                                (<option value="">Select Booth</option>)
                                                
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        }

                        {
                           this.state.tableDisPlay == "" ?
                            (
                                <>
                                <Row>
                                    <Col>
                                        <h6 className='fw-normal f-14 mt-2'>Total Voters : <span className='fw-bold color_blue'>
                                            {
                                                 this.state.attributesCollDashboard.length>0 ?
                                                 (this.state.attributesCollDashboard[0] == undefined ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.attributesCollDashboard[0].totalVoters)
                                                 :
                                                 (0)
                                                
                                            
                                            }
                                            </span></h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='bg_white_block text-center'>
                                            <h6 className='fw-normal f-14'>Party</h6>
                                            <h5 className='mb-0 f-14 color_blue'>
                                             {
                                                this.state.attributesCollDashboard.length>0 ?
                                                (
                                                    this.state.attributesCollDashboard[0] == undefined ?
                                                    <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : 
                                                    this.state.attributesCollDashboard[0].partyUpdated != 0 ?
                                                    (this.state.attributesCollDashboard[0].partyUpdated + " - ( "+this.state.attributesCollDashboard[0].partyUpdatedPercentage +"%  )")
                                                    :
                                                    (0)
                                                )
                                                :
                                                (0)
                                                
                                                    
                                                
                                                }

                                            
                                            </h5>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='bg_white_block text-center'>
                                            <h6 className='fw-normal f-14'>Caste</h6>
                                            <h5 className='mb-0 f-14 color_blue'>
                                             {
                                                this.state.attributesCollDashboard.length>0 ? 
                                                (
                                                    this.state.attributesCollDashboard[0] == undefined ?
                                                    <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : 
                                                    this.state.attributesCollDashboard[0].casteUpdated != 0 ?
                                                    (this.state.attributesCollDashboard[0].casteUpdated + " - ( "+this.state.attributesCollDashboard[0].casteUpdatedPercentage +"%  )")
                                                    :
                                                    (0)
                                                )
                                                :
                                                (0)
                                                
                                                    
                                                
                                                }

                                            
                                            </h5>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                            <div className='bg_white_block text-center mt-2'>
                                                <h6 className='fw-normal f-14'>Mobile</h6>
                                                <h5 className='mb-0 f-14 color_blue'>
                                                {
                                                    this.state.attributesCollDashboard.length>0 ?
                                                    (
                                                        this.state.attributesCollDashboard[0] == undefined ?
                                                        <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : 
                                                        this.state.attributesCollDashboard[0].mobileUpdated != 0 ?
                                                        (this.state.attributesCollDashboard[0].mobileUpdated + " - ( "+this.state.attributesCollDashboard[0].mobileUpdatedPercentage +"%  )")
                                                        :
                                                        (0)
                                                    )
                                                    :
                                                    (0)
                                                
                                                    
                                                
                                                }
                                                
                                                </h5>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='bg_white_block text-center mt-2'>
                                                <h6 className='fw-normal f-14'>Occupation</h6>
                                                <h5 className='mb-0 f-14 color_blue'>
                                                {
                                                    this.state.attributesCollDashboard.length>0 ?
                                                    (
                                                        this.state.attributesCollDashboard[0] == undefined ?
                                                        <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : 
                                                        this.state.attributesCollDashboard[0].occupationUpdated != 0 ?
                                                        (this.state.attributesCollDashboard[0].occupationUpdated + " - ( "+this.state.attributesCollDashboard[0].occupationUpdatedPercentage +"%  )")
                                                        :
                                                        (0)
                                                    )
                                                    :
                                                    (0)
                                                
                                                    
                                                
                                                }

                                                
                                                </h5>
                                            </div>
                                        </Col>
                                </Row>

                                <Row>
                                    <Col>
                                            <div className='bg_white_block text-center mt-2'>
                                                <h6 className='fw-normal f-14'>Residence</h6>
                                                <h5 className='mb-0 f-14 color_blue'>
                                                {
                                                    this.state.attributesCollDashboard.length>0 ?
                                                    (
                                                        this.state.attributesCollDashboard[0] == undefined ?
                                                        <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : 
                                                        this.state.attributesCollDashboard[0].residenceUpdated != 0 ?
                                                        (this.state.attributesCollDashboard[0].residenceUpdated + " - ( "+this.state.attributesCollDashboard[0].residenceUpdatedPercentage +"%  )")
                                                        :
                                                        (0)
                                                    )
                                                    :
                                                    (0)
                                                
                                                    
                                                
                                                }
                                                
                                                </h5>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='bg_white_block text-center mt-2'>
                                                <h6 className='fw-normal f-14'>Education</h6>
                                                <h5 className='mb-0 f-14 color_blue'>
                                                {
                                                    this.state.attributesCollDashboard.length>0 ?
                                                    (
                                                        this.state.attributesCollDashboard[0] == undefined ?
                                                        <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : 
                                                        this.state.attributesCollDashboard[0].educationUpdated != 0 ?
                                                        (this.state.attributesCollDashboard[0].educationUpdated + " - ( "+this.state.attributesCollDashboard[0].educationUpdatedPercentage +"%  )")
                                                        :
                                                        (0)
                                                    )
                                                    :
                                                    (0)
                                                
                                                    
                                                
                                                }

                                                
                                                </h5>
                                            </div>
                                        </Col>
                                </Row>

                                <Row>
                                    <Col>
                                            <div className='bg_white_block text-center mt-2'>
                                                <h6 className='fw-normal f-14'>Religion</h6>
                                                <h5 className='mb-0 f-14 color_blue'>
                                                {
                                                    this.state.attributesCollDashboard.length>0 ?
                                                    (
                                                        this.state.attributesCollDashboard[0] == undefined ?
                                                        <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : 
                                                        this.state.attributesCollDashboard[0].religionUpdated != 0 ?
                                                        (this.state.attributesCollDashboard[0].religionUpdated + " - ( "+this.state.attributesCollDashboard[0].religionUpdatedPercentage +"%  )")
                                                        :
                                                        (0)
                                                    )
                                                    :
                                                    (0)
                                                
                                                    
                                                
                                                }
                                                
                                                </h5>
                                            </div>
                                        </Col>
                                       
                                </Row>

                                </>
                            )
                            :
                            (
                                <div className='mt-2'>
                                    <Row>
                                        <Col >
                                        <h6 className='f-12 mt-3 fw-bold'>
                                            Note : <span className='ml_5 mr_2'>V - Total Voters,</span>
                                                    <span className='ml_5 mr_2'>P - Party,</span>
                                                    <span className='ml_5 mr_2'>C - Caste,</span>
                                                    <span className='ml_5 mr_2'>M - Mobile,</span>
                                                    <span className='ml_5 mr_2'>O - Occupation</span>
                                        </h6>
                                       
                                        </Col>
                                    </Row>
                                        {
                                             this.state.attributesCollDashboard.length>0 ?
                                             (
                                                 <CardLessDataTableWithSearch 
                                                 parentData={this.dataForDashboard()}
                                                 particlecolumns={this.columnsForDashboard()}
                                                 defaultSorted={[{
                                                     dataField: "Booth",
                                                     order: "asc"
                                                 }]}
                                                 tableHeading="Booth Wise Details"
                                                 />
                                             )
                                             :
                                             (
                                                this.state.attributesCollDashboard.length == 0 ? 
                                                (
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                ("No Data Available...")
                                               
                                             )
                                        }
                                </div>
                               
                                
                            )
                        }

                            
                            
                        </div>
               </Container>
            
            </section>
        )
    }
  }
  
  export default withRouter(VotersCollectionDashboard);
  