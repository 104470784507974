import React, { Component } from 'react'
import { Modal, Row,Col,Button,Form,Tabs,Tab,Image,Collapse } from "react-bootstrap";
import "./BoothAndHouseMapping.css";
import { getAPICallData } from '../../services/MemberService';
import loader from '../../images/loader.gif';
//import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';

class AddNewVoterModel extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
      
    }

  

    colFunction() {

        let columns = [
            {
                dataField: "type",
                text: "type",
                sort: true,
            },
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                
            },

            {
                dataField: "HNo",
                text: "H:No",
                
            },
            {
                dataField: "Gender",
                text: "Gender",
               
            },
            {
                dataField: "Age",
                text: "Age",
                
            },
            
            
        ];
        return columns;
    }

    

    tableData = (data) => {
        let getArray = [];

        data.map(item => {
            const newObject = {
                type :"fff",
                Name:item.voterName,
                HNo:item.houseNo,
                Gender:item.houseNo,
                Age:item.age,
            }
            getArray.push(newObject);
        })
        
        return getArray;
    }
    
    render() {
        return (
            <Modal {...this.props} 
                size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                    <Modal.Header closeButton className='p-3'>
                        <Modal.Title className='f-14 fw-bold'>Add New Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                    <Tabs
                        defaultActiveKey="newVote"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        >
                        <Tab eventKey="newVote" title="New Voter">
                            <Row>
                                <Col sm={12}>
                                {
                              this.props.stateData.viewExistingVotersList.length>0 ? 
                              (
                                <Col sm={12}>
                                <h6 className='mt-2 collapse-cls f-12' onClick={() => this.props.pviewExistingVotersDisplay()}>View Existing Members
                                - Total Voters : <b>{this.props.stateData.viewExistingVotersList.length}</b>
                                <span className='float-end'>
                                  {
                                    this.props.stateData.viewExistingVotersOpen == false ?
                                    (<i className="fa-solid fa-square-plus"></i>)
                                    :
                                    (<i className="fa-solid fa-square-minus"></i>)
                                  }
                                  
                                  </span>
                                </h6>
                                <Collapse in={this.props.stateData.viewExistingVotersOpen}>
                                  <div className='mt-2'>
                                    <table className='table table_custom mb-2 table-bordered'>
                                            <thead>
                                                <tr>
                                                <th style={{minWidth:"40%"}}>Name</th>
                                                <th>H:No</th>
                                                <th>Age</th>
                                                <th>Gender</th>
                                               
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.stateData.viewExistingVotersList.map((item,index) => {
                                                return(
                                                    
                                                    <tr>
                                                    <td style={{textAlign:"left"}}>{item.voterName}</td> 
                                                    <td>{item.houseNo}</td>
                                                    <td>{item.age}</td>
                                                    <td>{item.gender} </td>
                                                    
                                                    </tr>
                                                )
                                                
                                                })
                                            }
                                            
                                            </tbody>
                                        </table>
                                   </div>
                                </Collapse>
                                </Col>
                                
                              )
                              :
                              ("")
                            }
                                </Col>
                                <Col sm={12}>
                                <Form>
                                    <Form.Group className="" controlId="formNameId">
                                        <Form.Label className='f-14 fw-bold'>Full Name <span className='color_red f-9'>{this.props.stateData.validateNewName}</span></Form.Label>
                                        <Form.Control type="text" name="newVoterName" className='form-control-sm'  placeholder="Enter Full Name" onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                    </Form.Group>
                                    <Form.Group className="" controlId="formMobileNo">
                                        <Form.Label className='f-14 fw-bold'>Mobile No <span className='color_red f-9'>{this.props.stateData.validateNewMobileNo}</span></Form.Label>
                                        <Form.Control type="text" name="newVoterMobileNumber" className='form-control-sm'  placeholder="Enter Mobile No" onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                    </Form.Group>
                                    {/*<Form.Group className="" controlId="formVoterId">
                                        <Form.Label className='f-14 fw-bold'>Voter Id <span className='color_red f-9'>{this.props.stateData.validateNewVoterId}</span></Form.Label>
                                        <Form.Control type="text" name="newVoterId" className='form-control-sm' placeholder="Enter Voter Id" onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                    </Form.Group>*/}
                                    <Row>
                                        <Col>
                                            <Form.Group className="" controlId="formGenderId">
                                                <Form.Label className='f-14 fw-bold'>Gender <span className='color_red f-9'>{this.props.stateData.validateNewGender}</span></Form.Label>
                                                <select id="genderId" name="newGender" className='form-select form-select-sm' onChange={(e) => this.props.phandleOnchangeSelectBox(e,"newGender")}>
                                                    <option value="">Select Gender</option>
                                                    <option value="M">Male</option>
                                                    <option value="F">Female</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group  controlId="formAgeId">
                                                <Form.Label className='f-14 fw-bold'>Age <span className='color_red f-9'>{this.props.stateData.validateNewAge}</span></Form.Label>
                                                <Form.Control name="newAge" className='form-control-sm' type="text" placeholder="Enter Age" maxLength={2} onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group  controlId="formReleativeNameId">
                                        <Form.Label className='f-14 fw-bold'>RelativeName</Form.Label>
                                        <Form.Control name="newRelativeName" className='form-control-sm' type="text" placeholder="Enter Releative Name" onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                    </Form.Group>
                                    <Form.Group controlId="formReletionId">
                                        <Form.Label className='f-14 fw-bold'>Relation</Form.Label>
                                        <select id="newRelationNameId" name="newRelationName" className='form-select form-select-sm' onChange={(e) => this.props.phandleOnchangeSelectBox(e,"newRelationName")}>
                                            <option value="">Select Relation</option>
                                            <option value="Father">Father</option>
                                            <option value="Mother">Mother</option>
                                            <option value="Husband">Husband</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </Form.Group>
                                    <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-2' onClick={() => this.props.paddNewVoterToHouse()}>Add New Vote</Button>
                                    {
                                        this.props.stateData.newMemberAddedMsg !="" ?
                                        ( <h6 className='text-center fw-bold mt-2'>{this.props.stateData.newMemberAddedMsg}</h6>)
                                        :
                                        ("")
                                    }
                                    </Form>
                                </Col>
                            </Row> 
                        </Tab>
                        <Tab eventKey="shiftVote" title="Shift Voter">
                        <Row>
                                <Col sm={12}>
                                <Form>
                                <div className='flex_block'>
                                    <Form.Check className='f-12' type='radio' defaultChecked={true} id={`voterCardNo`} name="shiftVoterSelectedType" value="voterCardNo" label={`VoterCardNo`} onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                    <Form.Check className='f-12 ms-2' type='radio' id={`voterName`} name="shiftVoterSelectedType" value="voterName" label={`VoterName`} onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                    {/*<Form.Check className='f-12 ms-2' type='radio' id={`gender`} name="shiftVoterSelectedType" value = "gender" label={`Gender`} onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>*/}
                                    <Form.Check className='f-12 ms-1' type='radio' id={`houseNo`} name="shiftVoterSelectedType" value="houseNo" label={`HouseNo`} onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                </div>
                                
                                {
                                    this.props.stateData.shiftVoterSelectedType == "gender" ? 
                                    (
                                        <select id="shiftgenderId" name="shiftNewVoterId" className='form-select form-select-sm' onChange={(e) => this.props.phandleOnchangeSelectBox(e,"shiftNewVoterId")}>
                                            <option value="">Select Gender</option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </select>
                                    )
                                    :
                                    (<><Form.Control type="text" name="shiftNewVoterId" className='form-control-sm' placeholder={this.props.stateData.shiftVoterSelectedType} onChange={(e) => this.props.phandleOnchangeInputBox(e)}/><span className='color_red f-12'>{this.props.stateData.validShiftNewVoterId}</span></>)
                                }
                                
                                
                                    
                                    
                                    
                                
                                    {/*<Form.Group className="" controlId="shiftNewVoterId">
                                            <Form.Label className='f-14 fw-bold'>Voter Card No <span className='color_red f-9'>{this.props.stateData.validShiftNewVoterId}</span></Form.Label>
                                            <Form.Control type="text" name="shiftNewVoterId" className='form-control-sm' placeholder="Enter Voter Card No" onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                    </Form.Group>*/}

                                    <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mb-3' onClick={() => this.props.pgetVoterCardSearchDetails()}>Get Voters</Button>
                                    {
                                        this.props.stateData.shiftNewVoterLoderSymbol == "show" ?
                                        (
                                            <Row>
                                                <Col sm={12}>
                                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                        }
                                        <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.voterSearchMessageDisplay}</h6>
                                        {
                                            this.props.stateData.shiftVoterSearchDetails.length>0 ?
                                            (

                                                <Row>
                                                    <Col sm={12}>
                                                        <div className='mt-1'>
                                                            <h6 className='f-14 fw-bold'>Voter Details : count-<b>{this.props.stateData.shiftVoterSearchDetails.length}</b> <span className='color_red f-12'>{this.props.stateData.validboothHouseVoterId}</span></h6>
                                                            <div className='table_scroll'>
                                                           {/* <CardLessDataTableWithSearch
                                                                 parentData={this.tableData(this.props.stateData.shiftVoterSearchDetails)}
                                                                 particlecolumns={this.colFunction()}
                                                                 searchBar={false}
                                                                
                                                             />*/}

                                                            
                                                            <table className='table table_custom mb-2 table-bordered '>
                                                                <thead>
                                                                    <tr>
                                                                    <th style={{minWidth:"10%"}}></th>
                                                                    <th >Name</th>
                                                                    <th>H:No</th>
                                                                    {/*<th>Gender</th>*/}
                                                                    <th>Age</th>
                                                                
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    this.props.stateData.shiftVoterSearchDetails.map((item,index) => {
                                                                    return(
                                                                        
                                                                        <tr>
                                                                        <td><Form.Check name="boothHouseVoterId" type="radio" id={`inline-${index}-1`} value={item.boothHouseVoterId} onChange={(e) => this.props.psetBoothHouseVoterId(e)}/></td>
                                                                        <td style={{textAlign:"left"}}>{item.voterName} - ({item.voterCardNo})</td> 
                                                                        <td>{item.houseNo}</td>
                                                                        <td>{item.age}/{item.gender}</td>
                                                                         {/*<td>{item.age}</td>*/}
                                                                        
                                                                        </tr>
                                                                    )
                                                                    
                                                                    })
                                                                }
                                                                
                                                                </tbody>
                                                            </table>
                                                                {/*
                                                                    this.props.stateData.shiftVoterSearchDetails.map((vlist,index) => {
                                                                        return(
                                                                            <h6 className='f-14 mt-2 form_check_size' onChange={(e) => this.props.phandleOnchangeInputBox(e)}><Form.Check label={`${vlist.voterName} -   ${vlist.age}  / ${vlist.gender}`} name="boothHouseVoterId" type="radio" id={`inline-${index}-1`} value={vlist.boothHouseVoterId} /> <span className='color_red f-12'>{this.props.stateData.validboothHouseVoterId}</span><span className='f-12 float-end'>(Voter Card No: {vlist.voterCardNo})</span> </h6>
                                                                        )
                                                                    })
                                                                */}
                                                                
                                                            </div>
                                                            
                                                        </div>     
                                                        <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-3 mb-3' onClick={() => this.props.pupdateVoterBoothHouse()}>Assign To This House</Button>
                                                            {
                                                                this.props.stateData.shiftVoterLoderSymbolSave === "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                                            }
                                                    </Col> 
                                                        <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.shiftVoterMessageSave}</h6>
                                                </Row>
                                            )
                                            :
                                            (
                                               ""
                                            )
                                                
                                            }
                                    </Form>
                                </Col>
                            </Row> 
                        </Tab>
                        
                        </Tabs>
                        
                    </Modal.Body>
                </Modal>
            
        )
    }
}
export default AddNewVoterModel;
  

