import React, { Component } from 'react'
import { Modal, Button,Row,Col } from "react-bootstrap";
import "./EventsAndActivities.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

class ShowAllPhotos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            getAllPhotosList:props?.getAllPhotosList == undefined ? "" : props?.getAllPhotosList,
            images:[]
        }
        
      
    }

    componentDidMount() {
        
        if(this.state.getAllPhotosList != ""){
            var photosArr=[];
            for(let i = 0; i < this.state.getAllPhotosList.length; i++){
                
                if(this.props.modalTitle == "Booth Location Images"){
                    var obj={
                        original: this.state.getAllPhotosList[i],
                        thumbnail: this.state.getAllPhotosList[i],
                    };
                    photosArr.push(obj);
                    this.setState({images:photosArr})
                }else{
                    if(this.state.getAllPhotosList[i].fileType != "pdf"){
                        var obj={
                            original: this.state.getAllPhotosList[i].filePath,
                            thumbnail: this.state.getAllPhotosList[i].filePath,
                        };
                        photosArr.push(obj);
                        this.setState({images:photosArr});
                    }
                }
                
                
               
            }
        }
    }
    
    render() {
       
        console.log(this.state.images)
        
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>{this.props.modalTitle} All Photos</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    {
                        this.state.getAllPhotosList.length>0 ?
                        (
                            <ImageGallery
                                items={this.state.images}
                                showBullets={true}
                                showIndex={true}
                                showThumbnails={false}
                                lazyLoad={true}
                                showPlayButton={false}
                                originalHeight="500px"
                            />
                        )
                        :
                        ("")
                    }
                   
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default ShowAllPhotos;
  

