
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import "./raiseAnIssue.css";
import "../EventsAndActivities/EventsAndActivities.css"
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService'
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import moment from 'moment';
import ChangeStatusForIssues from './ChangeStatusForIssues';
import AddComments from './AddComments';
import ViewIssueDescription from './ViewIssueDescription';
import ViewCommentsForIssues from './ViewCommentsForIssues';
import ShowUploadFiles from './ShowUploadFiles';
import Webcam from "react-webcam";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class RaiseAnIssueSection extends Component {
    constructor(props) {
        super(props)
        this.fileupload = React.createRef()
        this.state = {
            webcamRef : React.createRef(),
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            createIssueBlock:true,
            issuesBlock:false,
            issuesTypeArr:[],
            validateIssueType:"",
            issueTypeValue:"",
            issueType:"",
            issueDescription:"",
            validateIssueDescription:"",
            createIssueLoadingSymbol:"hide",
            issueSuccMessage:"",
            issueStatusId:0,
            issueDetailsArr:[],
            allIssueDetailsArr:[],
            issueDetailsArrCheck:0,
            changeStatusForIssuesModal:false,
            addCommentsForIssuesModal:false,

            issueCommentLoadingSymbol:"hide",
            issueCommentMessage:"",
            validateIssueComment:"",
            issueComment:"",

            validateCloseIssueComment:"",
            closeIssueComment:"",
            changeStatusLodingSymbol:"hide",
            changeStatusMessage:"",

            issueId:"",

            showIssueDescriptionModal:false,
            showFullIssueDes:"",

            statusNameShow:"",

            viewCommentsModal:false,
            issueCommentsArr:[],
            issueCommentArrCheck:0,
            uploadIssuesArr:[],
            fileuploadCount:"",
            uploadFilesShowModal:false,
            issueFileDetailsArr:[],
            issueFileDetailsCheck:0,
            validateFilesUploadImage:"",
            camaraRadioButton:false,
            uploadImageRadioButton:true,
            videoConstraints:{facingMode: "environment"},
            selectedTypePhoto:"upload",
            validateIssuesImage:""

        }
        this.uploadMultipleFilesForIssues = this.uploadMultipleFilesForIssues.bind(this)
    }

    componentDidMount() {
        this.getIssueTypes();
        this.getUserCreatedIssues();
    }

    getIssueTypes = () => {
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID
            }); 

            getAPICallData(obj,"issue/getIssueTypes")
            .then((res) => res.json())
            .then((json) => {
                if(json !=null && json.length>0){
                    var arr=[];
                    for(var i in  json){
                        var obj={'value':json[i].id,'label':json[i].name};
                        arr.push(obj)
                        
                    }
                    this.setState({ issuesTypeArr:arr});
                }else{
                     this.setState({issuesTypeArr:[]});
                }
              
            });
    }

    issuesBlockShow = (blockType) => {
        this.setState({ issueStatusId: 0});
        this.setState({issueDetailsArr:this.state.allIssueDetailsArr});
        if(blockType == "create"){
          this.setState({ createIssueBlock: true});
          this.setState({ issuesBlock: false});
        }else{
          this.setState({ issuesBlock: true});
          this.setState({ createIssueBlock: false});
          
        }
  
      }


      handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });
        
      }


      handleOnchangeSelectBox = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "issueType"){
          let getIssIdName = this.state.issuesTypeArr.find((a) => a.value == value);
            this.setState({
                issueTypeValue: {
                  value: getIssIdName.value,
                  label: getIssIdName.label
              }
          });
        }
    
        this.setState({ [name]: value});
      }


      saveCreateIssue = () => {
        let uploadIssueFormsArr=[];
        if(this.state.issueType == "" || this.state.issueType == 0){
            this.setState({validateIssueType:"Please Select Issue Type" });
            return;
          }

        if(this.state.issueDescription == "" || this.state.issueDescription == 0){
            this.setState({validateIssueDescription:"Please Enter Description" });
            return;
        }

        if(this.state.selectedTypePhoto == "camara"){
            if(this.state.uploadIssuesArr.length == 0){
                this.setState({validateIssuesImage:"Please Take A Photo Atleast One..."});
                return;
            }
        }else if(this.state.selectedTypePhoto == "upload"){
            if(this.state.uploadIssuesArr.length == 0){
                this.setState({validateFilesUploadImage:"Please Upload Atleast One..."});
                return;
            }
        }

          
        for (let i = 0; i < this.state.uploadIssuesArr.length; i++) {
            var imageBase64 = this.state.uploadIssuesArr[i].data.replace("data:"+this.state.uploadIssuesArr[i].fileType+";base64,","");
            var fileTypeMod="";

            if(this.state.uploadIssuesArr[i].fileType == "image/jpeg" || this.state.uploadIssuesArr[i].fileType == "image/png" || this.state.uploadIssuesArr[i].fileType == "image/jpg"){
                fileTypeMod = "Image"
            }else if(this.state.uploadIssuesArr[i].fileType == "application/pdf"){
                fileTypeMod = "Pdf"
            }else{
                fileTypeMod = this.state.uploadIssuesArr[i].fileType;
            }

            var obj={
                "fileType" : fileTypeMod,
			    "base64Str" : imageBase64
            }
            uploadIssueFormsArr.push(obj);

            console.log("uploadIssueFormsArr",uploadIssueFormsArr)

          
        }


        setTimeout(() => {
            this.setState({createIssueLoadingSymbol:"show"});
            const obj =  
                JSON.stringify({ 
                "chatId" :this.state.chatID,
                "issueTypeId" : this.state.issueType,
                "issueDesc" : this.state.issueDescription,
                "files" : uploadIssueFormsArr
                }); 

                getAPICallData(obj,"issue/createNewIssue")
                .then((res) => res.json())
                .then((data) => {
                this.setState({createIssueLoadingSymbol:"hide"});  
                    if(data !=null && data.status == "Success"){
                        this.setState({issueSuccMessage:data.message}); 
                        setTimeout(() => { 
                            this.setState({fileuploadCount:"",issueSuccMessage:"",issueDescription:"",issueTypeValue:"",issueType:"",uploadIssuesArr:[]}); 
                            this.setState({ camaraRadioButton:false,
                                uploadImageRadioButton:true,
                                selectedTypePhoto:"upload",
                                validateIssuesImage:"",validateIssueType:"",validateIssueDescription:"",validateFilesUploadImage:""});

                            this.getUserCreatedIssues();  
                        }, 1500);
                        
                    }else{
                        this.setState({issueSuccMessage:"Some Thing Wrong,Please Check once"});  
                    
                    }
                });    
        }, 500); 
      }
      

      getUserCreatedIssues = () => {
        this.setState({ issueDetailsArrCheck:0});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "issueStatusId" : this.state.issueStatusId,
            }); 

            getAPICallData(obj,"issue/getUserCreatedIssues")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.length>0){
                    this.setState({issueDetailsArr:data,allIssueDetailsArr:data,issueDetailsArrCheck:0}); 
                    
                }else{
                    this.setState({issueDetailsArr:[],issueDetailsArrCheck:1});  
                   
                }
            });
      }
      

      getStatusWiseIssuesShow = (issueStatusId) => {
            const issueTypeWiseArr = this.state.issueDetailsArr.filter(item => item.issueStatusId == issueStatusId);
            if(issueStatusId == 1){
                this.setState({issueDetailsArr:issueTypeWiseArr});
            }else{
                this.setState({issueDetailsArr:this.state.allIssueDetailsArr});
            }
            this.setState({ issueStatusId: issueStatusId});
      }

      actionsFormatterForStatus = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Status)} 
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Created Time : {moment(row.createdTime, "YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY hh:mm:ss")}</span></>

               
            </span>
         );
      }

      actionsFormatterForIssueUpdate = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {
                    row.issueStatusId == 1 ?
                    (<>

                        <OverlayTrigger key={"top"} placement={"top"}
                            overlay={
                                <Tooltip id={`tooltio-Filter1`}>
                                <h6 className='f-12'>Close To Issue</h6>
                                </Tooltip>
                            }
                            >
                            <span style={{textAlign:"left",marginLeft:"0px"}} className='pos_bottom_5 close_issue_style' onClick={() => this.closeIssueModal(row.issueId,row.Status)}>
                                <i class="fa-regular fa-circle-xmark"></i> Close Issue
                            </span>
                        </OverlayTrigger>
                        <br/>
                        <OverlayTrigger key={"top"} placement={"top"}
                            overlay={
                                <Tooltip id={`tooltio-Filter1`}>
                                <h6 className='f-12'>Add Comments</h6>
                                </Tooltip>
                            }
                            >
                            <span style={{textAlign:"left",marginLeft:"0px"}} className='pos_top_5 add_comments_style' onClick={() => this.addCommentsModal(row.issueId)}>
                                <i class="fa-solid fa-comment"></i> Comment
                            </span>
                        </OverlayTrigger>
                    
                    </>)
                    :
                    (<span className='cursor_pointer fw-bold color_red' >closed</span>)
                }
                
            </>
              
         );
      }


      actionsFormatterForDescription = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                 <OverlayTrigger key={"top"} placement={"top"}
                    overlay={
                        <Tooltip id={`tooltio-Filter3`}>
                        <h6 className='f-12'>show Description</h6>
                        </Tooltip>
                    }
                    >
                        <h6 style={{textAlign:"left",marginLeft:"0px"}}>
                    {
                        row.issueDesc !=null && row.issueDesc.length > 5 ?
                        (
                            <>
                            {<span  className='f-12 fw-normal' >{row.issueDesc.substring(0,5)}</span>}
                            <span style={{textAlign:"left",marginLeft:"0px"}} className='f-10 cursor_pointer  text-decoration-underline color_red' onClick={() => this.showIssueDescription(row.issueDesc)}> More...</span>
                            </>
                        )
                        :
                        (<span className='f-12 fw-normal' >{row.issueDesc}</span>)
                    }
                    </h6>
                </OverlayTrigger>

                <OverlayTrigger key={"top"} placement={"top"}
                    overlay={
                        <Tooltip id={`tooltio-Filter3`}>
                        <h6 className='f-12'>show Comments</h6>
                        </Tooltip>
                    }
                    >
                        <h6 style={{textAlign:"left",marginLeft:"0px"}}>
                        <span className='f-10 cursor_pointer text-decoration-underline' onClick={() => this.showCommentsModal(row.issueId)}> show Comment</span>
                    </h6>
                </OverlayTrigger>
                {
                    row.filesCount != 0 ?
                    (
                        <h6 style={{textAlign:"left",marginLeft:"0px"}}>
                            <span className='f-12 cursor_pointer text-decoration-underline' onClick={() => this.showUploadFilesModal(row.issueId)}>Files - {row.filesCount}</span>
                        </h6>
                    )
                    :
                    ("")
                }
                
            </>
              
         );
      }

      issuesTypeColumns() {

        let columns = [
            {
                dataField: "issue_Type",
                text: "Type",
                sort: true,
                
                
            },
            {
                dataField: "Status",
                text: "Status",
                formatter: this.actionsFormatterForStatus.bind(this),
                headerStyle: {
                    width: "25%"
                }
            },
            {
                dataField: "issueDesc",
                text: "Desc",
                formatter: this.actionsFormatterForDescription.bind(this),
                headerStyle: {
                    width: "32%"
                }
                
            },
            {
                dataField: "Actions",
                text: "Actions",
                formatter: this.actionsFormatterForIssueUpdate.bind(this),
                headerStyle: {
                    width: "28%"
                }
                
            },
        
        ];
        return columns;
    }

    issuesDisplayData = () => {
        let issuesData = [];
        if(this.state.issueDetailsArr.length>0){
            
            for (let i = 0; i < this.state.issueDetailsArr.length; i++) {

                const issueType = (this.state.issueDetailsArr[i].issueType);
                const statusName = (this.state.issueDetailsArr[i].statusName);
                const issueStatusId = (this.state.issueDetailsArr[i].issueStatusId);
                const createdTime = (this.state.issueDetailsArr[i].createdTime);
                const issueId = (this.state.issueDetailsArr[i].issueId);
                const issueDesc = (this.state.issueDetailsArr[i].issueDesc);
                const issueTypeId = (this.state.issueDetailsArr[i].issueTypeId);
                const filesCount = (this.state.issueDetailsArr[i].filesCount);
                
                
                const obj = {
                    issue_Type:issueType,
                    Status: statusName,
                    issueDesc: issueDesc,
                    issueStatusId: issueStatusId,
                    createdTime:createdTime,
                    issueId:issueId,
                    issueTypeId:issueTypeId,
                    filesCount:filesCount,
                    Actions:"Update"
                }
                issuesData.push(obj);
            } 

            
            
            return issuesData;
        }
    }

    showIssueDescription = (desc) => {
        this.setState({showIssueDescriptionModal:true,showFullIssueDes:desc});
    }
    closeIssueModal = (issueId,status) => {
        this.setState({changeStatusForIssuesModal:true,issueId:issueId,statusNameShow:status});
    }
    addCommentsModal = (issueId) => {
        this.setState({addCommentsForIssuesModal:true,issueId:issueId});
    }

    showCommentsModal = (issueId) => {
        this.setState({issueCommentsArr:[],issueCommentArrCheck:0})
        this.setState({viewCommentsModal:true,issueId:issueId});
        setTimeout(() => {
            this.getIssueComments();
        }, 1000);
        
    }

    showUploadFilesModal = (issueId) => {
        this.setState({uploadFilesShowModal:true});
        this.setState({issueFileDetailsCheck:0});
        const obj =  
            JSON.stringify({ 
            "issueId" :issueId
            }); 

            getAPICallData(obj,"issue/getIssueFileDetails")
            .then((res) => res.json())
            .then((json) => {
                if(json !=null && json.length>0){
                    this.setState({ issueFileDetailsArr:json,issueFileDetailsCheck:0});
                }else{
                     this.setState({issueFileDetailsArr:[],issueFileDetailsCheck:1});
                }
              
            });
    }

    getIssueComments = () => {
        this.setState({issueCommentArrCheck:0});
        const obj =  
            JSON.stringify({ 
            "issueId" :this.state.issueId
            }); 

            getAPICallData(obj,"issue/getIssueComments")
            .then((res) => res.json())
            .then((json) => {
                if(json !=null && json.length>0){
                    this.setState({ issueCommentsArr:json,issueCommentArrCheck:0});
                }else{
                     this.setState({issueCommentsArr:[],issueCommentArrCheck:1});
                }
              
            });
    }

    closeIssue = () => {
        this.setState({validateCloseIssueComment:"" });
        if(this.state.closeIssueComment == "" || this.state.closeIssueComment == 0){
            this.setState({validateCloseIssueComment:"Please Enter Comments" });
            return;
        }


        this.setState({changeStatusLodingSymbol:"show"});

        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "issueId" : this.state.issueId,
            "comment" : this.state.closeIssueComment
            }); 

            getAPICallData(obj,"issue/closeIssueByUser")
            .then((res) => res.json())
            .then((data) => {
              this.setState({changeStatusLodingSymbol:"hide"});  
                if(data !=null && data.status == "Success"){
                    this.setState({changeStatusMessage:"Issue Close SuccessFully...."}); 
                    setTimeout(() => { 
                        this.setState({changeStatusMessage:""}); 
                        this.setState({changeStatusForIssuesModal:false,issueId:""}); 
                        this.getUserCreatedIssues();
                    }, 1500);
                }else{
                    this.setState({changeStatusMessage:"Some Thing Wrong,Please Check once"});  
                   
                }
            });   
    }

    addCommentForIssues = () => {
        this.setState({validateIssueComment:"" });
        if(this.state.issueComment == "" || this.state.issueComment == 0){
            this.setState({validateIssueComment:"Please Enter Comments" });
            return;
        }


        this.setState({issueCommentLoadingSymbol:"show"});

        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "issueId" : this.state.issueId,
            "comment" : this.state.issueComment
            }); 

            getAPICallData(obj,"issue/addIssueComment")
            .then((res) => res.json())
            .then((data) => {
              this.setState({issueCommentLoadingSymbol:"hide"});  
                if(data !=null && data.status == "Success"){
                    this.setState({issueCommentMessage:"Comment Added SuccessFully...."}); 
                    setTimeout(() => { 
                        this.setState({issueCommentMessage:""}); 
                        this.setState({addCommentsForIssuesModal:false,issueId:""});
                        this.getUserCreatedIssues(); 
                    }, 1500);
                }else{
                    this.setState({issueCommentMessage:"Some Thing Wrong,Please Check once"});  
                   
                }
            });   
    }
    
    toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

    uploadMultipleFilesForIssues(e) { 
        this.setState({validateFilesUploadImage:""});
        let fileObj=[];
        fileObj.push(e.target.files);
        /*if(fileObj[0][0].type == "image/jpeg" || fileObj[0][0].type == "image/png"){
            for (let i = 0; i < fileObj[0].length; i++) {
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    

                    var obj ={
                        fileId:i,
                        fileName:fileObj[0][i].name,
                        fileType:fileObj[0][i].type,
                        data:dataUrl,
                    }
                    this.setState({uploadIssuesArr:[...this.state.uploadIssuesArr, obj]});
                });
            }
        }else{
            this.setState({validateFilesUploadImage:"Please Upload Image Files Only..."});
            
        }*/

         for (let i = 0; i < fileObj[0].length; i++) {
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    

                    var obj ={
                        fileId:i,
                        fileName:fileObj[0][i].name,
                        fileType:fileObj[0][i].type,
                        data:dataUrl,
                    }
                    this.setState({uploadIssuesArr:[...this.state.uploadIssuesArr, obj]});
                });
            }

           
      }

      
selectCaptureType = (type) => {
    this.setState({uploadIssuesArr:[]});

    if(type == "upload"){
      this.setState({ camaraRadioButton:false,uploadImageRadioButton:true});
      this.setState({showIssueImgCapture:false});
    }else{
      this.setState({showIssueImgCapture:true})
      this.setState({ camaraRadioButton:true,uploadImageRadioButton:false});
    }
    

    this.setState({selectedTypePhoto:type});
  }

     
switchCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "environment"}});
  }
frontCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "user"}});
}

removeCapturePhotoIssues = (fileId) => {
    var tempArr = this.state.uploadIssuesArr;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);
  
    if (objWithIdIndex > -1) {
      tempArr.splice(objWithIdIndex, 1);
    }
  
    this.setState({uploadIssuesArr:tempArr})
  
  }

    
  captureForIssueImageUpload = () => {
    this.setState({showIssueImgCapture:true,validateIssuesImage:""});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        fileName:"photo",
        fileType:"image/jpeg",
        data : this.state.webcamRef.current.getScreenshot(),
      }

      setTimeout(() => {
        this.setState({uploadIssuesArr:[...this.state.uploadIssuesArr, obj]}); 
        this.setState({validateIssuesImage:""});
    }, 1000);

};


    render() {
        console.log("issueDetailsArr",this.state.issueDetailsArr)
        return (
            <section>
                <Container fluid>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'> Raise An Issue</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className='mt-4'>
                            <div className='flex_programs_block pos_top_cls'>
                            <div className={this.state.createIssueBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.issuesBlockShow("create")}><h6 className='fw-bold  text-center'><i class="fa-solid fa-square-plus color_red"></i> <span className='ms-2'>Create Issue</span></h6></div>
                            <div className={this.state.issuesBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.issuesBlockShow("issues")}><h6 className='fw-bold text-center'><i class="fa-solid fa-gauge color_red"></i> <span className='ms-2'>My Issues</span></h6></div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className={this.state.createIssueBlock == true ? "show_block" : "hide_block"}>
                                
                                <Row>
                                    <Col>
                                        <Form.Group className="" controlId="formIssueTypeId">
                                            <Form.Label className='f-12 fw-bold'>Issue Type <span className='color_red f-9'>{this.state.validateIssueType}</span></Form.Label>
                                            <SelectBox
                                                stateData={this.state.issueTypeValue}
                                                handleChange={this.handleOnchangeSelectBox}
                                                name="issueType"
                                                options={this.state.issuesTypeArr}
                                                placeholderName={'Select Issue Type'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className='f-12 fw-bold mt-3'>Issue Description <span className='color_red f-9'>{this.state.validateIssueDescription}</span></Form.Label>
                                        <FloatingLabel
                                            controlId="issueDesId"
                                            label="Enter Issue Description"
                                            className="f-12"
                                            
                                        >
                                            <Form.Control as="textarea" placeholder="Enter Issue Description" name="issueDescription" value={this.state.issueDescription} className='height100px f-14' onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Form className="mt-3">
                                                <Form.Check
                                                    inline
                                                    label={"Photo Capture"}
                                                    name="image_capture_upload"
                                                    type="radio"
                                                    id="image_capture"
                                                    onChange={()=>this.selectCaptureType("camara")}
                                                    className="f-12 fw-bold"
                                                    checked={this.state.camaraRadioButton}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="File Upload"
                                                    name="image_capture_upload"
                                                    type="radio"
                                                    id="upload_file"
                                                    onChange={()=>this.selectCaptureType("upload")}
                                                    className="f-12 fw-bold"
                                                    checked={this.state.uploadImageRadioButton}
                                                />
                                        </Form>
                                    </Col>
                                </Row>

                                {
                                    this.state.uploadImageRadioButton == true ?
                                    (
                                        <>
                                        <label for="uploadfilesForIssues" class="drop-container">
                                            <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                            <input type="file" ref={this.fileupload} id="uploadfilesForIssues" value={this.state.fileuploadCount} className='f-14'  multiple onChange={this.uploadMultipleFilesForIssues}/>
                                        </label>
                                        <span className='color_red f-12 fw-bold'>{this.state.validateFilesUploadImage}</span>
                                        </>
                                    )
                                    :
                                    (
                                        <Row>
                                            <Col className="mt-2">
                                                    <h6 className="f-12 color_red">{this.state.validateIssuesImage}</h6>
                                                    <div className="showImgCaptureDivBSBG">{this.state.showIssueImgCapture && (
                                                    <> 
                                                    <Webcam
                                                        audio={false}
                                                        ref={this.state.webcamRef}
                                                        screenshotFormat="image/jpeg"
                                                        videoConstraints={this.state.videoConstraints}
                                                    /> 
                                                    
                                                    
                                                    <div className="row mt-2">
                                                        <div className="col">
                                                            <h6 onClick={() => this.captureForIssueImageUpload()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                                        </div>
                                                        <div className="col">
                                                            {
                                                            this.state.videoConstraints.facingMode == "user" ?
                                                            (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                                            :
                                                            ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                                            }
                                                        </div>
                                                    </div>
    
                                                    
                                                    </>
                                                    
                                                    )}
                                                    </div>
                                                </Col>
                                            </Row>
                                    )
                                }

                                {
                                    this.state.uploadIssuesArr.length>0 ?
                                    (
                                    <>
                                        <Row>
                                        <Col sm={12} className="mt-2">
                                            {
                                                this.state.selectedTypePhoto == "camara" ? 
                                                (<h6>Capture Photos : </h6>)
                                                :
                                                (<h6>Upload Images: </h6>)
                                            }
                                        
                                        </Col>
                                        <Col sm={12} className="">
                                            <div className='flex_img'>
                                                {(this.state.uploadIssuesArr || []).map((url,index) => (
                                                    <div className='ms-1 border_img_pad'>
                                                        <i class="fa-solid fa-circle-xmark" onClick={() => this.removeCapturePhotoIssues(url.fileId)}></i>
                                                        <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                    </div>
                                                ))} 
                                            </div>
                                        </Col>
                                    </Row>
                                    </>
                                    
                                    
                                    )
                                    :
                                    ("")
                                }
                               
                              

                                <Row>
                                    <Col>
                                        <Button variant="success" className='mt-4 f-14 fw-bold center-auto' onClick={() => this.saveCreateIssue()}>SUBMIT</Button>
                                        {
                                            this.state.createIssueLoadingSymbol === "show" ? (<Image src={loader} alt="loader" className='loader_cls_issue ms-2'></Image>) : ("")
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h6 className='f-14 fw-bold text-center mt-2'>{this.state.issueSuccMessage}</h6>
                                    </Col>
                                </Row>

                            </div>
                            <div className={this.state.issuesBlock == true ? "show_block" : "hide_block"}>
                                <Row>
                                    <Col sm={12} className='mt-2'>
                                        <ul className='list-inline eventStatusLiCls'>
                                            <li className={this.state.issueStatusId == 0 ? "active red" : ""} onClick={() => this.getStatusWiseIssuesShow(0)}>All Issues </li>
                                            <li className={this.state.issueStatusId == 1 ? "active yellow" : ""} onClick={() => this.getStatusWiseIssuesShow(1)}>Open Issues </li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            this.state.issueDetailsArr.length == 0 ?
                                            (
                                                this.state.issueDetailsArrCheck == 1 ?
                                                (<Col className='f-14 text-center'>No Issues Available</Col>)
                                                :
                                                (<Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                </Col>)
                                            )
                                            :
                                            (
                                            this.state.issueDetailsArr.length>0 ?
                                                (
                                                    <div className='bg_light_blue'>
                                                        <CardLessDataTableWithSearch 
                                                            parentData={this.issuesDisplayData()}
                                                            particlecolumns={this.issuesTypeColumns()}
                                                            tableHeading="Issue Details"
                                                            />
                                                            
                                                                
                                                        </div>
                                                )
                                                :
                                                ("")
                                            )
                                        }
                                    </Col>
                                </Row>


                                
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ChangeStatusForIssues 
                        show={this.state.changeStatusForIssuesModal}
                        onHide={() => this.setState({ changeStatusForIssuesModal: false })}
                        stateData={this.state}
                        handleOnchangeInputBox={this.handleOnchangeInputBox}
                        pcloseIssue={this.closeIssue}
                />

                <AddComments 
                        show={this.state.addCommentsForIssuesModal}
                        onHide={() => this.setState({ addCommentsForIssuesModal: false })}
                        stateData={this.state}
                        handleOnchangeInputBox={this.handleOnchangeInputBox}
                        paddCommentForIssues={this.addCommentForIssues}
                />
                <ViewIssueDescription 
                        show={this.state.showIssueDescriptionModal}
                        onHide={() => this.setState({ showIssueDescriptionModal: false })}
                        stateData={this.state}
                        title="Issue Description"
                       
                />
                <ViewCommentsForIssues 
                        show={this.state.viewCommentsModal}
                        onHide={() => this.setState({ viewCommentsModal: false })}
                        stateData={this.state}
                />
                <ShowUploadFiles 
                        show={this.state.uploadFilesShowModal}
                        onHide={() => this.setState({ uploadFilesShowModal: false })}
                        stateData={this.state}
                />
                
                
            </section>   
        )
    }
  }
  
  export default withRouter(RaiseAnIssueSection);
  