
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Figure,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "./socialMedia.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import TDPLOGOTEXTNEW from '../../images/tdp_logo_text_new.png';
import SOCIALMEDIALOGO from '../../images/social_media_logo.png';
import FBTDPOFFICIAL from '../../images/fb-tdpofficial.png';
import INSTDPOFFICIAL from '../../images/ins-tdpofficial.png';
import INTDPOFFICIAL from '../../images/in-tdpofficial.png';
import TWTDPOFFICIAL from '../../images/tw-tdpofficial.png';
import WHTSTDPOFFICIAL from '../../images/whts-tdpofficial.png';
import YOUTUBETDPOFFICIAL from '../../images/youtube-tdpofficial.png';

class SocialMediaSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }


   

  
    render() {
        return (
            <section>
                <div class="text-white pos_relative">
                    <img src={SOCIALMEDIALOGO} className="card-img center-auto opacity_05 img_height_shadow" alt="SOCIALMEDIALOGO"/>
                    <div class="card-img-overlay">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="flex-header mt-1">
                                    <Link to={{pathname: "/dashboard"}}> <i class="fa-solid fa-arrow-left color_black f-24 ms-4"></i></Link>
                                    <h6 className='color_black text-center mt-2 f-16 fw-bold ln-32 text_pos_sm'>Party Social Media Links  <br/>Click<br/>  Subscribe / Join</h6>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                <Container>
                
                    <Row>
                        <Col>
                            <div className='offical_block mt-3'>
                                <h6 className='f-14 fw-bold'>TDP Official Social media</h6>
                                <Row>
                                    <Col>
                                        <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`tdpoff-fb`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.facebook.com/TDP.Official" target="_blank" className='cursor_pointer'><Image src={FBTDPOFFICIAL} alt="FBTDPOFFICIAL" className='mt-2'></Image></a>
                                            </OverlayTrigger>
                                        
                                        {/*<Image src={INSTDPOFFICIAL} alt="INSTDPOFFICIAL" className='mt-2'></Image>*/}
                                        <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`tdpoff-inst`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.instagram.com/jai_tdp/?hl=en" target="_blank"><Image src={INSTDPOFFICIAL} alt="INSTDPOFFICIAL" className='mt-2'></Image></a>
                                            </OverlayTrigger>
                                        
                                    </Col>
                                    <Col>
                                        <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`tdpoff-tw`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://twitter.com/JaiTDP" target="_blank"><Image src={TWTDPOFFICIAL} alt="TWTDPOFFICIAL" className='mt-2'></Image></a>
                                            </OverlayTrigger>       
                                        
                                        {/*<Image src={WHTSTDPOFFICIAL} alt="WHTSTDPOFFICIAL" className='mt-2'></Image>*/}
                                        <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`tdpoff-yu`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.youtube.com/@TeluguDesamPartyOfficial" target="_blank"><Image src={YOUTUBETDPOFFICIAL} alt="YOUTUBETDPOFFICIAL" className='mt-2'></Image></a>
                                            </OverlayTrigger>
                                        
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <div className='bg_light_blue mt-3'>
                                <h6 className='f-14 fw-bold'>WhatsApp Channel Links - Join Now  </h6>
                                <Row>
                                    <Col>
                                          <div className='bg_white_pad'>
                                               <div className='row'>
                                                <div className='width_col_30'>
                                                    <Image src={require('../../images/cbn.png')} alt="cbn" className='img_round width_50 center-auto'></Image>
                                                </div>
                                                <div className='width_col_70'>
                                                    <h6 className='f-14 fw-bold mb-0'>Nara Chandrababu Naidu</h6>
                                                    <a className='f-12 fw-bold' href="https://whatsapp.com/channel/0029Va5eBczDJ6GxSzp6fS0m" target='_blank'>Click - Join & Share</a>     

                                                    
                                                </div>
                                               </div>
                                           </div>  
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                          <div className='bg_white_pad mt-2'>
                                               <div className='row'>
                                                <div className='width_col_30'>
                                                    <Image src={require('../../images/lokesh.jpg')} alt="lokesh" className='img_round width_50 center-auto'></Image>
                                                </div>
                                                <div className='width_col_70'>
                                                    <h6 className='f-14 fw-bold mb-0'>Lokesh Nara</h6>
                                                    <a className='f-12 fw-bold' href="https://whatsapp.com/channel/0029Va5UvEV1noz3MqTakF0P" target='_blank'>Click - Join & Share</a>
                                                </div>
                                               </div>
                                           </div>  
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                          <div className='bg_white_pad mt-2'>
                                               <div className='row'>
                                                <div className='width_col_30'>
                                                    <Image src={require('../../images/tdp_logo.png')} alt="logo" className='img_round width_50 center-auto'></Image>
                                                </div>
                                                <div className='width_col_70'>
                                                    <h6 className='f-14 fw-bold mb-0'>Telugu Desam Party</h6>
                                                    <a className='f-12 fw-bold' href="https://whatsapp.com/channel/0029Va5SGpdAInPrzY2d2l19" target='_blank'>Click - Join & Share</a>
                                                </div>
                                               </div>
                                           </div>  
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='bg_light_blue mt-3'>
                                <h6 className='f-14 fw-bold'>CBN Official - Social Media Links</h6>
                                <Row>
                                    <Col>
                                        <div className='flex-social-media-icons mt-2'>
                                        <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`cbnoff-tdp`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.facebook.com/tdp.ncbn.official" target="_blank"><i class="fa-brands fa-facebook fb-color" ></i></a>  
                                            </OverlayTrigger>
                                            <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`cbnoff-inst`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.instagram.com/ncbn.official/?hl=en" target="_blank"><i class="fa-brands fa-instagram inst-color" ></i></a>
                                            </OverlayTrigger>
                                            <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`cbnoff-nara`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.youtube.com/@NaraChandrababuNaiduofficial" target="_blank"><i class="fa-brands fa-youtube youtube-color"></i></a>
                                            </OverlayTrigger>
                                            <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`cbnoff-ncbn`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://twitter.com/ncbn" target="_blank"><i class="fa-brands fa-twitter tw-color" ></i></a>
                                            </OverlayTrigger>
                                            <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`cbnoff-link`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                           <a href="https://www.linkedin.com/today/author/ncbnofficial/" target="_blank"><i class="fa-brands fa-linkedin link-color" ></i></a>
                                            </OverlayTrigger>
                                            
                                            
                                            
                                            
                                        </div>
                                        
                                    </Col>
                                    
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='bg_light_blue mt-3'>
                                <h6 className='f-14 fw-bold'>Lokesh Nara Official - Social Media Links</h6>
                                <Row>
                                    <Col>
                                        <div className='flex-social-media-icons mt-2'>
                                        <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`lokesh-tdp`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.facebook.com/naralokesh" target="_blank"><i class="fa-brands fa-facebook fb-color" ></i></a>
                                            </OverlayTrigger>
                                            <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`lokesh-inst`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.instagram.com/naralokesh/?hl=en" target="_blank"><i class="fa-brands fa-instagram inst-color" ></i></a>
                                            </OverlayTrigger>
                                            <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`lokesh-yu`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://www.youtube.com/@naralokeshofficial" target="_blank"><i class="fa-brands fa-youtube youtube-color"></i></a>
                                            </OverlayTrigger>
                                            <OverlayTrigger key={"top"} placement={"top"}
                                                overlay={
                                                <Tooltip id={`lokesh-tw`}>
                                                    <h6 className='f-14'>Click & Subscribe</h6>
                                                </Tooltip>
                                                }
                                            >
                                            <a href="https://twitter.com/naralokesh" target="_blank"><i class="fa-brands fa-twitter tw-color" ></i></a>
                                            </OverlayTrigger>
                                            
                                            
                                            
                                            {/*<Link to="/dashboard"><i class="fa-brands fa-linkedin link-color" ></i></Link>
                                            <Link to="/dashboard"><i class="fa-brands fa-youtube youtube-color"></i></Link>*/}
                                        </div>
                                        
                                    </Col>
                                    
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            
            </section>
        )
    }
  }
  
  export default withRouter(SocialMediaSection);
  