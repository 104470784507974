import React, { Component } from 'react'
import { Modal, Button,Row,Col } from "react-bootstrap";

class YuvaGalamModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" centered className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'></Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                        <Row>
                            <Col>
                                <h6 className='fw-bold'>You are an UnEmployed Person Proceed for Registration.</h6>
                            </Col>
                        </Row> 
                        <Row>
                            <Col>
                                <div className='float-right'>
                                    <Button variant="success" onClick={() => this.props.proccedForRegistrationYG("yes")}>Yes</Button>
                                    <Button variant="danger" className='ms-4' onClick={() => this.props.proccedForRegistrationYG("no")}>No</Button>
                                </div>
                                
                            </Col>
                        </Row>    
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default YuvaGalamModal;
  

