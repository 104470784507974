import React, { Component } from 'react'
import { Modal,Row,Col,Button,Image } from "react-bootstrap";
import "./BoothAndHouseMapping.css";
import { getAPICallData } from '../../services/MemberService';
import loader from '../../images/loader.gif';

class UnLinkMemberFromHouseModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
           voterReasonList:[]
        }
      
    }

    
  componentDidMount() {
    const reasonsObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
        }
      ); 

      //getBooths
      getAPICallData(reasonsObj,"getVoterDeleteReasons")
          .then((res) => res.json())
          .then((data) => {
              this.setState({ voterReasonList: data.optionsList });
      });
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
                size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                    <Modal.Header closeButton className='p-3'>
                        <Modal.Title className='f-14 fw-bold'>Un Link User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                           <Row>
                                <Col sm={12}>
                                    <div className='bg_yash'>
                                        <h6>{this.props.stateData.voterDeletionDetails.boothNo}</h6>
                                        <h6 className='f-14'>{this.props.stateData.voterDeletionDetails.voterName} <span className='ms-4'>{this.props.stateData.voterDeletionDetails.age}/{this.props.stateData.voterDeletionDetails.gender}</span></h6>
                                    </div>
                                </Col>
                                <Col sm={12} className='mt-4'>
                                    <label className="form-label f-14 fw-bold">Reason For Link <span className='color_red f-12'>{this.props.stateData.validateReason}</span></label>
                                    <select id="VoterReason_id" name="VoterReasonId" className='form-select form-select-sm' onChange={(e) => this.props.phandleOnchangeSelectBox(e,"deletionReason")}>
                                        {
                                                this.state.voterReasonList.length>0  ? 
                                                (
                                                    <>
                                                    <option key="" value="">Select Reason</option>
                                                    {
                                                        this.state.voterReasonList.map((item,index) => {
                                                            return(
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                
                                                )
                                                :
                                                (<option key="" value="">Select Reason</option>)
                                                
                                            }
                                    </select>
                                </Col>
                                <Col sm={12} className='mt-4'>
                                    <Button type="button" className='btn btn-custom-cls center-auto' onClick={() => this.props.pUnLinkMemberFromHouse()}>UnLink Member From House</Button>
                                    {
                                        this.props.stateData.unLinkMemberloader === "show" ? (<Image src={loader} alt="loader" className='loader1_cls ms-2 float-end'></Image>) : ("")
                                    }
                                </Col>
                                {
                                    this.props.stateData.punLinkMemberStatusMsg != "" ?
                                    (
                                        <Col sm={12} className='mt-4'>
                                            <h5 className='text-center fw-bold'>{this.props.stateData.punLinkMemberStatusMsg}</h5>
                                        </Col>
                                    )
                                    :
                                    (
                                       ""
                                    )
                                }
                            </Row>  
                    </Modal.Body>
                </Modal>
            
        )
    }
}
export default UnLinkMemberFromHouseModal;
  

