import React, { Component } from 'react'
import { Container, Button,Row,Col,Card,Form } from "react-bootstrap";
import loader from '../../images/loader.gif';
import QRCode from "react-qr-code";
import { withRouter,Link } from "react-router-dom";
import { getAPICallDataForCadreSaving } from '../../services/MemberService';

class PendingRenewalDisplayCardDetails extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            englishSelected:props?.location?.params?.englishSelected == undefined ? true : this.props?.location?.params?.englishSelected,
            teleguSelected:props?.location?.params?.teleguSelected == undefined ? false : this.props?.location?.params?.teleguSelected,

            cardDisplayDataLoadingSymbol:"hide",
            cardDisplayDataObj:"",
            cardDisplayDataCheck:0,

            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            accessName:props?.location?.params?.accessName == undefined ? "" : props?.location?.params?.accessName,
            roleName:props?.location?.params?.roleName == undefined ? "" : props?.location?.params?.roleName,
            boothId:props?.location?.params?.boothId == undefined ? "" : props?.location?.params?.boothId,
        }
      
    }

    componentDidMount() {
       this.getCitVlorCPDetails();

    }

    getCitVlorCPDetails = () => {
        this.setState({cardDisplayDataLoadingSymbol:"show",cardDisplayDataObj:"",cardDisplayDataCheck:0});

        const obj =  
            JSON.stringify({ 
                "tcid" : this.props.location?.params?.tdpCadreStr,
                
            });
    
            getAPICallDataForCadreSaving(obj,"getCitVlorCPDetails")
            .then((res) => res.json())
            .then((content) => {
                this.setState({cardDisplayDataLoadingSymbol:"hide"})
                if(content !=null){
                    this.setState({cardDisplayDataObj:content,cardDisplayDataCheck:0});
                }else{
                    this.setState({cardDisplayDataObj:"",cardDisplayDataCheck:1});
                }
            });
    }

    typeOfLanguageSelectionNew = (e,languageType) => {
   
        if(languageType == "english"){
            this.setState({englishSelected:true,teleguSelected:false})
        }else{
            this.setState({englishSelected:false,teleguSelected:true})
        }
    }

    backToNewCadreEnrollPage = () => {
        this.props.history.push({
            pathname: "/pending_renewal_details",
            params: {
                navbarDisplay:"hide",
                userAccessList:this.state.userAccessList,
                accessName:this.state.accessName,
                roleName:this.state.roleName,
                boothId:this.state.boothId
                //userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList
              },
          });
    }
  
    render() {
        
        return (
                <section className="mb-4">
                    <div className="header_cadre_img"></div>

                <Container className="mt-2">
                <Row>
                        <Col sm={12}>
                            <Form>
                                <div className='float-end'>
                                <Form.Check
                                    inline
                                    label={`English Version`}
                                    name="languageSelectionNew"
                                    type="radio"
                                    id={`englishddVersionId`}
                                    onChange={(e)=>this.typeOfLanguageSelectionNew(e,"english")}
                                    className="f-12 fw-bold color_red cursor_pointer"
                                    value="english"
                                    checked={this.state.englishSelected}
                                />
                                <Form.Check
                                    inline
                                    label={`తెలుగు వెర్షన్`}
                                    name="languageSelectionNew"
                                    type="radio"
                                    id={`teleguddVersionId`}
                                    onChange={(e)=>this.typeOfLanguageSelectionNew(e,"telegu")}
                                    className="f-12 fw-bold color_red cursor_pointer"
                                    value="telegu"
                                    checked={this.state.teleguSelected}
                                />
                                </div>
                            </Form>
                                
                        </Col>
                    </Row>

                <Row>
                            <Col sm={12}>
                                
                                {
                                    this.state.cardDisplayDataLoadingSymbol == "show" ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    ("")
                                }


                                {
                                    this.state.cardDisplayDataObj != "" ?
                                    (
                                        
                                        <>
                                            <h6 className='text_center fw-bold f-14'>{this.state.englishSelected == true ? "Your Application submitted Successfully" : "సభ్యత్వం నమోదు అయినందుకు ధన్యవాదములు"}</h6>
                                            <h6 className='text_center mt-1 color_green fw-bold f-14'>{this.state.englishSelected == true ? "MemberShip No" : "సభ్యత్వం నెంబర్" } : <span className='f-18'>{this.state.cardDisplayDataObj.mid}</span></h6>
                            
                                            {/*<div className='htmlToImageVis' ref={this.myref}> 
                                                <Card className="text-white border-0 mt-2">
                                                    <Card.Img src={require('../../images/front_mc.jpeg')} alt="Card image"  />
                                                       
                                                    
                                                    <Card.ImgOverlay className=''>
                                                        <Row>
                                                            <Col>
                                                                <div className='qr_code_pos_card'>
                                                                    <QRCode
                                                                        size={60}
                                                                        viewBox={`0 0 200 200`}
                                                                        value={`${this.state.cardDisplayDataObj.mid}`}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className='flex-card'>
                                                                    <div>
                                                                        {
                                                                            this.state.cardDisplayDataObj.imageUrl == "" || this.state.cardDisplayDataObj.imageUrl == undefined ?
                                                                            (<Card.Img src={require('../../images/human.jpg')} alt="Card image" className="member_img"  />)
                                                                            :
                                                                            (<Card.Img src={this.state.cardDisplayDataObj.imageUrl} alt="Card image" className="member_img" onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src=require('../../images/human.jpg');}} />)
                                                                        }
                                                                        
                                                                    </div>
                                                                    <div>
                                                                        <div className='bg_red_card'>
                                                                            <Card.Title className='mb-1 fw-bold f-12'>{this.state.cardDisplayDataObj.mid}</Card.Title>
                                                                            {
                                                                                this.state.cardDisplayDataObj.membershipType == "General" ?
                                                                                (<Card.Title className='mb-0  fw-bold f-12'>2024-26</Card.Title>)
                                                                                :
                                                                                ("")
                                                                            }
                                                                            
                                                                        </div>
                                                                        <div className='mt-1 ml-2'>
                                                                            <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.cname}</Card.Title>
                                                                            {
                                                                                this.state.cardDisplayDataObj.villageName == "" && this.state.cardDisplayDataObj.mandalName == "" ?
                                                                                (
                                                                                    <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.townName} - మున్సిపాలిటీ </Card.Title>
                                                                                )
                                                                                :
                                                                                (
                                                                                    this.state.cardDisplayDataObj.townName == ""  ?
                                                                                    (
                                                                                        <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.villageName} - గ్రామం , {this.state.cardDisplayDataObj.mandalName} - మండలం</Card.Title>
                                                                                    )
                                                                                    :
                                                                                    ("")
                                                                                )
                                                                            }
                                                                            
                                                                            <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.constituencyName} - నియోజకవర్గం , {this.state.cardDisplayDataObj.districtName} - జిల్లా </Card.Title>
                                                                            <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.state.cardDisplayDataObj.stateName}</Card.Title>
                                                                        </div>
                                                                    </div>
                                                                    

                                                                    

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    
                                                        
                                                        
                                                    </Card.ImgOverlay>
                                                </Card>

                                                <Card className="text-white mt-2">
                                                    <Card.Img src={require('../../images/back_mc.jpeg')} alt="Card image"  />
                                                </Card>


                                                </div>*/}
                                            <div className='text-center'>
                                                <Row>
                                                    
                                                    <Col>
                                                        <Button variant="warning" className='mt-4 f-14 fw-bold color_white' onClick={() => this.backToNewCadreEnrollPage()}>Start New Enroll</Button>
                                                    </Col>
                                                </Row>

                        
                                            </div>
                                        </>
                                    )
                                    :
                                    (<>
                                        {
                                        this.state.cardDisplayDataCheck == 1 ?
                                        (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                        :
                                        ("")
                                    }
                                    </>)
                                }
                            
                            
                            
                                
                            </Col>
                        </Row>    
                </Container>
                
            </section>
            
        )
    }
}
export default withRouter(PendingRenewalDisplayCardDetails);
  

