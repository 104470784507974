import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import Webcam from "react-webcam";

class HouseVisitedImageCapture extends Component {
    constructor(props) {
        super(props)
        this.webcamRef = React.createRef();
        this.state = {
           
        }
      
    }

    
  
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="houseVisitCaptureCls">
                <Modal.Body className="">
                <Row>
                    <Col>
                        <h6 className='text-center fw-bold'>Take A Picture For House Visting</h6>
                    </Col>
                </Row>
                <Row>
                      <Col className="mt-2">
                        <div className="showImgCaptureDiv">{this.props.stateData.showImgCapture && (
                        <> 
                          <Webcam
                            audio={false}
                            ref={this.webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={this.props.stateData.videoConstraints}
                          /> 
                          
                          
                          <div className="row mt-2">
                              <div className="col">
                                <h6 onClick={() => this.props.captureForHouseVisit()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                              </div>
                              <div className="col">
                                {
                                  this.props.stateData.videoConstraints.facingMode == "user" ?
                                  (<h6 className="switch_camara text-center" onClick={() => this.props.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                  :
                                  ( <h6 className="switch_camara text-center" onClick={() => this.props.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                }
                              </div>
                          </div>
                          {
                            this.props.stateData.visitedSuccessMsg != "" ?
                            (<h6 className="f-14 fw-bold text-center color_green">{this.props.stateData.visitedSuccessMsg}</h6>)
                            :
                            ("")
                          }
                          </>
                        
                        )}
                        </div>
                      </Col>
                  </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default HouseVisitedImageCapture;
  

