import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import {Container, Row, Col,Image,Button,Card,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "./electionCampaign.css";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import HouseWiseVoterListModal from "./HouseWiseVoterListModal";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class ElectionCampaignSection extends Component {
  constructor(props) {
    super(props)

      this.state = {
        chatID: window.User !=undefined ? window.User.chatID : "943827154",
        activeCampaignListArr:[],
        campaignDates:"",
        campaignId:"",
        campaignValue:"",
        validateCampaignType:"",
        campaignName:"",
        campaignBoothsArr:[],
        campaignBoothsvalue:"",
        campaignBoothsId:"",
        validateCampaignBooth:"",
        campaignBoothsArrCheck:0,
        filterBlockOpenEC:false,
        allcampaignCondAll:true,
        visitedCampaignCondYes:false,
        pendingCampaignCondNo:false,
        isVisited:null,
        boothWiseHouseListArr:[],
        boothWiseHouseListCheck:0,
        boothWiseHouseListLoader:"hide",

        houseWiseVoterListArr:[],
        houseWiseVoterListArrCheck:0,
        houseWiseVoterListArrLoader:"hide",

        houseWiseVoterListModalShow:false,
        electionCampaignVoterIdsArr:'',
        electionCampaignHouseId:"",
        getCurrentLocationCoordinatesForEC:{},
        saveECHouseVisitLoadingSymbol:"hide",
        successMessage:"",

        allcheckboxSelected:""
      }
  }


  componentDidMount() {
        this.getActiveElectionCampaigns();
  }

  getActiveElectionCampaigns = () => {
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID
        }); 

        getAPICallData(obj,"D2dCampaign/getActiveElectionCampaigns")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){
                var arr=[];
                for(var i in  json){
                    var obj={'value':json[i].electionCampaignId,'label':json[i].campaignName,'attr_dates':json[i].startDate+" to "+json[i].endDate};
                    arr.push(obj)
                    
                }
                this.setState({ activeCampaignListArr:arr});
            }else{
                 this.setState({activeCampaignListArr:[]});
            }
          
        });
}

handleOnchangeSelectBox = (ename, evalue) =>{
    const name = ename;
    const value = evalue;

    if(name == "campaignId"){
        console.log("this.state.activeCampaignListArr",this.state.activeCampaignListArr)
      let getCampIdName = this.state.activeCampaignListArr.find((a) => a.value == value);
        this.setState({
            campaignValue: {
              value: getCampIdName.value,
              label: getCampIdName.label
          },campaignName:getCampIdName.label,campaignDates:getCampIdName.attr_dates
      });

      this.getElectionCampaignBooths(getCampIdName.value);
    }else if(name == "campaignBoothsId"){
        let getCampBoothIdName = this.state.campaignBoothsArr.find((a) => a.value == value);
        this.setState({
            campaignBoothsvalue: {
              value: getCampBoothIdName.value,
              label: getCampBoothIdName.label
          },
      });

    }

    this.setState({ [name]: value});
  }


  getElectionCampaignBooths = (campaignId) => {
    this.setState({ campaignBoothsArr:[],campaignBoothsArrCheck:0});
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "electionCampaignId" : campaignId
        }); 

        getAPICallData(obj,"D2dCampaign/getElectionCampaignBooths")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){
                var arr=[];
                for(var i in  json){
                    var obj={'value':json[i].id,'label':"Booth No : "+json[i].name};
                    arr.push(obj)
                    
                }
                this.setState({ campaignBoothsArr:arr,campaignBoothsArrCheck:0});
            }else{
                 this.setState({campaignBoothsArr:[],campaignBoothsArrCheck:1});
            }
          
        });
}

isFilterBlockOpenEC = () => {
    this.setState({filterBlockOpenEC:!this.state.filterBlockOpenEC });
}

checkParameterValueEC = (e,visitedType,visitedName) => {
    this.setState({isVisited:visitedType });
}

getHouseDetailsForECInABooth = () => {
    this.setState({validateCampaignBooth:""});
    if(this.state.campaignBoothsId == ""){
        this.setState({validateCampaignBooth:"Please Select Booth" });
        return;
    }

    this.setState({boothWiseHouseListArr:[],boothWiseHouseListCheck:0,boothWiseHouseListLoader:"show"});
    

    const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "boothId" : this.state.campaignBoothsId,
                "isVisited" : this.state.isVisited // Y/N/null
            });
        
        

        getAPICallData(obj,"D2dCampaign/getHouseDetailsForECInABooth")
            .then((res) => res.json())
            .then((content) => {
              this.setState({boothWiseHouseListLoader:"hide"});  
                if(content !=null && content.length>0){
                    
                    this.setState({boothWiseHouseListArr:content,boothWiseHouseListCheck:0});
                }else{
                    this.setState({boothWiseHouseListArr:[],boothWiseHouseListCheck:1});
                }
            });
}


houseListDisplayColumns() {
    let columns = [
        
        {
            dataField: "house_no",
            text: "House No",
            sort: true,
            formatter: this.actionsFormatterForECHouseNo.bind(this),
            headerStyle: {
                width: "15%"
            }
            
            
        },
        {
            dataField: "elder_person",
            text: "Elder Person",
           // sort: true,
            formatter: this.actionsFormatterForECElderPerson.bind(this),
            headerStyle: {
                width: "28%"
            }
            
            
        },
        {
            dataField: "elder_age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECElderAge.bind(this),
            
        },
       
        {
            dataField: "younger_person",
            text: "Younger Person",
            formatter: this.actionsFormatterForECYoungerPersopn.bind(this),
            headerStyle: {
                width: "28%"
            }
           
            
        },
        {
            dataField: "younger_age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECYoungerAge.bind(this),
        }
    
    ];
    return columns;
}

actionsFormatterForECElderPerson = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.elder_person)} 
            {/*<><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.elder_age)}</span></>*/}
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.elder_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.elder_age)}</span></>
        </span>
     );
    
}

actionsFormatterForECYoungerPersopn = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.younger_person)} 
           {/* <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.younger_age)}</span></>*/}
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.younger_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.younger_age)}</span></>
        </span>
     );
    
}

actionsFormatterForECElderAge = (cell, row, rowIndex, formatExtraData) => {
    return (row.elderVoterCardNo)
}
actionsFormatterForECYoungerAge = (cell, row, rowIndex, formatExtraData) => {
    return (row.youngerVoterCardNo)
}
actionsFormatterForECHouseNo = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            <span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold color_green text-decoration-underline' onClick={() => this.getVotersInAECHouse(row.house_id)}>{row.house_no}</span>
        </>
          
     );
}

houseListDisplayData = () =>{
    let houseData = [];
        if(this.state.boothWiseHouseListArr.length>0){
            
            for (let i = 0; i < this.state.boothWiseHouseListArr.length; i++) {

                const house_no = (this.state.boothWiseHouseListArr[i].houseNo);
                const elder_person = (this.state.boothWiseHouseListArr[i].elderName);
                const younger_person = (this.state.boothWiseHouseListArr[i].youngerName);
                const elder_age = (this.state.boothWiseHouseListArr[i].elderAge);
                const elder_gender = (this.state.boothWiseHouseListArr[i].elderGender);
                const younger_age = (this.state.boothWiseHouseListArr[i].youngerAge);
                const younger_gender = (this.state.boothWiseHouseListArr[i].youngerGender);
                const house_id = (this.state.boothWiseHouseListArr[i].electionCampaignHouseId);
                const booth_house_id = (this.state.boothWiseHouseListArr[i].boothHouseId);
                const elderVoterCardNo = (this.state.boothWiseHouseListArr[i].elderVoterCardNo);
                const youngerVoterCardNo = (this.state.boothWiseHouseListArr[i].youngerVoterCardNo);
                
                const obj = {
                    house_no:(house_no),
                    elder_person:elder_person,
                    younger_person: younger_person,
                    elder_age: elderVoterCardNo,
                    elder_gender: elder_gender,
                    younger_age:youngerVoterCardNo,
                    younger_gender:younger_gender,
                    house_id:house_id,
                    booth_house_id:booth_house_id,
                    elderVoterCardNo:elder_age,
                    youngerVoterCardNo:younger_age
                    
                }
                houseData.push(obj);
            } 

            
            
            return houseData;
        }
}
showLocationDVMappingPostions = (position) => {
    const getCoordinates =  
        { 
          "latitude" : position.coords.latitude,
          "longitude" : position.coords.longitude,
          "accuracy":position.coords.accuracy
        }
        
    this.setState({getCurrentLocationCoordinatesForEC:getCoordinates});
  }

  ShowLocationPermissionError = (error) => {
    switch (error.code){
        case error.PERMISSION_DENIED:
          alert("User Location Permission is Denied,Please Enable Location Services...(Path:Settings > Privacy > Location Services)");
          break;

          case error.POSITION_UNAVAILABLE:
          alert("User Postion Unavailable Denied");
          break;

          default:
            alert("An unknown error occurred")
    }
}

getVotersInAECHouse = (house_id) => {
    navigator.geolocation.getCurrentPosition(this.showLocationDVMappingPostions,this.ShowLocationPermissionError)
    this.setState({houseWiseVoterListModalShow:true});
    this.setState({houseWiseVoterListArr:[],houseWiseVoterListArrLoader:"show",houseWiseVoterListArrCheck:0,electionCampaignHouseId:"",electionCampaignVoterIdsArr:''});
    const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "electionCampaignHouseId" : house_id,
        });
        getAPICallData(obj,"D2dCampaign/getVotersInAECHouse")
            .then((res) => res.json())
            .then((content) => {
                this.setState({houseWiseVoterListArrLoader:"hide"});
                if(content !=null && content.length>0){
                    content.forEach(object => {
                        object.checked = false;
                      });

                    this.setState({houseWiseVoterListArr:content,houseWiseVoterListArrCheck:0,electionCampaignHouseId:house_id});
                }else{
                    this.setState({houseWiseVoterListArr:[],houseWiseVoterListArrCheck:1,electionCampaignHouseId:""});
                }
        });
}


checkAllCheckBoxsForVoters = (e) => {
    if (e.target.checked == true) {
        const modifiedEmployees = this.state.houseWiseVoterListArr.map(obj => {
            return { ...obj, checked: true };
        });
        const voterIds = this.state.houseWiseVoterListArr.map(item => item.electionCampaignVoterId);

        this.setState({houseWiseVoterListArr:modifiedEmployees,electionCampaignVoterIdsArr:voterIds,allcheckboxSelected:"all"});
    }else{
        const modifiedEmployees = this.state.houseWiseVoterListArr.map(obj => {
            return { ...obj, checked: false };
        });

        this.setState({houseWiseVoterListArr:modifiedEmployees,electionCampaignVoterIdsArr:'',allcheckboxSelected:''});
    }
}

getHouseValue = (e,campaignVoterId) => {
    if (e.target.checked == true) {
        const modifiedEmployees = this.state.houseWiseVoterListArr.map(obj => {
            if (obj.electionCampaignVoterId == campaignVoterId) {
                return { ...obj, checked: true };
            }
            return obj;
        });
        const voterIds = this.state.houseWiseVoterListArr.filter((item,index) => item.electionCampaignVoterId == campaignVoterId).map(item => item.electionCampaignVoterId);
        const data = [...this.state.electionCampaignVoterIdsArr, voterIds[0]];

        this.setState({houseWiseVoterListArr:modifiedEmployees,electionCampaignVoterIdsArr:data,allcheckboxSelected:''});
    }else{
        
        const modifiedEmployees = this.state.houseWiseVoterListArr.map(obj => {
            if (obj.electionCampaignVoterId == campaignVoterId) {
                return { ...obj, checked: false };
            }
            return obj;
        });

        this.setState(({electionCampaignVoterIdsArr}) => ({
            electionCampaignVoterIdsArr: electionCampaignVoterIdsArr.filter((element) =>  (campaignVoterId !== element))
        }));
        this.setState({houseWiseVoterListArr:modifiedEmployees,allcheckboxSelected:''});

    }
}

saveECHouseVisit = () => {
    this.setState({saveECHouseVisitLoadingSymbol:"show",successMessage:""});
    const obj =  
    JSON.stringify({ 
        "chatId" :this.state.chatID,
        "electionCampaignHouseId": this.state.electionCampaignHouseId,
        "latitude": this.state.getCurrentLocationCoordinatesForEC.latitude,
        "longitude": this.state.getCurrentLocationCoordinatesForEC.longitude,
        "electionCampaignVoterIds" : this.state.electionCampaignVoterIdsArr
    });
    getAPICallData(obj,"D2dCampaign/saveECHouseVisit")
        .then((res) => res.json())
        .then((content) => {
            this.setState({saveECHouseVisitLoadingSymbol:"hide"});
            if(content !=null && content.status == "Success"){
               
                this.setState({successMessage:"Updated Successfully..."});
                var list = this.state.houseWiseVoterListArr.map(el => {
                    var found = this.state.electionCampaignVoterIdsArr.find(s => s === el.electionCampaignVoterId);
                    if (found) {
                        return { ...el, isVisited:'Y' } 
                    }
                    return el;
                  });

                  if(this.state.allcheckboxSelected == "all"){
                    setTimeout(() => {   
                        this.setState({houseWiseVoterListModalShow:false,successMessage:"",houseWiseVoterListArr:list});
                       }, 1500);
                    
                   }else{
                    setTimeout(() => {   
                     this.setState({successMessage:"",electionCampaignVoterIdsArr:'',houseWiseVoterListArr:list});
                    }, 1500);
                   }
            }else{
                this.setState({successMessage:"Some Thing Went Wrong,Please Try Agin later..."});
            }
    });
}

  render() {
    console.log("houseWiseVoterListArr",this.state.electionCampaignVoterIdsArr)
    return (
      <section>
        <Container fluid>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'>Door To Door Campaign</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mt-4" controlId="formIssueTypeId">
                                <Form.Label className='f-12 fw-bold'>Election Campaign Type <span className='color_red f-9'>{this.state.validateCampaignType}</span></Form.Label>
                                <SelectBox
                                    stateData={this.state.campaignValue}
                                    handleChange={this.handleOnchangeSelectBox}
                                    name="campaignId"
                                    options={this.state.activeCampaignListArr}
                                    placeholderName={'Select Campaign Type'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {
                                this.state.campaignId !="" && this.state.campaignBoothsArr.length == 0 ?
                                (
                                    this.state.campaignBoothsArrCheck == 1 ?
                                    (<Col className='f-14 text-center mt-3'>Campaigns Booths Are Available</Col>)
                                    :
                                    (<Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>)
                                )
                                :
                                (
                                this.state.campaignBoothsArr.length>0 ?
                                    (
                                        <div className="mt-3 border_pad_ele">
                                            <Row>
                                                <Col>
                                                    <h6 className="f-12 fw-bold text-center text-uppercase">{this.state.campaignName} Details : 
                                                    {/*<br/><span className="color_red mt-3 ml-0 pos_below_align_ec">({this.state.campaignDates})</span>*/} </h6> 
                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='w_80 mt-3'>
                                                    <Form.Group className="" controlId="formIssueTypeId">
                                                        <Form.Label className='f-12 fw-bold'>Select Booth <span className='color_red f-9'>{this.state.validateCampaignBooth}</span></Form.Label>
                                                        <SelectBox
                                                            stateData={this.state.campaignBoothsvalue}
                                                            handleChange={this.handleOnchangeSelectBox}
                                                            name="campaignBoothsId"
                                                            options={this.state.campaignBoothsArr}
                                                            placeholderName={'Select Booth'}
                                                            dropdownIndicator={{ DropdownIndicator }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='w_20 mt-3'>
                                                    <OverlayTrigger key={"top"} placement={"top"}
                                                        overlay={
                                                            <Tooltip id={`tooltio-Filter`}>
                                                            <h6 className='f-14'>Show Filter</h6>
                                                            </Tooltip>
                                                        }
                                                        >
                                                        <h6 className='float-right cursor_pointer color_red filter_pos' onClick={() => this.isFilterBlockOpenEC()}><i class="fa-solid fa-filter f-28" ></i></h6>   
                                                    </OverlayTrigger>
                                                    
                                                </Col>
                                            </Row>
                                            {
                                                this.state.filterBlockOpenEC == true ? 
                                                (
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <h6 className='mt-3'>
                                                                    <span className='me-4'>
                                                                        <Form.Check
                                                                            inline
                                                                            label={"All"}
                                                                            name="electionCampign"
                                                                            type="radio"
                                                                            id="elecCampAllId"
                                                                            onChange={(e)=>this.checkParameterValueEC(e,null,'All')}
                                                                            className="color_black f-14 pos_name_align"
                                                                            defaultChecked={true}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label={"Visited"}
                                                                            name="electionCampign"
                                                                            type="radio"
                                                                            id="visitedId"
                                                                            onChange={(e)=>this.checkParameterValueEC(e,'Y','Visited')}
                                                                            className="color_black f-14 pos_name_align"
                                                                            defaultChecked={false}
                                                                        />
                                                                        
                                                                        <Form.Check
                                                                            inline
                                                                            label={"Pending"}
                                                                            name="electionCampign"
                                                                            type="radio"
                                                                            id="pendingId"
                                                                            onChange={(e)=>this.checkParameterValueEC(e,'N','Pending')}
                                                                            className="color_black f-14 pos_name_align"
                                                                            defaultChecked={false}
                                                                        />
                                                                    </span>
                                                                </h6>
                                                            </Col>
                                                        </Row>
                                                        

                                                        </>
                                                    ):("")
                                            }

                                            <Row>
                                                <Col>
                                                    <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getHouseDetailsForECInABooth()}>GET HOUSE DETAILS</Button> 
                                                </Col>
                                            </Row>

                                            {
                                                this.state.boothWiseHouseListLoader == "show" ?
                                                (
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                ("")
                                            }
                                            {
                                                this.state.boothWiseHouseListArr.length>0 ?
                                                (
                                                    
                                                    <>
                                                    <Row>
                                                        <Col sm={12}>
                                                        <h6 className="f-14 fw-bold mt-4">{this.state.campaignBoothsvalue.label} Voter Details : </h6>
                                                        <div className='bg_light_blue mt-3'>
                                                        <CardLessDataTableWithSearch 
                                                            parentData={this.houseListDisplayData()}
                                                            particlecolumns={this.houseListDisplayColumns()}
                                                            defaultSorted={[{
                                                                dataField: "house_no",
                                                                order: "asc"
                                                            }]}
                                                            tableHeading=""
                                                            />
                                                            
                                                                
                                                        </div>
                                                        
                                                        </Col>
                                                    </Row>
                                                    </>
                                                )
                                                :
                                                (<>
                                                    {
                                                    this.state.boothWiseHouseListCheck == 1 ?
                                                    (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                                    :
                                                    ("")
                                                }
                                                </>)
                                            }

                                        </div>
                                    )
                                    :
                                    ("")
                                )
                            }
                        </Col>
                    </Row>
                    <HouseWiseVoterListModal 
                        show={this.state.houseWiseVoterListModalShow}
                        onHide={() => this.setState({ houseWiseVoterListModalShow: false })}
                        stateData={this.state}
                        checkAllCheckBoxsForVoters={this.checkAllCheckBoxsForVoters}
                        getHouseValue={this.getHouseValue}
                        saveECHouseVisit={this.saveECHouseVisit}
                />
                    
        </Container>
        
    </section>
    )
  }
}

export default withRouter(ElectionCampaignSection);
