import React, { Component } from 'react'
import { Modal, Button,Row,Col,Image} from "react-bootstrap";
import "./EventsAndActivities.css";
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import moment from 'moment';

class CampaignAttendedUsers extends Component {
    

    constructor(props) {
        super(props)
        this.state = {
           
        }
        
      
    }

    campaignAttendedUserColumns() {

        let columns = [
            {
                dataField: "UserName",
                text: "UserName",
                sort: true,
                
            },
            {
                dataField: "Role",
                text: "Role",
                sort: true,
                
            },

            {
                dataField: "AttendedTime",
                text: "AttendedDate",
                sort: true,
               
            },
            
            
        ];
        return columns;
    }

    campaignAttendedUserData = () => {
        let userDtsArr = [];
        let userDetails = this.props?.stateData?.campaignAttendedUserDetails;
        if(userDetails.length>0){
            for (let i = 0; i < userDetails.length; i++) {
                const obj = {
                    UserName: userDetails[i].username,
                    Role: userDetails[i].role,
                    AttendedTime: moment(userDetails[i].attendedTime, "YYYY-MM-DD").format("DD-MM-YYYY")
                }
                userDtsArr.push(obj);
            }  

            
            
            return userDtsArr;
        }
    }
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Campaign Attended User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Row>
                        {
                            this.props?.stateData?.campaignAttendedUserDetails.length == 0 ?
                            (
                                this.props?.stateData?.campaignAttendedUserDetailsCheck == 1 ?
                                (<Col className='f-14 text-center'>No Attended User Available</Col>)
                                :
                                (<Col sm={12}>
                                  <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>)
                            )
                            :
                            (
                                <Col sm={12}>
                                   <CardLessDataTableWithSearch 
                                        parentData={this.campaignAttendedUserData()}
                                        particlecolumns={this.campaignAttendedUserColumns()}
                                        defaultSorted={[{
                                            dataField: "AttendedTime",
                                            order: "desc"
                                        }]}
                                        tableHeading=""
                                        />
                                 </Col>
                            )
                          }
                        </Row>
                   
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default CampaignAttendedUsers;
  

