import React, { Component } from 'react'
import { Modal, Button,Row,Col,Image} from "react-bootstrap";
import "./EventsAndActivities.css";
import loader from '../../images/loader.gif';


class UploadFeedBackForms extends Component {
    

    constructor(props) {
        super(props)
        this.state = {
           
        }
        
      
    }
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Upload FeedBack Forms</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Row>
                        <Col>
                        <div className="form-group">
                            <h6 className='f-12 color_red'>{this.props.stateData.validateUploadForms}</h6>
                            <label for="uploadfiles" class="drop-container">
                                <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                <input type="file" id="uploadfiles" className='f-14' onChange={this.props.puploadMultipleFiles} multiple/>
                            </label>

                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <div className='flex_img'>
                                {(this.props.stateData.file || []).map(url => (
                                    <div className='ms-2'>
                                        {/*<span className='remove_file'><i class="fa-solid fa-circle-xmark"></i></span>*/}
                                        <object type={url.fileType} data={url.data} alt="image" className='img_width_height' />
                                    </div>
                                ))} 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Button variant="danger" className="red_button_event center-auto mt-4" onClick={() => this.props.puploadFeedBackFormsFiles()}>Upload </Button>
                            {
                                this.props.stateData.fileUploaderLoaderImg == "show" ? 
                                (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                :
                                ("")
                            }
                            <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.props.stateData.fileUploadedStatusMsg}</h6>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default UploadFeedBackForms;
  

