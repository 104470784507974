import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,Card } from "react-bootstrap";
import loader from '../../images/loader.gif';


class Correct_WrongPersonModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
          
        }
      
    }

    
    render() {
        
        return (
            <Modal {...this.props} 
            size="sm"  centered aria-labelledby="example-modal-sizes-title-lg" className="z_index_modal">
                <Modal.Body className="">
                        {
                            this.props.stateData.saveLeaderSearchAnswerLoadingSymbol == "show" ?
                            (
                                <Row>
                                    <Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        }
                        {
                            this.props.stateData.saveLeaderSearchAnswerResonse == "Success" ?
                            (
                                <Row>
                                    <Col>
                                        {
                                            this.props.stateData.photoSelectedTDPCadreId == this.props.stateData.nameSelectedTDPCadreId  ?
                                            (<><h6 className='mt-2 f-14 text-center'>Your Selected Image and Name</h6> <h6 className='color_green fw-bold f-18 mt-2 text-center'>MATCHED</h6></>
                                            )
                                            :
                                            (<><h6 className='mt-2 f-14 text-center'>Your Selected Image and Name</h6> <h6 className='color_red fw-bold f-18 text-center mt-2'>NOT MATCHED</h6></>
                                            )
                                        }
                                        
                                    {
                                        this.props.stateData.photoSelectedTDPCadreId == this.props.stateData.nameSelectedTDPCadreId  ?
                                        (
                                            <Row className='justify-content-center mt-3'>
                                                <Col sm={4}>
                                                    <div className='tick_green_modal_css'><img src={this.props.stateData.correctPersonNameWithImage.imageUrl} className='width_height_img img-thumbnail border_thick center-auto' alt="..." /></div>
                                                    <h6 className='text-center mt-3 fw-bold f-16'>{this.props.stateData.nameSelectedNameWithImage.memberName}</h6>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            <>
                                            <Row className='justify-content-center text-center'>
                                                <Col sm={4}>
                                                    <Button variant="outline-warning" className='f-10  fw-bold  pad_5  mt-3' onClick={() => this.props.selectCorrectName()}> Select Correct Name Now</Button>
                                                    <Button variant="outline-success" className='f-10  fw-bold pad_5 mt-3 ms-2' onClick={() => this.props.showCorrectPersonInModal()}>Show Correct Name</Button> 
                                                </Col>
                                            </Row>

                                        
                                            {
                                                this.props.stateData.showCorrectPersonInModalBlock == true ?
                                                (
                                                    <Row className='justify-content-center mt-2'>
                                                        <Col sm={4}>
                                                            <Card>
                                                                <Card.Body>
                                                                    <h6 className='f-12'>Name : <b>{this.props.stateData.correctPersonNameWithImage.memberName}</b></h6>
                                                                    <h6 className='f-12 mt-2'>Designation : <b>{this.props.stateData.correctPersonNameWithImage.designation}</b></h6>
                                                                        <Row className='justify-content-center mt-3'>
                                                                            <Col sm={4}>
                                                                                <div className='tick_green_modal_correct_css'><img src={this.props.stateData.correctPersonNameWithImage.imageUrl} className='width_height_img1 img-thumbnail border_thick center-auto' alt="..." /></div>
                                                                            </Col>
                                                                        </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row> 
                                                )
                                                :
                                                ("")
                                            }
                                            </>
                                        )

                                    }
                                        
                                        <Button variant="dark" className='f-12 float-end mt-3 btn-sm' onClick={() => this.props.closePersonModal()}>Close</Button> 
                                    </Col>
                                </Row>
                            )
                            :
                            (
                                <Row>
                                    <Col>
                                        <h6 className='text-center'>{this.props.stateData.saveLeaderSearchAnswerResonse}</h6>
                                        <Button variant="dark" className='f-12 float-end mt-3 btn-sm' onClick={() => this.props.closePersonModal()}>Close</Button> 
                                    </Col>
                                </Row>
                            )
                        }
                      
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default Correct_WrongPersonModal;
  

