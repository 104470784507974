
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Button,Image,Card,Table,InputGroup} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif'; 
import './LeaderSearch.css';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { getAPICallDataForOnlyBSA } from '../../services/MemberService'

class LeaderDashboardSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            overAll:true,
            customDate:false,
            overAllFromDateLD:"2024-01-01",
            overAllToDateLD:moment().format("YYYY-MM-DD"),

            leadersearchYesterdayCountArr:[],
            leadersearchTodayCountArr:[],
            leadersearchCountArr:[],
            leadersearchCountCheck:0,
            leadersearchCountLoadingSymbol:"hide"
        }
    }
  
    componentDidMount() {
        this.getLeaderSearchDashboardCounts("2024-01-01",moment().format('YYYY-MM-DD'));
        this.getLeaderSearchDashboardCountsToday(moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'));
        this.getLeaderSearchDashboardCountsYesterday(moment().subtract(1, "days").format('YYYY-MM-DD'), moment().subtract(1, "days").format('YYYY-MM-DD'));
    }

    datesWiseCountsShow = (value) => {
        if(value == "overAll"){
            this.setState({overAll:true,customDate:false})
        }else{
            this.setState({overAll:false,customDate:true})
        }
    }

    goToSearchPage = () => {
        this.props.history.push({
            pathname: `/leader_search_page`,
            params: {
                navbarDisplay:"hide",
              }
        }); 
    }

    rangeForFormsLD =  {
        "Today": [moment(), moment()],
        "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "OverAll": [moment("20240101"), moment()],
      };

      handleEventForLD = (event, picker) => {
        this.setState({leadersearchCountArr:[],leadersearchCountCheck:0,pickerChosenLabel:picker.chosenLabel})
        console.log(event)
        console.log("start: ",  moment(picker.startDate._d).format('YYYY-MM-DD'));
        console.log("end: ",  moment(picker.endDate._d).format('YYYY-MM-DD'));

        if(picker.chosenLabel == "OverAll"){
            this.setState({ overAllFromDateLD: "2023-09-01",overAllToDateLD:moment().format('YYYY-MM-DD')});
            this.getLeaderSearchDashboardCounts("2024-01-01",moment().format('YYYY-MM-DD'));
        }else{
            this.setState({ overAllFromDateLD: moment(picker.startDate._d).format('YYYY-MM-DD'),overAllToDateLD:moment(picker.endDate._d).format('YYYY-MM-DD')});
            this.getLeaderSearchDashboardCounts(moment(picker.startDate._d).format('YYYY-MM-DD'),moment(picker.endDate._d).format('YYYY-MM-DD'));
        }

        

      };

      getLeaderSearchDashboardCounts = (fromDate,toDate) => {
        this.setState({leadersearchCountLoadingSymbol:"show"})
        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            "fromDate" : fromDate,
            "toDate" : toDate
        }); 

        getAPICallDataForOnlyBSA(obj,"getLeaderSearchDashboardCounts")
        .then((res) => res.json())
        .then((data) => {
            this.setState({leadersearchCountLoadingSymbol:"hide"})
            if(data !=null){
                this.setState({ leadersearchCountArr: data,leadersearchCountCheck:0 });

            }else{
                this.setState({ leadersearchCountArr:[],leadersearchCountCheck:1});
            }
        });
    }

    getLeaderSearchDashboardCountsToday = (fromDate,toDate) => {
        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            "fromDate" : fromDate,
            "toDate" : toDate
        }); 

        getAPICallDataForOnlyBSA(obj,"getLeaderSearchDashboardCounts")
        .then((res) => res.json())
        .then((data) => {
            if(data !=null){
                this.setState({ leadersearchTodayCountArr: data });

            }else{
                this.setState({ leadersearchTodayCountArr:[]});
            }
        });
    }

    getLeaderSearchDashboardCountsYesterday = (fromDate,toDate) => {
        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            "fromDate" : fromDate,
            "toDate" : toDate
        }); 

        getAPICallDataForOnlyBSA(obj,"getLeaderSearchDashboardCounts")
        .then((res) => res.json())
        .then((data) => {
            if(data !=null){
                this.setState({ leadersearchYesterdayCountArr: data });

            }else{
                this.setState({ leadersearchYesterdayCountArr:[]});
            }
        });
    }

    matchedRecordsDetails = (date,type) => {
        this.props.history.push({
            pathname: `/leader_matched_details`,
            params: {
                dateSelected:date,
                selectedType:type,
                navbarDisplay:"hide",
            },
        }); 
    }

    notMatchedRecordsDetails = (date,type) => {
        this.props.history.push({
            pathname: `/leader_notMatched_details`,
            params: {
                dateSelected:date,
                selectedType:type,
                navbarDisplay:"hide",
            },
        }); 
    }

    render() {
        return (
            <>
            <section className='leader_bg_color1'>
                <Container  className=''>
                    <Row>
                        <Col>
                            <img src={require('../../images/leadership-icon.png')} alt="leader-ship" className='w_80 center-auto mt-2'></img>
                            <h3 className='text-center color_white fw-bold font-style'>LEADERS SEARCH DASHBOARD</h3>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col sm={4}>
                            <Button variant="outline-light" className='f-12 mb-2 fw-bold float-right' onClick={() => this.goToSearchPage()}> Home <i class="fa-solid fa-house"></i> </Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                   
                    <Row className='justify-content-center'>
                        <Col sm={4} className='mt-3'>
                            <div className='flex_programs_block'>
                                <div className={this.state.overAll == true ? "border_bottom_my cusor_pointer" : "cusor_pointer"} onClick={() => this.datesWiseCountsShow("overAll")}><h6 className='fw-bold f-14'><i class="fa-solid fa-gauge"></i> <span className='ms-2'>Over All</span></h6></div>
                                <div className={this.state.customDate == true ? "border_bottom_other cusor_pointer" : "cusor_pointer"} onClick={() => this.datesWiseCountsShow("custom")}><h6 className='fw-bold f-14'><i class="fa-solid fa-calendar-week"></i> <span className='ms-2'>Custom Date Range</span></h6></div>
                            </div>
                        </Col>
                     </Row>
                     {
                        this.state.customDate == true ?
                        (
                            <>
                            <Row className='justify-content-center'>
                                <Col sm={4}>
                                <h6 className="form-label f-12 fw-bold mt-2">Date Range: </h6>
                                <DateRangePicker
                                         alwaysShowCalendars={true}
                                         ranges = {this.rangeForFormsLD}
                                         onEvent={this.handleEventForLD}
                                         className="w-100"
                                    >   
                                    <div class="input-group">
                                            <div class="input-group-prepend bgColorYash">
                                            <i class="fa fa-calendar pad_5_15"></i>
                                            </div>
                                            <input type="text" className="form-control" id="dateRangeFormsId" value={moment(this.state.overAllFromDateLD).format("YYYY-MM-DD") +" to "+ moment(this.state.overAllToDateLD).format("YYYY-MM-DD")} />
                                        </div>
                                    </DateRangePicker>
                                </Col>
                            </Row>

                           
                        </>
                        )
                        :
                        ("")
                     }

                        {
                                this.state.leadersearchCountLoadingSymbol == "show" ?
                                (
                                    <Row>
                                        <Col sm={12}>
                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }
                            {
                                this.state.leadersearchCountArr != "" ?
                                (
                                    
                                    <>
                                        <Row className='justify-content-center'>
                                            <Col sm={4}>
                                                <div className=''>
                                                    {
                                                        this.state.overAll == true ?
                                                        (<h6 className='f-12 fw-bold mt-2 mb-2'>OverAll :</h6>)
                                                        :
                                                        (<h6 className='f-14 fw-bold mt-4'>Date : {this.state.overAllFromDateLD} to {this.state.overAllToDateLD}: </h6>)
                                                    }
                                                    
                                                    <Row>
                                                        <Col>
                                                            <div className='border_yellow'>
                                                                <h6 className='f-12 text-center'>Played&nbsp;Imaged</h6>
                                                                <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>{this.state.leadersearchCountArr.total}</h6>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='border_yellow'>
                                                                <h6 className='f-12 text-center'>Matched</h6>
                                                                {
                                                                    this.state.overAll == true ? 
                                                                    ( 
                                                                        this.state.leadersearchCountArr.matched > 0 ?
                                                                        (<h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.matchedRecordsDetails("OverAll","Matched")}>{this.state.leadersearchCountArr.matched}</h6>)
                                                                        :
                                                                        (<h6 className='f-14 mt-2 text-center color_green fw-bold' >{this.state.leadersearchCountArr.matched}</h6>)
                                                                        
                                                                    )
                                                                    :
                                                                    (
                                                                        this.state.leadersearchCountArr.matched > 0 ?
                                                                        (<h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.matchedRecordsDetails(this.state.overAllFromDateLD+" to "+this.state.overAllToDateLD,"Matched")}>{this.state.leadersearchCountArr.matched}</h6>)
                                                                        :
                                                                        (<h6 className='f-14 mt-2 text-center color_green fw-bold' >{this.state.leadersearchCountArr.matched}</h6>)
                                                                        
                                                                    )
                                                                }
                                                                
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='border_yellow'>
                                                                <h6 className='f-12 text-center'>Not Matched</h6>
                                                                {
                                                                    this.state.overAll == true ? 
                                                                    ( 
                                                                        this.state.leadersearchCountArr.notMatched > 0 ?
                                                                        (<h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedRecordsDetails("OverAll","NotMatched")}>{this.state.leadersearchCountArr.notMatched}</h6>)
                                                                        :
                                                                        (<h6 className='f-14 mt-2 text-center color_red fw-bold' >{this.state.leadersearchCountArr.matched}</h6>)
                                                                        
                                                                    )
                                                                    :
                                                                    (
                                                                        this.state.leadersearchCountArr.notMatched > 0 ?
                                                                        (<h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedRecordsDetails(this.state.overAllFromDateLD+" to "+this.state.overAllToDateLD,"NotMatched")}>{this.state.leadersearchCountArr.notMatched}</h6>)
                                                                        :
                                                                        (<h6 className='f-14 mt-2 text-center color_red fw-bold' >{this.state.leadersearchCountArr.matched}</h6>)
                                                                        
                                                                    )
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.overAll == true && this.state.leadersearchTodayCountArr != "" ? 
                                            (
                                                <Row className='justify-content-center'>
                                                    <Col sm={4}>
                                                        <div className=''>
                                                            <h6 className='f-12 fw-bold mt-4 mb-2'>Today :</h6>
                                                            
                                                            <Row>
                                                                <Col>
                                                                    <div className='border_yellow'>
                                                                        <h6 className='f-12 text-center'>Played&nbsp;Imaged</h6>
                                                                        <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>{this.state.leadersearchTodayCountArr.total}</h6>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className='border_yellow'>
                                                                        <h6 className='f-12 text-center'>Matched</h6>
                                                                        {
                                                                            this.state.leadersearchTodayCountArr.matched > 0 ?
                                                                            (<h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.matchedRecordsDetails("Today","Matched")}>{this.state.leadersearchTodayCountArr.matched}</h6>)
                                                                            :
                                                                            (<h6 className='f-14 mt-2 text-center color_green fw-bold' >{this.state.leadersearchTodayCountArr.matched}</h6>)
                                                                        }
                                                                        
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className='border_yellow'>
                                                                        <h6 className='f-12 text-center'>Not Matched</h6>
                                                                        {
                                                                            this.state.leadersearchTodayCountArr.notMatched > 0 ?
                                                                            (<h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedRecordsDetails("Today","NotMatched")}>{this.state.leadersearchTodayCountArr.notMatched}</h6>)
                                                                            :
                                                                            (<h6 className='f-14 mt-2 text-center'>{this.state.leadersearchTodayCountArr.notMatched}</h6>)
                                                                        }
                                                                        
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ("")
                                        }

{
                                            this.state.overAll == true && this.state.leadersearchYesterdayCountArr != "" ? 
                                            (
                                                <Row className='justify-content-center'>
                                                    <Col sm={4}>
                                                        <div className=''>
                                                            <h6 className='f-12 fw-bold mt-4 mb-2'>Yesterday :</h6>
                                                            
                                                            <Row>
                                                                <Col>
                                                                    <div className='border_yellow'>
                                                                        <h6 className='f-12 text-center'>Played&nbsp;Imaged</h6>
                                                                        <h6 className='f-14 mt-2 text-center color_yellow fw-bold'>{this.state.leadersearchYesterdayCountArr.total}</h6>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className='border_yellow'>
                                                                        <h6 className='f-12 text-center'>Matched</h6>
                                                                        {
                                                                            this.state.leadersearchYesterdayCountArr.matched > 0 ?
                                                                            (<h6 className='f-14 mt-2 text-center color_green fw-bold text-decoration-underline cursor_pointer' onClick={() => this.matchedRecordsDetails("Yesterday","Matched")}>{this.state.leadersearchYesterdayCountArr.matched}</h6>)
                                                                            :
                                                                            (<h6 className='f-14 mt-2 text-center color_green fw-bold' >{this.state.leadersearchYesterdayCountArr.matched}</h6>)
                                                                        }
                                                                        
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className='border_yellow'>
                                                                        <h6 className='f-12 text-center'>Not Matched</h6>
                                                                        {
                                                                            this.state.leadersearchYesterdayCountArr.notMatched > 0 ?
                                                                            (<h6 className='f-14 mt-2 text-center color_red fw-bold text-decoration-underline cursor_pointer' onClick={() => this.notMatchedRecordsDetails("Yesterday","NotMatched")}>{this.state.leadersearchYesterdayCountArr.notMatched}</h6>)
                                                                            :
                                                                            (<h6 className='f-14 mt-2 text-center color_red fw-bold ' >{this.state.leadersearchYesterdayCountArr.notMatched}</h6>)
                                                                        }
                                                                        
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ("")
                                        }
                                    </>
                                )
                                :
                                (<>
                                    {
                                    this.state.leadersearchCountCheck == 1 ?
                                    (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                    :
                                    ("")
                                }
                                </>)
                            }
                </Container>
            </section>
            </>
        )
    }
  }
  
  export default withRouter(LeaderDashboardSection);
  