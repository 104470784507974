
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "../AttributesCollections/AttributesCollections";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import "../RaiseAnIssues/raiseAnIssue.css";

class MyTeamSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            accessName:props?.location?.params?.accessName == undefined ? "" : props?.location?.params?.accessName,
            roleName:props?.location?.params?.roleName == undefined ? "" : props?.location?.params?.roleName,
            roleId:props?.location?.params?.roleId == undefined ? "" : props?.location?.params?.roleId,

            getCubsInchargeDetailsArr:null,
            inchargeDetailsArr:null,

            clusterInchsrgeBlock:true,
            unitInchsrgeBlock:false,
            boothInchsrgeBlock:false,
            kssInchsrgeBlock:false,

            roleTypeViewBlock:""
        }
    }

    componentDidMount() {
        console.log("userAccessList",this.state.userAccessList)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            
            if(this.state.roleId == "1"){
                this.setState({roleTypeViewBlock:"cluster",clusterInchsrgeBlock:true})
                this.getCubsInchargeDetails(4,this.state.userAccessList.constituencyList[0].id);
            }else if(this.state.roleId == "2"){
                this.setState({roleTypeViewBlock:"unit",unitInchsrgeBlock:true})
                this.getCubsInchargeDetails(17,this.state.userAccessList.clusterList[0].id);
            }else if(this.state.roleId == "3"){
                this.setState({roleTypeViewBlock:"booth",boothInchsrgeBlock:true})
                this.getCubsInchargeDetails(18,this.state.userAccessList.unitList[0].id);
            }else if(this.state.roleId == "4"){
                this.setState({roleTypeViewBlock:"kss",kssInchsrgeBlock:true})
                this.getCubsInchargeDetails(9,this.state.userAccessList.boothList[0].id);
            }
            
            console.log("roleName",this.state.roleName)
      
        }

        
    }

    getMyTeamDetails = (typeName) => {
        this.setState({roleTypeViewBlock:typeName});

        if(typeName == "cluster"){
            this.setState({inchargeDetailsArr:this.state.getCubsInchargeDetailsArr.clusterInchargeList});
            this.setState({
                clusterInchsrgeBlock:true,
                unitInchsrgeBlock:false,
                boothInchsrgeBlock:false,
                kssInchsrgeBlock:false,
            });
        }else if(typeName == "unit"){
            this.setState({inchargeDetailsArr:this.state.getCubsInchargeDetailsArr.unitInchargeList})
            this.setState({
                clusterInchsrgeBlock:false,
                unitInchsrgeBlock:true,
                boothInchsrgeBlock:false,
                kssInchsrgeBlock:false,
            });
        }else if(typeName == "booth"){
            this.setState({inchargeDetailsArr:this.state.getCubsInchargeDetailsArr.boothInchargeList});
            this.setState({
                clusterInchsrgeBlock:false,
                unitInchsrgeBlock:false,
                boothInchsrgeBlock:true,
                kssInchsrgeBlock:false,
            });
        }else if(typeName == "kss"){
            this.setState({
                clusterInchsrgeBlock:false,
                unitInchsrgeBlock:false,
                boothInchsrgeBlock:false,
                kssInchsrgeBlock:true,
            });
            this.setState({inchargeDetailsArr:this.state.getCubsInchargeDetailsArr.kssInchargeList})
        }
    }

    getCubsInchargeDetails = (locationScopeId,locationId) => {
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "locationScopeId" : locationScopeId,
            "locationId" : locationId
            }); 
    
            getAPICallDataForOnlyBSA(obj,"getCubsInchargeDetails")
            .then((res) => res.json())
            .then((json) => {
                if(json !=null ){
                  this.setState({getCubsInchargeDetailsArr:json});

                if(this.state.roleId == "1"){
                    this.setState({inchargeDetailsArr:json.clusterInchargeList})
                }else if(this.state.roleId == "2"){
                    this.setState({inchargeDetailsArr:json.unitInchargeList})
                }else if(this.state.roleId == "3"){
                    this.setState({inchargeDetailsArr:json.boothInchargeList})
                }else if(this.state.roleId == "4"){
                    this.setState({inchargeDetailsArr:json.kssInchargeList})
                }

                }else{
                     this.setState({getCubsInchargeDetailsArr:[]});
                }
              
            });
    }

    inchargeDisplayColumns() {

        let columns = [
            {
                dataField: "location_name",
                text: this.state.roleTypeViewBlock.toUpperCase(),
                sort: true,
                formatter: this.actionsFormatterForNameSplitMyTeam.bind(this),
                
                
            },
            {
                dataField: "incharge_name",
                text: "INCHARGE NAME",
                sort: true,
            },
            {
                dataField: "gender",
                text: "GDR",
                sort: true,
                headerStyle: {
                    width: "10%"
                }
            },
            {
                dataField: "mobile_no",
                text: "MOBILE",
                sort: true,
                formatter: this.actionsFormatterForPhoneCalling.bind(this),
            },
           
        
        ];
        return columns;
    }

    actionsFormatterForNameSplitMyTeam = (cell, row, rowIndex, formatExtraData) => {

        var splitUnitName = row.location_name.split("_");
    
        return (
            <>
            {
                this.state.roleTypeViewBlock == "unit" ?
                (
                    <span style={{textAlign:"left",marginLeft:"0px"}}>
                        {
                            splitUnitName.map((item,index) => {
                                return(
                                    <span>
                                        {item }<br/>
                                    </span>
                                    
                                )
                                
                                    
                                
                            })
                        }
                    
                    </span>
                )
                :
                (
                    <span style={{textAlign:"left",marginLeft:"0px"}}>{row.location_name}</span>
                )
            }
            </>
            
        );
        
    }

    actionsFormatterForPhoneCalling = (cell, row, rowIndex, formatExtraData) => {

    
        return (
            <>
                <a href={`tel:+91${row.mobile_no}`} target='_blank'><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}><i class="fa-solid fa-phone"></i> {(row.mobile_no)}</span></a>
            </>
            
        );
        
    }

    inchargeDisplayData = () => {
        let dataArr = [];
        if(this.state.inchargeDetailsArr.length>0){
            
            for (let i = 0; i < this.state.inchargeDetailsArr.length; i++) {

                const location_name = (this.state.inchargeDetailsArr[i].locationName);
                const incharge_name = (this.state.inchargeDetailsArr[i].memberName);
                const gender = (this.state.inchargeDetailsArr[i].gender);
                const mobile_no = (this.state.inchargeDetailsArr[i].mobileNo);
                
                
                
                const obj = {
                    location_name:location_name,
                    incharge_name: incharge_name,
                    gender: gender,
                    mobile_no: mobile_no
                }
                dataArr.push(obj);
            } 

            
            
            return dataArr;
        }
    }

    
    render() {
       
        return (
            <section>
               
                <Container fluid>
                  
                    <Row>
                        <Col >
                            <div className='border-bottom-double'>
                                <h6 className='fw-bold text-center f-14'> {this.state.accessName}  Team Details</h6>
                            </div>
                            
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} className='mt-44'>
                            {
                                this.state.roleId == "1" ?
                                (
                                    <div className='flex_programs_block'>
                                        <div className={this.state.clusterInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("cluster")}><h6 className='fw-bold  text-center f-14'><span className='ms-2'>Cluster</span></h6></div>
                                        <div className={this.state.unitInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("unit")}><h6 className='fw-bold text-center f-14'><span className='ms-2'>Unit</span></h6></div>
                                        <div className={this.state.boothInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("booth")}><h6 className='fw-bold text-center f-14'><span className='ms-2'>Booth</span></h6></div>
                                        <div className={this.state.kssInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("kss")}><h6 className='fw-bold text-center f-14'><span className='ms-2'>KSS</span></h6></div>
                                    </div>
                                )
                                :
                                (
                                    this.state.roleId == "2" ?
                                    (
                                        <div className='flex_programs_block'>
                                            <div className={this.state.unitInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("unit")}><h6 className='fw-bold text-center f-14'><span className='ms-2'>Unit</span></h6></div>
                                            <div className={this.state.boothInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("booth")}><h6 className='fw-bold text-center f-14'><span className='ms-2'>Booth</span></h6></div>
                                            <div className={this.state.kssInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("kss")}><h6 className='fw-bold text-center f-14'><span className='ms-2'>KSS</span></h6></div>
                                        </div>
                                    )
                                    :
                                    (
                                        this.state.roleId == "3" ? 
                                        (
                                            <div className='flex_programs_block'>
                                                <div className={this.state.boothInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("booth")}><h6 className='fw-bold text-center f-14'><span className='ms-2'>Booth</span></h6></div>
                                                <div className={this.state.kssInchsrgeBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.getMyTeamDetails("kss")}><h6 className='fw-bold text-center f-14'><span className='ms-2'>KSS</span></h6></div>
                                            </div>
                                        )
                                        :
                                        (
                                            this.state.roleId == "4" ? 
                                            (
                                               ""
                                            
                                            )
                                            :
                                            ("")
                                        )
                                    )
                                )
                            }
                            
                        </Col>
                    </Row>

                    {
                                this.state.inchargeDetailsArr == null ?
                                (
                                    <Row>
                                        <Col sm={12}>
                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>
                                    </Row>
                                )
                                :
                                (
                                    this.state.inchargeDetailsArr.length == 0 ? 
                                    (
                                        <Row>
                                            <Col>
                                                <h6 className='f-14 text-center mt-4'></h6>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    (
                                        <>
                                        {
                                            this.state.inchargeDetailsArr !=null &&  this.state.inchargeDetailsArr.length > 0 ?
                                            (
                                                <Row>
                                                    <Col>
                                                        <div className='bg_light_blue mt-3'>
                                                            <CardLessDataTableWithSearch 
                                                                parentData={this.inchargeDisplayData()}
                                                                particlecolumns={this.inchargeDisplayColumns()}
                                                                tableHeading=""
                                                                />
                                                                
                                                                    
                                                            </div>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ("")
                                         }
                                        </>
                                    )
                                )
                        }
                 </Container>
            </section>      
        )
    }
  }
  
  export default withRouter(MyTeamSection);
  