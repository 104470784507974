
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import "../AttributesCollections/AttributesCollections";
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService'
import loader from '../../images/loader.gif';
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import UpdateFirstTimeVoters from './UpdateFirstTimeVoters';
import EnabledLocationPopup from '../Volunteer/EnabledLocationPopup';
import jwt_decode from "jwt-decode";
import { getAPICallDataForOnlyBSA } from '../../services/MemberService'
import VoterIdNotAvailableModal from './VoterIdNotAvailableModal';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class FirstTimeVoters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            newVoterAccessId:props?.location?.params?.newVoterAccessId == undefined ? "" : props?.location?.params?.newVoterAccessId,
            accessNameNavigation:localStorage.getItem('accessNameNavigation'),
            
            SearchconstituencyValue:"",
            volunteerSearchConstituencyName:"",
            volunteerSearchName:"",
            voterSerachArr:[],
            votersDataCheck:0,
            votersListLoader:"hide",
            volunteerSearchVoterCardNo:"",
            ap_listArr:[],
            voterId:"",

            volunteerSearchBoothName:"",
            SearchBoothValue:"",
            searchOptionBoothListArr:[],
            boothsLoadingSymbol:"hide",
           

            party_ids_Arr:[{'value':872,'label':'TDP'},{'value':1117,'label':'YSRC'},{'value':1892,'label':'JSP'},{'value':163,'label':'BJP'},{'value':1887,'label':'Others'}],
            partyIdStr:"",
            partyIdStrValue:"",

            influencerName:"",
            volunteerMobile:"",
            validateVolunteerMobile:"",

            saveInfLoadingSymbol:"hide",
            successMsgInf:"",

            validatePartyIdStr:"",

            firstTimeVotersModalShow:false,

            checkingLocationServiceEnabledObj:{},
            checkingEnabledLocationModal:false,
            delaylocationModal:false,
            searchNameValue:"",
            voterFilterKeyValue:"",

            profession_ids_Arr:[{'value':7,'label':'Student'},{'value':25,'label':'Employed'},{'value':9,'label':'Unemployed'},{'value':8,'label':'Self-Employed'},{'value':24,'label':'Others'}],
            professionIdStr:"",
            professionIdStrValue:"",

            validateVolunteerOTP:"",
            volunteerOTPNO:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",
            mobileNoDisabled:false,
            disabledButtonBG:false,

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",
            
            minutes:1,
            seconds:0,
            validateProfessionIdStr:"",

            allConstituencyValue:"",
            allConstituencyName:"",
            ac_listArr:[],

            VoterName:"",
            ValidateVoterName:"",
            VoterRelativeName:"",
            ValidateVoterRelativeName:"",
            VoterReference:"",
            ValidateVoterReference:"",
            VoterGender:"",
            validateVoterGender:"",
            VoterMobileNo:"",
            validateVolunteerMobile:"",
            disabledBtnVoterEnroll:false,
            successMsgVN:"",
            saveNALoadingSymbol:"hide",

            voterIdNotAvailableModal:false
        }
        this.handleSearchNameChange = this.handleSearchNameChange.bind(this);
    }

    componentDidMount() {
        console.log("userAccessList",this.state.userAccessList)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            if(this.state.chatID != "1048868711"){
                if(navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(this.checkingLocationServiceEnabled,this.checkingLocationServiceEnabledError)
                }else{
                   <EnabledLocationPopup 
                        show={() => this.setState({ checkingEnabledLocationModal: true })}
                        onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                        closeEnabledModal={this.closeEnabledModal}
                    />
                }
                setTimeout(() => this.setState({delaylocationModal:true}), 3000);
            }

            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
                
                
            }
           
            this.setState({ ap_listArr:consArr });
            this.setState({
                SearchconstituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },volunteerSearchConstituencyName:this.state.userAccessList.constituencyList[0].id
        });

        /*if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
            let boothListArr=[];
            this.state.userAccessList.boothList.map(item => {
                const obj = {
                    value: item.id,
                    label: "Booth No -"+item.name,
                }
                boothListArr.push(obj);
                
            });
            this.setState({ searchOptionBoothListArr: boothListArr });
            
            
        }*/

        this.getAssemblyConstituencies();
        this.serchOptionGetBooths(this.state.userAccessList.constituencyList[0].id);

            
        }

        
    }

    getAssemblyConstituencies = () => {
        this.setState({consLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            
        }); 

        getAPICallDataForOnlyBSA(obj,"getAssemblyConstituencies")
        .then((res) => res.json())
        .then((data) => {
            this.setState({consLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ ac_listArr: parlArr });

            let getConsIdName = this.state.ac_listArr.find((a) => a.value == this.state.SearchconstituencyValue.value);
                this.setState({
                    allConstituencyValue: {
                    value: getConsIdName.value,
                    label: getConsIdName.label
                },allConstituencyName:getConsIdName.value
            });

          }else{
            this.setState({ ac_listArr:[]});
          }
        });
    }

    serchOptionGetBooths = (value) => {
        this.setState({ searchOptionBoothListArr: [],boothsLoadingSymbol:"show"});
        this.setState({volunteerSearchBoothName:"",
        SearchBoothValue:""})
    
        const obj =  
        JSON.stringify({ 
            "chatId" : this.state.chatID,
            "constituencyId" : value
           
        });
    
    

        getAPICallDataForOnlyBSA(obj,"getBoothsByConstituencyId")
          .then((res) => res.json())
          .then((json) => {
            this.setState({ boothsLoadingSymbol:"hide"});
              if(json !=null && json.length>0){
                var boothArr=[];
                for(var i in  json){
                    var obj={'value':json[i].id,'label':"Booth-"+json[i].name};
                    boothArr.push(obj)
                    
                }
               
                this.setState({ searchOptionBoothListArr:boothArr });
              }
            
          });
      }

    handleSearchNameChange = (event) => {
        this.setState({
            searchNameValue: event.target.value
        })
    }

    closeEnabledModal = () => {
        this.props.history.push({
            pathname: "/first_time_voter_search",
            params: {
                userAccessList:this.state.userAccessList,
            
            },
        });
    }

    checkingLocationServiceEnabled = (position) => {
        const getCoordinates =  
            { 
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude,
            "accuracy":position.coords.accuracy
            }
            
        this.setState({checkingLocationServiceEnabledObj:getCoordinates});
    }

    checkingLocationServiceEnabledError = (error) => {
      return(
        <EnabledLocationPopup 
                show={() => this.setState({ checkingEnabledLocationModal: true })}
                onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                closeEnabledModal={this.closeEnabledModal}
         />
      )
        
    }

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({validateVolunteerMobile:"",validateVolunteerOTP:"" });

        if(name == "volunteerSearchName"){
            if(value.length >= 3){
                //this.getVotersDisplayForVoterSerch();
            }
            
        }else if(name == "volunteerMobile"){
            if(value.length >= 10){
                /*if(value == "9948029998" || value == "8106184388" || value == "9652244900" || value == "9966542524" || value == "9666519677" || value == "8919278211" || value == "9948998953"){
                    this.setState({mobileNoSuccessMsg:"SUCCESS"});
                }else{
                    this.checkMobileNumberAlreadyExistForNewVoter(value);
                }*/

                this.checkMobileNumberAlreadyExistForNewVoter(value);
                
            }
        }else if(name == "volunteerOTPNO"){
            this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
            if(value.length >= 6){
                this.validateMobileOtp(value);
               
            }
            
        }

        this.setState({ [name]: value });
    }

    checkMobileNumberAlreadyExistForNewVoter = (value) => {
        this.setState({mobileNoLoadingSymbol:"show",mobileNoSuccessMsg:"",mobileNoSuccessResponseMsg:""});

        const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : value,
           
        });
    
    

    getAPICallData(obj,"checkMobileNumberAlreadyExistForNewVoter")
        .then((res) => res.json())
        .then((content) => {
          this.setState({mobileNoLoadingSymbol:"hide"});  
            if(content !=null && content.status == "Success"){
                this.setState({mobileNoSuccessMsg:"SUCCESS",mobileNoSuccessResponseMsg:content.status});
            }else{
                this.setState({mobileNoSuccessMsg:"FAIL",mobileNoSuccessResponseMsg:content.status});
            }
        });


       
      }


      getMobileNoOTP = () => {
        this.setState({validateVolunteerMobile:"" });
        var expr = /^(0|91)?[6-9][0-9]{9}$/;

        if(this.state.volunteerMobile == ""){
            this.setState({validateVolunteerMobile:"Please Enter Mobile No" });
            return;
        }

        if(!expr.test(this.state.volunteerMobile)){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
          }

        if(this.state.volunteerMobile.length < 10){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
        }


        this.setState({smsSentMessage:"",getOTPLoadingSymbol:"show",OTPSentStatus:"",otpVerifiedValue:0});
        
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.volunteerMobile,
            
            }); 

            getAPICallData(obj,"sendOtpToNewVoterMobileNumber")
            .then((res) => res.json())
            .then((data) => {
              this.setState({getOTPLoadingSymbol:"hide"});
              if(data !=null && data.status == "Success"){
                console.log(jwt_decode(data.reference).sub)
                this.setState({smsSentMessage:"OTP Sent Your Mobile No",OTPSentStatus:data.status,otpVerifiedValue:jwt_decode(data.reference).sub});
                this.setState({mobileNoDisabled:true})
                this.OTPTimerFunction();
              }else{
                this.setState({smsSentMessage:"Some Thing Went Wrong,Please Try Again...",OTPSentStatus:"Fail",otpVerifiedValue:0});
                this.setState({mobileNoDisabled:false})
              }
            });
    }
    
    resendOTPTimer = () => {
        this.setState({minutes:1});
        this.setState({seconds:0});

        this.OTPTimerFunction();
      };

    OTPTimerFunction = () => {
        const interval = setInterval(() => {
            if (this.state.seconds > 0) {
              this.setState({seconds:this.state.seconds - 1});
            }
      
            if (this.state.seconds === 0) {
              if (this.state.minutes === 0) {
                clearInterval(interval);
              } else {
                this.setState({seconds:59});
                this.setState({minutes:this.state.minutes - 1});

              }
            }
          }, 1000);
      
          return () => {
            clearInterval(interval);
          };
    }

    resendOTPNew = () => {
        this.setState({validateVolunteerMobile:"" });
        if(this.state.volunteerMobile == ""){
            this.setState({validateVolunteerMobile:"Please Enter Mobile No" });
            return;
        }
        var expr = /^(0|91)?[6-9][0-9]{9}$/;
        if(!expr.test(this.state.volunteerMobile)){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
          }

        

        if(this.state.volunteerMobile.length < 10){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
        }

        this.setState({resendOTPLoadingSymbol:"show",otpVerifiedValue:0});
       
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.volunteerMobile,
            
            }); 

            getAPICallData(obj,"sendOtpToMobileNumber")
            .then((res) => res.json())
            .then((data) => {
              this.setState({resendOTPLoadingSymbol:"hide"});
              if(data !=null && data.status == "Success"){
                console.log(jwt_decode(data.reference).sub)
                this.setState({otpVerifiedValue:jwt_decode(data.reference).sub});
                this.resendOTPTimer();
              }else{
                this.setState({otpVerifiedValue:0});
              }
            });
        
    }

    validateMobileOtp = (otp) => {
        this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.volunteerMobile,
            "otp" : otp
            

            }); 

            getAPICallData(obj,"validateMobileOtp")
            .then((res) => res.json())
            .then((data) => {
               this.setState({otpLoadingSymbol:"hide"});
               if(data !=null && data.status == "Success"){
                this.setState({otpVerifiedMessageStatus:"Verified"});
              }else{
                this.setState({otpVerifiedMessageStatus:"NotVerified"});
              }
            });
    }

  handleOnchangeSelectBoxNew1 = (ename, evalue) =>{
    const name = ename;
    const value = evalue;
    this.setState({validateProfessionIdStr:"",validatePartyIdStr:"" });

    if(name == "allConstituencyName"){
        this.setState({voterSerachArr:[],votersDataCheck:0,votersListLoader:"hide",validateSearchByBoothNo:""});
        let getConsIdName = this.state.ac_listArr.find((a) => a.value == value);
            this.setState({
                allConstituencyValue: {
                value: getConsIdName.value,
                label: getConsIdName.label
            }
        });
        this.serchOptionGetBooths(value);
    }else if(name == "volunteerSearchBoothName"){
        let getBoothIdName = this.state.searchOptionBoothListArr.find((a) => a.value == value);
        this.setState({
            SearchBoothValue: {
            value: getBoothIdName.value,
            label: getBoothIdName.label
        }
    });

       
    }else if(name == "professionIdStr"){
        let getProfessIdName = this.state.profession_ids_Arr.find((a) => a.value == value);
        this.setState({
            professionIdStrValue: {
            value: getProfessIdName.value,
            label: getProfessIdName.label
        }
    });

       
    }
      

    this.setState({ [name]: value});
  }
    
      getVotersDisplayForVoterSerch = () => {
        this.setState({voterSerachArr:[],votersDataCheck:0,votersListLoader:"hide",validateSearchByBoothNo:""});
        /*if(this.state.volunteerSearchBoothName == ""){
            this.setState({validateSearchByBoothNo:"Select Booth" });
            return;
        }*/

          const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "constituencyId" : this.state.allConstituencyValue.value,
                "voterName":this.state.volunteerSearchName,
                "voterCardNo":this.state.volunteerSearchVoterCardNo,
                "isNewVoter":'Y',
                "boothId":this.state.volunteerSearchBoothName,
                
            });
        
        
        this.setState({votersListLoader:"show"});

        getAPICallData(obj,"getVoterDetailsBySearch")
            .then((res) => res.json())
            .then((content) => {
              this.setState({votersListLoader:"hide"});  
                if(content !=null && content.length>0){
                    if(this.state.voterFilterKeyValue == ""){
                        this.setState({voterSerachArr:content,votersDataCheck:1});
                    }else if(this.state.voterFilterKeyValue != ""){
                        const dataArr = content.filter(item => item.isNewVoter == this.state.voterFilterKeyValue);
                        this.setState({voterSerachArr:dataArr,votersDataCheck:1});
                    }
                   
                }else{
                    this.setState({voterSerachArr:[],votersDataCheck:0});
                }
            });
      }

      getFilterWiseFirstTimeVoters = (e,value) => {
        this.setState({voterFilterKeyValue:value})
        
      }

      sortFuncSerialNo(a, b, order, dataField, rowA, rowB)  { 
        if (order == 'desc') {
            
            return parseInt(rowB.Serial_No)  > parseInt(rowA.Serial_No) ? 1 : -1;

        } else {
            return parseInt(rowA.Serial_No)  > parseInt(rowB.Serial_No) ? 1 : -1;
        }
    }
   

    actionsFormatterForVoterNameAtt = (cell, row, rowIndex, formatExtraData) => {
        const voter_Card = this.state.voterSerachArr.filter((item,index) => (item.voterId) == row.voterId).map(blist => blist.voterCardNo);

      
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.voter_Name)} 
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>V - {(voter_Card[0])}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>HouseNo - {(row.Gender)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>RN - {(row.relectiveName)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Relation - {(row.relation)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Mandal/Town - {(row.town == "" ? row.mandal : row.town)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Panchayat - {(row.panchayat)}</span></>

                <>
                    <h6 className='f-12 text-center mt-2' >
                        Party - {(row.party) != 0 ? <b className='color_green'>{row.party}</b> : <b className='color_red'>Not Updated</b>},
                    </h6>
                    <h6 className='f-12 text-center' >
                        Mobile - {(row.mobileNo) != '' ? <b className='color_green'>{row.mobileNo}</b> : <b className='color_red'>Not Updated</b>},
                    </h6>
                </>
               

            </span>
         );
        
    }

   

    actionsFormatterForFirstTimeVoters = (cell, row, rowIndex, formatExtraData) => {
        return (
            row.isNewVoter == "N" ?
            (<span className='cursor_pointer f-14 color_red ' style={{textAlign:"left",marginLeft:"0px"}} onClick={() => this.saveFirstTimeVotersModal(row.voterId,row.voter_Name)} ><i class="fa-solid fa-pen-to-square"></i> </span>)
            :
            (<span className='cursor_pointer f-10 color_green fw-bold' style={{textAlign:"left",marginLeft:"0px"}}  >Updated </span>)
            
                
           
         );
    }

    
   

      

    firstTimevotersDisplayColumns() {
        let columns = [
            
            
            {
                dataField: "Serial_No",
                text: "S:No",
                sort: true,
                sortFunc: this.sortFuncSerialNo.bind(this),
                headerStyle: {
                    width: "15%"
                }
                
                
            },
            {
                dataField: "voter_Name",
                text: "Name",
                formatter: this.actionsFormatterForVoterNameAtt.bind(this),
                headerStyle: {
                    width: "45%"
                }
                
            },
            {
                dataField: "Age",
                text: "A",
                sort: true,
                headerStyle: {
                    width: "10%"
                }
                
            },

            {
                dataField: "Gender",
                text: "G",
                sort: true,
                headerStyle: {
                    width: "10%"
                }
            
            },

            {
                dataField: "Add_influencer",
                text: "A/c",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionsFormatterForFirstTimeVoters.bind(this),
            
            }
        
        ];
        return columns;
    }

    

    votersDisplayData = (data) => {
        let votersData = [];
        if(data.length>0){
            
            for (let i = 0; i < data.length; i++) {

                const Serial_No_val = (data[i].serialNo);
                const voter_Name_val = (data[i].voterName);
                const Age_val = (data[i].age);
                const Gender_val = (data[i].gender);
                const voterId_val = (data[i].voterId);
                const houseNo = (data[i].houseNo);
                const relectiveName = (data[i].relativeName);
                const relation = (data[i].relation);
                const voterCardNo = (data[i].voterCardNo);
                const booth = (data[i].booth);
                const mandal = (data[i].tehsil);
                const town = (data[i].town);
                const panchayat = (data[i].panchayat);
                const isNewVoter = (data[i].isNewVoter);
                const party = typeof data[i].party != "undefined" ?  data[i].party : "";
                const mobileNo = typeof data[i].mobileNo != "undefined" ?  data[i].mobileNo : ""
                
                const obj = {
                    Serial_No:(Serial_No_val),
                    Booth:booth,
                    voter_Name: voter_Name_val,
                    Age: Age_val,
                    Gender: Gender_val,
                    voterCardNo:voterCardNo,
                    voterId:voterId_val,
                    houseNo:houseNo,
                    relectiveName:relectiveName,
                    relation:relation,
                    Add_influencer:"",
                    mandal:mandal,
                    town:town,
                    panchayat:panchayat,
                    isNewVoter:isNewVoter,
                    party:party,
                    mobileNo:mobileNo
                    
                }
                votersData.push(obj);
            } 

            
            
            return votersData;
        }
    }

    
    saveFirstTimeVotersModal = (voterId,voterName) => {
        this.setState({voterId:"",
            partyIdStr:"",
            partyIdStrValue:"",
            successMsgInf:"",

            influencerName:"",
            volunteerMobile:"",
            validateVolunteerMobile:"",

            saveInfLoadingSymbol:"hide",

            validateVolunteerOTP:"",
            volunteerOTPNO:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",
            mobileNoDisabled:false,
            disabledButtonBG:false,

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",
            
            minutes:1,
            seconds:0,
            validateProfessionIdStr:""
         });

        this.setState({firstTimeVotersModalShow:true,voterId:voterId,influencerName:voterName});
    }

    updateFirstTimeVoter = () => {
        this.setState({validateProfessionIdStr:"",validatePartyIdStr:"",validateVolunteerMobile:"",validateVolunteerOTP:"" });

        if(this.state.volunteerMobile == ""){
            this.setState({validateVolunteerMobile:"Please Enter Mobile No" });
            return;
        }
        if(!this.state.volunteerMobile.match(/^[0-9]+$/)){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
        }

        if(this.state.volunteerMobile.length < 10){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
        }

        if(this.state.volunteerOTPNO == ""){
            this.setState({validateVolunteerOTP:"Please Enter OTP" });
            return;
        }

        if(this.state.volunteerOTPNO.length < 6){
            this.setState({validateVolunteerOTP:"Please Enter Valid OTP" });
            return;
        }

        if(this.state.otpVerifiedMessageStatus == "NotVerified"){
            this.setState({validateVolunteerOTP:"Please Enter Valid OTP" });
            return;
        }

        if(this.state.professionIdStr == "" || this.state.professionIdStr == 0){
            this.setState({validateProfessionIdStr:"Select Profession" });
            return;
        }
       
        if(this.state.partyIdStr == "" || this.state.partyIdStr == 0){
            this.setState({validatePartyIdStr:"Select Party" });
            return;
        }

       
          const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "voterId" : this.state.voterId,
                "partyId":this.state.partyIdStr,
                "mobileNo":this.state.volunteerMobile,
                "latitude":this.state.checkingLocationServiceEnabledObj.latitude,
                "longitude":this.state.checkingLocationServiceEnabledObj.longitude,
                "professionId":this.state.professionIdStr
            });
        
        
        this.setState({saveInfLoadingSymbol:"show"});

        getAPICallData(obj,"saveNewVoter")
            .then((res) => res.json())
            .then((content) => {
              this.setState({saveInfLoadingSymbol:"hide"});  
                if(content !=null && content.status == "Success"){
                    this.setState({successMsgInf:"Updated SuccessFully..."});
                    this.change_status_FTV(this.state.voterSerachArr)   
                     setTimeout(() => {
                        
                        this.setState({firstTimeVotersModalShow:false});
                        this.setState({//voterId:"",
                                //partyIdStr:"",
                                //partyIdStrValue:"",
                                //volunteerMobile:"",
                                successMsgInf:"",
                                
                        });
                    }, 1000);
                }else if(content !=null && content.status == "Fail"){
                    this.setState({successMsgInf:content.message});
            
                }else{
                    this.setState({successMsgInf:"Some Thing Went Wrong,Please Try Again..."});
            
                }
            });
    }

    change_status_FTV = (_data) => {
        for(var key in _data){
            if(_data[key].voterId == this.state.voterId){
                if(this.state.partyIdStr != ""){
                    _data[key].party = this.state.partyIdStrValue.label
                }else{
                    _data[key].party = '0'
                }

               

                if(this.state.volunteerMobile != ""){
                    _data[key].mobileNo = this.state.volunteerMobile
                }else{
                    _data[key].mobileNo = ''
                }

                _data[key].isNewVoter = 'Y'



            }
        }
        console.log("_data",_data) ;
        this.setState({voterSerachArr:_data});
    }    


    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
       if(name == "partyIdStr"){
            let partyIdName = this.state.party_ids_Arr.find((a) => a.value == value);
            this.setState({
                partyIdStrValue: {
                  value: partyIdName.value,
                  label: partyIdName.label
              }
          });

    
          this.setState({ partyIdStr: value});
        }
    }

    saveNewVoterIdNotAvailableEnroll = () => {
        /*this.setState({ validateSearchByBoothNo:""});

        if(this.state.volunteerSearchBoothName == ""){
            this.setState({validateSearchByBoothNo:"Select Booth" });
            return;
        }*/


        this.setState({
            volunteerMobile:"",
            validateVolunteerMobile:"",


            validateVolunteerOTP:"",
            volunteerOTPNO:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",
            mobileNoDisabled:false,
            disabledButtonBG:false,

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",
            
            minutes:1,
            seconds:0,

            VoterName:"",
            ValidateVoterName:"",
            VoterReference:"",
            ValidateVoterReference:"",
            VoterGender:"",
            validateVoterGender:"",
            disabledBtnVoterEnroll:false,
            successMsgVN:"",
            VoterRelativeName:"",
            ValidateVoterRelativeName:""
        })
        this.setState({voterIdNotAvailableModal:true})
    }

    saveVoterIdNotAvilableEnroll = () => {
        this.setState({ validateSearchByBoothNo:"",VoterName:"",
        ValidateVoterName:"",
        VoterReference:"",
        ValidateVoterReference:"",
        VoterGender:"",
        validateVoterGender:"",
        successMsgVN:"",
        VoterRelativeName:"",
        ValidateVoterRelativeName:"",validateVolunteerMobile:"" });

        
        if(this.state.VoterName == ""){
            this.setState({ValidateVoterName:"Please Enter Name" });
            return;
        }

        if(this.state.VoterReference == ""){
            this.setState({ValidateVoterReference:"Please Enter Reference" });
            return;
        }
       /* if(this.state.VoterRelativeName == "" ){
            this.setState({ValidateVoterRelativeName:"Please Enter Releative Name" });
            return;
        }*/

        if(this.state.VoterGender == "" ){
            this.setState({ValidateVoterReference:"Please Select Gender" });
            return;
        }
        
        if(this.state.volunteerMobile == ""){
            this.setState({validateVolunteerMobile:"Please Enter Mobile No" });
            return;
        }
        if(!this.state.volunteerMobile.match(/^[0-9]+$/)){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
        }

        if(this.state.volunteerMobile.length < 10){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
        }

        if(this.state.volunteerMobile != ""){
            if(this.state.volunteerOTPNO == ""){
                this.setState({validateVolunteerOTP:"Please Enter OTP" });
                return;
            }

            if(this.state.volunteerOTPNO.length < 6){
                this.setState({validateVolunteerOTP:"Please Enter Valid OTP" });
                return;
            }

            if(this.state.otpVerifiedMessageStatus == "NotVerified"){
                this.setState({validateVolunteerOTP:"Please Enter Valid OTP" });
                return;
            }
        }
       

       
       
          const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "voterName" : this.state.VoterName,
                "relativeName" : this.state.VoterRelativeName,
                "gender" : this.state.VoterGender,
                "reference" : this.state.VoterReference,
                "mobileNo" : this.state.volunteerMobile,
                "boothId" : this.state.SearchBoothValue.value,
                "constituencyId" : this.state.allConstituencyValue.value,
                "latitude":this.state.checkingLocationServiceEnabledObj.latitude,
                "longitude":this.state.checkingLocationServiceEnabledObj.longitude,
            });
        
        
        this.setState({saveNALoadingSymbol:"show",disabledBtnVoterEnroll:true});

        getAPICallDataForOnlyBSA(obj,"saveNewVoterEnroll")
            .then((res) => res.json())
            .then((content) => {
              this.setState({saveNALoadingSymbol:"hide"});  
                if(content !=null && content.status == "Success"){
                    this.setState({successMsgVN:"Saved SuccessFully..."});
                     setTimeout(() => {
                        
                        this.setState({voterIdNotAvailableModal:false});
                        this.setState({  successMsgVN:""});
                        
                    }, 2000);
                }else if(content !=null && content.status == "Fail"){
                    this.setState({disabledBtnVoterEnroll:false});
                    this.setState({successMsgVN:content.message});
            
                }else{
                    this.setState({disabledBtnVoterEnroll:false});
                    this.setState({successMsgVN:"Some Thing Went Wrong,Please Try Again..."});
            
                }
            });
    }

    
    render() {
        let firstTimeVoterDetailsArr=[];
        let keyword = this.state.searchNameValue.toString()
        if (keyword !== ''){
            firstTimeVoterDetailsArr = this.state.voterSerachArr.filter(  (item) => {
                if (item.voterCardNo.toLowerCase().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.voterName.toLowerCase().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.serialNo.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.gender.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.age.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.relativeName.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.relation.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.houseNo.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.tehsil.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.town.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.panchayat.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
            } );
        }else{
            firstTimeVoterDetailsArr = this.state.voterSerachArr;
            
        }

        return (
            <section>
               
                <Container fluid>
                    <>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'> First Time Voter Search</h6>
                        </Col>
                    </Row>

                    {
                        this.state.chatID != "1048868711" ?
                        (
                            this.state.delaylocationModal == true && Object.keys(this.state.checkingLocationServiceEnabledObj).length == 0 ?
                            (
                                <EnabledLocationPopup 
                                    show={() => this.setState({ checkingEnabledLocationModal: true })}
                                    onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                                    closeEnabledModal={this.closeEnabledModal}
                                />
                             )
                            :
                            ("")
                        )
                        :
                        ("")
                     }
                    
                    <Row>
                        <Col className='mt-3'>
                        <label className="form-label f-12 fw-bold">Constituency<span className='color_red f-14 ms-1'>*</span></label>
                        <SelectBox
                                stateData={this.state.allConstituencyValue}
                                handleChange={this.handleOnchangeSelectBoxNew1}
                                name="allConstituencyName"
                                options={this.state.ac_listArr}
                                placeholderName={'Constituency'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                    <Col className='mt-3'>
                        <label className="form-label f-12 fw-bold">Booth <span className='f-12 color_red'>{this.state.validateSearchByBoothNo}</span>
                            {
                                this.state.boothsLoadingSymbol == "show" ?
                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                :
                                ("")
                            }
                            
                        </label>
                        <SelectBox
                                stateData={this.state.SearchBoothValue}
                                handleChange={this.handleOnchangeSelectBoxNew1}
                                name="volunteerSearchBoothName"
                                options={this.state.searchOptionBoothListArr}
                                placeholderName={'Booth'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mt-2" controlId="formVoterSerachCardNoId">
                            <Form.Label className='f-12 fw-bold'>Voter Card No </Form.Label>
                            <Form.Control type="text" name="volunteerSearchVoterCardNo" className='form-control-sm'  placeholder="Enter Voter Card No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Form.Group>    
                    </Col>
                </Row>
                <Row>
                    
                    <Col>
                        <Form.Group className="mt-2" controlId="formNameId">
                            <Form.Label className='f-12 fw-bold'>Voter Name </Form.Label>
                            <Form.Control type="text" name="volunteerSearchName" className='form-control-sm'  placeholder="Enter Name" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                        </Form.Group>    
                    </Col>
                    
                </Row>
                  
                  <Row>
                        <Col>
                            <h6 className='mt-3 text-center'>
                                <span className=''>
                                    <Form.Check
                                        inline
                                        label={"All"}
                                        name="firstTimeVoterUpdate"
                                        type="radio"
                                        id="firstTimeVoterAllId"
                                        onChange={(e)=>this.getFilterWiseFirstTimeVoters(e,'')}
                                        className="color_black f-14 pos_name_align"
                                        defaultChecked={true}
                                    />
                                    <Form.Check
                                        inline
                                        label={"Verified"}
                                        name="firstTimeVoterUpdate"
                                        type="radio"
                                        id="firstTimeVoterYesId"
                                        onChange={(e)=>this.getFilterWiseFirstTimeVoters(e,'Y')}
                                        className="color_black f-14 pos_name_align"
                                    />
                                    
                                    <Form.Check
                                        inline
                                        label={"Pending"}
                                        name="firstTimeVoterUpdate"
                                        type="radio"
                                        id="firstTimeVoterNoId"
                                        onChange={(e)=>this.getFilterWiseFirstTimeVoters(e,'N')}
                                        className="color_black f-14 pos_name_align"
                                    />
                                </span>
                            </h6>
                        </Col>
                    </Row>
                  
                    <Row>
                        <Col>
                            <Button variant="outline-danger" className='float-end f-12  fw-bold' onClick={() => this.saveNewVoterIdNotAvailableEnroll()}>ADD Voter Id Not Available  <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                        </Col>
                    </Row>

                    <Row className='text-center'> 
                        <Col>
                            <Button variant="success" className='f-12 fw-bold mt-3' onClick={() => this.getVotersDisplayForVoterSerch("Y")}>Get First Time Voters</Button> 
                        </Col>
                    </Row>
                    
                    {
                        this.state.votersListLoader == "show" ?
                        (
                            <Row>
                                <Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.votersDataCheck == 1 ?
                        (
                            <Row>
                                <Col sm={12}>
                            
                                <div className='bg_light_blue mt-3'>
                                        <Row>
                                            <Col sm={6}>
                                                <input
                                                    className='float-end search_bar mt-2'
                                                    type="search"
                                                    placeholder='Search'
                                                    value={this.state.searchNameValue}
                                                    onChange={this.handleSearchNameChange}
                                                />
                                                <i className='fa fa-search search-icon'></i>
                                            </Col>
                                        </Row>

                                        

                                            {
                                                    firstTimeVoterDetailsArr.length>0 ?
                                                    (
                                                        
                                                    
                                                        <CardLessDataTableWithOutSearch 
                                                            parentData={this.votersDisplayData(firstTimeVoterDetailsArr)}
                                                            particlecolumns={this.firstTimevotersDisplayColumns()}
                                                            defaultSorted={[{
                                                                dataField: "Serial_No",
                                                                order: "asc"
                                                            }]}
                                                            tableHeading={<><h6 className='f-14 fw-bold'>First Time Voter Details -  {this.state.SearchBoothValue.label}</h6></>}
                                                            />
                                                                        
                                                                            
                                                        
                                                    )
                                                    :
                                                    (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                            }
                                    </div>
                                                            
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                        
                        
                    }
                    
                    </>

                 <UpdateFirstTimeVoters 
                        show={this.state.firstTimeVotersModalShow}
                        onHide={() => this.setState({ firstTimeVotersModalShow: false })}
                        stateData={this.state}
                        phandleOnchangeSelectBoxNew={this.handleOnchangeSelectBoxNew}
                        phandleOnchangeInputBox={this.handleOnchangeInputBox}
                        updateFirstTimeVoter={this.updateFirstTimeVoter}
                        resendOTPNew={this.resendOTPNew}
                        getMobileNoOTP={this.getMobileNoOTP}
                        handleOnchangeSelectBoxNew1={this.handleOnchangeSelectBoxNew1}
                />
                <VoterIdNotAvailableModal 
                        show={this.state.voterIdNotAvailableModal}
                        onHide={() => this.setState({ voterIdNotAvailableModal: false })}
                        stateData={this.state}
                        handleOnchangeInputBox={this.handleOnchangeInputBox}
                        saveVoterIdNotAvilableEnroll={this.saveVoterIdNotAvilableEnroll}
                        resendOTPNew={this.resendOTPNew}
                        getMobileNoOTP={this.getMobileNoOTP}
                        
                />
                
                </Container>
                
            </section>   
        )
    }
  }
  
  export default withRouter(FirstTimeVoters);
  