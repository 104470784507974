
import React ,{ Component,createRef  } from 'react';
import {Container, Row, Col,Image,Button,Card  } from 'react-bootstrap';
import "./VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import MF_logo1 from '../../images/MF_logo1.png';
import PhysicalCard from '../../images/physical_card.jpeg';
import * as htmlToImage from "html-to-image";
import loader from '../../images/loader.gif';
import FrontImg from '../../images/front_img.jpeg';
import BackImg from '../../images/back_img.png';
import QRCode from "react-qr-code";
import { saveAs } from "file-saver";

class SuccessPageLocation extends Component {
    constructor(props) {
        super(props)
        this.myref = createRef();
        this.state = {
            volunteerDts:props?.location?.params?.volunteerDts == undefined ? "" : props?.location?.params?.volunteerDts,
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            downloadloadingSymCard:"hide",
			blobStr:"",
			bloburl:""
        }
    }

    componentDidMount() {
       
    }


    backToEnrollPage = () => {
        this.props.history.push({
            pathname: "/volunteer_page",
            params: {
                navbarDisplay:"hide",
                volunteerDts:this.props?.location?.params?.volunteerDts == undefined ? "" : this.props?.location?.params?.volunteerDts,
                userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList
              },
          });
    }

    downloadBGCard = async () => {
        
        this.setState({downloadloadingSymCard:"show" });

        const node = this.myref.current;
        htmlToImage.toPng(node).then((dataUrl) => {
           this.saveAs(dataUrl, 'BGCard.png');
        });

    }


     saveAs = async (blob, fileName) =>{
        this.setState({downloadloadingSymCard:"hide"});
        /*const downloadResult = await fetch(
            filePath
        );
        const blob = await downloadResult.blob();
        
        saveAs(blob, fileName);*/

        var elem = document.createElement('a');
        elem.href = blob
        elem.download = fileName;
        elem.style = 'display:none;';
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === 'function') {
            elem.click();
        } else {
            elem.target = '_blank';
            elem.dispatchEvent(new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
            }));
        }
        URL.revokeObjectURL(elem.href);
        elem.remove()
      }
    
  
    

    render() {
        console.log("ddddddd",this.props)
        return (
            <section>
               <div className="pos_relative">
                    <div class="text-white">
                    <img  class="bg_css_volunter_logo" />
                    <div class="card-img-overlay">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="flex-header mt-1">
                                <img className="center-auto w-80P" src={MF_logo1} />
                                
                                </div>
                            </div>
                        </div>
                        <Link to={{pathname: "/dashboard"}}> 
                            <Button variant="outline-light ms-3 mt-3 pad5_14 back_button" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Link>
                    </div>
                    </div>
                </div>
                
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h6 className='text_center mt-4 color_green fw-bold f-14 ln-30'>భవిష్యత్తుకు గ్యారెంటీ కార్యక్రమానికి నమోదు అయినందుకు ధన్యవాదములు</h6>
                           <div className='htmlToImageVis' ref={this.myref}> 
                                <Card className="text-white">
                                    <Card.Img src={FrontImg} alt="Card image"  />
                                        <div className='qr_code_pos'>
                                            <QRCode
                                                size={75}
                                                viewBox={`0 0 256 256`}
                                                value={`www.tdpmanifesto.com/?i=${this.props?.location?.params?.enrollId == undefined  ? "" : this.props?.location?.params?.enrollId}`}
                                            />
                                        </div>
                                    
                                    <Card.ImgOverlay className='main_text_pos'>
                                        <Card.Title className='cardId_pos color_black fw-bold f-12'>{this.props?.location?.params?.volunteerPhysicalCardId == undefined ? "" : this.props?.location?.params?.volunteerPhysicalCardId}</Card.Title>
                                        <Card.Title className='cardName_pos color_black fw-bold f-12'>{this.props?.location?.params?.volunteerName == undefined ? "" : this.props?.location?.params?.volunteerName}</Card.Title>
                                        <Card.Title className='cardPhone_pos color_black fw-bold f-12'>{this.props?.location?.params?.volunteerMobile == undefined ? "" : this.props?.location?.params?.volunteerMobile}</Card.Title>
                                        <Card.Title className='cardCons_pos color_black fw-bold f-12'>{this.props?.location?.params?.SelfconstituencyValue == undefined ? "" : this.props?.location?.params?.SelfconstituencyValue.label}</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>

                                <Card className="text-white mt-2">
                                    <Card.Img src={BackImg} alt="Card image"  />
                                </Card>


                                </div>
                            <div className='text-center'>
                                <Row>
                                    {/*<Col>
                                        <Button variant="success" className='mt-4 f-14 fw-bold' value={require('../../images/front_img.jpeg')} onClick={() => this.downloadBGCard()}>Download Card
                                        {
                                            this.state.downloadloadingSymCard == "show" ?
                                            (
                                                <Image src={loader} alt="loader" className='loader_cls ms-2'></Image>
                                            )
                                            :
                                            ("")
                                        }
                                        </Button>
                                    </Col>*/}
                                    <Col>
                                        <Button variant="warning" className='mt-4 f-14 fw-bold color_white' onClick={() => this.backToEnrollPage()}>Start New Enroll</Button>
                                    </Col>
                                </Row>

		
                            </div>
                            
                        </Col>
                    </Row>
                </Container>
            
            </section>
        )
    }
  }
  
  export default withRouter(SuccessPageLocation);
  