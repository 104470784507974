import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };


class UpdateVotersCollection extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Update Voter Attribute</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                      <Row>
                         <Col>
                         <h6 className="form-label f-14 fw-bold">Party </h6>
                                <SelectBox
                                    stateData={this.props.stateData.partyIdStrValue}
                                    handleChange={this.props.phandleOnchangeSelectBoxNew}
                                    name="partyIdStr"
                                    options={this.props.stateData.party_ids_Arr}
                                    placeholderName={'Select Party'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                /> 
                         </Col>
                      </Row>  
                      <Row>
                         <Col className='mt-2'>
                         <h6 className="form-label f-14 fw-bold">Caste </h6>
                                <SelectBox
                                    stateData={this.props.stateData.casteIdStrValue}
                                    handleChange={this.props.phandleOnchangeSelectBoxNew}
                                    name="casteIdStr"
                                    options={this.props.stateData.caste_ids_Arr}
                                    placeholderName={'Select Caste'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                /> 
                         </Col>
                      </Row> 
                      <Row>
                         <Col className='mt-2'>
                         <h6 className="form-label f-14 fw-bold">Education </h6>
                                <SelectBox
                                    stateData={this.props.stateData.educationStrValue}
                                    handleChange={this.props.phandleOnchangeSelectBoxNew}
                                    name="educationIdStr"
                                    options={this.props.stateData.education_ids_Arr}
                                    placeholderName={'Select Education'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                /> 
                         </Col>
                      </Row>
                      <Row>
                         <Col className='mt-2'>
                         <h6 className="form-label f-14 fw-bold">Religion </h6>
                                <SelectBox
                                    stateData={this.props.stateData.religionStrValue}
                                    handleChange={this.props.phandleOnchangeSelectBoxNew}
                                    name="religionIdStr"
                                    options={this.props.stateData.religion_ids_Arr}
                                    placeholderName={'Select Religion'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                /> 
                         </Col>
                      </Row>    
                      <Row>
                         <Col className='mt-2'>
                         <h6 className="form-label f-14 fw-bold">Mobile No <span className='color_red f-9'>{this.props.stateData.validateMobileNoVV}</span></h6>
                            <Form.Control type="text" name="mobileNoStr" value={this.props.stateData.mobileNoStr}  className='form-control-sm' maxLength={10}  placeholder="Enter Mobile No" onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                         </Col>
                      </Row>  
                      <Row>
                         <Col className='mt-2'>
                         <h6 className="form-label f-14 fw-bold">Occupation </h6>
                                <SelectBox
                                    stateData={this.props.stateData.occIdStrValue}
                                    handleChange={this.props.phandleOnchangeSelectBoxNew}
                                    name="occIdStr"
                                    options={this.props.stateData.occ_ids_Arr}
                                    placeholderName={'Select Occupation'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                /> 
                         </Col>
                      </Row>
                      <Row>
                         <Col className='mt-2'>
                         <h6 className="form-label f-14 fw-bold">Residence status </h6>
                                <SelectBox
                                    stateData={this.props.stateData.residenceStrValue}
                                    handleChange={this.props.phandleOnchangeSelectBoxNew}
                                    name="residenceStr"
                                    options={this.props.stateData.residence_Arr}
                                    placeholderName={'Select Residence status'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                /> 
                         </Col>
                      </Row>
                      <Row>
                         <Col className='mt-2'>
                         <h6 className="form-label f-14 fw-bold">Is NRI </h6>
                                <SelectBox
                                    stateData={this.props.stateData.is_nri_value}
                                    handleChange={this.props.phandleOnchangeSelectBoxNew}
                                    name="is_nri"
                                    options={this.props.stateData.is_nri_Arr}
                                    placeholderName={'Select Is NRI'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                /> 
                         </Col>
                      </Row>
                      
                      {
                        this.props.stateData.residenceStr == 4 ?
                        (
                            <Row>
                                <Col>
                                <h6 className="form-label f-14 fw-bold mt-2">Voter Location </h6>
                                    <FloatingLabel
                                            controlId="locationId"
                                            label="Enter Location"
                                            className="f-12"
                                            
                                        >
                                            <Form.Control as="textarea" placeholder="Enter Location" name="locationUpdated" value={this.props.stateData.locationUpdated} className='height100px f-14' onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                        </FloatingLabel>
                                        <span className='color_red f-9'>{this.props.stateData.validateLocationUpdated}</span>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                      }
                      <Row>
                            <Col>
                                <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-3 mb-3' onClick={() => this.props.pupdateVoterFields()}>Update</Button>
                                    {
                                        this.props.stateData.updateVoterLoadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                    }
                            </Col>
                      </Row>
                      <Row>
                            <Col>
                                <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.updateVoterFldMsg}</h6>
                            </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default UpdateVotersCollection;
  

