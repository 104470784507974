
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,Card  } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import "./VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import MF_logo1 from '../../images/MF_logo1.png';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import MS_logo from '../../images/MahaShakti/MS_logo1.png';
import AD_logo from '../../images/AnnaData/AD_logo1.png';
import BC_logo from '../../images/BC/BC_logo1.png';
import HW_logo from '../../images/HouseWater/HW_logo1.png';
import PTR_logo from '../../images/PoorToRich/PTR_logo1.png';
import YG_logo from '../../images/Yuvagalam/YG_logo1.png';
import YuvaGalamModal from './YuvaGalamModal';
import { getAPICallData } from '../../services/MemberService'
import QrReaderModalCode from './QrReaderModalCode';
import VoiceRecorder from '../VoiceRecorder/VoiceRecorder';
import { scroller } from "react-scroll";
import Webcam from "react-webcam";
import { Modal, ModalContent } from "./ImagePopupModal";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
var interestArr=[];
class VoterSearchEnrollSaveLocation extends Component {
    constructor(props) {
        super(props)
        this.webcamRef = React.createRef();
        this.mahaShaktiRef = React.createRef();
        this.annaDataRef = React.createRef();
        this.yuvagalamRef = React.createRef();
        this.BGCardRef = React.createRef();


        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            save_booth_id:props?.location?.params?.save_booth_id == undefined ? "" : props?.location?.params?.save_booth_id,
            save_voter_id:props?.location?.params?.save_voter_id == undefined ? "" : props?.location?.params?.save_voter_id,
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            volunteerDts:props?.location?.params?.volunteerDts == undefined ? "" : props?.location?.params?.volunteerDts,
            volunteerPhysicalCardId:"",
            
            volunteerName:props?.location?.params?.volunteerName == undefined ? "" : props?.location?.params?.volunteerName,
            volunteerGender:props?.location?.params?.volunteerGender == undefined ? "" : props?.location?.params?.volunteerGender,
            volunteerAge:props?.location?.params?.volunteerAge == undefined ? "" : props?.location?.params?.volunteerAge,
            volunteerMobile:"",
            volunteerOTPNO:"",
            volunteerVoterCard:props?.location?.params?.volunteerVoterCard == undefined ? "" : props?.location?.params?.volunteerVoterCard,
            volunteerPincode:"",
            volunteerAddress:"",

            volunteerOccupation:"",
            occupationListArr:[],
            occupationValue:"",

            volunteerConstituencyName:props?.location?.params?.volunteerConstituencyName == undefined ? "" : props?.location?.params?.volunteerConstituencyName,
            ap_listArr:props?.location?.params?.ap_listArr == undefined ? "" : props?.location?.params?.ap_listArr,
            SelfconstituencyValue:props?.location?.params?.SelfconstituencyValue == undefined ? "" : props?.location?.params?.SelfconstituencyValue,

            SelfMandalMunValue:"",
            volunteerMandalMunName:"",
            mandalMunArr:[{'value':1,'label':'Mandal Name'}],

            mahaShaktiCheckedCond:"",
            annaDataCheckedCond:"",
            yuvvaGalamCheckedCond:"",

            yuvaGalamCheckBox:props?.location?.params?.yuvaGalamCheckBox == undefined ? false : props?.location?.params?.yuvaGalamCheckBox,

            annaDataCardId :"",
            yuvaGalamCardId:"",
            mahaShaktiCardId:"",

            preferencesObj:[],
                
               
           
            schemeCheckVerified:0,

            validateVolunteerPhysicalCardId:"",
            validateVolunteerName:"",
            validateVolunteerGender:"",
            validateVolunteerAge:"",
            validateVolunteerOccupation:"",
            validateVolunteerMobile:"",
            validateVolunteerVoterCard:"",
            validateVolunteerConstituencyName:"",
            validateVolunteerPincode:"",
            validateVolunteerMandalMunName:"",
            validateVolunteerOTP:"",
            validateVolunteerAddress:"",
            validateInterests:"",

            selfOptionScreenType:"slide_one",
            selfOptionLoderSymbolSave:"hide",
            selfOptionMessageSave:"",
            yuvvaGalamCheckedUnemployedModal:false,
            getCurrentLocationCoordinatesForDV:{},

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",
            
            minutes:1,
            seconds:0,

            QrReaderModalCodeModal:false,
            QrReaderScanTypeVal:"",
            loadingScan:true,
            scanData:"",

            audioSourceURL:"",
            base64StrVoiceRecorded:"",
            recordedMsg:"",

            casteListArr:[{'value':1,'label':'OC'},{'value':2,'label':'BC'},{'value':3,'label':'SC'},{'value':4,'label':'ST'},{'value':5,'label':'Minority'},{'value':'','label':'Not Interested'}],
            casteStr:"",
            casteValue:{'value':'','label':'Not Interested'},

            bcProtectionLawCheckBox:false,
            annaDataCheckBox:false,
            yuvaGagalamCheckBox:false,
            mahaShaktiCheckBox:false,
            smsForBG:"",

            NOOfChildrens:0,
            validateNOOfChildrens:"",

            qrCodeUUID:"",

            noOfWomen:0,
            noOfUnEmployed:0,
            noofFarmers:0,
            totalFamilyMembers:0,
            validatenoOfWomen:"",
            validatenoOfUnEmployed:"",
            validatetotalFamilyMembers:"",

            validateCaste:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",

            mobileNoCheckingVal:"mobile",
            uploadPhotosArr:[],
            showImgCapture:true,
            videoConstraints:{facingMode: "environment"},
            imageAddedMsg:"",
            isOpen: false,
            enabledLocationModal:false
            
        }
        this.checkInterestValue = this.checkInterestValue.bind(this);
        this.uploadPhotosForBG = this.uploadPhotosForBG.bind(this);
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        if(this.state.userAccessList == "" || this.state.userAccessList == undefined){
            this.props.history.push("/dashboard");
        }else{
            navigator.geolocation.getCurrentPosition(this.showLocationDVMappingPostions,this.ShowLocationPermissionError)
            /*const getUID = (length=5) => {
                var result           = '';
                var characters       = '0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }
            const BGCardId = "6666120"+getUID();
            this.setState({ volunteerPhysicalCardId:BGCardId});*/
            this.getFutureGuaranteeEnrollmentNumber(this.state.SelfconstituencyValue.value);
                        

            
            this.getOccupations();


           if(this.state.volunteerGender == "F" ){ 
            this.setState({ preferencesObj:[],mahaShaktiCheckBox:!this.state.mahaShaktiCheckBox});

            let schemesSelectedArr=[ {"schemeCode": "MAHA_SHAKTI","interested": true,"cardId": "" }];
                        
            this.setState({ preferencesObj:schemesSelectedArr });
                       

            
            
           }
    
        }
    }

    getFutureGuaranteeEnrollmentNumber = (constituencyId) => {
        const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "constituencyId" : constituencyId,
        }); 

        getAPICallData(obj,"getFutureGuaranteeEnrollmentNumber")
        .then((res) => res.json())
        .then((data) => {
          if(data !=null && data.name == "Success"){
            this.setState({ volunteerPhysicalCardId:data.id});
          }else{
            this.setState({ volunteerPhysicalCardId:""});
          }
        });
    }

    getOccupations = () => {
        fetch(`/bot_api/v1/occupations`, {
            method: "GET", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "test_chat_id" :"5129193582",
            },
          })
          .then((res) => res.json())
          .then((json) => {
              if(json !=null && json.length>0){
                var occupationArr=[];
                for(var i in  json){
                    var obj={'value':json[i].occupationCode,'label':json[i].occupationName};
                    occupationArr.push(obj)
                    
                }
               
                this.setState({ occupationListArr:occupationArr});
              }
            
          });
    }

    handleOnchangeSelectBox = (e) => {
        const name = e.target.name;
        const value = e.target.value;
    
        this.setState({ [name]: value });
        
        
      }

    handleOnchangeInputBox = (e) => {
        this.setState({validateInterests:"" });
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        const re = /^[0-9\b]+$/;
        

       
        if(name == "volunteerOTPNO"){
            if(value.length >= 6){
                this.validateMobileOtp(value);
            }
            
        }else if(name == "noOfWomen"){
            let MAHA_SHAKTI = this.state.preferencesObj.filter(a => a.schemeCode == "MAHA_SHAKTI").map(b => b.schemeCode);
            if(value > 0){
                this.setState({ mahaShaktiCheckBox:true});
                if(MAHA_SHAKTI.length==0){
                    this.setState(({preferencesObj}) => ({  
                        preferencesObj: [...preferencesObj, {
                        "schemeCode": "MAHA_SHAKTI",
                        "interested": true,
                        "cardId": ""
                    }]
                    }));
                }
                
            }else if(value == 0 || value == ""){ 
                this.setState({ mahaShaktiCheckBox:false});
                const itemToBeRemoved = {"schemeCode": "MAHA_SHAKTI","interested": true,"cardId": "" };
                this.state.preferencesObj.forEach((item, index) => {
                    if (item.schemeCode == itemToBeRemoved.schemeCode) {
                        this.state.preferencesObj.splice(index, 1);
                    }
                });
            }
            
        }else if(name == "noOfUnEmployed"){
            let YUVA_GALAM = this.state.preferencesObj.filter(a => a.schemeCode == "YUVA_GALAM").map(b => b.schemeCode);
            if(value > 0){
                this.setState({ yuvaGagalamCheckBox:true});
                if(YUVA_GALAM.length==0){
                    this.setState(({preferencesObj}) => ({  
                        preferencesObj: [...preferencesObj, {
                        "schemeCode": "YUVA_GALAM",
                        "interested": true,
                        "cardId": ""
                    }]
                    }));
                }
                
            }else if(value == 0 || value == ""){ 
                this.setState({ yuvaGagalamCheckBox:false});
                const itemToBeRemoved = {"schemeCode": "YUVA_GALAM","interested": true,"cardId": "" };
                this.state.preferencesObj.forEach((item, index) => {
                    if (item.schemeCode == itemToBeRemoved.schemeCode) {
                        this.state.preferencesObj.splice(index, 1);
                    }
                });
            }
            
        }else if(name == "volunteerMobile"){
            if(value.length >= 10){
                if(value == "9948029998" || value == "8106184388" || value == "9652244900" || value == "9966542524" || value == "9666519677" || value == "8919278211"){
                    this.setState({mobileNoSuccessMsg:"SUCCESS"});
                }else{
                    this.verifyMobileNumberRegistered(value);
                }
                
            }
        }

        

        this.setState({ [name]: value });
        
      }

      verifyMobileNumberRegistered = (value) => {
        this.setState({mobileNoLoadingSymbol:"show",mobileNoSuccessMsg:""});

        fetch('/bot_api/v1/verifyMobileNumberRegistered', {
            method: "POST", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "test_chat_id" :"5129193582",
            },
            body: JSON.stringify({
                "mobileNo": value,
               
            }), 
          })
          .then((response) => {
            this.setState({mobileNoLoadingSymbol:"hide"});
            // 1. check response.ok
            if (response.ok) {
              return response.json();
            }
            return Promise.reject(response); // 2. reject instead of throw
          })
          .then((json) => {
            // all good, token is ready
            if(json.isRegistered == false){
                this.setState({mobileNoSuccessMsg:"SUCCESS"});
            }else{
                this.setState({mobileNoSuccessMsg:"FAIL"});
            }
          })
          .catch((response) => {
            this.setState({mobileNoSuccessMsg:"Something Wrong,Please Try Again..."});
            response.json().then((json) => {
                this.setState({mobileNoSuccessMsg:"Something Wrong,Please Try Again..."});
            })
          });

       
      }
      

      handleOnchangeAllowNumbersInputBox = (e) => {
        this.setState({validateInterests:"",validateVolunteerPhysicalCardId:"",validateNOOfChildrens:"",validatenoOfWomen:"",validatenoOfUnEmployed:"" });
        const name = e.target.name;
        const value = e.target.value;
        const re = /^[0-9\b]+$/;

        if(name == "mahaShaktiCardId"){
            if (!re.test(e.target.value)) {
                this.setState({validateInterests:"Please Enter Numbers Only...  ",mahaShaktiCardId:"" });
                return;
            }else if(value.length >=12){
                if (this.state.mahaShaktiCardId < 666600000001 || this.state.mahaShaktiCardId > 666699999999){
                    this.setState({validateInterests:"MahaShakti Range In Between 666600000001 to 666699999999" });
                    return;
                }else{
                    this.setState({validateInterests:"" });
                }
            }else if (this.state.mahaShaktiCardId.length < 12){
                this.setState({validateInterests:"Please Enter 12 Digit CardNo..." });
                return;
            }else{
                this.setState({validateInterests:"" });
            }

        }else if(name == "annaDataCardId"){
            if (!re.test(e.target.value)) {
                this.setState({validateInterests:"Please Enter Numbers Only...  ",annaDataCardId:"" });
                return;
            }else if(value.length >=12){
                if (this.state.annaDataCardId < 666600000001 || this.state.annaDataCardId > 666699999999){
                    this.setState({validateInterests:"AnnaData Range In Between 666600000001 to 666699999999" });
                    return;
                }else{
                    this.setState({validateInterests:"" });
                }
            }else if (this.state.annaDataCardId.length < 12){
                this.setState({validateInterests:"Please Enter 12 Digit CardNo..." });
                return;
            }else{
                this.setState({validateInterests:"" });
            }
        }else if(name == "yuvaGalamCardId"){
            if (!re.test(e.target.value)) {
                this.setState({validateInterests:"Please Enter Numbers Only...  ",yuvaGalamCardId:"" });
                return;
            }else if(value.length >=12){
                if (this.state.yuvaGalamCardId < 666600000001 || this.state.yuvaGalamCardId > 666699999999){
                    this.setState({validateInterests:"YuvaGalam Range In Between 666600000001 to 666699999999" });
                    return;
                }else{
                    this.setState({validateInterests:"" });
                }
            }else if (this.state.yuvaGalamCardId.length < 12){
                this.setState({validateInterests:"Please Enter 12 Digit CardNo..." });
                return;
            }else{
                this.setState({validateInterests:"" });
            }
        }else if(name == "volunteerPhysicalCardId"){
            if (!re.test(e.target.value)) {
                this.setState({validateVolunteerPhysicalCardId:"Please Enter Numbers Only...  ",volunteerPhysicalCardId:""});
                return;
            }else if(value.length >=12){
                if (this.state.volunteerPhysicalCardId < 666600000001 || this.state.volunteerPhysicalCardId > 666699999999){
                    this.setState({validateVolunteerPhysicalCardId:"Range In Between 666600000001 to 666699999999" });
                    return;
                }else{
                    this.setState({validateVolunteerPhysicalCardId:"" });
                }
            }else if (this.state.volunteerPhysicalCardId.length < 12){
                this.setState({validateVolunteerPhysicalCardId:"Please Enter 12 Digit CardNo..." });
                return;
            }else{
                this.setState({validateInterests:"" });
                this.setState({validateVolunteerPhysicalCardId:"" });
            }
        }else if(name == "NOOfChildrens"){
            if (!re.test(e.target.value)) {
                this.setState({validateNOOfChildrens:"Please Enter Numbers Only...  ",NOOfChildrens:""});
                return;
            }else{
                this.setState({validateNOOfChildrens:""});
            }
        }else if(name == "noOfWomen"){
            if (!re.test(e.target.value)) {
                this.setState({validatenoOfWomen:"Please Enter Numbers Only...  ",noOfWomen:""});
                return;
            }else{
                this.setState({validatenoOfWomen:""});
            }
        }else if(name == "noOfUnEmployed"){
            if (!re.test(e.target.value)) {
                this.setState({validatenoOfUnEmployed:"Please Enter Numbers Only...  ",noOfUnEmployed:""});
                return;
            }else{
                this.setState({validatenoOfUnEmployed:""});
            }
        }else if(name == "totalFamilyMembers"){
            if (!re.test(e.target.value)) {
                this.setState({validatetotalFamilyMembers:"Please Enter Numbers Only...  ",totalFamilyMembers:""});
                return;
            }else{
                this.setState({validatetotalFamilyMembers:""});
            }
        }else{
            this.setState({validateInterests:"" });
        }
      }

      
  handleOnchangeSelectBoxNew = (ename, evalue) =>{
    const name = ename;
    const value = evalue;

    this.setState({ validateVolunteerPhysicalCardId:"",
                        validateVolunteerName:"",
                        validateVolunteerGender:"",
                        validateVolunteerAge:"",
                        validateVolunteerOccupation:"",
                        validateVolunteerMobile:"",
                        validateVolunteerVoterCard:"",
                        validateVolunteerConstituencyName:"",
                        validateVolunteerPincode:"",
                        validateVolunteerOTP:"",
                        validateVolunteerMandalMunName:"",
                        validateVolunteerAddress:"",
                        validateInterests:"",
                        validateNOOfChildrens:"",
                        validatenoOfWomen:"",
                        validatenoOfUnEmployed:"",
                        validateCaste:"",
                        validatetotalFamilyMembers:""
                    });

    if(name == "volunteerConstituencyName"){
      let getConsIdName = this.state.ap_listArr.find((a) => a.value == value);
        this.setState({
          SelfconstituencyValue: {
              value: getConsIdName.value,
              label: getConsIdName.label
          }
      });
    }else if(name == "volunteerOccupation"){
        let getOccIdName = this.state.occupationListArr.find((a) => a.value == value);
        this.setState({
            occupationValue: {
              value: getOccIdName.value,
              label: getOccIdName.label
          }
      });

 
      if(value == "FARMER_AGRICULTURE" || value == "Farmer_Tenant"){
        this.setState({ annaDataCheckBox:true});
        let existedAnnaDataData = this.state.preferencesObj.filter(a => a.schemeCode == "RYTHU_NESTAM").map(b => b.schemeCode);
        if(existedAnnaDataData.length == 0){
            this.setState(({preferencesObj}) => ({  
                preferencesObj: [...preferencesObj, {
                "schemeCode": "RYTHU_NESTAM",
                "interested": true,
                "cardId": ""
            }]
            }));
        }
        
       
       
      }else if (value != "FARMER_AGRICULTURE" || value != "Farmer_Tenant"){
        const itemToBeRemoved = {"schemeCode": "RYTHU_NESTAM","interested": true,"cardId": "" };
        this.state.preferencesObj.forEach((item, index) => {
            if (item.schemeCode == itemToBeRemoved.schemeCode) {
                this.state.preferencesObj.splice(index, 1);
            }
        });
        this.setState({ annaDataCheckBox:false});

      }

      if(value == "Unemployed"){
        this.setState({ yuvaGagalamCheckBox:true});
        this.setState(({preferencesObj}) => ({  
            preferencesObj: [...preferencesObj, {
            "schemeCode": "YUVA_GALAM",
            "interested": true,
            "cardId": ""
        }]
        }));
       
       
      }else if (value != "Unemployed"){
        const itemToBeRemoved = {"schemeCode": "YUVA_GALAM","interested": true,"cardId": "" };
        this.state.preferencesObj.forEach((item, index) => {
            if (item.schemeCode == itemToBeRemoved.schemeCode) {
                this.state.preferencesObj.splice(index, 1);
            }
        });
        this.setState({ yuvaGagalamCheckBox:false});

      }


    }else if(name == "volunteerMandalMunName"){
        let getManIdName = this.state.mandalMunArr.find((a) => a.value == value);
        this.setState({
            SelfMandalMunValue: {
              value: getManIdName.value,
              label: getManIdName.label
          }
      });
    }else if(name == "casteStr"){
        let getCasteIdName = this.state.casteListArr.find((a) => a.value == value);
        this.setState({
            casteValue: {
              value: getCasteIdName.value,
              label: getCasteIdName.label
          }
      });
      if(value == 2){
        this.setState({ bcProtectionLawCheckBox:true});
        this.setState(({preferencesObj}) => ({  
            preferencesObj: [...preferencesObj, {
            "schemeCode": "BC_PROTECTION",
            "interested": true,
            "cardId": ""
        }]
        }));
       
       
      }else if (value != 2){
        const itemToBeRemoved = {"schemeCode": "BC_PROTECTION","interested": true,"cardId": "" };
        this.state.preferencesObj.forEach((item, index) => {
            if (item.schemeCode == itemToBeRemoved.schemeCode) {
                this.state.preferencesObj.splice(index, 1);
            }
        });
        this.setState({ bcProtectionLawCheckBox:false});

      }
      

     /* this.setState({ preferencesObj:[{"schemeCode": "INTINTKI_WATER","interested": true,"cardId": "" },
                                    {"schemeCode": "POOR_TO_RICH","interested": true,"cardId": "" },
                                    {"schemeCode": "BC_PROTECTION","interested": true,"cardId": "" }]
        });*/
    }

    this.setState({ [name]: value});
  }

  proccedForRegistrationYG = (resType) => {
    this.setState({ yuvvaGalamCheckedUnemployedModal:false});
    if(resType == "no"){
       this.setState({ yuvvaGalamCheckedCond:"",yuvaGalamCheckBox: !this.state.yuvaGalamCheckBox});
    }
  }

  /*checkInterestValue = (e,interestType,interestName) => {
    this.setState({validateInterests:"",yuvvaGalamCheckedUnemployedModal:false});
    
    let obj={};
        
    if(interestType == 1){
        this.mahaShaktiRef.current.focus();
        if (e.target.checked == true) {
            this.setState({ volunteerGender:"F",mahaShaktiCheckedCond:interestType,schemeCheckVerified:1});
          
        }else{
            this.setState({ volunteerGender:"",mahaShaktiCheckedCond:"",schemeCheckVerified:0});
        }
    }else if(interestType == 3){
        this.annaDataRef.current.focus();
        if (e.target.checked == true) {
            
            let getOccIdName = this.state.occupationListArr.find((a) => a.value == "FARMER_AGRICULTURE");
            this.setState({
                occupationValue: {
                    value: getOccIdName.value,
                    label: getOccIdName.label
                }
            });
             this.setState({ volunteerOccupation:getOccIdName.value,annaDataCheckedCond:interestType,schemeCheckVerified:1});
        }else{
            this.setState({occupationValue:"",volunteerOccupation:"",annaDataCheckedCond:"",schemeCheckVerified:0});
        }
        
    }else if(interestType == 2){
        this.yuvagalamRef.current.focus();
        this.setState({ yuvvaGalamCheckedTF: true});
        if (e.target.checked == true) {
            this.setState({ yuvvaGalamCheckedCond:interestType,yuvvaGalamCheckedUnemployedModal:true,schemeCheckVerified:1});
        }else{
            this.setState({ yuvvaGalamCheckedTF: !this.state.yuvvaGalamCheckedTF});
            this.setState({ yuvvaGalamCheckedCond:"",yuvvaGalamCheckedUnemployedModal:false,schemeCheckVerified:0});
        }
    }else{
        obj ={
            "schemeCode": interestName,
            "interested": true,
            "cardId": ""
        }
        interestArr.push(obj);
        this.setState({ preferencesObj:interestArr,schemeCheckVerified:1 });
    }

    
        
  }*/


  checkInterestValue = (e,interestType,interestName) => {
    this.BGCardRef.current.focus();
    

    if(e.target.checked){
        if(interestType == 1){
            this.setState({ mahaShaktiCheckedCond:1,mahaShaktiCheckBox:true});
        }else if(interestType == 3){
            this.setState({ annaDataCheckedCond:3,annaDataCheckBox:true,noofFarmers:1});
            let getOccIdName = this.state.occupationListArr.find((a) => a.value == "FARMER_AGRICULTURE");
            this.setState({
                occupationValue: {
                    value: getOccIdName.value,
                    label: getOccIdName.label
                }
            });
             this.setState({ volunteerOccupation:getOccIdName.value});
        }else if(interestType == 2){
            this.setState({ yuvvaGalamCheckedCond:2,yuvaGagalamCheckBox:true});
        }else if(interestType == 6){
            this.setState({ bcProtectionLawCheckBox:true});
        }

        this.setState(({preferencesObj}) => ({
            preferencesObj: [...preferencesObj, {
            "schemeCode": interestName,
            "interested": true,
            "cardId": ""
        }]
        }));
    } else {
        if(interestType == 1){
            this.setState({ mahaShaktiCheckedCond:"",mahaShaktiCheckBox:!this.state.mahaShaktiCheckBox,noOfWomen:0}); 
        }else if(interestType == 3){
            this.setState({occupationValue:"",volunteerOccupation:"",annaDataCheckedCond:"",annaDataCheckBox:!this.state.annaDataCheckBox,noofFarmers:0});
        }else if(interestType == 2){
            this.setState({yuvvaGalamCheckedCond:"",yuvaGagalamCheckBox:!this.state.yuvaGagalamCheckBox,noOfUnEmployed:0});
        }else if(interestType == 6){
            this.setState({ bcProtectionLawCheckBox:!this.state.bcProtectionLawCheckBox});
        }
        this.setState(({preferencesObj}) => ({
            preferencesObj: preferencesObj.filter((element) =>  (interestName !== element.schemeCode))
        }));
    }

    

  }
   
    showLocationDVMappingPostions = (position) => {
        const getCoordinates =  
            { 
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude,
            "accuracy":position.coords.accuracy
            }
            
        this.setState({getCurrentLocationCoordinatesForDV:getCoordinates});

    }

   
    ShowLocationPermissionError = (error) => {
        console.log(error)
        switch (error.code){
            case error.PERMISSION_DENIED:
            alert("User Location Permission is Denied,Please Enable Location Services...(Path:Settings > Privacy > Location Services)");
            break;

            case error.POSITION_UNAVAILABLE:
            alert("User Postion Unavailable Denied");
            break;

            default:
                alert("An unknown error occurred")
        }
    }

      getSelfPhyicalCard = () => {
        
        let obj1={};
        this.setState({ validateVolunteerPhysicalCardId:"",
                        validateVolunteerName:"",
                        validateVolunteerGender:"",
                        validateVolunteerAge:"",
                        validateVolunteerOccupation:"",
                        validateVolunteerMobile:"",
                        validateVolunteerVoterCard:"",
                        validateVolunteerConstituencyName:"",
                        validateVolunteerPincode:"",
                        validateVolunteerOTP:"",
                        validateVolunteerMandalMunName:"",
                        validateVolunteerAddress:"",
                        validateInterests:"",
                        validateNOOfChildrens:"",
                        validatenoOfWomen:"",
                        validatenoOfUnEmployed:"",
                        validateCaste:"",
                        validatetotalFamilyMembers:""
                    });


               /* if(this.state.preferencesObj.length == 0){
                    this.setState({validateVolunteerPhysicalCardId:"Please Select Atleast One Scheme...." });
                    return;
                }

                if(this.state.volunteerPhysicalCardId == "" ){
                    this.setState({validateVolunteerPhysicalCardId:"Please Enter Bhavshyatuku Guarantee No..." });
                    return;
                } 

                if(this.state.volunteerPhysicalCardId != "" ){
                    if (this.state.volunteerPhysicalCardId.match(/[a-z]/i)){
                        this.setState({validateVolunteerPhysicalCardId:"Please Enter Numbers Only..." });
                        return;
                    }
                }

                if(this.state.volunteerPhysicalCardId != "" ){
                    if (this.state.volunteerPhysicalCardId.length < 12){
                        this.setState({validateVolunteerPhysicalCardId:"Please Enter 12 Digit CardNo..." });
                        return;
                    }
                }

                if(this.state.volunteerPhysicalCardId != "" ){
                    if (this.state.volunteerPhysicalCardId < 666600000001 || this.state.volunteerPhysicalCardId > 666699999999){
                        this.setState({validateVolunteerPhysicalCardId:"Card No In Between 666600000001 to 666699999999" });
                        return;
                    }
                }*/            

        /*if(this.state.schemeCheckVerified == 0){
            this.setState({validateInterests:"Please Select Atleast One Scheme...." });
            return;
        }


        //MahaShakti
        if(this.state.mahaShaktiCheckedCond == 1 && this.state.mahaShaktiCardId == "" ){
            this.setState({validateInterests:"Please Enter MahaShakti Card No..." });
            return;
        } 

        if(this.state.mahaShaktiCheckedCond == "" && this.state.mahaShaktiCardId != "" ){
            this.setState({validateInterests:"Please Check MahaShakti CheckBox..." });
            return;
        }

        if(this.state.mahaShaktiCheckedCond == 1 && this.state.mahaShaktiCardId != "" ){
            if (this.state.mahaShaktiCardId.match(/[a-z]/i)){
                this.setState({validateInterests:"Please Enter Numbers Only..." });
                return;
            }
        }

        if(this.state.mahaShaktiCheckedCond == 1 && this.state.mahaShaktiCardId != "" ){
            if (this.state.mahaShaktiCardId.length < 12){
                this.setState({validateInterests:"Please Enter 12 Digit CardNo..." });
                return;
            }
        }

        if(this.state.mahaShaktiCheckedCond == 1 && this.state.mahaShaktiCardId != "" ){
            if (this.state.mahaShaktiCardId < 666600000001 || this.state.mahaShaktiCardId > 666699999999){
                this.setState({validateInterests:"MahaShakti Card No In Between 666600000001 to 666699999999" });
                return;
            }
        }
        console.log("dddddddweeeeeeeeeee",this.state.mahaShaktiCheckedCond)
        if(this.state.mahaShaktiCheckedCond == 1){
            obj1 ={
            "schemeCode": "MAHA_SHAKTI",
            "interested": true,
            "cardId": this.state.mahaShaktiCardId
            }
            interestArr.push(obj1);
            console.log("dddddddweeeeeeeeeee",this.state.preferencesObj)
            this.setState({ preferencesObj:interestArr,schemeCheckVerified:1});

        }

        //YuvaGalam
        if(this.state.yuvvaGalamCheckedCond == 2 && this.state.yuvaGalamCardId == "" ){
            this.setState({validateInterests:"Please Enter YuvaGalam Card No  " });
            return;
        } 


        if(this.state.yuvvaGalamCheckedCond == "" && this.state.yuvaGalamCardId != "" ){
            this.setState({validateInterests:"Please Check YuvaGalam CheckBox..." });
            return;
        }

        if(this.state.yuvvaGalamCheckedCond == 1 && this.state.yuvaGalamCardId != "" ){
            if (this.state.yuvaGalamCardId.match(/[a-z]/i)){
                this.setState({validateInterests:"Please Enter Numbers Only..." });
                return;
            }
        }

        if(this.state.yuvvaGalamCheckedCond == 2 && this.state.yuvaGalamCardId != "" ){
            if (this.state.yuvaGalamCardId.length < 12){
                this.setState({validateInterests:"Please Enter 12 Digit CardNo..." });
                return;
            }
        }

        if(this.state.yuvvaGalamCheckedCond == 2 && this.state.yuvaGalamCardId != "" ){
            if (this.state.yuvaGalamCardId < 666600000001 || this.state.yuvaGalamCardId > 666699999999){
                this.setState({validateInterests:"YuvaGalam Card No In Between 666600000001 to 666699999999" });
                return;
            }
        } 
        if(this.state.yuvvaGalamCheckedCond == 2){
            obj1 ={
            "schemeCode": "YUVA_GALAM",
            "interested": true,
            "cardId": this.state.yuvaGalamCardId
            }
            interestArr.push(obj1);
            this.setState({ preferencesObj:interestArr,schemeCheckVerified:1 });
        }

        //AnnaData
        if(this.state.annaDataCheckedCond == 3 && this.state.annaDataCardId == "" ){
            this.setState({validateInterests:"Please Enter AnnaData Card No  " });
            return;
        } 


        if(this.state.annaDataCheckedCond == "" && this.state.annaDataCardId != "" ){
            this.setState({validateInterests:"Please Check AnnaData CheckBox..." });
            return;
        }

        if(this.state.annaDataCheckedCond == 1 && this.state.annaDataCardId != "" ){
            if (this.state.annaDataCardId.match(/[a-z]/i)){
                this.setState({validateInterests:"Please Enter AnnaData Only..." });
                return;
            }
        }

        if(this.state.annaDataCheckedCond == 3 && this.state.annaDataCardId != "" ){
            if (this.state.annaDataCardId.length < 12){
                this.setState({validateInterests:"Please Enter 12 Digit CardNo..." });
                return;
            }
        }

        if(this.state.annaDataCheckedCond == 3 && this.state.annaDataCardId != "" ){
            if (this.state.annaDataCardId < 666600000001 || this.state.annaDataCardId > 666699999999){
                this.setState({validateInterests:"AnnaData Card No In Between 666600000001 to 666699999999" });
                return;
            }
        }
        
        if(this.state.annaDataCheckedCond == 3){
            obj1 ={
            "schemeCode": "RYTHU_NESTAM",
            "interested": true,
            "cardId": this.state.annaDataCardId
            }
            interestArr.push(obj1);
            this.setState({ preferencesObj:interestArr,schemeCheckVerified:1 });
        }
        */
        

        if(this.state.volunteerName == ""){
            scroller.scrollTo("scroll_top", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateVolunteerName:"Please Enter Name" });
            return;
        }

        if(this.state.volunteerGender == ""){
            scroller.scrollTo("scroll_top", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateVolunteerGender:"Please Select Gender" });
            return;
        }

        if(this.state.volunteerAge == ""){
            scroller.scrollTo("scroll_top", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateVolunteerAge:"Please Enter Age" });
            return;
        }

       /* if(!(isNaN(this.state.volunteerAge).match(/^[1-9]?[0-9]{1}$|^100$/))){
            this.setState({validateVolunteerAge:"Please Enter Valid Age" });
            return;
        }*/

       /* if(this.state.mahaShaktiCheckedCond == 1){
            if (isNaN(this.state.volunteerAge) || this.state.volunteerAge < 18 || this.state.volunteerAge > 59){
                scroller.scrollTo("scroll_top", { 
                    duration: 800,
                    delay: 0,
                    smooth: "easeInOutQuart",
                });
                this.setState({validateVolunteerAge:"age in between 18 to 59" });
                return;
            }
        }
        if(this.state.yuvvaGalamCheckedCond == 2){
            if (isNaN(this.state.volunteerAge) || this.state.volunteerAge < 18 || this.state.volunteerAge > 35){
                this.setState({validateVolunteerAge:"age in between 18 to 35" });
                return;
            }
        }*/


        if(this.state.volunteerOccupation == "" || this.state.volunteerOccupation == 0){
            scroller.scrollTo("scroll_two", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateVolunteerOccupation:"Please Select Occupation" });
            return;
        }

        if(this.state.volunteerConstituencyName == "" || this.state.volunteerConstituencyName == 0){
            this.setState({validateVolunteerConstituencyName:"Please Select Constituency Name" });
            return;
        }

        /*if(this.state.casteStr == "" || this.state.casteStr == 0){
            scroller.scrollTo("scroll_two", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateCaste:"Please Select Caste" });
            return;
        }*/

        

        if(this.state.volunteerVoterCard == ""){
            this.setState({validateVolunteerVoterCard:"Please Enter Voter Card Id" });
            return;
        }

        if(this.state.mobileNoCheckingVal == "mobile"){
            if(this.state.volunteerMobile == ""){
                this.setState({validateVolunteerMobile:"Please Enter Mobile No" });
                return;
            }
            if(!this.state.volunteerMobile.match(/^[0-9]+$/)){
                this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
                return;
            }
    
            if(this.state.volunteerMobile.length < 10){
                this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
                return;
            }
    
    
            if(this.state.volunteerOTPNO == ""){
                this.setState({validateVolunteerOTP:"Please Enter OTP" });
                return;
            }
    
            if(this.state.volunteerOTPNO.length < 6){
                this.setState({validateVolunteerOTP:"Please Enter Valid OTP" });
                return;
            }
    
            if(this.state.otpVerifiedMessageStatus == "NotVerified"){
                this.setState({validateVolunteerOTP:"Please Enter Valid OTP" });
                return;
            }
        }


       

        

        this.setState({selfOptionLoderSymbolSave:"show"})
       // console.log("preferencesObj",this.state.preferencesObj)

       var base64ImgListArr=[];
       if(this.state.mobileNoCheckingVal != "mobile"){
            var obj_base64 = this.state.uploadPhotosArr[0].replace("data:image/jpeg;base64,","");
            base64ImgListArr.push(obj_base64);
       }else{
           base64ImgListArr=[];
       }

        fetch('/bot_api/v1/enroll', {
            method: "POST", 
            mode: "cors", 
            cache: "no-cache", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'user_data':window.Telegram.WebApp.initData
            },
            body: JSON.stringify({
                "mgid": parseInt(this.state.volunteerPhysicalCardId),
                "name": this.state.volunteerName,
                "age": parseInt(this.state.volunteerAge),
                "gender": this.state.volunteerGender,
                "occupation": this.state.volunteerOccupation.label,
                "occupationCode": this.state.occupationValue.value,
                "mobileNo": this.state.volunteerMobile,
                "verified": true,
                "constituencyId": this.state.volunteerConstituencyName,
                "pinCode": this.state.volunteerPincode,
                "voterCardId": this.state.volunteerVoterCard,
                "mandalId": 0,
                "townId": 0,
                "address": this.state.volunteerAddress,
                "latitude": this.state.getCurrentLocationCoordinatesForDV.latitude,
                "longitude": this.state.getCurrentLocationCoordinatesForDV.longitude,
                "interestedSchemes": this.state.preferencesObj,
                "casteCategory": this.state.casteStr,
                "noOfChildren": this.state.NOOfChildrens,
                "noOfWomen": this.state.noOfWomen,
                "noOfUnEmployed": this.state.noOfUnEmployed,
                "noOfFarmers":this.state.noofFarmers,
                "totalFamilyMembers": this.state.totalFamilyMembers,
                "boothId": this.state.save_booth_id,
                "voterId": this.state.save_voter_id,
                "base64ImgList":base64ImgListArr
            }), 
          })
          .then((response) => {
            this.setState({selfOptionLoderSymbolSave:"hide"})
            // 1. check response.ok
            if (response.ok) {
              return response.json();
            }
            return Promise.reject(response); // 2. reject instead of throw
          })
          .then((json) => {
            // all good, token is ready
            if(json !=null && json.status == "Success"){
                if(this.state.mobileNoCheckingVal == "mobile"){
                    this.sendSMSForFutureGuaranteeProgram(json.shortUrlCode,json.qrCodeUUID);
                }
                
                this.props.history.push({
                    pathname: "/success_page_location",
                    params: {
                        navbarDisplay:"hide",
                        volunteerDts:this.props?.location?.params?.volunteerDts == undefined ? "" : this.props?.location?.params?.volunteerDts,
                        userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                        volunteerPhysicalCardId:this.state.volunteerPhysicalCardId,
                        volunteerName:this.state.volunteerName,
                        volunteerMobile:this.state.mobileNoCheckingVal == "mobile" ? this.state.volunteerMobile : "-",
                        SelfconstituencyValue:this.state.SelfconstituencyValue,
                        qrCodeUUID:json.qrCodeUUID,
                        enrollId:json.shortUrlCode
                    
                    },
                }); 
            }else if(json.status == "Fail"){
                this.setState({selfOptionMessageSave:json.message});
            }else{
                this.setState({selfOptionMessageSave:"Something Wrong,Please Try Again..."});
            }
            
          })
          .catch((response) => {
            if(response !=null && response.status == "Fail"){
                this.setState({selfOptionMessageSave:response.message});
            }else{
                this.setState({selfOptionMessageSave:"Something Wrong,Please Try Again..."});
            }
            // 3. get error messages, if any
            response.json().then((json) => {
                if(json !=null && json.status == "Fail"){
                    this.setState({selfOptionMessageSave:json.message});
                }else{
                    this.setState({selfOptionMessageSave:"Something Wrong,Please Try Again..."});
                }
            })
          });
          /*.then((res) => res.json())
          .then((json) => {
            this.setState({selfOptionLoderSymbolSave:"hide"});
           
            if(json!=null){
                this.setState({selfOptionMessageSave:"SuccessFully..."});
                setTimeout(() => {
                    //interestArr=[];
                    this.setState({selfOptionMessageSave:""});
                    
                                this.props.history.push({
                                    pathname: "/success_page",
                                    params: {
                                        navbarDisplay:"hide",
                                        volunteerDts:this.props?.location?.params?.volunteerDts == undefined ? "" : this.props?.location?.params?.volunteerDts,
                                        userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                        volunteerPhysicalCardId:this.state.volunteerPhysicalCardId,
                                        volunteerName:this.state.volunteerName,
                                        volunteerMobile:this.state.volunteerMobile,
                                        SelfconstituencyValue:this.state.SelfconstituencyValue
                                       
                                      },
                                  });
                }, 1500);
              }else{
                this.setState({selfOptionMessageSave:"Something Wrong,Please Try Again..."});
              }
          }).catch((error) => {
            this.setState({selfOptionMessageSave:"Something Wrong,Please Try Again..."});
          });*/


        
        
      }


      resendOTPTimer = () => {
        this.setState({minutes:1});
        this.setState({seconds:0});

        this.OTPTimerFunction();
      };

    OTPTimerFunction = () => {
        const interval = setInterval(() => {
            if (this.state.seconds > 0) {
              this.setState({seconds:this.state.seconds - 1});
            }
      
            if (this.state.seconds === 0) {
              if (this.state.minutes === 0) {
                clearInterval(interval);
              } else {
                this.setState({seconds:59});
                this.setState({minutes:this.state.minutes - 1});

              }
            }
          }, 1000);
      
          return () => {
            clearInterval(interval);
          };
    }

    resendOTPNew = () => {
        this.setState({validateVolunteerMobile:"" });
        if(this.state.volunteerMobile == ""){
            this.setState({validateVolunteerMobile:"Please Enter Mobile No" });
            return;
        }
        var expr = /^(0|91)?[6-9][0-9]{9}$/;
        if(!expr.test(this.state.volunteerMobile)){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
          }

        

        if(this.state.volunteerMobile.length < 10){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
        }


        this.setState({resendOTPLoadingSymbol:"show"});
       
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.volunteerMobile,
            
            }); 

            getAPICallData(obj,"sendOtpToMobileNumber")
            .then((res) => res.json())
            .then((data) => {
              this.setState({resendOTPLoadingSymbol:"hide"});
              if(data !=null && data.status == "Success"){
                this.resendOTPTimer();
              }
            });
    }
  
    getMobileNoOTP = () => {
        this.setState({validateVolunteerMobile:"" });
        var expr = /^(0|91)?[6-9][0-9]{9}$/;

        if(this.state.volunteerMobile == ""){
            this.setState({validateVolunteerMobile:"Please Enter Mobile No" });
            return;
        }

        if(!expr.test(this.state.volunteerMobile)){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
          }

        if(this.state.volunteerMobile.length < 10){
            this.setState({validateVolunteerMobile:"Please Enter Valid Mobile No" });
            return;
        }


        this.setState({smsSentMessage:"",getOTPLoadingSymbol:"show",OTPSentStatus:""});
        
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.volunteerMobile,
            
            }); 

            getAPICallData(obj,"sendOtpToMobileNumber")
            .then((res) => res.json())
            .then((data) => {
              this.setState({getOTPLoadingSymbol:"hide"});
              if(data !=null && data.status == "Success"){
                this.setState({smsSentMessage:"OTP Sent Your Mobile No",OTPSentStatus:data.status});
                this.OTPTimerFunction();
              }else{
                this.setState({smsSentMessage:"Some Thing Went Wrong,Please Try Again...",OTPSentStatus:"Fail"});
              }
            });
    }

    


    validateMobileOtp = (otp) => {
        this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.volunteerMobile,
            "otp" : otp
            

            }); 

            getAPICallData(obj,"validateMobileOtp")
            .then((res) => res.json())
            .then((data) => {
               this.setState({otpLoadingSymbol:"hide"});
               if(data !=null && data.status == "Success"){
                this.setState({otpVerifiedMessageStatus:"Verified"});
              }else{
                this.setState({otpVerifiedMessageStatus:"NotVerified"});
              }
            });
    }

    getQRScanCode = (scanType) => {
        this.setState({ loadingScan:true });
        this.setState({QrReaderScanTypeVal:""});
        this.setState({QrReaderModalCodeModal:true,QrReaderScanTypeVal:scanType});
    }

    QRhandleScan = async (data) => {
        this.setState({ scanData:"" });
        if (data && data !== "") {
          console.log(`loaded >>>`, data);
          if(this.state.QrReaderScanTypeVal == "MahaShakti"){
            this.setState({ scanData:data,mahaShaktiCardId:data });
          }else if(this.state.QrReaderScanTypeVal == "YuvaGalam"){
            this.setState({ scanData:data,yuvaGalamCardId:data });
          }else if(this.state.QrReaderScanTypeVal == "AnnaData"){
            this.setState({ scanData:data,annaDataCardId:data });
          }else if(this.state.QrReaderScanTypeVal == "singleCard"){
            this.setState({ scanData:data,volunteerPhysicalCardId:data });
          }
          
          this.setState({QrReaderModalCodeModal:false});
          this.setState({ loadingScan:false });
      }
    }
  
    QRhandleError = (err) => {
      console.error(err);
    };


    addAudioElement = (blob) => {
        this.setState({ audioSourceURL:"",base64StrVoiceRecorded:"" });
        const url = URL.createObjectURL(blob);
        this.setState({ audioSourceURL:url });

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend =  () => {
            var base64String = reader.result;
            setTimeout(() => { 
                this.setState({ base64StrVoiceRecorded: base64String});
            }, 500);
        }
    }

    saveUserVoiceForFutureGuarantee = () => {
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "voterCardNo" : this.state.volunteerVoterCard,
            "base64Str" : this.state.base64StrVoiceRecorded
            }); 

            getAPICallData(obj,"saveUserVoiceForFutureGuarantee")
            .then((res) => res.json())
            .then((data) => {
              if(data !=null && data.status == "Success"){
                this.setState({ recordedMsg: "Recorded SuccessFully"});
              }
            });
    }

    sendSMSForFutureGuaranteeProgram = (shortUrlCode,qrCodeUUID) => {
        const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.volunteerMobile,
            "name" : this.state.volunteerName,
            "mgid" : this.state.volunteerPhysicalCardId.toString(),
            "url" : `www.tdpmanifesto.com/p/?i=${shortUrlCode}`

        }); 

        getAPICallData(obj,"sendConformationSMSForFGProgramEnrollment")
        .then((res) => res.json())
        .then((data) => {
          if(data !=null && data.status == "Success"){
            this.setState({ smsForBG: "SMS Sent SuccessFully"});
          }
        });
    }

    mobileNoCheckingType = (e,type) => {
        if(type == "notInterested"){
            this.setState({ mobileNoSuccessMsg:"SUCCESS",showImgCapture:true,imageAddedMsg:""});
        }else{
            this.setState({ mobileNoSuccessMsg:"",showImgCapture:false,imageAddedMsg:""});
        }

        this.setState({ mobileNoCheckingVal: type,uploadPhotosArr:[],volunteerMobile:""});
    }
      
    toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

    uploadPhotosForBG(e) {
        let fileObj=[];
        fileObj.push(e.target.files);
            for (let i = 0; i < fileObj[0].length; i++) {
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                   
                    this.setState({uploadPhotosArr:[...this.state.uploadPhotosArr, dataUrl]});
                    console.log("preferencesObj",this.state.uploadPhotosArr)
                });
            }
      }

      switchCamara = () => {
        this.setState({videoConstraints:{facingMode: "environment"}});
      }
      frontCamara = () => {
        this.setState({videoConstraints:{facingMode: "user"}});
      }

      retakePhoto = () => {
        this.setState({showImgCapture:true,imageAddedMsg:"",uploadPhotosArr:[]});
      }
      capture = () => {
        this.setState({showImgCapture:true,imageAddedMsg:""});
        let base64url = this.webcamRef.current.getScreenshot();

          this.setState({uploadPhotosArr:[...this.state.uploadPhotosArr, base64url]});   
          console.log("preferencesObj",base64url) 
          
          if(base64url != ""){
            this.setState({imageAddedMsg:"added successfully...",showImgCapture:false});
          }
          
    };
    openModal () {
        this.setState({isOpen: true})
      }

    render() {
        console.log("preferencesObj",this.state)
        return (
            <section className="mb-4">
               <div className="pos_relative">
                    <div class="text-white">
                    <img  class="bg_css_volunter_logo" />
                    <div class="card-img-overlay">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="flex-header mt-1">
                                <img className="center-auto w-80P" src={MF_logo1} />
                                
                                </div>
                            </div>
                        </div>
                        <Link to={{pathname: "/volunteer_page",
                                    params: {
                                        navbarDisplay:"hide",
                                        volunteerDts:this.state.volunteerDts,
                                        userAccessList:this.state.userAccessList,
                                    }}}> 
                            <Button variant="outline-light ms-3 mt-3 pad5_14 back_button" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Link>
                    </div>
                    </div>
                </div>
                
                <Container>

                  {
                                this.state.selfOptionScreenType == "slide_one" ?
                                (
                                    <div className={`slide_one_css scroll_top`}>
                                        <Row>
                                            <Col>
                                                
                                                <Form>
                                                    {/*<Form.Group className="mt-2" controlId="formPhyicalCardId">
                                                        <h6 className='f-12 fw-bold'>Card Id<span className='color_red f-9'>{this.state.validateVolunteerPhysicalCardId}</span> 
                                                        <Link to={{
                                                                pathname: "/qr_reader",
                                                                params: {
                                                                    navbarDisplay:"hide",
                                                                    volunteerDts:this.state.volunteerDts
                                                                }
                                                            }}>
                                                            <i class="fa-solid fa-qrcode float-right f-28 ms-4 scanner_css" ></i> 
                                                        </Link>
                                                        
                                                        </h6>
                                                        <Form.Control type="text" name="volunteerPhysicalCardId" value={this.state.volunteerPhysicalCardId} className='form-control-sm'  placeholder="Enter Card ID" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                        <h6 className='f-12 mt-2 color_red'>Note : click Scan QR Code From Card.</h6>
                                                    </Form.Group>*/}

                                                    {/*<Row>
                                                        <Col>
                                                            <label className="form-label f-12 fw-bold  mt-2">Interests <span className='color_red f-9'>{this.state.validateInterests}</span></label>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Form className="check_box_size">
                                                                        {
                                                                            this.state.volunteerGender == "M" ||  this.state.volunteerGender == "m" || this.state.volunteerGender == "male" ?
                                                                            (
                                                                                <div className='flex-interests-align'>
                                                                                    <Form.Check
                                                                                        disabled
                                                                                        inline
                                                                                        label={<img className="center-auto w_90_R" src={MS_logo} />}
                                                                                        name="interestsId"
                                                                                        type="checkbox"
                                                                                        id="maha_shakti_id"
                                                                                        onChange={(e)=>this.checkInterestValue(e,"1","MAHA_SHAKTI")}
                                                                                        className="f-12 fw-bold color_black input_size"
                                                                                        defaultChecked={false}
                                                                                    />
                                                                                    <span className='me-2 ml_15'>-</span>
                                                                                    <Form.Control  type="text" name="mahaShaktiCardId" maxLength={12} ref={this.mahaShaktiRef} value={this.state.mahaShaktiCardId} className='form-control-sm'  placeholder="Enter MahaShakti Card No" disabled="disabled" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (
                                                                                <div className='flex-interests-align'>
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label={<img className="center-auto w_90_R" src={MS_logo} />}
                                                                                        name="interestsId"
                                                                                        type="checkbox"
                                                                                        id="maha_shakti_id"
                                                                                        onChange={(e)=>this.checkInterestValue(e,"1","MAHA_SHAKTI")}
                                                                                        className="f-12 fw-bold color_black input_size"
                                                                                        defaultChecked={false}
                                                                                    />
                                                                                    <span className='me-2 ml_15'>-</span>
                                                                                    <Form.Control  type="text" name="mahaShaktiCardId" maxLength={12} ref={this.mahaShaktiRef} value={this.state.mahaShaktiCardId} className='form-control-sm'  placeholder="Enter MahaShakti Card No" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                                                    <i class="fa-solid fa-qrcode float-right f-20 ms-2 mt_1 scanner_css" onClick={() => this.getQRScanCode("MahaShakti")}></i> 
                                                                                </div>
                                                                            )
                                                                        }
                                                                        
                                                                        <div className='flex-interests-align'>
                                                                            <Form.Check
                                                                                inline
                                                                                label={<img className="center-auto w_60_R" src={YG_logo} />}
                                                                                name="interestsId"
                                                                                type="checkbox"
                                                                                id="yuva_galam_id"
                                                                                onChange={(e)=>this.checkInterestValue(e,"2","YUVA_GALAM")}
                                                                                className="f-12 fw-bold color_black input_size"
                                                                                defaultChecked={this.state.yuvaGalamCheckBox}
                                                                            />
                                                                            <span className='ml_28 me-2'>-</span>
                                                                            <Form.Control type="text" name="yuvaGalamCardId" maxLength={12} ref={this.yuvagalamRef} value={this.state.yuvaGalamCardId} className='form-control-sm'  placeholder="Enter YuvaGalam Card No" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                                            <i class="fa-solid fa-qrcode float-right f-20 ms-2 mt_1 scanner_css" onClick={() => this.getQRScanCode("YuvaGalam")}></i> 
                                                                        </div>
                                                                        <div className='flex-interests-align'>
                                                                            <Form.Check
                                                                                inline
                                                                                label={<img className="center-auto w_60_R" src={AD_logo} />}
                                                                                name="interestsId"
                                                                                type="checkbox"
                                                                                id="ad_id"
                                                                                onChange={(e)=>this.checkInterestValue(e,"3","RYTHU_NESTAM")}
                                                                                className="f-12 fw-bold color_black input_size"
                                                                                defaultChecked={false}
                                                                            />
                                                                            <span className='ml_28 me-2'>-</span>
                                                                            <Form.Control type="text" name="annaDataCardId"  maxLength={12} ref={this.annaDataRef} value={this.state.annaDataCardId} className='form-control-sm'  placeholder="Enter AnnaData Card No" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                                            <i class="fa-solid fa-qrcode float-right f-20 ms-2 mt_1 scanner_css" onClick={() => this.getQRScanCode("AnnaData")}></i> 
                                                                        </div>
                                                                        
                                                                        
                                                                    </Form>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <Form className="check_box_size">
                                                                        <Form.Check
                                                                            inline
                                                                            label={<img className="center-auto w_90_R" src={HW_logo} />}
                                                                            name="interestsId"
                                                                            type="checkbox"
                                                                            id="house_water_id"
                                                                            onChange={(e)=>this.checkInterestValue(e,"4","INTINTKI_WATER")}
                                                                            className="f-12 fw-bold color_black input_size"
                                                                            defaultChecked={false}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label={<img className="center-auto w_90_R" src={PTR_logo} />}
                                                                            name="interestsId"
                                                                            type="checkbox"
                                                                            id="ptr_id"
                                                                            onChange={(e)=>this.checkInterestValue(e,"5","POOR_TO_RICH")}
                                                                            className="f-12 fw-bold color_black input_size"
                                                                            defaultChecked={false}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label={<img className="center-auto w_90_R" src={BC_logo} />}
                                                                            name="interestsId"
                                                                            type="checkbox"
                                                                            id="bc_id"
                                                                            onChange={(e)=>this.checkInterestValue(e,"6","BC_PROTECTION")}
                                                                            className="f-12 fw-bold color_black input_size"
                                                                            defaultChecked={false}
                                                                        />
                                                                        
                                                                        </Form>
                                                                </Col>
                                                                <h6 className='f-12 mt-2 color_red'>Note : click Scan QR Code From Card.</h6>
                                                            </Row>
                                                        </Col>
                                                    </Row>*/}

                                                    <Row>
                                                     <Col sm={12}>
                                                        <Form className="check_box_size">
                                                            {
                                                                this.state.volunteerGender == "M" ||  this.state.volunteerGender == "m" || this.state.volunteerGender == "male" ?
                                                                (
                                                                    <Form.Check
                                                                        inline
                                                                        label={<img className="center-auto w_90_R" src={MS_logo} />}
                                                                        name="interestsId"
                                                                        type="checkbox"
                                                                        id="maha_shakti_id"
                                                                        onChange={(e)=>this.checkInterestValue(e,"1","MAHA_SHAKTI")}
                                                                        className="f-12 fw-bold color_black input_size_MS"
                                                                        checked={this.state.mahaShaktiCheckBox}
                                                                    />
                                                                )
                                                                :
                                                                (
                                                                    <Form.Check
                                                                        inline
                                                                        label={<img className="center-auto w_90_R" src={MS_logo} />}
                                                                        name="interestsId"
                                                                        type="checkbox"
                                                                        id="maha_shakti_id"
                                                                        onChange={(e)=>this.checkInterestValue(e,"1","MAHA_SHAKTI")}
                                                                        className="f-12 fw-bold color_black input_size_MS"
                                                                        checked={this.state.mahaShaktiCheckBox}
                                                                    />
                                                                )
                                                                }
                 
                                                                <Form.Check
                                                                    inline
                                                                    label={<img className="center-auto w_60_R" src={YG_logo} />}
                                                                    name="interestsId"
                                                                    type="checkbox"
                                                                    id="yuva_galam_id"
                                                                    onChange={(e)=>this.checkInterestValue(e,"2","YUVA_GALAM")}
                                                                    className="f-12 fw-bold color_black input_size"
                                                                    //checked={this.state.yuvvaGalamCheckedTF}
                                                                    checked={this.state.yuvaGagalamCheckBox}
                                                                    //defaultChecked={false}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label={<img className="center-auto w_60_R" src={AD_logo} />}
                                                                    name="interestsId"
                                                                    type="checkbox"
                                                                    id="ad_id"
                                                                    onChange={(e)=>this.checkInterestValue(e,"3","RYTHU_NESTAM")}
                                                                    className="f-12 fw-bold color_black input_size_AD"
                                                                    checked={this.state.annaDataCheckBox}
                                                                />
                                                        </Form>
                                                    </Col>
                                                     <Col sm={12}>
                                                        <Form className="check_box_size">
                                                            <Form.Check
                                                                inline
                                                                label={<img className="center-auto w_90_R" src={HW_logo} />}
                                                                name="interestsId"
                                                                type="checkbox"
                                                                id="house_water_id"
                                                                onChange={(e)=>this.checkInterestValue(e,"4","INTINTKI_WATER")}
                                                                className="f-12 fw-bold color_black input_size"
                                                                defaultChecked={false}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label={<img className="center-auto w_90_R" src={PTR_logo} />}
                                                                name="interestsId"
                                                                type="checkbox"
                                                                id="ptr_id"
                                                                onChange={(e)=>this.checkInterestValue(e,"5","POOR_TO_RICH")}
                                                                className="f-12 fw-bold color_black input_size"
                                                                defaultChecked={false}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label={<img className="center-auto w_90_R" src={BC_logo} />}
                                                                name="interestsId"
                                                                type="checkbox"
                                                                id="bc_id"
                                                                onChange={(e)=>this.checkInterestValue(e,"6","BC_PROTECTION")}
                                                                className="f-12 fw-bold color_black input_size"
                                                                checked={this.state.bcProtectionLawCheckBox}
                                                            />
                                                            
                                                            </Form>
                                                        </Col>
                                                     </Row>
                                                     <h6 className='color_red f-14 mt-3 mb-4'>Note : ఓటరుకి అర్హత కలిగిన పథకాలు మాత్రమే ఈ రిజిస్ట్రేషన్ ఫారం నందు ఎంపిక చేయగలరు.</h6>
                                                     
                                                     <fieldset class=""><legend>Personal Details</legend>            
                                                    <Form.Group className="mt-2" controlId="formPhyicalCardId">
                                                        <h6 className='f-12 fw-bold'>Bhavshyatuku Guarantee No
                                                        </h6>
                                                        <div className='flex-interests-align'>
                                                            <Form.Control type="text" name="volunteerPhysicalCardId" disabled ref={this.BGCardRef} value={this.state.volunteerPhysicalCardId} className='form-control-sm' maxLength={12} placeholder="Enter Bhavshyatuku Guarantee No" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)} />
                                                            {/*<i class="fa-solid fa-qrcode float-right f-20 ms-2 mt_1 scanner_css" onClick={() => this.getQRScanCode("singleCard")}></i> */}
                                                        </div>
                                                        <h6 className='color_red f-9 mt-2 fw-bold'>{this.state.validateVolunteerPhysicalCardId} </h6> 
                                                        {/*<h6 className='f-12 mt-2 color_red'>Note : click Scan QR Code From Card.</h6>*/}
                                                        
                                                    </Form.Group>
                                                        <Form.Group className="scroll_two" controlId="formNameId">
                                                            <Form.Label className='f-12 fw-bold'>Name/పేరు:  <span className='color_red f-9'>{this.state.validateVolunteerName}</span></Form.Label>
                                                            <Form.Control type="text" name="volunteerName" value={this.state.volunteerName}  className='form-control-sm'  placeholder="Enter Name" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                        </Form.Group>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mt-3" controlId="formGenderId">
                                                                <Form.Label className='f-12 fw-bold'>Gender/లింగం: <span className='color_red f-9'>{this.state.validateVolunteerGender}</span></Form.Label>
                                                                {
                                                                    this.state.volunteerGender == "F" ||  this.state.volunteerGender == "f" || this.state.volunteerGender == "female" ?
                                                                    (
                                                                        <select id="genderId" name="volunteerGender" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                                                            <option value="">Select Gender</option>
                                                                            <option value="F" selected>Female</option>
                                                                        </select>
                                                                    )
                                                                    :
                                                                    (
                                                                        this.state.volunteerGender == "M" ||  this.state.volunteerGender == "m" || this.state.volunteerGender == "male" ?
                                                                        (
                                                                            <select id="genderId" name="volunteerGender" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                                                                <option value="">Select Gender</option>
                                                                                <option value="M" selected>Male</option>
                                                                            </select>
                                                                        )
                                                                        :
                                                                        (
                                                                            <select id="genderId" name="volunteerGender" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                                                                <option value="">Select Gender</option>
                                                                                <option value="M">Male</option>
                                                                                <option value="F" >Female</option>
                                                                            </select>
                                                                        )
                                                                    )
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className='mt-3' controlId="formAgeId">
                                                                <Form.Label className='f-12 fw-bold'>Age/వయస్సు:  <span className='color_red f-9'>{this.state.validateVolunteerAge}</span></Form.Label>
                                                                <Form.Control name="volunteerAge" value={this.state.volunteerAge} className='form-control-sm' type="text" placeholder="Enter Age" maxLength={2} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mt-3" controlId="formConstituencyNameId">
                                                                <Form.Label className='f-12 fw-bold'>Constituency/నియోజకవర్గం:  <span className='color_red f-9'>{this.state.validateVolunteerConstituencyName}</span></Form.Label>
                                                                <SelectBox
                                                                    stateData={this.state.SelfconstituencyValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="volunteerConstituencyName"
                                                                    options={this.state.ap_listArr}
                                                                    placeholderName={'Constituency'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mt-3" controlId="formVoterCardId">
                                                                <Form.Label className='f-12 fw-bold'>Voter Card No/ఓటర్ కార్డ్:  <span className='color_red f-9'>{this.state.validateVolunteerVoterCard}</span></Form.Label>
                                                                <Form.Control type="text" name="volunteerVoterCard" value={this.state.volunteerVoterCard} className='form-control-sm'  placeholder="Enter Voter Card ID" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mt-3" controlId="formOccupationId">
                                                                <Form.Label className='f-12 fw-bold'>Occupation/వృత్తి:<span className='color_red f-14 ms-1'>*</span>  <span className='color_red f-9'>{this.state.validateVolunteerOccupation}</span></Form.Label>
                                                                <SelectBox
                                                                    stateData={this.state.occupationValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="volunteerOccupation"
                                                                    options={this.state.occupationListArr}
                                                                    placeholderName={'Occupation'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                            </Form.Group>   
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mt-3" controlId="formOccupationId">
                                                                <Form.Label className='f-12 fw-bold'>Caste/కులం:<span className='color_red f-14 ms-1'>*</span>  <span className='color_red f-9'>{this.state.validateCaste}</span></Form.Label>
                                                                <SelectBox
                                                                    stateData={this.state.casteValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew}
                                                                    name="casteStr"
                                                                    options={this.state.casteListArr}
                                                                    placeholderName={'Select Caste'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                            </Form.Group>
                                                            </Col>

                                                    </Row>
                                                    </fieldset>
                                                   
                                                    <fieldset class=""><legend>Family Details</legend>
                                                    <Row>
                                                        <Col className='mt-3'>
                                                            <Form.Group  controlId="formtotalFamilyMembersId">
                                                                <Form.Label className='f-12 fw-bold'>Total Family Members/మొత్తం కుటుంబ సభ్యులు: <span className='color_red f-9'>{this.state.validatetotalFamilyMembers}</span></Form.Label>
                                                                <Form.Control name="totalFamilyMembers" className='form-control-sm' maxLength={2} type="text" value={this.state.totalFamilyMembers} placeholder="Enter Family Members" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                            </Form.Group>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row>
                                                        <Col className='mt-2'>
                                                            <Form.Group  controlId="formNoOfChildrensId">
                                                                <Form.Label className='f-12 fw-bold'>No Of Childrens/ఎంతమంది పిల్లలు (18 సంవత్సరాలు లోపు): <span className='color_red f-9'>{this.state.validateNOOfChildrens}</span></Form.Label>
                                                                <Form.Control name="NOOfChildrens" className='form-control-sm' maxLength={1} type="text" value={this.state.NOOfChildrens} placeholder="Enter Childrens" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                            </Form.Group>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row>
                                                        <Col className='mt-2'>
                                                            <Form.Group  controlId="formnoOfWomenId">
                                                                <Form.Label className='f-12 fw-bold'>No Of Women/ఎంతమంది మహిళలు (18 సంవత్సరాలు పైన): <span className='color_red f-9'>{this.state.validatenoOfWomen}</span></Form.Label>
                                                                <Form.Control name="noOfWomen" className='form-control-sm' type="text" maxLength={2} value={this.state.noOfWomen} placeholder="Enter No Of Women" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                            </Form.Group>
                                                        </Col>
                                                        
                                                    </Row>
                                                    
                                                    <Row>
                                                       
                                                        <Col className='mt-2'>
                                                            <Form.Group  controlId="formnoOfUnEmployedId">
                                                                <Form.Label className='f-12 fw-bold'>No Of UnEmployed/ఎంతమంది నిరుద్యోగులు: <span className='color_red f-9'>{this.state.validatenoOfUnEmployed}</span></Form.Label>
                                                                <Form.Control name="noOfUnEmployed" className='form-control-sm' type="text" maxLength={2} value={this.state.noOfUnEmployed} placeholder="Enter No Of UnEmployed" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    </fieldset>
                                                    
                                                    <Row>
                                                        <Col>
                                                        {/*<Form.Label className='f-12 fw-bold'>Address<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateVolunteerAddress}</span></Form.Label>    
                                                            <FloatingLabel
                                                                controlId="addressId"
                                                                label="Address"
                                                                className="f-12"
                                                                
                                                            >
                                                                <Form.Control as="textarea" placeholder="Enter Address" name="volunteerAddress" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                            </FloatingLabel>
                                                            <Form.Group className="" controlId="formPincodeId">
                                                                <Form.Label className='f-12 fw-bold'>Pincode <span className='color_red f-9'>{this.state.validateVolunteerPincode}</span></Form.Label>
                                                                <Form.Control type="text" name="volunteerPincode" className='form-control-sm'  placeholder="Enter Pincode" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                            </Form.Group>*/}
                                                        </Col>
                                                            </Row>
                                                   
                                                   {/* <Row>
                                                    <Col sm={12}>
                                                        <Form className="mt-2">
                                                            <Form.Check
                                                                inline
                                                                label="Mobile No"
                                                                name="mobileNoChecking"
                                                                type="radio"
                                                                id="mobileNoCheckingId"
                                                                onChange={(e)=>this.mobileNoCheckingType(e,"mobile")}
                                                                className="f-12 fw-bold color_black"
                                                                defaultChecked={true}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Not Interested to share mobile no"
                                                                name="mobileNoChecking"
                                                                type="radio"
                                                                id="notInterestedId"
                                                                onChange={(e)=>this.mobileNoCheckingType(e,"notInterested")}
                                                                className="f-12 fw-bold color_black"
                                                            />
                                                            </Form>
                                                        </Col>
                                                        </Row>*/}
                                                    {
                                                        this.state.mobileNoCheckingVal == "mobile" ?
                                                        (
                                                            <fieldset class=""><legend>OTP Verification</legend>
                                                            <Row>
                                                                <Col className='mt-2'>
                                                                    <Form>
                                                                        <Form.Group className="" controlId="formMobileId">
                                                                            <Form.Label className='f-12 fw-bold'>Mobile No/ఫోన్ నెంబర్:<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateVolunteerMobile}</span>
                                                                                {
                                                                                    this.state.mobileNoLoadingSymbol == "show" ?
                                                                                    (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls1'></Image>)
                                                                                    :
                                                                                    (
                                                                                        ""
                                                                                    )
                                                                                }
                                                                                {
                                                                                    this.state.mobileNoSuccessMsg == "SUCCESS"  ?
                                                                                    (<i class="fa-regular fa-circle-check color_green f-14"></i>)
                                                                                    :
                                                                                    (
                                                                                        this.state.mobileNoSuccessMsg == "FAIL" || this.state.mobileNoSuccessMsg == "Something Wrong,Please Try Again..." ?
                                                                                        (<i class="fa-regular fa-circle-xmark color_red f-14"></i>)
                                                                                        :
                                                                                        ("")
                                                                                        
                                                                                    )
                                                                                }
                                                                                
                                                                            </Form.Label>
                                                                            <Row>
                                                                                <Col className='width_90_static'> 
                                                                                    <Form.Control type="text" name="volunteerMobile" className='form-control-sm' maxLength={10} value={this.state.volunteerMobile}  placeholder="Enter Mobile No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                                                </Col>
                                                                                <Col className='width_10_static'>
                                                                                    
                                                                                    {
                                                                                        this.state.mobileNoSuccessMsg == "SUCCESS" || this.state.mobileNoSuccessMsg == ""  ?
                                                                                        (
                                                                                            <>
                                                                                            {
                                                                                                this.state.OTPSentStatus == "Fail" || this.state.OTPSentStatus == "" ?
                                                                                                (
                                                                                                    <>
                                                                                                    <span className='f-12 color_red'>{this.state.smsSentMessage}</span>
                                                                                                        <Button variant="dark" className='f-12 float-right  btn-sm' onClick={() => this.getMobileNoOTP()}>GET OTP
                                                                                                            {
                                                                                                                this.state.getOTPLoadingSymbol == "show" ?
                                                                                                                (<Image src={loader} alt="loader" className='get_otp_loading_symbol_cls22'></Image>)
                                                                                                                :
                                                                                                                ("")
                                                                                                            }  
                                                                                                        </Button> 
                                                                                                    </>
                                                                                                )
                                                                                                :
                                                                                                ("")
                                                                                            }
                                                                                            </>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                            {
                                                                                this.state.mobileNoSuccessMsg == "SUCCESS" || this.state.mobileNoSuccessMsg == ""  ?
                                                                                (
                                                                                    ""
                                                                                )
                                                                                :
                                                                                (
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <span className='mt-3 color_red fw-bold f-12 pos_top_m'>This mobile no already registered, use another mobile no</span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    
                                                                                )
                                                                            }
                                                                            
                                                                            
                                                                                
                                                                                
                                                                        </Form.Group>  
                                                                    </Form>
                                                                    <Form>
                                                                        <Row>
                                                                            <Col className='width_90_static'>
                                                                                <Form.Group className="mt-4" controlId="formOTPId">
                                                                                    <Form.Label className='f-12 fw-bold'>OTP No<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateVolunteerOTP}</span></Form.Label>
                                                                                    <Form.Control type="text" name="volunteerOTPNO" maxLength={6} className='form-control-sm' value={this.state.volunteerOTPNO}  placeholder="Enter OTP No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                                                </Form.Group> 
                                                                            </Col>
                                                                            <Col className='width_10_static mt-5'>
                                                                                {
                                                                                    this.state.mobileNoSuccessMsg == "SUCCESS" && this.state.otpVerifiedMessageStatus == "Verified" ? 
                                                                                    (<Button variant="success" className='center-auto mt-4 f-14 fw-bold btn-sm' onClick={() => this.getSelfPhyicalCard()}>REGISTER
                                                                                        {
                                                                                            this.state.selfOptionLoderSymbolSave === "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                                                                        }

                                                                                    </Button>)
                                                                                    :
                                                                                    (<Button variant="success" disabled className='center-auto mt-4 f-14 fw-bold btn-sm' onClick={() => this.getSelfPhyicalCard()}>REGISTER
                                                                                         {
                                                                                            this.state.selfOptionLoderSymbolSave === "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                                                                         }

                                                                                    </Button>)
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        
                                                                            <Row>
                                                                                <Col>
                                                                                    {
                                                                                        this.state.OTPSentStatus == "Success"?
                                                                                        (
                                                                                            <>
                                                                                                <h6 className='mt-2 f-12 color_green line_height_20'>{this.state.smsSentMessage} : <b >{this.state.volunteerMobile}</b></h6>
                                                                                                
                                                                                                {
                                                                                                    this.state.otpVerifiedMessageStatus != "Verified" || this.state.otpVerifiedMessageStatus == "" ?
                                                                                                    (
                                                                                                        <div className="countdown-text">
                                                                                                            {this.state.seconds > 0 || this.state.minutes > 0 ? (
                                                                                                            <h6 className='mt-2 f-14'>
                                                                                                                Time Remaining: {this.state.minutes < 10 ? `0${this.state.minutes}` : this.state.minutes}:
                                                                                                                {this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}
                                                                                                            </h6>
                                                                                                            ) : (
                                                                                                            <h6 className='mt-2 f-12'>Didn't recieve code?</h6>
                                                                                                            )}
                                                                                                            {
                                                                                                                this.state.resendOTPLoadingSymbol == "show" ?
                                                                                                                (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                                                                :
                                                                                                                ("")
                                                                                                            } 
                                                                                                            <Button variant="dark" className='f-12 float-right mt-2' 
                                                                                                            disabled={this.state.seconds > 0 || this.state.minutes > 0}
                                                                                                            style={{
                                                                                                                color: this.state.seconds > 0 || this.state.minutes > 0 ? "#DFE3E8" : "#fff",
                                                                                                            }} onClick={this.resendOTPNew}  >Resend OTP</Button>
                                                                                                            
                                                                                                        </div>
                                                                                                    )
                                                                                                    :
                                                                                                    ("")
                                                                                                }
                                                                                            
                                                                                                
                                                                                            </>
                                                                                        )
                                                                                        :
                                                                                        ("")
                                                                                    }
                                                                                </Col>
                                                                            </Row>

                                                                            <Row>
                                                                                <Col>
                                                                                    {
                                                                                            this.state.otpLoadingSymbol == "show" ?
                                                                                            (<Image src={loader} alt="loader" className='loader_cls_otp ms-2'></Image>)
                                                                                            :
                                                                                            (
                                                                                                this.state.otpVerifiedMessageStatus == "Verified" ?
                                                                                                (

                                                                                                    <h6 class="color_green fw-bold f-14  text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>OTP verified successfully</h6>
                                                                                                    
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    this.state.otpVerifiedMessageStatus == "NotVerified" ?
                                                                                                    (<h6 class="color_red fw-bold f-14 text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>Not Verified,Please Enter Valid OTP</h6>)
                                                                                                    :
                                                                                                    ("")
                                                                                                    
                                                                                                
                                                                                                )
                                                                                        )
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        
                                                                    </Form>    
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                
                                                                <h6 className='f-14 fw-bold text-center mt-2'>{this.state.selfOptionMessageSave}</h6>
                                                                </Col>
                                                            </Row>
                                                            </fieldset>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                            <Row>
                                                                <div>
                                                                    <Col className="mt-2">
                                                                    <h6 className='f-12 fw-bold ln_30'>మీరు ఓటరు కుటుంబానికి ఇస్తున్న భవిష్యతుకు గ్యారెంటీ సర్టిఫికేట్ నీ ఫోటో తీసి ఇక్కడ అప్‌లోడ్ చేయవలసింది : <span className='color_red f-14 ms-1'>*</span></h6>
                                                                        <Modal onOpen={this.state.isOpen}>
                                                                            <div className="holder">
                                                                                <h6 className='cursor_pointer float-end color_red f-12 text-decoration-underline' onClick={this.openModal}>Sample Certificate To Upload</h6>
                                                                            </div>
                                                                        </Modal>
                                                                        {this.state.isOpen && (
                                                                            <ModalContent onClose={() => this.setState({isOpen: false})}>
                                                                            <img src={require("../../images/sample_cer.jpeg")} alt="image" className='w-100' />
                                                                            </ModalContent>
                                                                        )}
                                                                        <div className="showImgCaptureDiv">{this.state.showImgCapture && (
                                                                        <> 
                                                                        <Webcam
                                                                            audio={false}
                                                                            ref={this.webcamRef}
                                                                            screenshotFormat="image/jpeg"
                                                                            videoConstraints={this.state.videoConstraints}
                                                                        /> 
                                                                        
                                                                        
                                                                        <div className="row mt-2">
                                                                            <div className="col">
                                                                                <h6 onClick={() => this.capture()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                                                            </div>
                                                                            <div className="col">
                                                                                {
                                                                                this.state.videoConstraints.facingMode == "user" ?
                                                                                (<h6 className="switch_camara text-center" onClick={() => this.switchCamara()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                                                                :
                                                                                ( <h6 className="switch_camara text-center" onClick={() => this.frontCamara()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        </>
                                                                        
                                                                        )}
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                            </Row>
 
                                                            {
                                                                this.state.uploadPhotosArr.length>0 ?
                                                                (
                                                                    <>
                                                                    <Row>
                                                                        <Col sm={12} className="">
                                                                            <div className='flex_img'>
                                                                                {(this.state.uploadPhotosArr || []).map((url,index) => (
                                                                                    <img src={url} className='img-thumbnail' alt="certificate image" />
                                                                                ))} 
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Button variant="dark" className='f-12 float-right mt-2 btn-sm' onClick={() => this.retakePhoto()}>RE-TAKE</Button>          
                                                                        </Col>
                                                                    </Row>
                                                                
                                                                </>
                                                                
                                                                )
                                                                :
                                                                ("")
                                                            }
                                                            </>
                                                        )
                                                    }
                                                    

                                                    
                                                </Form>
                                                

                                            </Col>

                                            
                                        </Row>
                                        {/*<Row>
                                            <Col>
                                                <Form.Label className='f-12 fw-bold mt-2'>Voice Recorded </Form.Label>    
                                                <VoiceRecorder addAudioElement={this.addAudioElement} stateData={this.state} />
                                            </Col>
                                        </Row>*/}
                                        {/*<Row>
                                            <Col>
                                                {
                                                    this.state.mobileNoSuccessMsg == "SUCCESS" ? 
                                                    (<Button variant="success" className='center-auto mt-4 f-14 fw-bold' onClick={() => this.getSelfPhyicalCard()}>REGISTER</Button>)
                                                    :
                                                    (<Button variant="success" disabled className='center-auto mt-4 f-14 fw-bold' onClick={() => this.getSelfPhyicalCard()}>REGISTER</Button>)
                                                }
                                                
                                            </Col>
                                            </Row>*/}

                                        
                                    </div>
                                )
                                :
                                (
                                    ""
                                )
                            }

                
                    
                </Container>
                <YuvaGalamModal 
                        show={this.state.yuvvaGalamCheckedUnemployedModal}
                        onHide={() => this.setState({ yuvvaGalamCheckedUnemployedModal: false })}
                        stateData={this.state}
                        proccedForRegistrationYG={this.proccedForRegistrationYG}
                />
                <QrReaderModalCode 
                        show={this.state.QrReaderModalCodeModal}
                        onHide={() => this.setState({ QrReaderModalCodeModal: false })}
                        stateData={this.state}
                        PQRhandleError={this.QRhandleError}
                        PQRhandleScan ={this.QRhandleScan}
                />
               
            
            </section>
        )
    }
  }
  
  export default withRouter(VoterSearchEnrollSaveLocation);
  