import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';


class EnabledLocationPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }
    render() {
        return (
            <Modal {...this.props} 
            size="sm" centered  aria-labelledby="example-modal-sizes-title-lg" className="">
                <Modal.Body className="">
                      <Row>
                         <Col>
                            <h3 className='text-center color_red dot-slashed'><i class="fa-solid fa-location-dot f-34 pos_left_location"></i></h3>
                            <h6 className='mt-2 f-12 fw-bold ln-40'>మీరు ఈ కార్యక్రమం చేయుటకు మీ మొబైల్ నందు లొకేషన్ సర్వీసెస్ ను ఆన్ చేకోవలెను.</h6>
                            <h6 className='mt-2 f-12 fw-bold ln-40'>లొకేషన్ సర్వీసెస్ ను ఆన్ చేసుకొనుటకు ఈ క్రింద సూచించిన సూచనలు పాటించండి లేదా కాల్ సెంటర్ నెంబర్ కి -  <a href={`tel:+91-8919196329`} target='_blank'><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}><i class="fa-solid fa-phone"></i> {8919196329}</span></a> - కాల్ చేయండి వారు మీకు సహాయం చేయగలరు.</h6>
                            <h6 className='mt-3 f-12 fw-bold'>సూచనలు: </h6>
                            <ul className='list-stylelocation'>
                                <li>సెట్టింగ్‌లకు వెళ్లండి</li>
                                <li>లొకేషన్ సేవలకు వెళ్లండి</li>
                                <li>లొకేషన్ సేవలను అనుమతించడానికి స్లయిడర్‌ను జరపండి </li>
                                <li>మీ ఫోన్ యొక్క GPSని ఎనేబుల్ లేదా డిసేబుల్ చేయడానికి లొకేషన్ ఆన్ లేదా ఆఫ్ నొక్కండి</li>
                            </ul>
                            
                            <Button variant="dark" className='f-12 center-auto mt-2 btn-sm' onClick={() => this.props.closeEnabledModal()}>Reload Page or Close then Reopen the Bot</Button> 
                         </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default EnabledLocationPopup;
  

