
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import "../AttributesCollections/AttributesCollections";
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService'
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import AddInfluencer from './AddInfluencer';
import ViewInfluencer from './ViewInfluencer';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class VoterSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            SearchconstituencyValue:"",
            volunteerSearchConstituencyName:"",
            volunteerSearchName:"",
            voterSerachArr:[],
            votersDataCheck:0,
            votersListLoader:"hide",
            volunteerSearchVoterCardNo:"",
            ap_listArr:[],
            influencerModal:false,
            voterId:"",

            influencingTypeArr:[],
            influencingTypeValue:"",
            influencingTypeId:"",

            party_ids_Arr:[{'value':872,'label':'TDP'},{'value':1117,'label':'YSRC'},{'value':1892,'label':'JSP'},{'value':1887,'label':'Others'}],
            partyIdStr:"",
            partyIdStrValue:"",

            influencingLevelArr:[],
            influencingLevelValue:"",
            influencingLevelId:"",

            influencerName:"",
            validateInfluencerName:"",
            description:"",
            validateDescription:"",
            mobileNoStr:"",
            validateMobileNoIn:"",
            influencingVotes:"",
            validateInfluencingVotes:"",

            saveInfLoadingSymbol:"hide",
            successMsgInf:"",

            validatePartyIdStr:"",
            validateInfluencingTypeId:"",
            validateInfluencingLevelId:"",

            viewInfluencerModal:false,
            viewinfluencersSymbol:"hide",
            viewinfluencersArr:[],
            viewinfluencersCheck:0
        }
    }

    componentDidMount() {
        console.log("userAccessList",this.state.userAccessList)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
                
                
            }
           
            this.setState({ ap_listArr:consArr });
            this.setState({
                SearchconstituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },volunteerSearchConstituencyName:this.state.userAccessList.constituencyList[0].id
        });

        this.influencingTypes();
        this.influencingLevels();
            
        }

        
    }

    influencingTypes = () => {
        fetch(`/bot_api/v1/influencingTypes`, {
            method: "GET", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "test_chat_id" :"5129193582",
            },
          })
          .then((res) => res.json())
          .then((json) => {
            if(json !=null && json.length>0){
                var influencingTypes=[];
                for(var i in  json){
                    var obj={'value':json[i].influencingTypeId,'label':json[i].influenceTypeName};
                    influencingTypes.push(obj)
                    
                }
               
                this.setState({ influencingTypeArr:influencingTypes});
              }
            
          });
    }

    influencingLevels = () => {
        fetch(`/bot_api/v1/influencingLevels`, {
            method: "GET", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "test_chat_id" :"5129193582",
            },
          })
          .then((res) => res.json())
          .then((json) => {
            if(json !=null && json.length>0){
                var influencingLevels=[];
                for(var i in  json){
                    var obj={'value':json[i].influencingLevelId,'label':json[i].levelName};
                    influencingLevels.push(obj)
                    
                }
               
                this.setState({ influencingLevelArr:influencingLevels});
              }
            
          });
    }


    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });

        if(name == "volunteerSearchName"){
            if(value.length >= 3){
                //this.getVotersDisplayForVoterSerch();
            }
            
        }

        
        
      }
    
      getVotersDisplayForVoterSerch = () => {
        this.setState({voterSerachArr:[],votersDataCheck:0,votersListLoader:"hide",validateSearchByMobileNo:"",
        validateSearchByBoothNo:""});
        

       
          const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "constituencyId" : this.state.volunteerSearchConstituencyName,
                "voterName":this.state.volunteerSearchName,
                "voterCardNo":this.state.volunteerSearchVoterCardNo
            });
        
        
        this.setState({votersListLoader:"show"});

        getAPICallData(obj,"getVoterDetailsBySearch")
            .then((res) => res.json())
            .then((content) => {
              this.setState({votersListLoader:"hide"});  
                if(content !=null && content.length>0){
                    
                    this.setState({voterSerachArr:content,votersDataCheck:0});
                }else{
                    this.setState({voterSerachArr:[],votersDataCheck:1});
                }
            });
      }

      sortFuncSerialNo(a, b, order, dataField, rowA, rowB)  { 
        if (order == 'desc') {
            
            return parseInt(rowB.voterCardNo)  > parseInt(rowA.voterCardNo) ? 1 : -1;

        } else {
            return parseInt(rowA.voterCardNo)  > parseInt(rowB.voterCardNo) ? 1 : -1;
        }
    }
    actionsFormatterForVoterSerialNoAtt = (cell, row, rowIndex, formatExtraData) => {
        return (
           (row.voterCardNo)
           
         );
    }

    actionsFormatterForVoterNameAtt = (cell, row, rowIndex, formatExtraData) => {
        const voter_Card = this.state.voterSerachArr.filter((item,index) => (item.voterId) == row.voterId).map(blist => blist.voterCardNo);

      
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.voter_Name)} 
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>V - {(voter_Card[0])}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>HouseNo - {(row.Gender)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>RN - {(row.relectiveName)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Relation - {(row.relation)}</span></>
            </span>
         );
        
    }

    actionsFormatterForAddInflucener = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span className='cursor_pointer f-14' style={{textAlign:"left",marginLeft:"0px"}} onClick={() => this.saveInfluencer(row.voterId,row.voter_Name)} ><i class="fa-solid fa-user-plus"></i>
                
            </span>
         );
    }

    actionsFormatterForGenderAtt = (cell, row, rowIndex, formatExtraData) => {
        return (
            (row.houseNo)
            
          );
    }

      votersDisplayColumns() {
        let columns = [
            
            {
                dataField: "Booth",
                text: "B",
                sort: true,
                headerStyle: {
                    width: "10%"
                }
                
                
            },
            {
                dataField: "Serial_No",
                text: "S",
                sort: true,
                formatter: this.actionsFormatterForVoterSerialNoAtt.bind(this),
                sortFunc: this.sortFuncSerialNo.bind(this),
                headerStyle: {
                    width: "10%"
                }
                
                
            },
            {
                dataField: "voter_Name",
                text: "Name",
                formatter: this.actionsFormatterForVoterNameAtt.bind(this),
                headerStyle: {
                    width: "50%"
                }
                
            },
            {
                dataField: "Age",
                text: "A",
                sort: true,
                headerStyle: {
                    width: "10%"
                }
                
            },

            {
                dataField: "Gender",
                text: "G",
                sort: true,
                formatter: this.actionsFormatterForGenderAtt.bind(this),
                headerStyle: {
                    width: "10%"
                }
            
            },

            {
                dataField: "Add_influencer",
                text: "Add Influencer",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionsFormatterForAddInflucener.bind(this),
            
            }
        
        ];
        return columns;
    }

    votersDisplayData = () => {
        let votersData = [];
        if(this.state.voterSerachArr.length>0){
            
            for (let i = 0; i < this.state.voterSerachArr.length; i++) {

                const Serial_No_val = (this.state.voterSerachArr[i].serialNo);
                const voter_Name_val = (this.state.voterSerachArr[i].voterName);
                const Age_val = (this.state.voterSerachArr[i].age);
                const Gender_val = (this.state.voterSerachArr[i].gender);
                const voterId_val = (this.state.voterSerachArr[i].voterId);
                const houseNo = (this.state.voterSerachArr[i].houseNo);
                const relectiveName = (this.state.voterSerachArr[i].relativeName);
                const relation = (this.state.voterSerachArr[i].relation);
                const voterCardNo = (this.state.voterSerachArr[i].voterCardNo);
                const booth = (this.state.voterSerachArr[i].booth);
                
                const obj = {
                    Serial_No:(voterCardNo),
                    Booth:booth,
                    voter_Name: voter_Name_val,
                    Age: Age_val,
                    Gender: houseNo,
                    voterCardNo:Serial_No_val,
                    voterId:voterId_val,
                    houseNo:Gender_val,
                    relectiveName:relectiveName,
                    relation:relation,
                    Add_influencer:""
                    
                }
                votersData.push(obj);
            } 

            
            
            return votersData;
        }
    }

    saveInfluencer = (voterId,voterName) => {
        this.setState({influencerModal:true,voterId:voterId,influencerName:voterName});
    }

    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "influencingTypeId"){
          let influencingTypeIdName = this.state.influencingTypeArr.find((a) => a.value == value);
            this.setState({
                influencingTypeValue: {
                  value: influencingTypeIdName.value,
                  label: influencingTypeIdName.label
              }
          });
    
          this.setState({ influencingTypeId: influencingTypeIdName.value});
        
        
        }else if(name == "partyIdStr"){
            let partyIdName = this.state.party_ids_Arr.find((a) => a.value == value);
            this.setState({
                partyIdStrValue: {
                  value: partyIdName.value,
                  label: partyIdName.label
              }
          });

    
          this.setState({ partyIdStr: value});
        }else if(name == "influencingLevelId"){
            let influencingLevelIdName = this.state.influencingLevelArr.find((a) => a.value == value);
            this.setState({
                influencingLevelValue: {
                  value: influencingLevelIdName.value,
                  label: influencingLevelIdName.label
              }
          });

    
          this.setState({ influencingLevelId: value});
        }
    }

    createInfluencer = () => {
        if(this.state.influencerName == ""){
            this.setState({validateInfluencerName:"Please Enter Name" });
            return;
        }
        if(this.state.description == ""){
            this.setState({validateDescription:"Please Enter Description" });
            return;
        }
        if(this.state.mobileNoStr == ""){
            this.setState({validateMobileNoIn:"Please Enter Mobile No" });
            return;
        }
        if(!this.state.mobileNoStr.match(/^[0-9]+$/)){
            this.setState({validateMobileNoIn:"Please Enter Valid Mobile No" });
            return;
        }

        if(this.state.mobileNoStr.length < 10){
            this.setState({validateMobileNoIn:"Please Enter Valid Mobile No" });
            return;
        }

        if(this.state.influencingVotes == ""){
            this.setState({validateInfluencingVotes:"Please Enter Votes" });
            return;
        }

        if(this.state.partyIdStr == "" || this.state.partyIdStr == 0){
            this.setState({validatePartyIdStr:"Please Select Party" });
            return;
        }

        if(this.state.influencingTypeId == "" || this.state.influencingTypeId == 0){
            this.setState({validateInfluencingTypeId:"Please Select Type" });
            return;
        }

        if(this.state.influencingLevelId == "" || this.state.influencingLevelId == 0){
            this.setState({validateInfluencingLevelId:"Please Select Value" });
            return;
        }

        this.setState({saveInfLoadingSymbol:"show"})

        fetch('/bot_api/v1/createInfluencer', {
            method: "POST", 
            mode: "cors", 
            cache: "no-cache", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "test_chat_id" :"5129193582",
            },
            body: JSON.stringify({
                "constituencyId": this.state.volunteerSearchConstituencyName,
                "voterId": this.state.voterId,
                "partyId": this.state.partyIdStr,
                "personName": this.state.influencerName,
                "description": this.state.description,
                "contactNo": this.state.mobileNoStr,
                "influencingVotes": this.state.influencingVotes,
                "influencingTypeId": this.state.influencingTypeId,
                "influencingLevelId": this.state.influencingLevelId,
                //"influencingLevelValue": "9875",
                "insertedUserId": this.state.chatID
            }), 
          })
          .then((response) => {
            this.setState({saveInfLoadingSymbol:"hide"})
            // 1. check response.ok
            if (response.ok) {
              return response.json();
            }
            return Promise.reject(response); // 2. reject instead of throw
          })
          .then((json) => {
            // all good, token is ready
            this.setState({successMsgInf:"Saved SuccessFully..."});
            setTimeout(() => {
                this.setState({influencerModal:false});
                this.setState({voterId:"",
                        influencingTypeValue:"",
                        influencingTypeId:"",
                        partyIdStr:"",
                        partyIdStrValue:"",
                        influencingLevelValue:"",
                        influencingLevelId:"",
                        influencerName:"",
                        validateInfluencerName:"",
                        description:"",
                        validateDescription:"",
                        mobileNoStr:"",
                        validateMobileNoIn:"",
                        influencingVotes:"",
                        validateInfluencingVotes:"",
                        successMsgInf:"",
                        validatePartyIdStr:"",
                        validateInfluencingTypeId:"",
                        validateInfluencingLevelId:"",
                });
            }, 1000);
          })
          .catch((response) => {
            this.setState({successMsgInf:"Something Wrong,Please Try Again..."});
            // 3. get error messages, if any
            response.json().then((json) => {
                this.setState({successMsgInf:"Something Wrong,Please Try Again..."});
            })
          });
    }

    viewInfluencer = () => {
        this.setState({viewInfluencerModal:true});

        fetch(`/bot_api/v1/influencers`, {
            method: "GET", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "test_chat_id" :"5129193582",
            },
          })
          .then((res) => res.json())
          .then((json) => {
            if(json !=null && json.length>0){
                
                this.setState({viewinfluencersArr:json});
              }else{
                this.setState({viewinfluencersArr:[]});
              }
            
          });
    }
    
    render() {
        return (
            <section>
               
                <Container fluid>
                    <>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'> Voter Search</h6>
                        </Col>
                    </Row>
                    
                    <Row>
                            <Col className=''>
                            <label className="form-label f-12 fw-bold">Constituency<span className='color_red f-14 ms-1'>*</span></label>
                            <SelectBox
                                    stateData={this.state.SearchconstituencyValue}
                                    handleChange={this.handleOnchangeSelectBoxNew1}
                                    name="volunteerSearchConstituencyName"
                                    options={this.state.ap_listArr}
                                    placeholderName={'Constituency'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                />
                        </Col>
                        
                    </Row>
                    <Row>
                       
                        
                        <Col>
                            <Form.Group className="mt-2" controlId="formVoterSerachCardNoId">
                                <Form.Label className='f-12 fw-bold'>Voter Card No </Form.Label>
                                <Form.Control type="text" name="volunteerSearchVoterCardNo" className='form-control-sm'  placeholder="Enter Voter Card No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                            </Form.Group>    
                        </Col>
                    </Row>
                    <Row>
                       
                       <Col>
                           <Form.Group className="mt-2" controlId="formNameId">
                               <Form.Label className='f-12 fw-bold'>Voter Name </Form.Label>
                               <Form.Control type="text" name="volunteerSearchName" className='form-control-sm'  placeholder="Enter Name" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                           </Form.Group>    
                       </Col>
                       
                   </Row>
                   <Row>
                        <Col>
                            <h6 className='f-12 mt-3'>Note :  <b> You can add new influencers in your area now, after Clicking Get Voters</b></h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="outline-danger" className='float-end f-12  fw-bold' onClick={() => this.viewInfluencer()}>View already added Influencers  <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <Button variant="dark" className='f-12 center-auto mt-3' onClick={() => this.getVotersDisplayForVoterSerch()}>GET VOTERS</Button> 
                           
                        </Col>
                    </Row>
                    
                    {
                        this.state.votersListLoader == "show" ?
                        (
                            <Row>
                                <Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.voterSerachArr.length>0 ?
                        (
                            
                            <>
                            <Row>
                                <Col sm={12}>
                            
                                <div className='bg_light_blue mt-3'>
                                <CardLessDataTableWithSearch 
                                    parentData={this.votersDisplayData()}
                                    particlecolumns={this.votersDisplayColumns()}
                                    defaultSorted={[{
                                        dataField: "Serial_No",
                                        order: "asc"
                                    }]}
                                    tableHeading="Voters Details"
                                    />
                                    
                                        
                                </div>
                                
                                </Col>
                            </Row>
                            </>
                        )
                        :
                        (<>
                            {
                            this.state.votersDataCheck == 1 ?
                            (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                            :
                            ("")
                        }
                        </>)
                    }
                    
                    </>

                    <AddInfluencer 
                        show={this.state.influencerModal}
                        onHide={() => this.setState({ influencerModal: false })}
                        stateData={this.state}
                        phandleOnchangeSelectBoxNew={this.handleOnchangeSelectBoxNew}
                        phandleOnchangeInputBox={this.handleOnchangeInputBox}
                        createInfluencer={this.createInfluencer}
                />
                  <ViewInfluencer 
                        show={this.state.viewInfluencerModal}
                        onHide={() => this.setState({ viewInfluencerModal: false })}
                        stateData={this.state}
                />
                </Container>
                
            </section>   
        )
    }
  }
  
  export default withRouter(VoterSearch);
  