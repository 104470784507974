import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';


class EventRestrictModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="z-index" >
                <Modal.Body className="">
                      <Row>
                         <Col>
                            <h3 className='text-center color_red'><i class="fa-solid fa-triangle-exclamation f-32"></i></h3>
                            <h6 className='mt-2 f-14 fw-bold ln-28'>మీరు యువగళం - నవశకం బహిరంగ సభ జరుగు  ప్రాంతంలో మాత్రమే మీ హాజరుని ఇవ్వగలరు.</h6>
                            <Button variant="dark" className='f-12 float-right mt-2 btn-sm' onClick={() => this.props.closeEventModal()}>Close</Button> 
                         </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default EventRestrictModal;
  

