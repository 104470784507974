import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import { withRouter,Link } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import moment from 'moment';
class ViewCommentsForIssues extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
      
    }

    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>View Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Row>
                        <Col>
                            {
                                this.props.stateData.issueCommentsArr.length == 0 ?
                                (
                                    this.props.stateData.issueCommentArrCheck == 1 ?
                                    (<Col className='f-14 text-center'>No Comments Available</Col>)
                                    :
                                    (<Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>)
                                )
                                :
                                (
                                    this.props.stateData.issueCommentsArr.length>0 ?
                                    (
                                        <ListGroup>
                                            {
                                                 this.props.stateData.issueCommentsArr.map((item,index) => {
                                                    return(
                                                        <ListGroup.Item>
                                                           <span className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>Description</span> :  {item.issueDesc}
                                                            <h6 className='f-12 mt-2'>statusName :  {item.statusName},Time : {moment(item.createdTime, "YYYY-MM-DD").format("MMM Do YY")}</h6>
                                                        </ListGroup.Item>
                                                    )
                                                })
                                            }
                                           
                                        </ListGroup>
                                    )
                                    :
                                    ("")
                                )
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default withRouter(ViewCommentsForIssues);
  

