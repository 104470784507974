
import React ,{ Component } from 'react';
import {Container, Row, Col,Button,Form} from 'react-bootstrap';
import "./Dashboard.css";
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService';
//import  {getUserAccessData}  from '../../components/UserAccessFile';
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';

class KSSDashboard extends Component {  
    constructor(props) {
        super(props)
   
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            roleName:props?.location?.params?.roleName == undefined ? "" : props?.location?.params?.roleName,
            selectedKssType:"booth",
            boothCheckedKss:true,
            clusterCheckedKss:false,
            unitCheckedKss:false,

            boothsListArrKss:[],
            boothsListKssCheck:0,
            clusterListArrKss:[],
            clusterListKssCheck:0,
            unitListArrKss:[],
            unitListKssCheck:0
        }
    }
  
    componentDidMount() {
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            if(this.state.roleName == "constituency"){
                this.getBoothWiseKSSDetailsByConstituencyId();
                this.getClusterWiseKSSDetailsByConstituencyId();
                this.getUnitWiseKSSDetailsByConstituencyId();
            }else if(this.state.roleName == "cluster"){
                this.getBoothWiseKSSDetailsByConstituencyId();
                this.getClusterWiseKSSDetailsByConstituencyId();
            }else if(this.state.roleName == "unit"){
                this.getBoothWiseKSSDetailsByConstituencyId();
                this.getUnitWiseKSSDetailsByConstituencyId();
            }else if(this.state.roleName == "booth"){
                this.getBoothWiseKSSDetailsByConstituencyId();
            }else if(this.state.roleName == "Mandal"){
                if(this.state.userAccessList.clusterList!= null && this.state.userAccessList.clusterList.length>0){
                    this.getClusterWiseKSSDetailsByConstituencyId();
                }
                else if(this.state.userAccessList.unitList!= null && this.state.userAccessList.unitList.length>0){
                    this.getUnitWiseKSSDetailsByConstituencyId();
                }else if(this.state.userAccessList.mandalList !=null && this.state.userAccessList.mandalList.length>0){
                    this.getBoothWiseKSSDetailsByConstituencyId();
                }else if(this.state.userAccessList.clusterList.length>0 && this.state.userAccessList.unitList.length>0 && this.state.userAccessList.mandalList.length>0){
                    this.getBoothWiseKSSDetailsByConstituencyId();
                    this.getClusterWiseKSSDetailsByConstituencyId();
                    this.getUnitWiseKSSDetailsByConstituencyId();
                }

                
          }
            
        }
    }
    locationScopeSelectedKSS = (e,type) => {
   
        if(type == "booth"){
            this.setState({ boothCheckedKss: true,clusterCheckedKss:false,unitCheckedKss:false});
        }else if(type == "cluster"){
            this.setState({ boothCheckedKss: false,clusterCheckedKss:true,unitCheckedKss:false});
        }else if(type == "unit"){
            this.setState({ boothCheckedKss: false,clusterCheckedKss:false,unitCheckedKss:true});
        }
        this.setState({ selectedKssType: type});
        
      }

    getBoothWiseKSSDetailsByConstituencyId = (locationScopeId) => {
        this.setState({boothsListArrKss:[],boothsListKssCheck:0});
        const obj =  
                JSON.stringify({ 
                    "constituencyId" :this.state.userAccessList.constituencyList[0].id,
                    "locationScopeId":9
                });
        getAPICallData(obj,"getBoothWiseKSSDetailsByConstituencyId")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
                        let result = content.filter(o1 => this.state.userAccessList.boothList.some(o2 => o1.locationId === o2.id));
                        this.setState({boothsListArrKss:result,boothsListKssCheck:0});     
                    }else{
                        this.setState({boothsListArrKss:content,boothsListKssCheck:0});
                    }
                   
                }else{
                    this.setState({boothsListArrKss:[],boothsListKssCheck:1});
                }
            });
    }

    getClusterWiseKSSDetailsByConstituencyId = () => {
        this.setState({clusterListArrKss:[],clusterListKssCheck:0});
        const obj =  
                JSON.stringify({ 
                    "constituencyId" :this.state.userAccessList.constituencyList[0].id,
                    "locationScopeId":17
                });
        getAPICallData(obj,"getBoothWiseKSSDetailsByConstituencyId")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    if(this.state.userAccessList.clusterList != null && this.state.userAccessList.clusterList.length>0){
                        let result = content.filter(o1 => this.state.userAccessList.clusterList.some(o2 => o1.locationId === o2.id));
                        this.setState({clusterListArrKss:result,clusterListKssCheck:0});     
                    }else{
                        this.setState({clusterListArrKss:content,clusterListKssCheck:0});
                    }
                }else{
                    this.setState({clusterListArrKss:[],clusterListKssCheck:1});
                }
            });
    }

    getUnitWiseKSSDetailsByConstituencyId = () => {
        this.setState({unitListArrKss:[],unitListKssCheck:0});
        const obj =  
                JSON.stringify({ 
                    "constituencyId" :this.state.userAccessList.constituencyList[0].id,
                    "locationScopeId":18
                });
        getAPICallData(obj,"getBoothWiseKSSDetailsByConstituencyId")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    if(this.state.userAccessList.unitList != null && this.state.userAccessList.unitList.length>0){
                        let result = content.filter(o1 => this.state.userAccessList.unitList.some(o2 => o1.locationId === o2.id));
                        this.setState({unitListArrKss:result,unitListKssCheck:0});     
                    }else{
                        this.setState({unitListArrKss:content,unitListKssCheck:0});
                    }
                }else{
                    this.setState({unitListArrKss:[],unitListKssCheck:1});
                }
            });
    }

    boothsKssColumns() {
        let columns = [
            
            {
                dataField: "Booth",
                text: this.state.selectedKssType == "booth" ? "Booth" : this.state.selectedKssType == "cluster" ? "Cluster" : "Unit",
                sort: true,
                formatter: this.actionsFormatterForNameSplit.bind(this),
                headerStyle: {
                    width: this.state.selectedKssType == "booth" ? "20%" : this.state.selectedKssType == "cluster" ? "25%" : "30%"
                }

            },
            {
                dataField: "TotalVoters",
                text: "Total Voters",
                sort: true,
                
            },
            {
                dataField: "minimumSections",
                text: "Min Secs",
                sort: true,
            },
            {
                dataField: "TotalSections",
                text: "Total Secs",
                sort: true,
                
            },
        
            {
                dataField: "Male",
                text: "M",
                sort: true,
            },
        
            {
                dataField: "Female",
                text: "F",
                sort: true,
            }
        
        ];
        return columns;
    }

    actionsFormatterForNameSplit = (cell, row, rowIndex, formatExtraData) => {

        var splitUnitName = row.Booth.split("_");
       
    
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    this.state.selectedKssType == "booth" ?
                    (parseInt(row.Booth))
                    :
                    (
                        this.state.selectedKssType == "cluster" ? 
                        (row.Booth)
                        :
                        (
                            splitUnitName.map((item,index) => {
                                return(
                                    <span>
                                        {item }<br/>
                                    </span>
                                )
                            })
                        )
                    )
                }
               
            </span>
        );
        
    }
   
    
    boothsKssData = () =>{
    let dataArr2 = [];
    let mainDataArr=[];
    if(this.state.selectedKssType == "booth"){
        mainDataArr = this.state.boothsListArrKss;
    }else if(this.state.selectedKssType == "cluster"){
        mainDataArr = this.state.clusterListArrKss;
    }else if(this.state.selectedKssType == "unit"){
        mainDataArr = this.state.unitListArrKss;    
    }
        if(mainDataArr.length>0){
            
            for (let i = 0; i < mainDataArr.length; i++) {

                const location = (mainDataArr[i].name);
                const totalVoters = (mainDataArr[i].totalVoters);
                const minimumSections = (mainDataArr[i].minimumSections);
                const totalSections = (mainDataArr[i].totalSections);
                const maleAssigned = (mainDataArr[i].maleAssigned);
                const femaleAssigned = (mainDataArr[i].femaleAssigned);
               
                
                const obj = {
                    Booth: location,
                    TotalVoters: totalVoters,
                    minimumSections: minimumSections,
                    TotalSections: totalSections,
                    Male: maleAssigned,
                    Female:femaleAssigned
                
                    
                }
                dataArr2.push(obj);
            } 

            
            
            return dataArr2;
        }
    }

    render() {
        /*getUserAccessData().then(data => {
            console.log(data)
         });*/
        return (
            <section>
                <Container fluid>
                    <>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center mb-3'>Kutumba Sadikara Saradulu Deatils</h6>
                        </Col>
                    </Row>

                    <Row>
                    <Col sm={12} >
                        <Form>
                            <div className='text-center'>
                                {
                                    this.state.roleName == "constituency" ?
                                    (
                                        <>
                                        <Form.Check
                                            inline
                                            label={`Booth`}
                                            name="boothClusterUnit"
                                            type="radio"
                                            id={`boothWiseId`}
                                            onChange={(e)=>this.locationScopeSelectedKSS(e,"booth")}
                                            className="f-15 fw-bold color_black"
                                            checked={this.state.boothCheckedKss}
                                            value="booth"
                                        />
                                        <Form.Check
                                            inline
                                            label={`Cluster`}
                                            name="boothClusterUnit"
                                            type="radio"
                                            id={`clusterWiseId`}
                                            onChange={(e)=>this.locationScopeSelectedKSS(e,"cluster")}
                                            className="f-15 fw-bold color_black"
                                            checked={this.state.clusterCheckedKss}
                                            value="cluster"
                                        />
                                        
                                        <Form.Check
                                            inline
                                            label={`Unit`}
                                            name="boothClusterUnit"
                                            type="radio"
                                            id={`unitWiseId`}
                                            onChange={(e)=>this.locationScopeSelectedKSS(e,"unit")}
                                            className="f-15 fw-bold color_black"
                                            checked={this.state.unitCheckedKss}
                                            value="unit"
                                        />
                                        </>
                                    )
                                    :
                                    (
                                        this.state.roleName == "cluster" ?
                                        (
                                            <>
                                                <Form.Check
                                                    inline
                                                    label={`Booth`}
                                                    name="boothClusterUnit"
                                                    type="radio"
                                                    id={`boothWiseId`}
                                                    onChange={(e)=>this.locationScopeSelectedKSS(e,"booth")}
                                                    className="f-15 fw-bold color_black"
                                                    checked={this.state.boothCheckedKss}
                                                    value="booth"
                                                />
                                                <Form.Check
                                                    inline
                                                    label={`Cluster`}
                                                    name="boothClusterUnit"
                                                    type="radio"
                                                    id={`clusterWiseId`}
                                                    onChange={(e)=>this.locationScopeSelectedKSS(e,"cluster")}
                                                    className="f-15 fw-bold color_black"
                                                    value="cluster"
                                                    checked={this.state.clusterCheckedKss}
                                                />
                                                </>
                                        )
                                        :
                                        (
                                            this.state.roleName == "unit" ? 
                                            (
                                                <>
                                                <Form.Check
                                                    inline
                                                    label={`Booth`}
                                                    name="boothClusterUnit"
                                                    type="radio"
                                                    id={`boothWiseId`}
                                                    onChange={(e)=>this.locationScopeSelectedKSS(e,"booth")}
                                                    className="f-15 fw-bold color_black"
                                                    checked={this.state.boothCheckedKss}
                                                    value="booth"
                                                />
                                                
                                                <Form.Check
                                                    inline
                                                    label={`Unit`}
                                                    name="boothClusterUnit"
                                                    type="radio"
                                                    id={`unitWiseId`}
                                                    onChange={(e)=>this.locationScopeSelectedKSS(e,"unit")}
                                                    className="f-15 fw-bold color_black"
                                                    value="unit"
                                                    checked={this.state.unitCheckedKss}
                                                />
                                                </>
                                            )
                                            :
                                            (
                                                this.state.roleName == "booth" ? 
                                                (
                                                    <>
                                                        <Form.Check
                                                            inline
                                                            label={`Booth`}
                                                            name="boothClusterUnit"
                                                            type="radio"
                                                            id={`boothWiseId`}
                                                            onChange={(e)=>this.locationScopeSelectedKSS(e,"booth")}
                                                            className="f-15 fw-bold color_black"
                                                            checked={this.state.boothCheckedKss}
                                                            value="booth"
                                                        />
                                                    </>
                                                )
                                                :
                                                (
                                                    this.state.roleName == "Mandal" ? 
                                                    (
                                                        this.state.userAccessList.clusterList.length>0 && this.state.userAccessList.unitList.length>0 && this.state.userAccessList.mandalList.length>0 ?
                                                        (
                                                            <>
                                                                <Form.Check
                                                                    inline
                                                                    label={`Booth`}
                                                                    name="boothClusterUnit"
                                                                    type="radio"
                                                                    id={`boothWiseId`}
                                                                    onChange={(e)=>this.locationScopeSelectedKSS(e,"booth")}
                                                                    className="f-15 fw-bold color_black"
                                                                    checked={this.state.boothCheckedKss}
                                                                    value="booth"
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label={`Cluster`}
                                                                    name="boothClusterUnit"
                                                                    type="radio"
                                                                    id={`clusterWiseId`}
                                                                    onChange={(e)=>this.locationScopeSelectedKSS(e,"cluster")}
                                                                    className="f-15 fw-bold color_black"
                                                                    checked={this.state.clusterCheckedKss}
                                                                    value="cluster"
                                                                />
                                                                
                                                                <Form.Check
                                                                    inline
                                                                    label={`Unit`}
                                                                    name="boothClusterUnit"
                                                                    type="radio"
                                                                    id={`unitWiseId`}
                                                                    onChange={(e)=>this.locationScopeSelectedKSS(e,"unit")}
                                                                    className="f-15 fw-bold color_black"
                                                                    checked={this.state.unitCheckedKss}
                                                                    value="unit"
                                                                />
                                                                </>
                                                        )
                                                        :
                                                        (
                                                            this.state.userAccessList.clusterList.length>0 ?
                                                            (
                                                                <>
                                                                    <Form.Check
                                                                        inline
                                                                        label={`Booth`}
                                                                        name="boothClusterUnit"
                                                                        type="radio"
                                                                        id={`boothWiseId`}
                                                                        onChange={(e)=>this.locationScopeSelectedKSS(e,"booth")}
                                                                        className="f-15 fw-bold color_black"
                                                                        checked={this.state.boothCheckedKss}
                                                                        value="booth"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label={`Cluster`}
                                                                        name="boothClusterUnit"
                                                                        type="radio"
                                                                        id={`clusterWiseId`}
                                                                        onChange={(e)=>this.locationScopeSelectedKSS(e,"cluster")}
                                                                        className="f-15 fw-bold color_black"
                                                                        value="cluster"
                                                                        checked={this.state.clusterCheckedKss}
                                                                    />
                                                                    </>
                                                            )
                                                            :
                                                            (
                                                                this.state.userAccessList.unitList.length>0 ?
                                                                (
                                                                    <>
                                                                    <Form.Check
                                                                        inline
                                                                        label={`Booth`}
                                                                        name="boothClusterUnit"
                                                                        type="radio"
                                                                        id={`boothWiseId`}
                                                                        onChange={(e)=>this.locationScopeSelectedKSS(e,"booth")}
                                                                        className="f-15 fw-bold color_black"
                                                                        checked={this.state.boothCheckedKss}
                                                                        value="booth"
                                                                    />
                                                                    
                                                                    <Form.Check
                                                                        inline
                                                                        label={`Unit`}
                                                                        name="boothClusterUnit"
                                                                        type="radio"
                                                                        id={`unitWiseId`}
                                                                        onChange={(e)=>this.locationScopeSelectedKSS(e,"unit")}
                                                                        className="f-15 fw-bold color_black"
                                                                        value="unit"
                                                                        checked={this.state.unitCheckedKss}
                                                                    />
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    this.state.userAccessList.mandalList.length>0 ?
                                                                    (
                                                                        <>
                                                                            <Form.Check
                                                                                inline
                                                                                label={`Booth`}
                                                                                name="boothClusterUnit"
                                                                                type="radio"
                                                                                id={`boothWiseId`}
                                                                                onChange={(e)=>this.locationScopeSelectedKSS(e,"booth")}
                                                                                className="f-15 fw-bold color_black"
                                                                                checked={this.state.boothCheckedKss}
                                                                                value="booth"
                                                                            />
                                                                            </>
                                                                    )
                                                                    :
                                                                    ("")
                                                                )
                                                            )
                                                        )
                                                    )
                                                    :
                                                    ("")
                                                )
                                            )
                                        )
                                    )

                                }
                            
                            </div>
                        </Form>
                            
                    </Col>
                    </Row>

                    {
                                this.state.selectedKssType == "booth" ?
                                (
                                    
                                        this.state.boothsListArrKss.length == 0 ?
                                        (
                                            this.state.boothsListKssCheck == 1 ?
                                            (<Col className='f-14 text-center'>No Data Available</Col>)
                                            :
                                            (<Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>)
                                        )
                                        :
                                        (
                                            this.state.boothsListArrKss.length>0 ?
                                            (
                                                <>
                                                <Row>
                                                    <Col sm={12}>
                                                    <div className='mt-2 p-0 bg_light_blue'>
                                                        
                                                        <CardLessDataTableWithSearch 
                                                            parentData={this.boothsKssData()}
                                                            particlecolumns={this.boothsKssColumns()}
                                                            defaultSorted={[{
                                                                dataField: "TotalVoters",
                                                                order: "desc"
                                                            }]}
                                                            tableHeading="Booth Wise Kss Details"
                                                            />
                                                            
                                                            
                                                    </div>
                                                    
                                                    </Col>
                                                </Row>
                                                </>
                                            )
                                            :
                                            ("")
                                        )
                                        
                                )
                                :
                                (
                                    this.state.selectedKssType == "cluster" ?
                                    (
                                        this.state.clusterListArrKss.length == 0 ?
                                        (
                                            this.state.clusterListKssCheck == 1 ?
                                            (<Col className='f-14 text-center'>No Data Available</Col>)
                                            :
                                            (<Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>)
                                        )
                                        :
                                        (
                                            this.state.clusterListArrKss.length>0 ?
                                            (
                                                <>
                                                <Row>
                                                    <Col sm={12}>
                                                    <div className='mt-2 p-0 bg_light_blue'>
                                                        
                                                        <CardLessDataTableWithSearch 
                                                            parentData={this.boothsKssData()}
                                                            particlecolumns={this.boothsKssColumns()}
                                                            defaultSorted={[{
                                                                dataField: "TotalVoters",
                                                                order: "desc"
                                                            }]}
                                                            tableHeading="Cluster Wise Kss Details"
                                                            />
                                                            
                                                            
                                                    </div>
                                                    
                                                    </Col>
                                                </Row>
                                                </>
                                            )
                                            :
                                            ("")
                                        )
                                    )
                                    :
                                    (
                                        this.state.selectedKssType == "unit" ?
                                        (
                                            this.state.unitListArrKss.length == 0 ?
                                                (
                                                    this.state.unitListKssCheck == 1 ?
                                                    (<Col className='f-14 text-center'>No Data Available</Col>)
                                                    :
                                                    (<Col sm={12}>
                                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                    </Col>)
                                                )
                                                :
                                                (
                                                    this.state.unitListArrKss.length>0 ?
                                                    (
                                                        <>
                                                        <Row>
                                                            <Col sm={12}>
                                                            <div className='mt-2 p-0 bg_light_blue'>
                                                                
                                                                <CardLessDataTableWithSearch 
                                                                    parentData={this.boothsKssData()}
                                                                    particlecolumns={this.boothsKssColumns()}
                                                                    defaultSorted={[{
                                                                        dataField: "TotalVoters",
                                                                        order: "desc"
                                                                    }]}
                                                                    tableHeading="Unit Wise Kss Details"
                                                                    />
                                                                    
                                                                    
                                                            </div>
                                                            
                                                            </Col>
                                                        </Row>
                                                        </>
                                                    )
                                                    :
                                                    ("")
                                                )
                                        )
                                        :
                                        ("")
                                    )
                                )
                            }

                    </>
                    
                        
                
                </Container> 
                
            </section>
        )
    }
  }
  
  export default withRouter(KSSDashboard);
  