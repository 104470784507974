import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,InputGroup } from "react-bootstrap";
import loader from '../../images/loader.gif';
import Webcam from "react-webcam";

class QuestionsSavingPart extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          //videoConstraints:{facingMode: "environment"},

          
          
        }
      
    }

  
   
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="houseVisitCaptureCls">
              <Modal.Header closeButton className='p-3'>
                <Modal.Title className='f-14 fw-bold'>

                     {
                            this.props.stateData.campaignId == 4 ?
                            (
                                <h6 className='text-center fw-bold f-16'>Update Voter Slip Campaign</h6>
                            )
                            :
                            (
                                this.props.stateData.campaignId == 5 ? 
                                (
                                    <h6 className='text-center fw-bold f-16'>Update Voter Status Campaign</h6>
                                )
                                :
                                (
                                    this.props.stateData.campaignId == 6 ?   
                                    (
                                        <h6 className='text-center fw-bold f-16'>Update Exit Poll Campaign</h6>
                                    )
                                    :
                                    ("")
                                )
                            )
                        }
                </Modal.Title>
              </Modal.Header>
                <Modal.Body className="">
                    <>
                    

                    {
                                    this.props.stateData.campaignSurveyQuestionnaireArr == null ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    (
                                        this.props.stateData.campaignSurveyQuestionnaireArr.length == 0 ? 
                                        (
                                            <Row>
                                                <Col>
                                                    <h6 className='f-14 text-center mt-4'></h6>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            <>
                                            {
                                                this.props.stateData.campaignSurveyQuestionnaireArr !=null &&  this.props.stateData.campaignSurveyQuestionnaireArr.length > 0 ?
                                                (
                                                    <Row>
                                                        <Col>
                                                            <fieldset class="mt-3"><legend className="f-14"> Questions <span className='color_red f-14 ms-1'>*</span> </legend>
                                                            {
                                                                this.props.stateData.campaignSurveyQuestionnaireArr.map((item,index) => {
                                                                    return(
                                                                        <div className='border-dashed mt-2'>
                                                                        <Row>
                                                                            <Col>
                                                                                <h6 className='f-14'><span className='f-12 bg_round'>{index+1}</span> {item.question} <span className='color_red f-12 ml-22'>*</span></h6>
                                                                                {
                                                                                    item.answerType == "Single Option" ?
                                                                                    (
                                                                                        <>
                                                                                        <Form>
                                                                                            <div className=''>
                                                                                                {
                                                                                                    item.optionsList.map((item2,indexValue) => {
                                                                                                        return(
                                                                                                            
                                                                                                            <Form.Check
                                                                                                                inline
                                                                                                                label={`${item2.name}`}
                                                                                                                name={`${item.questionId}-${item.answerType.trim()}`}
                                                                                                                id={`id-${item.questionId}-${item.answerType.trim()}`}
                                                                                                                type="radio"
                                                                                                                className="f-16"
                                                                                                                value={item2.id}
                                                                                                                onChange={(e)=>this.props.getOnchangeSelectedKPIAnswer(e,item2,item)}
                                                                                                                defaultChecked={typeof(item.activityAnswer) == "undefined" ? false : item.activityAnswer.optionId == item2.id ? true : false}
                                                                                                            />
                                                                                                        
                                                                                                                
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                            </Form>
                                                                                            <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                            </>
                                                                                        
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        item.answerType == "Multi Option" ?  
                                                                                        (
                                                                                            <>
                                                                                            <Form>
                                                                                                <div className=''>
                                                                                                    {
                                                                                                        item.optionsList.map((item2,indexValue) => {
                                                                                                            return(
                                                                                                            
                                                                                                                <Form.Check
                                                                                                                    inline
                                                                                                                    label={`${item2.name}`}
                                                                                                                    id={`idCheckbox-${item.questionId}-${item.answerType.trim()}`}
                                                                                                                    type="checkbox"
                                                                                                                    className="f-16"
                                                                                                                    value={item2.id}
                                                                                                                    onChange={(e)=>this.props.getOnchangeSelectedKPIAnswer(e,item2,item)}
                                                                                                                    defaultChecked={typeof(item.activityAnswer) == "undefined" ? false : item.activityAnswer.optionId == item2.id ? true : false}
                                                                                                                />  
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </Form>
                                                                                            <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                            </>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            item.answerType == "Text" ?  
                                                                                            (
                                                                                                <>
                                                                                                    {
                                                                                                        typeof(item.activityAnswer) == "undefined" ?
                                                                                                        (
                                                                                                            <>
                                                                                                            <Form.Control type="text" name="answerText"  id={`stringAll${item.questionId}`}  className='form-control-sm' defaultValue=""   placeholder="Enter Text" onChange={(e)=>{this.props.getOnchangeSelectedKPIAnswer(e,'',item);this.props.handleOnchangeInputBox(e)}} />
                                                                                                            <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                            </>
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <>
                                                                                                            <Form.Control type="text" name={item.activityAnswer.answerText}  id={`stringAll${item.questionId}`}  className='form-control-sm' defaultValue={item.activityAnswer.answerText}   placeholder="Enter Text" onChange={(e)=>{this.props.getOnchangeSelectedKPIAnswer(e,'',item);this.props.handleOnchangeInputBox(e)}} />
                                                                                                            <h6 className='color_red f-12 mt-2 fw-bold' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                                
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                item.answerType == "Number" ?  
                                                                                                (
                                                                                                    <>
                                                                                                    {
                                                                                                        typeof(item.activityAnswer) == "undefined" ?
                                                                                                        (
                                                                                                            <>
                                                                                                            <Form.Control type="text" name="answerNumber"   id={`NumberAll${item.questionId}`}  className='form-control-sm' defaultValue=""   placeholder="Enter Number" onChange={(e)=>{this.props.getOnchangeSelectedKPIAnswer(e,'',item);this.props.handleOnchangeInputBox(e)}} />
                                                                                                            <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                            </>
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <>
                                                                                                            <Form.Control type="text" name={item.activityAnswer.answerNumber}   id={`NumberAll${item.questionId}`}  className='form-control-sm' defaultValue={item.activityAnswer.answerNumber}   placeholder="Enter Number" onChange={(e)=>{this.props.getOnchangeSelectedKPIAnswer(e,'',item);this.props.handleOnchangeInputBox(e)}} />
                                                                                                            <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                    </>
                                                                                                    
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    item.answerType == "Percentage" ?  
                                                                                                    (
                                                                                                        <>
                                                                                                            {
                                                                                                                typeof(item.activityAnswer) == "undefined" ?
                                                                                                                (
                                                                                                                    <>
                                                                                                                    <Form.Control type="text" name="answerPercentage"  id={`percentageAll${item.questionId}`}  className='form-control-sm' defaultValue=""   placeholder="Enter Percentage" onChange={(e)=>{this.props.getOnchangeSelectedKPIAnswer(e,'',item);this.props.handleOnchangeInputBox(e)}} />
                                                                                                                    <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                                    </>
                                                                                                                )
                                                                                                                :
                                                                                                                (
                                                                                                                    <>
                                                                                                                    <Form.Control type="text" name={item.activityAnswer.answerPercentage}  id={`percentageAll${item.questionId}`}  className='form-control-sm' defaultValue={item.activityAnswer.answerPercentage}   placeholder="Enter Percentage" onChange={(e)=>{this.props.getOnchangeSelectedKPIAnswer(e,'',item);this.props.handleOnchangeInputBox(e)}} />
                                                                                                                    <h6 className='color_red f-12 fw-bold mt-2' id={`errorMsgDisplay${item.questionId}`}></h6>
                                                                                                                    </>
                                                                                                                )
                                                                                                            }
                                                                                                        </>

                                                                                                    )
                                                                                                    :
                                                                                                    ("")
                                                                                                )
                                                                                            )
                                                                                        ) 
                                                                                    )
                                                                                    
                                                                                }

                                                                                
                                                                                
                                                                            
                                                                                
                                                                            </Col>
                                                                        </Row>
                                                                        </div>
                                                                    )

                                                                })
                                                            }

                                                        

                                                        </fieldset>
                                                    </Col>
                                                </Row>  
                                                )
                                                :
                                                ("")
                                            }
                                            </>
                                        )
                                    )
                                }

                    

                    
                            {
                                this.props.stateData.checkValidationsArr != undefined && this.props.stateData.checkValidationsArr.length > 0 ?
                                (
                                    <Row>
                                        <Col>
                                            <h6 className='color_red f-12 fw-bold mt-2'>{this.props.stateData.checkQuestionsMessage}</h6>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }
                            
               
                            <Row>
                                <Col sm={12}>
                                
                                    <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.props.stateData.submitButtonDisabled} onClick={() => this.props.saveCampaignEnrollment()}>SUBMIT </Button>
                                    
                                    {
                                        this.props.stateData.savaCampaignLoadingSymbol == "show" ? 
                                        (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                        :
                                        ("")
                                    }
                                    {
                                        this.props.stateData.savaCampaignLoadingSuccessMsg == "Visited SuccessFully..." ?
                                        (<h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.props.stateData.savaCampaignLoadingSuccessMsg}</h6>)
                                        :
                                        (
                                            <>
                                            <h6 className='text-center f-14 color_red mt-5 fw-bold ln-22'>{this.props.stateData.savaCampaignLoadingSuccessMsg}</h6>

                                            

                                            </>
                                        )
                                    }

                                    {
                                        this.props.stateData.savaCampaignLoadingSuccessMsg != "Visited SuccessFully..."  &&  this.props.stateData.savaCampaignLoadingSuccessMsg != "" ? 
                                        (
                                            <Row>
                                                <Col>
                                                    <Button variant="danger" className="red_button_event center-auto btn-sm f-14 mt-3" onClick={() => this.props.reloadPageForHouseVisit()}>RELOAD PAGE </Button>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                    }
                                    
                                </Col>
                            </Row>
                            </>
                  
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default QuestionsSavingPart;
  

