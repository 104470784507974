
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,OverlayTrigger,Tooltip,InputGroup,Form } from 'react-bootstrap';
import { getAPICallData } from '../../services/MemberService';
import "./BoothWiseNearestPlaces.css";
import { withRouter,Link } from "react-router-dom";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearchLimit10 from '../datatables/CardLessDataTableWithSearchLimit10';
import EnabledLocationPopup from '../Volunteer/EnabledLocationPopup';  

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

  function haversine(lat1, lon1, lat2, lon2) {
    // Convert latitude and longitude from degrees to radians
    const degToRad = (angle) => angle * (Math.PI / 180);
    const R = 6371000; // Radius of Earth in meters

    const dLat = degToRad(lat2 - lat1);
    const dLon = degToRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in meters

    return distance;
  }

class BoothWiseNearestPlacesSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessListArr:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            boothIdsList:[],
            boothId:"0",
            boothNo:"",
            boothIdValue:"",
            houseLocationArr:[],
            houseLocationDataCheck:0,
            houseLocationLoading:"hide",
            getCurrentLocationCoordinatesObj:{},
            delaylocationModal:false,
            checkingEnabledLocationModal:false,
            filterBlockOpenVAC:false,
            distanceValue:"1000",
            fetchHouseValue:"10",
            tableHeadingName:"Booth Wise Nearest 10 House In 100 Meters",
            userLatitude:"",
            userLongitude:"",
            validateLocations:"",
            useCurrentLocationSymbol:"hide"
        }
        
    }

    componentDidMount() {
        let userAccessList = this.state.userAccessListArr;
        if(userAccessList != null && userAccessList.status == "Success" ){
            if(userAccessList.boothList != null && userAccessList.boothList.length>0){
                let boothListArr=[];
                userAccessList.boothList.map(item => {
                    const obj = {
                        value: item.id,
                        label: "Booth No -"+item.name,
                        attr_mapped:item.isMapped
                    }
                    boothListArr.push(obj);
                    
                });
                this.setState({ boothIdsList: boothListArr });
                
               
                
            }
        }else{
            this.props.history.push({pathname:"/dashboard"});
            
        }
    }

    

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        
      }

    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;

        console.log(name,value)
    
        if(name == "boothId"){
          let getBoothIdName = this.state.boothIdsList.find((a) => a.value == value);
            this.setState({
              boothIdValue: {
                  value: getBoothIdName.value,
                  label: getBoothIdName.label
              }
          });
    
          this.setState({ boothId: getBoothIdName.value,boothNo:"Booth "+getBoothIdName.label});
          
        }

        this.setState({ [name]: value });
    }

    handleOnchangeSelectBox = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    getHousesLocations = () => {
        this.setState({validateboothId:"",validateLocations:""});
        if(this.state.boothId == "" || this.state.boothId == 0){
            this.setState({validateboothId:"Please Select Booth" });
            return;
        }
        if((this.state.userLatitude == "" || this.state.userLatitude == 0) || (this.state.userLongitude == "" || this.state.userLongitude == 0)){
            this.setState({validateLocations:"Please Select Locations" });
            return;
        }
        
        console.log(this.state.distanceValue)
        this.setState({tableHeadingName:'Booth Wise Nearest '+this.state.fetchHouseValue+' House In '+this.state.distanceValue+' Meters'})
        this.getMappedHousesLocationInABoothData();
    }

    getMappedHousesLocationInABoothData() {
        this.setState({houseLocationArr:[],houseLocationDataCheck:0,houseLocationLoading:"show"});
        const mapObj =  
              JSON.stringify({ 
              "username" : "bsa_api",
              "password" : "9D#Jcx!i5B$z@ym4d7K2f",
              "chatId" :this.state.chatID,
              "boothId" : this.state.boothId
              }
          ); 
    
              getAPICallData(mapObj,"getMappedHousesLocationInABooth")
              .then((res) => res.json())
              .then((data) => {
                this.setState({houseLocationLoading:"hide"});
                if(data !=null && data.length>0){
                    this.setState({houseLocationArr:data,houseLocationDataCheck:0});
                }else{
                    this.setState({houseLocationArr:[],houseLocationDataCheck:1});
                }
              
              });
        
      }
    

      boothNearestLocationsDataColumns() {

        let columns = [
            {
                dataField: "House_No",
                text: "House No",
                sort: true,
                
                
            },
            {
                dataField: "Location",
                text: "Street",
                
            },
            {
                dataField: "Distance",
                text: "Distance (mts)",
                
            },
            {
                dataField: "Coordinates",
                text: "Coordinates",
                formatter: this.actionsFormatterForCoordinates.bind(this),
                headerStyle: {
                    width: "36%"
                }
                
            }
        
        ];
        return columns;
    }

    actionsFormatterForCoordinates = (cell, row, rowIndex, formatExtraData) => {
       
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>lat : {(row.latitude)} 
                <><br/><span className='f-10' style={{textAlign:"left",marginLeft:"0px"}}>long : {(row.longitude)}</span></>
                <><br/><a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${row.latitude},${row.longitude}`}><Button variant="outline-danger" className='f-10  mt-2 pad_3'> <i class="fa-solid fa-map-location-dot"></i> Get Direction</Button></a> </>
                
            </span>
         );
        
    }
   

    boothNearestLocationsData = () => {
        let locationsData = [];
        let noOfHouseDisplayData=[];
        const currentLatitude =  this.state.userLatitude;
        const currentLongitude  = this.state.userLongitude;
        const distanceValue = parseInt(this.state.distanceValue);
        const noOfHouseDisplay = parseInt(this.state.fetchHouseValue);

        if(this.state.houseLocationArr.length>0){

            this.state.houseLocationArr.forEach( (data) => {
				const distance = haversine(currentLatitude,currentLongitude,data.latitude,data.longitude);
				
				if(distance !=0 && distance <= distanceValue){
                    const obj = {
                        House_No:(data.houseNo),
                        Location: data.street,
                        Distance: distance.toFixed(2),
                        latitude:data.latitude,
                        longitude:data.longitude
                    }
                    noOfHouseDisplayData.push(obj);
                    locationsData = noOfHouseDisplayData.slice(0, noOfHouseDisplay)

				} 
				
			});

            if(locationsData.length == 0){
                this.setState({houseLocationArr:[],houseLocationDataCheck:1});
            }

            return locationsData;
        }
    }

    closeEnabledModal = () => {
        this.props.history.push({
            pathname: "/boothWiseNearestPlaces",
            params: {
                userAccessList:this.state.userAccessListArr,
            
            },
        });
    }
    isFilterBlockOpenVAC = () => {
        this.setState({filterBlockOpenVAC:!this.state.filterBlockOpenVAC });
    }
    
    getCurrentLocation = () => {
        if(this.state.chatID != "1048868711"){
            if(navigator.geolocation){
                this.setState({useCurrentLocationSymbol:"show"})
                navigator.geolocation.getCurrentPosition(this.getCurrentLocationCoordinates,this.checkingLocationServiceEnabledError)
            }else{
              /* <EnabledLocationPopup 
                    show={() => this.setState({ checkingEnabledLocationModal: true })}
                    onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                    closeEnabledModal={this.closeEnabledModal}
                />*/
            }
            setTimeout(() => this.setState({delaylocationModal:true}), 2000);
        }
       
    }

    getCurrentLocationCoordinates = (position) => {
        const getCoordinates =  
            { 
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude,
            "accuracy":position.coords.accuracy
            }
            
        this.setState({getCurrentLocationCoordinatesObj:getCoordinates});
        this.setState({userLatitude:position.coords.latitude,userLongitude:position.coords.longitude});
        this.setState({useCurrentLocationSymbol:"hide"})
        
    }
  
    checkingLocationServiceEnabledError = (error) => {
        return(
          <EnabledLocationPopup 
                  show={() => this.setState({ checkingEnabledLocationModal: true })}
                  onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                  closeEnabledModal={this.closeEnabledModal}
           />
        )
          
      }

    render() {
       console.log(this.state.houseLocationArr,this.state.houseLocationDataCheck)
        return (
            <section>
               <Container>
               <Row>
                    <Col>
                        <h6 className='text-center fw-bold'>Booth Wise Nearest Houses</h6>
                    </Col>
                </Row>
                {/*
                        this.state.chatID != "1048868711" ?
                        (
                            this.state.delaylocationModal == true && Object.keys(this.state.getCurrentLocationCoordinatesObj).length == 0 ?
                            (
                                <EnabledLocationPopup 
                                    show={() => this.setState({ checkingEnabledLocationModal: true })}
                                    onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                                    closeEnabledModal={this.closeEnabledModal}
                                />
                             )
                            :
                            ("")
                        )
                        :
                        ("")
                    */ }
                <Row>
                    <Col className='w_80'>
                        <h6 className="form-label f-14 fw-bold">Booth<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-12'>{this.state.validateboothId}</span></h6>
                            <SelectBox
                                stateData={this.state.boothIdValue}
                                handleChange={this.handleOnchangeSelectBoxNew}
                                name="boothId"
                                options={this.state.boothIdsList}
                                placeholderName={'Select Booth'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                    <Col className='w_20'>
                        <OverlayTrigger key={"top"} placement={"top"}
                            overlay={
                                <Tooltip id={`tooltio-Filter`}>
                                <h6 className='f-14'>Show Filter</h6>
                                </Tooltip>
                            }
                            >
                            <h6 className='float-right cursor_pointer color_red filter_pos' onClick={() => this.isFilterBlockOpenVAC()}><i class="fa-solid fa-filter f-28" ></i></h6>   
                        </OverlayTrigger>
                        
                    </Col>
                </Row>
                {
                    this.state.filterBlockOpenVAC == true ? 
                    (
                        <>
                        <Row>
                            <Col>
                                <InputGroup className="mt-3">
                                    <InputGroup.Text id="basic-addon1" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>Distance</InputGroup.Text>
                                    <select id="distanceId" name="distanceValue" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                                <option value="">Select Distance</option>
                                                <option value="1000" selected>1000</option>
                                                <option value="2000">2000</option>
                                                <option value="3000">3000</option>
                                                <option value="4000">4000</option>
                                                <option value="5000">5000</option>
                                            </select>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="mt-3">
                                    <InputGroup.Text id="basic-addon2" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>Houses</InputGroup.Text>
                                    <select id="fetchHouseValueId" name="fetchHouseValue" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                                <option value="">Select Count</option>
                                                <option value="10" selected>10</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="40">40</option>
                                                <option value="50">50</option>
                                            </select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <h6 className='f-12 mt-2 fw-bold'>
                                    Note : <span className='ml_5 mr_2 color_red'>Distance In Meters,</span>
                                            
                                </h6>
                            </Col>
                        </Row>
                        

                        
                        
                        </>
                    )
                    :
                    ("")
                }


                    <Row>
                        <Col>
                            <Form.Group className="" >
                                <Form.Label className='f-12 fw-bold'>Locations :<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateLocations}</span> </Form.Label>
                                <Row>
                                    <Col>
                                        <InputGroup className="">
                                            <InputGroup.Text id="basic-addon3" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>lat</InputGroup.Text>
                                            <Form.Control type="text" name="userLatitude" className='form-control-sm' value={this.state.userLatitude}  placeholder="Latitude" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className="">
                                            <InputGroup.Text id="basic-addon4" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>lang</InputGroup.Text>
                                            <Form.Control type="text" name="userLongitude" className='form-control-sm' value={this.state.userLongitude}   placeholder="Longitude" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="outline-danger" className='f-10  mt-2 pad_3 float-end' onClick={() => this.getCurrentLocation()}> <i class="fa-solid fa-location-dot"></i> Use current location
                                {
                                    this.state.useCurrentLocationSymbol == "show" ?
                                    (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                    :
                                    ("") 
                                }
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getHousesLocations()}>GET Locations</Button> 
                        </Col>
                    </Row>

                {
                    this.state.houseLocationLoading == "show" ?
                    (
                        <Row>
                            <Col sm={12}>
                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                            </Col>
                        </Row>
                    )
                    :
                    ("")
                }
                {
                    this.state.houseLocationArr.length>0 ?
                    (
                        
                        <>
                        <Row>
                            <Col sm={12}>
                        
                            <div className='bg_light_blue'>
                            <CardLessDataTableWithSearchLimit10 
                                parentData={this.boothNearestLocationsData()}
                                particlecolumns={this.boothNearestLocationsDataColumns()}
                                defaultSorted={[{
                                    dataField: "House_No",
                                    order: "asc"
                                }]}
                                tableHeading={this.state.tableHeadingName}
                                />
                                
                                    
                            </div>
                            
                            </Col>
                        </Row>
                        
                        </>
                    )
                    :
                    (<>
                        {
                        this.state.houseLocationDataCheck == 1 ?
                        (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                        :
                        ("")
                    }
                    </>)
                }
                                    
                    
               </Container>
               
            
            </section>
        )
    }
  }
  
  export default withRouter(BoothWiseNearestPlacesSection);
  