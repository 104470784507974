
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button  } from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService';
import MapComponentData from "./MapComponentData";

class DisplayGoogleMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
          userAccessListArr:props?.location?.params?.userAccessListArr == undefined ? "" : props?.location?.params?.userAccessListArr,
          chatID: window.User !=undefined ? window.User.chatID : "943827154",
          boothListData:[],
          boothId:props?.location?.params?.boothId == undefined ? 0 : props?.location?.params?.boothId,
          boothNo:props?.location?.params?.boothNo == undefined ? "" : props?.location?.params?.boothNo,
          accessName:props?.location?.params?.accessName == undefined ? "" : props?.location?.params?.accessName,
          checkMapsData:props?.location?.params?.checkMapsData == undefined ? false : props?.location?.params?.checkMapsData,
          
          
        }
    }
  
    componentDidMount() {
      this.getAllBoothsData();
  }

  getAllBoothsData = () => {
        const userAccessObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID
        }
      ); 

      //getBooths
      getAPICallData(userAccessObj,"getUserAccessLocationDetails")
          .then((res) => res.json())
          .then((userAccessList) => {
              if(userAccessList !=null && userAccessList.boothList !=null && userAccessList.boothList.length>0){
                this.setState({ boothListData: userAccessList.boothList });
              }else{
                this.setState({ boothListData: []});
              }
            
              
              
      });
  }

  onChangeMapsDataBoothWise(e) {
    this.setState({checkMapsData:false });
    var index1 = e.target.selectedIndex;
    this.setState({ boothNo:e.target[index1].text,boothId:e.target.value });

    const mapObj =  
          JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothId" : e.target.value
          }
      ); 

          getAPICallData(mapObj,"getMappedHousesLocationInABooth")
          .then((res) => res.json())
          .then((data) => {
          if(data !=null && data.length>0){
              this.setState({checkMapsData:true });
          }else{
              this.setState({checkMapsData:false });
          }
          
          });
    
  }

    render() {
      console.log("userAccessListArr",this.state.mapsDataAvailable)
        return (
            <>
            
            <Container fluid>

                <Row>
                  <Col>
                    <Link to={{pathname: "/verify_house_dashboard",
                                params: {
                                  userAccessList:this.state.userAccessListArr,
                                }}}> 
                        <Button variant="outline-dark pad5_14 float-end" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                    </Link>
                  </Col>
                </Row>

              {
                this.state.boothListData.length>0 && this.state.boothListData.length > 1 ?
                (
                    <>
                    <Col className=''>
                        <h6 className="form-label f-14 fw-bold">Booth </h6>
                        <select id="booth_id" name="boothId" className='form-select form-select-sm' onChange={(e) => this.onChangeMapsDataBoothWise(e)}>
                            {
                                this.state.boothListData.length>0 ? 
                                (
                                    <>
                                    {
                                        this.state.boothListData.map((item,index) => {
                                          return(
                                            <>
                                            {
                                              this.state.boothId !=undefined ? 
                                              (
                                                <>
                                                  {
                                                    this.state.boothId == item.id ? 
                                                    (<option key={index} value={item.id} selected>Booth No - {item.name}</option>)
                                                    :
                                                    (<option key={index} value={item.id} >Booth No - {item.name}</option>)
                                                  }
                                                </>
                                              )
                                              :
                                              (<option key={index} value={item.id} >Booth No - {item.name}</option>)
                                            }
                                            </>
                                            
                                          )
                                        })
                                    }
                                    </>
                                
                                )
                                :
                                (<option value="">Select Booth</option>)
                                
                            }
                        </select>
                    </Col>
                    </>
                  )
                  :
                  ("")
                }
                
                
                <Row>
                    <Col sm={12} className='mt-4 mb-3'>
                        {
                          this.state.checkMapsData == true ?
                          (
                            <div style={{height: "100vh" }}>
                            <MapComponentData
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC5LChQlgAYuNsJra-_oUlpluSRyokMOLs&v=3.exp&libraries=geometry,drawing,places}`}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100%` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                booth_id={this.state.boothId}
                              />
                              </div>
                        
                          )
                          :
                          (<h6 className="text-center mt-3">Coordinates Not Available</h6>)
                        }
                    </Col>
                    
                     
                </Row>  
            </Container>
            </>
        )
    }
  }
  
  export default withRouter(DisplayGoogleMap);
  