
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "./PollManagement.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import arrowLeft from '../../images/arrow-left.png';
import poll_mang from '../../images/poll_mang.png';
import MultiSelect from "react-multi-select-component";
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';


var cadre_status=[{'id':'Y','name':'Yes'},{'id':'N','name':'No'}];

var polling_status=[{'id':'Y','name':'Yes'},{'id':'N','name':'No'}];

class PollManagementSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            voterName:"",
            serialNo:"",
            cadreStatus:"",
            pollingStatus:"",
            partyArr : [{'value':872,'label':'TDP'},{'value':1117,'label':'YSRC'},{'value':1892,'label':'JSP'},{'value':1887,'label':'Others'}],
            partyseldata: [],
            partyselvalue: [],
            MLCvoterType:'CUBS',
            MLC_clusterNames:[],
            MLC_unitNames:[],
            MLC_boothList:[],
            MLC_MandalNames:[],
            MLC_VillageNames:[],
            MLC_StreetNames:[],
            clusterId:"",
            unitId:"",
            boothId:"",
            mandalId:"",
            villageId:"",
            villageCheckLoader:0,
            streetId:"",
            streetCheckLoader:0,
            streetCheckMessage:0,
            setLocationScopeId:0,
            setLocationId:0,
            villageName:"",
            streetName:"",
            MandalView_locationScopeId:0,
            validateReasonMandal:"",
            validateReasonVillage:"",
            validateReasonCubs:"",
            MLCPollVoterDataArr:[],
            MLCPollVoterDataLoadingSymbol:"hide",
            votersListCheck:0,
            succuessMsg:[],
            visitLoaderSymbol:"hide",
            PollvoterIdCheck:0,
            selectedVoterListTypeName:[],
            showSearchOptions:false,
            MLC_BoothLoc:[],
            MLCBoothLocId:"",
            MLCBoothLocName:"",
            validateReasonMLCBoothLoc:""
        }
    }

    componentDidMount() {
        this.getSubLocationsByUserAccess(17);
        this.getSubLocationsByUserAccess(18);
        this.getSubLocationsByUserAccess(9);
        this.getMandalTownsByUserAccess();
        
    }

    selectedVoterType = (value) => {
        this.setState({MLC_clusterNames:[],MLC_unitNames:[],MLC_boothList:[],clusterId:"",
                      unitId:"",
                      boothId:"",
                      mandalId:"",
                      villageId:"",
                      villageCheckLoader:0,
                      streetId:"",
                      streetCheckLoader:0,
                      streetCheckMessage:0,
                      setLocationScopeId:0,
                      setLocationId:0,
                      villageName:"",
                      streetName:"",
                      MandalView_locationScopeId:0,
                      validateReasonMandal:"",
                      validateReasonVillage:"",
                      validateReasonCubs:"",
                      MLCPollVoterDataLoadingSymbol:"hide",
                      votersListCheck:0,
                      succuessMsg:[],
                      visitLoaderSymbol:"hide",
                      PollvoterIdCheck:0,
                      selectedVoterListTypeName:[],
                      MLCPollVoterDataArr:[],
                      showSearchOptions:false,
                      MLC_BoothLoc:[],
                      MLCBoothLocId:"",
                      validateReasonMLCBoothLoc:"",
                      MLCBoothLocName:""
                     
                    });
    
       
        
        if(value == "CUBS"){
          this.getSubLocationsByUserAccess(17);
          this.getSubLocationsByUserAccess(18);
          this.getSubLocationsByUserAccess(9);
        }else if(value == "mandal"){
          this.getMandalTownsByUserAccess();
        }else{
            this.getMlcBoothsInAssemblyConstituency();
        }
        
        this.setState({MLCvoterType:value});
      }

      getMlcBoothsInAssemblyConstituency = () => {
        // this.setState({MLC_clusterNames:[],MLC_unitNames:[],MLC_boothList:[]});
            const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                
            });
            

        getCampaignAPICallData(userAccessObj,"getMlcBoothsInAssemblyConstituency")
            .then((res) => res.json())
            .then((data) => { 
                if(data !=null && data.length>0){
                    this.setState({MLC_BoothLoc:data});
                }else{
                    this.setState({MLC_BoothLoc:[]});
                }
                
            });
      }

    getSubLocationsByUserAccess = (subLocationScopeId) => {
        // this.setState({MLC_clusterNames:[],MLC_unitNames:[],MLC_boothList:[]});
         const userAccessObj =  
             JSON.stringify({ 
                 "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                 "subLocationScopeId":subLocationScopeId,
                 
             });
              
     
         getCampaignAPICallData(userAccessObj,"getSubLocationsByUserAccess")
             .then((res) => res.json())
             .then((data) => { 
                 if(subLocationScopeId == 17){
                   if(data !=null && data.length>0){
                     this.setState({MLC_clusterNames:data});
                   }else{
                       this.setState({MLC_clusterNames:[]});
                   }
                 }else if(subLocationScopeId == 18){
                   if(data !=null && data.length>0){
                     this.setState({MLC_unitNames:data});
                   }else{
                       this.setState({MLC_unitNames:[]});
                   }
                 }else if(subLocationScopeId == 9){
                   if(data !=null && data.length>0){
                     this.setState({MLC_boothList:data});
                   }else{
                       this.setState({MLC_boothList:[]});
                   }
                 }
                 
              });
       }
     
     
       
       getMandalTownsByUserAccess = () => {
         this.setState({MLC_MandalNames:[],MLC_StreetNames:[],MLC_VillageNames:[]});
         const userAccessObj =  
             JSON.stringify({ 
                 "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                 
             });
              
     
         getCampaignAPICallData(userAccessObj,"getMandalTownsByUserAccess")
             .then((res) => res.json())
             .then((data) => { 
               if(data !=null && data.length>0){
                 this.setState({MLC_MandalNames:data});
               }else{
                   this.setState({MLC_MandalNames:[]});
               }
                 
              });
       }
       
       getVillagesForMLCVoters = (locationScopeId,locationId) => {
         this.setState({MLC_VillageNames:[]});
         this.setState({villageCheckLoader:1});
         const userAccessObj =  
             JSON.stringify({ 
                 "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                 "locationScopeId" : locationScopeId,
                 "locationId" : parseInt(locationId)
                 
             });
              
     
         getCampaignAPICallData(userAccessObj,"getVillagesForMLCVoters")
             .then((res) => res.json())
             .then((data) => { 
               if(data !=null && data.length>0){
                 this.setState({MLC_VillageNames:data,villageCheckLoader:0});
               }else{
                   this.setState({MLC_VillageNames:[],villageCheckLoader:0});
               }
                 
              });
       }
       
       getStreetsForMLCVoters = (locationScopeId,villageName) => {
         this.setState({MLC_StreetNames:[]});
         this.setState({streetCheckLoader:1});
         const userAccessObj =  
             JSON.stringify({ 
                 "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                 "locationScopeId" : locationScopeId,
                 "locationId" : this.state.mandalId,
                 "village" : villageName.replace(/ *\([^)]*\) */g, "").trim()
                 
             });
              
     
         getCampaignAPICallData(userAccessObj,"getStreetsForMLCVoters")
             .then((res) => res.json())
             .then((data) => { 
               if(data !=null && data.length>0){
                 this.setState({MLC_StreetNames:data,streetCheckLoader:0,streetCheckMessage:0});
               }else{
                   this.setState({MLC_StreetNames:[],streetCheckLoader:0,streetCheckMessage:1});
               }
                 
              });
       }

       handleOnchangeSelectBoxForCubs = (e,type) => {
        this.setState({MLCPollVoterDataArr:[],votersListCheck:0});
        this.setState({streetCheckMessage:0,validateReasonMandal:"",validateReasonVillage:"",validateReasonCubs:""});

        this.setState({voterName:0,serialNo:"",cadreStatus:"",pollingStatus:"",partyselvalue:[],partyseldata:[],showSearchOptions:false});

        const name = e.target.name;
        const value = e.target.value;
        var index = e.target.selectedIndex;
    
        if(type == "cluster"){
          this.setState({MLC_unitNames:[],MLC_boothList:[],setLocationScopeId:17,setLocationId:value,selectedVoterListTypeName:e.target[index].text});
          this.getSubLocationsByUserAccess(18);
          this.getSubLocationsByUserAccess(9);
        }else if(type == "unit"){
          this.setState({MLC_clusterNames:[],MLC_boothList:[],setLocationScopeId:18,setLocationId:value,selectedVoterListTypeName:e.target[index].text});
          this.getSubLocationsByUserAccess(17);
          this.getSubLocationsByUserAccess(9);
        }else if(type == "booth"){
          this.setState({MLC_clusterNames:[],MLC_unitNames:[],setLocationScopeId:9,setLocationId:value,selectedVoterListTypeName:e.target[index].text});
          this.getSubLocationsByUserAccess(17);
          this.getSubLocationsByUserAccess(18);
          
        }
        this.setState({ [name]: value });
    
        
        
      }

      handleOnchangeSelectBoxForMandal = (e,type) => {
        this.setState({MLCPollVoterDataArr:[],votersListCheck:0});
        this.setState({voterName:0,serialNo:"",cadreStatus:"",pollingStatus:"",partyselvalue:[],partyseldata:[],showSearchOptions:false});
        this.setState({streetCheckMessage:0,validateReasonMandal:"",validateReasonVillage:"",validateReasonCubs:""});
        
    
        const name = e.target.name;
        const value = e.target.value;
        var index = e.target.selectedIndex;
    
        if(type == "mandal"){
          this.setState({ mandalId: value });
          setTimeout(() => {
            var mandalNameSplit = e.target[index].text.split(" ");
            if(mandalNameSplit[1] == "Town"){
              this.setState({setLocationScopeId:7,setLocationId:value,MandalView_locationScopeId:7 });
              this.getVillagesForMLCVoters(7,value);
            }else{
              this.setState({setLocationScopeId:5,setLocationId:value,MandalView_locationScopeId:5});
              this.getVillagesForMLCVoters(5,value);
            }
      
            
          }, 500);
    
        }else if(type == "village"){
          this.setState({ villageId: value,villageName:e.target[index].text,setLocationScopeId:6,setLocationId:value,selectedVoterListTypeName:e.target[index].text});
          this.getStreetsForMLCVoters(5,e.target[index].text);
        }else if(type == "street"){
          this.setState({ streetId: value,streetName:e.target[index].text,setLocationScopeId:0,setLocationId:value,selectedVoterListTypeName:e.target[index].text});
        }
      }

      handleOnchangeSelectBoxForMLCBooth = (e,type) => {
        this.setState({MLCPollVoterDataArr:[],votersListCheck:0});
        this.setState({voterName:0,serialNo:"",cadreStatus:"",pollingStatus:"",partyselvalue:[],partyseldata:[],showSearchOptions:false});
        const name = e.target.name;
        const value = e.target.value;
        var index = e.target.selectedIndex;
        this.setState({ MLCBoothLocId: value,MLCBoothLocName:e.target[index].text,setLocationScopeId:20,setLocationId:value,selectedVoterListTypeName:e.target[index].text});
      }

      handleOnchangeVoterSelect = (e,type) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });
      }

   

    handleMultipleSelectInputChange = async (e, name) => {
        console.log("eeeee", e,name);
        
        let etesttype = Array();
        let etesttypevalue = Array();
        if (e != null && e.length > 0) {
            for (let i = 0; i < e.length; i++) {
                const etesttypeval = e[i];
                const newObj = {
                    label: etesttypeval.label,
                    value: etesttypeval.value
                }
                etesttypevalue.push(newObj);
                etesttype.push(etesttypeval.value);
            }
            this.setState({
                partyseldata: etesttypevalue,
                partyselvalue: etesttype,
                
            });
        }else{
            this.setState({
                partyseldata: [],
                partyselvalue: [],
                
            }); 
        }
    };

    getMlcVoterDetailsForPoll = () => {
        this.setState({MLCPollVoterDataArr:[],votersListCheck:0});
        this.setState({validateReasonMandal:"",validateReasonVillage:"",validateReasonCubs:"",votersListCheck:0,validateReasonMLCBoothLoc:"" });
        if(this.state.MLCvoterType == "mandal" ){
            if(this.state.mandalId == ""){
                this.setState({validateReasonMandal:"Please Select Mandal" });
                return;
            }
            else if(this.state.villageId == ""){
                this.setState({validateReasonVillage:"Please Select Village" });
                return;
            }    
        }else if(this.state.MLCvoterType == "CUBS" ){
            if(this.state.clusterId == "" &&  this.state.unitId == "" && this.state.boothId == "" ){
                this.setState({validateReasonCubs:"Please Select Any one Cluster/Unit/Booth...." });
                return;
            } 
        }else{
            if(this.state.MLCBoothLocId == "" ){
                this.setState({validateReasonMLCBoothLoc:"Please Select Location" });
                return;
            } 
        }

        this.setState({MLCPollVoterDataLoadingSymbol:"show"});
       
       let parties_Arr=[];
       if(this.state.partyselvalue.length == 4){
            parties_Arr=[];
       }else{
        parties_Arr = this.state.partyselvalue;
       } 


       let userAccessObj;
       if(this.state.setLocationScopeId == 6){
            if(this.state.MandalView_locationScopeId == 5){
                userAccessObj =  
                JSON.stringify({ 
                    "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
                    "locationScopeId" : 6,
                    "locationId" : 0,
                    "tehsilId" : this.state.mandalId,
                    "village" : this.state.villageName.replace(/ *\([^)]*\) */g, "").trim(),
                    "isPolled" : this.state.pollingStatus,
                    "partyIds" : parties_Arr,
                    "isCadre" : 'N',
                    "voterName" : this.state.voterName,
                    "serialNo": parseInt(this.state.serialNo),  
                    
                });
            }else{
                userAccessObj =  
                JSON.stringify({ 
                    "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
                    "locationScopeId" : 6,
                    "locationId" : 0,
                    "townId" : this.state.mandalId,
                    "village" : this.state.villageName.replace(/ *\([^)]*\) */g, "").trim(),
                    "isPolled" : this.state.pollingStatus,
                    "partyIds" : parties_Arr,
                    "isCadre" : 'N',
                    "voterName" : this.state.voterName,
                    "serialNo": parseInt(this.state.serialNo),  
                    
                });
            }
            
        }else if(this.state.setLocationScopeId == 0){
            if(this.state.MandalView_locationScopeId == 5){
                userAccessObj =  
                JSON.stringify({ 
                    "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
                    "locationScopeId" : 0,
                    "locationId" : 0,
                    "tehsilId" : this.state.mandalId,
                    "village" : this.state.villageName.replace(/ *\([^)]*\) */g, "").trim(),
                    "streetName" :this.state.streetName.replace(/ *\([^)]*\) */g, "").trim(),
                    "isPolled" : this.state.pollingStatus,
                    "partyIds" : parties_Arr,
                    "isCadre" : 'N',
                    "voterName" : this.state.voterName,
                    "serialNo": parseInt(this.state.serialNo),  
                    
                });
            }else{
                userAccessObj =  
                JSON.stringify({ 
                    "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
                    "locationScopeId" : 0,
                    "locationId" : 0,
                    "townId" : this.state.mandalId,
                    "village" : this.state.villageName.replace(/ *\([^)]*\) */g, "").trim(),
                    "streetName" :this.state.streetName.replace(/ *\([^)]*\) */g, "").trim(),
                    "isPolled" : this.state.pollingStatus,
                    "partyIds" : parties_Arr,
                    "isCadre" : 'N',
                    "voterName" : this.state.voterName,
                    "serialNo": parseInt(this.state.serialNo),  
                    
                });
            }
        }else if(this.state.setLocationScopeId == 20){
            userAccessObj =  
                JSON.stringify({ 
                "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
                "locationScopeId" : this.state.setLocationScopeId,
                "locationId" : this.state.setLocationId,
                "isPolled" : this.state.pollingStatus,
                "partyIds" : parties_Arr,
                "isCadre" : 'N',
                "voterName" : this.state.voterName,
                "serialNo": parseInt(this.state.serialNo),    
                });
        }else{
            userAccessObj =  
                JSON.stringify({ 
                "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
                "locationScopeId" : this.state.setLocationScopeId,
                "locationId" : this.state.setLocationId,
                "isPolled" : this.state.pollingStatus,
                "partyIds" : parties_Arr,
                "isCadre" : 'N',
                "voterName" : this.state.voterName,
                "serialNo": parseInt(this.state.serialNo),    
                });
        }

        console.log(userAccessObj);

        getCampaignAPICallData(userAccessObj,"getMlcVoterDetailsForPoll")
            .then((res) => res.json())
            .then((data) => { 
                this.setState({MLCPollVoterDataLoadingSymbol:"hide"});
                if(data !=null && data.length>0){
                    this.setState({MLCPollVoterDataArr:data,votersListCheck:0});
                }else{
                    this.setState({MLCPollVoterDataArr:[],votersListCheck:1});
                }
        });
                
           
    }


    
  MLCPollvotersDataColumns() {

    let columns = [
        {
            dataField: "serialNo",
            text: "serial No",
            sort: true,
            
        },
        {
            dataField: "VoterName",
            text: "Voter Name",
            sort: true,
            
        },
        {
            dataField: "Gender",
            text: "Gender / Age",
            sort: true,
            
        },
        {
            dataField: "MobileNo",
            text: "Mobile No",
            sort: true,
           
        },
        
        {
          dataField: "isPolled",
          text: "isPolled",
          formatter: this.actionsFormatterForVoter.bind(this),
      },
        
        
        
    ];
    return columns;
}

actionsFormatterForVoter = (cell, row, rowIndex, formatExtraData) => {
  console.log(row)
  return (
            <>
            {
                
                row.isPolledStatus == 'N' ?
                (
                    <OverlayTrigger key={"top"} placement={"top"}
                        overlay={
                            <Tooltip id={`tooltio-link`}>
                            <h6 className='f-14'>Need To Polled</h6>
                            </Tooltip>
                        }
                        >
                        <>
                            <i className= "fa-solid fa-circle-xmark f-14 ms-4 color_red cursor_pointer" onClick={() => this.verifyMLCVoterPolled(row.voterId,'Y')}></i> 
                            {
                            ((this.state.visitLoaderSymbol == "show") && (row.voterId == this.state.PollvoterIdCheck)) ?
                                (<Image src={loader} alt="loader" className='loader_cls'></Image>)
                                :
                                ("") 
                            }
                        </>
                        
                        </OverlayTrigger>
                )
                :
                (
                    <Row>
                        <Col sm={12}>
                            <div className='flex_block_poll'>
                                <i className="f-14 fa-solid fa-circle-check color_green"></i>
                                <i className="fa-solid fa-rotate-left f-14 color_red cursor_pointer" onClick={() => this.verifyMLCVoterPolled(row.voterId,'N')}></i>
                            </div>
                            
                        </Col>
                        <Col>
                            
                        </Col>
                    </Row>
                   
                )
            }
            </>
        )
  
}

MLCPollvotersData = () => {
  let MLCPOllVotersDataArr = [];
      if(this.state.MLCPollVoterDataArr.length>0){
          for (let i = 0; i < this.state.MLCPollVoterDataArr.length; i++) {
              const obj = {
                serialNo: this.state.MLCPollVoterDataArr[i].serialNo,
                VoterName: this.state.MLCPollVoterDataArr[i].voterName,
                Gender: this.state.MLCPollVoterDataArr[i].gender+"/"+this.state.MLCPollVoterDataArr[i].age,
                MobileNo: this.state.MLCPollVoterDataArr[i].mobileNo != "" ? <a href={`tel:+91 ${this.state.MLCPollVoterDataArr[i].mobileNo}`} className="color_black" ><i class="fa-solid fa-phone"></i>{this.state.MLCPollVoterDataArr[i].mobileNo}</a> : "",
                isPolledStatus: this.state.MLCPollVoterDataArr[i].isPolled,
                voterId: this.state.MLCPollVoterDataArr[i].voterId,
              }
              MLCPOllVotersDataArr.push(obj);
          } 

          
          
          return MLCPOllVotersDataArr;
      }
}

verifyMLCVoterPolled = (voterId,isPolled) => {
    this.setState({PollvoterIdCheck:voterId,visitLoaderSymbol:"show"})
    
    const addNewVoterObj =  
      JSON.stringify({ 
        "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
        "voterId" : voterId,
        "isPolled" :isPolled,
       }
    ); 

    

    //addNewVoter
    getCampaignAPICallData(addNewVoterObj,"updateMlcVoterPollStatus")
        .then((res) => res.json())
        .then(async(data) => {
          if(data !=null){
            this.setState({PollvoterIdCheck:0,visitLoaderSymbol:"hide"})
            if(data.status == "Success"){
              this.setState({succuessMsg:"Voter Polled SuccessFully..." });
              setTimeout(() => {
                this.setState({succuessMsg:""})

                let new_updated_data = this.state.MLCPollVoterDataArr.map((item) => {
                    if (item.voterId == voterId) {
                        return {
                            ...item,
                            isPolled: isPolled,
                        };
                    }
                    return item;
                });
                this.setState({MLCPollVoterDataArr:new_updated_data})
            }, 1000);
            
            }else if(data.status == "Fail"){
              if(data.message == "Invalid Inputs"){
                this.setState({succuessMsg:"Something Wrong,Please Try Again..." });
              }else{
                this.setState({succuessMsg:data.message });
              }
              
            }
          }else{
            this.setState({succuessMsg:"Something Wrong,Please Try Again..." });
          }
         
          
          
    });
}

showSearchOptionsBlock = () => {
    this.setState({showSearchOptions:!this.state.showSearchOptions});
    
}

    render() {
        
        let partyselectall = true;
        let partylabelledBY = "Select Party";
        let partydisablesearch = false;
        if (this.state.partyArr.length > 0) {
            partyselectall = true;
            partydisablesearch = false;
        }
        else {
            partydisablesearch = true;
            partyselectall = false;
            partylabelledBY = "No Options"
        }

        return (
            <section>
                <div className='bg_red_event'>
                    <h6 className='color_white fw-bold mt-3'><Link to={{pathname: "/dashboard"}}> 
                    <Image src={arrowLeft} alt="Arrow" ></Image></Link> <span className='ml-4 f-18'>Poll Management</span></h6>
                    {/*<div className='white_block_with_left_border mt-3'>
                        <Image src={poll_mang} alt="Arrow" className='w-100' ></Image>
                    </div>*/}
                </div>
                <Container>
                    
                    <Row>
                        <Col sm={12} className="">
                            <h6 className='f-14 fw-bold mt-2'>View Type :</h6>
                            <div className='border_pad mt-2'>
                                <Row>
                                    <Col>
                                    <Form >
                                        <Form.Check
                                            inline
                                            label="CUBS"
                                            name="voters_type"
                                            type="radio"
                                            id="crudViewId"
                                            defaultChecked={true}
                                            onChange={()=>this.selectedVoterType("CUBS")}
                                            className="f-14 fw-bold"
                                            value="CRUD"
                                        />
                                        
                                        <Form.Check
                                            inline
                                            label="Mandal"
                                            name="voters_type"
                                            type="radio"
                                            id="mandalViewId"
                                            onChange={()=>this.selectedVoterType("mandal")}
                                            className="f-14 fw-bold"
                                            value="mandal"
                                        />
                                         <Form.Check
                                            inline
                                            label="MLC Booth"
                                            name="voters_type"
                                            type="radio"
                                            id="MLC_BoothId"
                                            onChange={()=>this.selectedVoterType("Mlc_booth")}
                                            className="f-14 fw-bold"
                                            value="Mlc_booth"
                                        />
                                    </Form>
                                    </Col>
                                </Row>
                                <hr className='mb-1 mt-1'></hr>
                                {
                                    this.state.MLCvoterType == "CUBS" ? 
                                    (
                                        <>
                                        <Row>
                                        <Col className='mt-2'>
                                                <label className="form-label f-13 fw-bold">Cluster 
                                                </label>
                                                <select id="cluster_id" name="clusterId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForCubs(e,"cluster")}>
                                                    {
                                                            this.state.MLC_clusterNames.length>0  ? 
                                                            (
                                                                <>
                                                                <option value="">Select Cluster</option>
                                                                {
                                                                    this.state.MLC_clusterNames.map((item,index) => {
                                                                        return(
                                                                        <option key={index} value={item.id} >{item.name}</option>
                                                                        
                                                                        )
                                                                    })
                                                                }
                                                                </>
                                                            
                                                            )
                                                            :
                                                            (<option value="">Select Cluster</option>)
                                                            
                                                        }
                                                </select>
                                                
                                            </Col>
                                            <Col className='mt-2'>
                                                <label className="form-label f-13 fw-bold">Unit 
                                                </label>
                                                <select id="unit_id" name="unitId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForCubs(e,"unit")}>
                                                    {
                                                            this.state.MLC_unitNames.length>0  ? 
                                                            (
                                                                <>
                                                                <option value="">Select Unit</option>
                                                                {
                                                                    this.state.MLC_unitNames.map((item,index) => {
                                                                        return(
                                                                        <option key={index} value={item.id} > {item.name}</option>
                                                                        
                                                                        )
                                                                    })
                                                                }
                                                                </>
                                                            
                                                            )
                                                            :
                                                            (<option value="">Select Unit</option>)
                                                            
                                                        }
                                                </select>
                                                
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col className='mt-2'>
                                                <label className="form-label f-13 fw-bold">Booth 
                                                </label>
                                                <select id="booth_id" name="boothId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForCubs(e,"booth")}>
                                                    {
                                                            this.state.MLC_boothList.length>0  ? 
                                                            (
                                                                <>
                                                                <option value="">Select Booth</option>
                                                                {
                                                                    this.state.MLC_boothList.map((item,index) => {
                                                                        return(
                                                                        <option key={index} value={item.id} >Booth No - {item.name}</option>
                                                                        
                                                                        )
                                                                    })
                                                                }
                                                                </>
                                                            
                                                            )
                                                            :
                                                            (<option value="">Select Booth</option>)
                                                            
                                                        }
                                                </select>
                                                
                                            </Col>
                                            {/* <Col>
                                                <Button type="button" className='btn btn-sm btn-success float-end m_top45_right_40' onClick={(e) => this.getMLCVoters(e,"cubs")}>GET Voters</Button>
                                                </Col>*/}
                                        </Row>
                                        </>
                                    )
                                    :
                                    (
                                        this.state.MLCvoterType == "mandal" ? 
                                        (
                                            <>
                                            <Row>
                                            <Col className='mt-2'>
                                                    <label className="form-label f-13 fw-bold">Mandal 
                                                    </label>
                                                    <select id="mandal_id" name="mandalId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForMandal(e,"mandal")}>
                                                        {
                                                                this.state.MLC_MandalNames.length>0  ? 
                                                                (
                                                                    <>
                                                                    <option value="">Select Mandal</option>
                                                                    {
                                                                        this.state.MLC_MandalNames.map((item,index) => {
                                                                            return(
                                                                            <option key={index} value={item.id} attr_name={item.name} >{item.name}</option>
                                                                            
                                                                            )
                                                                        })
                                                                    }
                                                                    </>
                                                                
                                                                )
                                                                :
                                                                (<option value="">Select Mandal</option>)
                                                                
                                                            }
                                                    </select>
                                                    {
                                                    this.state.validateReasonMandal !="" ?
                                                    (<span className='f-12 color_red'>{this.state.validateReasonMandal}</span>)
                                                    :
                                                    ("")
                                                    }
                                                    
                                                </Col>
                                                <Col className='mt-2'>
                                                    <label className="form-label f-13 fw-bold">Village 
                                                    {
                                                    this.state.villageCheckLoader == 1 ?
                                                    (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image> )
                                                    :
                                                    ("")
                                                    }
                                                    
                                                    </label>
                                                    <select id="village_id" name="villageId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForMandal(e,"village")}>
                                                        {
                                                                this.state.MLC_VillageNames.length>0  ? 
                                                                (
                                                                    <>
                                                                    <option value="">Select Village</option>
                                                                    {
                                                                        this.state.MLC_VillageNames.map((item,index) => {
                                                                            return(
                                                                            <option key={index} value={item.name} > {item.name}</option>
                                                                            
                                                                            )
                                                                        })
                                                                    }
                                                                    </>
                                                                
                                                                )
                                                                :
                                                                (<option value="">Select Village</option>)
                                                                
                                                            }
                                                    </select>
                                                    {
                                                    this.state.validateReasonVillage !="" ?
                                                    (<span className='f-12 color_red'>{this.state.validateReasonVillage}</span>)
                                                    :
                                                    ("")
                                                    }
                                                    
                                                </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='mt-2'>
                                                        <label className="form-label f-13 fw-bold">Street 
                                                        {
                                                        this.state.streetCheckLoader == 1 ?
                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                                        :
                                                        ("")
                                                        }
                                                        
                                                        </label>
                                                        <select id="street_id" name="streetId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForMandal(e,"street")}>
                                                            {
                                                                    this.state.MLC_StreetNames.length>0  ? 
                                                                    (
                                                                        <>
                                                                        <option value="">Select Street</option>
                                                                        {
                                                                            this.state.MLC_StreetNames.map((item,index) => {
                                                                                return(
                                                                                <option key={index} value={item.id} > {item.name}</option>
                                                                                
                                                                                )
                                                                            })
                                                                        }
                                                                        </>
                                                                    
                                                                    )
                                                                    :
                                                                    (<option value="">Select Street</option>)
                                                                    
                                                                }
                                                        </select>
                                                        {
                                                        this.state.streetCheckMessage == 1  ?
                                                        (<span className='f-12 color_red'>No Streets Available...</span>)
                                                        :
                                                        ("")
                                                        }
                                                        
                                                    </Col>
                                                </Row>
                                                </>
                                        )
                                        :
                                        (
                                            <>
                                            <Row>
                                            <Col className='mt-2'>
                                                    <label className="form-label f-13 fw-bold">MLC Booth Location 
                                                    </label>
                                                    <select id="MLC_Booth_LocId" name="MLCBoothLocId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForMLCBooth(e,"MLCBoothLocId")}>
                                                        {
                                                                this.state.MLC_BoothLoc.length>0  ? 
                                                                (
                                                                    <>
                                                                    <option value="">Select Location</option>
                                                                    {
                                                                        this.state.MLC_BoothLoc.map((item,index) => {
                                                                            return(
                                                                            <option key={index} value={item.id} attr_name={item.name} >{item.name}</option>
                                                                            
                                                                            )
                                                                        })
                                                                    }
                                                                    </>
                                                                
                                                                )
                                                                :
                                                                (<option value="">Select Location</option>)
                                                                
                                                            }
                                                    </select>
                                                    {
                                                    this.state.validateReasonMLCBoothLoc !="" ?
                                                    (<span className='f-12 color_red'>{this.state.validateReasonMLCBoothLoc}</span>)
                                                    :
                                                    ("")
                                                    }
                                                    
                                                </Col>
                                                </Row>
                                                </>
                                        )
                                        
                                    )
                                } 
                                {
                                    this.state.validateReasonCubs !="" ?
                                    (
                                        <Row>
                                            <Col>
                                               <h6 className='f-12 color_red text-center mt-2'>{this.state.validateReasonCubs}</h6>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    ("")
                                }
                                                  
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className='f-14 fw-bold mt-3'>Search Options :<span className='f-12 border_serch_option' onClick={(e) => this.showSearchOptionsBlock(e)}>{this.state.showSearchOptions == true ? "Click To Hide" : "Click To Show"} <i class="fa-solid fa-angle-down"></i></span></h6>   
                        </Col>
                    </Row>

                    {
                        this.state.showSearchOptions == true ?
                        (
                            <Row>
                                <Col>
                                    <div className='border_pad'>
                                        <Row>
                                            <Col>
                                                <label className="form-label f-12 fw-bold">Voter Name</label>
                                                <input type="text" className='form-control' name="voterName"  placeholder='Enter Voter Name'  onChange={(e) => this.handleOnchangeVoterSelect(e,"voterName")}></input>
                                            </Col>
                                            <Col>
                                                <label className="form-label f-12 fw-bold">Serial No</label>
                                                <input type="text" className='form-control' name="serialNo"  placeholder='Enter Serial No'  onChange={(e) => this.handleOnchangeVoterSelect(e,"serialNo")}></input>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/*<Col>
                                                <label className="form-label f-12 fw-bold">Cadre</label>
                                                <select id="cadreStatusId" name="cadreStatus" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeVoterSelect(e,"cadreStatus")}>
                                                    <option value="0">Select Cadre</option>
                                                        {
                                                            cadre_status.map((item,index) => {
                                                                return(
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                        
                                                </select>
                                            </Col>*/}
                                            <Col>
                                                <label className="form-label f-12 fw-bold">Polling Status</label>
                                                <select id="pollingStatusId" name="pollingStatus" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeVoterSelect(e,"pollingStatus")}>
                                                    <option value="0">Select Status</option>
                                                        {
                                                            polling_status.map((item,index) => {
                                                                return(
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                        
                                                </select>
                                            </Col>
                                            <Col>
                                                <label className="form-label f-12 fw-bold">Party</label>
                                                <MultiSelect
                                                        overrideStrings={{
                                                            "allItemsAreSelected": "All",
                                                            "selectSomeItems": partylabelledBY
                                                        }}
                                                        hasSelectAll={partyselectall}
                                                        options={this.state.partyArr}
                                                        disableSearch={partydisablesearch}
                                                        value={this.state.partyseldata}
                                                        onChange={(e) => this.handleMultipleSelectInputChange(e, "party")}
                                                        labelledBy={"Select"}
                                                        className="f-14 color_black"
                                                    />       
                                            </Col>
                                        </Row>
                                        
                                    </div>
                                    
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }

                    

                    <Row>
                        <Col>
                            <Button type="button" className='btn btn-sm btn-success center-auto mt-4 fw-bold' onClick={(e) => this.getMlcVoterDetailsForPoll(e)}>GET Voters</Button>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col>
                        {
                            this.state.MLCPollVoterDataLoadingSymbol == "show" && this.state.MLCPollVoterDataArr.length == 0  ?
                            (
                                <Row>
                                <Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>
                                </Row>
                            )
                            :
                            ("")
                            }

                            {
                            this.state.MLCPollVoterDataArr.length>0 ?
                            (
                                
                                <Row>
                                    <Col sm={12}>
                                        <h6 className='f-14 mt-3'>
                                            Note : <span className='ml_5 mr_2'><i class="fa-solid fa-circle-xmark f-14 ms-4 color_red"></i> Not Polled,</span>
                                                    <span className='ml_5 mr_2'><i class="f-14 fa-solid fa-circle-check color_green"></i> Polled,</span>
                                                    <span className='ml_5 mr_2'><i class="fa-solid fa-rotate-left f-14 color_red "></i> Undo</span>
                                        </h6>
                                        </Col>
                                    <Col sm={12}>
                                        <div className='bg_light_blue mt-3'>
                                           
                                           <h6 className='f-14'> {this.state.selectedVoterListTypeName} MLC Voter Details : 
                                            {
                                            this.state.succuessMsg != "" ?
                                            (<span className='f-12 color_green fw-bold mt-2 mb-2'>{this.state.succuessMsg}</span>)
                                            :
                                            ("")
                                            }
                                            </h6>
                                            
                                            <CardLessDataTableWithSearch 
                                                parentData={this.MLCPollvotersData()}
                                                particlecolumns={this.MLCPollvotersDataColumns()}
                                                defaultSorted={[{
                                                    dataField: "VoterName",
                                                    order: "desc"
                                                }]}
                                                tableHeading=""
                                                />
                                            
                                        </div>     
                                    </Col> 
                                </Row>
                            )
                            :
                            (
                                <>
                                {
                                this.state.votersListCheck == 1 ?
                                (<h6 className='f-14 text-center mt-4'>No Voters Availble...</h6>)
                                :
                                ("")
                                }
                                </>
                            )
                                
                            }
                        </Col>
                    </Row>
                           
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(PollManagementSection);
  