
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Button,Image,Card,Table,InputGroup} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif'; 
import './LeaderSearch.css';
import moment from "moment";
import { getAPICallDataForOnlyBSA } from '../../services/MemberService'


class LeaderNotMatchedRecordsSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateSelected:props?.location?.params?.dateSelected == undefined ? "" : props?.location?.params?.dateSelected,
            selectedType:props?.location?.params?.selectedType == undefined ? "" : props?.location?.params?.selectedType,

            
            notMatchedRecordsArr:[],
            notMatchedRecordsCheck:0,
            deleteLodingSymbol:"hide",
            selectedMarkDoneTdpCadreId:""
           
        }
    }
  
    componentDidMount() {
        if(this.state.selectedType == ""){
            this.props.history.push({
                pathname: `/leader_dashboard`,
                params: {
                    navbarDisplay:"hide",
                  }
            }); 
        }else{
            this.getLeaderSearchAnswerInBetweenDate();
        }
    }

    getLeaderSearchAnswerInBetweenDate = () => {
        this.setState({ notMatchedRecordsArr:[],notMatchedRecordsCheck:0});
        var fromDateValue="";
        var toDateValue = "";

       

        if(this.state.dateSelected == "OverAll"){
            fromDateValue = "2024-01-01";
            toDateValue = moment().format("YYYY-MM-DD");
        }else if(this.state.dateSelected == "Today"){
            fromDateValue = moment().format("YYYY-MM-DD");
            toDateValue = moment().format("YYYY-MM-DD");
        }else if(this.state.dateSelected == "Yesterday"){
            fromDateValue = moment().subtract(1, "days").format('YYYY-MM-DD');
            toDateValue = moment().subtract(1, "days").format('YYYY-MM-DD');
        }else{
            var splitDate = this.state.dateSelected.split('to');
            fromDateValue = splitDate[0];
            toDateValue = splitDate[1];

        }
        



        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            "fromDate" : fromDateValue,
            "toDate" : toDateValue,
            "isCorrect":"N"
        }); 

        getAPICallDataForOnlyBSA(obj,"getLeaderSearchAnswerInBetweenDate")
        .then((res) => res.json())
        .then((data) => {
            if(data !=null && data.length >0){
                const dataArr = data.map((item,index) => {
                    return {...item,indexValue:index+1,showCorrectName:false,markAsDone:false}
                })

                this.setState({ notMatchedRecordsArr: dataArr,notMatchedRecordsCheck:0 });

            }else{
                this.setState({ notMatchedRecordsArr:[],notMatchedRecordsCheck:1});
            }
        });
    }

    showCorrectPerson = (data) => {
        const getData = this.state.notMatchedRecordsArr.map((item) => {
            if (item.tdpCadreId == data.tdpCadreId) {
                return { ...item, showCorrectName: true}
            }
            return { ...item }
        });

        this.setState({notMatchedRecordsArr:getData});
    }

    markAsDoneRecord = (selectedRecord) => {
        this.setState({ responseMessageMarkAsDone:"",deleteLodingSymbol:"show",selectedMarkDoneTdpCadreId:selectedRecord.leaderSearchAnswerId });

        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            "leaderSearchAnswerId" : selectedRecord.leaderSearchAnswerId
        }); 

        getAPICallDataForOnlyBSA(obj,"deleteLeaderSearchAnswer")
        .then((res) => res.json())
        .then((data) => {
            this.setState({ deleteLodingSymbol:"hide" });
            if(data !=null){
               if(data.status == "Success"){
                    this.setState({ responseMessageMarkAsDone:"Mark As Done SuccessFully..." });
                    setTimeout(() => 
                        this.setState({ responseMessageMarkAsDone:"" }),
                    2000);
                    setTimeout(() => 
                        this.updatedMarkAsDoneData(selectedRecord),
                    2500);

               }else{
                this.setState({ responseMessageMarkAsDone:"Some Thing Went Wrong,Please Try Again Later..." });
               }
                

            }else{
                this.setState({ responseMessageMarkAsDone:"Some Thing Went Wrong,Please Try Again Later..." });

            }
        });
    }

    updatedMarkAsDoneData = (data) => {
        const updatedMarkAsDoneArr = this.state.notMatchedRecordsArr.map((item) => {
            if (item.leaderSearchAnswerId == data.leaderSearchAnswerId) {
                return { ...item, markAsDone:true }
            }
            return { ...item }
        });

        console.log("notMatchedRecordsArr",data)
        console.log("notMatchedRecordsArr",updatedMarkAsDoneArr)

        this.setState({notMatchedRecordsArr:updatedMarkAsDoneArr});
    }

   
    goToSearchPage = () => {
        this.props.history.push({
            pathname: `/leader_search_page`,
            params: {
                navbarDisplay:"hide",
              }
        }); 
    }

    goToDashboardPage = () => {
        this.props.history.push({
            pathname: `/leader_dashboard`,
            params: {
                navbarDisplay:"hide",
              }
        }); 
    }
   
    
    
    render() {
        
        return (
            <>
            <section className='leader_bg_color1'>
                <Container  className=''>
                    <Row>
                        <Col>
                            <img src={require('../../images/leadership-icon.png')} alt="leader-ship" className='w_80 center-auto mt-2'></img>
                            <h3 className='text-center color_white fw-bold font-style'>LEADERS SEARCH</h3>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col sm={4}>
                            <Button variant="outline-light" className='f-12  fw-bold  mb-2 ms-2' onClick={() => this.goToSearchPage()}> HOME <i class="fa-solid fa-house"></i></Button>
                            <Button variant="outline-light" className='f-12 float-right fw-bold  mb-2' onClick={() => this.goToDashboardPage()}> DASHBOARD <i class="fa-solid fa-gauge"></i></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={4}>
                            <h6 className='mt-3 fw-bold text-center f-14 border-bottom-double'>Not Matched Photos - <span style={{textAlign:"left",marginLeft:"0px"}} className='f-14 color_red'>{this.state.dateSelected}</span></h6>
                           
                            {
                                this.state.notMatchedRecordsArr.length == 0 ?
                                (
                                    this.state.notMatchedRecordsCheck == 1 ?
                                    (<Col className='f-14 text-center'>No Data Available</Col>)
                                    :
                                    (
                                        <Col sm={12}>
                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>
                                    )
                                )
                                :
                                (
                                    <>
                                        <Row className='justify-content-center'>
                                                {
                                                    this.state.notMatchedRecordsArr.filter(a => a.markAsDone == false).map((item,index) => {
                                                        return(
                                                            <Col sm={4}>
                                                                <Card className='mt-2 border_red_new'>
                                                                    <Card.Body>
                                                                        <span className='f-12 bg_round'>{item.indexValue}</span>
                                                                        <h6 className='f-12'>Wrong Name : <b>{item.memberName}</b></h6>
                                                                        <h6 className='f-12 mt-2'>Wrong Designation : <b>{item.designation}</b></h6>
                                                                       
                                                                        <div class="mt-2 ">
                                                                            <Row className=''>
                                                                                <Col className='w_40_LS tick_red_css'><img src={item.imageUrl} className='width_height_img img-thumbnail center-auto' alt="..." /></Col>  
                                                                                <Col className='w_60_LS'>
                                                                                    {
                                                                                        item.showCorrectName == true ?
                                                                                        (
                                                                                            <>
                                                                                                <h6 className='f-12 mt-3 color_green'><b>{item.correctMemberName}</b></h6>
                                                                                                <h6 className='f-12 mt-2 color_green'><b>{item.correctDesignation}</b></h6>
                                                                                            </>
                                                                                        )
                                                                                        :
                                                                                        ("")
                                                                                    }
                                                                                    
                                                                                </Col>
                                                                            </Row>
                                                                            
                                                                            <Row>
                                                                                <Col>
                                                                                    <Button variant="outline-danger" className='f-12 float-right fw-bold mt-2 ml-2' onClick={() => this.markAsDoneRecord(item)}> Mark As Done
                                                                                    {
                                                                                        this.state.deleteLodingSymbol == "show" && item.leaderSearchAnswerId == this.state.selectedMarkDoneTdpCadreId ?
                                                                                        (<Image src={loader} alt="loader" className='get_otp_loading_symbol_cls22'></Image>)
                                                                                        :
                                                                                        ("")
                                                                                    }
                                                                                    </Button>
                                                                                    <Button variant="outline-success" className='f-12 float-right fw-bold  mt-2' onClick={() => this.showCorrectPerson(item)}> Show Correct Name</Button>
                                                                                </Col>
                                                                            </Row>
                                                                            {
                                                                                item.leaderSearchAnswerId == this.state.selectedMarkDoneTdpCadreId ?
                                                                                (
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className='text-center mt-2 color_green f-14 fw-bold'>{this.state.responseMessageMarkAsDone}</h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )
                                                                                :
                                                                                ("")
                                                                            }
                                                                           
                                                                        </div>
                                                                        
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            
                                                
                                            </Row>
                                    </>
                                )
                           }
                            
                        </Col>
                    </Row>
                    
                </Container>
            </section>
            </>
        )
    }
  }
  
  export default withRouter(LeaderNotMatchedRecordsSection);
  