
import React ,{ Component } from 'react';
import {Linking,Platform,TouchableOpacity,Text} from "react-native";
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import "./VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import MF_logo1 from '../../images/MF_logo1.png';
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService';
import { getAPICallDataForOnlyBSA4 } from '../../services/MemberService';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';



class VolunteerDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            volunteerDts:props?.location?.params?.volunteerDts == undefined ? "" : props?.location?.params?.volunteerDts,
            accessName:props?.location?.params?.accessName == undefined ? "" : props?.location?.params?.accessName,
            roleName:props?.location?.params?.roleName == undefined ? "" : props?.location?.params?.roleName,
            VolunterFromDate:moment().format('YYYY-MM-DD'),
            VolunterToDate:moment().format('YYYY-MM-DD'),
            totalEnrollCountsArr:[],
            totalEnrollCountsCheck:0,
            dateWiseEnrollCountsArr:[],
            dateWiseEnrollCountsCheck:0,

            cubsBlock:true,
            datesBlock:false,

            selectedCubType:"booth",

            cubsStartDate:moment().format('YYYY-MM-DD'),
            cubsEndDate:moment().format('YYYY-MM-DD'),

            cubsBoothsListArr:[],
            cubsBoothsListCheck:0,

            cubsClusterListArr:[],
            cubsClusterListCheck:0,

            cubsUnitListArr:[],
            cubsUnitListCheck:0,

            cubsBoothChecked:true,
            cubsClusterChecked:false,
            cubsUnitChecked:false
        }
    }

    componentDidMount() {
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            this.getFGEnrollmentCountsInUserLocation();
            this.getFGEnrollmentCountsInUserLocationDateWise();
            if(this.state.roleName == "constituency"){
                this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
                //this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
                //this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
            }else if(this.state.roleName == "cluster"){
                this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
                //this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
            }else if(this.state.roleName == "unit"){
                this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
                //this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
            }else if(this.state.roleName == "booth"){
                this.setState({ datesBlock: true});
                this.setState({ cubsBlock: false});
                //this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
            }else if(this.state.roleName == "Mandal"){
                if(this.state.userAccessList.clusterList!= null && this.state.userAccessList.clusterList.length>0){
                    this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
                }
                else if(this.state.userAccessList.unitList!= null && this.state.userAccessList.unitList.length>0){
                    this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
                }else if(this.state.userAccessList.mandalList !=null && this.state.userAccessList.mandalList.length>0){
                    this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
                }else if(this.state.userAccessList.clusterList.length>0 && this.state.userAccessList.unitList.length>0 && this.state.userAccessList.mandalList.length>0){
                    this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
                    //this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
                    //this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
                }
            
            }
        }
        
    }
    getLocationWiseFGEnrollmentsInAConstituencyBooths = () => {
        this.setState({cubsBoothsListArr:[],cubsBoothsListCheck:0});
        const obj =  
                JSON.stringify({ 
                    "chatId" : this.state.chatID,
                    "locationScopeId" : 9,
                    "constituencyId" : this.state.userAccessList.constituencyList[0].id,
                    "fromDate" : this.state.cubsStartDate,
                    "toDate" : this.state.cubsEndDate
                });
        getAPICallData(obj,"FTRGRT/getLocationWiseFGEnrollmentsInAConstituency")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
                        let result = content.filter(o1 => this.state.userAccessList.boothList.some(o2 => o1.locationId === o2.id));
                        this.setState({cubsBoothsListArr:result,cubsBoothsListCheck:0});     
                    }else{
                        this.setState({cubsBoothsListArr:content,cubsBoothsListCheck:0});
                    }
                   
                }else{
                    this.setState({cubsBoothsListArr:[],cubsBoothsListCheck:1});
                }
            });
    }

    
    cubsBoothColumns() {
        let columns = [
            
            {
                dataField: "Booth",
                text: "Booth",
                sort: true,
            },
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                formatter: this.actionsFormatterForBOTDCubs.bind(this),
                headerStyle: {
                    width: "25%"
                }
            },
            {
                dataField: "TotalHouses",
                text: "TH",
                sort: true,
            },
            {
                dataField: "minimal",
                text: "MH",
                sort: true,
            },
            
            {
                dataField: "BotTotalHousesEnrolled",
                text: "BE",
                sort: true,
                
            },
            {
                dataField: "perc",
                text: "BE%",
                sort: true,
            },
            {
                dataField: "minimalPerc",
                text: "MH-%",
                sort: true,
            },
            
        
        ];
        return columns;
    }

    

    actionsFormatterForBOTDCubs = (cell, row, rowIndex, formatExtraData) => {

        
        return (
            <>
            {
                row.Name == "" || row.Name == undefined ?
                (
                    <>
                    <span style={{textAlign:"left",marginLeft:"0px"}}> - </span>
                    </>
                )
                :
                (
                    <>
                    <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Name)} 
                        <><br/><a href={`tel:+91${row.inchargeMobile}`} target='_blank'><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}><i class="fa-solid fa-phone"></i> {(row.inchargeMobile)}</span></a></>
                    </span>
                    </>
                )
            }
            </>
            
        );
        
    }
    dialCall = (number) => {
        let phoneNumber = '';
        if (Platform.OS === 'android') { phoneNumber = `tel:${number}`; }
        else {phoneNumber = `telprompt:${number}`; }
        Linking.openURL(phoneNumber);
     };
    cubsBoothData = () =>{
    let dataArr2 = [];
    var botEnrolledPerc = 0.0,minimalPerc=0.0;
        if(this.state.cubsBoothsListArr.length>0){
            
            for (let i = 0; i < this.state.cubsBoothsListArr.length; i++) {

                const location = (this.state.cubsBoothsListArr[i].location);
                const inchargeName = (this.state.cubsBoothsListArr[i].inchargeName);
                const inchargeMobile = (this.state.cubsBoothsListArr[i].inchargeMobile);
                const totalHouses = (this.state.cubsBoothsListArr[i].totalHouses);
                const botEnrolled = (this.state.cubsBoothsListArr[i].botEnrolled);
                const minimal = (this.state.cubsBoothsListArr[i].minimalHouses);
                botEnrolledPerc = parseFloat((this.state.cubsBoothsListArr[i].botEnrolled * 100)/this.state.cubsBoothsListArr[i].totalHouses).toFixed(2);
                minimalPerc = parseFloat((this.state.cubsBoothsListArr[i].botEnrolled * 100)/this.state.cubsBoothsListArr[i].minimalHouses).toFixed(2);
                
               
                
                const obj = {
                    Booth: location,
                    Name: inchargeName,
                    TotalHouses: totalHouses,
                    BotTotalHousesEnrolled: botEnrolled,
                    perc: parseFloat(botEnrolledPerc),
                    inchargeMobile:inchargeMobile,
                    minimal:minimal,
                    minimalPerc:minimalPerc
                
                    
                }
                dataArr2.push(obj);
            } 

            
            
            return dataArr2;
        }
    }

    getLocationWiseFGEnrollmentsInAConstituencyClusters = () => {
        this.setState({cubsClusterListArr:[],cubsClusterListCheck:0});
        const obj =  
                JSON.stringify({ 
                    "chatId" : this.state.chatID,
                    "locationScopeId" : 17,
                    "constituencyId" : this.state.userAccessList.constituencyList[0].id,
                    "fromDate" : this.state.cubsStartDate,
                    "toDate" : this.state.cubsEndDate
                });
        getAPICallData(obj,"FTRGRT/getLocationWiseFGEnrollmentsInAConstituency")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    if(this.state.userAccessList.clusterList != null && this.state.userAccessList.clusterList.length>0){
                        let result = content.filter(o1 => this.state.userAccessList.clusterList.some(o2 => o1.locationId === o2.id));
                        this.setState({cubsClusterListArr:result,cubsClusterListCheck:0});     
                    }else{
                        this.setState({cubsClusterListArr:content,cubsClusterListCheck:0});
                    }
                }else{
                    this.setState({cubsClusterListArr:[],cubsClusterListCheck:1});
                }
            });
    }



    cubsClusterColumns() {
        let columns = [
            
            {
                dataField: "cluster",
                text: "cluster",
                sort: true,
                formatter: this.actionsFormatterForNameSplit1.bind(this),
                headerStyle: {
                    width: "15%"
                }
            },
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                formatter: this.actionsFormatterForBOTDCubsCluster.bind(this),
                headerStyle: {
                    width: "25%"
                }
            },
            {
                dataField: "TotalHouses",
                text: "TH",
                sort: true,
            },
            {
                dataField: "minimal",
                text: "MH",
                sort: true,
            },
            
            {
                dataField: "BotTotalHousesEnrolled",
                text: "BE",
                sort: true,
                
            },
            {
                dataField: "perc",
                text: "BE%",
                sort: true,
            },
            {
                dataField: "minimalPerc",
                text: "MH-%",
                sort: true,
            },
        
        ];
        return columns;
    }

    actionsFormatterForNameSplit1 = (cell, row, rowIndex, formatExtraData) => {

        var splitUnitName = row.cluster.split("_");
    
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    splitUnitName.map((item,index) => {
                        return(
                            <span style={{textAlign:"left",marginLeft:"0px"}}>
                                {item }<br/>
                            </span>
                            
                        )
                        
                            
                        
                    })
                }
               
            </span>
        );
        
    }

    actionsFormatterForBOTDCubsCluster = (cell, row, rowIndex, formatExtraData) => {

    
        return (
            <>
            {
                row.Name == "" || row.Name == undefined ?
                (
                    <>
                    <span style={{textAlign:"left",marginLeft:"0px"}}> - </span>
                    </>
                )
                :
                (
                    <>
                    <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Name)} 
                        <><br/><a href={`tel:+91${row.inchargeMobile}`} target='_blank'><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}><i class="fa-solid fa-phone"></i> {(row.inchargeMobile)}</span></a></>
                    </span>
                    </>
                )
            }
            </>
            
        );
        
    }

    cubsClusterData = () =>{
    let dataArr2 = [];
    var botEnrolledClusterPerc = 0,minimalPerc=0;
        if(this.state.cubsClusterListArr.length>0){
            
            for (let i = 0; i < this.state.cubsClusterListArr.length; i++) {

                const location = (this.state.cubsClusterListArr[i].location);
                const inchargeName = (this.state.cubsClusterListArr[i].inchargeName);
                const inchargeMobile = (this.state.cubsClusterListArr[i].inchargeMobile);
                const totalHouses = (this.state.cubsClusterListArr[i].totalHouses);
                const botEnrolled = (this.state.cubsClusterListArr[i].botEnrolled);
                botEnrolledClusterPerc = parseFloat((this.state.cubsClusterListArr[i].botEnrolled * 100)/this.state.cubsClusterListArr[i].totalHouses).toFixed(2);
                const minimal = (this.state.cubsClusterListArr[i].minimalHouses);
                minimalPerc = parseFloat((this.state.cubsClusterListArr[i].botEnrolled * 100)/this.state.cubsClusterListArr[i].minimalHouses).toFixed(2);
                
                const obj = {
                    cluster: location,
                    Name: inchargeName,
                    TotalHouses: totalHouses,
                    BotTotalHousesEnrolled: botEnrolled,
                    perc: parseFloat(botEnrolledClusterPerc),
                    inchargeMobile:inchargeMobile,
                    minimalPerc:minimalPerc,
                    minimal:minimal
                
                    
                }
                dataArr2.push(obj);
            } 

            
            
            return dataArr2;
        }
    } 


    getLocationWiseFGEnrollmentsInAConstituencyUnits = () => {
        this.setState({cubsUnitListArr:[],cubsUnitListCheck:0});
        const obj =  
                JSON.stringify({ 
                    "chatId" : this.state.chatID,
                    "locationScopeId" : 18,
                    "constituencyId" : this.state.userAccessList.constituencyList[0].id,
                    "fromDate" : this.state.cubsStartDate,
                    "toDate" : this.state.cubsEndDate
                });
        getAPICallData(obj,"FTRGRT/getLocationWiseFGEnrollmentsInAConstituency")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    if(this.state.userAccessList.unitList != null && this.state.userAccessList.unitList.length>0){
                        let result = content.filter(o1 => this.state.userAccessList.unitList.some(o2 => o1.locationId === o2.id));
                        this.setState({cubsUnitListArr:result,cubsUnitListCheck:0});     
                    }else{
                        this.setState({cubsUnitListArr:content,cubsUnitListCheck:0});
                    }
                }else{
                    this.setState({cubsUnitListArr:[],cubsUnitListCheck:1});
                }
            });
    }


    cubsUnitColumns() {
        let columns = [
            
            {
                dataField: "unit",
                text: "unit",
                sort: true,
                formatter: this.actionsFormatterForNameSplit.bind(this),
                headerStyle: {
                    width: "20%"
                }
            },
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                formatter: this.actionsFormatterForBOTDCubsUnit.bind(this),
                headerStyle: {
                    width: "25%"
                }
            },
            {
                dataField: "TotalHouses",
                text: "TH",
                sort: true,
            },
            {
                dataField: "minimal",
                text: "MH",
                sort: true,
            },
            
            {
                dataField: "BotTotalHousesEnrolled",
                text: "BE",
                sort: true,
                
            },
            {
                dataField: "perc",
                text: "BE%",
                sort: true,
            },
            {
                dataField: "minimalPerc",
                text: "MH-%",
                sort: true,
            },
        
        ];
        return columns;
    }

    actionsFormatterForNameSplit = (cell, row, rowIndex, formatExtraData) => {

        var splitUnitName = row.unit.split("_");
    
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>
                {
                    splitUnitName.map((item,index) => {
                        return(
                            <span>
                                {item }<br/>
                            </span>
                            
                        )
                        
                            
                        
                    })
                }
               
            </span>
        );
        
    }

    actionsFormatterForBOTDCubsUnit = (cell, row, rowIndex, formatExtraData) => {

    
        return (
            <>
            {
                row.Name == "" || row.Name == undefined ?
                (
                    <>
                    <span style={{textAlign:"left",marginLeft:"0px"}}> - </span>
                    </>
                )
                :
                (
                    <>
                    <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Name)} 
                        <><br/><a href={`tel:+91${row.inchargeMobile}`} target='_blank'><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}><i class="fa-solid fa-phone"></i> {(row.inchargeMobile)}</span></a></>
                    </span>
                    </>
                )
            }
            </>
            
        );
        
    }

    cubsUnitData = () =>{
    let dataArr2 = [];
    var botEnrolledUnitPerc = 0,minimalPerc= 0;
        if(this.state.cubsUnitListArr.length>0){
            
            for (let i = 0; i < this.state.cubsUnitListArr.length; i++) {

                const location = (this.state.cubsUnitListArr[i].location);
                const inchargeName = (this.state.cubsUnitListArr[i].inchargeName);
                const inchargeMobile = (this.state.cubsUnitListArr[i].inchargeMobile);
                const totalHouses = (this.state.cubsUnitListArr[i].totalHouses);
                const botEnrolled = (this.state.cubsUnitListArr[i].botEnrolled);
                botEnrolledUnitPerc = parseFloat((this.state.cubsUnitListArr[i].botEnrolled * 100)/this.state.cubsUnitListArr[i].totalHouses).toFixed(2);
                const minimal = (this.state.cubsUnitListArr[i].minimalHouses);
                minimalPerc = parseFloat((this.state.cubsUnitListArr[i].botEnrolled * 100)/this.state.cubsUnitListArr[i].minimalHouses).toFixed(2);
                
                const obj = {
                    unit: location,
                    Name: inchargeName,
                    TotalHouses: totalHouses,
                    BotTotalHousesEnrolled: botEnrolled,
                    perc: parseFloat(botEnrolledUnitPerc),
                    inchargeMobile:inchargeMobile,
                    minimalPerc:minimalPerc,
                    minimal:minimal
                
                    
                }
                dataArr2.push(obj);
            } 

            
            
            return dataArr2;
        }
    } 


    //Date Wise //
    getFGEnrollmentCountsInUserLocation = () => {
        this.setState({totalEnrollCountsArr:[],totalEnrollCountsCheck:0});
        const obj =  
                JSON.stringify({ 
                    "chatId" : this.state.chatID,
                    "fromDate" :this.state.VolunterFromDate,
                    "toDate" : this.state.VolunterToDate,
                    "type" : "TOTAL"  
                });
            getAPICallData(obj,"FTRGRT/getFGEnrollmentCountsInUserLocation")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    
                    this.setState({totalEnrollCountsArr:content,totalEnrollCountsCheck:0});
                }else{
                    this.setState({totalEnrollCountsArr:[],totalEnrollCountsCheck:1});
                }
            });
    }

    getFGEnrollmentCountsInUserLocationDateWise = () => {
        this.setState({dateWiseEnrollCountsArr:[],dateWiseEnrollCountsCheck:0});
        const obj =  
                JSON.stringify({ 
                    "chatId" : this.state.chatID,
                    "fromDate" :this.state.VolunterFromDate,
                    "toDate" : this.state.VolunterToDate,
                    "type" : "DATE_WISE"  
                });
            getAPICallData(obj,"FTRGRT/getFGEnrollmentCountsInUserLocation")
            .then((res) => res.json())
            .then((content) => {
                if(content !=null && content.length>0){
                    
                    this.setState({dateWiseEnrollCountsArr:content,dateWiseEnrollCountsCheck:0});
                }else{
                    this.setState({dateWiseEnrollCountsArr:[],dateWiseEnrollCountsCheck:1});
                }
            });
    }

    getDateWiseEnrollCounts = () => {
        this.getFGEnrollmentCountsInUserLocation();
        this.getFGEnrollmentCountsInUserLocationDateWise();
    }

    totalEnrollCountsColumns() {
        let columns = [
            
            {
                dataField: "Total",
                text: "Total Enrolls",
                sort: true,
            },
            {
                dataField: "BOT",
                text: "BOT",
                sort: true,
                
                
                
            },
            {
                dataField: "WEB",
                text: "WEB",
                sort: true,
                
            },
           
            {
                dataField: "OWN",
                text: "OWN",
                sort: true,
            }
         
        ];
        return columns;
    }

    
    totalEnrollCountsData = () =>{
    let dataArr = [];
        if(this.state.totalEnrollCountsArr.length>0){
            
            for (let i = 0; i < this.state.totalEnrollCountsArr.length; i++) {

                const totalEnrolled = (this.state.totalEnrollCountsArr[i].totalEnrolled);
                const botEnrolled = (this.state.totalEnrollCountsArr[i].botEnrolled);
                const webEnrolled = (this.state.totalEnrollCountsArr[i].webEnrolled);
                const ownEnrolled = (this.state.totalEnrollCountsArr[i].ownEnrolled);
                const thirdPartyEnrolled = (this.state.totalEnrollCountsArr[i].thirdPartyEnrolled);
                
                
                const obj = {
                    Total: <span className='f-14 fw-bold'>{totalEnrolled}</span>,
                    BOT: <span className='f-14 fw-bold'>{botEnrolled}</span>,
                    WEB:  <span className='f-14 fw-bold'>{webEnrolled+thirdPartyEnrolled}</span>,
                    OWN: <span className='f-14 fw-bold'>{ownEnrolled}</span>,
                   
                    
                }
                dataArr.push(obj);
            } 

            
            
            return dataArr;
        }
}



dateWiseEnrollCountsColumns() {
    let columns = [
        
        {
            dataField: "Date",
            text: "Date",
            sort: true,
            headerStyle: {
                width: "32%"
            }
        },
        {
            dataField: "Total",
            text: "Total Enrolls",
            sort: true,
            headerStyle: {
                width: "20%"
            }
        },
        {
            dataField: "BOT",
            text: "BOT",
            sort: true,
            
            
            
        },
        {
            dataField: "WEB",
            text: "WEB",
            sort: true,
            
        },
       
        {
            dataField: "OWN",
            text: "OWN",
            sort: true,
        }
     
    ];
    return columns;
}


dateWiseEnrollCountsData = () =>{
let dataArr1 = [];
    if(this.state.dateWiseEnrollCountsArr.length>0){
        
        for (let i = 0; i < this.state.dateWiseEnrollCountsArr.length; i++) {

            const Date = (this.state.dateWiseEnrollCountsArr[i].date);
            const totalEnrolled = (this.state.dateWiseEnrollCountsArr[i].totalEnrolled);
            const botEnrolled = (this.state.dateWiseEnrollCountsArr[i].botEnrolled);
            const webEnrolled = (this.state.dateWiseEnrollCountsArr[i].webEnrolled);
            const ownEnrolled = (this.state.dateWiseEnrollCountsArr[i].ownEnrolled);
            const thirdPartyEnrolled = (this.state.dateWiseEnrollCountsArr[i].thirdPartyEnrolled);
            
            const obj = {
                Date: Date,
                Total: totalEnrolled,
                BOT: botEnrolled,
                WEB: webEnrolled+thirdPartyEnrolled,
                OWN: ownEnrolled,
               
                
            }
            dataArr1.push(obj);
        } 

        
        
        return dataArr1;
    }
}
//Date Wise End//

showDashboardBlockType = (blockType) => {
    if(blockType == "cubs"){
      this.setState({ cubsBoothChecked: true,cubsClusterChecked:false,cubsUnitChecked:false,selectedCubType:"booth"});
      this.setState({ cubsBlock: true});
      this.setState({ datesBlock: false});
    }else{
      this.setState({ datesBlock: true});
      this.setState({ cubsBlock: false});
      
    }

  }

  cubsWiseSelectedBlock = (e,type) => {
    
    if(type == "booth"){
        this.setState({ cubsBoothChecked: true,cubsClusterChecked:false,cubsUnitChecked:false});
        this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
    }else if(type == "cluster"){
        this.setState({ cubsBoothChecked: false,cubsClusterChecked:true,cubsUnitChecked:false});
        this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
    }else if(type == "unit"){
        this.setState({ cubsBoothChecked: false,cubsClusterChecked:false,cubsUnitChecked:true});
        this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
    }
    this.setState({ selectedCubType: type});
    
  }

  handleEventForCubs = (event, picker) => {
    console.log("start: ",  moment(picker.startDate._d).format('YYYY-MM-DD'));
    console.log("end: ",  moment(picker.endDate._d).format('YYYY-MM-DD'));
    
    this.setState({ cubsStartDate: moment(picker.startDate._d).format('YYYY-MM-DD'),cubsEndDate:moment(picker.endDate._d).format('YYYY-MM-DD')});
    if(this.state.roleName == "constituency"){
        this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
       // this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
        //this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
    }else if(this.state.roleName == "cluster"){
        this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
        //this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
    }else if(this.state.roleName == "unit"){
        this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
       // this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
    }else if(this.state.roleName == "booth"){
        this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
    }else if(this.state.roleName == "Mandal"){
        if(this.state.userAccessList.clusterList!= null && this.state.userAccessList.clusterList.length>0){
          this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
        }
        else if(this.state.userAccessList.unitList!= null && this.state.userAccessList.unitList.length>0){
          this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
        }else if(this.state.userAccessList.mandalList !=null && this.state.userAccessList.mandalList.length>0){
          this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
        }else if(this.state.userAccessList.clusterList.length>0 && this.state.userAccessList.unitList.length>0 && this.state.userAccessList.mandalList.length>0){
          this.getLocationWiseFGEnrollmentsInAConstituencyBooths();
          //this.getLocationWiseFGEnrollmentsInAConstituencyClusters();
          //this.getLocationWiseFGEnrollmentsInAConstituencyUnits();
        }

        
  }
  };

  handleEventForDateStartDate = (event, picker) => {
    this.setState({ VolunterFromDate: moment(picker.startDate._d).format('YYYY-MM-DD')});
  }

  handleEventForDateEndDate = (event, picker) => {
    this.setState({ VolunterToDate: moment(picker.endDate._d).format('YYYY-MM-DD')});
  }

  range = {
    "Today": [moment(), moment()],
    "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
  };


  

      
    render() {
        console.log("roleName",this.state.roleName)
        return (
            <section className="mb-4">
               <div className="pos_relative">
                    <div class="text-white">
                    <img  class="bg_css_volunter_logo" />
                    <div class="card-img-overlay">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="flex-header mt-1">
                                <img className="center-auto w-80P" src={MF_logo1} />
                                
                                </div>
                            </div>
                        </div>
                        <Link to={{pathname: "/volunteer_page",
                                    params: {
                                        navbarDisplay:"hide",
                                        volunteerDts:this.state.volunteerDts,
                                        userAccessList:this.state.userAccessList,
                                    }}}> 
                            <Button variant="outline-light ms-3 mt-3 pad5_14 back_button" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Link>
                    </div>
                    </div>
                </div>
                
                <Container fluid>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center mt-4'> భవిష్యత్తుకు గ్యారెంటీ - Dashboard </h6>
                            <h6 className="form-label f-12 fw-bold mt-2">(Enrollments in your area : {this.state.accessName})</h6>
                        </Col>
                    </Row> 
                    <Row>
                        <Col sm={12} className='mt-4'>
                            <div className='flex_programs_block pos_top_cls'>
                                {
                                    this.state.roleName == "constituency" || this.state.roleName == "cluster" || this.state.roleName == "unit" ?
                                    (<div className={this.state.cubsBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.showDashboardBlockType("cubs")}><h6 className='fw-bold  text-center'><i class="fa-solid fa-cube"></i> <span className='ms-2'>CUBS WISE</span></h6></div>)
                                    :
                                    ("")
                                }
                            
                            <div className={this.state.datesBlock == true ? "border_bottom_issues cusor_pointer" : "cusor_pointer"} onClick={() => this.showDashboardBlockType("date")}><h6 className='fw-bold text-center'><i class="fa-solid fa-calendar-days"></i><span className='ms-2'>DATE WISE</span></h6></div>
                            </div>
                        </Col>
                    </Row>

                    {
                        this.state.cubsBlock == true ?
                        (
                            <>
                            <Row>
                                <Col sm={12} >
                                    <Form>
                                        <div className='text-center'>
                                            {
                                                this.state.roleName == "constituency" ?
                                                (
                                                    <>
                                                    <Form.Check
                                                        inline
                                                        label={`Booth`}
                                                        name="boothClusterUnit"
                                                        type="radio"
                                                        id={`boothWiseId`}
                                                        onChange={(e)=>this.cubsWiseSelectedBlock(e,"booth")}
                                                        className="f-15 fw-bold color_black"
                                                        checked={this.state.cubsBoothChecked}
                                                        value="booth"
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label={`Cluster`}
                                                        name="boothClusterUnit"
                                                        type="radio"
                                                        id={`clusterWiseId`}
                                                        onChange={(e)=>this.cubsWiseSelectedBlock(e,"cluster")}
                                                        className="f-15 fw-bold color_black"
                                                        checked={this.state.cubsClusterChecked}
                                                        value="cluster"
                                                    />
                                                    
                                                    <Form.Check
                                                        inline
                                                        label={`Unit`}
                                                        name="boothClusterUnit"
                                                        type="radio"
                                                        id={`unitWiseId`}
                                                        onChange={(e)=>this.cubsWiseSelectedBlock(e,"unit")}
                                                        className="f-15 fw-bold color_black"
                                                        checked={this.state.cubsUnitChecked}
                                                        value="unit"
                                                    />
                                                    </>
                                                )
                                                :
                                                (
                                                    this.state.roleName == "cluster" ?
                                                    (
                                                        <>
                                                            <Form.Check
                                                                inline
                                                                label={`Booth`}
                                                                name="boothClusterUnit"
                                                                type="radio"
                                                                id={`boothWiseId`}
                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"booth")}
                                                                className="f-15 fw-bold color_black"
                                                                checked={this.state.cubsBoothChecked}
                                                                value="booth"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label={`Cluster`}
                                                                name="boothClusterUnit"
                                                                type="radio"
                                                                id={`clusterWiseId`}
                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"cluster")}
                                                                className="f-15 fw-bold color_black"
                                                                value="cluster"
                                                                checked={this.state.cubsClusterChecked}
                                                            />
                                                            </>
                                                    )
                                                    :
                                                    (
                                                        this.state.roleName == "unit" ? 
                                                        (
                                                            <>
                                                            <Form.Check
                                                                inline
                                                                label={`Booth`}
                                                                name="boothClusterUnit"
                                                                type="radio"
                                                                id={`boothWiseId`}
                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"booth")}
                                                                className="f-15 fw-bold color_black"
                                                                checked={this.state.cubsBoothChecked}
                                                                value="booth"
                                                            />
                                                            
                                                            <Form.Check
                                                                inline
                                                                label={`Unit`}
                                                                name="boothClusterUnit"
                                                                type="radio"
                                                                id={`unitWiseId`}
                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"unit")}
                                                                className="f-15 fw-bold color_black"
                                                                value="unit"
                                                                checked={this.state.cubsUnitChecked}
                                                            />
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            this.state.roleName == "booth" ? 
                                                            (
                                                                <>
                                                                    <Form.Check
                                                                        inline
                                                                        label={`Booth`}
                                                                        name="boothClusterUnit"
                                                                        type="radio"
                                                                        id={`boothWiseId`}
                                                                        onChange={(e)=>this.cubsWiseSelectedBlock(e,"booth")}
                                                                        className="f-15 fw-bold color_black"
                                                                        checked={this.state.cubsBoothChecked}
                                                                        value="booth"
                                                                    />
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                this.state.roleName == "Mandal" ? 
                                                                (
                                                                    this.state.userAccessList.clusterList.length>0 && this.state.userAccessList.unitList.length>0 && this.state.userAccessList.mandalList.length>0 ?
                                                                    (
                                                                        <>
                                                                            <Form.Check
                                                                                inline
                                                                                label={`Booth`}
                                                                                name="boothClusterUnit"
                                                                                type="radio"
                                                                                id={`boothWiseId`}
                                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"booth")}
                                                                                className="f-15 fw-bold color_black"
                                                                                checked={this.state.cubsBoothChecked}
                                                                                value="booth"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label={`Cluster`}
                                                                                name="boothClusterUnit"
                                                                                type="radio"
                                                                                id={`clusterWiseId`}
                                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"cluster")}
                                                                                className="f-15 fw-bold color_black"
                                                                                checked={this.state.cubsClusterChecked}
                                                                                value="cluster"
                                                                            />
                                                                            
                                                                            <Form.Check
                                                                                inline
                                                                                label={`Unit`}
                                                                                name="boothClusterUnit"
                                                                                type="radio"
                                                                                id={`unitWiseId`}
                                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"unit")}
                                                                                className="f-15 fw-bold color_black"
                                                                                checked={this.state.cubsUnitChecked}
                                                                                value="unit"
                                                                            />
                                                                            </>
                                                                    )
                                                                    :
                                                                    (
                                                                        this.state.userAccessList.clusterList.length>0 ?
                                                                        (
                                                                            <>
                                                                            <Form.Check
                                                                                inline
                                                                                label={`Booth`}
                                                                                name="boothClusterUnit"
                                                                                type="radio"
                                                                                id={`boothWiseId`}
                                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"booth")}
                                                                                className="f-15 fw-bold color_black"
                                                                                checked={this.state.cubsBoothChecked}
                                                                                value="booth"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label={`Cluster`}
                                                                                name="boothClusterUnit"
                                                                                type="radio"
                                                                                id={`clusterWiseId`}
                                                                                onChange={(e)=>this.cubsWiseSelectedBlock(e,"cluster")}
                                                                                className="f-15 fw-bold color_black"
                                                                                checked={this.state.cubsClusterChecked}
                                                                                value="cluster"
                                                                            />
                                                                            
                                                                            
                                                                            </>
                                                                        )
                                                                        :
                                                                        (
                                                                            this.state.userAccessList.unitList.length>0 ?
                                                                            (
                                                                                <>
                                                                                <Form.Check
                                                                                    inline
                                                                                    label={`Booth`}
                                                                                    name="boothClusterUnit"
                                                                                    type="radio"
                                                                                    id={`boothWiseId`}
                                                                                    onChange={(e)=>this.cubsWiseSelectedBlock(e,"booth")}
                                                                                    className="f-15 fw-bold color_black"
                                                                                    checked={this.state.cubsBoothChecked}
                                                                                    value="booth"
                                                                                />
                                                                                
                                                                                <Form.Check
                                                                                    inline
                                                                                    label={`Unit`}
                                                                                    name="boothClusterUnit"
                                                                                    type="radio"
                                                                                    id={`unitWiseId`}
                                                                                    onChange={(e)=>this.cubsWiseSelectedBlock(e,"unit")}
                                                                                    className="f-15 fw-bold color_black"
                                                                                    value="unit"
                                                                                    checked={this.state.cubsUnitChecked}
                                                                                />
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                this.state.userAccessList.mandalList.length>0 ?
                                                                                (
                                                                                    <>
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label={`Booth`}
                                                                                            name="boothClusterUnit"
                                                                                            type="radio"
                                                                                            id={`boothWiseId`}
                                                                                            onChange={(e)=>this.cubsWiseSelectedBlock(e,"booth")}
                                                                                            className="f-15 fw-bold color_black"
                                                                                            checked={this.state.cubsBoothChecked}
                                                                                            value="booth"
                                                                                        />
                                                                                        </>
                                                                                )
                                                                                :
                                                                                ("")
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                                :
                                                                ("")
                                                            )
                                                        )
                                                    )
                                                )

                                            }
                                        
                                        </div>
                                    </Form>
                                        
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                <h6 className="form-label f-12 fw-bold mt-3">Date Range :</h6>
                                <DateRangePicker
                                        alwaysShowCalendars={true}
                                        ranges = {this.range}
                                        onEvent={this.handleEventForCubs}
                                        className="w-100"
                                    >   
                                    <div class="input-group">
                                        <div class="input-group-prepend bgColorYash">
                                            <i class="fa fa-calendar pad_5_15"></i>
                                        </div>
                                        <input type="text" className="form-control" value={moment(this.state.cubsStartDate).format("YYYY-MM-DD") +" to "+ moment(this.state.cubsEndDate).format("YYYY-MM-DD")} />
                                    </div>
                                    </DateRangePicker>
                                </Col>
                            </Row>

                            {
                                this.state.selectedCubType == "booth" ?
                                (
                                    
                                        this.state.cubsBoothsListArr.length == 0 ?
                                        (
                                            this.state.cubsBoothsListCheck == 1 ?
                                            (<Col className='f-14 text-center'>No Data Available</Col>)
                                            :
                                            (<Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>)
                                        )
                                        :
                                        (
                                            this.state.cubsBoothsListArr.length>0 ?
                                            (
                                                <>
                                                 <Row>
                                                            <Col >
                                                            <h6 className='f-10 mt-3 fw-bold color_red'>
                                                                Note : <span className='ml_5 mr_2'>TH - TOTAL HOUSES,</span>
                                                                        <span className='ml_5 mr_2'>MH - MINIMUL HOUSES</span><br/>
                                                                        <span className='ml_5 mr_2'>MH% - In Minimul Houses Completed %</span><br/>
                                                                        <span className='ml_5 mr_2'>BE - Total Houses Enrolled</span><br/>
                                                                        <span className='ml_5 mr_2'>BE% - In Total Houses Completed %</span>
                                                            </h6>
                                                        
                                                            </Col>
                                                        </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                    <div className='mt-2 p-0 bg_light_blue'>
                                                        
                                                        <CardLessDataTableWithSearch 
                                                            parentData={this.cubsBoothData()}
                                                            particlecolumns={this.cubsBoothColumns()}
                                                            defaultSorted={[{
                                                                dataField: "BotTotalHousesEnrolled",
                                                                order: "desc"
                                                            }]}
                                                            tableHeading="Booth Wise Details"
                                                            />
                                                            
                                                            
                                                    </div>
                                                    
                                                    </Col>
                                                </Row>
                                                </>
                                            )
                                            :
                                            ("")
                                        )
                                        
                                )
                                :
                                (
                                    this.state.selectedCubType == "cluster" ?
                                    (
                                        this.state.cubsClusterListArr.length == 0 ?
                                        (
                                            this.state.cubsClusterListCheck == 1 ?
                                            (<Col className='f-14 text-center'>No Data Available</Col>)
                                            :
                                            (<Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>)
                                        )
                                        :
                                        (
                                            this.state.cubsClusterListArr.length>0 ?
                                            (
                                                <>
                                                <Row>
                                                            <Col >
                                                            <h6 className='f-10 mt-3 fw-bold color_red'>
                                                                Note : <span className='ml_5 mr_2'>TH - TOTAL HOUSES,</span>
                                                                        <span className='ml_5 mr_2'>MH - MINIMUL HOUSES</span><br/>
                                                                        <span className='ml_5 mr_2'>MH% - In Minimul Houses Completed %</span><br/>
                                                                        <span className='ml_5 mr_2'>BE - Total Houses Enrolled</span><br/>
                                                                        <span className='ml_5 mr_2'>BE% - In Total Houses Completed %</span>
                                                            </h6>
                                                        
                                                            </Col>
                                                        </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                    <div className='mt-2 p-0 bg_light_blue'>
                                                        
                                                        <CardLessDataTableWithSearch 
                                                            parentData={this.cubsClusterData()}
                                                            particlecolumns={this.cubsClusterColumns()}
                                                            defaultSorted={[{
                                                                dataField: "BotTotalHousesEnrolled",
                                                                order: "desc"
                                                            }]}
                                                            tableHeading="Cluster Wise Details"
                                                            />
                                                            
                                                            
                                                    </div>
                                                    
                                                    </Col>
                                                </Row>
                                                </>
                                            )
                                            :
                                            ("")
                                        )
                                    )
                                    :
                                    (
                                        this.state.selectedCubType == "unit" ?
                                        (
                                            this.state.cubsUnitListArr.length == 0 ?
                                                (
                                                    this.state.cubsUnitListCheck == 1 ?
                                                    (<Col className='f-14 text-center'>No Data Available</Col>)
                                                    :
                                                    (<Col sm={12}>
                                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                    </Col>)
                                                )
                                                :
                                                (
                                                    this.state.cubsUnitListArr.length>0 ?
                                                    (
                                                        <>
                                                        <Row>
                                                            <Col >
                                                            <h6 className='f-10 mt-3 fw-bold color_red'>
                                                                Note : <span className='ml_5 mr_2'>TH - TOTAL HOUSES,</span>
                                                                        <span className='ml_5 mr_2'>MH - MINIMUL HOUSES</span><br/>
                                                                        <span className='ml_5 mr_2'>MH% - In Minimul Houses Completed %</span><br/>
                                                                        <span className='ml_5 mr_2'>BE - Total Houses Enrolled</span><br/>
                                                                        <span className='ml_5 mr_2'>BE% - In Total Houses Completed %</span>
                                                            </h6>
                                                        
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                            <div className='mt-2 p-0 bg_light_blue'>
                                                                
                                                                <CardLessDataTableWithSearch 
                                                                    parentData={this.cubsUnitData()}
                                                                    particlecolumns={this.cubsUnitColumns()}
                                                                    defaultSorted={[{
                                                                        dataField: "BotTotalHousesEnrolled",
                                                                        order: "desc"
                                                                    }]}
                                                                    tableHeading="Unit Wise Details"
                                                                    />
                                                                    
                                                                    
                                                            </div>
                                                            
                                                            </Col>
                                                        </Row>
                                                        </>
                                                    )
                                                    :
                                                    ("")
                                                )
                                        )
                                        :
                                        ("")
                                    )
                                )
                            }

                            </>
                        )
                        :
                        (
                            this.state.datesBlock == true ?
                            (
                                <>
                                <Row>
                                    <Col>
                                    <h6 className="form-label f-12 fw-bold mt-3">From Date : <span className='color_red f-9'>{this.state.validateFromDate}</span></h6>
                                    <DateRangePicker
                                            singleDatePicker={true}
                                            alwaysShowCalendars={true}
                                            onEvent={this.handleEventForDateStartDate}
                                            className="w-100"
                                        >   
                                        <div class="input-group">
                                            <div class="input-group-prepend bgColorYash">
                                                <i class="fa fa-calendar pad_5_15"></i>
                                            </div>
                                            <input type="text" className="form-control" value={moment(this.state.VolunterFromDate).format("YYYY-MM-DD")}/>
                                        </div>
                                        </DateRangePicker>
                                    </Col>
                                    <Col>
                                    <h6 className="form-label f-12 fw-bold mt-3">To Date : <span className='color_red f-9'>{this.state.validateToDate}</span></h6>
                                    <DateRangePicker
                                            singleDatePicker={true}
                                            alwaysShowCalendars={true}
                                            onEvent={this.handleEventForDateEndDate}
                                            className="w-100"
                                        >   
                                        <div class="input-group">
                                            <div class="input-group-prepend bgColorYash">
                                                <i class="fa fa-calendar pad_5_15"></i>
                                            </div>
                                            <input type="text" className="form-control" value={moment(this.state.VolunterToDate).format("YYYY-MM-DD")}/>
                                        </div>
                                        </DateRangePicker>
                                    </Col>
                                </Row>      
                                <Row>
                                    <Col>
                                        <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getDateWiseEnrollCounts()}>GET DETAILS</Button> 
                                    </Col>
                                </Row> 

                                    {
                                        this.state.totalEnrollCountsArr.length == 0 ?
                                        (
                                            this.state.totalEnrollCountsCheck == 1 ?
                                            (<Col className='f-14 text-center'>No Data Available</Col>)
                                            :
                                            (<Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>)
                                        )
                                        :
                                        (
                                            this.state.totalEnrollCountsArr.length>0 ?
                                            (
                                                <>
                                                <Row>
                                                    <Col sm={12}>
                                                    <div className='mt-1 p-0 pagination_none'>
                                                        
                                                        <CardLessDataTableWithOutSearch 
                                                            parentData={this.totalEnrollCountsData()}
                                                            particlecolumns={this.totalEnrollCountsColumns()}
                                                            defaultSorted={[{
                                                                dataField: "Total",
                                                                order: "desc"
                                                            }]}
                                                            tableHeading="Enrollments Summary"
                                                            />
                                                            
                                                            
                                                    </div>
                                                    
                                                    </Col>
                                                </Row>
                                                </>
                                            )
                                            :
                                            ("")
                                        )
                                        }  

                                        {
                                            this.state.dateWiseEnrollCountsArr.length>0 ?
                                            (
                                                <>
                                                <Row>
                                                    <Col sm={12}>
                                                    
                                                    <div className='mt-1 pagination_none'>
                                                        
                                                        <CardLessDataTableWithOutSearch 
                                                            parentData={this.dateWiseEnrollCountsData()}
                                                            particlecolumns={this.dateWiseEnrollCountsColumns()}
                                                            defaultSorted={[{
                                                                dataField: "Total",
                                                                order: "desc"
                                                            }]}
                                                            tableHeading={<span class='f-12' style={{textAlign:"left",marginLeft:"0px"}}>Date Wise Enrollments : {this.state.VolunterFromDate} To {this.state.VolunterToDate}</span>}
                                                            />
                                                            
                                                            
                                                    </div>
                                                    
                                                    </Col>
                                                </Row>
                                                </>
                                            )
                                            :
                                            ("")
                                        } 
                            </>
                            )
                            :
                            ("")
                        )
                    }                 
                         
                </Container>
                
            
            </section>
        )
    }
  }
  
  export default withRouter(VolunteerDashboard);
  