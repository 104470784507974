import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,Card, Container } from "react-bootstrap";
import loader from '../../images/loader.gif';


class SuccessMessagePublicOutReach extends Component {
    constructor(props) {
        super(props)
        this.state = {
          
        }
      
    }

    
    render() {
        
        return (
            <Modal {...this.props} 
            size="sm"  centered aria-labelledby="example-modal-sizes-title-lg" className="z_index_modal">
                
                <Modal.Body className="">
                        
                        <Container>
                            <Row>
                                <Col>
                                    {
                                        this.props.stateData.successMessageModal == "Invalid User" || this.props.stateData.successMessageModal == "SomeThing Went Wrong,Please Try Agian..." ?
                                        (
                                            <><i class="fa-solid fa-circle-xmark color_red center-auto text-center" style={{fontSize:"7rem"}}></i>
                                            <h4 className='color_red text-center mt-2 fw-bold'>{this.props.stateData.successMessagePB}</h4></>
                                        )
                                        :
                                        (
                                            <><i class="fa-solid fa-check  color_green center-auto text-center" style={{fontSize:"7rem"}}></i>
                                            <h4 className='color_green text-center mt-2 fw-bold'>Saved SuccessFully...</h4></>
                                        ) 
                                    }
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="dark" className='f-12 float-end mt-3 btn-sm' onClick={() => this.props.closeSuccessModalForPublicOutReach()}>Close</Button> 
                                </Col>
                            </Row>
                        </Container>
                      
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default SuccessMessagePublicOutReach;
  

