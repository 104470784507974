
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button  } from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import TDPLOGO from '../../images/tdp_logo.png';
import HEADER from '../../images/header.png';
import Pdf from './pdf'

class PDFViewerSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }


  
    render() {
        return (
            <section>
                <div class="text-white">
                    <img src={HEADER} class="card-img center-auto" />
                    <div class="card-img-overlay">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="flex-header mt-1">
                                    {
                                        this.props?.location?.params?.navigate == "help_form" ?
                                        (
                                            <Link to={{pathname: "/voters_list_revision",
                                                        params:{
                                                            navBarBottom:"hide",
                                                            userAccessList:this.props?.location?.params?.userAccessList
                                                        }}}> <i class="fa-solid fa-arrow-left color_white f-24 ms-4"></i></Link>
                                        )
                                        :
                                        (
                                            this.props?.location?.params?.navigate == "performance_report" ?
                                            (
                                                <Link to={{pathname: "/performance_report",
                                            }}> <i class="fa-solid fa-arrow-left color_white f-24 ms-4"></i></Link>
                                            )
                                            :
                                            (<Link to={{pathname: "/dashboard"}}> <i class="fa-solid fa-arrow-left color_white f-24 ms-4"></i></Link>)

                                            
                                        )
                                    }

                                       
                                    
                                    <h5 className="logo_font_size">Telugu Desam Party</h5>
                                    <img className="center-auto" src={TDPLOGO} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <React.Fragment>
                    <Pdf file={this.props?.location?.params?.pdf_url} className='w-100 border_pdf'/>
                </React.Fragment>

            </section>
        )
    }
  }
  
  export default withRouter(PDFViewerSection);
  