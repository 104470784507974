import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,InputGroup,Card,FloatingLabel } from "react-bootstrap";
import loader from '../../images/loader.gif';
import Webcam from "react-webcam";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class AddPartySeniorOrInfluencers extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          videoConstraints:{facingMode: "environment"},

         
        }
      
    }

  
   
switchCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "environment"}});
  }
  frontCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "user"}});
  }
  
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="houseVisitCaptureCls">
              <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>
                        {
                            this.props.stateData.influencerTypeVal == "PS" ?
                            ("ADD Party Seniors")
                            :
                            ("ADD Neutral Influencers")
                        }
                         
                    </Modal.Title>
                     
                </Modal.Header>
                <Modal.Body className="">

                <Row>
                    <Col>
                        <fieldset class=""><legend className='f-12'>Person Details</legend>
                            <Row>
                                <Col className='mt-2'>
                                    <h6 className="form-label f-14 fw-bold">{this.props.stateData.influencerTypeVal == "AO" ? "Association Name" : "Person Name" } <span className='color_red f-14 fw-bold ml-22'>*</span> <span className='color_red f-9 ml-22'>{this.props.stateData.ValidatePersonName}</span></h6>
                                    <Form.Control type="text" name="partyPersonName" value={this.props.stateData.partyPersonName}  className='form-control-sm'  placeholder="Enter Name" onChange={(e) => this.props.handleOnchangeInputBox(e)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mt-2'>
                                    <h6 className="form-label f-14 fw-bold mt-2">Mobile No <span className='color_red f-14 fw-bold ml-22'>*</span> <span className='color_red f-9 ml-22'>{this.props.stateData.ValidatePartyPersonMobileNo}</span></h6>
                                    <Form.Control type="text" name="partyPersonMobileNo" value={this.props.stateData.partyPersonMobileNo} maxLength={10}  className='form-control-sm'  placeholder="Enter Mobile No" onChange={(e) => this.props.handleOnchangeInputBox(e)} onKeyUp={(e) => this.props.handleOnchangeAllowNumbersInputBox(e)}/>
                                </Col>
                            </Row>
                            {
                                this.props.stateData.influencerTypeVal == "PS" ?
                                (
                                    <Row>
                                        <Col>
                                        <h6 className="form-label f-14 fw-bold mt-2">Party<span className='color_red f-14 fw-bold ml-22'>*</span> </h6>
                                                <SelectBox
                                                    stateData={this.props.stateData.partyIdStrValue}
                                                    handleChange={this.props.handleOnchangeSelectBox}
                                                    name="partyIdStr"
                                                    options={this.props.stateData.party_ids_Arr}
                                                    placeholderName={'Select Party'}
                                                    dropdownIndicator={{ DropdownIndicator }}
                                                /> 
                                                <span className='color_red f-9'>{this.props.stateData.ValidatepartyPersonParty}</span>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }
                            
                            <Row>
                                <Col>
                                <h6 className="form-label f-14 fw-bold mt-2">Details </h6>
                                    <FloatingLabel
                                            controlId="locationId"
                                            label="Enter Details"
                                            className="f-12"
                                            
                                        >
                                            <Form.Control as="textarea" placeholder="Enter Location" name="partyPersonDetails" value={this.props.stateData.partyPersonDetails} className='height100px f-14' onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                        </FloatingLabel>
                                        <span className='color_red f-9'>{this.props.stateData.ValidatepartyPersonDetails}</span>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <fieldset class=""><legend className='f-12'>లైవ్ ఫోటో తీయటం లేదా ఫోటో అప్లోడ్ చేయండి</legend>
                            <Row>
                            <Col sm={12}>
                                <Form className="">
                                    <Form.Check
                                            inline
                                            label={"లైవ్ ఫోటో తీయటం"}
                                            name="image_capture"
                                            type="radio"
                                            id="image_capture_upload"
                                            onChange={()=>this.props.selectCaptureTypeForActivityInfluencer("camara")}
                                            className="f-12 fw-bold"
                                            checked={this.props.stateData.camaraRadioButtonActivityInfluencer}
                                        />
                                        <Form.Check
                                            inline
                                            label="ఫోటో అప్లోడ్ చేయండి"
                                            name="image_capture_upload"
                                            type="radio"
                                            id="upload_photos"
                                            onChange={()=>this.props.selectCaptureTypeForActivityInfluencer("upload")}
                                            className="f-12 fw-bold"
                                            checked={this.props.stateData.uploadImageRadioButtonActivityInfluencer}
                                        />
                                </Form>
                            </Col>
                            </Row>

                            {
                                this.props.stateData.selectedTypePhotoActivityInfluencer == "camara" ?
                                (
                                    <>
                                    <h6 className='f-12 color_red'>{this.props.stateData.validateImageUploadActivityInfluencer}</h6>
                                    <Row>
                                        <Col className="mt-2">
                                                <div className="showImgCaptureDivBSBG">{this.props.stateData.showActivityImgCaptureActivityInfluencer && (
                                                <> 
                                                <Webcam
                                                    audio={false}
                                                    ref={this.props.stateData.webcamRef}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={this.state.videoConstraints}
                                                /> 
                                                
                                                
                                                <div className="row mt-2">
                                                    <div className="col">
                                                        <h6 onClick={() => this.props.captureForActivityImageUploadActivityInfluencer()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                                    </div>
                                                    <div className="col">
                                                        {
                                                        this.state.videoConstraints.facingMode == "user" ?
                                                        (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                                        :
                                                        ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                                        }
                                                    </div>
                                                </div>

                                                
                                                </>
                                                
                                                )}
                                                </div>
                                            </Col>
                                        </Row>
                                        </>
                                )
                                :
                                (
                                    <Row>
                                        <Col className="mt-2">
                                        <div className="form-group">
                                            <h6 className='f-12 color_red'></h6>
                                            <label for="uploadfilesForLive" class="drop-container">
                                                <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                                <input type="file" id="uploadfilesForLive" className='f-14'  multiple onChange={this.props.uploadMultipleFilesForPhotosActivityInfluencer}/>
                                            </label>
                                        </div>
                                        <h6 className='f-12 color_red mt-2'>{this.props.stateData.validateUploadImageActivityInfluencer}</h6>
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                this.props.stateData.photoCaptureForActivityArrActivityInfluencer.length>0 ?
                                (
                                <>
                                    <Row>
                                    <Col sm={12} className="mt-2">
                                        {
                                            this.props.stateData.selectedTypePhotoActivityInfluencer == "camara" ? 
                                            (<h6>Capture Photos : </h6>)
                                            :
                                            (<h6>Upload Images: </h6>)
                                        }
                                       
                                    </Col>
                                    <Col sm={12} className="">
                                        <div className='flex_img'>
                                            {(this.props.stateData.photoCaptureForActivityArrActivityInfluencer || []).map((url,index) => (
                                                <div className='ms-1 border_img_pad'>
                                                    <i class="fa-solid fa-circle-xmark" onClick={() => this.props.removeCapturePhotoFromActivityActivityInfluencer(url.fileId)}></i>
                                                    <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                </div>
                                            ))} 
                                        </div>
                                    </Col>
                                </Row>
                                </>
                                
                                
                                )
                                :
                                ("")
                            }
                            
                            </fieldset>
                    </Col>
                </Row>
                  

                  <Row>
                        <Col sm={12}>
                          
                            <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.props.stateData.submitButtonDisabledForActivityActivityInfluencer} onClick={() => this.props.saveActivityInfluencer()}>{this.props.stateData.influencerTypeVal == "AO" ? "ADD Association" : "ADD Person"}</Button>
                            
                            {
                                this.props.stateData.savaLoadingSymbolActivityInfluencer == "show" ? 
                                (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                :
                                ("")
                            }
                            <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.props.stateData.saveSuccessMsgActivityInfluencer}</h6>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default AddPartySeniorOrInfluencers;
  

