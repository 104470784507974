
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button  } from 'react-bootstrap';
import "./EventsAndActivities.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import arrowLeft from '../../images/arrow-left.png';
import users from '../../images/users.png';
import Idem_Logo from '../../images/idem_logo.png';
import ntr_trust_Logo from '../../images/ntr_trust.jpg';
import loader from '../../images/loader.gif';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import EventPhotosSection from './EventPhotosSection';
import AttendedPhotosSection from './AttendedPhotosSection';
import FeedBackFormsSection from './FeedBackFormsSection';
import UploadFeedBackForm from './UploadFeedBackForms';
import CampaignAttendedUsers from './CampaignAttendedUsers';
import MediaCoverageSection from './MediaCoverageSection';
import LOGORED from '../../images/logo_red.png';
import VisitedHousesCount from '../VotersListRevision/VisitedHousesCount'

class OngoingAndReady extends Component {

    constructor(props) {
        super(props)
        this.state = {
           event:props?.location?.params?.event == undefined ? "" : props?.location?.params?.event,
           eventStatus:props?.location?.params?.eventStatus == undefined ? "" : props?.location?.params?.eventStatus,
           campaignDetailsId:props?.location?.params?.campaignDetailsId == undefined ? "" : props?.location?.params?.campaignDetailsId,
           eventStatusDisplayMsg:"",
           eventLoaderImg:"hide",
           eventType:props?.location?.params?.eventType == undefined ? "" : props?.location?.params?.eventType,
           captureImageBase64:props?.location?.params?.captureImageBase64 == undefined ? "" : props?.location?.params?.captureImageBase64,
           captureFileName:props?.location?.params?.captureFileName == undefined ? "" : props?.location?.params?.captureFileName,
           captureImageType:props?.location?.params?.captureImageType == undefined ? "" : props?.location?.params?.captureImageType,
           isAttended:props?.location?.params?.isAttended == undefined ? "" : props?.location?.params?.isAttended,
           startEventLatCoordinates:props?.location?.params?.startEventLatCoordinates == undefined ? "" : props?.location?.params?.startEventLatCoordinates,
           startEventLongCoordinates:props?.location?.params?.startEventLongCoordinates == undefined ? "" : props?.location?.params?.startEventLongCoordinates,
           surveyType:props?.location?.params?.surveyType == undefined ? "" : props?.location?.params?.surveyType,
           pollManagementPage:props?.location?.params?.pollManagementPage == undefined ? "" : props?.location?.params?.pollManagementPage,
           MLCPage:props?.location?.params?.MLCPage == undefined ? "" : props?.location?.params?.MLCPage,
           
           
           
           uploadFeedBackFormModal:false,
           feedBackFormsList:[],
           feedBackFormsCheck:0,
           file: [],
           validateUploadForms:"",
           fileUploaderLoaderImg:"hide",
           fileUploadedStatusMsg:"",

           eventPhotosList:[],
           eventPhotosCheck:0,

           attenedPhotosList:[],
           attendedPhotosCheck:0,

           campaignAttendedCount:0,

           eventMaterialBlock:"hide",
           campaignAttendedUserModal:false,
           campaignAttendedUserDetails:[],
           campaignAttendedUserDetailsCheck:0,

           mediaCoveragePhotosList:[],
           mediaCoveragePhotosCheck:0,

           campaignQuestionnaireCheck:[]
           
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.saveFeedBackFormsFiles = this.saveFeedBackFormsFiles.bind(this)
    }

    componentDidMount() {

        if((this.state.captureImageBase64 == "") && (this.state.event == "" || this.state.eventStatus == "")){
            this.props.history.push({pathname:"/events",params: { navbarDisplay:"hide"}});
            
        }else if(this.state.captureImageBase64 !=""){
            if(this.state.isAttended == "Yes"){
                setTimeout(() => {
                    this.giveOtherProgramsCampaignAttendance();
                }, 1000);
            }else{
                setTimeout(() => {
                    this.giveMyProgramsReadyCampaignAttendance();
                }, 1000);
            }
        }

        setTimeout(() => {
            if(this.state.eventStatus != 1){
                this.getCampaignAttendedCount();
                this.getCampaignAttendedUserDetails();
                this.getEventPhotos();
                this.getCampaignAttendanceMedia();
                this.getCampaignFeedbackForms();
                this.getCampaignMediaCoverage();
                this.getCampaignQuestionnaireCheck();
            }
            
        }, 1000);

        
        
    }

    getCampaignAttendedUserDetails = () => {
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                
            });
             

        getCampaignAPICallData(userAccessObj,"getCampaignAttendedUserDetails")
            .then((res) => res.json())
            .then((data) => { 
                if(data !=null && data.length>0){
                    this.setState({campaignAttendedUserDetails:data,campaignAttendedUserDetailsCheck:1});
                }else{
                    this.setState({campaignAttendedUserDetails:[],campaignAttendedUserDetailsCheck:0});
                }
             });
                
           
    }

    getCampaignAttendedCount = () => {
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                
            });
             

        getCampaignAPICallData(userAccessObj,"getCampaignAttendedCount")
            .then((res) => res.json())
            .then((data) => { 
                if(data !=null && data.attendedCount>0){
                    this.setState({campaignAttendedCount:data.attendedCount});
                }else{
                    this.setState({campaignAttendedCount:0});
                }
             });
                
           
    }

    giveMyProgramsReadyCampaignAttendance = () => {
        this.setState({eventStatus:2});
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                "latitude":this.state.startEventLatCoordinates.toString(),
                "longitude":this.state.startEventLongCoordinates.toString(),
                "files":[{
                        "fileName" : this.state.captureFileName,
                        "fileType" : this.state.captureImageType,
                        "eventType" : "Attendance",
                        "base64Str" : this.state.captureImageBase64
                    }]
            });
             

        getCampaignAPICallData(userAccessObj,"giveCampaignAttendance")
            .then((res) => res.json())
            .then((data) => { this.startEvent(2);  this.getCampaignAttendanceMedia();});
                
           
    }

    giveOtherProgramsCampaignAttendance = () => {
        this.setState({eventStatus:2});
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.ShowOtherProgramsPosition,this.ShowLocationPermissionError)
        }else{
            alert("An unknown error occurred")
        }
    }

    ShowOtherProgramsPosition = (position) => {
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                "latitude":position.coords.latitude,
                "longitude":position.coords.longitude,
                "files":[{
                        "fileName" : this.state.captureFileName,
                        "fileType" : this.state.captureImageType,
                        "eventType" : "Attendance",
                        "base64Str" : this.state.captureImageBase64
                    }]
            });
            

        getCampaignAPICallData(userAccessObj,"giveCampaignAttendance")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.status == "Success"){
                    this.setState({eventStatusDisplayMsg:"Attend SuccessFully..."});
                    setTimeout(() => {
                        this.setState({eventStatusDisplayMsg:""});
                        this.getCampaignAttendanceMedia();
                    }, 600);
                }else{
                    this.setState({eventStatusDisplayMsg:"Something Wrong,Please Try Again..."});
                }
            });
    }

    ShowLocationPermissionError = (error) => {
        switch (error.code){
            case error.PERMISSION_DENIED:
              alert("User Location Permission is Denied,Please Enable Location Services...(Path:Settings > Privacy > Location Services)");
              break;

              case error.POSITION_UNAVAILABLE:
              alert("User Postion Unavailable Denied");
              break;

              default:
                alert("An unknown error occurred")
        }
    }

    startEvent = (conductedStatusId) => {
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                "conductedStatusId":conductedStatusId,
                "latitude":this.state.startEventLatCoordinates.toString(),
                "longitude":this.state.startEventLongCoordinates.toString(),
            });
            

        getCampaignAPICallData(userAccessObj,"updateCampaignConductedStatus")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.status == "Success"){
                    this.setState({eventStatusDisplayMsg:"Event Started SuccessFully..."});
                    setTimeout(() => {
                        this.setState({eventStatus:2,eventStatusDisplayMsg:""});
                    }, 2000);
                }else{
                    this.setState({eventStatusDisplayMsg:"Something Wrong,Please Try Again..."});
                }
            });
      
    }
  
    updateCampaignConductedStatus = (conductedStatusId) => {
        if(conductedStatusId == 3){
            this.setState({eventLoaderImg:"show"});
        }
        
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition( position => {
                 const getCoordinates =  
                  { 
                    "latitude" : position.coords.latitude,
                    "longitude" : position.coords.longitude,
                  }

                  const userAccessObj =  
                    JSON.stringify({ 
                        "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                        "campaignDetailsId":this.state.campaignDetailsId,
                        "conductedStatusId":conductedStatusId,
                        "latitude":getCoordinates.latitude,
                        "longitude":getCoordinates.longitude,
                    });
                    
        
                getCampaignAPICallData(userAccessObj,"updateCampaignConductedStatus")
                    .then((res) => res.json())
                    .then((data) => {
                        if(conductedStatusId == 3){
                            this.setState({eventLoaderImg:"hide"});
                        }
                        if(data !=null && data.status == "Success"){
                            if(conductedStatusId == 2){
                                this.setState({eventStatusDisplayMsg:"Event Started SuccessFully..."});
                                setTimeout(() => {
                                    this.setState({eventStatus:2,eventStatusDisplayMsg:""});
                                }, 2000);
                            }else if(conductedStatusId == 3){
                                this.setState({eventStatusDisplayMsg:"Event End SuccessFully..."});
                                setTimeout(() => {
                                    this.setState({eventStatus:3,eventStatusDisplayMsg:""});
                                    this.props.history.push({pathname:"/events",params: { navbarDisplay:"hide"}});
                                }, 2000);
                            }
                        }else{
                            this.setState({eventStatusDisplayMsg:"Something Wrong,Please Try Again..."});
                        }
                    });
                
    
            });
          }
      
    }

    closepopup = () => {
        var thingToRemove = document.querySelectorAll(".bs-popover-top")[0];
        while (thingToRemove.firstChild) {
            thingToRemove.removeChild(thingToRemove.firstChild);
          }
    }
   

    checkAttendPhoto = () => {
        this.props.history.push({
            pathname:"/image_capture",
            params: {
                navbarDisplay:"hide",
                eventStatus: this.state.eventStatus,
                event:this.state.event,
                eventType:this.state.eventType,
                campaignDetailsId:this.state.campaignDetailsId,
                isAttended:this.state.isAttended,
                startEventLatCoordinates:"",
                startEventLongCoordinates:"",
                surveyType:this.state.surveyType,
                pollManagementPage:this.state.pollManagementPage,
                MLCPage:this.state.MLCPage
                
            }
        });
    }

    //Upload FeedBack Form//
    uploadFeedBackForm = () => {
        this.setState({ file: [] })
        var thingToRemove = document.querySelectorAll(".bs-popover-bottom")[0];
        while (thingToRemove.firstChild) {
            thingToRemove.removeChild(thingToRemove.firstChild);
          }
        this.setState({uploadFeedBackFormModal:true});
    }

        getCampaignFeedbackForms = () => {
            const obj =  
                JSON.stringify({ 
                    "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                    "campaignDetailsId":this.state.campaignDetailsId,
                    
                });
                

            getCampaignAPICallData(obj,"getCampaignFeedbackForms")
                .then((res) => res.json())
                .then((data) => {
                    if(data !=null && data.length>0){
                        this.setState({feedBackFormsList:data,feedBackFormsCheck:0});
                    }else{
                        this.setState({feedBackFormsList:[],feedBackFormsCheck:1});
                    }
                });
        }

        toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    uploadMultipleFiles(e) {
        this.setState({validateUploadForms:"" });
            let fileObj=[],fileArray=[];
            fileObj.push(e.target.files);
                for (let i = 0; i < fileObj[0].length; i++) {
                    this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                    .then(dataUrl => {
                        var obj ={
                            fileId:i,
                            fileName:fileObj[0][i].name,
                            fileType:fileObj[0][i].type,
                            data:dataUrl
                        }
                        fileArray.push(obj)
                    });
                }
                setTimeout(() => {
                    this.setState({ file: fileArray })
                }, 500);
            
    }

    saveFeedBackFormsFiles() {
        this.setState({validateUploadForms:"" });
        if(this.state.file.length==0){
            this.setState({validateUploadForms:"Please Atleast one File Upload...." });
            return;
        }
        this.setState({ fileUploaderLoaderImg: "show" });
        
       
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.ShowSaveFeedBackFormsPosition,this.ShowLocationPermissionError)
        }else{
            alert("An unknown error occurred")
        }
    }

    ShowSaveFeedBackFormsPosition = (position) => {
        let uploadFormsArr=[];
        for (let i = 0; i < this.state.file.length; i++) {
            var splitFileType = this.state.file[i].fileType.split("/");
            var imageBase64 = this.state.file[i].data.replace("data:"+this.state.file[i].fileType+";base64,","");
            
           
            var obj={
                "fileName" : this.state.file[i].fileName,
                "fileType" : splitFileType[1],
                "eventType" : "Feedback",
                "latitude":position.coords.latitude,
                "longitude":position.coords.longitude,
                "base64Str" :imageBase64
            }
            uploadFormsArr.push(obj);
        }
        setTimeout(() => {
            if(uploadFormsArr.length>0){
                const obj =  
                    JSON.stringify({ 
                        "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                        "campaignDetailsId":this.state.campaignDetailsId,
                        "files":uploadFormsArr
                        
                    });
                    
    
                getCampaignAPICallData(obj,"uploadCampaignMedia")
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({ fileUploaderLoaderImg: "hide" });
                        if(data !=null && data.status == "Success"){
                            this.setState({fileUploadedStatusMsg:"Uploaded SuccessFully..."});
                            setTimeout(() => {
                                this.setState({fileUploadedStatusMsg:"",uploadFeedBackFormModal:false});
                                this.getCampaignFeedbackForms();
                            }, 2000);
                        }else{
                            this.setState({fileUploadedStatusMsg:"Something Wrong,Please Try Again..."});
                        }
                });
              }
        }, 500);
    }
    //Upload Feedback form End//

    //Event Photos//
    getEventPhotos = () => {
        const obj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                
            });
             

        getCampaignAPICallData(obj,"getCampaignMedia")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.length>0){
                    this.setState({eventPhotosList:data,eventPhotosCheck:0});
                }else{
                    this.setState({eventPhotosList:[],eventPhotosCheck:1});
                }
            });
    }

    liveEventPhotosCapture = (uploadType) => {
        var thingToRemove = document.querySelectorAll(".bs-popover-bottom")[0];
        while (thingToRemove.firstChild) {
            thingToRemove.removeChild(thingToRemove.firstChild);
          }

          this.props.history.push({
            pathname:"/live_event_photos_capture",
            params: {
                navbarDisplay:"hide",
                eventStatus: this.state.eventStatus,
                event:this.state.event,
                eventType:this.state.eventType,
                campaignDetailsId:this.state.campaignDetailsId,
                isAttended:this.state.isAttended,
                uploadType:uploadType,
                surveyType:this.state.surveyType,
                pollManagementPage:this.state.pollManagementPage,
                MLCPage:this.state.MLCPage
                
            }
        });
    }
    //Event Photos End//

    //Attended Photos//

    getCampaignAttendanceMedia = () => {
        const obj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                
            });
             

        getCampaignAPICallData(obj,"getCampaignAttendanceMedia")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.length>0){
                    this.setState({attenedPhotosList:data,attendedPhotosCheck:0,attendedPhotosLength:data.length});
                }else{
                    this.setState({attenedPhotosList:[],attendedPhotosCheck:1,attendedPhotosLength:0});
                }
            });
    }

    //Attended Photos//

    eventMaterial = () => {
        var thingToRemove = document.querySelectorAll(".bs-popover-bottom")[0];
        while (thingToRemove.firstChild) {
            thingToRemove.removeChild(thingToRemove.firstChild);
          }

          this.props.history.push({
            pathname:"/event_material",
            params: {
                navbarDisplay:"hide",
                eventStatus: this.state.eventStatus,
                event:this.state.event,
                eventType:this.state.eventType,
                campaignDetailsId:this.state.campaignDetailsId,
                isAttended:this.state.isAttended,
                surveyType:this.state.surveyType,
                pollManagementPage:this.state.pollManagementPage,
                MLCPage:this.state.MLCPage
                
            }
        });
    }

    campaignAttendedUserDetails = () => {
        this.setState({campaignAttendedUserModal:true});
    }

    getCampaignMediaCoverage = () => {
        const obj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                
            });
             

        getCampaignAPICallData(obj,"getCampaignMediaCoverage")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.length>0){
                    this.setState({mediaCoveragePhotosList:data,mediaCoveragePhotosCheck:0});
                }else{
                    this.setState({mediaCoveragePhotosList:[],mediaCoveragePhotosCheck:1});
                }
            });
    }

    questionerForm = () => {
        var thingToRemove = document.querySelectorAll(".bs-popover-bottom")[0];
        while (thingToRemove.firstChild) {
            thingToRemove.removeChild(thingToRemove.firstChild);
          }

          this.props.history.push({
            pathname:"/questionerForm",
            params: {
                navbarDisplay:"hide",
                eventStatus: this.state.eventStatus,
                event:this.state.event,
                eventType:this.state.eventType,
                campaignDetailsId:this.state.campaignDetailsId,
                isAttended:this.state.isAttended,
                surveyType:this.state.surveyType,
                pollManagementPage:this.state.pollManagementPage,
                MLCPage:this.state.MLCPage
                
            }
        });

    }


    visitMLCVoterDetails= () => {
        var thingToRemove = document.querySelectorAll(".bs-popover-bottom")[0];
        while (thingToRemove.firstChild) {
            thingToRemove.removeChild(thingToRemove.firstChild);
          }

          this.props.history.push({
            pathname:"/mlc_voters",
            params: {
                navbarDisplay:"hide",
                eventStatus: this.state.eventStatus,
                event:this.state.event,
                eventType:this.state.eventType,
                campaignDetailsId:this.state.campaignDetailsId,
                isAttended:this.state.isAttended,
                surveyType:this.state.surveyType,
                pollManagementPage:this.state.pollManagementPage,
                MLCPage:this.state.MLCPage
                
            }
        });
    }

    visitMLCPollManagementDetails = () => {
        var thingToRemove = document.querySelectorAll(".bs-popover-bottom")[0];
        while (thingToRemove.firstChild) {
            thingToRemove.removeChild(thingToRemove.firstChild);
          }

          this.props.history.push({
            pathname:"/poll_management",
            params: {
                navbarDisplay:"hide"
            }
        });
    }

    getCampaignQuestionnaireCheck = () => {
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignId":this.state.event.campaignId,
                
            });
             

        getCampaignAPICallData(userAccessObj,"getCampaignQuestionnaire")
            .then((res) => res.json())
            .then((data) => { 
                if(data !=null && data.length>0){
                    this.setState({campaignQuestionnaireCheck:data});
                }else{
                    this.setState({campaignQuestionnaireCheck:[]});
                }
             });
                
           
    }
  
    render() {
        console.log("surveyType",this.state.surveyType)
        return (
              <section >
                <div className='bg_red_event'>
                    <div className='flex_event_name_top'>
                         <div>
                         <Link to={{pathname: "/events",params: {navbarDisplay:"hide"}}}> <Image src={arrowLeft} alt="Arrow" ></Image></Link>
                      
                    
                         </div>  
                         <div>
                            <h6 className='color_white fw-bold mt-3'>Today Party Program & Activities</h6>
                         </div> 
                    </div>
                    <div className='white_block_with_left_border mt-2'>
                        {
                            this.state.campaignAttendedCount !=0 ?
                            (<h6 className='f-16 fw-bold'>{this.state.event.campaignName}  <span className='float-end campaignAttendedDtsCls' onClick={() => this.campaignAttendedUserDetails()}><Image src={users} alt="users" ></Image> {this.state.campaignAttendedCount}</span></h6>)
                            :
                            (<h6 className='f-16 fw-bold'>{this.state.event.campaignName} <span className='float-end'><Image src={users} alt="users" ></Image> {this.state.campaignAttendedCount}</span></h6>)
                        }
                        
                        
                        {
                            this.state.event.campaignId == 320 ?
                            (
                                <>
                                <div className='flex_PA'>
                                      <div className='mt-1'>
                                            <h6 className='f-12'>Date</h6>
                                            <h6 className='f-14 fw-bold'>From 25-09-2023</h6>
                                        </div>  
                                        <div>
                                            <img src={require('../../images/cbn_support_2.jpeg')} alt="cbn_support" className='w_60'></img>
                                        </div>
                                </div>
                                </>
                                
                            )
                            :
                            (
                                <>
                                <div className='flex_PA mt-2'>
                                    <div>
                                        <h6 className='f-12'>Location</h6>
                                        <h6 className='f-12'>{this.state.event.level} - {this.state.event.location}</h6>
                                    </div>
                                    
                                </div>
                                <div className='mt-1'>
                                    <h6 className='f-12'>Date</h6>
                                    <h6 className='f-12'>{this.state.event.fromDate} To {this.state.event.todate}</h6>
                                </div>
                                </>
                            )
                        }
                        
                    
                    </div>
                </div>
                
                {
                    ((this.state.eventType == "my_programs") && (this.state.eventStatus == 2 || this.state.eventStatus == 3)) ? 
                    (
                        <Container>
                        <Row>
                            <Col sm={12} className="mt-2">
                                <OverlayTrigger
                                    rootClose
                                    trigger={`click`}
                                    key={'bottom'}
                                    placement={'bottom'}
                                    overlay={
                                        <Popover id="popover-top">
                                        
                                        <Popover.Body>
                                            <i class="fa-solid fa-circle-xmark float-end color_red f-18 cursor_pointer" onClick={() => this.closepopup()}></i>
                                            <ul className='list-inline event_material_dots_popup'>
                                                
                                                <li onClick={() => this.eventMaterial()}>Event Material </li>
                                                {
                                                    this.state.surveyType == "Voter"  ?
                                                    (<li onClick={() => this.visitMLCVoterDetails()}>MLC Door to Door Campaign </li>
                                                    )
                                                    :
                                                    ("")
                                                }
                                                {
                                                    this.state.event.campaignId == 277 ?
                                                    (<li onClick={() => this.visitMLCPollManagementDetails()}>Poll Management </li>)
                                                    :
                                                    ("")
                                                }
                                                <li onClick={() => this.liveEventPhotosCapture("media")}>Media Coverage </li>
                                                <li onClick={() => this.liveEventPhotosCapture("photos")}>Upload Program Photos</li>
                                                {
                                                    this.state.event.campaignId == 238 ?
                                                    (<li onClick={() => this.uploadFeedBackForm()}>Upload Feedback Form</li>)
                                                    :
                                                    ("")
                                                }
                                                {
                                                    this.state.campaignQuestionnaireCheck.length>0 ?
                                                    (<li onClick={() => this.questionerForm()}>Questioner</li>)
                                                    :
                                                    ("")
                                                }
                                                
                                                
                                            </ul>
                                        </Popover.Body>
                                        </Popover>
                                    }
                                    >
                                    <h6 className="float-end dots_icon" ><i class="fa-solid fa-ellipsis"></i></h6>
                                    </OverlayTrigger>
                                    
                                </Col>
                            </Row>
                            </Container>
                    )
                    :
                    ("")
                }

                {
                    ((this.state.eventType == "other_programs") && (this.state.isAttended == "Yes") && (this.state.eventStatus == 2 || this.state.eventStatus == 3)) ? 
                    (
                        <Container>
                        <Row>
                            <Col sm={12} className="mt-2">
                                <OverlayTrigger
                                    rootClose
                                    trigger={`click`}
                                    key={'bottom'}
                                    placement={'bottom'}
                                    overlay={
                                        <Popover id="popover-top">
                                        
                                        <Popover.Body>
                                            <i class="fa-solid fa-circle-xmark float-end color_red f-18 cursor_pointer" onClick={() => this.closepopup()}></i>
                                            <ul className='list-inline event_material_dots_popup'>
                                                
                                                <li onClick={() => this.eventMaterial()}>Event Material </li>
                                                {
                                                    this.state.surveyType == "Voter"  ?
                                                    (<li onClick={() => this.visitMLCVoterDetails()}>MLC Door to Door Campaign </li>
                                                    )
                                                    :
                                                    ("")
                                                }
                                                {
                                                    this.state.event.campaignId == 277 ?
                                                    (<li onClick={() => this.visitMLCPollManagementDetails()}>Poll Management </li>)
                                                    :
                                                    ("")
                                                }
                                                <li onClick={() => this.liveEventPhotosCapture("media")}>Media Coverage </li>
                                                <li onClick={() => this.liveEventPhotosCapture("photos")}>Upload Program Photos</li>
                                                {
                                                    this.state.event.campaignId == 238 ?
                                                    (<li onClick={() => this.uploadFeedBackForm()}>Upload Feedback Form</li>)
                                                    :
                                                    ("")
                                                }
                                                 {
                                                    this.state.campaignQuestionnaireCheck.length>0 ?
                                                    (<li onClick={() => this.questionerForm()}>Questioner</li>)
                                                    :
                                                    ("")
                                                }
                                                
                                            </ul>
                                        </Popover.Body>
                                        </Popover>
                                    }
                                    >
                                    <h6 className="float-end dots_icon" ><i class="fa-solid fa-ellipsis"></i></h6>
                                    </OverlayTrigger>
                                    
                                </Col>
                            </Row>
                            </Container>
                    )
                    :
                    ("")
                }

                {
                    this.state.eventStatus == 1 ?
                    (
                        <>
                        {
                            this.state.event.campaignId == 240 ?
                            (<Image src={ntr_trust_Logo} alt="ntr_trust_logo" className='idem_img_pos'></Image>)
                            :
                            (
                                this.state.event.campaignId == 238 ?
                                (<Image src={Idem_Logo} alt="idem_logo" className='idem_img_pos'></Image>)
                                :
                                (
                                    this.state.event.campaignId == 271 ?
                                    (<Image src={LOGORED} alt="LOGORED" className='w-100 mt-3 mb-4'></Image>)
                                    :
                                    (<h6 className='mb-4'></h6>)
                                )
                                
                            )
                        }
                        
                            <Container>
                                <Row>
                                    <Col>
                                        <Button variant="danger" className="red_button_event center-auto mt-2" onClick={() => this.checkAttendPhoto()}>Start Event
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )
                    :
                    (
                        this.state.eventStatus == 2 && this.state.isAttended == "No" ?
                        (
                            <>
                                    {
                                        this.state.event.campaignId == 240 ?
                                        (<Image src={ntr_trust_Logo} alt="ntr_trust_logo" className='idem_img_pos'></Image>)
                                        :
                                        (
                                            this.state.event.campaignId == 238 ?
                                            (<Image src={Idem_Logo} alt="idem_logo" className='idem_img_pos'></Image>)
                                            :
                                            (
                                                this.state.event.campaignId == 271 ?
                                                (<Image src={LOGORED} alt="LOGORED" className='w-100 mt-3 mb-4'></Image>)
                                                :
                                                ("")
                                            )
                                            
                                        )
                                    }
                                
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Button variant="danger" className="red_button_event center-auto mt-2" onClick={() => this.checkAttendPhoto()}>Attend Event
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </>
                        )
                        :
                        (
                            this.state.eventStatus == 2 ? 
                            (
                                <Container>
                                    {
                                        this.state.event.campaignId == 320 ?
                                        (<VisitedHousesCount/>)
                                        :
                                        ("")
                                    }
                                    
                                    <EventPhotosSection  stateData = {this.state}/>
                                    <AttendedPhotosSection  stateData = {this.state}/>
                                    {
                                        this.state.event.campaignId == 238 ?
                                        (
                                            <FeedBackFormsSection  
                                                stateData = {this.state}
                                                puploadFeedBackFormsFiles={this.saveFeedBackFormsFiles}
                                            />
                                        )
                                        :
                                        ("")
                                    }
                                    <MediaCoverageSection stateData = {this.state}/>

                                    {
                                        this.state.eventType == "my_programs" ?
                                        (
                                            <Row>
                                                <Col sm={12}>
                                                    <Button variant="danger" className="red_button_event center-auto mt-4" onClick={() => this.updateCampaignConductedStatus(3)}>End Event
                                                        {
                                                            this.state.eventLoaderImg == "show" ? 
                                                            (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                                            :
                                                            ("")
                                                        }
                                                    </Button>
                                                
                                                    <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.state.eventStatusDisplayMsg}</h6>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                    }
                                   
                                </Container>
                            )
                            :
                            (
                                this.state.eventStatus == 3 ?
                                (
                                    <Container>
                                        <EventPhotosSection  stateData = {this.state}/>
                                        <AttendedPhotosSection  stateData = {this.state}/>
                                        {
                                            this.state.event.campaignId == 238 ?
                                            (
                                                <FeedBackFormsSection  
                                                    stateData = {this.state}
                                                    puploadFeedBackFormsFiles={this.saveFeedBackFormsFiles}
                                                />
                                            )
                                            :
                                            ("")
                                        }
                                        
                                        <MediaCoverageSection stateData = {this.state}/>
                                    </Container>
                                    
                                )
                                :
                                ("")
                            )
                        )
                    )
                }

                    <UploadFeedBackForm 
                        show={this.state.uploadFeedBackFormModal}
                        onHide={() => this.setState({ uploadFeedBackFormModal: false })}
                        campaignDetailsId={this.state.campaignDetailsId}
                        stateData={this.state}
                        puploadFeedBackFormsFiles={this.saveFeedBackFormsFiles}
                        puploadMultipleFiles={this.uploadMultipleFiles}
                    />

                    <CampaignAttendedUsers 
                        show={this.state.campaignAttendedUserModal}
                        onHide={() => this.setState({ campaignAttendedUserModal: false })}
                        stateData={this.state}
                    />

              </section>  
        )
    }
  }
  
  export default withRouter(OngoingAndReady);
  