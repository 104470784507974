
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,Card,InputGroup,Collapse  } from 'react-bootstrap';
import "./cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';


//const fetch = require('node-fetch');

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class PaymentOptionMode extends Component {
    constructor(props) {
        super(props)
        

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
        }
    }

    componentDidMount() {
        
       
    }
    render() {
        
       
        return (
           
            <>
            <div className='bg_white_shadow_border mt-3'>
                <Row>
                    <Col>
                            <h6 className='f-14 fw-bold'>{this.props.stateData.englishSelected== true ? "Do you want to donate to the party?" : "మీరు పార్టీకి విరాళం (డొనేషన్) చేయదల్చుకున్నారా ?"}</h6>
                            <Row >
                                <Col className='text-center mt-2'>
                                    <Button variant="outline-success" className={this.props.stateData.haveDonatePartyIdCheck == true ? "f-14 fw-bold active_checked" : "f-14 fw-bold"} onClick={() => this.props.haveDonateParty("yes")}> {this.props.stateData.englishSelected== true ? "YES" : "అవును " } <i class="fa-solid fa-circle-check m_left_8"></i></Button>
                                    <Button variant="outline-success" className={this.props.stateData.noDonatePartyIdCheck == true ? "f-14 mr_10 fw-bold active_checked" : "f-14 mr_10 fw-bold"} onClick={() => this.props.haveDonateParty("no")}> {this.props.stateData.englishSelected== true ? "NO" : "కాదు" } <i class="fa-solid fa-circle-xmark m_left_8"></i></Button>
                                </Col>
                            </Row>
                    
                    </Col>
                </Row>
                {
                    this.props.stateData.validatePaymentOptionCheck != "" ?
                    (
                        <Row>
                            <Col>
                                <h6 className='error_cls_new mt-2'>{this.props.stateData.validatePaymentOptionCheck}</h6>
                            </Col>
                        </Row>
                    )
                    :
                    ("")
                }

                {
                    this.props.stateData.haveDonatePartyIdCheck == true ?
                    (
                        <fieldset class=""><legend>{this.props.stateData.englishSelected== true ? "YES" : "అవును "}</legend>  

                        <h6 className='mt-3 f-14 ln-25'>{this.props.stateData.englishSelected== true ? "You Can Specify The Amount You Wish To Donate :" : "మీరు విరాళం (డొనేషన్) చేయదలచుకున్న మొత్తం ని తెలియజేయగలరు ?"}</h6>

                            <Row>
                                <Col>
                                    <div className="mt-1">
                                        <Form.Check
                                                inline
                                                label={"99"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationOneId`}
                                                className="f-12 fw-bold color_black cursor_pointer"
                                                value="99"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"99")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"999"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationtwoId`}
                                                className="f-12 fw-bold color_black cursor_pointer"
                                                value="999"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"999")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"9999"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationThreeId`}
                                                className="f-12 fw-bold color_black cursor_pointer"
                                                value="9999"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"9999")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"19999"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationFourId`}
                                                className="f-12 fw-bold color_black cursor_pointer"
                                                value="19999"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"19999")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"99999"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationFiveId`}
                                                className="f-12 fw-bold color_black cursor_pointer"
                                                value="99999"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"99999")}
                                        ></Form.Check>
                                        <Form.Check
                                                inline
                                                label={"Other Amount"}
                                                name="donationName"
                                                type="radio"
                                                id={`donationSixId`}
                                                className="f-12 fw-bold color_black cursor_pointer"
                                                value="other_amount"
                                                onChange={(e)=>this.props.donationAmountSeletedType(e,"other_amount")}
                                        ></Form.Check>
                                    
                                    </div>
                                </Col>
                            </Row>

                            {
                                this.props.stateData.otherDonationAmount == true ?
                                (
                                    <Row>
                                        <Col className='mt-2'>
                                            <h6 className="form-label f-12 fw-bold"> {this.props.stateData.englishSelected== true ? "Enter Amount" : "Enter Amount"} <span className='color_red f-14 ms-1'>*</span></h6>
                                            <Form.Control type="text" name="donationAmountValue" value={this.props.stateData.donationAmountValue}  className='form-control-sm'  placeholder="Enter Amount" onKeyUp={(e) => this.props.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }

                            {
                                this.props.stateData.validateDonationAmount != "" ?
                                (
                                    <Row>
                                        <Col>
                                            <h6 className='error_cls_new mt-2'>{this.props.stateData.validateDonationAmount}</h6>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }

                            
                           {/* <Row>
                                <Col className='mt-3'>
                                    <h6 className="form-label f-12 fw-bold"> {this.props.stateData.englishSelected== true ? "Pan Card Number" : "పాన్ నంబర్"} </h6>
                                    <Form.Control type="text" name="donationPanNumber" value={this.props.stateData.donationPanNumber}  className='form-control-sm'  placeholder="Enter PanNumber" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                </Col>
                            </Row>*/}

                            {
                                this.props.stateData.validatePanCardNumber != "" ?
                                (
                                    <Row>
                                        <Col>
                                            <h6 className='color_red f-12 mt-2'>{this.props.stateData.validatePanCardNumber}</h6>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                                
                            }
                            
                                        
                        </fieldset>
                    )
                    :
                    (
                        ""
                    )
                }

                

                
            </div>

           {
            this.props.stateData.isVolunteer == "Y" && this.props.stateData.noDonatePartyIdCheck == true ?
            (
                <fieldset class=""><legend>{this.props.stateData.englishSelected== true ? "Payment Mode" : "చెల్లింపు విధానము "}</legend> 
                    <Row>
                        <Col>
                            <div className="mt-2">
                                <Form.Check
                                        inline
                                        label={"Online"}
                                        name="paymentSelectedName"
                                        type="radio"
                                        id={`onlinePaymentId`}
                                        className="f-12 fw-bold color_black cursor_pointer"
                                        value="Online"
                                        onChange={(e)=>this.props.paymentSelectType(e,"Online")}
                                ></Form.Check>
                                {
                                    this.props.stateData.typeOfCardSelectedvalue == "generalCardSelected" ?
                                    (
                                        <Form.Check
                                                inline
                                                label={"Coupon"}
                                                name="paymentSelectedName"
                                                type="radio"
                                                id={`couponDeductionId`}
                                                className="f-12 fw-bold color_black cursor_pointer"
                                                value="Coupon"
                                                onChange={(e)=>this.props.paymentSelectType(e,"Coupon")}
                                        ></Form.Check>
                                    )
                                    :
                                    ("")
                                }
                                
                                {/*<Form.Check
                                        inline
                                        label={"Cash"}
                                        name="paymentSelectedName"
                                        type="radio"
                                        id={`cashPaymentId`}
                                        className="f-12 fw-bold color_black cursor_pointer"
                                        value="Cash"
                                        onChange={(e)=>this.props.paymentSelectType(e,"Cash")}
                                ></Form.Check>*/}
                                
                            
                            </div>
                        </Col>
                    </Row>
                    {
                        this.props.stateData.validatePaymentMode !="" ?
                        (
                            <Row>
                                <Col>
                                    <h6 className='error_cls_new mt-1'>{this.props.stateData.validatePaymentMode}</h6>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                </fieldset>
            )
            :
            ("")
           }

            </>
        )
    }
  }
  
  export default withRouter(PaymentOptionMode);
  