import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form} from "react-bootstrap";

class SelectedUserDetailsForEnroll extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enrollmentsListArr: props?.stateData?.enrollmentsListArr
        }
      
    }

    
    
    render() {
        console.log("ssssssss",this.props?.stateData?.selectedUserViewId)
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>User View Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                        <Row>
                           <Col>
                                 {
                                   this.props?.stateData.selectedUserViewId !=undefined && this.props?.stateData.selectedUserViewId.length>0 ?
                                    (
                                        this.props?.stateData.selectedUserViewId.map((item,index) => {
                                            return(
                                                <>
                                                <h6 className='mt-2'>Name : <b>{item.name}</b></h6>
                                                <h6 className='mt-3'>Age : <b>{item.age}</b>,Gender : <b>{item.gender}</b></h6>
                                                <h6 className='mt-3'>Occupation : <b>{item.occupationCode}</b></h6>
                                                <h6 className='mt-3'>voterCardId : <b>{item.voterCardId}</b></h6>
                                                <h6 className='mt-3'>MobileNo : <b>{item.mobileNo}</b></h6>
                                                <h6 className='mt-3'>Interests : 
                                                {
                                                    item.interestedSchemes.filter(a=> a.interested == true).map((item1,index)=>{
                                                        return(
                                                            <b className='f-12'>{item1.schemeCode},</b>   
                                                        )
                                                        
                                                    })
                                                }
                                                </h6>
                                                </>
                                            )
                                        })
                                    )
                                    :
                                    ("")
                                 }     
                               
                           </Col>   
                        </Row>    
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default SelectedUserDetailsForEnroll;
  

