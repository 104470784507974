import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,InputGroup } from "react-bootstrap";
import loader from '../../images/loader.gif';
import Webcam from "react-webcam";

class HouseVisitedImageCapture extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          videoConstraints:{facingMode: "environment"},

          
          
        }
      
    }

  
   
switchCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "environment"}});
  }
  frontCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "user"}});
  }
  
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="houseVisitCaptureCls">
              <Modal.Header closeButton className='p-3'>
                {
                     this.props.stateData.checkOTPImageValidate == "mobile" ?
                     (<Modal.Title className='f-14 fw-bold'> OTP Verification For House Visiting</Modal.Title>)
                     :
                     (<Modal.Title className='f-14 fw-bold'> Image Verification For House Visiting</Modal.Title>)
                }
                    
                    
                     
                </Modal.Header>
                <Modal.Body className="">
                {
                    this.props.stateData.checkOTPImageValidate == "mobile" ?
                    (
                        <Row>
                            <Col className='mt-2'>
                                <Form>
                                    <Form.Group className="" controlId="formMobileId">
                                        <Form.Label className='f-12 fw-bold'>Mobile No/ఫోన్ నెంబర్:<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.props.stateData.validateCaptureMobileNo}</span>
                                        {
                                            this.props.stateData.mobileNoLoadingSymbol == "show" ?
                                            (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls1'></Image>)
                                            :
                                            (
                                                ""
                                            )
                                        }
                                        {
                                            this.props.stateData.mobileNoSuccessMsg == "SUCCESS"  ?
                                            (<i class="fa-regular fa-circle-check color_green f-14"></i>)
                                            :
                                            (
                                                this.props.stateData.mobileNoSuccessMsg == "FAIL" || this.props.stateData.mobileNoSuccessMsg == "Something Wrong,Please Try Again..." ?
                                                (<i class="fa-regular fa-circle-xmark color_red f-14"></i>)
                                                :
                                                ("")
                                                
                                            )
                                        }
                                        </Form.Label>
                                        <Row>
                                            <Col className='width_90_static'> 
                                                <Form.Control type="text" name="captureMobileNo" disabled={this.props.stateData.mobileNoDisabled} className='form-control-sm' maxLength={10} value={this.props.stateData.captureMobileNo}  placeholder="Enter Mobile No" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                            </Col>
                                            <Col className='width_10_static'>
                                                
                                            {
                                                this.props.stateData.mobileNoSuccessMsg == "SUCCESS" || this.props.stateData.mobileNoSuccessMsg == ""  ?
                                                (
                                                    <>
                                                    {
                                                        this.props.stateData.OTPSentStatus == "Fail" || this.props.stateData.OTPSentStatus == "" ?
                                                        (
                                                            <>
                                                            <span className='f-12 color_red'>{this.props.stateData.smsSentMessage}</span>
                                                                <Button variant="dark" className='f-12 float-right  btn-sm' onClick={() => this.props.getMobileNoOTP()}>GET OTP
                                                                    {
                                                                        this.props.stateData.getOTPLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='get_otp_loading_symbol_cls22'></Image>)
                                                                        :
                                                                        ("")
                                                                    }  
                                                                </Button> 
                                                            </>
                                                        )
                                                        :
                                                        ("")
                                                    }
                                                    </>
                                                )
                                                :
                                                (
                                                    ""
                                                )
                                            }
                                            </Col>
                                        </Row>
                                        {
                                            this.props.stateData.mobileNoSuccessMsg == "SUCCESS" || this.props.stateData.mobileNoSuccessMsg == ""  ?
                                            (
                                                ""
                                            )
                                            :
                                            (
                                                <Row>
                                                    <Col>
                                                        <span className='mt-3 color_red fw-bold f-12 pos_top_m'>This mobile no already registered, use another mobile no</span>
                                                    </Col>
                                                </Row>
                                                
                                            )
                                        }
                                        
                                    </Form.Group>  
                                </Form>

                                <Form>
                                    <Row>
                                        
                                        <Col className=''>
                                        <InputGroup className="mt-3">
                                                    <InputGroup.Text id="basic-addon1" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>OTP No<span className='color_red f-14 ms-1'>*</span>  </InputGroup.Text>
                                                    <Form.Control type="text" name="captureOTPNO" maxLength={6} disabled={this.props.stateData.OTPVerifiedDisabledButton} className='form-control-sm' value={this.props.stateData.captureOTPNO}  placeholder="Enter OTP No" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                        </InputGroup>
                                        <h6 className='color_red f-12 mt-2 font-weight-bold'>{this.props.stateData.validateCaptureOTP}</h6>
                                        </Col>
                                        
                                    </Row>
                                    
                                        <Row>
                                            <Col>
                                                {
                                                    this.props.stateData.OTPSentStatus == "Success"?
                                                    (
                                                        <>
                                                            <h6 className='mt-2 f-12 color_green line_height_20'>{this.props.stateData.smsSentMessage} : <b >{this.props.stateData.captureMobileNo}</b></h6>
                                                            
                                                            {
                                                                this.props.stateData.otpVerifiedMessageStatus != "Verified" || this.props.stateData.otpVerifiedMessageStatus == "" ?
                                                                (
                                                                    <div className="countdown-text">
                                                                        {this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0 ? (
                                                                        <h6 className='mt-2 f-14'>
                                                                            Time Remaining: {this.props.stateData.minutes < 10 ? `0${this.props.stateData.minutes}` : this.props.stateData.minutes}:
                                                                            {this.props.stateData.seconds < 10 ? `0${this.props.stateData.seconds}` : this.props.stateData.seconds}
                                                                        </h6>
                                                                        ) : (
                                                                        <h6 className='mt-2 f-12'>Didn't recieve code?</h6>
                                                                        )}
                                                                        {
                                                                            this.props.stateData.resendOTPLoadingSymbol == "show" ?
                                                                            (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                            :
                                                                            ("")
                                                                        } 
                                                                        <Button variant="dark" className='f-12 float-right mt-2' 
                                                                        disabled={this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0}
                                                                        style={{
                                                                            color: this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0 ? "#DFE3E8" : "#fff",
                                                                        }} onClick={this.props.resendOTPNew}  >Resend OTP</Button>
                                                                        
                                                                    </div>
                                                                )
                                                                :
                                                                ("")
                                                            }
                                                        
                                                            
                                                        </>
                                                    )
                                                    :
                                                    ("")
                                                }
                                            </Col>
                                        </Row>
        
                                        <Row>
                                            <Col>
                                                {
                                                        this.props.stateData.otpLoadingSymbol == "show" ?
                                                        (<Image src={loader} alt="loader" className='loader_cls_otp ms-2'></Image>)
                                                        :
                                                        (
                                                            this.props.stateData.otpVerifiedMessageStatus == "Verified" ?
                                                            (
        
                                                                <h6 class="color_green fw-bold f-14  text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>OTP verified successfully</h6>
                                                                
                                                            )
                                                            :
                                                            (
                                                                this.props.stateData.otpVerifiedMessageStatus == "NotVerified" ?
                                                                (<h6 class="color_red fw-bold f-14 text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>Not Verified,Please Enter Valid OTP</h6>)
                                                                :
                                                                ("")
                                                                
                                                            
                                                            )
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    
                                </Form>

                                    
                            </Col>
                        </Row>
                    )
                    :
                    (
                        <Row>
                            <Form.Label className='f-12 fw-bold mt-2'>Photo Capture : <span className='color_red f-9'>{this.props.stateData.validateCapturePhoto}</span></Form.Label>
                            <Col className="mt-2">
                                <div className="showImgCaptureDivBSBG">{this.props.stateData.showImgCapture && (
                                <> 
                                <Webcam
                                    audio={false}
                                    ref={this.props.stateData.webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={this.state.videoConstraints}
                                /> 
                                
                                
                                <div className="row mt-2">
                                    <div className="col">
                                        <h6 onClick={() => this.props.captureForHouseVisit()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                    </div>
                                    <div className="col">
                                        {
                                        this.state.videoConstraints.facingMode == "user" ?
                                        (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                        :
                                        ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                        }
                                    </div>
                                </div>

                                {
                                    this.props.stateData.imageAddedMsg != "" ?
                                    (<h6 className="f-14 fw-bold text-center color_green">{this.props.stateData.imageAddedMsg}</h6>)
                                    :
                                    ("")
                                }
                                
                                {
                                    this.props.stateData.photoCaptureArr.length>0 ?
                                    (
                                    <>
                                        <Row>
                                        <Col sm={12} className="mt-2">
                                        <h6>Capture Live Photos : </h6>
                                            
                                        </Col>
                                        <Col sm={12} className="">
                                            <div className='flex_img'>
                                                {(this.props.stateData.photoCaptureArr || []).map((url,index) => (
                                                    <div className='ms-1 border_img_pad'>
                                                        <i class="fa-solid fa-circle-xmark" onClick={() => this.props.removeCapturePhotoFromBSBG(url.fileId)}></i>
                                                        <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                    </div>
                                                ))} 
                                            </div>
                                        </Col>
                                    </Row>
                                    </>
                                    
                                    
                                    )
                                    :
                                    ("")
                                }

                                </>
                                
                                )}
                                </div>
                            </Col>
                                </Row>
                    )
                }
               


              {/* <Row>
                    <Col>
                        <h6 className='f-12 color_red mt-3'>Note : Camara is Not Available,Please Click on Reload Button</h6>
                        <Button variant="danger" className='f-12 float-right float-right btn-sm' onClick={() => this.props.reloadCam()}> Reload Webcam</Button>
                    </Col>
                </Row>
                <Row>
                    <Form.Label className='f-12 fw-bold mt-2'>Photo Capture : <span className='color_red f-9'>{this.props.stateData.validateCapturePhoto}</span></Form.Label>
                      <Col className="mt-2">
                        <div className="showImgCaptureDivBSBG">{this.props.stateData.showImgCapture && (
                        <> 
                          <Webcam
                            audio={false}
                            ref={this.props.stateData.webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={this.state.videoConstraints}
                          /> 
                          
                          
                          <div className="row mt-2">
                              <div className="col">
                                <h6 onClick={() => this.props.captureForHouseVisit()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                              </div>
                              <div className="col">
                                {
                                  this.state.videoConstraints.facingMode == "user" ?
                                  (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                  :
                                  ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                }
                              </div>
                          </div>

                          {
                            this.props.stateData.imageAddedMsg != "" ?
                            (<h6 className="f-14 fw-bold text-center color_green">{this.props.stateData.imageAddedMsg}</h6>)
                            :
                            ("")
                          }
                          
                          {
                            this.props.stateData.photoCaptureArr.length>0 ?
                            (
                              <>
                                <Row>
                                  <Col sm={12} className="mt-2">
                                  <h6>Capture Live Photos : </h6>
                                      
                                  </Col>
                                  <Col sm={12} className="">
                                      <div className='flex_img'>
                                          {(this.props.stateData.photoCaptureArr || []).map((url,index) => (
                                              <div className='ms-1 border_img_pad'>
                                                <i class="fa-solid fa-circle-xmark" onClick={() => this.props.removeCapturePhotoFromBSBG(url.fileId)}></i>
                                                <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                              </div>
                                          ))} 
                                      </div>
                                  </Col>
                              </Row>
                              </>
                              
                              
                            )
                            :
                            ("")
                          }

                          </>
                        
                        )}
                        </div>
                      </Col>
                        </Row>
                  <Row>
                        <Col>
                            <h6 className='color_red f-12'>{this.props.stateData.validateCaptureMobileNoInAbove}</h6>
                            <h6 className='color_red f-12'>{this.props.stateData.validateCaptureOTPInAbove}</h6>
                            <h6 className='color_red f-12'>{this.props.stateData.validateCapturePhoto}</h6>
                        </Col>
                  </Row>*/}

                        <Row>
                            <Col sm={12}>
                            
                                <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.props.stateData.mobileNoDisabledShak} onClick={() => this.props.saveCampaignEnrollment()}>SUBMIT </Button>
                                
                                {
                                    this.props.stateData.savaCampaignLoadingSymbol == "show" ? 
                                    (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                    :
                                    ("")
                                }
                                <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.props.stateData.savaCampaignLoadingSuccessMsg}</h6>
                            </Col>
                        </Row>
                  
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default HouseVisitedImageCapture;
  

