
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button  } from 'react-bootstrap';
import "./EventsAndActivities.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import ShowAllPhotos from './ShowAllPhotos';

class EventPhotosSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ShowAllPhotosModal:false
        }
    }

   
    showAllPhotosBlock = () => {
        this.setState({ShowAllPhotosModal:true});
    }

  
    render() {
        return (
            <>
            <Row>
                <Col sm={12} className="mt-3">
                    <h6 className='f-14  fw-bold mb-0'>FEEDBACK FORMS {this.props.stateData.feedBackFormsList.length == 0 ? "" : <b className='event_headings_color'> : {this.props.stateData.feedBackFormsList.length}</b>}</h6>
                        {
                            this.props.stateData.feedBackFormsList.length == 0 ?
                            (
                                this.props.stateData.feedBackFormsCheck == 1 ?
                                    (<h6 className='f-14 text-center mt-2'>No Feedback Forms Yet For This Program...</h6>)
                                    :
                                    (
                                    <Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>
                                    )
                                
                            )
                            :
                            (
                                this.props.stateData.feedBackFormsList.length>0 ?
                                (

                                    <Col sm={12}>
                                        <div className='photos_gallery_flex'>
                                            {
                                                this.props.stateData.feedBackFormsList.slice(0, 3).map((item,index) => {
                                                return(
                                                        <div >
                                                            {
                                                                item.fileType == "pdf" ?
                                                                (<object height="60" width="100" type="application/pdf" data={item.filePath} className="border_black"></object>)
                                                                :
                                                                (<Image src={item.filePath} alt={item.fileName} onClick={() => this.showAllPhotosBlock()} className="width_height_photo cursor_pointer"></Image>)
                                                            }
                                                            
                                                        </div>
                                                )
                                                })
                                                
                                            }
                                        </div>
                                        {
                                            this.props.stateData.feedBackFormsList.length>3 ?
                                            (<div className='mt-1 f-14 fw-bold cursor_pointer float-end' onClick={() => this.showAllPhotosBlock()}>View More...</div>)
                                            :
                                            ("")
                                        }
                                    </Col>

                                )
                                :
                                ("")
                            )
                        }

    
                </Col>
            </Row>
            {
                this.props.stateData.feedBackFormsList.length>0 ?
                (
                    <ShowAllPhotos 
                        show={this.state.ShowAllPhotosModal}
                        onHide={() => this.setState({ ShowAllPhotosModal: false })}
                        getAllPhotosList={this.props.stateData.feedBackFormsList}
                        modalTitle="FEED BACK FORMS"
                    />
                )
                :
                ("")
            }
            
            </>
        )
    }
  }
  
  export default withRouter(EventPhotosSection);
  