import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "./datatable.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

class CardLessDataTableSearchFilterLimit10 extends Component {
  constructor() {
    super();
  }

  render() {
    console.log("Table Props", this.props);

    //  console.log("this.props.particlecolumns", this.props.particlecolumns);
    // console.log("articlecolumns", articlecolumns);
    const customTotal = (from, to, size) => (
      <span className="text-right react-bootstrap-table-pagination-total">
        
      </span>
    );
    const { SearchBar } = Search;

    const options = {
      paginationSize: 5,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "First",
      prePageText: "Previous",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
       
        {
          text: "25",
          value: 25
        },
        {
          text: "50",
          value: 50
        },
        {
          text: "100",
          value: 100
        },
        {
          text: "150",
          value: 150
        },
        
        {
          text: "All",
          value: this.props.parentData.length
        }
      ],
      onSizePerPageChange: this.onPageSizeChange,
      sizePerPage: 10 // A numeric array is also available. the purpose of above example is custom the text
    };

    const contentTable = ({ paginationTableProps }) => (
      <ToolkitProvider
        keyField="id"
        data={this.props.parentData}
        columns={this.props.particlecolumns}
        defaultSorted={this.props.defaultSorted}
         search
        bootstrap4
      >
        {toolkitprops => {
          console.log("Table", toolkitprops);

          return (
            
            <div>
              <div className="row">
                <div className="col-sm-12">
                  <SearchBar {...toolkitprops.searchProps} placeholder="Search" className="float_right" />
                </div>
              </div>
              {
                this.props.tableHeading != "" ?
                (
                  <div className="col-sm-12">
                    <h6 className="fw-bold f-14 mt-3 mb-2">{this.props.tableHeading}</h6>
                  </div>
                )
                :
                ("")
              }
              
              <div className="col-sm-12 mt-2 pagination_css">
                <BootstrapTable
                    defaultSorted={this.props.defaultSorted}
                    wrapperClasses="table-responsive table_custom"
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                  />
              </div>
            </div>
          );
        }}
      </ToolkitProvider>
    );

    return (
      <PaginationProvider pagination={paginationFactory(options)}>
        {contentTable}
      </PaginationProvider>
    );
  }
}

export default CardLessDataTableSearchFilterLimit10;
