import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,Card } from "react-bootstrap";
import loader from '../../images/loader.gif';


class EventAttendamceModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="z-index" >
                 <Modal.Header closeButton className='p-2'>
                    <h6 className='fw-bold text-center ln-28 center-auto'><span className='color_red f-18'>యువగళం - నవశకం</span><br/><span className=''>బహిరంగ సభకు ఆహ్వానం </span></h6>
                </Modal.Header>
                <Modal.Body className="p-1">
                <Row>
                        <Col>
                            <Card className=''>
                                <Card.Body className='p-2'>
                                    
                                    <Card.Text>
                                        <p className='mt-2 f-16 ln-28 fw-bold text-center'>
                                        ఈ బహిరంగ సభకు హాజరు అయినా ఎదల  <br/><span className='f-30 color_red pos_bo'>మీ హాజరు నమోదు చేయవలెను</span>
                                        </p>
                                        {/*<p className='fw-bold f-14'>ప్రియమైన మీకు,</p>
                                        
                                        {/*<p className='mt-2 f-14 ln-25 text-justify'>
                                            యువగళం - నవశకం.. <b>226</b> రోజులు, <b>97</b> నియోజకవర్గాలు, <b>2100</b> పై చిలుకు గ్రామాల్లో నిర్విరామంగా  యువగళం పాదయాత్ర ముగింపు సభ <b>తేదీ : డిసెంబర్ 20, సమయం: మధ్యాహ్నం 2 గంటల</b> నుండి విజయనగరం జిల్లా పోలిపల్లి <br/><a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=17.968477,83.429794`}><span className='fw-bold color_red text-decoration-underline' style={{textAlign:"left",marginLeft:"0px"}} > సభ జరుగు స్థలం ఇక్కడ క్లిక్ చేయండి <i class="fa-solid fa-arrow-right ms-1"></i></span></a>&nbsp;&nbsp; లో జరగనుంది. ఈ కార్యక్రమానికి హాజరై సభను విజయవంతం చేయాలని మిమ్మల్ని  ఆహ్వానిస్తునాము.
                                        </p>
                                        <p className='mt-2 f-14 ln-25 text-justify'>
                                            ప్రతి బూత్ , యూనిట్ , క్లస్టర్ ఇంచార్జిలు మరియు తెలుగుదేశం పార్టీ నాయకులు ఈ సభకు విచ్చేసి జయప్రదం చేయగలరు. మీతోపాటు కుటుంబ సాధికారిక సారధులను కూడా భాగస్వామ్యులను చేయండి. ఈ దశాబ్దపు అతిపెద్ద రాజకీయ వేడుకలో భాగస్వాములవ్వండి.
                                        </p>
                                        <p className='mt-1 f-14 fw-bold'>
                                            ఇట్లు,<br/>
                                            తెలుగుదేశం పార్టీ.
                                        </p>*/}
                                        {/*<p className='text-center f-14'>
                                        ముఖ్య గమనిక : బాట్ నందు ఆరోజు మీ హాజరుని క్రింద కల్పించిన లింక్ ద్వారా నమోదు చేసుకొనగలరు
                                    </p>*/}
                                    </Card.Text>
                                    <Card.Text>
                                        <Row>
                                            <Col className='text_decoration_none_a'>
                                                <Button variant="danger" className='f-14 mt-4 fw-bold float-end' onClick={() => this.props.getCurrentLocation("location")} >మీ హాజరు నమోదు <i class="fa-solid fa-arrow-right ms-1"></i>
                                                {
                                                    this.props.stateData.useCurrentLocationSymbolD == "show" ?
                                                    (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                                    :
                                                    ("") 
                                                }
                                              </Button> 
                                                
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default EventAttendamceModal;
  

