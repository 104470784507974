
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import "./VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import MF_logo1 from '../../images/MF_logo1.png';
import SelectedUserDetailsForEnroll from './SelectedUserDetailsForEnroll';
import MS_logo from '../../images/MahaShakti/MS_logo1.png';
import AD_logo from '../../images/AnnaData/AD_logo1.png';
import BC_logo from '../../images/BC/BC_logo1.png';
import HW_logo from '../../images/HouseWater/HW_logo1.png';
import PTR_logo from '../../images/PoorToRich/PTR_logo1.png';
import YG_logo from '../../images/Yuvagalam/YG_logo1.png';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import BGLOGO from '../../images/BG_logo.png';

class EnrollmentsSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enrollmentsListArr:[],
            enrollListCheck:0,
            enrollUserDetailsViewModal:false,
            selectedUserViewId:"",
            filterBlockOpen:true,
            interestName:"ALL Interests",
            interestId:"0"
        }
    }

    componentDidMount() {
        this.setState({ enrollmentsListArr:[],enrollListCheck:0 });
        fetch('/bot_api/v1/enrollments', {
            method: "GET", 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "test_chat_id" :"5129193582",
            },
          })
          .then((res) => res.json())
          .then((json) => {
              if(json !=null && json.length>0){
                this.setState({ enrollmentsListArr:json,enrollListCheck:0 });
              }else{
                this.setState({ enrollmentsListArr:[],enrollListCheck:1 });
              }
          });
    }

    enrollUserViewDtClick = (selectedData) => {
        this.setState({enrollUserDetailsViewModal:true,selectedUserViewId:selectedData});
    }

    isFilterBlockOpen = () => {
        this.setState({filterBlockOpen:!this.state.filterBlockOpen });
      }
   
    
      checkInterestValueEn = (id,name) => {
        this.setState({interestName:name,interestId:id });
      }
  
      enrollDisplayColumns() {

        let columns = [
            
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                headerStyle: {
                    width: "35%"
                }
                
            },
            {
                dataField: "Age",
                text: "Age",
                sort: true,
                
            },

            {
                dataField: "Gender",
                text: "Gender",
                sort: true,
               
            },
            
            
            {
                dataField: "Actions",
                text: "Actions",
                //sort: true,
                formatter: this.actionsFormatterForEnroll.bind(this),
                
            },
           
        ];
        return columns;
    }

    actionsFormatterForEnroll = (cell, row, rowIndex, formatExtraData) => {
        const selectedData = this.state.enrollmentsListArr.filter((item,index) => item.id == row.id);
        return (
            <span className='view_enroll_css fw-bold' onClick={() => this.enrollUserViewDtClick(selectedData)}>View</span>
              
         );
    }

    enrollDisplayData = () => {
        let votersData = [];
        if(this.state.enrollmentsListArr.length>0){
            for (let i = 0; i < this.state.enrollmentsListArr.length; i++) {
                const obj = {
                    Name: this.state.enrollmentsListArr[i].name,
                    Age: this.state.enrollmentsListArr[i].age,
                    Gender: this.state.enrollmentsListArr[i].gender,
                    id:this.state.enrollmentsListArr[i].id,
                    Actions:"View"
                }
                votersData.push(obj);
            } 

            
            
            return votersData;
        }
    }
    render() {
       
        return (
            <section>
               <div className="pos_relative">
                    <div class="text-white">
                    <img  class="bg_css_volunter_logo" />
                    <div class="card-img-overlay">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="flex-header mt-1">
                                <img className="center-auto w-80P" src={BGLOGO} />
                                
                                </div>
                            </div>
                        </div>
                        <Link to={{pathname: "/dashboard"}}> 
                            <Button variant="outline-light ms-3 mt-3 pad5_14 back_button" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Link>
                    </div>
                    </div>
                </div>
                
                <Container>
                    <Row>
                        {/*
                        <Col sm={12}>
                            <h6 className='mt-3 float-right cursor_pointer' onClick={() => this.isFilterBlockOpen()}>Search By Interests <i class="fa-solid fa-filter f-18" ></i></h6>
                        </Col>
                        {
                              this.state.filterBlockOpen == false ? 
                              (
                                <Col>
                                        <label className="form-label f-14 fw-bold  mt-2">Interests : </label>
                                        <Form>
                                            <Form.Check
                                                    inline
                                                    label="ALL"
                                                    name="interestsId"
                                                    type="radio"
                                                    id="all_id"
                                                    defaultChecked={true}
                                                    onChange={()=>this.checkInterestValueEn("0","")}
                                                    className="f-16 fw-bold color_black"
                                                />
                                        </Form>
                                        <Form className="check_box_size text-center">
                                            <Form.Check
                                                inline
                                                label={<img className="center-auto w_90_R" src={MS_logo} />}
                                                name="interestsId"
                                                type="radio"
                                                id="maha_shakti_id"
                                                onChange={()=>this.checkInterestValueEn("1","Maha Shakti")}
                                                className="f-12 fw-bold color_black"
                                            />
                                            <Form.Check
                                                inline
                                                label={<img className="center-auto w_90_R" src={HW_logo} />}
                                                name="interestsId"
                                                type="radio"
                                                id="house_water_id"
                                                onChange={()=>this.checkInterestValueEn("4","Intintiki water")}
                                                className="f-12 fw-bold color_black"
                                            />
                                            
                                            <Form.Check
                                                inline
                                                label={<img className="center-auto w_60_R" src={YG_logo} />}
                                                name="interestsId"
                                                type="radio"
                                                id="yuva_galam_id"
                                                onChange={()=>this.checkInterestValueEn("2","Yuva Galam")}
                                                className="f-12 fw-bold color_black"
                                            />
                                            <Form.Check
                                                inline
                                                label={<img className="center-auto w_60_R" src={AD_logo} />}
                                                name="interestsId"
                                                type="radio"
                                                id="ad_id"
                                                onChange={()=>this.checkInterestValueEn("3","Rythu Nestam")}
                                                className="f-12 fw-bold color_black"
                                            />
                                            
                                            <Form.Check
                                                inline
                                                label={<img className="center-auto w_90_R" src={PTR_logo} />}
                                                name="interestsId"
                                                type="radio"
                                                id="ptr_id"
                                                onChange={()=>this.checkInterestValueEn("5","Poot To Rich")}
                                                className="f-12 fw-bold color_black"
                                            />
                                            <Form.Check
                                                inline
                                                label={<img className="center-auto w_90_R" src={BC_logo} />}
                                                name="interestsId"
                                                type="radio"
                                                id="bc_id"
                                                onChange={()=>this.checkInterestValueEn("6","BC Protection Law")}
                                                className="f-12 fw-bold color_black"
                                            />
                                    </Form>
                                    </Col>
                              )
                              :
                              ("")
                        }*/}
                        <Col sm={12}>
                        
                        <div className='bg_light_blue mt-3'>
                            <h6 className='f-14'>Enrollments Details </h6>
                            {
                                this.state.enrollmentsListArr.length == 0 ?
                                (
                                    <Row>
                                        <Col sm={12}>
                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                                }
                                {
                                    this.state.enrollmentsListArr.length>0 ?
                                    (
                                      

                                    <>
                                    <CardLessDataTableWithSearch 
                                            parentData={this.enrollDisplayData()}
                                            particlecolumns={this.enrollDisplayColumns()}
                                            defaultSorted={[{
                                                dataField: "Serial_No",
                                                order: "desc"
                                            }]}
                                            tableHeading=""
                                            />
                                    </>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            this.state.enrollListCheck == 1 ?
                                            (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                            :
                                            ("")
                                        }
                                      </>
                                      )
                                }
                                
                        </div>
                        
                        </Col>
                    </Row>

                    <SelectedUserDetailsForEnroll 
                        show={this.state.enrollUserDetailsViewModal}
                        onHide={() => this.setState({ enrollUserDetailsViewModal: false })}
                        stateData = {this.state}
                    />
                    
                </Container>
            
            </section>
        )
    }
  }
  
  export default withRouter(EnrollmentsSection);
  