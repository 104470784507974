import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import {Container, Row, Col,Image,Button,Card,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "../ElectionActivities/electionActivities.css";
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import ViewBoothLocationStatus from './ViewBoothLocationStatus';
import UpdateBoothLocation from "./UpdateBoothLocation";
import EnabledLocationPopup from "../Volunteer/EnabledLocationPopup"

class BoothLocationMappingSection extends Component {
  constructor(props) {
    super(props)

      this.state = {
        webcamRef : React.createRef(),
        chatID: window.User !=undefined ? window.User.chatID : "943827154",
        userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
        
        boothListArr:[],
        boothWiseLocationMappArr:null,
        viewBoothLocationStatusModal:false,
        selectdBoothLocationData:{},

        updateBoothLocationStatusModal:false,
        selectdBoothIdObj:{},
        useCurrentBoothLocationSymbol:"hide",
        validateBoothLocations:"",

        getCurrentLocationCoordinatesObj:{},
        checkingEnabledLocationModal:false,

        camaraRadioButtonForBoothLoc:false,
        uploadImageRadioButtonForBoothLoc:true,
        selectedTypePhotoForBoothLoc:"upload",
        validatePhotoCaptureForBoothLoc:"",
        showBoothLocImgCapture:false,
        manualUploadImageForBoothLoc:"",
        photoCaptureForBoothLocArr:[],
        submitButtonDisabledForBoothLoc:false,
        saveBoothLocationDetailsLoadingSymbol:"hide",
        saveBoothLocationMessage:""
        
      }

      
  }


  componentDidMount() {

    if(this.state.userAccessList == "" ){
        this.props.history.push("/dashboard");
    }else{

        if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
            let boothIdsArr=[];
            this.state.userAccessList.boothList.map(item => {
                boothIdsArr.push(item.id);
                
            });
            this.setState({ boothListArr: boothIdsArr });
            this.getBoothLocationUpdateStatus(boothIdsArr); 
            
        }
    }
  }





  getBoothLocationUpdateStatus = (boothIdsArr) => {
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "boothIdsList":boothIdsArr
        }); 

        getAPICallData(obj,"getBoothLocationUpdateStatus")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){
                this.setState({boothWiseLocationMappArr:json});
            }else{
                this.setState({boothWiseLocationMappArr:[]});
            }
        });
    }

    columnsBoothLocMap() {
        let columns = [
            
            {
                dataField: "Booth",
                text: "Booth",
                sort: true,
                
            },
            {
                dataField: "Status",
                text: "Status",
                sort: true,
                formatter: this.actionsFormatterForStatusColorChange.bind(this),
                 
            },
           
            {
                dataField: "Action",
                text: "Action",
                formatter: this.actionsFormatterForViewUpdate.bind(this),
            }
        
        ];
        return columns;
    }

    parentDataBoothLocMap = () =>{
        let dataArr = [];
            if(this.state.boothWiseLocationMappArr.length>0){
                
                for (let i = 0; i < this.state.boothWiseLocationMappArr.length; i++) {
    
                    const boothId = (this.state.boothWiseLocationMappArr[i].boothId);
                    const Booth = (this.state.boothWiseLocationMappArr[i].partNo);
                    const Status = (this.state.boothWiseLocationMappArr[i].status);
                    const boothLocationId = (this.state.boothWiseLocationMappArr[i].boothLocationId);
                    const mandalTown=this.state.boothWiseLocationMappArr[i].mandalTown;
                    const panchayat=this.state.boothWiseLocationMappArr[i].panchayat;
                    const latitude=this.state.boothWiseLocationMappArr[i].latitude;
                    const longitude=this.state.boothWiseLocationMappArr[i].longitude;
                    const locationName=this.state.boothWiseLocationMappArr[i].locationName;
                    const villagesCovered=this.state.boothWiseLocationMappArr[i].villagesCovered;
                    const imgList=this.state.boothWiseLocationMappArr[i].imgList;
                   
                    
                    const obj = {
                        boothId:(boothId),
                        Booth:Booth,
                        Status: Status,
                        boothLocationId: boothLocationId,
                        mandalTown:mandalTown,
                        panchayat:panchayat,
                        latitude:latitude,
                        longitude:longitude,
                        locationName:locationName== "" ? "N/A" : locationName,
                        villagesCovered:villagesCovered == "" ? "N/A" : villagesCovered,
                        imgList:imgList
                        
                    }
                    dataArr.push(obj);
                } 
    
                
                
                return dataArr;
            }
    }

actionsFormatterForStatusColorChange = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            {
                row.Status == "Pending" ?
                (<h6  className="color_red f-12">{row.Status}</h6>)
                :
                (<h6  className="color_green f-12">{row.Status}</h6>)
            }
        </>
    )
}
actionsFormatterForViewUpdate = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <>
           <Row>
                <Col>
                    <div className='flex_block_evenly'>
                        <OverlayTrigger key={"top"} placement={"top"}
                            overlay={
                                <Tooltip id={`tooltio-unlink`}>
                                <h6 className='f-14'>View Details</h6>
                                </Tooltip>
                            }
                        >
                        <Button type="button" className="btn-custom-icon" onClick={() => this.showBoothStatusDetails(row)}>
                            <i className="fa-solid fa-eye f-14 color_black"></i>  
                        </Button>
                        </OverlayTrigger>

                        <OverlayTrigger key={"top"} placement={"top"}
                            overlay={
                                <Tooltip id={`tooltio-link`}>
                                <h6 className='f-14'>Update</h6>
                                </Tooltip>
                            }
                        >
                        <Button type="button" className='btn-custom-icon' onClick={() => this.updateBoothStatusDetails(row)}>
                            <i className= "fa-solid fa-pen-to-square f-14 color_black"></i> 
                        </Button>

                        </OverlayTrigger>
                        </div>
                    </Col>
                </Row>
            
        </>
        
     );
    
}

showBoothStatusDetails = (row) => {
    console.log(row)
    this.setState({viewBoothLocationStatusModal:true,selectdBoothLocationData:row})
}

updateBoothStatusDetails = (row) => {
    this.setState({
        useCurrentBoothLocationSymbol:"hide",
        validateBoothLocations:"",

        getCurrentLocationCoordinatesObj:{},

        camaraRadioButtonForBoothLoc:false,
        uploadImageRadioButtonForBoothLoc:true,
        selectedTypePhotoForBoothLoc:"upload",
        validatePhotoCaptureForBoothLoc:"",
        showBoothLocImgCapture:false,
        manualUploadImageForBoothLoc:"",
        photoCaptureForBoothLocArr:[],
        submitButtonDisabledForBoothLoc:false,
        saveBoothLocationDetailsLoadingSymbol:"hide",
        saveBoothLocationMessage:""
    })
    this.setState({updateBoothLocationStatusModal:true,selectdBoothIdObj:row})
}


getCurrentBoothLocation = () => {
    this.setState({validateBoothLocations:""})
    if(this.state.chatID != "1048868711"){
        if(navigator.geolocation){
            this.setState({useCurrentBoothLocationSymbol:"show"})
            navigator.geolocation.getCurrentPosition(this.getCurrentLocationCoordinates,this.checkingLocationServiceEnabledError)
        }
    }
   
}


getCurrentLocationCoordinates = (position) => {
    const getCoordinates =  
        { 
        "latitude" : position.coords.latitude,
        "longitude" : position.coords.longitude,
        "accuracy":position.coords.accuracy
        }
        
    this.setState({getCurrentLocationCoordinatesObj:getCoordinates});
    this.setState({useCurrentBoothLocationSymbol:"hide"})
    
}

checkingLocationServiceEnabledError = (error) => {
    return(
      <EnabledLocationPopup 
              show={() => this.setState({ checkingEnabledLocationModal: true })}
              onHide={() => this.setState({ checkingEnabledLocationModal: false })}
              closeEnabledModal={this.closeEnabledModal}
       />
    )
      
  }

  closeEnabledModal = () => {
    this.props.history.push({
        pathname: "/booth_location_mapping",
        params: {
            navbarDisplay:"show",
            userAccessList:this.state.userAccessList
        
        },
    });
    
}


selectCaptureTypeForBoothLoc = (type) => {
    this.setState({photoCaptureForBoothLocArr:[],manualUploadImageForBoothLoc:"",validatePhotoCaptureForBoothLoc:""});
   
        
    if(type == "upload"){
      this.setState({ camaraRadioButtonForBoothLoc:false,uploadImageRadioButtonForBoothLoc:true});
      this.setState({showBoothLocImgCapture:false});
    }else{
      this.setState({showBoothLocImgCapture:true})
      this.setState({ camaraRadioButtonForBoothLoc:true,uploadImageRadioButtonForBoothLoc:false});
    }
    

    this.setState({selectedTypePhotoForBoothLoc:type});
  }

  toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

    uploadMultipleFilesForBoothLocPhotos = (e) => {
    this.setState({manualUploadImageForBoothLoc:""});
    const getUID = (length=8) => {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

    let fileObj=[];
      fileObj.push(e.target.files);
          for (let i = 0; i < fileObj[0].length; i++) {
            if(fileObj[0][i].type == "image/jpeg" || fileObj[0][i].type == "image/png" || fileObj[0][i].type == "image/jpg"){
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    var obj ={
                        fileId:getUID(),
                        fileType:fileObj[0][i].type,
                        data:dataUrl,
                    }
                    this.setState({photoCaptureForBoothLocArr:[...this.state.photoCaptureForBoothLocArr, obj]});
                    //this.setState({submitButtonDisabledForBoothLoc:false});
                });
            }else{
                this.setState({manualUploadImageForBoothLoc:"Please Upload Only JPG/PNG/JPEG Files..."});
                return;
            }

              
          }

          
  }

  removeCapturePhotoFromBoothLoc = (fileId) => {
    var tempArr = this.state.photoCaptureForBoothLocArr;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);
  
    if (objWithIdIndex > -1) {
      tempArr.splice(objWithIdIndex, 1);
    }
  
    this.setState({photoCaptureForBoothLocArr:tempArr})
  
  }

  
  captureForBoothLocImageUpload = () => {
    
    this.setState({showBoothLocImgCapture:true,validatePhotoCaptureForBoothLoc:""});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        data : this.state.webcamRef.current.getScreenshot(),
      }

     // this.setState({imageAddedMsgForActivity:"Capture Photo added successfully in below..."});
      setTimeout(() => {
        this.setState({photoCaptureForBoothLocArr:[...this.state.photoCaptureForBoothLocArr, obj]}); 
        this.setState({validatePhotoCaptureForBoothLoc:""});
    }, 1000);
    //this.setState({submitButtonDisabledForBoothLoc:false});
}


saveBoothLocationDetails = () => {
    this.setState({validatePhotoCaptureForBoothLoc:"",manualUploadImageForBoothLoc:"",validateBoothLocations:""});
    
    if(Object.keys(this.state.getCurrentLocationCoordinatesObj).length == 0){
        this.setState({validateBoothLocations:"Please Update Location..."});
            return;
    }

    if(this.state.selectedTypePhotoForBoothLoc == "camara"){
        if(this.state.photoCaptureForBoothLocArr.length == 0){
            this.setState({validatePhotoCaptureForBoothLoc:"Please Take A Photo Atleast One..."});
            return;
        }
    }else if(this.state.selectedTypePhotoForBoothLoc == "upload"){
        if(this.state.photoCaptureForBoothLocArr.length == 0){
            this.setState({manualUploadImageForBoothLoc:"Please Upload Atleast One..."});
            return;
        }
    }
    
    this.saveBoothLocationDetailsCall();
    this.setState({saveBoothLocationDetailsLoadingSymbol:"show"});  

    
}



saveBoothLocationDetailsCall = () => {
    this.setState({submitButtonDisabledForBoothLoc:true});
    var base64ImgListArr=[];

    if(this.state.photoCaptureForBoothLocArr.length > 0){
        for(var i in this.state.photoCaptureForBoothLocArr){
            if(this.state.selectedTypePhotoForBoothLoc == "upload"){
                base64ImgListArr.push(this.state.photoCaptureForBoothLocArr[i].data.replace("data:"+this.state.photoCaptureForBoothLocArr[i].fileType+";base64,",""));
            }else{
                base64ImgListArr.push(this.state.photoCaptureForBoothLocArr[i].data.replace("data:image/jpeg;base64,",""));
            }
            
        }
    }

    setTimeout(() => {
    const obj =  
    JSON.stringify({ 
        "chatId" :this.state.chatID,
        "boothId":this.state.selectdBoothIdObj.boothId,
        "latitude":this.state.getCurrentLocationCoordinatesObj.latitude,
        "longitude":this.state.getCurrentLocationCoordinatesObj.longitude,
        "base64StrList" : base64ImgListArr
    });
    
    

    getAPICallData(obj,"saveBoothLocationDetails")
    .then((res) => res.json())
    .then((content) => {
        this.setState({saveBoothLocationDetailsLoadingSymbol:"hide"});  

        if(content !=null && content.status == "Success"){
            this.setState({saveBoothLocationMessage:"Submitted SuccessFully..."});
             setTimeout(() => {
                this.setState({submitButtonDisabledForBoothLoc:false});
                this.setState({saveBoothLocationMessage:""});
                this.setState({photoCaptureForBoothLocArr:[],showBoothLocImgCapture:true,updateBoothLocationStatusModal:false});
                this.getBoothLocationUpdateStatus(this.state.boothListArr); 
                 
            }, 1500);

        }else if(content !=null && content.status == "Fail"){
            this.setState({saveBoothLocationMessage:content.message});
    
        }else{
            this.setState({saveBoothLocationMessage:"Some Thing Went Wrong,Please Try Again..."});
    
        }
    });
 }, 1200);   
}


  render() {
    return (
      <section>
        <Container fluid>
                <Row>
                    <Col>
                        <h6 className="f-14 fw-bold text-center"> Booth Location Mapping</h6>
                    </Col>
                </Row>
               
                {
                    this.state.boothWiseLocationMappArr == null ?
                    (
                        <Row>
                            <Col sm={12}>
                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                            </Col>
                        </Row>
                    )
                    :
                    (
                        this.state.boothWiseLocationMappArr.length == 0 ? 
                        (
                            <Row>
                                <Col>
                                    <h6 className='f-14 text-center mt-4'></h6>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <>
                            {
                                this.state.boothWiseLocationMappArr !=null &&  this.state.boothWiseLocationMappArr.length > 0 ?
                                (
                                    <Row>
                                        <Col>
                                            <div className='p-5222'>
                                                <CardLessDataTableWithSearch 
                                                    parentData={this.parentDataBoothLocMap()}
                                                    particlecolumns={this.columnsBoothLocMap()}
                                                    defaultSorted={[{
                                                        dataField: "",
                                                        order: "desc"
                                                    }]}
                                                    tableHeading=""
                                                    />
                                            </div>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }
                            </>
                        )
                    )
                }

                <ViewBoothLocationStatus 
                        show={this.state.viewBoothLocationStatusModal}
                        onHide={() => this.setState({ viewBoothLocationStatusModal: false })}
                        stateData={this.state}
                />
                <UpdateBoothLocation
                        show={this.state.updateBoothLocationStatusModal}
                        onHide={() => this.setState({ updateBoothLocationStatusModal: false })}
                        stateData={this.state}
                        getCurrentBoothLocation={this.getCurrentBoothLocation}
                        selectCaptureTypeForBoothLoc={this.selectCaptureTypeForBoothLoc}
                        uploadMultipleFilesForBoothLocPhotos={this.uploadMultipleFilesForBoothLocPhotos}
                        captureForBoothLocImageUpload={this.captureForBoothLocImageUpload}
                        removeCapturePhotoFromBoothLoc={this.removeCapturePhotoFromBoothLoc}
                        saveBoothLocationDetails={this.saveBoothLocationDetails}
                />
                
                    
        </Container>
        
        
    </section>
    )
  }
}

export default withRouter(BoothLocationMappingSection);
