import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import {Container, Row, Col,Image,Button,Card,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "../ElectionCampaign/electionCampaign.css";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class PollManagementPanchayatElection extends Component {
  constructor(props) {
    super(props)

      this.state = {
        chatID: window.User !=undefined ? window.User.chatID : "943827154",
        activePollsArr:[],
        validateElectionType:"",
        electionValue:"",
        electionTypeId:"",
        electionTypeName:"",

        accessPollBoothsArr:[],
        accessPollBoothsCheck:0,

        validatePollBooth:"",
        pollBoothValue:"",
        pollBoothId:"",

        party_ids_Arr:[{'value':0,'label':'All'},{'value':872,'label':'TDP'},{'value':1117,'label':'YSRC'},{'value':1892,'label':'JSP'},{'value':1887,'label':'Others'}],
        partyIdStr:0,
        partyIdStrValue:{'value':0,'label':'All'},
        validatePartyIdStr:"",

        isPolled:'',
        selectedPollVoterId:"",

        votersInABoothForPollArr:[],
        votersInABoothForPollCheck:0,
        votersInABoothForPollSymbol:"hide",

        successMessage:"",
        successSymbol:"hide"
       
      }
  }


  componentDidMount() {
        this.getActivePolls();
  }

  getActivePolls = () => {
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID
        }); 

        getAPICallData(obj,"getActivePolls")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){
                var arr=[];
                for(var i in  json){
                    var obj={'value':json[i].id,'label':json[i].name};
                    arr.push(obj)
                    
                }
                this.setState({ activePollsArr:arr});
            }else{
                 this.setState({activePollsArr:[]});
            }
          
        });
}

handleOnchangeSelectBox = (ename, evalue) =>{
    const name = ename;
    const value = evalue;

    if(name == "electionTypeId"){
      let getElecIdName = this.state.activePollsArr.find((a) => a.value == value);
        this.setState({
            electionValue: {
              value: getElecIdName.value,
              label: getElecIdName.label
          },electionTypeName:getElecIdName.label
      });

      this.getUserAccessPollBooths(value);
    }else if(name == "pollBoothId"){
        let getPollBoothIdName = this.state.accessPollBoothsArr.find((a) => a.value == value);
        this.setState({
            pollBoothValue: {
              value: getPollBoothIdName.value,
              label: getPollBoothIdName.label
          }
      });
    }else if(name == "partyIdStr"){
        let getPartyIdName = this.state.party_ids_Arr.find((a) => a.value == value);
        this.setState({
            partyIdStrValue: {
              value: getPartyIdName.value,
              label: getPartyIdName.label
          }
      });
    }

    this.setState({ [name]: value});
  }


  getUserAccessPollBooths = (pollId) => {
    this.setState({ accessPollBoothsArr:[],accessPollBoothsCheck:0});
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "pollId" : pollId
        }); 

        getAPICallData(obj,"getUserAccessPollBooths")
        .then((res) => res.json())
        .then((json) => {
            if(json !=null && json.length>0){
                var arr=[];
                for(var i in  json){
                    var obj={'value':json[i].id,'label':json[i].name};
                    arr.push(obj)
                    
                }
                this.setState({ accessPollBoothsArr:arr,accessPollBoothsCheck:0});
            }else{
                 this.setState({accessPollBoothsArr:[],accessPollBoothsCheck:1});
            }
          
        });
}
checkParameterValueForPoll = (e,isPolled) => {
    this.setState({isPolled:isPolled });
}

getVotersInABoothForPoll = () => {
    this.setState({validatePollBooth:""});
    if(this.state.pollBoothId == "" ){
        this.setState({validatePollBooth:"Please Select Booth" });
        return;
    }
    

    this.setState({votersInABoothForPollArr:[],votersInABoothForPollCheck:0,votersInABoothForPollSymbol:"show"});
    

    const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "pollId" : this.state.electionTypeId,
                "boothId" : this.state.pollBoothId,
                "isPolled" : this.state.isPolled,
                "partyId" : this.state.partyIdStr
            });
        
        

        getAPICallData(obj,"getVotersInABoothForPoll")
            .then((res) => res.json())
            .then((content) => {
              this.setState({votersInABoothForPollSymbol:"hide"});  
                if(content !=null && content.length>0){
                    
                    this.setState({votersInABoothForPollArr:content,votersInABoothForPollCheck:0});
                }else{
                    this.setState({votersInABoothForPollArr:[],votersInABoothForPollCheck:1});
                }
            });
}




pollManagementDisplayColumns() {
    let columns = [
        
        {
            dataField: "serialNo",
            text: "Serial No",
            sort: true,
            headerStyle: {
                width: "10%"
            }
        },
        {
            dataField: "voterName",
            text: "voter Name",
            formatter: this.actionsFormatterForVoterNamePoll.bind(this),
            headerStyle: {
                width: "40%"
            }
            
            
        },
        {
            dataField: "Age",
            text: "A",
            sort: true,
            formatter: this.actionsFormatterForAgePoll.bind(this),
            headerStyle: {
                width: "10%"
            }
            
        },
        {
            dataField: "Party",
            text: "P",
            sort: true,
            formatter: this.actionsFormatterForPollManParty.bind(this),
            headerStyle: {
                width: "10%"
            }
            
        },
       
        {
            dataField: "Polled",
            text: "Poll",
            sort: true,
            formatter: this.actionsFormatterForPollManUpdate.bind(this),
            headerStyle: {
                width: "15%"
            }
        }
       
    
    ];
    return columns;
}

actionsFormatterForPollManParty = (cell, row, rowIndex, formatExtraData) => {
    return (
        
            row.Party == "JANASENA " ?
            (<span style={{textAlign:"center",marginLeft:"0px"}}>JSP </span>)
            :
            (<span style={{textAlign:"center",marginLeft:"0px"}}>{(row.Party)} </span>)
    )
}
actionsFormatterForAgePoll = (cell, row, rowIndex, formatExtraData) => {
    return (row.voterCardNo)
}

actionsFormatterForVoterNamePoll = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.voterName)}  
            <><br/><span className='f-10 color_green' style={{textAlign:"left",marginLeft:"0px"}}><i className="fa-solid fa-phone"></i> - <a  style={{color:"green"}} href={`tel:${row.mobileNo}`}> {row.mobileNo}</a> </span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.Age)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>H - {(row.houseNo)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>RN - {(row.relativeName)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>R - {(row.relation)}</span></>
        </span>
     );
    
}

actionsFormatterForPollManUpdate = (cell, row, rowIndex, formatExtraData) => {
    console.log("successSymbol",this.state.successSymbol)
    return (
        <>
            {
                row.Polled == 'N' ?
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold color_red text-decoration-underline' onClick={() => this.updateVoterPollStatus(row.voterId)}>NO
                    {
                        this.state.successSymbol == "show" && row.voterId == this.state.selectedPollVoterId ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                    }
                </span>)
                :
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold color_green' >YES</span>)
            }
            
        </>
          
     );
}

pollManagementDisplayData = () =>{
    let votersData = [];
        if(this.state.votersInABoothForPollArr.length>0){
            
            for (let i = 0; i < this.state.votersInABoothForPollArr.length; i++) {

                const serialNo = (this.state.votersInABoothForPollArr[i].serialNo);
                const voterName = (this.state.votersInABoothForPollArr[i].voterName);
                const Age = (this.state.votersInABoothForPollArr[i].age);
                const voterId = (this.state.votersInABoothForPollArr[i].voterId);
                const voterCardNo = (this.state.votersInABoothForPollArr[i].voterCardNo);
                const gender = (this.state.votersInABoothForPollArr[i].gender);
                const relativeName = (this.state.votersInABoothForPollArr[i].relativeName);
                const relation = (this.state.votersInABoothForPollArr[i].relation);
                const houseNo = (this.state.votersInABoothForPollArr[i].houseNo);
                const mobileNo = (this.state.votersInABoothForPollArr[i].mobileNo);
                const Party = (this.state.votersInABoothForPollArr[i].party);
                const Polled = (this.state.votersInABoothForPollArr[i].isPolled);
                
                const obj = {
                    serialNo:(serialNo),
                    voterName:voterName,
                    Age: voterCardNo,
                    voterId: voterId,
                    voterCardNo: Age,
                    gender:gender,
                    relativeName:relativeName,
                    relation:relation,
                    houseNo:houseNo,
                    Party:Party,
                    Polled:Polled,
                    mobileNo:mobileNo
                    
                }
                votersData.push(obj);
            } 

            
            
            return votersData;
        }
}

updateVoterPollStatus = (pollVoterId) => {
    
    this.setState({successSymbol:"show",selectedPollVoterId:pollVoterId});

    const obj =  
    JSON.stringify({ 
        "chatId" :this.state.chatID,
        "pollVoterId" : pollVoterId,
        "isPolled" : "Y"
    });



getAPICallData(obj,"updateVoterPollStatus")
    .then((res) => res.json())
    .then((content) => {
        this.setState({successSymbol:"hide",selectedPollVoterId:""});
        if(content !=null && content.status == "Success"){
            this.setState({successMessage:"Updated"});
            setTimeout(() => {   
                this.setState({successMessage:""});
                this.change_status(this.state.votersInABoothForPollArr,pollVoterId)   
            }, 500);

        }else{
            this.setState({successMessage:"Some Thing Went Wrong,Please Try Agin later..."});
        }
    });

   
}

change_status = (_data,pollVoterId) => {
    for(var key in _data){
        if(_data[key].voterId == pollVoterId){
            _data[key].isPolled = 'Y'
        }
    }
    console.log("_data",_data) ;
    this.setState({votersInABoothForPollArr:_data});
}

  render() {
    console.log("successSymbol",this.state.successSymbol)
    return (
      <section>
        <Container fluid>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'>Poll Management</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mt-4" controlId="formIssueTypeId">
                                <Form.Label className='f-12 fw-bold'>Election Type <span className='color_red f-9'>{this.state.validateElectionType}</span></Form.Label>
                                <SelectBox
                                    stateData={this.state.electionValue}
                                    handleChange={this.handleOnchangeSelectBox}
                                    name="electionTypeId"
                                    options={this.state.activePollsArr}
                                    placeholderName={'Select Type'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {
                                this.state.electionTypeId !="" && this.state.accessPollBoothsArr.length == 0 ?
                                (
                                    this.state.accessPollBoothsCheck == 1 ?
                                    (<Col className='f-14 text-center mt-3'>Poll Booths Are Available</Col>)
                                    :
                                    (<Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>)
                                )
                                :
                                (
                                this.state.accessPollBoothsArr.length>0 ?
                                    (
                                        <div className="mt-3 border_pad_ele">
                                            <Row>
                                                <Col>
                                                    <h6 className="f-12 fw-bold text-center text-uppercase">{this.state.electionTypeName} Details : 
                                                     </h6> 
                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='w_80 mt-3'>
                                                    <Form.Group className="" controlId="formIssueTypeId">
                                                        <Form.Label className='f-12 fw-bold'>Select Booth <span className='color_red f-9'>{this.state.validatePollBooth}</span></Form.Label>
                                                        <SelectBox
                                                            stateData={this.state.pollBoothValue}
                                                            handleChange={this.handleOnchangeSelectBox}
                                                            name="pollBoothId"
                                                            options={this.state.accessPollBoothsArr}
                                                            placeholderName={'Select Booth'}
                                                            dropdownIndicator={{ DropdownIndicator }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                <h6 className="form-label f-12 fw-bold mt-2">Party </h6>
                                                    <SelectBox
                                                        stateData={this.state.partyIdStrValue}
                                                        handleChange={this.handleOnchangeSelectBox}
                                                        name="partyIdStr"
                                                        options={this.state.party_ids_Arr}
                                                        placeholderName={'Select Party'}
                                                        dropdownIndicator={{ DropdownIndicator }}
                                                    /> 
                                                    <span className='color_red f-9'>{this.state.validatePartyIdStr}</span>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col>
                                                    <h6 className='mt-3'>
                                                        <span className="form-label f-12 fw-bold ml-0">
                                                            Poll Status : 
                                                        </span>
                                                        <span className='me-4'>
                                                            <Form.Check
                                                                inline
                                                                label={"All"}
                                                                name="pollStatus"
                                                                type="radio"
                                                                id="PollAllId"
                                                                onChange={(e)=>this.checkParameterValueForPoll(e,'')}
                                                                className="color_black f-14 pos_name_align"
                                                                defaultChecked={true}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label={"polled"}
                                                                name="pollStatus"
                                                                type="radio"
                                                                id="polledId"
                                                                onChange={(e)=>this.checkParameterValueForPoll(e,'Y')}
                                                                className="color_black f-14 pos_name_align"
                                                                defaultChecked={false}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label={"Pending"}
                                                                name="pollStatus"
                                                                type="radio"
                                                                id="PendingId"
                                                                onChange={(e)=>this.checkParameterValueForPoll(e,'N')}
                                                                className="color_black f-14 pos_name_align"
                                                                defaultChecked={false}
                                                            />
                                                            
                                                            
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getVotersInABoothForPoll()}>GET VOTERS</Button> 
                                                </Col>
                                            </Row>

                                            {
                                                this.state.votersInABoothForPollSymbol == "show" ?
                                                (
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                ("")
                                            }
                                            {
                                                this.state.votersInABoothForPollArr.length>0 ?
                                                (
                                                    
                                                    <>
                                                    <Row>
                                                        <Col sm={12}>
                                                        <h6 className="f-14 fw-bold mt-4">{this.state.pollBoothValue.label} Voter Details : </h6>
                                                        <div className='bg_light_blue mt-3'>
                                                        <CardLessDataTableWithSearch 
                                                                parentData={this.pollManagementDisplayData()}
                                                                particlecolumns={this.pollManagementDisplayColumns()}
                                                                defaultSorted={[{
                                                                    dataField: "influencer_votes",
                                                                    order: "asc"
                                                                }]}
                                                                tableHeading=""
                                                            />
                                                            
                                                                
                                                        </div>
                                                        
                                                        </Col>
                                                    </Row>
                                                    </>
                                                )
                                                :
                                                (<>
                                                    {
                                                    this.state.votersInABoothForPollCheck == 1 ?
                                                    (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                                    :
                                                    ("")
                                                }
                                                </>)
                                            }
                                        </div>
                                    )
                                    :
                                    ("")
                                )
                            }
                        </Col>
                    </Row>
                  
                    
        </Container>
        
    </section>
    )
  }
}

export default withRouter(PollManagementPanchayatElection);
