
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button  } from 'react-bootstrap';
import "./performanceReport.css";
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService'
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import moment from 'moment';

class PerformanceReportSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            reportsArr:[],
            performanceReportDataCheck:0,
            performanceReportListLoader:"hide"
        }
    }


    componentDidMount() {
        this.getUserPerformanceReports();
    }

    getUserPerformanceReports = () => {
        this.setState({reportsArr:[],performanceReportDataCheck:0,performanceReportListLoader:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID
            }); 

            getAPICallData(obj,"getUserPerformanceReports")
            .then((res) => res.json())
            .then((json) => {
                this.setState({performanceReportListLoader:"hide"});
                if(json !=null && json.length>0){
                    this.setState({ reportsArr:json,performanceReportDataCheck:0});
                }else{
                     this.setState({reportsArr:[],performanceReportDataCheck:1});
                }
              
            });
    }

    actionsFormatterForReportName = (cell, row, rowIndex, formatExtraData) => {

      
        return (
            <span className='view_enroll_css fw-bold' onClick={() => this.getReportView(row.reportPath)}>View {row.report_name}</span>
         );
        
    }

    getReportView = (filePath) => {
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "filePath": filePath
            }); 

            getAPICallData(obj,"getUserPerformanceReportURL")
            .then((res) => res.json())
            .then((json) => {
                if(json !=null){
                    this.props.history.push({
                        pathname: "/pdf_viewer",
                        params: {
                            navbarDisplay:"hide",
                            pdf_url:json.filePath,
                            navigate:"performance_report",
                        
                        },
                    }); 
                }
            });
    }

    reportsDisplayColumns() {
        let columns = [
            
            
            {
                dataField: "MonthName",
                text: "Month Name",
                sort: true,
                
                
            },
            {
                dataField: "report_name",
                text: "Report Name",
                formatter: this.actionsFormatterForReportName.bind(this),
                headerStyle: {
                    width: "60%"
                }
                
            }
        
        ];
        return columns;
    }

    reportsDisplayData = () => {
        let reportsData = [];
        if(this.state.reportsArr.length>0){
            
            for (let i = 0; i < this.state.reportsArr.length; i++) {

                const MonthName = moment(this.state.reportsArr[i].monthName, "MM-YYYY").format("MMM  YY");
                const reportName = (this.state.reportsArr[i].fileName);
                const reportPath = (this.state.reportsArr[i].filePath);
                
                
                const obj = {
                    MonthName:(MonthName),
                    report_name:reportName,
                    reportPath: reportPath
                   
                    
                }
                reportsData.push(obj);
            } 

            
            
            return reportsData;
        }
    }

    render() {
        return (
            <section>
                <Container fluid>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'> User Performance Reports</h6>
                        </Col>
                    </Row>

                    {
                        this.state.performanceReportListLoader == "show" ?
                        (
                            <Row>
                                <Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }
                    {
                        this.state.reportsArr.length>0 ?
                        (
                            
                            <>
                            <Row>
                                <Col sm={12}>
                            
                                <div className='bg_light_blue mt-3'>

                                    <table className='table table_custom mb-2 table-bordered'>
                                            <thead>
                                                <tr>
                                                <th >Month Name</th>
                                                <th>Report</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.reportsArr.map((item,index) => {
                                                return(
                                                    
                                                    <tr>
                                                    <td >{moment(item.monthName, "MM-YYYY").format("MMM  YY")} </td>
                                                    <td onClick={() => this.getReportView(item.filePath)}> <span className='view_enroll_css fw-bold'>View {item.fileName} </span></td>
                                                </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                            
                                    {/*<CardLessDataTableWithSearch 
                                        parentData={this.reportsDisplayData()}
                                        particlecolumns={this.reportsDisplayColumns()}
                                        defaultSorted={[{
                                            dataField: "Serial_No",
                                            order: "asc"
                                        }]}
                                        tableHeading="Voters Details"
                                    />*/}
                                    
                                        
                                </div>
                                
                                </Col>
                            </Row>
                            </>
                        )
                        :
                        (<>
                            {
                            this.state.performanceReportDataCheck == 1 ?
                            (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                            :
                            ("")
                        }
                        </>)
                    }
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(PerformanceReportSection);
  