
import React ,{ Component } from 'react';
import {Container, Row, Col,Collapse,Image,Button} from 'react-bootstrap';
import "./Dashboard.css";
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService';
import loader from '../../images/loader.gif';

class VerifyHouseDashboard extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
          userAccessListArr:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
          boothVerificationStatus:"",
          boothIdsList:[],
          boothId:"0",
          boothNo:"",
          first_name:  window.User !=undefined ? window.User.first_name : "",
          last_name:  window.User !=undefined ? window.User.last_name : "" ,
          chatID: window.User !=undefined ? window.User.chatID : "943827154",
          first_name_new:  window.User.first_name,
          last_name_new:  window.User.last_name,
          accessName:"",
          UnAssignedVotersList:[],
          validateUnAssignedVoter:"",
          validateUnAssignedHouseNo:"",
          UnAssignedVoter:"",
          UnAssignedHouse:"",
          UnAssignedHouseNo:"",
          UnAssignedVoterToHouseMsg:"",
          UnAssignedVotersOpen:false,
          loadingSymbolForUnAssignBlock:"hide",
          houseNoList:[],
          mapsDataAvailable:false,
          
        }
    }
  
    componentDidMount() {
        let userAccessList = this.state.userAccessListArr;
        console.log("sssssssssss",this.state.userAccessListArr)
        this.setState({ accessName: "" });
        if(userAccessList != null && userAccessList.status == "Success" ){
            if(userAccessList.boothList != null && userAccessList.boothList.length>0){
                
                this.setState({ boothIdsList:userAccessList.boothList})

                if(userAccessList.unitList.length>0 && userAccessList.clusterList.length>0){
                    if(userAccessList.constituencyList !=null && userAccessList.constituencyList.length>0){
                        if(userAccessList.constituencyList.length == 1){
                            const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                            this.setState({ accessName:cons_name +"_Constituency",verifyHouseAccess:"yes"})
                        }else{
                            this.setState({ accessName:"Constituency",verifyHouseAccess:"yes"})
                        }
                    }
                    
                }else if(userAccessList.unitList.length == 0  &&  userAccessList.clusterList.length == 0){
                    const booth_id = userAccessList.boothList.filter((item,index) => index === 0).map(blist => blist.id);
                    const booth_Name = userAccessList.boothList.filter((item,index) => index === 0).map(blist => blist.name);
                    const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                    this.setState({ accessName:cons_name+" > Booth - "+booth_Name,boothId:booth_id,boothNo:"Booth - "+booth_Name,verifyHouseAccess:"yes"})
                    this.getBoothWiseVerificationAnalysis(booth_id);
                    this.checkMapsDataCall(booth_id);
                    

                }else if(userAccessList.clusterList!= null && userAccessList.clusterList.length>0){
                    const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                    const clusterName = userAccessList.clusterList.filter((clist,index) => index === 0).map(clist => clist.name);
                    this.setState({ accessName:cons_name+" > "+clusterName + "",verifyHouseAccess:"yes"})
                }
                else if(userAccessList.unitList!= null && userAccessList.unitList.length>0){
                    const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                    const clusterName = userAccessList.unitList.filter((clist,index) => index === 0).map(clist => clist.name);
                    this.setState({ accessName:cons_name+" > "+clusterName + "",verifyHouseAccess:"yes"})
                }else if(userAccessList.unitList.length == 0  &&  userAccessList.clusterList.length == 0 && userAccessList.boothList.length == 0  &&  userAccessList.constituencyList.length == 0 ){
                    this.setState({ accessName:"NoAccess"})
                    }

                if(userAccessList.boothList.length>1){
                    this.getAllBoothWiseVerificationStatus();
                }
            }
    }else{
        this.setState({ accessName:"NoAccess"})
        this.props.history.push({pathname:"/dashboard"});
        
    }
        
        
    }

    getBoothWiseVerificationAnalysis = (boothId) => {
        this.setState({ boothVerificationStatus: "" });
        const boothWiseDtsObj =  
            JSON.stringify({ 
            "username" : "bsa_api",
            "password" : "9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :this.state.chatID,
            "boothId" : parseInt(boothId)
            }
        ); 

        //getBoothVerificationStatus
        getAPICallData(boothWiseDtsObj,"getBoothWiseVerificationAnalysis")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null && data.length>0){
                    let finalObj = {};
                    for(let i = 0; i < data.length; i++ ) {
                        Object.assign(finalObj, data[i]);
                    }
                    this.setState({ boothVerificationStatus: finalObj });
                }else{
                    this.setState({ boothVerificationStatus: "" });
                }
            }); 
    }

    getAllBoothWiseVerificationStatus = () => {
        const boothVerificationStatusObj =  
        JSON.stringify({ 
            "username" : "bsa_api",
            "password" : "9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :this.state.chatID
        });
        
    //getAllBoothVerificationStatus
    getAPICallData(boothVerificationStatusObj,"getUserDashboard").then((res) => res.json())
    .then((json) => {
        if(json !=null){
            this.setState({ boothVerificationStatus: json });
        }else{
            this.setState({ boothVerificationStatus: "" });
        }
        
    });
    }

    handleOnchangeSelectBox = (e,type) => {
        const name = e.target.name;
        const value = e.target.value;
    
        this.setState({validateUnAssignedVoter:"",validateUnAssignedHouseNo:""})
       if(type === "unAssignedHouseId"){
          var index1 = e.target.selectedIndex;
          this.setState({UnAssignedHouse:e.target.value,UnAssignedHouseNo:e.target[index1].text });
        }else{
          this.setState({ [name]: value });
        }
    }
    
    boothNoHandleChange = (e) => {
        if(e.target.value == 0){
            this.setState({boothId:"0",boothNo:""})
            this.getAllBoothWiseVerificationStatus();
        }else{
            var index1 = e.target.selectedIndex;
            this.setState({ boothId:e.target.value,boothNo:e.target[index1].text });
            this.getBoothWiseVerificationAnalysis(e.target.value);
            this.getUnAssignedVotersInABooth(e.target.value);
            this.getHousesList(e.target.value,"All","All");
            this.checkMapsDataCall(e.target.value);
        }
        

    }
    getHousesList = (booth_id,verifiedStatus,mappingStatus) => {
        const getHousesObj =  
            JSON.stringify({ 
              "username" : "bsa_api",
              "password" : "9D#Jcx!i5B$z@ym4d7K2f",
              "chatId" :this.state.chatID,
              "locationId" : parseInt(booth_id),
              "verifiedStatus" : verifiedStatus,
              "mappingStatus" : mappingStatus
            }
          ); 
    
          //gethousesList
          getAPICallData(getHousesObj,"getHousesByBoothId")
              .then((res) => res.json())
              .then((data) => {
                if(data.housesList !=null && data.housesList.length>0){
                  this.setState({ houseNoList: data.housesList });
                  
                }else{
                  this.setState({ houseNoList: [] });
                }
    
                
                
          });
      }
        unAssignedVotersBlockOpen = () => {
            this.setState({UnAssignedVoterToHouseMsg:"",validateUnAssignedVoter:"",validateUnAssignedHouseNo:"",UnAssignedVoter:"",UnAssignedHouse:""});
            this.setState({UnAssignedVotersOpen:!this.state.UnAssignedVotersOpen });
        }
        getUnAssignedVotersInABooth = (booth_id) => {
            const UnAssignedVotersObj =  
                JSON.stringify({ 
                    "username" : "bsa_api",
                    "password" : "9D#Jcx!i5B$z@ym4d7K2f",
                    "chatId" :this.state.chatID,
                    "boothId" : parseInt(booth_id)
                }
            ); 
    
            //getUnAssignedVotersInABooth
            getAPICallData(UnAssignedVotersObj,"getUnAssignedVotersInABooth")
                .then((res) => res.json())
                .then(async(data) => {
                if(data.votersList != null && data.votersList.length>0){
                    this.setState({UnAssignedVotersList:data.votersList });
                }else{
                    this.setState({UnAssignedVotersList:[] });
                }
                
                
            });
        }

      updateAssignVoterToHouse = () => {
        this.setState({validateUnAssignedVoter:"",validateUnAssignedHouseNo:"",UnAssignedVoterToHouseMsg:""})
        
        if(this.state.UnAssignedVoter == ""){
            this.setState({validateUnAssignedVoter:"Please Select Voter" });
            return;
        }
        if(this.state.UnAssignedHouse == ""){
          this.setState({validateUnAssignedHouseNo:"Please Select House No" });
          return;
        }
  
        this.setState({loadingSymbolForUnAssignBlock:"show"})
  
        const updateAssignVoterObj =  
          JSON.stringify({ 
            "username" : "bsa_api",
            "password" : "9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :this.state.chatID,
            "voterId" : parseInt(this.state.UnAssignedVoter),
            "boothHouseId" : parseInt(this.state.UnAssignedHouse),
          }
        ); 
  
        //getUnAssignedVotersInABooth
        getAPICallData(updateAssignVoterObj,"assignVoterToBoothHouse")
            .then((res) => res.json())
            .then(async(data) => {
              this.setState({loadingSymbolForUnAssignBlock:"hide"});
              if(data.status == "Success"){
                this.setState({UnAssignedVoterToHouseMsg:"Voter Added SuccessFully To This House No..."+ this.state.UnAssignedHouseNo+" And Booth No" + this.state.boothNo });
                setTimeout(() => {
                  this.setState({UnAssignedVoterToHouseMsg:"",validateUnAssignedVoter:"",validateUnAssignedHouseNo:"",UnAssignedVoter:"",UnAssignedHouse:""});
                  this.getUnAssignedVotersInABooth(this.state.boothId);
              }, 2000);
              }else{
                this.setState({UnAssignedVoterToHouseMsg:"Something Wrong,Please Try Again..." ,validateUnAssignedVoter:"",validateUnAssignedHouseNo:""});
              }
              
              
        });
      }

      checkMapsDataCall = (boothId) => {
        const mapObj =  
            JSON.stringify({ 
            "username" : "bsa_api",
            "password" : "9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :'943827154',
            "boothId" : parseInt(boothId)
            }
        ); 

            getAPICallData(mapObj,"getMappedHousesLocationInABooth")
            .then((res) => res.json())
            .then((data) => {
            if(data !=null && data.length>0){
                this.setState({mapsDataAvailable:true });
            }else{
                this.setState({mapsDataAvailable:false });
            }
            
            });
      }


    render() {
        console.log("accessName",this.state.boothVerificationStatus)
        return (
            <section>
                <Container fluid>
                     <Row>
                        <Col>
                            <h6 className='fw-bold text-center mb-3'>Booth Wise Verify House Dashboard</h6>
                        </Col>
                    </Row>
                    <div className='bg_light_blue'> 
                            {
                                this.state.boothIdsList.length>0 && this.state.boothIdsList.length > 1 ?
                                (
                                    <>
                                    <Row>
                                    <Col className='mt-2'>
                                        <h6 className="form-label f-14 fw-bold">Booth </h6>
                                        <select id="booth_id" name="booth" className='form-select form-select-sm' onChange={(e) => this.boothNoHandleChange(e)}>
                                            {
                                                this.state.boothIdsList.length>0 ? 
                                                (
                                                    <>
                                                    <option value="0" selected>All</option>
                                                    {
                                                        this.state.boothIdsList.map((item,index) => {
                                                            return(
                                                                <option value={item.id}>Booth No - {item.name}</option>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                
                                                )
                                                :
                                                (<option value="">Select Booth</option>)
                                                
                                            }
                                        </select>
                                    </Col>
                                    <Col >
                                        {
                                            this.state.boothId != 0 ?
                                            (
                                                <Link to={{
                                                    pathname: "/map_view_component" ,
                                                    params: {
                                                        boothId:this.state.boothId,
                                                        boothNo:this.state.boothNo,
                                                        accessName:this.state.accessName,
                                                        checkMapsData:this.state.mapsDataAvailable,
                                                        userAccessListArr:this.state.userAccessListArr
                                                    }
                                                }} >
                                                <h6 className='f-12 float-end map_view_cls mt_1_9'>MAP VIEW <i class="fa-solid fa-map-location-dot"></i></h6>
                                            </Link>
                                                
                                            )
                                            :
                                            ("")
                                        }
                                        
                                    </Col>
                                    </Row>
                                    </>
                                )
                                :
                                ("")
                            }

                            {
                                this.state.boothIdsList.length>0 && this.state.boothIdsList.length == 1 ?
                                (
                                    <Row>
                                        <Col>
                                            <Link to={{
                                                    pathname: "/map_view_component" ,
                                                    params: {
                                                        boothId:this.state.boothId,
                                                        boothNo:this.state.boothNo,
                                                        accessName:this.state.accessName,
                                                        checkMapsData:this.state.mapsDataAvailable,
                                                    }
                                                }} >
                                                <h6 className='f-12 float-end map_view_cls mt-2'>MAP VIEW <i class="fa-solid fa-map-location-dot"></i></h6>
                                            </Link>
                                        
                                        </Col>
                                        </Row>
                                )
                                :
                                ("")
                            }

                            <hr/>
                             {/* Verification Overview Code*/}
                            <Row>
                                <Col sm={12}>
                                    <div className='bg_light_blue mt-3'>
                                        <h6 className='fw-bold f-14'>House Verification Overview 
                                        <Link to={{
                                                pathname: "/booth_wise_analysis" ,
                                                params: {
                                                    viewStatusStr: "house_verification",
                                                    boothId:this.state.boothId,
                                                    boothNo:this.state.boothNo,
                                                    accessName:this.state.accessName,
                                                    verifyHouseAccess:this.state.verifyHouseAccess
                                                }
                                            }} >
                                            <i class="fa-solid fa-circle-info float-end"></i>
                                        </Link>
                                        
                                        </h6>
                                        <Row>
                                            <Col sm={12}>
                                                <div className='flex_block'>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Total Houses</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.verifyHouseAccess == 'yes' && this.state.boothId !="0" ?
                                                                (
                                                                    <Link to={{
                                                                        pathname: "/all_houses" ,
                                                                            params: {
                                                                                boothIdFromDashBoard: this.state.boothId,
                                                                                boothNoFromDashBoard:this.state.boothNo
                                                                            }
                                                                        }} >
                                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.totalHouses}
                                                                    </Link>
                                                                )
                                                                :
                                                                (this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.totalHouses)
                                                            }
                                                        
                                                        </h5>
                                                    </div>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Verified</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.verifyHouseAccess == 'yes' && this.state.boothId !="0" ?
                                                                (
                                                                    <>
                                                                    <Link to={{
                                                                            pathname: "/verify_house" ,
                                                                            params: {
                                                                                boothIdFromDashBoard: this.state.boothId,
                                                                                verifiedStatusFromDashboard: "Completed",
                                                                            }
                                                                        }} >
                                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.verifiedHouses}
                                                                    </Link>
                                                                        <span className='color_green f-12'>{this.state.boothVerificationStatus.verifiedHousesPercentage} %</span>
                                                                    </>
                                                                )
                                                                :
                                                                (this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.verifiedHouses)
                                                            }
                                                                
                                                        </h5>
                                                    </div>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Unverified</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.verifyHouseAccess == 'yes' && this.state.boothId !="0" ?
                                                                (
                                                                    <>
                                                                    <Link to={{
                                                                            pathname: "/verify_house" ,
                                                                            params: {
                                                                                boothIdFromDashBoard: this.state.boothId,
                                                                                verifiedStatusFromDashboard: "Pending",
                                                                            }
                                                                        }} >
                                                                    {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.notVerifiedHouses}
                                                                    </Link>
                                                                    
                                                                    <span className='color_green f-12'>{this.state.boothVerificationStatus.notVerifiedHousesPercentage}%</span>
                                                                    </>
                                                                )
                                                                :
                                                                (this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.notVerifiedHouses)
                                                            }
                                                                
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            {/* Mapping Overview Code*/}

                            <Row>
                                <Col sm={12}>
                                    <div className='bg_light_blue mt-3'>
                                        <h6 className='fw-bold f-14'>House Mapping Overview 
                                            <Link to={{
                                                    pathname: "/booth_wise_analysis" ,
                                                    params: {
                                                        viewStatusStr: "house_mapping",
                                                        boothId:this.state.boothId,
                                                        boothNo:this.state.boothNo,
                                                        accessName:this.state.accessName,
                                                        verifyHouseAccess:this.state.verifyHouseAccess
                                                    }
                                                }} >
                                                <i class="fa-solid fa-circle-info float-end"></i>
                                            </Link>
                                        </h6>
                                        <Row>
                                            <Col sm={12}>
                                                <div className='flex_block'>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Total Houses</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.verifyHouseAccess == 'yes' && this.state.boothId !="0"  ?
                                                                (
                                                                    <Link to={{
                                                                        pathname: "/all_houses" ,
                                                                            params: {
                                                                                boothIdFromDashBoard: this.state.boothId,
                                                                                boothNoFromDashBoard:this.state.boothNo
                                                                            }
                                                                        }} >
                                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.totalHouses}
                                                                    </Link>
                                                                )
                                                                :
                                                                (this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.totalHouses)
                                                            }
                                                            
                                                        </h5>
                                                    </div> 
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Mapped</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.verifyHouseAccess == 'yes' && this.state.boothId !="0"  ?
                                                                (
                                                                    <>
                                                                    <Link to={{
                                                                            pathname: "/verify_house" ,
                                                                            params: {
                                                                                boothIdFromDashBoard: this.state.boothId,
                                                                                mappingStatusFromDashboard: "Completed",
                                                                            }
                                                                        }} >
                                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.mappedHouses}
                                                                    </Link>
                                                                    <span className='color_green f-12'>{this.state.boothVerificationStatus.mappedHousesPercentage}%</span>
                                                                    </>
                                                                )
                                                                :
                                                                (this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.mappedHouses)
                                                            }
                                                            
                                                            
                                                        </h5>
                                                    </div> 
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Not Mapped</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.verifyHouseAccess == 'yes' && this.state.boothId !="0"  ?
                                                                (
                                                                    <>
                                                                    <Link to={{
                                                                            pathname: "/verify_house" ,
                                                                            params: {
                                                                                boothIdFromDashBoard: this.state.boothId,
                                                                                mappingStatusFromDashboard: "Pending",
                                                                            }
                                                                        }} >
                                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.unMappedHouses}
                                                                    </Link>
                                                                    
                                                                    <span className='color_green f-12'>{this.state.boothVerificationStatus.unMappedHousesPercentage}%</span>
                                                                    </>
                                                                )
                                                                :
                                                                (this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.unMappedHouses)
                                                            }

                                                            
                                                        </h5>
                                                    </div>  
                                                </div>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            {/* Voters Overview Code*/}

                            <Row>
                                <Col sm={12}>
                                    <div className='bg_light_blue mt-3'>
                                        <h6 className='fw-bold f-14'>Voters Verification Overview 
                                            <Link to={{
                                                        pathname: "/booth_wise_analysis" ,
                                                        params: {
                                                            viewStatusStr: "voters_verification",
                                                            boothId:this.state.boothId,
                                                            boothNo:this.state.boothNo,
                                                            accessName:this.state.accessName,
                                                            verifyHouseAccess:this.state.verifyHouseAccess
                                                        }
                                                    }} >
                                                    <i class="fa-solid fa-circle-info float-end"></i>
                                                </Link>
                                        </h6>
                                        <Row>
                                            <Col sm={12}>
                                                <div className='flex_block'>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Total Voters</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.totalVoters
                                                                
                                                            }
                                                            
                                                        </h5>
                                                    </div> 
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Verified</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.boothVerificationStatus.verifiedVoters>0 ?
                                                                (
                                                                    <>
                                                                    
                                                                    {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.verifiedVoters}
                                                                    <span className='color_green f-12'>{this.state.boothVerificationStatus.verifiedVotersPercentage}%</span>
                                                                    </>
                                                                )
                                                                :
                                                                (this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.verifiedVoters)
                                                            }
                                                            
                                                        </h5>
                                                    </div> 
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Pending</h6>
                                                        <h5 className='mb-0 f-14 color_blue'>
                                                            {
                                                                this.state.boothVerificationStatus.pendingVoters>0 ?
                                                                (
                                                                    <>
                                                                
                                                                    {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.pendingVoters}
                                                                    
                                                                    <span className='color_green f-12'>{this.state.boothVerificationStatus.pendingVotersPercentage}%</span>
                                                                    </>
                                                                )
                                                                :
                                                                (this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.pendingVoters)
                                                            }
                                                            
                                                        </h5>
                                                    </div>  
                                                </div>
                                            </Col>
                                        
                                            
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            {/* Voters Overview Code*/}

                            <Row>
                                <Col sm={12}>
                                    <div className='bg_light_blue mt-3'>
                                        <h6 className='fw-bold f-14'>Detached Voters : {this.state.boothVerificationStatus.detachedVoters} <span className='color_green f-12'>{this.state.boothVerificationStatus.detachedVotersPercentage}%</span> 
                                            {
                                                this.state.boothVerificationStatus.detachedVoters>0 ?
                                                (
                                                    <Link to={{
                                                            pathname: "/booth_wise_analysis" ,
                                                            params: {
                                                                viewStatusStr: "detached_voters",
                                                                boothId:this.state.boothId,
                                                                boothNo:this.state.boothNo,
                                                                accessName:this.state.accessName,
                                                                verifyHouseAccess:this.state.verifyHouseAccess
                                                            }
                                                        }} >
                                                        <i class="fa-solid fa-circle-info float-end"></i>
                                                    </Link>
                                                )
                                                :
                                                ("")
                                            }
                                            
                                        </h6>
                                        <Row>
                                            <Col sm={12}>
                                                <div className='flex_block'>
                                                    <div className='bg_white_block1'>
                                                        <h6 className='fw-normal f-12'>Death Voters</h6>
                                                        <h5 className='mb-0 f-14 text-center color_blue'>
                                                            {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.deathVoters}
                                                            
                                                        </h5>
                                                    </div> 
                                                    <div className='bg_white_block1'>
                                                        <h6 className='fw-normal f-12'>House Shifting</h6>
                                                        <h5 className='mb-0 f-14 text-center color_blue'>
                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.houseShiftingVoters}
                                                            
                                                        </h5>
                                                    </div> 
                                                    <div className='bg_white_block1'>
                                                        <h6 className='fw-normal f-12'>Moved Permanently</h6>
                                                        <h5 className='mb-0 f-14 text-center color_blue'>
                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.movedPermanentlyVoters}
                                                            
                                                        </h5>
                                                    </div> 
                                                </div>
                                            </Col>
                                            
                                            
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            {/* Voters Overview Code*/}

                            <Row>
                                <Col sm={12}>
                                    <div className='bg_light_blue mt-3'>
                                        <h6 className='fw-bold f-14'>New Voters & House Shifted Status 
                                        {
                                            this.state.boothVerificationStatus.houseShiftedVoters>0 || this.state.boothVerificationStatus.newVoters>0 ?
                                            (
                                                <Link to={{
                                                        pathname: "/booth_wise_analysis" ,
                                                        params: {
                                                            viewStatusStr: "new_voters",
                                                            boothId:this.state.boothId,
                                                            boothNo:this.state.boothNo,
                                                            accessName:this.state.accessName,
                                                            verifyHouseAccess:this.state.verifyHouseAccess
                                                        }
                                                    }} >
                                                    <i class="fa-solid fa-circle-info float-end"></i>
                                                </Link>
                                            )
                                            :
                                            ("")
                                        }
                                        
                                        </h6>
                                        <Row>
                                            <Col sm={12}>
                                                <div className='flex_block'>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-12'>Total Voters</h6>
                                                        <h5 className='mb-0 f-14 text-center color_blue'>
                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.houseShiftedVoters+this.state.boothVerificationStatus.newVoters}
                                                        
                                                            
                                                        </h5>
                                                    </div> 
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-12'>House Shifted</h6>
                                                        <h5 className='mb-0 f-14 text-center color_blue'>
                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.houseShiftedVoters}
                                                            
                                                        </h5>
                                                    </div>  
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>New Voters</h6>
                                                        <h5 className='mb-0 f-14 text-center color_blue'>
                                                        {this.state.boothVerificationStatus == "" ?<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>  : this.state.boothVerificationStatus.newVoters}
                                                            
                                                        </h5>
                                                    </div> 
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                {
                                    this.state.accessName.includes("Constituency")  && this.state.boothId != "" && this.state.UnAssignedVotersList.length>0 ? 
                                    (
                                    <Col sm={12}>
                                    <h6 className='mt-2 collapse-cls f-14' onClick={() => this.unAssignedVotersBlockOpen()}>Un Assigned Voters to House 
                                    <span className='float-end'>
                                        {
                                        this.state.UnAssignedVotersOpen == false ?
                                        (<i className="fa-solid fa-square-plus"></i>)
                                        :
                                        (<i className="fa-solid fa-square-minus"></i>)
                                        }
                                        
                                        </span>
                                    </h6>
                                    <Collapse in={this.state.UnAssignedVotersOpen}>
                                        <div className='bg_light_blue mt-3'>
                                            <h6 className='f-14'><b>Assign Voters to House</b></h6>
                                            <Row>
                                            <Col className='mt-2'>
                                                <label className="form-label f-14 fw-bold">Voter <span className='color_red f-9'>{this.state.validateUnAssignedVoter}</span></label>
                                                <select id="UnAssignedVoterId" name="UnAssignedVoter" value={this.state.UnAssignedVoter} className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e,"unAssignedVoterId")} >
                                                    {
                                                            this.state.UnAssignedVotersList.length>0  ? 
                                                            (
                                                                <>
                                                                <option  value="" selected>Select Voter</option>
                                                                {
                                                                    this.state.UnAssignedVotersList.map((item,index) => {
                                                                        return(
                                                                        <option key={index} value={item.voterId}>{item.voterName}</option>
                                                                        )
                                                                    })
                                                                }
                                                                </>
                                                            
                                                            )
                                                            :
                                                            (<option value="">Select Voter</option>)
                                                            
                                                        }
                                                </select>
                                                </Col>
                                                <Col className='mt-2'>
                                                <label className="form-label f-14 fw-bold">House No  <span className='color_red f-9'>{this.state.validateUnAssignedHouseNo}</span></label>
                                                <select id="UnAssignedHouseId" name="UnAssignedHouse" value={this.state.UnAssignedHouse} className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e,"unAssignedHouseId")} >
                                                    {
                                                            this.state.houseNoList.length>0  ? 
                                                            (
                                                                <>
                                                                <option  value="" selected>Select House</option>
                                                                {
                                                                    this.state.houseNoList.map((item,index) => {
                                                                        return(
                                                                        <option key={index} value={item.boothHouseId}>House No - {item.houseNo}</option>
                                                                        )
                                                                    })
                                                                }
                                                                </>
                                                            
                                                            )
                                                            :
                                                            (<option value="">Select House</option>)
                                                            
                                                        }
                                                </select>
                                                </Col>
                                                {
                                                    this.state.UnAssignedVotersList.length>0  ?
                                                    (
                                                    this.state.UnAssignedVotersList.filter(item1 => item1.voterId == this.state.UnAssignedVoter).map((item,index) => {
                                                    return(
                                                        <Col sm={12} className='mt-2'>
                                                            <h6 className='f-12 color_red'>{item.deleteReason == "House Shifting" ? "House Shifted" : item.deleteReason}</h6>
                                                        </Col>
                                                        )
                                                    })
                                                    )
                                                    :
                                                    ("")
                                                }
                                                <Col sm={12} className='mt-2'>
                                                    <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-1' onClick={() => this.updateAssignVoterToHouse()}>Update/Assign</Button>
                                                    {
                                                    this.state.loadingSymbolForUnAssignBlock === "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>) : ("")
                                                    }
                                                </Col>
                                                {
                                                this.state.UnAssignedVoterToHouseMsg != "" ?
                                                (
                                                    <Col sm={12} className='mt-2'>
                                                        <h6 className='f-14 fw-bold text-center mt-2'>{this.state.UnAssignedVoterToHouseMsg}</h6>
                                                    </Col>
                                                )
                                                :
                                                ("")
                                                }
                                                
                                                
                                            </Row>
                                        </div>
                                    </Collapse>
                                    </Col>
                                    )
                                    :
                                    ("")
                                }
                            </Row>

                            {/* Membership Overview Code

                            <Row>
                                <Col sm={12}>
                                    <div className='bg_light_blue mt-3'>
                                        <h6 className='fw-bold f-14'>Membership Overview</h6>
                                        <Row>
                                            <Col sm={12}>
                                                <div className='flex_block'>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Total Houses</h6>
                                                        <h5 className='mb-0'>-</h5>
                                                        <hr/>
                                                        <h6 className='fw-normal f-14'>Members</h6>
                                                        <h5 className='mb-0'>-</h5>
                                                    </div>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>  Not Registered</h6>
                                                        <h5 className='mb-0'>-</h5>
                                                        <hr/>
                                                        <h6 className='fw-normal f-14'>Members</h6>
                                                        <h5 className='mb-0'>-</h5>
                                                    </div>
                                                    <div className='bg_white_block'>
                                                        <h6 className='fw-normal f-14'>Yet to renewal</h6>
                                                        <h5 className='mb-0'>-</h5>
                                                        <hr/>
                                                        <h6 className='fw-normal f-14'>Members</h6>
                                                        <h5 className='mb-0'>-</h5>
                                                    </div>
                                                </div>
                                            </Col>
                                        
                                        </Row>
                                    </div>
                                </Col>
                            </Row>*/}
                    </div>
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(VerifyHouseDashboard);
  