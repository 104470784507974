
import React ,{ Component } from 'react';
import {Container, Row, Col,Button,Tab,Tabs,OverlayTrigger,Tooltip,Image,Collapse } from 'react-bootstrap';
import "./BoothAndHouseMapping.css";
import { getAPICallData } from '../../services/MemberService'
import UpdateLocationModal from '../UpdateLocationModal';
import UnLinkMemberFromHouseModal from './UnLinkMemberFromHouseModal';
import AddNewHouseModal from './AddNewHouseModal';
import AddNewVoterModel from './AddNewVoterModel';
import verifiedLogo from '../../images/verified.png';
import loader from '../../images/loader.gif';
import UploadFeedBackForm from '../EventsAndActivities/UploadFeedBackForms';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';

let voterLinkIndexArr=[];

const DropdownIndicator = props => {
  return (
      components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
          </components.DropdownIndicator>
      )
  );
};



const colourStylesVal = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    let colorCode="";
    if(data.verificationStatus == "Pending" && data.mappingStatus == "Pending"){
      colorCode =  '#D30C00';
    }else if(data.verificationStatus == "Completed" && data.mappingStatus == "Pending"){
      colorCode =  "#f9bb37";
    }else if(data.verificationStatus == "Completed" && data.mappingStatus == "Completed"){
      colorCode =  "#568B00";
    }else if(data.verificationStatus == "Pending" && data.mappingStatus == "Completed"){
      colorCode =  "#f9bb37";
    }
    return {
      ...styles,
      color: colorCode,
      background: isFocused
      ? '#9fc3f3'
      : isSelected
        ? '#fff'
        : undefined,
  }
  },
}

class BoothAndHouseMapping extends Component {
  constructor(props) {
      super(props)
      this.state = {
        chatID: window.User !=undefined ? window.User.chatID : "943827154",
        
        verifyStatusValue:"",
        verifyStatusList:[{value:"",label:"All"},{value:"Completed",label:"Verified"},{value:"Pending",label:"Pending"}],
        verifiedStatus:props?.location?.params?.verifiedStatusFromDashboard == undefined ? "All" : props?.location?.params?.verifiedStatusFromDashboard,
        
        mappedStatusValue:"",
        mappedStatusList:[{value:"",label:"All"},{value:"Completed",label:"Mapped"},{value:"Pending",label:"Pending"}],
        mappedStatus:props?.location?.params?.mappingStatusFromDashboard == undefined ? "All" : props?.location?.params?.mappingStatusFromDashboard,
        
        boothIdValue:"",
        boothIdsList:[],
        boothId:props?.location?.params?.boothIdFromDashBoard == undefined ? "" : props?.location?.params?.boothIdFromDashBoard.toString(),
        boothNo:"",

        houseNoIdValue:"",
        houseNoList:[],
        boothHouseId:props?.location?.params?.boothHouseIdFromAllHouse == undefined ? "" : props?.location?.params?.boothHouseIdFromAllHouse,
        validateBooth:"",
        validateHouseNo:"",
        
        votersList:[],
        votersListCheck:0,
        votersListLoader:"hide",
        votersListLoaderForOnlyHouseNo:0,
        
        updateLocationModalShow:false,
        boothHouseLocationUpdated:"",
        getCurrentLocationCoordinates:{},
        boothHouseLocationMsg:"",
        boothLocationUpdated:props?.location?.params?.mappingStatusFromDashboard == undefined ? "" : props?.location?.params?.mappingStatusFromDashboard,
        boothLocationMsg:"",
        
        voterLinkIndexVal:0,
        voterDeletionDetails:{},
        voterDeletionModalShow:false,
        validateReason:"",
        VoterReasonId:"",
        unLinkMemberStatusMsg:"",
        unLinkCurrentVoterId:0,
        unLinkMemberloader:"",

        addStreetName:"",
        addNewStreetBlock:"hide",
        streetSuccessMsg:"",
        validateStreet:"",
        streetArr:[],
        getStreetName:"",
        getStreetNameValue:"",

        boothIdForHouseCreateValue:"",
        boothIdForHouseCreate:"",
        addHouseNo:"",
        newHouseModalShow:false,
        newHouseSuccessMsg:"",
        validateBoothForHouse:"",
        validateForNewHouse:"",

        
        mappingTypeLocation:"",

        viewExistingVotersList:[],
        viewExistingVotersOpen:false,

        addNewVoterModalShow:false,
        newVoterName:"",
        newVoterMobileNumber:"",
        newVoterId:"",
        newGender:"",
        newAge:"",
        newRelativeName:"",
        newRelationName:"",
        newMemberAddedMsg:"",
        newMemberStatus:"No",

        loadingSymbol:"hide",
        messageDisplay:"",

        shiftNewVoterId:"",
        validShiftNewVoterId:"",
        shiftVoterSearchDetails:[],
        shiftNewVoterLoderSymbol:"hide",
        boothHouseVoterId:"",
        shiftVoterLoderSymbolSave:"hide",
        shiftVoterMessageSave:"",
        voterSearchMessageDisplay:"",
        shiftVoterSelectedType:"voterCardNo",
        filterBlockOpen:true,


        uploadFeedBackFormModal:false,
        feedBackFormsList:[],
        feedBackFormsCheck:0,
        file: [],
        validateUploadForms:"",
        fileUploaderLoaderImg:"hide",
        fileUploadedStatusMsg:"",

      }

      
  }

  componentDidMount() {
    console.log("wwwwwwwwwwwww",this.state.boothId)
    if(this.state.boothId !=""){
      this.getHousesList(this.state.boothId,this.state.verifiedStatus,this.state.mappedStatus);
    }

    if(this.state.boothHouseId !=""){
      this.getHouseWiseDetails(this.state.boothHouseId);
    }

    if(this.state.verifiedStatus == "All"){
      this.setState({ verifyStatusValue: { value: "", label: "All"}});
    }else if(this.state.verifiedStatus == "Completed"){
      this.setState({ verifyStatusValue: { value: "Completed", label: "Verified"}});
    }else if(this.state.verifiedStatus == "Pending"){
      this.setState({ verifyStatusValue: { value: "Pending", label: "Pending"}});
    }

    if(this.state.mappedStatus == "All"){
      this.setState({ mappedStatusValue: { value: "", label: "All"}});
    }else if(this.state.mappedStatus == "Completed"){
      this.setState({ mappedStatusValue: { value: "Completed", label: "Mapped"}});
    }else if(this.state.mappedStatus == "Pending"){
      this.setState({ mappedStatusValue: { value: "Pending", label: "Pending"}});
    }


    const userAccessObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID
        }
      ); 

      //getBooths
      getAPICallData(userAccessObj,"getUserAccessLocationDetails")
          .then((res) => res.json())
          .then((userAccessList) => {
              if(userAccessList !=null && userAccessList.boothList !=null && userAccessList.boothList.length>0){
                let boothListArr=[];
                if(this.state.boothId !=""){
                    let boothIdNameForDash = userAccessList.boothList.find((a) => a.id == this.state.boothId);
                    this.setState({
                      boothIdValue: {
                          value: boothIdNameForDash.id,
                          label: "Booth No -"+boothIdNameForDash.name
                      }
                  });
                }
                userAccessList.boothList.map(item => {
                  const obj = {
                      value: item.id,
                      label: "Booth No -"+item.name,
                      attr_mapped:item.isMapped
                  }
                  boothListArr.push(obj);
                  
              });
                
                this.setState({ boothIdsList: boothListArr });
              }
             
              if(userAccessList !=null && userAccessList.boothList !=null && userAccessList.boothList.length == 1){
                const booth_Name = userAccessList.boothList.filter((item,index) => index === 0).map(blist => blist.name);
                const booth_id_str = userAccessList.boothList.filter((item,index) => index === 0).map(blist => blist.id);
                this.setState({ boothNo:booth_Name,boothId:booth_id_str });
                this.getHousesList(booth_id_str,"All","All");
              }
              
      });


  }

  getBoothOnChangeStatus = (booth_id) => {
    const userAccessObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID
        }
      ); 

      getAPICallData(userAccessObj,"getUserAccessLocationDetails")
          .then((res) => res.json())
          .then((userAccessList) => {
              if(userAccessList !=null && userAccessList.boothList !=null && userAccessList.boothList.length>0){
                const isMappedStatus = userAccessList.boothList.filter((item,index) => item.id == booth_id).map(blist => blist.isMapped);
                console.log("isMappedStatusisMappedStatus",isMappedStatus.toString())
                this.setState({ boothLocationUpdated: isMappedStatus.toString() });
              }
             
            
              
      });//votersList:[],boothHouseId:""
  }

  handleOnchangeSelectBoxNew = (ename, evalue) =>{
    this.setState({validateBooth:"",votersListCheck:0,validateBoothForHouse:"",validateUnAssignedVoter:"",validateUnAssignedHouseNo:""});
    const name = ename;
    const value = evalue;

    if(name == "boothId"){
      this.setState({ houseNoIdValue:"",houseNoList: [],votersList:[],boothHouseId:""});
      let getBoothIdName = this.state.boothIdsList.find((a) => a.value == value);
        this.setState({
          boothIdValue: {
              value: getBoothIdName.value,
              label: getBoothIdName.label
          }
      });

      this.setState({ boothId: getBoothIdName.value,boothNo:"Booth "+getBoothIdName.label,boothLocationUpdated:getBoothIdName.attr_mapped });
        
      this.getBoothOnChangeStatus(getBoothIdName.value);
      this.getHousesList(getBoothIdName.value,this.state.verifiedStatus,this.state.mappedStatus);
      this.getStreetsByBooth(getBoothIdName.value);
    
    }else if(name == "verifiedStatus"){
      this.setState({ houseNoIdValue:"",houseNoList: [],votersList:[],boothHouseId:""});
      if(this.state.boothId == ""){
        this.setState({validateBooth:"Please Select Booth" });
        return;
      }
      let getVerifyStatusIdName = this.state.verifyStatusList.find((a) => a.value == value);
        this.setState({
          verifyStatusValue: {
              value: getVerifyStatusIdName.value,
              label: getVerifyStatusIdName.label
          },verifiedStatus:getVerifyStatusIdName.value
      });
      this.getHousesList(this.state.boothId,getVerifyStatusIdName.value,this.state.mappedStatus);

    }else if(name == "mappedStatus"){
      this.setState({ houseNoIdValue:"",houseNoList: [],votersList:[],boothHouseId:""});
      if(this.state.boothId == ""){
        this.setState({validateBooth:"Please Select Booth" });
        return;
      }
      let getMappedStatusIdName = this.state.mappedStatusList.find((a) => a.value == value);
        this.setState({
          mappedStatusValue: {
              value: getMappedStatusIdName.value,
              label: getMappedStatusIdName.label
          },mappedStatus:getMappedStatusIdName.value
      });
      this.getHousesList(this.state.boothId,this.state.verifiedStatus,value);

    }else if(name == "boothIdForHouseCreate"){
      let getBoothIdNameForHouse = this.state.boothIdsList.find((a) => a.value == value);
        this.setState({
          boothIdForHouseCreateValue: {
              value: getBoothIdNameForHouse.value,
              label: getBoothIdNameForHouse.label
          },boothIdForHouseCreate:getBoothIdNameForHouse.value
      });
    }else if(name == "getStreetName"){
      let getStreetIdName = this.state.streetArr.find((a) => a.value == value);
        this.setState({
          getStreetNameValue: {
              value: getStreetIdName.value,
              label: getStreetIdName.label
          },getStreetName:getStreetIdName.value
      });
    }

    this.setState({ [name]: value});
  }
 
  handleOnchangeSelectBox = (e,type) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
    
    
  }

  handleOnchangeInputBox = (e) => {
    this.setState({validateStreet:"",validateForNewHouse:"",validboothHouseVoterId:"",shiftVoterSearchDetails:[],validShiftNewVoterId:"" });
    console.log("handleInputChange", e.target.name, e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    
  }



  getHousesList = (booth_id,verifiedStatus,mappingStatus) => {
    const getHousesObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "locationId" : parseInt(booth_id),
          "verifiedStatus" : verifiedStatus,
          "mappingStatus" : mappingStatus
        }
      ); 

      //gethousesList
     

      getAPICallData(getHousesObj,"getHousesByBoothId")
          .then((res) => res.json())
          .then((data) => {
            if(data.housesList !=null && data.housesList.length>0){
                  let houseNoListArr=[];
                  if(this.state.boothHouseId !=""){
                    let boothHouseIdNameForDash = data.housesList.find((a) => a.boothHouseId == this.state.boothHouseId);
                    this.setState({
                      houseNoIdValue: {
                          value: boothHouseIdNameForDash.boothHouseId,
                          label: "House No -"+boothHouseIdNameForDash.houseNo
                      }
                  });
                }

                  data.housesList.map(item => {
                    const obj = {
                        value: item.boothHouseId,
                        label: "House No -"+item.houseNo,
                        verificationStatus:item.verificationStatus,
                        mappingStatus:item.mappingStatus

                    }
                    houseNoListArr.push(obj);
                });
                this.setState({ houseNoList: houseNoListArr });
              
            }else{
              this.setState({ houseNoList: [] });
            }
      });
  }


  handleOnchangeBoothHouseIdByVoters = (ename, evalue) => {
    const name = ename;
    const value = evalue;

    this.setState({validateBooth:"",votersListLoaderForOnlyHouseNo:1,votersList:[]})
    let getHouseNoListIdName = this.state.houseNoList.find((a) => a.value == value);
        this.setState({
          houseNoIdValue: {
              value: getHouseNoListIdName.value,
              label: getHouseNoListIdName.label
          }
      });

    this.setState({ boothHouseId:value});
    this.getHouseWiseDetails(value);
    
  }


  getHouseWiseDetails = (boothHouseId) => {
    this.setState({boothHouseLocationUpdated:"",votersListLoader:"show",newMemberStatus:"No"});
    voterLinkIndexArr=[];
    const getVotersObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothHouseId" : parseInt(boothHouseId)
        }
      ); 

      //getvotersList
      getAPICallData(getVotersObj,"getVotersByBoothHouseId")
          .then((res) => res.json())
          .then((data) => {
            this.setState({votersListLoader:"hide",votersListLoaderForOnlyHouseNo:0});
            if(data !=null){
              this.setState({newMemberStatus:data.newMemberStatus});
            }else{
              this.setState({newMemberStatus:"No"});
            }
            if(data !=null && data.status == "Success"){
              const getLocationUpdatedBoothHouseId = data.mappingStatus == "Completed";
              if(getLocationUpdatedBoothHouseId){
                this.setState({boothHouseLocationUpdated:"updated"});
              }

              this.getHousesList(this.state.boothId,this.state.verifiedStatus,this.state.mappedStatus);
              
  
              this.setState({votersList:data.votersList,votersListCheck:0 });
            }else{
              this.setState({votersList:[],votersListCheck:1});
            }
           
      });


  }

  voterDeletedReasonModal = (currentVoterDetails,indexVal) => {
    this.setState({voterDeletionDetails:{}});
    let currentUnLinkVoterObj = {
        "voterName" : currentVoterDetails.voterName,
        "age" : currentVoterDetails.age,
        "gender" : currentVoterDetails.gender,
        "boothNo":this.state.boothNo,
        "voterId":currentVoterDetails.voterId
    }
    this.setState({voterDeletionModalShow:true,voterDeletionDetails:currentUnLinkVoterObj });
  }

  

  verifyCurrentHouseMappingVoter = (voterId) => {
    const index = voterLinkIndexArr.indexOf(voterId);
    if (index > -1) { 
      voterLinkIndexArr.splice(index, 1); 
    }else{
      voterLinkIndexArr.push(voterId)
    }
    
    this.setState({voterLinkIndexVal:voterId});
    const currentVoterId = this.state.votersList.filter(checkVoterId =>checkVoterId.voterId == voterId).map(item => item.voterId);

    this.verifyBoothHouseVoters(currentVoterId);
  }

  allHouseMembersVerified = () => {
    const getAllVoterIds = this.state.votersList.map(item => item.voterId);
    this.verifyBoothHouseVoters(getAllVoterIds);
  }

  verifyBoothHouseVoters = (getAllVoterIds) => {
    this.setState({loadingSymbol:"show",messageDisplay:""});
    const verifyAllMemberObj =  
      JSON.stringify({ 
        "username" : "bsa_api",
        "password" : "9D#Jcx!i5B$z@ym4d7K2f",
        "chatId" :this.state.chatID,
        "boothHouseId" : parseInt(this.state.boothHouseId),
        "verifiedList" : getAllVoterIds,
        "removedList" :[],
        "deleteReasonList":[]
      }
    ); 

    //updateBoothHouseVoters
    getAPICallData(verifyAllMemberObj,"updateBoothHouseVoters")
        .then((res) => res.json())
        .then(async(data) => {
          this.setState({loadingSymbol:"hide",messageDisplay:""});
          if(data.status == "Success"){
            this.setState({messageDisplay:"Updated SuccessFully..."});
            //this.getHousesList(this.state.boothId,this.state.verifiedStatus,this.state.mappedStatus);no
            setTimeout(() => {
              this.setState({messageDisplay:""});
              this.getHouseWiseDetails(this.state.boothHouseId);
            }, 1000);
            
          }else{
            this.setState({messageDisplay:"Something Wrong,Please Try Again..."});
          }
          
    });
  }

  
  UnLinkMemberFromHouse = () => {
    this.setState({validateReason:"",unLinkCurrentVoterId:0,unLinkMemberStatusMsg:"",unLinkMemberloader:"show"})
    let removedListArr=[],deleteReasonList=[];

    if(this.state.VoterReasonId == ""){
        this.setState({validateReason:"Please Select Reason" });
        return;
    }

    removedListArr.push(this.state.voterDeletionDetails.voterId);
    var obj={
      "voterId":parseInt(this.state.voterDeletionDetails.voterId),
      "deleteReasonId":parseInt(this.state.VoterReasonId)
    }
    deleteReasonList.push(obj);



    const unLinkMemberObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothHouseId" : parseInt(this.state.boothHouseId),
          "verifiedList" : [],
          "removedList" :removedListArr,
          "deleteReasonsList":deleteReasonList
        }
      ); 

      //updateBoothHouseVoters
      getAPICallData(unLinkMemberObj,"updateBoothHouseVoters")
          .then((res) => res.json())
          .then(async(data) => {
            this.setState({unLinkMemberloader:"hide"});
            if(data.status == "Success"){
              this.setState({unLinkMemberStatusMsg:"Updated SuccessFully...",unLinkCurrentVoterId:this.state.voterDeletionDetails.voterId });
              setTimeout(() => {
                this.setState({voterDeletionModalShow:false,unLinkMemberStatusMsg:""});
                //this.getUnAssignedVotersInABooth(this.state.boothId);
                this.getHouseWiseDetails(this.state.boothHouseId);
              }, 2000);
            }else{
              this.setState({unLinkMemberStatusMsg:"Something Wrong,Please Try Again..." });
            }
            
      });


  }

  checkAllVerified = () => {
      const arrayOfObject = this.state.votersList;
      if(arrayOfObject.some(person => person.status == "Pending")){
          return "Pending";
      }else{
          return "Completed";
      }      
  }

  checkNewMemberStatus = () => {
    const arrayOfObject = this.state.houseNoList;
      if(arrayOfObject.some(person => person.newMemberStatus == "No")){
          return "No";
      }else{
          return "Yes";
      }  
  }

  checkVoterIndexVal = (id) => {
    for(var i in voterLinkIndexArr){
      if(voterLinkIndexArr[i] == id){
          return voterLinkIndexArr[i];
      }
    }
    
  }

  streetBlockShow = () => {
      this.setState({addNewStreetBlock:"show"});
  }

  addNewStreet = () => {
    this.setState({validateStreet:""})

    if(this.state.addStreetName == ""){
        this.setState({validateStreet:"Please Enter Street Name" });
        return;
    }
    const addStreetObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothId" : parseInt(this.state.boothId),
          "streetName" : this.state.addStreetName
        }
      ); 

      //getStreets
      getAPICallData(addStreetObj,"createNewStreet")
          .then((res) => res.json())
          .then(async(data) => {
            if(data.status == "Success"){
              this.setState({streetSuccessMsg:"Add Street SuccessFully..."});
              setTimeout(() => {
                  this.setState({addNewStreetBlock:"hide",addStreetName:"",validateStreet:"",streetSuccessMsg:""});
                  this.getStreetsByBooth(this.state.boothId);
              }, 2000);
             
            }else{
              this.setState({streetSuccessMsg:"Something Wrong,Please Try Again..." });
            }
      });
  }

  getStreetsByBooth = (booth_id) => {
    const streetObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothId" : parseInt(booth_id)
        }
      ); 

      //getStreets
      getAPICallData(streetObj,"getStreetsByBoothId")
          .then((res) => res.json())
          .then(async(data) => {
            if(data.optionsList !=null && data.optionsList.length>0){
              let streetArrListArr=[];
              data.optionsList.map(item => {
                    const obj = {
                        value: item.id,
                        label:item.name,

                    }
                    streetArrListArr.push(obj);
                });
                this.setState({ streetArr: streetArrListArr });

            }else{
              this.setState({streetArr:[]});
            }
            
      });
  }

  locationMappingModal = (mappingType) => {
    this.setState({validateStreet:""})
    this.setState({getCurrentLocationCoordinates:{},boothHouseLocationMsg:"",mappingTypeLocation:"",validateStreet:""});
    if (navigator.geolocation) {
        if(mappingType == "booth"){
          navigator.geolocation.getCurrentPosition(this.showLocationBoothMappingPostions,this.ShowLocationPermissionError)
        }else{
          navigator.geolocation.getCurrentPosition(this.showLocationHouseMappingPostions,this.ShowLocationPermissionError)
        }
        
    }else{
        alert("An unknown error occurred")
    }
  }

  showLocationBoothMappingPostions = (position) => {
    const getCoordinates =  
        { 
          "latitude" : position.coords.latitude,
          "longitude" : position.coords.longitude,
          "accuracy":position.coords.accuracy
        }
        
    this.setState({updateLocationModalShow:true,getCurrentLocationCoordinates:getCoordinates,mappingTypeLocation:"booth"});
    this.getStreetsByBooth(this.state.boothId);
  }

  showLocationHouseMappingPostions = (position) => {
    const getCoordinates =  
        { 
          "latitude" : position.coords.latitude,
          "longitude" : position.coords.longitude,
          "accuracy":position.coords.accuracy
        }
        
    this.setState({updateLocationModalShow:true,getCurrentLocationCoordinates:getCoordinates,mappingTypeLocation:"house"});
    this.getStreetsByBooth(this.state.boothId);
  }

  ShowLocationPermissionError = (error) => {
    switch (error.code){
        case error.PERMISSION_DENIED:
          alert("User Location Permission is Denied,Please Enable Location Services...(Path:Settings > Privacy > Location Services)");
          break;

          case error.POSITION_UNAVAILABLE:
          alert("User Postion Unavailable Denied");
          break;

          default:
            alert("An unknown error occurred")
    }
}

    handleGetLocation = () => {
      this.setState({validateStreet:""})

    if(this.state.getStreetName == ""){
        this.setState({validateStreet:"Please Enter Street Name" });
        return;
    }
      const updateLocationObj =  
          JSON.stringify({ 
              "username" : "bsa_api",
              "password" : "9D#Jcx!i5B$z@ym4d7K2f",
              "chatId":this.state.chatID,
              "boothHouseId" : parseInt(this.state.boothHouseId),
              "boothStreetId" : this.state.getStreetName == "" ? 0 : parseInt(this.state.getStreetName),
              "latitude" : this.state.getCurrentLocationCoordinates.latitude.toString(),
              "longitude" : this.state.getCurrentLocationCoordinates.longitude.toString()
          });
          getAPICallData(updateLocationObj,"updateBoothHouseLocation").then((res) => res.json())
            .then((json) => {
              if(json.status === "Success"){
                  this.setState({boothHouseLocationMsg:"Updated SuccessFully..." });
                  setTimeout(() => {
                    this.setState({updateLocationModalShow:false,boothHouseLocationMsg:"",boothHouseLocationUpdated:"updated",validateStreet:"",getStreetNameValue:""});
                    this.getHousesList(this.state.boothId,this.state.verifiedStatus,this.state.mappedStatus);
                }, 2000);
              }else{
                this.setState({boothHouseLocationMsg:"Something Wrong,Please Try Again..." });
              }
                
          });
    }

    handleGetBoothLocation = () => {
      const updateBoothLocationObj =  
          JSON.stringify({ 
              "username" : "bsa_api",
              "password" : "9D#Jcx!i5B$z@ym4d7K2f",
              "chatId":this.state.chatID,
              "boothId" : parseInt(this.state.boothId),
              "latitude" : this.state.getCurrentLocationCoordinates.latitude.toString(),
              "longitude" : this.state.getCurrentLocationCoordinates.longitude.toString()
          });
          getAPICallData(updateBoothLocationObj,"updateBoothLocation").then((res) => res.json())
            .then((json) => {
              if(json.status === "Success"){
                  this.setState({boothLocationMsg:"Updated SuccessFully..." });
                  setTimeout(() => {
                    this.setState({updateLocationModalShow:false,boothLocationMsg:"",boothLocationUpdated:"Completed"});
                }, 2000);
              }else{
                this.setState({boothLocationMsg:"Something Wrong,Please Try Again..." });
              }
                
          });
    }

    addNewHouseModal = () => {
      this.setState({newHouseModalShow:true});
      
    }

    saveNewHouse = () => {
      this.setState({validateBoothForHouse:"",validateForNewHouse:""})

    if(this.state.boothIdForHouseCreate == ""){
        this.setState({validateBoothForHouse:"Please Select Booth" });
        return;
    }
    if(this.state.addHouseNo == ""){
      this.setState({validateForNewHouse:"Please Enter House No" });
      return;
    }

    
    const addHouseObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothId" : parseInt(this.state.boothIdForHouseCreate),
          "houseNo" : this.state.addHouseNo
        }
      ); 

      //updateBoothHouseVoters
      getAPICallData(addHouseObj,"createNewHouse")
          .then((res) => res.json())
          .then(async(data) => {
            if(data.status == "Success"){
              this.setState({newHouseSuccessMsg:"Updated SuccessFully...",});
              setTimeout(() => {
                this.setState({newHouseModalShow:false,validateBoothForHouse:"",validateForNewHouse:"",newHouseSuccessMsg:""});
              }, 2000);
            }else{
              this.setState({newHouseSuccessMsg:"Something Wrong,Please Try Again..." });
            }
            
      });
    }


    addNewVoterToHouseModal = () => { 
      this.setState({boothHouseVoterId:"",viewExistingVotersList:[],shiftVoterMessageSave:"",shiftNewVoterId:"",shiftVoterSearchDetails:[],validShiftNewVoterId:"",voterSearchMessageDisplay:"",shiftVoterSelectedType:"voterCardNo"});
      
      const getNewMembersObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothId" : parseInt(this.state.boothId),
          "boothHouseId" : parseInt(this.state.boothHouseId),
        }
      ); 

      //getNewMembersObj
      getAPICallData(getNewMembersObj,"getNewMembers")
          .then((res) => res.json())
          .then(async(data) => {
            if(data !=null && data.status == "Success"){
              this.setState({viewExistingVotersList:data.votersList });
            }else{
              this.setState({viewExistingVotersList:[]});
            }
            
            
      });

      this.setState({addNewVoterModalShow:true});
    }

    addNewVoterToHouse = () => {
      this.setState({validateNewName:"",validateNewVoterId:"",validateNewGender:"",validateNewAge:"",newMemberAddedMsg:""})
      
      var regName = /^[a-zA-Z ]*$/;
      if(this.state.newVoterName == ""){
          this.setState({validateNewName:"Please Enter Name" });
          return;
      }

      if(this.state.newVoterName.length <=3){
        this.setState({validateNewName:"Please Enter Valid Name" });
        return;
      }

      if(!regName.test(this.state.newVoterName)){
        this.setState({validateNewName:"Please Enter Valid Name" });
        return;
      }
      if(this.state.newVoterMobileNumber == ""){
          this.setState({validateNewMobileNo:"Please Enter Mobile No" });
          return;
      }
      if(!this.state.newVoterMobileNumber.match(/^[0-9]+$/)){
        this.setState({validateNewMobileNo:"Please Enter Valid Mobile No" });
        return;
      }
      


      /*if(this.state.newVoterId == ""){
        this.setState({validateNewVoterId:"Please Enter Voter Card No" });
        return;
      }

      if(this.state.newVoterId.match(' ')){
        this.setState({validateNewVoterId:"Please Enter Valid Voter Card No(remove space)" });
        return;
      }*/

      if(this.state.newGender == ""){
        this.setState({validateNewGender:"Please Select Gender" });
        return;
      }
      if(this.state.newAge == ""){
        this.setState({validateNewAge:"Please Enter Age" });
        return;
      }
      if(!this.state.newAge.match(/^[0-9]+$/)){
        this.setState({validateNewAge:"Please Enter Valid Age" });
        return;
      }

      const addNewVoterObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothHouseId" : parseInt(this.state.boothHouseId),
          "voterName":this.state.newVoterName,
          //"voterCardNo":this.state.newVoterId,
          "gender":this.state.newGender,
          "age":parseInt(this.state.newAge),
          "relativeName":this.state.newRelativeName,
          "relation":this.state.newRelationName,
          "mobileNo":this.state.newVoterMobileNumber
          //"serialNo":1

        }
      ); 

      //addNewVoter
      getAPICallData(addNewVoterObj,"addNewVoter")
          .then((res) => res.json())
          .then(async(data) => {
            if(data !=null){
              if(data.status == "Success"){
                this.setState({newMemberAddedMsg:"Member Added SuccessFully..." });
                setTimeout(() => {
                  this.setState({newMemberAddedMsg:"",addNewVoterModalShow:false,validateNewName:"",validateNewVoterId:"",validateNewGender:"",validateNewAge:"",validateNewMobileNo:"",newMemberAddedMsg:""});
                  this.setState({newVoterName:"",newVoterId:"",newGender:"",newAge:"",newRelativeName:"",newRelationName:"",newVoterMobileNumber:""})
                  this.getHouseWiseDetails(this.state.boothHouseId);
              }, 2000);
              }else if(data.status == "Fail"){
                if(data.message == "Invalid Inputs"){
                  this.setState({newMemberAddedMsg:"Please Enter Valid Inputs...." });
                }else{
                  this.setState({newMemberAddedMsg:data.message });
                }
                
              }
            }else{
              this.setState({newMemberAddedMsg:"Something Wrong,Please Try Again..." });
            }
           
            
            
      });

    }

    undoAllVotersBySelectedhouse = () => {
      this.setState({loadingSymbol:"show",messageDisplay:""});
      const undoAllVotersObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothHouseId" : parseInt(this.state.boothHouseId)
        }
      ); 

      //getvotersList
      getAPICallData(undoAllVotersObj,"undoVerification")
          .then((res) => res.json())
          .then((data) => {
            this.setState({loadingSymbol:"hide"});
            if(data.status == "Success"){
              this.setState({messageDisplay:"Updated SuccessFully..."});
              setTimeout(() => {
                this.setState({messageDisplay:""});
                this.getHouseWiseDetails(this.state.boothHouseId);
            }, 1000);
            }else{
                this.setState({messageDisplay:"Something Wrong,Please Try Again..."});
            }
           
      });
    }

    getVoterCardSearchDetails = () => {
      this.setState({validShiftNewVoterId:"",voterSearchMessageDisplay:"",shiftVoterSearchDetails:[],validboothHouseVoterId:"",boothHouseVoterId:""})
      

      if(this.state.shiftNewVoterId == ""){
        this.setState({validShiftNewVoterId:"Please Enter Valid Information" });
        return;
      }
      /*if(this.state.shiftNewVoterId.match(' ')){
        this.setState({validShiftNewVoterId:"Please Enter Valid Voter Card No(remove space)" });
        return;
      }*/

      var voterCardNoStr="",voterNameStr="",houseNoStr="",genderStr="";
      if(this.state.shiftVoterSelectedType == "voterCardNo"){
        voterCardNoStr=this.state.shiftNewVoterId;
      }else if(this.state.shiftVoterSelectedType == "voterName"){
        voterNameStr=this.state.shiftNewVoterId;
      }else if(this.state.shiftVoterSelectedType == "gender"){
        genderStr=this.state.shiftNewVoterId;
      }else if(this.state.shiftVoterSelectedType == "houseNo"){
        houseNoStr=this.state.shiftNewVoterId;
      }

      this.setState({shiftNewVoterLoderSymbol:"show"})

      const voterSearchObj =  
        JSON.stringify({ 
          "username":"bsa_api",
          "password":"9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "voterCardNo" : voterCardNoStr,
          "voterName" : voterNameStr,
          "houseNo" : houseNoStr,
          "gender" : genderStr,
          "boothId":parseInt(this.state.boothId)
        }
      ); 

      //addNewVoter
      getAPICallData(voterSearchObj,"voterSearch")
          .then((res) => res.json())
          .then(async(data) => {
            this.setState({shiftNewVoterLoderSymbol:"hide"});

            if(data!=null && data.status == "Success"){
              if(data.votersList !=null && data.votersList.length>0){
                this.setState({shiftVoterSearchDetails:data.votersList});
                setTimeout(() => {
                  this.setState({validShiftNewVoterId:"",voterSearchMessageDisplay:""});
              }, 1000);
              }else{
                this.setState({shiftVoterSearchDetails:[],validShiftNewVoterId:"",voterSearchMessageDisplay:"No Voters Found..."});
              } 
            }else{
              this.setState({shiftVoterSearchDetails:[],validShiftNewVoterId:"",voterSearchMessageDisplay:"No Voters Found..."});
            }
      });
    }

     validate = () => {
      var radios = document.getElementsByName("boothHouseVoterId");
      var formValid = false;
  
      var i = 0;
      while (!formValid && i < radios.length) {
          if (radios[i].checked) formValid = true;
          i++;        
      }
  
      if (!formValid) {
        this.setState({validboothHouseVoterId:"Please Select Atleast One"});
        return formValid;
      }
  };

  setBoothHouseVoterId = (e) => {
    this.setState({boothHouseVoterId:e.target.value})
  }

    updateVoterBoothHouse = () => {
      this.validate();

      if(this.state.boothHouseVoterId == ""){
        return;
      }
      this.setState({shiftVoterLoderSymbolSave:"show"})
      
        const updateVoterBoothHouseObj =  
          JSON.stringify({ 
            "username":"bsa_api",
            "password":"9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :this.state.chatID,
            "boothHouseVoterId" : parseInt(this.state.boothHouseVoterId),
            "boothHouseId":parseInt(this.state.boothHouseId)
          }
        ); 

        //updateVoterBoothHouse
        getAPICallData(updateVoterBoothHouseObj,"updateVoterBoothHouse")
            .then((res) => res.json())
            .then(async(data) => {
              this.setState({shiftVoterLoderSymbolSave:"hide"})
              if(data!=null && data.status == "Success"){
                this.setState({shiftVoterMessageSave:"Updated SuccessFully..."});
                setTimeout(() => {
                    this.setState({addNewVoterModalShow:false,shiftVoterMessageSave:"",shiftNewVoterId:"",shiftVoterSearchDetails:[],validShiftNewVoterId:"",voterSearchMessageDisplay:"",boothHouseVoterId:"",validboothHouseVoterId:""});
                    this.getHouseWiseDetails(this.state.boothHouseId);
                }, 1500);
              }else{
                this.setState({shiftVoterMessageSave:"Something Wrong,Please Try Again..."});
              }
          });
    }

    viewExistingVotersDisplay = () => {
      this.setState({viewExistingVotersOpen:!this.state.viewExistingVotersOpen });
    }

    isFilterBlockOpen = () => {
      this.setState({filterBlockOpen:!this.state.filterBlockOpen });
    }

    setSelColorCode = () => {
      const verificationStatusStr = this.state.houseNoList.filter(item => item.boothHouseId == this.state.boothHouseId).map(item1 => item1.verificationStatus)
      const mappingStatusStr = this.state.houseNoList.filter(item => item.boothHouseId == this.state.boothHouseId).map(item1 => item1.mappingStatus)

      if(verificationStatusStr.toString() == "Pending" && mappingStatusStr.toString() == "Pending"){
        return "color_red";
      }else if(verificationStatusStr.toString() == "Completed" && mappingStatusStr.toString() == "Pending"){
        return "color_yellow";
      }else if(verificationStatusStr.toString() == "Completed" && mappingStatusStr.toString() == "Completed"){
        return "color_green";
      }else if(verificationStatusStr.toString() == "Pending" && mappingStatusStr.toString() == "Completed"){
        return "color_yellow";
      }
    }
    getColorCode = (verificationStatus,mappingStatus) => {
      
      if(verificationStatus == "Pending" && mappingStatus == "Pending"){
        return "color_red";
      }else if(verificationStatus == "Completed" && mappingStatus == "Pending"){
        return "color_yellow";
      }else if(verificationStatus == "Completed" && mappingStatus == "Completed"){
        return "color_green";
      }else if(verificationStatus == "Pending" && mappingStatus == "Completed"){
        return "color_yellow";
      }
      
    }

    undoCurrentHouseMappingVoter = (voterId) => {

      const undoSelectedMemberObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothHouseId" : parseInt(this.state.boothHouseId),
          "voterId":parseInt(voterId),
        }
      ); 

      //undoVerification
      getAPICallData(undoSelectedMemberObj,"undoVerification")
          .then((res) => res.json())
          .then((data) => {
            this.setState({loadingSymbol:"hide"});
            if(data.status == "Success"){
              this.setState({messageDisplay:"Updated SuccessFully..."});
              setTimeout(() => {
                this.setState({messageDisplay:""});
                this.getHouseWiseDetails(this.state.boothHouseId);
            }, 1000);
            }else{
                this.setState({messageDisplay:"Something Wrong,Please Try Again..."});
            }
           
      });
     
    }

    uploadFeedBackForms = () => {
      this.setState({uploadFeedBackFormModal:true});
    }
    toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
  }))
    uploadMultipleFiles = (e) => {
      this.setState({validateUploadForms:"" });
          let fileObj=[],fileArray=[];
          fileObj.push(e.target.files);
              for (let i = 0; i < fileObj[0].length; i++) {
                  this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                  .then(dataUrl => {
                      var obj ={
                          fileId:i,
                          fileName:fileObj[0][i].name,
                          fileType:fileObj[0][i].type,
                          data:dataUrl
                      }
                      fileArray.push(obj)
                  });
              }
              setTimeout(() => {
                  this.setState({ file: fileArray })
              }, 500);
          
    }

    uploadFeedBackFormsFiles = () => {
      this.setState({ fileUploaderLoaderImg: "show" });
     
        setTimeout(() => {
            this.setState({ fileUploaderLoaderImg: "hide" });
            this.setState({fileUploadedStatusMsg:"Uploaded SuccessFully..."});
        }, 2000);
        setTimeout(() => {
          this.setState({fileUploadedStatusMsg:"",uploadFeedBackFormModal:false});
      }, 4000);
    }

  render() {
      return (
          <Container fluid>
                  
                      <Row>
                        <Col sm={12}>
                            <div className='row'>
                              <div className='width_80'>
                                
                                    {
                                      this.state.boothIdsList.length == 1 ?
                                      (
                                        <div className='bg_light_blue mt-2'>
                                        <h6 className='f-14'>Booth No - {this.state.boothNo}
                                        <span className='booth_mapping_icon cursor_pointer float-end' onClick={() => this.locationMappingModal("booth")} >
                                            <i className="fa-solid fa-location-crosshairs"></i>
                                        </span>
                                          {
                                              this.state.boothLocationUpdated === "Completed" ?
                                              ( <i className="fa-solid fa-location-dot f-18 ms-2 color_green float-end"></i> )
                                              :
                                              (
                                                  <i className="fa-solid fa-location-dot f-18 ms-2  color_red float-end"></i> 
                                              )
                                          }
                                        
                                        </h6>
                                        </div>
                                      )
                                      :
                                      (
                                        <Col className='mt-2'>
                                          <label className="form-label f-14 fw-bold">Booth 
                                          {
                                                this.state.boothLocationUpdated === "Completed" ?
                                                ( <i className="fa-solid fa-location-dot f-16 ms-2 pos_booth_icon_left color_green"></i> )
                                                :
                                                (
                                                    <i className="fa-solid fa-location-dot f-16 ms-2 pos_booth_icon_left color_red"></i> 
                                                )
                                            }
                                            <span className='booth_mapping_icon1 cursor_pointer' onClick={() => this.locationMappingModal("booth")} >
                                                <i className="fa-solid fa-location-crosshairs"></i>
                                            </span>
                                          </label>
                                          <SelectBox
                                            stateData={this.state.boothIdValue}
                                            handleChange={this.handleOnchangeSelectBoxNew}
                                            name="boothId"
                                            options={this.state.boothIdsList}
                                            placeholderName={'Select Booth'}
                                            dropdownIndicator={{ DropdownIndicator }}
                                        />
                                        </Col>
                                      )
                                    }
                                    

                              
                              </div>
                              <div className='width_20'>
                                <OverlayTrigger key={"top"} placement={"top"}
                                    overlay={
                                      <Tooltip id={`tooltio-Filter`}>
                                        <h6 className='f-14'>Filter</h6>
                                      </Tooltip>
                                    }
                                  >
                                  <i class="fa-solid fa-filter filter_pos_alignment_cls" onClick={() => this.isFilterBlockOpen()}></i>     
                                  </OverlayTrigger>
                                
                              </div>
                              
                            </div>
                          
                          </Col>
                        </Row>
                        <Row>
                          {
                              this.state.filterBlockOpen == false ? 
                              (
                                <Col sm={12}>
                                  <Row>
                                    <Col  className='mt-2'>
                                      <label className="form-label f-14 fw-bold">Verified Status</label>
                                      <SelectBox
                                            stateData={this.state.verifyStatusValue}
                                            handleChange={this.handleOnchangeSelectBoxNew}
                                            name="verifiedStatus"
                                            options={this.state.verifyStatusList}
                                            placeholderName={'All'}
                                            dropdownIndicator={{ DropdownIndicator }}
                                        />

                                     
                                    </Col>
                                    <Col className='mt-2'>
                                      <label className="form-label f-14 fw-bold">Mapped Status </label>
                                      <SelectBox
                                            stateData={this.state.mappedStatusValue}
                                            handleChange={this.handleOnchangeSelectBoxNew}
                                            name="mappedStatus"
                                            options={this.state.mappedStatusList}
                                            placeholderName={'All'}
                                            dropdownIndicator={{ DropdownIndicator }}
                                        />

                                     
                                    </Col>   
                                  </Row>
                              </Col>
                              )
                              :
                              ("")
                            }
                        </Row>
                        <Row>
                        <Col className='mt-2'>
                          <label className="form-label f-14 fw-bold">House No <span className='float-end f-12 add-new-house-span color_green cursor_pointer' onClick={() => this.addNewHouseModal()}><i className="fa-solid fa-plus"></i> Add</span> <span className='color_red f-9'>{this.state.validateHouseNo}</span></label>
                          <SelectBox
                                stateData={this.state.houseNoIdValue}
                                handleChange={this.handleOnchangeBoothHouseIdByVoters}
                                name="boothHouseId"
                                options={this.state.houseNoList}
                                placeholderName={'Select House'}
                                dropdownIndicator={{ DropdownIndicator }}
                                styles={colourStylesVal}
                            />
                            
                          
                          
                        </Col>
                        <h6 className='color_red f-12 mt-2'>{this.state.validateBooth}</h6>
                    </Row>
                    {/*<Row>
                        <Col className='mt-3'>
                            <Button type="button" className='btn btn-info fw-bold f-14 float-end' onClick={() => this.getHouseWiseDetails()}>Get Details</Button>    
                        </Col>
                    </Row>*/}

                    {
                      this.state.votersListLoader == "show" && this.state.votersListLoaderForOnlyHouseNo == 1 ?
                      (
                        <Row>
                          <Col sm={12}>
                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                          </Col>
                        </Row>
                      )
                      :
                      ("")
                    }

                    {
                    this.state.votersList.length>0 ?
                      (
                        
                        <Row>
                            <Col sm={12}>
                              <div className='bg_light_blue mt-2'>
                                  <h6 className='f-14'><b>Step 1 :</b> 
                                    <OverlayTrigger key={"top"} placement={"top"}
                                          overlay={
                                            <Tooltip id={`tooltio-link-house-map`}>
                                              <h6 className='f-14'>{this.state.boothHouseLocationUpdated === "updated" ? "House Mapping Updated" : "House Mapping Not Updated"}</h6>
                                            </Tooltip>
                                          }
                                        >
                                          {
                                            this.state.boothHouseLocationUpdated === "updated" ?
                                            (<i className="fa-solid fa-location-dot f-i-22 ms-4 color_green"></i> )
                                            :
                                            (<i className="fa-solid fa-location-dot f-i-22 ms-4 color_red"></i> )
                                          }
                                        
                                          
                                        </OverlayTrigger>
                                        <OverlayTrigger key={"top"} placement={"top"}
                                          overlay={
                                            <Tooltip id={`tooltio-link-house-location`}>
                                              <h6 className='f-14'>Location Updated For House</h6>
                                            </Tooltip>
                                          }
                                        >
                                        <span className='bg_white_icon cursor_pointer ml-4' onClick={() => this.locationMappingModal("house")} >
                                            <i className="fa-solid fa-location-crosshairs"></i>
                                        </span>
                                        </OverlayTrigger>
                                  </h6>
                              </div>
                            </Col>
                            <Col sm={12}>
                                <div className='bg_light_blue mt-3'>
                                    <h6 className='f-14'><b>Step 2 :</b> 

                                    <OverlayTrigger key={"top"} placement={"top"}
                                        overlay={
                                          <Tooltip id={`tooltio-link-add-new-voter`}>
                                            <h6 className='f-14'>Upload FeedBack Forms</h6>
                                          </Tooltip>
                                        }
                                      >
                                        <span className='bg_white_icon_add cursor_pointer float-end' onClick={() => this.uploadFeedBackForms("")} >
                                          <i class="fa-solid fa-circle-info"></i>
                                        
                                        </span>
                                       </OverlayTrigger>
                                     
                                      <OverlayTrigger key={"top"} placement={"top"}
                                        overlay={
                                          <Tooltip id={`tooltio-link-add-new-voter`}>
                                            <h6 className='f-14'>Add New Voter</h6>
                                          </Tooltip>
                                        }
                                      >
                                        <span className='bg_white_icon_add cursor_pointer float-end' onClick={() => this.addNewVoterToHouseModal("")} >
                                          {
                                             this.state.newMemberStatus == "No" ? 
                                             (<i class="fa-sharp fa-solid fa-user-plus"></i>)
                                             :
                                             (<i class="fa-sharp fa-solid fa-user-plus color_green"></i>)
                                          }
                                        
                                        </span>
                                       </OverlayTrigger>   

                                      
                                       {/*
                                        this.checkAllVerified() != "Completed" ?
                                        (
                                          <>
                                          {
                                            this.state.loadingSymbol === "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                          }
                                          <span className='verify-All-cls float-end ms-3' onClick={() => this.allHouseMembersVerified()}>Verify All</span>
                                          </>
                                        )
                                        :
                                        ("")
                                        */}
                                      {
                                        this.checkAllVerified() == "Completed" ?
                                        (
                                          <>
                                            {
                                              this.state.loadingSymbol === "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>) : ("")
                                            }
                                            <span className='undo-cls float-end' onClick={() => this.undoAllVotersBySelectedhouse()}>
                                              <i className="fa-solid fa-rotate-left"></i>
                                            </span>
                                            <span className='verify-not-All-cls color_green border_green float-end'>Verifyed 
                                              <i className="fa-solid fa-square-check ms-2"></i>
                                            </span>
                                            
                                          </>
                                          )
                                        :
                                        (<span className='verify-not-All-cls color_red border_red float-end'>Not Verified <i className="fa-sharp fa-solid fa-circle-xmark"></i></span>)
                                      }
                                      
                                      
                                      
                                    </h6>
                                    <h6 className='f-14 mt-4'>House No-{this.state.votersList[0].houseNo} Details :</h6>
                                    <table className='table table_custom mb-2'>
                                        <thead>
                                            <tr>
                                              <th style={{minWidth:"40%"}}>Name</th>
                                              <th>Age</th>
                                              <th>Gender</th>
                                              <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {
                                              this.state.votersList.map((item,index) => {
                                              return(
                                                
                                                <tr>
                                                  <td style={{textAlign:"left"}}>{item.voterName} 
                                                    {
                                                      item.status == "Detached" ?
                                                      (<><br/><span className='f-12 color_red'>{item.deleteReason == "House Shifting" ? "House Shifted" : item.deleteReason}</span></>) : ("")
                                                    }
                                                  </td>
                                                  <td>{item.age}</td>
                                                  <td>{item.gender} </td>
                                                  <td> 
                                                  {
                                                    item.status == "Completed" ?
                                                    (
                                                      <Row>
                                                        <Col>
                                                        <div className='flex_block'>
                                                        <OverlayTrigger key={"top"} placement={"top"}
                                                            overlay={
                                                              <Tooltip id={`tooltio-unlink`}>
                                                                <h6 className='f-14'>UnLink Member From House</h6>
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Button type="button" className="btn-custom-icon disabled_cursor_pointer"   disabled >
                                                              <i className= "fa-solid fa-link-slash f-14 ms-4 color_c3c3c3"></i>  
                                                            </Button>
                                                          </OverlayTrigger>
                                                          <OverlayTrigger key={"top"} placement={"top"}
                                                            overlay={
                                                              <Tooltip id={`tooltio-link`}>
                                                                <h6 className='f-14'>Member Has Linked This House</h6>
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Button type="button" className='btn-custom-icon'>
                                                              <i className= "fa-solid fa-circle-check f-14 ms-4 color_green" onClick={() => this.undoCurrentHouseMappingVoter(item.voterId)}></i> 
                                                            </Button>
                                                            
                                                          </OverlayTrigger>
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                    )
                                                    :
                                                    (
                                                      item.status == "Detached" ? 
                                                      (
                                                        <Row>
                                                          <Col>
                                                          <div className='flex_block'>
                                                          <OverlayTrigger key={"top"} placement={"top"}
                                                              overlay={
                                                                <Tooltip id={`tooltio-unlink`}>
                                                                  <h6 className='f-14'>Member Detached From This House</h6>
                                                                </Tooltip>
                                                              }
                                                            >
                                                              <i className= "fa-solid fa-link-slash f-14 ms-4 color_red cursor_pointer"></i> 
                                                            </OverlayTrigger>

                                                              <i className= "fa-solid fa-circle-check f-14 ms-4 color_c3c3c3"></i> 
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                      )
                                                      :
                                                      (
                                                        <Row>
                                                          <Col sm={12}>
                                                            <div className='flex_block'>
                                                            <OverlayTrigger key={"top"} placement={"top"}
                                                                overlay={
                                                                  <Tooltip id={`tooltio-unlink`}>
                                                                    <h6 className='f-14'>UnLink Member From House</h6>
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                {
                                                                  this.checkVoterIndexVal(item.voterId) == item.voterId ?
                                                                  (
                                                                    <Button type="button" className="btn-custom-icon disabled_cursor_pointer"   disabled >
                                                                      <i className= "fa-solid fa-link-slash f-14 ms-4 color_c3c3c3"></i>  
                                                                    </Button>
                                                                  )
                                                                  :
                                                                  (
                                                                    <i className="fa-solid fa-link-slash f-14 ms-4 color_000 cursor_pointer"  onClick={() => this.voterDeletedReasonModal(item,index)}></i>  
                                                                  )
                                                                  }
                                                              </OverlayTrigger>
                                                              <OverlayTrigger key={"top"} placement={"top"}
                                                                overlay={
                                                                  <Tooltip id={`tooltio-link`}>
                                                                    <h6 className='f-14'>Link Member From House</h6>
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                <>
                                                                <i className= "fa-solid fa-circle-check f-14 ms-4 color_000 cursor_pointer" onClick={() => this.verifyCurrentHouseMappingVoter(item.voterId)}></i> 
                                                                </>
                                                                
                                                              </OverlayTrigger>
                                                            </div>
                                                          </Col>
                                                          
                                                          
                                                        </Row>
                                                      )
                                                      
                                                    )
                                                  }
                                                    
                                                    
                                                   
                                                    </td>
                                                </tr>
                                              )
                                              
                                            })
                                          }
                                          
                                        </tbody>
                                    </table>
                                    <h6 className='mb-0'>
                                   
                                      

                                     

                                      
                                      
                                      </h6>
                                      {
                                        this.state.messageDisplay != "" ? (<h6 className='f-14 text-center mt-4 fw-bold color_green'>{this.state.messageDisplay}</h6>) : ("")
                                      }
                                </div>     
                            </Col> 

                                 

                      </Row>
                      )
                      :
                      (
                        <>
                          {
                          this.state.votersListCheck == 1 ?
                          (<h6 className='f-14 text-center mt-4'>No Voters Availble in this House</h6>)
                          :
                          ("")
                        }
                        </>
                      )
                        
                    }  
                    
                              
                    
                    <UpdateLocationModal
                        show={this.state.updateLocationModalShow}
                        onHide={() => this.setState({ updateLocationModalShow: false })}
                        stateData = {this.state}
                        paddNewStreet={this.addNewStreet}
                        pstreetBlockShow={this.streetBlockShow}
                        phandleOnchangeInputBox = {this.handleOnchangeInputBox}
                        phandleOnchangeSelectBox={this.handleOnchangeSelectBoxNew}
                        phandleGetLocation={this.handleGetLocation}
                        phandleGetBoothLocation={this.handleGetBoothLocation}
                    />
                    <UnLinkMemberFromHouseModal
                      show={this.state.voterDeletionModalShow}
                      onHide={() => this.setState({ voterDeletionModalShow: false })}
                      stateData = {this.state}
                      pUnLinkMemberFromHouse={this.UnLinkMemberFromHouse}
                      phandleOnchangeSelectBox={this.handleOnchangeSelectBox}
                    />
                    <AddNewHouseModal
                       show={this.state.newHouseModalShow}
                       onHide={() => this.setState({ newHouseModalShow: false })}
                       stateData = {this.state}
                       phandleOnchangeInputBox = {this.handleOnchangeInputBox}
                       phandleOnchangeSelectBox={this.handleOnchangeSelectBoxNew}
                       psaveNewHouse={this.saveNewHouse}
                    />
                    <AddNewVoterModel
                      show={this.state.addNewVoterModalShow}
                      onHide={() => this.setState({ addNewVoterModalShow: false })}
                      phandleOnchangeInputBox = {this.handleOnchangeInputBox}
                      phandleOnchangeSelectBox={this.handleOnchangeSelectBox}
                      paddNewVoterToHouse={this.addNewVoterToHouse}
                      stateData = {this.state}
                      pgetVoterCardSearchDetails={this.getVoterCardSearchDetails}
                      pupdateVoterBoothHouse={this.updateVoterBoothHouse}
                      pviewExistingVotersDisplay={this.viewExistingVotersDisplay}
                      psetBoothHouseVoterId={this.setBoothHouseVoterId}
                    />
                    <UploadFeedBackForm 
                        show={this.state.uploadFeedBackFormModal}
                        onHide={() => this.setState({ uploadFeedBackFormModal: false })}
                        campaignDetailsId="0"
                        stateData={this.state}
                        puploadFeedBackFormsFiles={this.uploadFeedBackFormsFiles}
                        puploadMultipleFiles={this.uploadMultipleFiles}
                    />
                      
              </Container>
          
      )
  }
}

export default (BoothAndHouseMapping);
