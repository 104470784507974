
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button  } from 'react-bootstrap';
import "./EventsAndActivities.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import arrowLeft from '../../images/arrow-left.png';
import users from '../../images/users.png';
import image from '../../images/image.png';
import file_image from '../../images/file-image.png';
import file_pdf from '../../images/file-pdf.png';
import loader from '../../images/loader.gif';
import { saveAs } from "file-saver";
import axios from 'axios'


class EventMaterialSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            event:props?.location?.params?.event == undefined ? "" : props?.location?.params?.event,
            eventStatus:props?.location?.params?.eventStatus == undefined ? "" : props?.location?.params?.eventStatus,
            campaignDetailsId:props?.location?.params?.campaignDetailsId == undefined ? "" : props?.location?.params?.campaignDetailsId,
            eventType:props?.location?.params?.eventType == undefined ? "" : props?.location?.params?.eventType,
            isAttended:props?.location?.params?.isAttended == undefined ? "" : props?.location?.params?.isAttended,
            surveyType:props?.location?.params?.surveyType == undefined ? "" : props?.location?.params?.surveyType,
            pollManagementPage:props?.location?.params?.pollManagementPage == undefined ? "" : props?.location?.params?.pollManagementPage,
            MLCPage:props?.location?.params?.MLCPage == undefined ? "" : props?.location?.params?.MLCPage,
            eventMateialData:[],
            eventMateialDataCheck:0,
            downloadfile:"hide",
            downloadFileId:0
            
        }
    }

    componentDidMount() {
        if(this.state.event == "" || this.state.eventStatus == ""){
            this.props.history.push({pathname:"/events",params: { navbarDisplay:"hide"}});
            return;
        }
        setTimeout(() => {
            this.getCampaignAttendedCount();
            this.getCampaignMaterial();
        }, 1000);

    }

    getCampaignAttendedCount = () => {
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignDetailsId":this.state.campaignDetailsId,
                
            });
             

        getCampaignAPICallData(userAccessObj,"getCampaignAttendedCount")
            .then((res) => res.json())
            .then((data) => { 
                if(data !=null && data.attendedCount>0){
                    this.setState({campaignAttendedCount:data.attendedCount});
                }else{
                    this.setState({campaignAttendedCount:0});
                }
             });
                
           
    }

    getCampaignMaterial = () => {
        const userAccessObj =  
            JSON.stringify({ 
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "campaignId":this.state.event.campaignId,
                
            });
             

        getCampaignAPICallData(userAccessObj,"getCampaignMaterial")
            .then((res) => res.json())
            .then((data) => { 
                if(data !=null && data.length>0){
                    this.setState({eventMateialData:data,eventMateialDataCheck:0});
                }else{
                    this.setState({eventMateialData:[],eventMateialDataCheck:1});
                }
             });
                
           
    }

    downloadEventMaterial = async(file) => {
        //this.setState({downloadfile:"show",downloadFileId:file.fileId});
        /*this.setState({downloadfile:"show",downloadFileId:file.fileId});
        const downloadResult = await fetch(
            file.filePath
        );
        const blob = await downloadResult.blob();
        saveAs(blob, file.fileName);
        this.setState({downloadfile:"hide",downloadFileId:0});*/
        
        /*axios({
            url: file.filePath, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', file.fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.setState({downloadfile:"hide"});
        
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });

		fetch(file.filePath)
			.then(response => {
                response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = file.fileName;
                    a.target="_blank"
					a.click();
                    this.setState({downloadfile:"hide"});
				});
				//window.location.href = response.url;
		});

        fetch(file.filePath)
			.then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
					this.setState({downloadfile:"hide",downloadFileId:0});
                    var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
                    window.open(file.filePath, "_blank", strWindowFeatures);
				});
				//window.location.href = response.url;
		});*/

        var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
        window.open(file.filePath, "_blank", strWindowFeatures);

        
        
	}

    render() {
        return (
            <section >
                <div className='bg_red_event'>
                    <div className='flex_event_name_top'>
                         <div>
                         <Link to={{pathname: "/events",params: {navbarDisplay:"hide"}}}> <Image src={arrowLeft} alt="Arrow" ></Image></Link>
                      
                    
                         </div>  
                         <div>
                            <h6 className='color_white fw-bold mt-3'>Today Party Event & Activities</h6>
                         </div> 
                    </div>
                    <div className='white_block_with_left_border mt-2'>
                        <h6 className='f-14 fw-bold'>{this.state.event.campaignName} <span className='float-end'><Image src={users} alt="users" ></Image> {this.state.campaignAttendedCount}</span></h6>
                        <div className='flex_PA mt-2'>
                            <div>
                                <h6 className='f-12'>Location</h6>
                                <h6 className='f-12'>{this.state.event.level} - {this.state.event.location}</h6>
                            </div>
                            
                        </div>
                        <div className='mt-1'>
                            <h6 className='f-12'>Date</h6>
                            <h6 className='f-12'>{this.state.event.fromDate} To {this.state.event.todate}</h6>
                        </div>
                    
                    </div>
                </div>
                <Container>
                    <Row>
                        <Col sm={12} className="mt-4">
                            <h6 className='f-14'>DOWNLOAD EVENT MATERIAL : </h6>
                            {
                                this.state.eventMateialData.length == 0?
                                (
                                    this.state.eventMateialDataCheck == 1 ?
                                    (<Col className='f-14 text-center'>No Data Available</Col>)
                                    :
                                    (
                                        <Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                        </Col>
                                    )
                                )
                                :
                                (
                                    <div className='photos_gallery_flex'>
                                        {
                                            this.state.eventMateialData.map((item,index) => {
                                            return(
                                                <button type="button" className="event_material_block cursor_pointer" onClick={() => this.downloadEventMaterial(item)}>
                                                    {
                                                        item.fileType == "jpg" || item.fileType == "png" ?
                                                        (<Image src={image} alt="image"></Image>)
                                                        :
                                                        (
                                                            item.fileType == "psd" ?
                                                            (<Image src={file_image} alt="file_image"></Image>)
                                                            :
                                                            (
                                                                <Image src={file_pdf} alt="file_pdf"></Image>
                                                            )
                                                        )
                                                    }
                                                    <h6 className='f-12 mt-3'>{item.fileName}</h6>
                                                    {
                                                   item.fileId == this.state.downloadFileId && this.state.downloadfile == "show" ? 
                                                    (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                                    :
                                                    ("")
                                                }
                                                </button>
                                            )
                                            })
                                            
                                            
                                        }
                                          
                                    </div>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(EventMaterialSection);
  