
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService'

class CadreRegistrationUserWiseDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            //userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            isVolunteermobileNo:props?.location?.params?.isVolunteermobileNo == undefined ? "" : props?.location?.params?.isVolunteermobileNo,
            userWiseDataArr:[],
            userWiseDataCheck:0,
            userWiseDataLoadingSymbol:"hide",
            cadreFromDate:moment().format('YYYY-MM-DD'),
            cadreToDate:moment().format('YYYY-MM-DD'),
        }
    }

    componentDidMount() {
        this.getDayWiseVolunteerRegistrationsCount();
        
    }
    getDayWiseVolunteerRegistrationsCount = () => {
        this.setState({userWiseDataArr:[],userWiseDataCheck:0,userWiseDataLoadingSymbol:"show"});
        const obj =  
                JSON.stringify({ 
                    "volunteerMobileNo" : this.state.isVolunteermobileNo,
                    "fromDate" : this.state.cadreFromDate, 
                    "toDate" : this.state.cadreToDate
                    
                });
                
        getAPICallDataForOnlyBSA(obj,"Cadre/getDayWiseVolunteerRegistrationsCnt")
            .then((res) => res.json())
            .then((content) => {
                this.setState({userWiseDataLoadingSymbol:"hide"});
                if(content !=null && content.length>0){
                    this.setState({userWiseDataArr:content,userWiseDataCheck:0});
                   
                }else{
                    this.setState({userWiseDataArr:[],userWiseDataCheck:1});
                }
            });
    }

    
    userWiseRegColumns() {
        let columns = [
            
            {
                dataField: "Date",
                text: "Date",
                sort: true,
            },
            {
                dataField: "Telegarm",
                text: "Telegarm",
                sort: true,
               
            },
            {
                dataField: "WhatsApp",
                text: "WhatsApp",
                sort: true,
            },
            {
                dataField: "Web",
                text: "Web",
                sort: true,
            },
            
            {
                dataField: "Total",
                text: "Total",
                sort: true,
                
            }
            
        
        ];
        return columns;
    }

    
    
    userWiseRegDataArr = () =>{
    let dataArr2 = [];
        if(this.state.userWiseDataArr.length>0){
            
            for (let i = 0; i < this.state.userWiseDataArr.length; i++) {

                const date = (typeof(this.state.userWiseDataArr[i].date) == "undefined" ? "-" : this.state.userWiseDataArr[i].date);
                const tbotCnt = (this.state.userWiseDataArr[i].tbotCnt);
                const wbotCnt = (this.state.userWiseDataArr[i].wbotCnt);
                const webCnt = (this.state.userWiseDataArr[i].webCnt);
                const total = (this.state.userWiseDataArr[i].total);
                
               
                
                const obj = {
                    Date: date ,
                    Telegarm: tbotCnt,
                    WhatsApp: wbotCnt,
                    Web: webCnt,
                    Total: total,
                    
                
                    
                }
                dataArr2.push(obj);
            } 

            
            
            return dataArr2;
        }
    }

    
    range = {
        "Today": [moment(), moment()],
        "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "OverAll": [moment("20240901"), moment()],
      };

      handleEventForCubs = (event, picker) => {
        console.log("start: ",  moment(picker.startDate._d).format('YYYY-MM-DD'));
        console.log("end: ",  moment(picker.endDate._d).format('YYYY-MM-DD'));
        
        this.setState({ cadreFromDate: moment(picker.startDate._d).format('YYYY-MM-DD'),cadreToDate:moment(picker.endDate._d).format('YYYY-MM-DD')});
        this.getDayWiseVolunteerRegistrationsCount();
      };
  

      
    render() {
        console.log("roleName",this.state.roleName)
        return (
            <section className="mb-4">
            <div className="header_cadre_img"></div>

            <Container className="mt-2">
              <Row>
                  <Col>
                      <Link to={{pathname: "/dashboard"}}> 
                          <Button variant="outline-dark pad5_14" className='f-12 home_btn fw-bold'> <i class="fa-solid fa-house"></i> Home</Button> 
                      </Link>
                  </Col>
              </Row>

              <Row>
                  <Col>
                      <h6 className='text-center f-16 fw-bold mt-2 mb-3'>Date Wise Cadre Registration</h6>
                  </Col>
              </Row>

              <Row>
                <Col>
                <h6 className="form-label f-12 fw-bold mt-3">Date Range :</h6>
                <DateRangePicker
                        alwaysShowCalendars={true}
                        ranges = {this.range}
                        onEvent={this.handleEventForCubs}
                        className="w-100"
                    >   
                    <div class="input-group">
                        <div class="input-group-prepend bgColorYash">
                            <i class="fa fa-calendar pad_5_15"></i>
                        </div>
                        <input type="text" className="form-control" value={moment(this.state.cadreFromDate).format("YYYY-MM-DD") +" to "+ moment(this.state.cadreToDate).format("YYYY-MM-DD")} />
                    </div>
                    </DateRangePicker>
                </Col>
            </Row>


              {
                this.state.userWiseDataArr.length == 0 ?
                (
                    this.state.userWiseDataCheck == 1 ?
                    (<Col className='f-14 text-center'>No Data Available</Col>)
                    :
                    (<Col sm={12}>
                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                    </Col>)
                )
                :
                (
                    this.state.userWiseDataArr.length>0 ?
                    (
                        <>
                        <Row>
                        
                            <Col sm={12}>
                            <div className='mt-2 p-0 bg_light_blue'>
                                
                                <CardLessDataTableWithSearch 
                                    parentData={this.userWiseRegDataArr()}
                                    particlecolumns={this.userWiseRegColumns()}
                                    defaultSorted={[{
                                        dataField: "Date",
                                        order: "desc"
                                    }]}
                                    tableHeading=""
                                    />
                                    
                                    
                            </div>
                            
                            </Col>
                        </Row>
                        </>
                    )
                    :
                    ("")
                )
              }

              </Container>
            </section>
        )
    }
  }
  
  export default withRouter(CadreRegistrationUserWiseDashboard);
  