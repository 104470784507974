import React, { Component } from 'react'
import { Container, Button,Row,Col,Card,Form,InputGroup,Image } from "react-bootstrap";
import "./cadreRegistration.css";
import loader from '../../images/loader.gif';
import { withRouter,Link } from "react-router-dom";
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import { getAPICallDataForCadre } from '../../services/MemberService';
import MobileNoOTPValidation from './MobileNoOTPValidation';

class LifeTimeEnrollReg extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            englishSelected:props?.location?.params?.englishSelected == undefined ? true : this.props?.location?.params?.englishSelected,
            teleguSelected:props?.location?.params?.teleguSelected == undefined ? false : this.props?.location?.params?.teleguSelected,

            captureMobileNo:"",
            validateCaptureMobileNo:"",
            captureOTPNO:"",
            validateCaptureOTP:"",

            mobileNoDisabled:false,

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",
            
            minutes:1,
            seconds:0,
            OTPVerifiedDisabledButton:true,

            simpleEnrollmentBlock:true,
            detailedEnrollmentBlock:false,

            voterIdSearchVoterCardNo:"",
            voterIdSearchLoadingSymbol:"hide",
            voterIdSearchDetailsObj:"",
            voterIdSearchDataCheck:0,
            validateVoterIdSearch:"",

            firstPageDetailedEnrollment:false,
            secondPageDetailedEnrollment:false

        }
      
    }

    componentDidMount() {
       
    }

    typeOfLanguageSelectionNew = (e,languageType) => {
   
        if(languageType == "english"){
            this.setState({englishSelected:true,teleguSelected:false})
        }else{
            this.setState({englishSelected:false,teleguSelected:true})
        }
    }

    backToNewCadreEnrollPage = () => {
        this.props.history.push({
            pathname: "/cadre_registration",
            params: {
                navbarDisplay:"hide",
                //userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList
              },
          });
    }

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;

        if(name == "captureOTPNO"){
            this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
            if(value.length >= 6){
                this.validateMobileOtp(value);
               
            }
            
        }

        this.setState({ [name]: value });
        
      }
    
getMobileNoOTP = () => {
    this.setState({validateCaptureMobileNo:"" });
    var expr = /^(0|91)?[6-9][0-9]{9}$/;

    if(this.state.captureMobileNo == ""){
        this.setState({validateCaptureMobileNo:"Please Enter Mobile No" });
        return;
    }

    if(!expr.test(this.state.captureMobileNo)){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    if(this.state.captureMobileNo.length < 10){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }


    this.setState({smsSentMessage:"",getOTPLoadingSymbol:"show",OTPSentStatus:"",otpVerifiedValue:0});
    
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "mobileNo" : this.state.captureMobileNo,
        "volunteerMobileNo" : this.state.volunteerMobileNoInput,
        
        }); 

        getAPICallDataForOnlyBSA(obj,"sendOTPForCitVlorRegistration")
        .then((res) => res.json())
        .then((data) => {
        this.setState({getOTPLoadingSymbol:"hide"});
        if(data !=null && data.status == "Success"){
           // console.log(jwt_decode(data.reference).sub)
            this.setState({smsSentMessage:"OTP Sent Your Mobile No",OTPSentStatus:data.status,otpVerifiedValue:1});
            this.setState({mobileNoDisabled:true,OTPVerifiedDisabledButton:false})
            this.OTPTimerFunction();
        }else if(data !=null && data.status == "Fail"){
            this.setState({smsSentMessage:data.message,OTPSentStatus:"Fail",otpVerifiedValue:0});
            this.setState({mobileNoDisabled:false,OTPVerifiedDisabledButton:true})
        }else{
            this.setState({smsSentMessage:"Some Thing Went Wrong,Please Try Again...",OTPSentStatus:"Fail",otpVerifiedValue:0});
            this.setState({mobileNoDisabled:false,OTPVerifiedDisabledButton:true})
        }
        });
}

resendOTPTimer = () => {
    this.setState({minutes:1});
    this.setState({seconds:0});

    this.OTPTimerFunction();
};

OTPTimerFunction = () => {
    const interval = setInterval(() => {
        if (this.state.seconds > 0) {
        this.setState({seconds:this.state.seconds - 1});
        }

        if (this.state.seconds === 0) {
        if (this.state.minutes === 0) {
            clearInterval(interval);
        } else {
            this.setState({seconds:120});
            this.setState({minutes:this.state.minutes - 1});

        }
        }
    }, 1000);

    return () => {
        clearInterval(interval);
    };
}

resendOTPNew = () => {
    this.setState({validateCaptureMobileNo:"",OTPVerifiedDisabledButton:false });
    if(this.state.captureMobileNo == ""){
        this.setState({validateCaptureMobileNo:"Please Enter Mobile No" });
        return;
    }
    var expr = /^(0|91)?[6-9][0-9]{9}$/;
    if(!expr.test(this.state.captureMobileNo)){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    

    if(this.state.captureMobileNo.length < 10){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    this.setState({resendOTPLoadingSymbol:"show",otpVerifiedValue:0});

    const obj =  
        JSON.stringify({ 
           "chatId" :this.state.chatID,
            "mobileNo" : this.state.captureMobileNo,
            "volunteerMobileNo" : this.state.volunteerMobileNoInput,
        
        }); 

        getAPICallDataForOnlyBSA(obj,"sendOTPForCitVlorRegistration")
        .then((res) => res.json())
        .then((data) => {
        this.setState({resendOTPLoadingSymbol:"hide"});
        if(data !=null && data.status == "Success"){
            //console.log(jwt_decode(data.reference).sub)
            this.setState({otpVerifiedValue:1});
            this.resendOTPTimer();
        }else{
            this.setState({otpVerifiedValue:0});
        }
        });
    
}

validateMobileOtp = (otp) => {
this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
const obj =  
    JSON.stringify({ 
    "chatId" :this.state.chatID,
    "mobileNo" : this.state.captureMobileNo,
    "otp" : otp,
    "volunteerMobileNo" : this.state.volunteerMobileNoInput,
    

    }); 

    getAPICallDataForOnlyBSA(obj,"verifyCitVlorRegistrationOTP")
    .then((res) => res.json())
    .then((data) => {
    this.setState({otpLoadingSymbol:"hide"});
    if(data !=null && data.status == "Success"){
        this.setState({otpVerifiedMessageStatus:"Verified",OTPVerifiedDisabledButton:true});
    }else{
        this.setState({otpVerifiedMessageStatus:"NotVerified",OTPVerifiedDisabledButton:false});
    }
    });
}


lifeTimeEnrollTypeRegister = (e,type) => {
    this.setState({
        captureMobileNo:"",
            validateCaptureMobileNo:"",
            captureOTPNO:"",
            validateCaptureOTP:"",

            mobileNoDisabled:false,

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",
            
            minutes:1,
            seconds:0,
            OTPVerifiedDisabledButton:true,

           
            voterIdSearchVoterCardNo:"",
            voterIdSearchLoadingSymbol:"hide",
            voterIdSearchDetailsObj:"",
            voterIdSearchDataCheck:0,
            validateVoterIdSearch:"",

    });

    if(type == "simple"){
        this.setState({simpleEnrollmentBlock:true,detailedEnrollmentBlock:false,firstPageDetailedEnrollment:false,secondPageDetailedEnrollment:false})
    }else{
        this.setState({simpleEnrollmentBlock:false,detailedEnrollmentBlock:true,firstPageDetailedEnrollment:true,secondPageDetailedEnrollment:false})
    }
}


secondPageDetailedEnroll = () => {
    this.setState({firstPageDetailedEnrollment:false,secondPageDetailedEnrollment:true})
}

previousFirstPageLifeTimeEnroll = () => {
    this.setState({firstPageDetailedEnrollment:true,secondPageDetailedEnrollment:false})
}

getVoterDetailsByVoterCardNo = () => {
    this.setState({validateVoterIdSearch:""})
    if(!this.state.voterIdSearchVoterCardNo){
        this.setState({validateVoterIdSearch:"Please Enter Voter Card No..." });
        return;
    }

    this.setState({voterIdSearchLoadingSymbol:"show",voterIdSearchDetailsObj:"",voterIdSearchDataCheck:0});

    const obj =  
        JSON.stringify({ 
            "vno" : this.state.voterIdSearchVoterCardNo,
            "username" : "app_api_cadre",
            "password" : "6Da3!p#w$5xqinRk"
        });

        getAPICallDataForCadre(obj,"getPersonDetailsByVno")
        .then((res) => res.json())
        .then((content) => {
            this.setState({voterIdSearchLoadingSymbol:"hide"})
            if(content !=null){
                this.setState({voterIdSearchDetailsObj:content,voterIdSearchDataCheck:0});
            }else{
                this.setState({voterIdSearchDetailsObj:"",voterIdSearchDataCheck:1});
            }
        });
    
    
}



  
    render() {
        console.log("xcdd",this.props.location?.params?.imageBase64Str)
        return (
            <section className="mb-4">
              <div className="header_cadre_img"></div>
              <Container>
            <Row>
                <Col>
                    <Button variant="outline-dark pad5_14" className='f-12 fw-bold mt-2' onClick={() => this.backToNewCadreEnrollPage()}> <i class="fa-solid fa-arrow-left"></i> Home</Button> 
                </Col>
            </Row>


            <Row>
                <Col>
                    <h6 className='text-center color_green fw-bold  mt-3'>{this.state.englishSelected== true ? <span className='border-bottom-double'>Patrons of TDP (Life Time Membership)</span> : <span className='border-bottom-double'>టీడీపీ పోషకులు (లైఫ్ టైమ్ సభ్యత్వం)</span>}</h6>
                </Col>
            </Row>

            <Row>
                    <Col sm={12}>
                        <Form>
                            <div className='mt-2 text-center'>
                            <Form.Check
                                inline
                                label={this.state.englishSelected== true ? `SIMPLE` : `సింపుల్ సభ్యత్వం`}
                                name="lifeTimeEnrollDetailsNameCls"
                                type="radio"
                                id={`simpleEnrollId`}
                                onChange={(e)=>this.lifeTimeEnrollTypeRegister(e,"simple")}
                                className="f-14 fw-bold color_black cursor_pointer"
                                defaultChecked={true}
                                value="simple"
                            />
                            <Form.Check
                                inline
                                label={this.state.englishSelected== true ? `DETAILED` : `వివరంగా సభ్యత్వం`}
                                name="lifeTimeEnrollDetailsNameCls"
                                type="radio"
                                id={`detailedEnrollId`}
                                onChange={(e)=>this.lifeTimeEnrollTypeRegister(e,"detailed")}
                                className="f-14 fw-bold color_black cursor_pointer"
                                value="detailed"
                            />
                           
                            </div>
                        </Form>
                            
                    </Col>
                </Row>

                {
                    this.state.simpleEnrollmentBlock == true ?
                    (
                        <>
                        <MobileNoOTPValidation 
                            stateData={this.state}
                            handleOnchangeInputBox={this.handleOnchangeInputBox}
                            getMobileNoOTP={this.getMobileNoOTP}
                            resendOTPNew={this.resendOTPNew}
                        />
                        
                        <Row>
                            <Col>
                                <div className='bg_white_shadow_border mt-3'>
                                    Rs.100000

                                    <Button variant="danger" className="red_button_event center-auto mt-2"  >PAY NOW </Button>
                                </div>
                            </Col>
                        </Row>
                        </>
                    )
                    :
                    (
                        this.state.detailedEnrollmentBlock == true ?   
                        (
                            <>
                            {
                                this.state.firstPageDetailedEnrollment == true ?
                                (
                                    <>
                                    <fieldset class=""><legend>SEARCH BY OWN VOTER ID</legend> 
                                        <Row>
                                            <Col>
                                                <Form.Group  controlId="formVoterSerachCardNoId">
                                                    <Form.Label className='f-12 fw-bold mb-0'>{this.state.englishSelected== true ? "Voter Card No" : "ఓటరు కార్డు నం"} </Form.Label>
                                                    <Form.Control type="text" name="voterIdSearchVoterCardNo" value={this.state.voterIdSearchVoterCardNo} className='form-control-sm'  placeholder="Enter Voter Card No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                </Form.Group>    
                                            </Col>
                                        </Row>
                                    </fieldset>

                                    {
                                        this.state.validateVoterIdSearch != "" ?
                                        (
                                            <Row>
                                                <Col>
                                                    <h6 className='error_cls_new mt-2'>{this.state.validateVoterIdSearch}</h6>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                    }

                                    <Row>
                                        <Col>
                                            <Button variant="dark" className='f-12 center-auto mt-2' onClick={() => this.getVoterDetailsByVoterCardNo()}>{this.state.englishSelected== true ? "GET VOTER" : "ఓటరు పొందండి"}</Button> 
                                        </Col>
                                    </Row>

                                    {
                                        this.state.voterIdSearchLoadingSymbol == "show" ?
                                        (
                                            <Row>
                                                <Col sm={12}>
                                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                    }
                                    {
                                        this.state.voterIdSearchDetailsObj != "" ?
                                        (
                                            
                                            <>
                                            <Row>
                                                <Col>
                                                    <div className="border_yash_pad mt-3">
                                                        {
                                                            this.state.voterIdSearchDetailsObj.personTeluguName != "" ?
                                                            (<h6 className='f-14'>{this.state.englishSelected== true ? "Name" : "పేరు"} : {this.state.voterIdSearchDetailsObj.personTeluguName}</h6>)
                                                            :
                                                            (<h6 className='f-14'>{this.state.englishSelected== true ? "Name" : "పేరు"} : {this.state.voterIdSearchDetailsObj.personName}</h6>)
                                                        }
                                                        <h6 className='f-14 mt-2'>{this.state.englishSelected== true ? "Voter No" : "ఓటరు నెంబర్"}:{this.state.voterIdSearchDetailsObj.vno}</h6>
                                                        <h6 className='f-14 mt-2'>{this.state.englishSelected== true ? "Relative Name" : "బంధువు పేరు"}:{this.state.voterIdSearchDetailsObj.relativeName}</h6>
                                                        <h6 className='f-14 mt-2'>{this.state.englishSelected== true ? "H.NO" : "ఇంటి నెంబర్"}:{this.state.voterIdSearchDetailsObj.houseNo	}  |  {this.state.englishSelected== true ? "Gender" : "లింగం"} : {this.state.voterIdSearchDetailsObj.gender}  |  {this.state.englishSelected== true ? "Age" : "వయస్సు"} :{this.state.voterIdSearchDetailsObj.age}</h6>

                                                        <h6 className='f-14 mt-2'>{this.state.englishSelected== true ? "Enroll Status" : "నమోదు స్థితి"} :
                                                            {
                                                                this.state.voterIdSearchDetailsObj.enrollStatus == "Enrolled" ?
                                                                (<span className='color_green fw-bold f-16'>{this.state.voterIdSearchDetailsObj.enrollStatus}</span>)
                                                                :
                                                                (
                                                                    this.state.voterIdSearchDetailsObj.enrollStatus == "Payment Pending" ?
                                                                    (<span className='color_red fw-bold f-16'>{this.state.voterIdSearchDetailsObj.enrollStatus}</span>)
                                                                    :
                                                                    (<span className='color_red fw-bold f-16'>{this.state.voterIdSearchDetailsObj.enrollStatus}</span>)
                                                                )
                                                            }
                                                            
                                                        </h6>

                                                        {
                                                            this.state.voterIdSearchDetailsObj.cid != "" && this.state.voterIdSearchDetailsObj.mid != "" && this.state.voterIdSearchDetailsObj.enrollStatus	 == "Not Enrolled" ?
                                                            (
                                                                <h6 className='f-14 mt-3 fw-bold'>{this.state.englishSelected== true ? "This Person Previously Enrolled" : "ఈ వ్యక్తి గతంలో నమోదు చేసుకున్నాడు"} <span className='float-end text-decoration-underline color_anchor cursor_pointer' onClick={() => this.secondPageRenewalMemberShip(this.state.voterIdSearchDetailsObj)}>{this.state.englishSelected== true ? "Renewal Now" : "పునరుద్ధరణ కోసం"} </span></h6>
                                                            )
                                                            :
                                                            (
                                                                this.state.voterIdSearchDetailsObj.cid != "" && this.state.voterIdSearchDetailsObj.mid != "" && this.state.voterIdSearchDetailsObj.enrollStatus	 == "Enrolled" ?
                                                                (
                                                                    <h6 className='f-14 mt-3 fw-bold color_green text-center'>{this.state.englishSelected== true ? "This Person Already Enrolled" : "ఈ వ్యక్తి ఇప్పటికే నమోదు చేసుకున్నారు"} </h6>
                                                                )
                                                                :
                                                                (
                                                                    this.state.voterIdSearchDetailsObj.cid == "" && this.state.voterIdSearchDetailsObj.mid == "" && this.state.voterIdSearchDetailsObj.enrollStatus	 == "Not Enrolled" ?
                                                                    (
                                                                        <Row>
                                                                            <Col>
                                                                                <h6 className='f-14 fw-bold float-end text-decoration-underline color_red cursor_pointer' onClick={() => this.secondPageDetailedEnroll()}>{this.state.englishSelected== true ? "Pay Now" : "Pay Now"} </h6>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                    :
                                                                    (
                                                                        this.state.voterIdSearchDetailsObj.enrollStatus	 == "Payment Pending" ?
                                                                        (
                                                                            <Row>
                                                                                <Col>
                                                                                    <h6 className='f-14 fw-bold float-end text-decoration-underline color_red cursor_pointer' onClick={() => this.secondPageDetailedEnroll()}>Pay Now</h6>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                        :
                                                                        ("")
                                                                    )
                                                                    
                                                                    
                                                                )
                                                            )
                                                        }

                                                    </div>
                                                </Col>
                                            </Row>
                                            </>
                                        )
                                        :
                                        (<>
                                            {
                                            this.state.voterIdSearchDataCheck == 1 ?
                                            (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                            :
                                            ("")
                                        }
                                        </>)
                                    }

                                    </>

                                )
                                :
                                (
                                    this.state.secondPageDetailedEnrollment == true ? 
                                    (
                                        <>
                                             <Row>
                                                <Col>
                                                    <Button variant="outline-dark pad5_14" className='f-12 fw-bold float-end mt-2' onClick={() => this.previousFirstPageLifeTimeEnroll()}> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                                                </Col>
                                            </Row>
                                            <MobileNoOTPValidation 
                                                stateData={this.state}
                                                handleOnchangeInputBox={this.handleOnchangeInputBox}
                                                getMobileNoOTP={this.getMobileNoOTP}
                                                resendOTPNew={this.resendOTPNew}
                                            />
                                            
                                            <Row>
                                                <Col>
                                                    <div className='bg_white_shadow_border mt-3'>
                                                        Rs.100000

                                                        <Button variant="danger" className="red_button_event center-auto mt-2"  >PAY NOW </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </>
                                    )
                                    :
                                    ("")
                                )
                            }
                            </>
                        )
                        :
                        ("")
                    )
                }

                
                </Container>
                </section>
                
            
        )
    }
}
export default withRouter(LifeTimeEnrollReg);
  

