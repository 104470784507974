
import React ,{ Component } from 'react';
import { Row, Col,Modal} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import QrReader from "react-qr-reader";

class QrReaderModalCode extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

   
    render() {
        return (
          <Modal {...this.props} 
          size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
              <Modal.Header closeButton className='p-3'>
                  <Modal.Title className='f-14 fw-bold'>QR Scan</Modal.Title>
              </Modal.Header>
              <Modal.Body className="">
                  <Row>
                        <Col className='mt-3 center-auto'>
                          {this.props.stateData.loadingScan && (
                          <>
                            
                            <div className='ocrloader'>
                                <QrReader
                                  constraints={ {facingMode: 'environment'} }
                                // facingMode={this.state.scannerConstraints}
                                  delay={1000}
                                  onError={this.props.PQRhandleError}
                                  onScan={this.props.PQRhandleScan}
                                  // chooseDeviceId={()=>selected}
                                  style={{ width: "100%" }}
                                  className="qr-image-wrapper"
                                />
                                  <em></em>
                                  <span></span>
                            </div>
                            
                          </>
                        )}
                        
                         
                        </Col>
                    </Row>  
              </Modal.Body>
          </Modal>
        )
    }
  }
  
  export default withRouter(QrReaderModalCode);
  