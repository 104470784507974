import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';
import MaxLimitEnrollChecking from './MaxLimitEnrollChecking';

class HouseWiseVoterList extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
      
    }

    
    hoseWiseVoterListDisplayColumnsForFV() {
    let columns = [
        
        {
            dataField: "serial_no",
            text: "Serial No",
            sort: true,
            
            
            
        },
        {
            dataField: "voter_name",
            text: "Voter Name",
           // sort: true,
            formatter: this.actionsFormatterForECVoterNameForFV.bind(this),
            headerStyle: {
                width: "35%"
            }
            
            
        },
        {
            dataField: "Age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECAgeNameModifiedForFV.bind(this),
            
        },
       
        {
            dataField: "Action",
            text: "A/c",
            formatter: this.actionsFormatterForECUpdatedVoterForFV.bind(this),
            headerStyle: {
                width: "40%"
            }
        }
     
    ];
    return columns;
}

actionsFormatterForECVoterNameForFV = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.voterName)} 
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.age)}</span></>
            {/*<><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Rel_Name - {(row.relativeName)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Relation - {(row.relation)}</span></>*/}
        </span>
     );
    
}
actionsFormatterForECAgeNameModifiedForFV = (cell, row, rowIndex, formatExtraData) =>{
    
    return (
        (row.voterCardNo)
        
      );
}

actionsFormatterForECUpdatedVoterForFV = (cell, row, rowIndex, formatExtraData) => {
    const selectedVoterData = this.props.stateData.houseWiseVoterListForFVArr.filter((item,index) => item.voterId == row.electionCampaignVoterId);
   
    return (
        <>
            {/*
                row.isEnrolled == 'Y' ?
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='fw-bold color_green' >Enrolled</span>)
                :
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold view_enroll_css text-decoration-underline' onClick={() => this.props.getEnrollmentsCountByUserByDate(selectedVoterData)}>Enroll</span>)
          */ }
             {
                <span style={{textAlign:"left",marginLeft:"0px"}}>Thank You
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>House Visited Updated... <span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold view_enroll_css text-decoration-underline' onClick={() => this.props.houseVisitCaptureClick(selectedVoterData)}>Take A Photo</span></span></>
                </span>
             }
            
        </>
          
     );
}

hoseWiseVoterListDisplayDataForFV = () =>{
    let houseWiseVoterData = [];
        if(this.props.stateData.houseWiseVoterListForFVArr.length>0){
            
            for (let i = 0; i < this.props.stateData.houseWiseVoterListForFVArr.length; i++) {

                const serial_no = (this.props.stateData.houseWiseVoterListForFVArr[i].serialNo);
                const voterName = (this.props.stateData.houseWiseVoterListForFVArr[i].voterName);
                const gender = (this.props.stateData.houseWiseVoterListForFVArr[i].gender);
                const age = (this.props.stateData.houseWiseVoterListForFVArr[i].age);
                const relativeName = (this.props.stateData.houseWiseVoterListForFVArr[i].relativeName);
                const relation = (this.props.stateData.houseWiseVoterListForFVArr[i].relation);
                const houseNo = "";
                const electionCampaignVoterId = (this.props.stateData.houseWiseVoterListForFVArr[i].voterId);
                const voterCardNo = (this.props.stateData.houseWiseVoterListForFVArr[i].voterCardNo);
                const checkedType = "";
                const isVisited = "";
                const isEnrolled = (this.props.stateData.houseWiseVoterListForFVArr[i].isEnrolled);
               
                
                const obj = {
                    serial_no:(serial_no),
                    voterName:voterName,
                    gender: gender,
                    age: voterCardNo,
                    relativeName: relativeName,
                    relation:relation,
                    houseNo:houseNo,
                    electionCampaignVoterId:electionCampaignVoterId,
                    voterCardNo:age,
                    checkedType:checkedType,
                    isVisited:isVisited,
                    isEnrolled:isEnrolled,
                   
                    
                }
                houseWiseVoterData.push(obj);
            } 

            
            
            return houseWiseVoterData;
        }
}




    
    render() {
        console.log("ddd",this.props.stateData.houseWiseVoterListForFVArr)
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play scroll_top_limit">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'> Please select the voter name which need to be in "Bhavshyatuku Guarantee" Card. 
                     <br/><b>House No - {this.props.stateData.houseNoValue}</b> 
                     </Modal.Title>
                     
                </Modal.Header>
                <Modal.Body className="">
                    <div className=''>
                    {
                            this.props.stateData.houseWiseVoterListForFVArr.length == 0 ?
                            (
                                this.props.stateData.houseWiseVoterListArrForFVCheck == 1 ?
                                (<Col className='f-14 text-center'> { this.props.stateData.enrollCheckVal == 'Y' ? "In This House No One Can Registered..." : ""}</Col>)
                                :
                                (<Col sm={12}>
                                  <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>)
                            )
                            :
                            (
                              this.props.stateData.houseWiseVoterListForFVArr.length>0 ?
                                (
                                    <>
                                    <Row>
                                        <Col sm={12}>
                                        
                                        <div className='bg_light_blue p-0 pagination_none'>
                                           
                                            <CardLessDataTableWithOutSearch 
                                                parentData={this.hoseWiseVoterListDisplayDataForFV()}
                                                particlecolumns={this.hoseWiseVoterListDisplayColumnsForFV()}
                                                defaultSorted={[{
                                                    dataField: "serial_no",
                                                    order: "asc"
                                                }]}
                                                tableHeading=""
                                                />
                                                
                                                
                                        </div>
                                        
                                        </Col>
                                    </Row>
                                    </>
                                )
                                :
                                ("")
                            )
                          }
                          </div>

                </Modal.Body>
            </Modal>
            
        )
    }
}
export default HouseWiseVoterList;
  

