import React, { useState } from "react";
import {Image } from 'react-bootstrap';
import { useHistory   } from "react-router-dom";
import Webcam from "react-webcam";
import { withRouter,Link } from "react-router-dom";
import events_logo from '../../images/events_logo.png';
import events_name from '../../images/events_name.png';
import { getCampaignAPICallData } from '../../services/MemberService';
import loader from '../../images/loader.gif';


const ImageCaptureBlock = (props) => {


  const [showImgCapture, setShowImgCapture] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);
  const webcamRef = React.useRef(null);
  const history = useHistory();
  const [videoConstraints, setVideoConstraints] = useState({facingMode: "user"});
  const [eventStatusDisplayMsg,setEventStatusDisplayMsg] = useState(null)
  const [loaderSymbol, setLoaderSymbol] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  
  const switchCamara = () => {
    setShowImgCapture(false);
    if ( videoConstraints.facingMode == 'user' ) {
      setVideoConstraints({facingMode: "environment"})
    } else {
      setVideoConstraints({facingMode: "user"})
    }

    setTimeout(() =>{  
      setShowImgCapture(true);
    }, 500);

  }

  const frontCamara = () => {
    setVideoConstraints({facingMode: "user"})
  }
  const captureForAttendence = () => {
      
      setImgSrc(webcamRef.current.getScreenshot());
      setDisabledButton(true)
      if(props?.location?.params?.pollManagementPage == 'Yes'){
        const captureImageBase64 = webcamRef.current.getScreenshot().replace("data:image/jpeg;base64,","");
        giveOtherProgramsCampaignAttendance(captureImageBase64,"Poll");
       
      }else if(props?.location?.params?.MLCPage == 'Yes'){
        const captureImageBase64 = webcamRef.current.getScreenshot().replace("data:image/jpeg;base64,","");
        giveOtherProgramsCampaignAttendance(captureImageBase64,"MLC");
      }else{ 
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(Showposition,showError)
          setLoaderSymbol("show");
        }else{
          alert("An unknown error occurred")
        }

     }
      
    }


    const Showposition = (position) => {
     // setTimeout(() => {
      const getCoordinates =  
            { 
              "latitude" : position.coords.latitude,
              "longitude" : position.coords.longitude,
              "accuracy":position.coords.accuracy
            }
            
      if(getCoordinates !="" ){
        console.log(webcamRef.current.getScreenshot())
        setLoaderSymbol("");
        history.push({
          pathname: "/ongoing_ready_event",
          params: {
              captureImageBase64:  webcamRef.current.getScreenshot().replace("data:image/jpeg;base64,",""),
              captureFileName: "AttenedImage",
              captureImageType:"jpeg",
              navbarDisplay:"hide",
              event:props?.location?.params?.event == undefined ? "" : props?.location?.params?.event,
              eventStatus:props?.location?.params?.eventStatus == undefined ? "" : props?.location?.params?.eventStatus,
              campaignDetailsId:props?.location?.params?.campaignDetailsId == undefined ? "" : props?.location?.params?.campaignDetailsId,
              eventType:props?.location?.params?.eventType == undefined ? "" : props?.location?.params?.eventType,
              isAttended:props?.location?.params?.eventType == "other_programs" ? "Yes" : props?.location?.params?.isAttended == undefined ? "" : props?.location?.params?.isAttended,
              startEventLatCoordinates:getCoordinates.latitude,
              startEventLongCoordinates:getCoordinates.longitude,
              surveyType:props?.location?.params?.surveyType == undefined ? "" : props?.location?.params?.surveyType,
              pollManagementPage:props?.location?.params?.pollManagementPage == undefined ? "" : props?.location?.params?.pollManagementPage,
              MLCPage:props?.location?.params?.MLCPage == undefined ? "" : props?.location?.params?.MLCPage,
            
    
            },
        });
      }
   // }, 1500);

    }

    const showError = (error) => {
        switch (error.code){
            case error.PERMISSION_DENIED:
              alert("User Location Permission is Denied,Please Enable Location Services...(Path:Settings > Privacy > Location Services)");
              break;

              case error.POSITION_UNAVAILABLE:
              alert("User Postion Unavailable Denied");
              break;

              default:
                alert("An unknown error occurred")
        }
    }

   

    const giveOtherProgramsCampaignAttendance = (captureImageBase64,redirectType) => {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition( position => {
              const getCoordinates =  
                { 
                  "latitude" : position.coords.latitude,
                  "longitude" : position.coords.longitude,
                }

                const userAccessObj =  
                  JSON.stringify({ 
                      "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                      "campaignDetailsId":props?.location?.params?.campaignDetailsId,
                      "latitude":getCoordinates.latitude,
                      "longitude":getCoordinates.longitude,
                      "files":[{
                              "fileName" : "AttenedImage",
                              "fileType" : "jpeg",
                              "eventType" : "Attendance",
                              "base64Str" : captureImageBase64
                          }]
                  });
                  
      
              getCampaignAPICallData(userAccessObj,"giveCampaignAttendance")
                  .then((res) => res.json())
                  .then((data) => {
                      if(data !=null && data.status == "Success"){
                        setEventStatusDisplayMsg("Attend SuccessFully...");
                        
                          setTimeout(() => {
                            setShowImgCapture(false);
                            if(redirectType == "Poll"){
                              history.push({
                                pathname: "/poll_management",
                                params: {
                                    navbarDisplay:"hide",
                                  
                                  },
                              });
                            }else{
                              history.push({
                                pathname: "/mlc_voters",
                                params: {
                                  navbarDisplay:"hide",
                                  event:props?.location?.params?.event == undefined ? "" : props?.location?.params?.event,
                                  eventStatus:props?.location?.params?.eventStatus == undefined ? "" : props?.location?.params?.eventStatus,
                                  campaignDetailsId:props?.location?.params?.campaignDetailsId == undefined ? "" : props?.location?.params?.campaignDetailsId,
                                  eventType:props?.location?.params?.eventType == undefined ? "" : props?.location?.params?.eventType,
                                  isAttended:props?.location?.params?.eventType == "other_programs" ? "Yes" : props?.location?.params?.isAttended == undefined ? "" : props?.location?.params?.isAttended,
                                  surveyType:props?.location?.params?.surveyType == undefined ? "" : props?.location?.params?.surveyType,
                                  
                                  },
                              });
                            }
                            
                        }, 2000);
                         
                          
                      }else{
                        setEventStatusDisplayMsg("Something Wrong,Please Try Again...")
                      }
                  });

              });
          }

    
  }
  return (
   
    <>
      <div className=''>
          <div className='flex_event_name_top'>
            {
              props?.location?.params?.pollManagementPage == 'Yes' || props?.location?.params?.MLCPage == 'Yes' ?
              (
                <Link to={{pathname: "/dashboard"}}> <i class="fa-solid fa-arrow-left color_black"></i></Link>
                  
              )
              :
              (
                <Link to={{pathname: "/ongoing_ready_event",
                  params: { 
                    navbarDisplay:"hide",
                    event:props?.location?.params?.event == undefined ? "" : props?.location?.params?.event,
                    eventStatus:props?.location?.params?.eventStatus == undefined ? "" : props?.location?.params?.eventStatus,
                    campaignDetailsId:props?.location?.params?.campaignDetailsId == undefined ? "" : props?.location?.params?.campaignDetailsId,
                    eventType:props?.location?.params?.eventType == undefined ? "" : props?.location?.params?.eventType,
                    isAttended:props?.location?.params?.eventType == "other_programs" ? "Yes" : props?.location?.params?.isAttended == undefined ? "" : props?.location?.params?.isAttended,
                    surveyType:props?.location?.params?.surveyType == undefined ? "" : props?.location?.params?.surveyType,
                    pollManagementPage:props?.location?.params?.pollManagementPage == undefined ? "" : props?.location?.params?.pollManagementPage,
                    MLCPage:props?.location?.params?.MLCPage == undefined ? "" : props?.location?.params?.MLCPage
                   
                  }}}> <i class="fa-solid fa-arrow-left color_black"></i></Link>
              )
            }
            
            <img src={events_logo} alt="event logo" />
            <img src={events_name} alt="event Name" />
              
        </div>
      </div>
      <div className='bg_red_events color_white fw-bold f-12'>
        <span>ATTENDEES PHOTOS {loaderSymbol}</span>
        </div>
      <div className="showImgCaptureDiv mt-1">
       
       {
          showImgCapture == true ?
          (<Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          /> )
          :
          ("")
       }

      <div className="row pos_top mt-2">
        {
           showImgCapture == true ? 
           (
            <div className="col">
                  {
                    disabledButton == false ?
                    (
                      <h6 onClick={captureForAttendence}  className="camara_icon"><i class="fa-solid fa-camera" ></i>
                        {
                        loaderSymbol == "show" ? 
                        (
                          <Image src={loader} alt="loader" className='loader_cls1 ms-2'></Image>
                        )
                        :
                        ("")
                      }
                    </h6>
                    )
                    :
                    (
                      <h6   className="camara_icon"><i class="fa-solid fa-camera" ></i>
                        {
                        loaderSymbol == "show" ? 
                        (
                          <Image src={loader} alt="loader" className='loader_cls1 ms-2'></Image>
                        )
                        :
                        ("")
                      }
                    </h6>
                    )
                  }
                  
            </div>
           )
           :
           ("")
        }
          <div className="col">
            {
                videoConstraints.facingMode == "user" ?
                (<h6 className="switch_camara text-center" onClick={switchCamara} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                :
                ( <h6 className="switch_camara text-center" onClick={switchCamara} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
              }
          </div>
      </div>

      {
        eventStatusDisplayMsg != "" ?
        (<h6 className="text-center f-14 mt-2 color_green">{eventStatusDisplayMsg}</h6>)
        :
        ("")
      }     
    </div>
    </>
  );
};

export default withRouter(ImageCaptureBlock);