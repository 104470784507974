
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Accordion,Button,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "./EventsAndActivities.css";
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import my_programsImg from '../../images/my_programs.png';
import other_programsImg from '../../images/other_programs.png';
import events_logo from '../../images/events_logo.png';
import events_name from '../../images/events_name.png';
import arrowLeft from '../../images/arrow-left.png';

class EventsAndActivities extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
           myProgramsData:[],
           otherCampaignsData:[],
           myProgramsListCheck:0,
           otherProgramsListCheck:0,
           otherProgramsBlock:false,
           myProgramsBlock:true,
           conductedStatusFilterVal: props?.location?.params?.selectedEventDisplay == undefined ? 1 : props?.location?.params?.selectedEventDisplay,
           yetToStartCount:0,
           onGoingCount:0,
           completedCount:0,
           unique_myPrograms:[],
           unique_otherPrograms:[],
          
        }
    }
  
    componentDidMount() {
      this.getAllPrograms();
    }

    getAllPrograms = () => {
      this.setState({ myProgramsListCheck:0,otherProgramsListCheck:0});
      
      const userAccessObj =  
      JSON.stringify({ 
        "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
      }
    ); 

    //getBooths
    getCampaignAPICallData(userAccessObj,"getAllCampaignsList")
        .then((res) => res.json())
        .then((data) => {
           if(data.myCampaigns !=null && data.myCampaigns.length>0){
            let unique_campaignArr = new Map();
              let unique = data.myCampaigns.filter(el =>  {
                if(el.conductedStatusId == this.state.conductedStatusFilterVal){
                    const val = unique_campaignArr.get(el.campaignId);
                    console.log(el.campaignName.trim())
                   // console.log(val)
                    if(val) {
                        if(el.campaignName < val) {
                          unique_campaignArr.delete(el.campaignId);
                          unique_campaignArr.set(el.campaignId, el.campaignName);
                            return true;
                        } else {
                            return false;
                        }
                    }
                    unique_campaignArr.set(el.campaignId, el.campaignName);
                    return true;
                }
              });

           
              var mainMyCampaignData = data.myCampaigns.sort((a,b) => b.campaignId - a.campaignId);
              var unique_myProgramsSort = unique.sort((a,b) =>b.campaignId - a.campaignId);
              this.setState({ myProgramsData: mainMyCampaignData,myProgramsListCheck:0,unique_myPrograms:unique_myProgramsSort});
              this.setState({ yetToStartCount: mainMyCampaignData.filter(item => item.conductedStatusId == 1)});
              this.setState({ onGoingCount: mainMyCampaignData.filter(item => item.conductedStatusId == 2)});
              this.setState({ completedCount: mainMyCampaignData.filter(item => item.conductedStatusId == 3)});
            
           }else{
            this.setState({ myProgramsData: [],myProgramsListCheck:1,unique_myPrograms:[]});
           }

           if(data.otherCampaigns !=null && data.otherCampaigns.length>0){
            let unique_other_campaignArr = new Map();
              let unique_other = data.otherCampaigns.filter(el =>  {
                  const val = unique_other_campaignArr.get(el.campaignId);
                  if(val) {
                      if(el.campaignName < val) {
                        unique_other_campaignArr.delete(el.campaignId);
                        unique_other_campaignArr.set(el.campaignId, el.campaignName);
                          return true;
                      } else {
                          return false;
                      }
                  }
                  unique_other_campaignArr.set(el.campaignId, el.campaignName);
                  return true;
              });

              console.log("unique_other",unique_other)
              var mainOtherCampaignData = data.otherCampaigns.sort((a,b) => b.campaignId - a.campaignId);
              var unique_otherProgramsSort = unique_other.sort((a,b) =>b.campaignId - a.campaignId);
              this.setState({ otherCampaignsData: mainOtherCampaignData,otherProgramsListCheck:0,unique_otherPrograms:unique_otherProgramsSort});
           }else{
            this.setState({ otherCampaignsData: [],otherProgramsListCheck:1,unique_otherPrograms:[]});
           }
        });
    }

    programsBlockShow = (blockType) => {
      this.setState({ conductedStatusFilterVal: 1});
      if(blockType == "myPrograms"){
        this.setState({ myProgramsBlock: true});
        this.setState({ otherProgramsBlock: false});
      }else{
        this.setState({ otherProgramsBlock: true});
        this.setState({ myProgramsBlock: false});
      }

    }

    getStatusWiseProgramsShow = (conductedStatusType) => {
      let unique_campaignArr = new Map();
          let unique = this.state.myProgramsData.filter(el => {
            if(el.conductedStatusId == conductedStatusType){
              const val = unique_campaignArr.get(el.campaignId);
              if(val) {
                  if(el.campaignName < val) {
                    unique_campaignArr.delete(el.campaignId);
                    unique_campaignArr.set(el.campaignId, el.campaignName);
                      return true;
                  } else {
                      return false;
                  }
              }
              unique_campaignArr.set(el.campaignId, el.campaignName);
              return true;
            }
             
          });

          console.log(unique)
          var unique_myProgramsSort1 = unique.sort((a,b) =>b.campaignId - a.campaignId);
          this.setState({ unique_myPrograms:unique_myProgramsSort1});
          this.setState({ conductedStatusFilterVal: conductedStatusType});
    }

    showProgramBlockCls = (selectedProgram) => {
      this.setState({programBlockOpen:!this.state.programBlockOpen,blockOpenCampaignId:selectedProgram.campaignId });
  }

    render() {
      console.log("unique_otherPrograms",this.state.unique_otherPrograms)
        return (
          <section>
            <div className=''>
                <div className='flex_event_name_top'>
                  <Link to={{pathname: "/dashboard"}}> <i class="fa-solid fa-arrow-left color_black"></i></Link>
                  <Image src={events_logo} alt="event logo" ></Image>
                  <Image src={events_name} alt="event Name" ></Image>
                    
              </div>
            </div>
            <div className='bg_red_events color_white fw-bold f-12'>
              Programs
            </div>
            <Container fluid>
                <Row>

                    <Col sm={12} className='mt-2'>
                        <div className='flex_programs_block'>
                          <div className={this.state.myProgramsBlock == true ? "border_bottom_my cusor_pointer" : "cusor_pointer"} onClick={() => this.programsBlockShow("myPrograms")}><h6 className='fw-bold f-14'><Image src={my_programsImg} className='icon_width_height' alt="My Programs" ></Image> <span className='ms-2'>My Programs</span></h6></div>
                          <div className={this.state.otherProgramsBlock == true ? "border_bottom_other cusor_pointer" : "cusor_pointer"} onClick={() => this.programsBlockShow("otherPrograms")}><h6 className='fw-bold f-14'><Image src={other_programsImg} className='icon_width_height' alt="other Programs" ></Image> <span className='ms-2'>Other Programs</span></h6></div>
                        </div>
                     </Col>
                    {
                      this.state.myProgramsBlock == true ?
                      (
                        <Col sm={12} className='mt-2'>
                          <ul className='list-inline eventStatusLiCls'>
                            <li className={this.state.conductedStatusFilterVal == 1 ? "active red" : ""} onClick={() => this.getStatusWiseProgramsShow(1)}>Yet to start : {this.state.yetToStartCount.length}</li>
                            <li className={this.state.conductedStatusFilterVal == 2 ? "active yellow" : ""} onClick={() => this.getStatusWiseProgramsShow(2)}>Ongoing : {this.state.onGoingCount.length}</li>
                            <li className={this.state.conductedStatusFilterVal == 3 ? "active green" : ""} onClick={() => this.getStatusWiseProgramsShow(3)}>Completed : {this.state.completedCount.length}</li>
                          </ul>
                      </Col>
                      )
                      :
                      ("")
                    }
                     
                     
                     <div className={this.state.myProgramsBlock == true ? "show_block" : "hide_block"}>
                        <Col sm={12}>
                          <h6 className='fw-bold f-14'>My Programs : </h6>
                        </Col>
                          {
                            this.state.myProgramsData.length == 0 ?
                            (
                                this.state.myProgramsListCheck == 1 ?
                                (<Col className='f-14 text-center'>No Programs Available</Col>)
                                :
                                (<Col sm={12}>
                                  <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>)
                            )
                            :
                            (
                              this.state.myProgramsData.length>0 ?
                                (
                                  <Accordion>
                                      {
                                        this.state.unique_myPrograms.length>0 && this.state.unique_myPrograms.map((item,index) => {
                                          return(
                                            <>
                                            <Accordion.Item eventKey={index}>
                                              <Accordion.Header className='fw-bold'>{item.campaignName}</Accordion.Header>
                                              <Accordion.Body className='pad_bottom'>
                                                  {
                                                     this.state.myProgramsData.filter(filterVal => filterVal.conductedStatusId == this.state.conductedStatusFilterVal && filterVal.campaignId == item.campaignId).map((myProg,index) => {
                                                      return(
                                                          <Col sm={12} key={index}>
                                                            <Link to={{
                                                                pathname: "/ongoing_ready_event" ,
                                                                params: {
                                                                    navbarDisplay:"hide",
                                                                    eventStatus: myProg.conductedStatusId,
                                                                    event:myProg,
                                                                    eventType:"my_programs",
                                                                    campaignDetailsId:myProg.campaignDetailsId,
                                                                    isAttended:"",
                                                                    surveyType:myProg.surveyType
                                                                }
                                                            }} className="color_black_no_underline">
                                                            <div className={myProg.conductedStatusId == 1 ? "white_block_with_left_border border_left_black mt-2" : myProg.conductedStatusId == 2 ? "white_block_with_left_border border_left_yellow  mt-2" : "white_block_with_left_border border_left_green  mt-2"}>
                                                                  <div>
                                                                    <h6 className='f-12 fw-bold'>{myProg.campaignName}  
                                                                    <span className='float-end f-12'>
                                                                      <i className={myProg.conductedStatusId == 1 ? "fa-regular fa-circle-dot color_yellow mr-2" : myProg.conductedStatusId == 2 ? "fa-regular fa-circle-dot color_green mr-2" : "mr-2 fa-regular fa-circle-dot color_black"}></i> 
                                                                      {myProg.conductedStatus == "READY" ? "YET TO START" : myProg.conductedStatus}
                                                                      </span>
                                                                    </h6>
                                                                    <h6 className='f-12 fw-bold color_red'>{myProg.subType}</h6>
                                                                  </div>
                                                                  <div className='mt-3'>
                                                                    <div>
                                                                      <h6 className='f-12'>Location</h6>
                                                                      <h6 className='f-12'>{myProg.level} - {myProg.location}</h6> 
                                                                    </div>
                                                                  </div>
                                                                  <div className='mt-2'>
                                                                    <Row>
                                                                      <Col>
                                                                      {
                                                                        myProg.conductedStatusId == 1 ?
                                                                        (
                                                                          <OverlayTrigger key={"top"} placement={"top"}
                                                                            overlay={
                                                                              <Tooltip id={`tooltio-Filter`}>
                                                                                <h6 className='f-12'>Start Event...</h6>
                                                                              </Tooltip>
                                                                            }
                                                                          >
                                                                          <Button variant="outline-danger" className='float-end f-12 pad_10_5 fw-bold button_red'>Start Now <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                          </OverlayTrigger>
                                                                          )  
                                                                        :
                                                                        (
                                                                          myProg.conductedStatusId == 2 ?
                                                                          (
                                                                            <OverlayTrigger key={"top"} placement={"top"}
                                                                              overlay={
                                                                                <Tooltip id={`tooltio-Filter`}>
                                                                                  <h6 className='f-12'>View Event...</h6>
                                                                                </Tooltip>
                                                                              }
                                                                            >
                                                                            <Button variant="outline-warning" className='float-end f-12 pad_10_5 fw-bold'>View <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                          </OverlayTrigger>
                                                                         
                                                                          )
                                                                          :
                                                                          (
                                                                            <OverlayTrigger key={"top"} placement={"top"}
                                                                              overlay={
                                                                                <Tooltip id={`tooltio-Filter`}>
                                                                                  <h6 className='f-12'>View Event...</h6>
                                                                                </Tooltip>
                                                                              }
                                                                            >
                                                                            <Button variant="outline-success" className='float-end f-12 pad_10_5 fw-bold'>View <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                          </OverlayTrigger>
                                                                            
                                                                          )
                                                                        )
                                                                      }
                                                                        
                                                                      </Col>
                                                                    </Row>
                                                                    
                                                                  </div>
                                                                 
                                                              </div>
                                                              </Link>
                                                        </Col>
                  
                                                      )
                                                    })
                                                  }
                                              </Accordion.Body>
                                            </Accordion.Item>
                                            
                                            </>
                                          )
                                        })
                                      }
                                  </Accordion>
                                )
                                :
                                ("")
                            )
                          }
                        

                     </div>
                     <div className={this.state.otherProgramsBlock == true ? "show_block" : "hide_block"}>
                        <Col sm={12} className="mt-2">
                          <h6 className='fw-bold f-14'>Other Programs : </h6>
                        </Col>
                        {
                            this.state.otherCampaignsData.length == 0 ?
                            (
                              
                              this.state.otherProgramsListCheck == 1 ?
                              (<Col className='f-14 text-center'>No Programs Available</Col>)
                              :
                              (
                                <Col sm={12}>
                                  <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>
                              )
                            )
                            :
                            (
                              this.state.otherCampaignsData.length>0 ?
                                (
                                  <Accordion>
                                      {
                                        this.state.unique_otherPrograms.length>0 && this.state.unique_otherPrograms.map((item,index) => {
                                          return(
                                            <>
                                            <Accordion.Item eventKey={index}>
                                              <Accordion.Header className='fw-bold'>{item.campaignName}</Accordion.Header>
                                              <Accordion.Body className='pad_bottom'>
                                                  {
                                                     this.state.otherCampaignsData.filter(filterVal =>  filterVal.campaignId == item.campaignId).map((item,index) => {
                                                      return(
                                                        <Col sm={12} key={index}>
                                                          <Link to={{
                                                              pathname: "/ongoing_ready_event" ,
                                                              params: {
                                                                  navbarDisplay:"hide",
                                                                  eventStatus: item.conductedStatusId,
                                                                  event:item,
                                                                  eventType:"other_programs",
                                                                  campaignDetailsId:item.campaignDetailsId,
                                                                  isAttended:item.isAttended,
                                                                  surveyType:item.surveyType
                                                              }
                                                          }} className="color_black_no_underline">
                                                            <div className={item.conductedStatusId == 1 ? "white_block_with_left_border border_left_black  mt-2" : item.conductedStatusId == 2 ? "white_block_with_left_border border_left_yellow  mt-2" : "white_block_with_left_border border_left_green mt-2"}>
                                                                  <div>
                                                                    <h6 className='f-14 fw-bold'>{item.campaignName}  
                                                                    <span className='float-end'>
                                                                      <i className={item.conductedStatusId == 1 ? "fa-regular fa-circle-dot color_yellow mr-2" : item.conductedStatusId == 2 ? "fa-regular fa-circle-dot color_green mr-2" : "mr-2 fa-regular fa-circle-dot color_black"}></i> 
                                                                      {item.conductedStatus}
                                                                      </span>
                                                                    </h6>
                                                                    <h6 className='f-12 fw-bold color_red'>{item.subType}</h6>
                                                                  </div>
                                                                  <div className='mt-3'>
                                                                    <div>
                                                                      <h6 className='f-12'>Location</h6>
                                                                      <h6 className='f-12'>{item.level} - {item.location}</h6>
                                                                    </div>
                                                                  </div>
                                                                  <div className='mt-2'>
                                                                    <Row>
                                                                      <Col>
                                                                      {
                                                                        item.conductedStatusId == 1 ?
                                                                        (
                                                                          <OverlayTrigger key={"top"} placement={"top"}
                                                                            overlay={
                                                                              <Tooltip id={`tooltio-Filter`}>
                                                                                <h6 className='f-12'>Start Event...</h6>
                                                                              </Tooltip>
                                                                            }
                                                                          >
                                                                          <Button variant="outline-danger" className='float-end f-12 pad_10_5 fw-bold'>Start Now <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                          </OverlayTrigger>
                                                                          )  
                                                                        :
                                                                        (
                                                                          item.conductedStatusId == 2 ?
                                                                          (
                                                                            <OverlayTrigger key={"top"} placement={"top"}
                                                                              overlay={
                                                                                <Tooltip id={`tooltio-Filter`}>
                                                                                  <h6 className='f-12'>View / Particiapte Event...</h6>
                                                                                </Tooltip>
                                                                              }
                                                                            >
                                                                            <Button variant="outline-warning" className='float-end f-12 pad_10_5 fw-bold'>View / Particiapte <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                          </OverlayTrigger>
                                                                         
                                                                          )
                                                                          :
                                                                          (
                                                                            <OverlayTrigger key={"top"} placement={"top"}
                                                                              overlay={
                                                                                <Tooltip id={`tooltio-Filter`}>
                                                                                  <h6 className='f-12'>View Event...</h6>
                                                                                </Tooltip>
                                                                              }
                                                                            >
                                                                            <Button variant="outline-success" className='float-end f-12 pad_10_5 fw-bold'>View <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                          </OverlayTrigger>
                                                                            
                                                                          )
                                                                        )
                                                                      }
                                                                        
                                                                      </Col>
                                                                    </Row>
                                                                    
                                                                  </div>
                                                              </div>
                                                            </Link>
                                                      </Col>
                  
                                                      )
                                                    })
                                                  }
                                              </Accordion.Body>
                                            </Accordion.Item>
                                            
                                            </>
                                          )
                                        })
                                      }
                                  </Accordion>
                                  
                                )
                                :
                                ("")
                              )
                          }
                       
                     </div>
                </Row>  
                
            </Container>
            </section>
        )
    }
  }
  
  export default withRouter(EventsAndActivities);
  