
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,InputGroup  } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import { getAPICallData } from '../../services/MemberService'
import { scroller } from "react-scroll";
import BGLOGO from '../../images/BG_logo.png';
import Webcam from "react-webcam";
import EnabledLocationPopup from "../Volunteer/EnabledLocationPopup";
import FamilyVoterSearch from './FamilyVoterSearch';
import QuestionsSavingPart from "./QuestionsSavingPart";
import jwt_decode from "jwt-decode";
import LOKESH from '../../images/Lokesh.png';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class SingleVoterSearch extends Component {
    constructor(props) {
        super(props)
       

        this.state = {
            webcamRef : React.createRef(),
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            searchOptionTypeVal:props?.location?.params?.searchOptionTypeVal == undefined ? "family" : props?.location?.params?.searchOptionTypeVal,
            campaignId:props?.location?.params?.campaignId == undefined ? "" : props?.location?.params?.campaignId,
            accessNameNavigation:localStorage.getItem('accessNameNavigation'),

            ap_listArr:[],
            SearchconstituencyValue:"",
            volunteerSearchConstituencyName:"",
            volunteerSearchBoothName:"",
            SearchBoothValue:"",
            searchOptionBoothListArr:[],
            boothsLoadingSymbol:"hide",
            volunteerSerchGender:"",
            volunteerSearchName:"",
            voterDisplayArr:[],
            votersDataCheck:0,
            votersListLoader:"hide",
            volunteerSearchVoterCardNo:"",
            searchByMobileNo:"",
            validateSearchByMobileNo:"",
            validateSearchByBoothNo:"",

            checkingLocationServiceEnabledObj:{},
            checkingEnabledLocationModal:false,
            delaylocationModal:false,

            checkingLocationServiceVoterObj:{},

            voterVisitedImageCaptureModal:false,

            photoCaptureArr:[],
            showImgCapture:true,
            imageAddedMsg:"",

            captureMobileNo:"",
            validateCaptureMobileNo:"",
            validateCapturePhoto:"",

            
            savaCampaignLoadingSymbol:"hide",
            savaCampaignLoadingSuccessMsg:"",

            selectedVoterVisitVoterId:{},

            captureOTPNO:"",
            validateCaptureOTP:"",

            mobileNoDisabled:false,

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",
            
            minutes:1,
            seconds:0,

            voterSearchEnrolled:"N",

            validateCaptureMobileNoInAbove:"",
            validateCaptureOTPInAbove:"",

            checkOTPImageValidate:"Y",
            submitButtonDisabled:true,
            OTPVerifiedDisabledButton:true,

            campaignSurveyQuestionnaireArr:null,
            answerDateTemplateArr:[],
            checkValidationsArr:[],
            checkQuestionsMessage:""

        }
      
    }

    componentDidMount() {
        
        console.log("userAccessList",this.state)
			
        if(this.state.userAccessList == "" || this.state.campaignId == ""){
            this.props.history.push("/dashboard");
        }else{
            if(this.state.chatID != "1048868711"){
                if(navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(this.checkingLocationServiceEnabled,this.checkingLocationServiceEnabledError)
                }else{
                    <EnabledLocationPopup 
                        show={() => this.setState({ checkingEnabledLocationModal: true })}
                        onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                        closeEnabledModal={this.closeEnabledModal}
                    />
                }
                setTimeout(() => this.setState({delaylocationModal:true}), 2000);
             }
           

            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
                
                
            }
           
            this.setState({ ap_listArr:consArr });
            this.setState({
                SearchconstituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },volunteerSearchConstituencyName:this.state.userAccessList.constituencyList[0].id
        });

        if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
            let boothListArr=[];
            this.state.userAccessList.boothList.map(item => {
                const obj = {
                    value: item.id,
                    label: "Booth No -"+item.name,
                }
                boothListArr.push(obj);
                
            });
            this.setState({ searchOptionBoothListArr: boothListArr });
            
            
        }

        this.getCampaignSurveyQuestionnaire();
        
            
        }
    }

    getCampaignSurveyQuestionnaire = () => {
        const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "campaignId" : this.state.campaignId,
        });
    
    

    getAPICallData(obj,"CampaignEnrollment/getCampaignSurveyQuestionnaire")
        .then((res) => res.json())
        .then((content) => {
            if(content !=null && content.length>0){
                this.setState({campaignSurveyQuestionnaireArr:content});
            }else{
                this.setState({campaignSurveyQuestionnaireArr:[]});
            }
        });
    }
   

    
    checkingLocationServiceEnabled = (position) => {
        const getCoordinates =  
            { 
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude,
            "accuracy":position.coords.accuracy
            }
            
        this.setState({checkingLocationServiceEnabledObj:getCoordinates});
    }

    checkingLocationServiceVoter = (position) => {
        const getCoordinates =  
            { 
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude,
            "accuracy":position.coords.accuracy
            }
            
        this.setState({checkingLocationServiceVoterObj:getCoordinates});
    }

    

    checkingLocationServiceEnabledError = (error) => {
      return(
        <EnabledLocationPopup 
                show={() => this.setState({ checkingEnabledLocationModal: true })}
                onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                closeEnabledModal={this.closeEnabledModal}
         />
      )
        
    }

    closeEnabledModal = () => {
        if(this.state.campaignId == 4){
            this.props.history.push({
                pathname: "/voter_slip_campaign",
                params: {
                    navbarDisplay:"show",
                    userAccessList:this.state.userAccessList,
                    campaignId:4
                
                },
            });
        }else if(this.state.campaignId == 5){
            this.props.history.push({
                pathname: "/voter_status_campaign",
                params: {
                    navbarDisplay:"show",
                    userAccessList:this.state.userAccessList,
                    campaignId:5
                
                },
            });
        }else if(this.state.campaignId == 6){
            this.props.history.push({
                pathname: "/exit_poll_campaign",
                params: {
                    navbarDisplay:"show",
                    userAccessList:this.state.userAccessList,
                    campaignId:6
                
                },
            });
        }
        
    }

    searchDigitalCardType = (e,type) => {
        this.setState({
                //SearchconstituencyValue:"",
                //volunteerSearchConstituencyName:"",
                volunteerSearchBoothName:"",
                SearchBoothValue:"",
                searchOptionBoothListArr:[],
                boothsLoadingSymbol:"hide",
                volunteerSerchGender:"",
                volunteerSearchName:"",
                voterDisplayArr:[],
                votersDataCheck:0,
                votersListLoader:"hide",
                volunteerSearchVoterCardNo:"",
                searchByMobileNo:"",
                validateSearchByMobileNo:"",
                validateSearchByBoothNo:"",
                selectedVoterVisitVoterId:{}
        })
        this.setState({ searchOptionTypeVal: type });
        if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
            let boothListArr=[];
            this.state.userAccessList.boothList.map(item => {
                const obj = {
                    value: item.id,
                    label: "Booth No -"+item.name,
                }
                boothListArr.push(obj);
                
            });
            this.setState({ searchOptionBoothListArr: boothListArr });
        }
      }

          
  handleOnchangeSelectBoxNew1 = (ename, evalue) =>{
    const name = ename;
    const value = evalue;

    if(name == "volunteerSearchConstituencyName"){
        let getConsIdName = this.state.ap_listArr.find((a) => a.value == value);
            this.setState({
                SearchconstituencyValue: {
                value: getConsIdName.value,
                label: getConsIdName.label
            }
        });
       // this.serchOptionGetBooths(value);
    }else if(name == "volunteerSearchBoothName"){
        let getBoothIdName = this.state.searchOptionBoothListArr.find((a) => a.value == value);
        this.setState({
            SearchBoothValue: {
            value: getBoothIdName.value,
            label: getBoothIdName.label
        }
    });

    //this.checkBoothForCondition(value);
       
    }
      

    this.setState({ [name]: value});
  }

  checkBoothForCondition = (value) => {
    const obj =  
       JSON.stringify({ 
           "chatId" :this.state.chatID,
           "boothId" : value,
           "conditionName" : "BG_NO_OTP"
       });
   getAPICallData(obj,"checkBoothForCondition")
       .then((res) => res.json())
       .then((content) => {
          if(content !=null){
           if(content.name == 'Y'){
               this.setState({checkOTPImageValidate:content.name})
           }else{
               this.setState({checkOTPImageValidate:"mobile"})
           }
          
          }else{
           this.setState({checkOTPImageValidate:"mobile"})
          }
   });
}

  
  checkEnrolledByVoterSearch = (e,value) => {
    this.setState({voterSearchEnrolled:value})
}

  getVotersDisplay = () => {
    var votersENArr=[];
    this.setState({voterDisplayArr:[],votersDataCheck:0,votersListLoader:"hide",validateSearchByMobileNo:"",validateSearchByBoothNo:""});
    let voter_serch_obj =  "";
    if(this.state.volunteerSearchBoothName == ""){
        this.setState({validateSearchByBoothNo:"Select Booth" });
        return;
    }
    

    if(this.state.searchByMobileNo != ""){
        var expr = /^(0|91)?[6-9][0-9]{9}$/;
        if(!expr.test(this.state.searchByMobileNo)){
            this.setState({validateSearchByMobileNo:"Please Enter Valid Mobile No" });
            return;
        }

        
        if(this.state.searchByMobileNo.length < 10){
            this.setState({validateSearchByMobileNo:"Enter Valid Mobile No" });
            return;
        }
    }

    

    voter_serch_obj =  JSON.stringify({ 
        "chatId" :this.state.chatID,
        "constituencyId" : this.state.SearchconstituencyValue.value,
        "voterName":this.state.volunteerSearchName,
        "voterCardNo":this.state.volunteerSearchVoterCardNo,
        "isBSBGVoter":'Y',
        "boothId":this.state.volunteerSearchBoothName,
        "campaignId":this.state.campaignId
      }); 
    
    
    this.setState({votersListLoader:"show"});

        getAPICallData(voter_serch_obj,"getVoterDetailsBySearch")
            .then((res) => res.json())
            .then((content) => {
                this.setState({votersListLoader:"hide"});
                if(content !=null && content.length>0){
                    if(this.state.voterSearchEnrolled == ""){
                        votersENArr = content;
                    }else{
                        votersENArr = content.filter((item) => item.isEnrolled == this.state.voterSearchEnrolled );
                    }
                    if(this.state.voterSearchEnrolled == "Y"){
                        if(votersENArr.length == 0){
                            this.setState({voterDisplayArr:votersENArr,votersDataCheck:1});
                        }else{
                            this.setState({voterDisplayArr:votersENArr,votersDataCheck:0});
                        }
                    }else{
                        this.setState({voterDisplayArr:votersENArr,votersDataCheck:0});
                    }
                    

                }else{
                    this.setState({voterDisplayArr:[],votersDataCheck:1});
                }
        });

    

    //1729381
  }

  sortFuncSerialNo(a, b, order, dataField, rowA, rowB)  { 
    if (order == 'desc') {
        
        return parseInt(rowB.voterIdCardNo)  > parseInt(rowA.voterIdCardNo) ? 1 : -1;

    } else {
        return parseInt(rowA.voterIdCardNo)  > parseInt(rowB.voterIdCardNo) ? 1 : -1;
    }
}

actionsFormatterForVoterSerialNoAtt = (cell, row, rowIndex, formatExtraData) => {
    return (
       (row.voterIdCardNo)
       
     );
}

actionsFormatterForVoterName = (cell, row, rowIndex, formatExtraData) => {
    const voter_Name = this.state.voterDisplayArr.filter((item,index) => item.voterId == row.voterId).map(blist => blist.voterName);
    const voter_Card = this.state.voterDisplayArr.filter((item,index) => item.voterId == row.voterId).map(blist => blist.voterCardNo);
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{voter_Name} 
            <><br/><span className='f-10 color_red'>VCN: {voter_Card}</span></>
        </span>
     );
    
}

actionsFormatterForEnroll = (cell, row, rowIndex, formatExtraData) => {
    const selectedVoterData = this.state.voterDisplayArr.filter((item,index) => item.voterId == row.voterId);
    return (
        <>
            {
                row.isEnrolled == 'Y' ?
                (<span className='fw-bold color_green' style={{textAlign:"left",marginLeft:"0px"}}>Visited</span>)
                :
                (<span className='view_enroll_css fw-bold' style={{textAlign:"left",marginLeft:"0px"}} onClick={() => this.voterWiseVisitCaptureClick(selectedVoterData)}>Visit</span>)
            }
        </>
        
          
     );
}

  votersDisplayColumns() {

    let columns = [
        {
            dataField: "Serial_No",
            text: "Serial No",
            sort: true,
            formatter: this.actionsFormatterForVoterSerialNoAtt.bind(this),
            sortFunc: this.sortFuncSerialNo.bind(this)
            
            
        },
        {
            dataField: "voter_Name",
            text: "Name",
            formatter: this.actionsFormatterForVoterName.bind(this),
            headerStyle: {
                width: "35%"
            }
            
        },
        {
            dataField: "Age",
            text: "Age",
            
        },

        {
            dataField: "Gender",
            text: "Gender",
           
        },
        
        {
            dataField: "Actions",
            text: "Actions",
            formatter: this.actionsFormatterForEnroll.bind(this),
            headerStyle: {
                width: "20%"
            }
            
        },
       
    ];
    return columns;
}

votersDisplayData = () => {
    let votersData = [];
    if(this.state.voterDisplayArr.length>0){
        for (let i = 0; i < this.state.voterDisplayArr.length; i++) {
            const obj = {
                Serial_No:this.state.voterDisplayArr[i].voterCardNo,
                voter_Name: this.state.voterDisplayArr[i].voterName,
                Age: this.state.voterDisplayArr[i].age,
                Gender: this.state.voterDisplayArr[i].gender,
                voterId:this.state.voterDisplayArr[i].voterId,
                voterIdCardNo:this.state.voterDisplayArr[i].serialNo,
                isEnrolled:this.state.voterDisplayArr[i].isEnrolled,
                Actions:"Enroll"
            }
            votersData.push(obj);
        } 

        
        
        return votersData;
    }
}

handleOnchangeInputBox = (e) => {
    console.log("handleInputChange", e.target.name, e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    this.setState({validateCaptureMobileNo:"",validateCaptureMobileNoInAbove:"",validateCaptureOTP:"",validateCaptureOTPInAbove:""});

    if(name == "captureOTPNO"){
        this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
        if(value.length >= 6){
            this.validateMobileOtp(value);
           
        }
        
    }else if(name == "captureMobileNo"){
        if(value.length >= 10){
           // this.checkMobileNumberAlreadyExistForCampaignEnrollment(value);
            
        }
    }

    this.setState({ [name]: value });
    
  }

  checkMobileNumberAlreadyExistForCampaignEnrollment = (value) => {
    this.setState({mobileNoLoadingSymbol:"show",mobileNoSuccessMsg:""});

    const mapObj =  
    JSON.stringify({ 
        "chatId" : this.state.chatID,
        "campaignId" : this.state.campaignId,
        "mobileNo" : value
    }); 
        

    getAPICallData(mapObj,"CampaignEnrollment/checkMobileNumberAlreadyExistForCampaignEnrollment")
      .then((response) => {
        this.setState({mobileNoLoadingSymbol:"hide"});
        // 1. check response.ok
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response); // 2. reject instead of throw
      })
      .then((json) => {
        // all good, token is ready
        if(json.status == "Success"){
            this.setState({mobileNoSuccessMsg:"SUCCESS"});
        }else{
            this.setState({mobileNoSuccessMsg:"FAIL"});
        }
      })
      .catch((response) => {
        this.setState({mobileNoSuccessMsg:"Something Wrong,Please Try Again..."});
        response.json().then((json) => {
            this.setState({mobileNoSuccessMsg:"Something Wrong,Please Try Again..."});
        })
      });

   
  }


  voterWiseVisitCaptureClick = (data) => {
    if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(this.checkingLocationServiceVoter,this.checkingLocationServiceEnabledError)
    }

    this.setState({photoCaptureArr:[],showImgCapture:true,
        imageAddedMsg:"",savaCampaignLoadingSuccessMsg:"",captureMobileNo:"",captureOTPNO:"",
        validateCaptureOTP:"",
        validateCaptureMobileNoInAbove:"",
        validateCaptureOTPInAbove:"",

        mobileNoDisabled:false,

        smsSentMessage:"",
        otpVerifiedMessageStatus:"",
        otpLoadingSymbol:"hide",
        getOTPLoadingSymbol:"hide",
        OTPSentStatus:"",
        resendOTPLoadingSymbol:"hide",
        resendSmsSentMessage:"",

        mobileNoLoadingSymbol:"hide",
        mobileNoSuccessMsg:"",
        answerDateTemplateArr:[],
        checkValidationsArr:[],
        checkQuestionsMessage:"",
        
        minutes:1,
        seconds:0,OTPVerifiedDisabledButton:true,validateCapturePhoto:""});
        setTimeout(() => this.setState({selectedVoterVisitVoterId:data,voterVisitedImageCaptureModal:true}), 1000);
    

   
  }

  
getOnchangeSelectedKPIAnswer = (e,data,mainlist) => {
    const name = e.target.name;
    const value = e.target.value;

    var newItem = {};
    var tempArr = this.state.answerDateTemplateArr;

    this.setState({checkQuestionsMessage:""});
    for(var i in this.state.campaignSurveyQuestionnaireArr){
        var errorQuestion = "errorMsgDisplay"+this.state.campaignSurveyQuestionnaireArr[i].questionId+"";
        document.getElementById(errorQuestion).innerHTML = "";
    }

    const objWithIdIndex = tempArr.findIndex((obj) => obj.questionId == mainlist.questionId);

    if (objWithIdIndex > -1) {
        tempArr.splice(objWithIdIndex, 1);
        this.setState({answerTemplateArr:tempArr})
    }


    if (mainlist.answerType == "Single Option") {
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            optionId: value, 
        };
    }else if(mainlist.answerType == "Number"){
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            answerNumber: value, 
        };
    }else if(mainlist.answerType == "Text"){
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            answerText: value, 
        };
    }else if(mainlist.answerType == "Percentage"){
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            answerPercentage: value, 
        };
    }else if(mainlist.answerType == "Multi Option"){
        newItem = {
            campaignSurveyId:mainlist.campaignSurveyId,
            questionId: mainlist.questionId,
            optionId: value, 
        };
    }

    this.setState({answerDateTemplateArr:[...this.state.answerDateTemplateArr, newItem]}); 
}



saveCampaignEnrollment = () => {
    var base64ImgListArr=[];
    let finalMergeKPIAnswerDts = this.state.answerDateTemplateArr;

    this.setState({validateCaptureMobileNo:"",validateCapturePhoto:"",validateCaptureOTP:"",validateCaptureMobileNoInAbove:"",validateCaptureOTPInAbove:""});

    if(this.state.checkOTPImageValidate == "mobile"){
        if(this.state.captureMobileNo == ""){
            this.setState({validateCaptureMobileNo:"Please Enter Mobile No",validateCaptureMobileNoInAbove:"Please Scroll to Above To Enter Mobile No" });
            return;
        }
        if(!this.state.captureMobileNo.match(/^[0-9]+$/)){
            this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No",validateCaptureMobileNoInAbove:"Please Scroll to Above To Enter Valid Mobile No" });
            return;
        }
    
        if(this.state.captureMobileNo.length < 10){
            this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No",validateCaptureMobileNoInAbove:"Please Scroll to Above To Enter Mobile No" });
            return;
        }
    
        if(this.state.captureMobileNo != ""){
            if(this.state.captureOTPNO == ""){
                this.setState({validateCaptureOTP:"Please Enter OTP",validateCaptureOTPInAbove:"Please Scroll to Above To Enter OTP" });
                return;
            }
    
            if(this.state.captureOTPNO.length < 6){
                this.setState({validateCaptureOTP:"Please Enter Valid OTP",validateCaptureOTPInAbove:"Please Scroll to Above To Enter Valid OTP" });
                return;
            }
    
            if(this.state.otpVerifiedMessageStatus == "NotVerified"){
                this.setState({validateCaptureOTP:"Please Enter Valid OTP",validateCaptureOTPInAbove:"Please Scroll to Above To Enter Valid OTP" });
                return;
            }
        }
    }else if(this.state.checkOTPImageValidate == "Y"){

       //clear 
       for(var i in this.state.campaignSurveyQuestionnaireArr){
            var errorQuestion = "errorMsgDisplay"+this.state.campaignSurveyQuestionnaireArr[i].questionId+"";
                document.getElementById(errorQuestion).innerHTML = "";
        }

            const validationsArr =  this.state.campaignSurveyQuestionnaireArr.filter(item => !finalMergeKPIAnswerDts.some(finalMergeKPIAnswerDts => finalMergeKPIAnswerDts.questionId == item.questionId))
            this.setState({checkValidationsArr:validationsArr})  

            if(validationsArr.length > 0){
                this.setState({checkQuestionsMessage:"Please Answer All Questions In Above Block"})
                for(var i in validationsArr){
                    var errorQuestion = "errorMsgDisplay"+validationsArr[i].questionId+"";
                    
                    if(validationsArr[i].answerType == "Single Option"){
                        document.getElementById(errorQuestion).innerHTML = "Please Check Option";
                        return;
                    }else if(validationsArr[i].answerType == "Multi Option"){
                        document.getElementById(errorQuestion).innerHTML = "Please Check Atleast one Option";
                        return;
                    }else if(validationsArr[i].answerType == "Number"){
                        document.getElementById(errorQuestion).innerHTML = "Please Enter Number";
                        return;
                    }else if(validationsArr[i].answerType == "Text"){
                        document.getElementById(errorQuestion).innerHTML = "Please Enter Text";
                        return;
                    }else if(validationsArr[i].answerType == "Percentage"){
                        document.getElementById(errorQuestion).innerHTML = "Please Enter Percentage";
                        return;
                    }
                    
                }
                
            }

        /*if(this.state.photoCaptureArr.length == 0){
            this.setState({validateCapturePhoto:"Please Take A Photo Atleast One..." });
            return;
        }*/

        if(this.state.photoCaptureArr.length > 0){
            for(var i in this.state.photoCaptureArr){
                base64ImgListArr.push(this.state.photoCaptureArr[i].data.replace("data:image/jpeg;base64,",""));
            }
        }
    }
    
    /*if(this.state.photoCaptureArr.length > 0){
        for(var i in this.state.photoCaptureArr){
            base64ImgListArr.push(this.state.photoCaptureArr[i].data.replace("data:image/jpeg;base64,",""));
        }
    }else{
        //this.setState({disabledUploadButton:false});
        this.setState({validateCapturePhoto:"Please Take A Photo Atleast One..." });
        return;
    }*/
    this.setState({savaCampaignLoadingSymbol:"show"});   

    const obj =  
    JSON.stringify({ 
        "chatId" :this.state.chatID,
        "mobileNo" : this.state.captureMobileNo,
        "campaignId" : this.state.campaignId,
        "voterName" : this.state.selectedVoterVisitVoterId[0].voterName,
        "voterId" : this.state.selectedVoterVisitVoterId[0].voterId,
        "gender" : this.state.selectedVoterVisitVoterId[0].gender,
        "age" : this.state.selectedVoterVisitVoterId[0].age,
        "houseId" : "",
        "boothId" : this.state.volunteerSearchBoothName,
        "constituencyId" : this.state.SearchconstituencyValue.value,
        "latitude":this.state.checkingLocationServiceVoterObj.latitude,
        "longitude":this.state.checkingLocationServiceVoterObj.longitude,
        "base64StrList" : base64ImgListArr,
        "answerList":finalMergeKPIAnswerDts
    });
    
    

getAPICallData(obj,"CampaignEnrollment/saveCampaignEnrollmentNew")
    .then((res) => res.json())
    .then((content) => {
        this.setState({savaCampaignLoadingSymbol:"hide"});  

        if(content !=null && content.status == "Success"){
            this.setState({savaCampaignLoadingSuccessMsg:"Visited SuccessFully..."});
             setTimeout(() => {
                this.setState({voterVisitedImageCaptureModal:false});
                this.setState({savaCampaignLoadingSuccessMsg:"",captureMobileNo:""});
                this.setState({photoCaptureArr:[],showImgCapture:true,
                    imageAddedMsg:"",captureOTPNO:"",
                    validateCaptureOTP:"",
                    validateCaptureMobileNoInAbove:"",
                    validateCaptureOTPInAbove:"",
            
                    mobileNoDisabled:false,
            
                    smsSentMessage:"",
                    otpVerifiedMessageStatus:"",
                    otpLoadingSymbol:"hide",
                    getOTPLoadingSymbol:"hide",
                    OTPSentStatus:"",
                    resendOTPLoadingSymbol:"hide",
                    resendSmsSentMessage:"",

                    mobileNoLoadingSymbol:"hide",
                    mobileNoSuccessMsg:"",
                    
                    minutes:1,
                    seconds:0,OTPVerifiedDisabledButton:true});
                    
                const visitedVoterListArr = this.state.voterDisplayArr.map((item) => {
                    if (item.voterId == this.state.selectedVoterVisitVoterId[0].voterId) {
                        return { ...item, isEnrolled:'Y' }
                    }
                    return { ...item }
                });
        
                console.log("visitedVoterListArr",visitedVoterListArr)
                this.setState({voterDisplayArr:visitedVoterListArr});

            }, 1000);
        }else if(content !=null && content.message == "Minimum 3 Mins Required"){
            this.setState({savaCampaignLoadingSuccessMsg:" ప్రతి ఓటర్ దగ్గర కొంత సమయం గడిపి వారికీ బాబు సూపర్ సిక్స్ ధ్వారా వారికి వచ్చే ఎక్కువ ప్రయోజనాలు తెలియజేయగలరు. ప్రతి ఇంటి వద్ద 3  నిముషాలు గడిపిన తరువాత మరొకరి రిజిస్ట్రేషన్ చేయగలరు" });
    
        }else if(content !=null  && content.message == "Same Location Detected"){
            this.setState({savaCampaignLoadingSuccessMsg:"ఓకే ఇంటి వద్ద రెండు కన్నా ఎక్కువ రెజిస్ట్రేషన్స్ చేస్తున్నారు , దయవుంచి ప్రక్క ఇంటివద్ద రెజిస్ట్రేషన్స్ చేయగలరు"});
    
        }else if(content !=null && content.status == "Fail"){
            this.setState({savaCampaignLoadingSuccessMsg:content.message});
    
        }else{
            this.setState({savaCampaignLoadingSuccessMsg:"Some Thing Went Wrong,Please Try Again..."});
    
        }
    });
}

removeCapturePhotoFromBSBG = (fileId) => {
    var tempArr = this.state.photoCaptureArr;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);
  
    if (objWithIdIndex > -1) {
      tempArr.splice(objWithIdIndex, 1);
    }
  
    this.setState({photoCaptureArr:tempArr})
  
  }
  
    
captureForHouseVisit = () => {
    this.setState({showImgCapture:true,imageAddedMsg:""});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        data : this.state.webcamRef.current.getScreenshot(),
      }

      this.setState({imageAddedMsg:"Capture Photo added successfully in below..."});
      setTimeout(() => {
        this.setState({photoCaptureArr:[...this.state.photoCaptureArr, obj]}); 
        this.setState({imageAddedMsg:"",validateCapturePhoto:""});
    }, 1000);
};

getMobileNoOTP = () => {
    this.setState({validateCaptureMobileNo:"" });
    var expr = /^(0|91)?[6-9][0-9]{9}$/;

    if(this.state.captureMobileNo == ""){
        this.setState({validateCaptureMobileNo:"Please Enter Mobile No" });
        return;
    }

    if(!expr.test(this.state.captureMobileNo)){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    if(this.state.captureMobileNo.length < 10){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }


    this.setState({smsSentMessage:"",getOTPLoadingSymbol:"show",OTPSentStatus:"",otpVerifiedValue:0});
    
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "mobileNo" : this.state.captureMobileNo,
        "campaignId":this.state.campaignId
        
        }); 

        getAPICallData(obj,"sendOTPToMobileNumberForCampaignEnrollment")
        .then((res) => res.json())
        .then((data) => {
        this.setState({getOTPLoadingSymbol:"hide"});
        if(data !=null && data.status == "Success"){
            console.log(jwt_decode(data.reference).sub)
            this.setState({smsSentMessage:"OTP Sent Your Mobile No",OTPSentStatus:data.status,otpVerifiedValue:jwt_decode(data.reference).sub});
            this.setState({mobileNoDisabled:true,OTPVerifiedDisabledButton:false})
            this.OTPTimerFunction();
        }else{
            this.setState({smsSentMessage:"Some Thing Went Wrong,Please Try Again...",OTPSentStatus:"Fail",otpVerifiedValue:0});
            this.setState({mobileNoDisabled:false,OTPVerifiedDisabledButton:true})
        }
        });
}

resendOTPTimer = () => {
    this.setState({minutes:1});
    this.setState({seconds:0});

    this.OTPTimerFunction();
};

OTPTimerFunction = () => {
    const interval = setInterval(() => {
        if (this.state.seconds > 0) {
        this.setState({seconds:this.state.seconds - 1});
        }

        if (this.state.seconds === 0) {
        if (this.state.minutes === 0) {
            clearInterval(interval);
        } else {
            this.setState({seconds:59});
            this.setState({minutes:this.state.minutes - 1});

        }
        }
    }, 1000);

    return () => {
        clearInterval(interval);
    };
}

resendOTPNew = () => {
    this.setState({validateCaptureMobileNo:"",OTPVerifiedDisabledButton:false });
    if(this.state.captureMobileNo == ""){
        this.setState({validateCaptureMobileNo:"Please Enter Mobile No" });
        return;
    }
    var expr = /^(0|91)?[6-9][0-9]{9}$/;
    if(!expr.test(this.state.captureMobileNo)){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    

    if(this.state.captureMobileNo.length < 10){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    this.setState({resendOTPLoadingSymbol:"show",otpVerifiedValue:0});

    const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.captureMobileNo,
            "campaignId":this.state.campaignId
        
        }); 

        getAPICallData(obj,"sendOTPToMobileNumberForCampaignEnrollment")
        .then((res) => res.json())
        .then((data) => {
        this.setState({resendOTPLoadingSymbol:"hide"});
        if(data !=null && data.status == "Success"){
            console.log(jwt_decode(data.reference).sub)
            this.setState({otpVerifiedValue:jwt_decode(data.reference).sub});
            this.resendOTPTimer();
        }else{
            this.setState({otpVerifiedValue:0});
        }
        });
    
}

validateMobileOtp = (otp) => {
    this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "mobileNo" : this.state.captureMobileNo,
        "otp" : otp
        

        }); 

        getAPICallData(obj,"validateMobileOtp")
        .then((res) => res.json())
        .then((data) => {
        this.setState({otpLoadingSymbol:"hide"});
        if(data !=null && data.status == "Success"){
            this.setState({otpVerifiedMessageStatus:"Verified",OTPVerifiedDisabledButton:true});
        }else{
            this.setState({otpVerifiedMessageStatus:"NotVerified",OTPVerifiedDisabledButton:false});
        }
        });
}
     
reloadCam = () => {
    const constraints = {
        audio: false,
        video: true,
      };
      
    this.setState({showImgCapture:true})
    //this.setState({videoConstraints:{facingMode: "environment"}});
    navigator.mediaDevices
    .getUserMedia(constraints)
    .then((mediaStream) => {
        const video = document.querySelector("video");
        video.srcObject = mediaStream;
        video.onloadedmetadata = () => {
        video.play();
        };
    })
    .catch((err) => {
        // always check for errors at the end.
        console.error(`${err.name}: ${err.message}`);
    });
      
}

reloadPageForHouseVisit = () => {
    this.setState({ voterVisitedImageCaptureModal: false })
}

    render() {
        return (
            <section className="mb-4">
               
                <Container className="mt-2">
                <Row>
                    <Col>
                        <Link to={{pathname: "/dashboard"}}> 
                            <Button variant="outline-dark pad5_14 ms-2 float-right" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <Col>
                     <h6 className='text-center fw-bold f-16'>Voter Slip Distribution Campaign</h6>
                    </Col>
                </Row>

                <Row>
                        <Col sm={12} >
                            <Form>
                                <div className='mt-2 text-center'>
                                <Form.Check
                                    inline
                                    label={`Visit By House No`}
                                    name="voter_search_card_test"
                                    type="radio"
                                    id={`family_option_id1`}
                                    onChange={(e)=>this.searchDigitalCardType(e,"family")}
                                    className="f-12 fw-bold color_black"
                                    defaultChecked={true}
                                    value="family"
                                />
                                <Form.Check
                                    inline
                                    label={`Visit By Voter`}
                                    name="voter_search_card_test"
                                    type="radio"
                                    id={`search_option_id1`}
                                    onChange={(e)=>this.searchDigitalCardType(e,"search")}
                                    className="f-12 fw-bold color_black"
                                    value="search"
                                />
                                </div>
                            </Form>
                                
                        </Col>
                    </Row>

                    {
                        this.state.chatID != "1048868711" ?
                        (
                            this.state.delaylocationModal == true && Object.keys(this.state.checkingLocationServiceEnabledObj).length == 0 ?
                            (
                                <EnabledLocationPopup 
                                    show={() => this.setState({ checkingEnabledLocationModal: true })}
                                    onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                                    closeEnabledModal={this.closeEnabledModal}
                                />
                             )
                            :
                            ("")
                        )
                        :
                        ("")
                    }

                    <Row>
                        <Col>
                            {
                                this.state.searchOptionTypeVal == "family"  ?
                                (
                                    <FamilyVoterSearch
                                        userAccessList = {this.state.userAccessList}
                                        //getlocationCoordinates = {this.state.checkingLocationServiceEnabledObj}
                                        campaignId={this.state.campaignId}
                                    />
                                )
                                :
                                (
                                    <>
                                        <Row>
                                            <Col className='mt-3'>
                                                <label className="form-label f-12 fw-bold">Constituency<span className='color_red f-14 ms-1'>*</span></label>
                                                <SelectBox
                                                        stateData={this.state.SearchconstituencyValue}
                                                        handleChange={this.handleOnchangeSelectBoxNew1}
                                                        name="volunteerSearchConstituencyName"
                                                        options={this.state.ap_listArr}
                                                        placeholderName={'Constituency'}
                                                        dropdownIndicator={{ DropdownIndicator }}
                                                    />
                                            </Col>
                                            <Col className='mt-3'>
                                                <label className="form-label f-12 fw-bold">Booths<span className='color_red f-14 ms-1'>*</span><span className='f-12 color_red'>{this.state.validateSearchByBoothNo}</span>
                                                    {
                                                        this.state.boothsLoadingSymbol == "show" ?
                                                        (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                        :
                                                        ("")
                                                    }
                                                    
                                                </label>
                                                <SelectBox
                                                        stateData={this.state.SearchBoothValue}
                                                        handleChange={this.handleOnchangeSelectBoxNew1}
                                                        name="volunteerSearchBoothName"
                                                        options={this.state.searchOptionBoothListArr}
                                                        placeholderName={'Booth'}
                                                        dropdownIndicator={{ DropdownIndicator }}
                                                    />
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col>
                                                <Form.Group className="mt-2" controlId="formNameId">
                                                    <Form.Label className='f-12 fw-bold'>Voter Name </Form.Label>
                                                    <Form.Control type="text" name="volunteerSearchName" className='form-control-sm'  placeholder="Enter Name" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                </Form.Group>    
                                            </Col>
                                            <Col>
                                                <Form.Group className="mt-2" controlId="formVoterSerachCardNoId">
                                                    <Form.Label className='f-12 fw-bold'>Voter Card No </Form.Label>
                                                    <Form.Control type="text" name="volunteerSearchVoterCardNo" className='form-control-sm'  placeholder="Enter Voter Card No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                </Form.Group>    
                                            </Col>
                                           
                                        </Row>

                                        <Row>
                                            <Col>
                                            <Form>
                                                <div className='text-center mt-3'>
                                                <Form.Check
                                                    inline
                                                    label={`All`}
                                                    name="enrolledName"
                                                    type="radio"
                                                    id={`allenrolledId`}
                                                    onChange={(e)=>this.checkEnrolledByVoterSearch(e,"")}
                                                    className="f-14 fw-bold color_black"
                                                    value=""
                                                />
                                                <Form.Check
                                                    inline
                                                    label={`Visited`}
                                                    name="enrolledName"
                                                    type="radio"
                                                    id={`enrolledId`}
                                                    onChange={(e)=>this.checkEnrolledByVoterSearch(e,"Y")}
                                                    className="f-14 fw-bold color_black"
                                                    value="Y"
                                                />
                                                <Form.Check
                                                    inline
                                                    label={`Not Visited`}
                                                    name="enrolledName"
                                                    type="radio"
                                                    id={`notEnrolledId`}
                                                    onChange={(e)=>this.checkEnrolledByVoterSearch(e,"N")}
                                                    className="f-14 fw-bold color_black"
                                                    value="N"
                                                    defaultChecked={true}
                                                />
                                                </div>
                                            </Form>   
                                            </Col>
                                                </Row>
                                    
                                        <Row>
                                            <Col>
                                                <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getVotersDisplay()}>GET VOTERS</Button> 
                                            </Col>
                                        </Row>
                                    
                                        {
                                            this.state.votersListLoader == "show" ?
                                            (
                                                <Row>
                                                    <Col sm={12}>
                                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ("")
                                        }
                                        {
                                            this.state.voterDisplayArr.length>0 ?
                                            (
                                                
                                                <>
                                                <Row>
                                                    <Col sm={12}>
                                                
                                                    <div className='bg_light_blue mt-3'>
                                                    <CardLessDataTableWithSearch 
                                                        parentData={this.votersDisplayData()}
                                                        particlecolumns={this.votersDisplayColumns()}
                                                        defaultSorted={[{
                                                            dataField: "Serial_No",
                                                            order: "asc"
                                                        }]}
                                                        tableHeading="Voters Details"
                                                        />
                                                        
                                                            
                                                    </div>
                                                    
                                                    </Col>
                                                </Row>
                                                </>
                                            )
                                            :
                                            (<>
                                                {
                                                this.state.votersDataCheck == 1 ?
                                                (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                                :
                                                ("")
                                            }
                                        </>)
                                        }
                                        
                                        </>
                                )
                            }
                        </Col>
                    </Row>
                    <QuestionsSavingPart
                        show={this.state.voterVisitedImageCaptureModal}
                        onHide={() => this.setState({ voterVisitedImageCaptureModal: false })}
                        stateData={this.state}
                        saveCampaignEnrollment={this.saveCampaignEnrollment}
                        handleOnchangeInputBox={this.handleOnchangeInputBox}
                        captureForHouseVisit={this.captureForHouseVisit}
                        removeCapturePhotoFromBSBG={this.removeCapturePhotoFromBSBG}
                        resendOTPNew={this.resendOTPNew}
                        getMobileNoOTP={this.getMobileNoOTP}
                        reloadCam={this.reloadCam}
                        reloadPageForHouseVisit={this.reloadPageForHouseVisit}
                        getOnchangeSelectedKPIAnswer={this.getOnchangeSelectedKPIAnswer}
                />

                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(SingleVoterSearch);
  