import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };


class AddInfluencer extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Create Influencer</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                      <Row>
                        <Col>
                        <Form.Group className="" controlId="formNameId">
                            <Form.Label className='f-12 fw-bold'>Name<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.props.stateData.validateInfluencerName}</span> </Form.Label>
                            <Form.Control type="text" name="influencerName" className='form-control-sm'  placeholder="Enter Name" value={this.props.stateData.influencerName} onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                        </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                        <h6 className="form-label f-14 fw-bold mt-2">Description </h6>
                            <FloatingLabel
                                    controlId="locationId"
                                    label="Enter Description"
                                    className="f-12"
                                    
                                >
                                    <Form.Control as="textarea" placeholder="Enter Description" name="description" value={this.props.stateData.description} className='height20px f-14' onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                                </FloatingLabel>
                                <span className='color_red f-9'>{this.props.stateData.validateDescription}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-2'>
                        <h6 className="form-label f-12 fw-bold">Mobile No <span className='color_red f-9'>{this.props.stateData.validateMobileNoIn}</span></h6>
                        <Form.Control type="text" name="mobileNoStr" value={this.props.stateData.mobileNoStr} maxLength={10}  className='form-control-sm'  placeholder="Enter Mobile No" onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    <Row>
                        <Col className='mt-2'>
                        <h6 className="form-label f-12 fw-bold">Influencing Votes <span className='color_red f-9'>{this.props.stateData.validateInfluencingVotes}</span></h6>
                        <Form.Control type="text" name="influencingVotes" value={this.props.stateData.influencingVotes}  className='form-control-sm'  placeholder="Enter Influencing Votes" onChange={(e) => this.props.phandleOnchangeInputBox(e)}/>
                        </Col>
                    </Row> 
                    <Row>
                        <Col>
                        <h6 className="form-label f-12 fw-bold mt-2">Party </h6>
                            <SelectBox
                                stateData={this.props.stateData.partyIdStrValue}
                                handleChange={this.props.phandleOnchangeSelectBoxNew}
                                name="partyIdStr"
                                options={this.props.stateData.party_ids_Arr}
                                placeholderName={'Select Party'}
                                dropdownIndicator={{ DropdownIndicator }}
                            /> 
                            <span className='color_red f-9'>{this.state.validatePartyIdStr}</span>
                        </Col>
                    </Row> 
                    <Row>
                        <Col>
                        <h6 className="form-label f-12 fw-bold mt-2">Influencing Type </h6>
                            <SelectBox
                                stateData={this.props.stateData.influencingTypeValue}
                                handleChange={this.props.phandleOnchangeSelectBoxNew}
                                name="influencingTypeId"
                                options={this.props.stateData.influencingTypeArr}
                                placeholderName={'Select Type'}
                                dropdownIndicator={{ DropdownIndicator }}
                            /> 
                             <span className='color_red f-9'>{this.state.validateInfluencingTypeId}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <h6 className="form-label f-12 fw-bold mt-2">Influencing Value </h6>
                            <SelectBox
                                stateData={this.props.stateData.influencingLevelValue}
                                handleChange={this.props.phandleOnchangeSelectBoxNew}
                                name="influencingLevelId"
                                options={this.props.stateData.influencingLevelArr}
                                placeholderName={'Select Level'}
                                dropdownIndicator={{ DropdownIndicator }}
                            /> 
                             <span className='color_red f-9'>{this.state.validateInfluencingLevelId}</span>
                        </Col>
                    </Row> 
                      
                      <Row>
                            <Col>
                                <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-3 mb-3' onClick={() => this.props.createInfluencer()}>SUBMIT</Button>
                                    {
                                        this.props.stateData.saveInfLoadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                    }
                            </Col>
                      </Row>
                      <Row>
                            <Col>
                                <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.successMsgInf}</h6>
                            </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default AddInfluencer;
  

