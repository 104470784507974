import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,InputGroup } from "react-bootstrap";
import loader from '../../images/loader.gif';
import Webcam from "react-webcam";

class HouseVisitedImageCapturePBNew extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          videoConstraints:{facingMode: "environment"},

          
          
        }
      
    }

  
   
switchCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "environment"}});
  }
  frontCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "user"}});
  }
  
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="houseVisitCaptureCls">
              <Modal.Header closeButton className='p-3'>
                <Modal.Title className='f-14 fw-bold'> Image Verification For House Visiting</Modal.Title>
              </Modal.Header>
                <Modal.Body className="">
                    <>
                    <Row>
                        <Col>
                            <Form.Label className='f-12 fw-bold'>Mobile No/ఫోన్ నెంబర్: </Form.Label>
                            <Form.Control type="text" name="captureMobileNo"  className='form-control-sm' maxLength={10} value={this.props.stateData.captureMobileNo}  placeholder="Enter Mobile No" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label className='f-12 fw-bold mt-2'>Photo Capture :<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.props.stateData.validateCapturePhoto}</span></Form.Label>
                            <Col className="mt-2">
                                <div className="showImgCaptureDivBSBG">{this.props.stateData.showImgCapture && (
                                <> 
                                <Webcam
                                    audio={false}
                                    ref={this.props.stateData.webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={this.state.videoConstraints}
                                /> 
                                
                                
                                <div className="row mt-2">
                                    <div className="col">
                                        <h6 onClick={() => this.props.captureForHouseVisit()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                    </div>
                                    <div className="col">
                                        {
                                        this.state.videoConstraints.facingMode == "user" ?
                                        (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                        :
                                        ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                        }
                                    </div>
                                </div>

                                {
                                    this.props.stateData.imageAddedMsg != "" ?
                                    (<h6 className="f-14 fw-bold text-center color_green">{this.props.stateData.imageAddedMsg}</h6>)
                                    :
                                    ("")
                                }
                                
                                {
                                    this.props.stateData.photoCaptureArr.length>0 ?
                                    (
                                    <>
                                        <Row>
                                        <Col sm={12} className="mt-2">
                                        <h6>Capture Live Photos : </h6>
                                            
                                        </Col>
                                        <Col sm={12} className="">
                                            <div className='flex_img'>
                                                {(this.props.stateData.photoCaptureArr || []).map((url,index) => (
                                                    <div className='ms-1 border_img_pad'>
                                                        <i class="fa-solid fa-circle-xmark" onClick={() => this.props.removeCapturePhotoFromBSBG(url.fileId)}></i>
                                                        <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                    </div>
                                                ))} 
                                            </div>
                                        </Col>
                                    </Row>
                                    </>
                                    
                                    
                                    )
                                    :
                                    ("")
                                }

                                </>
                                
                                )}
                                </div>
                            </Col>
                            </Row>
                            
               
                            <Row>
                                <Col sm={12}>
                                
                                    <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.props.stateData.submitButtonDisabled} onClick={() => this.props.saveCampaignEnrollment()}>SUBMIT </Button>
                                    
                                    {
                                        this.props.stateData.savaCampaignLoadingSymbol == "show" ? 
                                        (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                        :
                                        ("")
                                    }
                                    {
                                        this.props.stateData.savaCampaignLoadingSuccessMsg == "Visited SuccessFully..." ?
                                        (<h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.props.stateData.savaCampaignLoadingSuccessMsg}</h6>)
                                        :
                                        (
                                            <>
                                            <h6 className='text-center f-14 color_red mt-5 fw-bold ln-22'>{this.props.stateData.savaCampaignLoadingSuccessMsg}</h6>

                                           

                                            </>
                                        )
                                    }
                                    
                                    {
                                        this.props.stateData.savaCampaignLoadingSuccessMsg != "Visited SuccessFully..."  &&  this.props.stateData.savaCampaignLoadingSuccessMsg != "" ? 
                                        (
                                            <Row>
                                                <Col>
                                                    <Button variant="danger" className="red_button_event center-auto btn-sm f-14 mt-3" onClick={() => this.props.reloadPageForHouseVisit()}>RELOAD PAGE </Button>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                    }
                                </Col>
                            </Row>
                            </>
                  
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default HouseVisitedImageCapturePBNew;
  

