import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import "./NavBar.css"

class MenuItem extends Component {
    constructor(props) {
      super(props);
      this.state = {
        chatID: window.User !=undefined ? window.User.chatID : "5129193582",
        isSubMenuOpen: false,
      }; 
     
    }
   
    
    toggleSubMenu = () => {
        this.setState((prevState) => ({
          isSubMenuOpen: !prevState.isSubMenuOpen,
        }));
      };


      hideSubmenu = () => {
        this.setState({isSubMenuOpen:false});
      }
    
  
    render() {
       
      const { label, subMenuItems,icon,newBlock } = this.props;
      const { isSubMenuOpen } = this.state;
      
      return (
        <li>
          {
            subMenuItems.length == 0 ?
            (
              newBlock == "new" ?
              (<div><i className= {`${icon} me-2`}></i> {label} <span className="new_block_css">New</span> </div>)
              :
              (<div><i className= {`${icon} me-2`}></i> {label} </div>)
              
            )
            :
            (<div onClick={() => { this.toggleSubMenu();}} className="cursor_pointer"><i className= {`${icon} me-2`}></i> {label} 
                {
                    isSubMenuOpen == false ?
                    ( <i class="fa-solid fa-chevron-down float-end"></i>)
                    :
                    ( <i class="fa-solid fa-chevron-up float-end"></i>)
                }
               
            </div>)
          }
          
          {isSubMenuOpen && (
            <ul className="sub-menu-items list-inline mt-3">
              {subMenuItems.map((subMenuItem, index) => (
                <>
                    {
                        subMenuItem.nameType == "" ?
                        (
                            <Link to={{
                                pathname: subMenuItem.pathname,
                                params:{
                                    navbarDisplay:subMenuItem.navbarDisplay,
                                    userAccessList:subMenuItem.userAccessList,
                                    roleName:subMenuItem.roleName,
                                    accessName:subMenuItem.accessName,
                                    accessNameNavigation:subMenuItem.accessNameNavigation
                                }
                                }}>
                                <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name}</li>
                            </Link>
                        )
                        :
                        (
                            <>
                            {
                                subMenuItem.nameType == "attr_collection" ?
                                (
                                    <>
                                        {
                                            subMenuItem.attrVerfiDtsCheck.length == 0 ?
                                            (
                                                <Link to={{
                                                    pathname: subMenuItem.pathname,
                                                    params:{
                                                    userAccessList:subMenuItem.userAccessList,
                                                    attrVerfiDtsCheck:subMenuItem.attrVerfiDtsCheck,
                                                    }
                                                }}>
                                                <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name}</li>
                                                </Link>
                                            )
                                            :
                                            (
                                            ""
                                            )
                                        }
                                    </>
                                )
                                :
                                ("")
                            }
                            {
                                subMenuItem.nameType == "voters_verification" ?
                                (
                                    <>
                                         <Link to={{
                                                pathname: subMenuItem.pathname,
                                                params:{
                                                userAccessList:subMenuItem.userAccessList,
                                                attrVerfiDtsCheck:subMenuItem.attrVerfiDtsCheck,
                                                }
                                            }}>
                                            <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name}</li>
                                        </Link>
                                    </>
                                )
                                :
                                ("")
                            }

                            {
                                subMenuItem.nameType == "attr_verification" ?
                                (
                                    <>
                                        {
                                            subMenuItem.attrVerfiDtsCheck.length > 0 ?
                                            (
                                                <Link to={{
                                                    pathname: subMenuItem.pathname,
                                                    params:{
                                                    userAccessList:subMenuItem.userAccessList,
                                                    attrVerfiDtsCheck:subMenuItem.attrVerfiDtsCheck,
                                                    }
                                                }}>
                                                <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name}</li>
                                                </Link>
                                            )
                                            :
                                            (
                                            ""
                                            )
                                        }
                                    </>
                                )
                                :
                                (
                                  ""
                                )
                            }

                            {
                              subMenuItem.nameType == "bsbg"? 
                              (
                                <Link to={{
                                    pathname: subMenuItem.pathname,
                                    params:{
                                      navbarDisplay:subMenuItem.navbarDisplay,
                                      userAccessList:subMenuItem.userAccessList,
                                      roleName:subMenuItem.roleName,
                                      accessName:subMenuItem.accessName,
                                      accessNameNavigation:subMenuItem.accessNameNavigation,
                                      campaignId:3
                                    }
                                }}>
                                <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name}</li>
                                </Link>
                              )
                              :
                              (
                                "" 
                              ) 
                            }

                            {   
                              subMenuItem.nameType == "bsbgNew"? 
                              (
                                <Link to={{
                                    pathname: subMenuItem.pathname,
                                    params:{
                                      navbarDisplay:subMenuItem.navbarDisplay,
                                      userAccessList:subMenuItem.userAccessList,
                                      roleName:subMenuItem.roleName,
                                      accessName:subMenuItem.accessName,
                                      accessNameNavigation:subMenuItem.accessNameNavigation,
                                      campaignId:3
                                    }
                                }}>
                                <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name} <span className="new_block_css">New</span></li>
                                </Link>
                              )
                              :
                              (
                                "" 
                              ) 
                            }

                            {
                              subMenuItem.nameType == "publicOutReachNew" ? 
                              (
                                this.state.chatID == "5129193582" || this.state.chatID == "6899079589" || this.state.chatID == "943827154" ?
                                (
                                  <Link to={{
                                      pathname: subMenuItem.pathname,
                                      params:{
                                        navbarDisplay:subMenuItem.navbarDisplay,
                                        userAccessList:subMenuItem.userAccessList,
                                        roleName:subMenuItem.roleName,
                                        accessName:subMenuItem.accessName,
                                        accessNameNavigation:subMenuItem.accessNameNavigation,
                                        campaignId:2
                                      }
                                  }}>
                                  <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name} <span className="new_block_css">New</span></li>
                                  </Link>
                                )
                                :
                                ("")
                                
                              )
                              :
                              (
                                "" 
                              ) 
                            }

                            {
                              subMenuItem.nameType == "publicOutReach2"? 
                              (
                                <Link to={{
                                    pathname: subMenuItem.pathname,
                                    params:{
                                      navbarDisplay:subMenuItem.navbarDisplay,
                                      userAccessList:subMenuItem.userAccessList,
                                      roleName:subMenuItem.roleName,
                                      accessName:subMenuItem.accessName,
                                      accessNameNavigation:subMenuItem.accessNameNavigation,
                                      campaignId:2
                                    }
                                }}>
                                <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name} <span className="new_block_css">New</span></li>
                                </Link>
                              )
                              :
                              (
                                "" 
                              ) 
                            }

                            {
                              subMenuItem.nameType == "publicOutReach"? 
                              (
                                <Link to={{
                                    pathname: subMenuItem.pathname,
                                    params:{
                                      navbarDisplay:subMenuItem.navbarDisplay,
                                      userAccessList:subMenuItem.userAccessList,
                                      roleName:subMenuItem.roleName,
                                      accessName:subMenuItem.accessName,
                                      accessNameNavigation:subMenuItem.accessNameNavigation,
                                    }
                                }}>
                                <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name}</li>
                                </Link>
                              )
                              :
                              (
                                "" 
                              ) 
                            }

                            {
                              subMenuItem.nameType == "verify_house"? 
                              (
                                subMenuItem.roleId != 19 ?
                                (
                                  <Link to={{
                                    pathname: subMenuItem.pathname,
                                    params:{
                                        navbarDisplay:subMenuItem.navbarDisplay,
                                        userAccessList:subMenuItem.userAccessList,
                                        roleName:subMenuItem.roleName,
                                        accessName:subMenuItem.accessName,
                                        accessNameNavigation:subMenuItem.accessNameNavigation
                                    }
                                    }}>
                                    <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name}</li>
                                </Link>
                                )
                                :
                                ("")
                              )
                              :
                              (
                                "" 
                              ) 
                            }

                            {
                              this.state.chatID == "5129193582" || this.state.chatID == "943827154" || this.state.chatID == "6899079589" || this.state.chatID == "1048868711" 
                              ?
                              (
                                <>
                                  {
                                    subMenuItem.nameType == "booth_location_map"? 
                                    (
                                      <Link to={{
                                          pathname: subMenuItem.pathname,
                                          params:{
                                            navbarDisplay:subMenuItem.navbarDisplay,
                                            userAccessList:subMenuItem.userAccessList,
                                            roleName:subMenuItem.roleName,
                                            accessName:subMenuItem.accessName,
                                            accessNameNavigation:subMenuItem.accessNameNavigation,
                                          }
                                      }}>
                                      <li key={index} onClick={() => {this.props.showSidebar();this.hideSubmenu()}}><i className= {`${subMenuItem.icon} me-2`}></i> {subMenuItem.name} <span className="new_block_css">New</span></li>
                                      </Link>
                                    )
                                    :
                                    (
                                      "" 
                                    ) 
                                  }
                                </>
                              )
                              :
                              ("")
                            }

                            
                             
                            </>
                        )

                    }
                     

                   
                    
                </>
              ))}
            </ul>
          )}
        </li>
      );
    }
  }

  export default withRouter(MenuItem);