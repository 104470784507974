import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,OverlayTrigger,Tooltip } from "react-bootstrap";
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import { withRouter,Link } from "react-router-dom";
import Webcam from "react-webcam";

class UpdateBoothLocation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            videoConstraints:{facingMode: "environment"},
        }
      
    }

    switchCamaraForHouseVisit = () => {
        this.setState({videoConstraints:{facingMode: "environment"}});
      }
      frontCamaraForHouseVisit = () => {
        this.setState({videoConstraints:{facingMode: "user"}});
      }

  
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Booth - {this.props.stateData.selectdBoothIdObj.Booth} Update:</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Row>
                        <Col>
                        <div className='bg_light_blue mt-2'>
                                  <h6 className='f-14'><b>Location Update : <span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.props.stateData.validateBoothLocations}</span></b> 
                                        <OverlayTrigger key={"top"} placement={"top"}
                                          overlay={
                                            <Tooltip id={`tooltio-link-house-locationi`}>
                                              <h6 className='f-14'>Location Updated For Booth </h6>
                                            </Tooltip>
                                          }
                                        >
                                        <span className='bg_white_icon cursor_pointer ml-4' onClick={() => this.props.getCurrentBoothLocation()} >
                                            {
                                                Object.keys(this.props.stateData.getCurrentLocationCoordinatesObj).length == 0 ? 
                                                (<i className="fa-solid fa-location-crosshairs color_red"></i>)
                                                :
                                                (<i className="fa-solid fa-location-crosshairs color_green"></i>)
                                            }
                                            
                                        </span>
                                        </OverlayTrigger>
                                        {
                                            Object.keys(this.props.stateData.getCurrentLocationCoordinatesObj).length > 0 ?
                                            (<h6 className='f-12 color_green mt-3'>Location Updated SuccessFully</h6>)
                                            :
                                            ("")
                                        }
                                        {
                                            this.props.stateData.useCurrentBoothLocationSymbol == "show" ?
                                            (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                            :
                                            ("") 
                                        }
                                  </h6>
                              </div>
                        </Col>
                    </Row>

                    <Row>
                    <Col>
                        <fieldset class="mt-4"><legend className="f-14">లైవ్ ఫోటో తీయటం లేదా ఫోటో అప్లోడ్ చేయండి <span className='color_red f-14 ms-1'>*</span> </legend>
                            <Row>
                                <Col sm={12}>
                                    <Form className="">
                                            <Form.Check
                                                inline
                                                label={"లైవ్ ఫోటో తీయటం"}
                                                name="image_capture"
                                                type="radio"
                                                id="image_capture_upload"
                                                onChange={()=>this.props.selectCaptureTypeForBoothLoc("camara")}
                                                className="f-12 fw-bold"
                                                checked={this.props.stateData.camaraRadioButtonForBoothLoc}
                                            />
                                            <Form.Check
                                                inline
                                                label="ఫోటో అప్లోడ్ చేయండి"
                                                name="image_capture_upload"
                                                type="radio"
                                                id="upload_photos"
                                                onChange={()=>this.props.selectCaptureTypeForBoothLoc("upload")}
                                                className="f-12 fw-bold"
                                                checked={this.props.stateData.uploadImageRadioButtonForBoothLoc}
                                            />
                                    </Form>
                                </Col>
                            </Row>
        
                            {
                                this.props.stateData.selectedTypePhotoForBoothLoc == "camara" ?
                                (
                                    <Row>
                                        <Col className="mt-2">
                                            <h6 className='f-12 color_red mt-2'>{this.props.stateData.validatePhotoCaptureForBoothLoc}</h6>
                                                <div className="showImgCaptureDivBSBG">{this.props.stateData.showBoothLocImgCapture && (
                                                <> 
                                                <Webcam
                                                    audio={false}
                                                    ref={this.props.stateData.webcamRef}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={this.state.videoConstraints}
                                                /> 
                                                
                                                
                                                <div className="row mt-2">
                                                    <div className="col">
                                                        <h6 onClick={() => this.props.captureForBoothLocImageUpload()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                                    </div>
                                                    <div className="col">
                                                        {
                                                        this.state.videoConstraints.facingMode == "user" ?
                                                        (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                                        :
                                                        ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                                        }
                                                    </div>
                                                </div>

                                                
                                                </>
                                                
                                                )}
                                                </div>
                                            </Col>
                                        </Row>
                                )
                                :
                                (
                                    <Row>
                                        <Col className="mt-2">
                                        <div className="form-group">
                                            <h6 className='f-12 color_red'></h6>
                                            <label for="uploadfilesForLive" class="drop-container">
                                                <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                                <input type="file" id="uploadfilesForLive" className='f-14'  multiple onChange={this.props.uploadMultipleFilesForBoothLocPhotos}/>
                                            </label>
                                        </div>
                                        <h6 className='f-12 color_red mt-2'>{this.props.stateData.manualUploadImageForBoothLoc}</h6>
                                        </Col>
                                    </Row>
                                )
                            }
        
                            {
                                this.props.stateData.photoCaptureForBoothLocArr.length>0 ?
                                (
                                <>
                                    <Row>
                                    <Col sm={12} className="mt-2">
                                        {
                                            this.props.stateData.selectedTypePhotoForBoothLoc == "camara" ? 
                                            (<h6>Capture Photos : </h6>)
                                            :
                                            (<h6>Upload Images: </h6>)
                                        }
                                        
                                    </Col>
                                    <Col sm={12} className="">
                                        <div className='flex_img'>
                                            {(this.props.stateData.photoCaptureForBoothLocArr || []).map((url,index) => (
                                                <div className='ms-1 border_img_pad'>
                                                    <i class="fa-solid fa-circle-xmark" onClick={() => this.props.removeCapturePhotoFromBoothLoc(url.fileId)}></i>
                                                    <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                </div>
                                            ))} 
                                        </div>
                                    </Col>
                                </Row>
                                </>
                                
                                
                                )
                                :
                                ("")
                            }

                            {
                                this.props.stateData.validateBoothLocations !="" ?
                                (
                                    <Row>
                                        <Col>
                                            <h6 className='f-12 mt-2 color_red'>{this.props.stateData.validateBoothLocations}</h6>
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                            }
                            

                            <Row>
                                <Col sm={12}>
                                
                                    <Button variant="danger" className="red_button_event center-auto mt-3" disabled={this.props.stateData.submitButtonDisabledForBoothLoc} onClick={() => this.props.saveBoothLocationDetails()}>SUBMIT </Button>
                                    
                                    {
                                        this.props.stateData.saveBoothLocationDetailsLoadingSymbol == "show" ? 
                                        (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                        :
                                        ("")
                                    }
                                    <h6 className='text-center f-14 color_green mt-5 fw-bold'>{this.props.stateData.saveBoothLocationMessage}</h6>
                                </Col>
                            </Row>
                           
                            
                            </fieldset>
                    </Col>
                </Row>

                </Modal.Body>
            </Modal>
            
        )
    }
}
export default withRouter(UpdateBoothLocation);
  

