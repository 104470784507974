
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,InputGroup  } from 'react-bootstrap';
import "./VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import MF_logo1 from '../../images/MF_logo1.png';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import HouseWiseVoterList from "./HouseWiseVoterList";
import MaxLimitEnrollChecking from './MaxLimitEnrollChecking';
import EnabledLocationPopup from './EnabledLocationPopup';
import HouseVisitedImageCapture from './HouseVisitedImageCapture';


const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

  function haversine(lat1, lon1, lat2, lon2) {
    // Convert latitude and longitude from degrees to radians
    const degToRad = (angle) => angle * (Math.PI / 180);
    const R = 6371000; // Radius of Earth in meters

    const dLat = degToRad(lat2 - lat1);
    const dLon = degToRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in meters

    return distance;
  }

class FamilyVoterSearch extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props.userAccessList,

            constituencyValue:"",
            constituencyId:"",
            constituencyListArr:[],

            boothValue:"",
            boothListArr:[],
            boothId:"",
            validateboothId:"",
            getBoothsLoadingSymbol:"hide",

            boothWiseHouseListForFVArr:[],
            boothWiseHouseListCheckForFV:0,
            boothWiseHouseListLoaderForFV:"hide",

            houseWiseVotersDisplayModal:false,

            houseWiseVoterListForFVArr:[],
            houseWiseVoterListArrForFVLoader:"hide",
            houseWiseVoterListArrForFVCheck:0,

            houseNoValue:"",

            checkingEnrollsSymbol:"hide",
            checkingEnrollMessage:"",
            
            maxEnrollModal:false,

            enrollCheckVal:"N",

            voterCheckedCond:true,
            locationCheckedCond:false,
            distanceValue:"1000",
            fetchHouseValue:"0",
            validateLocations:"",
            userLatitude:14.4781698,
            userLongitude:79.9914578,
            tableHeadingName:"Booth Wise Nearest All House In 1000 Meters",
            houseLocationArr:[],
            houseLocationDataCheck:0,
            houseLocationLoading:"hide",
            getCurrentLocationCoordinatesObj:{},
            houseVisitedImageCaptureModal:false,
            showImgCapture:true,
            videoConstraints:{facingMode: "environment"},
            visitedSuccessMsg:"",
            selectedHouseVisitVoterId:""
            
        }
    }

    componentDidMount() {
        console.log("userAccessListdddd",this.state.chatID)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
                
                
            }
           
            this.setState({ constituencyListArr:consArr });
            this.setState({
                constituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },constituencyId:this.state.userAccessList.constituencyList[0].id
        });
           // this.getBoothsByConstituencyId(this.state.userAccessList.constituencyList[0].id);
            if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
                let arr1=[];
                this.state.userAccessList.boothList.map(item => {
                    const obj = {
                        value: item.id,
                        label: "Booth No -"+item.name,
                    }
                    arr1.push(obj);
                    
                });
                this.setState({ boothListArr: arr1 });
                
                
            }
    
        }
    }

   

    handleOnchangeSelectBoxForFamily = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "constituencyId"){
            let getConsIdName = this.state.constituencyListArr.find((a) => a.value == value);
                this.setState({
                    constituencyValue: {
                    value: getConsIdName.value,
                    label: getConsIdName.label
                }
            });
            //this.getBoothsByConstituencyId(value);
        }else if(name == "boothId"){
            let getBoothIdName = this.state.boothListArr.find((a) => a.value == value);
            this.setState({
                boothValue: {
                value: getBoothIdName.value,
                label: getBoothIdName.label
            }
        });
           
        }
          
    
        this.setState({ [name]: value});
      }

      
      getBoothsByConstituencyId = (value) => {
    this.setState({ boothListArr: [],getBoothsLoadingSymbol:"show"});

    fetch(`/bot_api/v1/booth?constituency_id=${value}`, {
        method: "GET", 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'user_data':'user_data',
            "test_chat_id" :"5129193582",
        },
      })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ getBoothsLoadingSymbol:"hide"});
          if(json !=null && json.length>0){
            var boothArr=[];
            for(var i in  json){
                var obj={'value':json[i].boothId,'label':"Booth"+json[i].partNo};
                boothArr.push(obj)
                
            }
           
            this.setState({ boothListArr:boothArr });
          }
        
      });
  }

  
  getHouseWiseVoters = () => {
    var houselistArr=[];
    this.setState({validateboothId:""});
    if(this.state.boothId == ""){
        this.setState({validateboothId:"Please Select Booth" });
        return;
    }

    this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:0,boothWiseHouseListLoaderForFV:"show"});
    

    const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
                "boothId" : this.state.boothId,
                "isVisited" : '' // Y/N/null
            });
        
        

        getAPICallData(obj,"FTRGRT/getHouseDetailsInABooth")
            .then((res) => res.json())
            .then((content) => {
              this.setState({boothWiseHouseListLoaderForFV:"hide"});  
                if(content !=null && content.length>0){
                    if(this.state.enrollCheckVal == ""){
                        houselistArr = content;
                    }else{
                        houselistArr = content.filter((item) => item.isEnrolled == this.state.enrollCheckVal );
                    }
                    if(houselistArr !=null && houselistArr.length>0){
                        this.setState({boothWiseHouseListForFVArr:houselistArr,boothWiseHouseListCheckForFV:0});
                    }else{
                        this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:1});
                    }

                }else{
                    this.setState({boothWiseHouseListForFVArr:[],boothWiseHouseListCheckForFV:1});
                }
            });
}


houseListDisplayColumnsForFV() {
    let columns = [
        
        {
            dataField: "house_no",
            text: "House No",
            sort: true,
            formatter: this.actionsFormatterForECHouseNoForFV.bind(this),
            headerStyle: {
                width: "15%"
            }
            
            
        },
        {
            dataField: "elder_person",
            text: "Elder Person",
           // sort: true,
            formatter: this.actionsFormatterForECElderPersonForFV.bind(this),
            headerStyle: {
                width: "28%"
            }
            
            
        },
        {
            dataField: "elder_age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECElderAgeForFV.bind(this),
            
        },
       
        {
            dataField: "younger_person",
            text: "Younger Person",
            formatter: this.actionsFormatterForECYoungerPersopnForFV.bind(this),
            headerStyle: {
                width: "28%"
            }
           
            
        },
        {
            dataField: "younger_age",
            text: "Age",
            sort: true,
            formatter: this.actionsFormatterForECYoungerAgeForFV.bind(this),
        }
    
    ];
    return columns;
}

actionsFormatterForECElderPersonForFV = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.elder_person)} 
            {/*<><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.elder_age)}</span></>*/}
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.elder_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.elder_age)}</span></>
        </span>
     );
    
}

actionsFormatterForECYoungerPersopnForFV = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.younger_person)} 
           {/* <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.younger_age)}</span></>*/}
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.younger_gender)}</span></>
            <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.younger_age)}</span></>
        </span>
     );
    
} 

actionsFormatterForECElderAgeForFV = (cell, row, rowIndex, formatExtraData) => {
    return (row.elderVoterCardNo)
}
actionsFormatterForECYoungerAgeForFV = (cell, row, rowIndex, formatExtraData) => {
    return (row.youngerVoterCardNo)
}
actionsFormatterForECHouseNoForFV = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            {
                row.isEnrolled == 'Y' ?
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold color_green text-decoration-underline' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no} <br/> Visited</span>)
                :
                (<span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold  text-decoration-underline' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no}</span>)
        }
            
        </>
          
     );
}

houseListDisplayDataForFV = () =>{
    let houseData = [];
        if(this.state.boothWiseHouseListForFVArr.length>0){
            
            for (let i = 0; i < this.state.boothWiseHouseListForFVArr.length; i++) {

                const house_no = (this.state.boothWiseHouseListForFVArr[i].houseNo);
                const elder_person = (this.state.boothWiseHouseListForFVArr[i].elderName);
                const younger_person = (this.state.boothWiseHouseListForFVArr[i].youngerName);
                const elder_age = (this.state.boothWiseHouseListForFVArr[i].elderAge);
                const elder_gender = (this.state.boothWiseHouseListForFVArr[i].elderGender);
                const younger_age = (this.state.boothWiseHouseListForFVArr[i].youngerAge);
                const younger_gender = (this.state.boothWiseHouseListForFVArr[i].youngerGender);
                const house_id = (this.state.boothWiseHouseListForFVArr[i].boothHouseId);
                const booth_house_id = (this.state.boothWiseHouseListForFVArr[i].boothHouseId);
                const elderVoterCardNo = (this.state.boothWiseHouseListForFVArr[i].elderVoterCardNo);
                const youngerVoterCardNo = (this.state.boothWiseHouseListForFVArr[i].youngerVoterCardNo);
                const isEnrolled = (this.state.boothWiseHouseListForFVArr[i].isEnrolled);
                
                const obj = {
                    house_no:(house_no),
                    elder_person:elder_person,
                    younger_person: younger_person,
                    elder_age: elderVoterCardNo,
                    elder_gender: elder_gender,
                    younger_age:youngerVoterCardNo,
                    younger_gender:younger_gender,
                    house_id:house_id,
                    booth_house_id:booth_house_id,
                    elderVoterCardNo:elder_age,
                    youngerVoterCardNo:younger_age,
                    isEnrolled:isEnrolled
                    
                }
                houseData.push(obj);
            } 

            
            
            return houseData;
        }
}


closeLimitModal = () => {
    this.setState({maxEnrollModal:false});
}

getEnrollmentsCountByUserByDate = (data) => {
       
    this.setState({checkingEnrollsSymbol:"show",checkingEnrollMessage:""});
    const obj =  
            JSON.stringify({ 
                "chatId" :this.state.chatID,
            });

        getAPICallData(obj,"FTRGRT/getEnrollmentsCountByUserByDate")
            .then((res) => res.json())
            .then((content) => {
                this.setState({checkingEnrollsSymbol:"hide"});
                if(content !=null && content.id > 40){
                    this.setState({maxEnrollModal:true});
                    this.setState({checkingEnrollMessage:"Sorry !! Everyday the max enrollment limit is 40, you reached that limit today.Please continue your enrollments tomorrow."
                    });
                }else{
                    this.setState({checkingEnrollMessage:""});
                    this.enrollUserViewDtClickForFV(data);
                }
            });
}


getVotersInAHouseForFV = (houseId,house_no) => {
    var houseWiseDataArr=[];
    this.setState({checkingEnrollMessage:""});
    this.setState({houseWiseVotersDisplayModal:true,houseNoValue:house_no});
    this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVLoader:"show",houseWiseVoterListArrForFVCheck:0});
    const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
            "boothHouseId" : houseId,
        });
        getAPICallData(obj,"FTRGRT/getVotersInAHouse")
            .then((res) => res.json())
            .then((content) => {
                this.setState({houseWiseVoterListArrForFVLoader:"hide"});
                if(content !=null && content.length>0){
                   /* console.log(this.state.enrollCheckVal)
                    if(this.state.enrollCheckVal == ""){
                        houseWiseDataArr = content;
                    }else{
                        houseWiseDataArr = content.filter((item) => item.isEnrolled == this.state.enrollCheckVal );
                    }
                    if(houseWiseDataArr !=null && houseWiseDataArr.length>0){
                        this.setState({houseWiseVoterListForFVArr:houseWiseDataArr,houseWiseVoterListArrForFVCheck:0});
                    }else{
                        this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVCheck:1});
                    }*/
                    this.setState({houseWiseVoterListForFVArr:content,houseWiseVoterListArrForFVCheck:0});

                }else{
                    this.setState({houseWiseVoterListForFVArr:[],houseWiseVoterListArrForFVCheck:1});
                }
        });
}
    
enrollUserViewDtClickForFV = (data) => {
   // setTimeout(() => {   
        this.props.history.push({
            pathname: "/voter_search_enroll_save",
            params: {
                navbarDisplay:"hide",
                userAccessList:this.props?.userAccessList == undefined ? "" : this.props?.userAccessList,
                volunteerDts:"",
                volunteerName:data[0].voterName,
                volunteerGender:data[0].gender,
                volunteerAge:data[0].age, 
                volunteerVoterCard:data[0].voterCardNo,
                ap_listArr:[{'value':this.state.constituencyValue.value,'label':this.state.constituencyValue.label}],
                volunteerConstituencyName:this.state.constituencyValue.value,
                SelfconstituencyValue:{'value':this.state.constituencyValue.value,'label':this.state.constituencyValue.label},
                save_booth_id:this.state.boothId,
                save_voter_id:data[0].voterId
              },
          });
      // }, 1000);
    
}



checkEnrolled = (e,type) => {
    this.setState({enrollCheckVal:type});
}

handleOnchangeInputBox = (e) => {
    console.log("handleInputChange", e.target.name, e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    
  }

  handleOnchangeSelectBox = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
}

checksearchParameterByValue = (e,type) => {
    if(type == "voter"){
        this.setState({voterCheckedCond:true,locationCheckedCond:false})
    }else if(type == "location"){
        this.setState({voterCheckedCond:false,locationCheckedCond:true})
    }
}

getHousesLocations = () => {
    this.setState({validateboothId:"",validateLocations:""});
    if(this.state.boothId == ""){
        this.setState({validateboothId:"Please Select Booth" });
        return;
    }
    /*if((this.state.userLatitude == "" || this.state.userLatitude == 0) || (this.state.userLongitude == "" || this.state.userLongitude == 0)){
        this.setState({validateLocations:"Please Select Locations" });
        return;
    }*/
    
    if(this.state.fetchHouseValue == 0){
        this.setState({tableHeadingName:'Booth Wise Nearest All House In '+this.state.distanceValue+' Meters'})
    }else{
        this.setState({tableHeadingName:'Booth Wise Nearest '+this.state.fetchHouseValue+' House In '+this.state.distanceValue+' Meters'})
    }
    
    this.getMappedHousesLocationInABoothData();
}

getMappedHousesLocationInABoothData() {
    this.setState({houseLocationArr:[],houseLocationDataCheck:0,houseLocationLoading:"show"});
    const mapObj =  
          JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID,
          "boothId" : this.state.boothId
          }
      ); 

          getAPICallData(mapObj,"getMappedHousesLocationInABooth")
          .then((res) => res.json())
          .then((data) => {
            this.setState({houseLocationLoading:"hide"});
            if(data !=null && data.length>0){
                this.setState({houseLocationArr:data,houseLocationDataCheck:0});
            }else{
                this.setState({houseLocationArr:[],houseLocationDataCheck:1});
            }
          
          });
    
  }

  sortFuncDistance(a, b, order, dataField, rowA, rowB)  { 
    if (order == 'desc') {
        
        return parseInt(rowB.Distance)  > parseInt(rowA.Distance) ? 1 : -1;

    } else {
        return parseInt(rowA.Distance)  > parseInt(rowB.Distance) ? 1 : -1;
    }
}

  boothNearestLocationsDataColumns() {

    let columns = [
        {
            dataField: "House_No",
            text: "House No",
            sort: true,
            formatter: this.actionsFormatterForHouseByVoters.bind(this),
            
            
        },
        {
            dataField: "Location",
            text: "Street",
            
        },
        {
            dataField: "Distance",
            text: "Distance (mts)",
            sort: true,
            sortFunc: this.sortFuncDistance.bind(this)
            
        },
        {
            dataField: "Coordinates",
            text: "Coordinates",
            formatter: this.actionsFormatterForCoordinates.bind(this),
            headerStyle: {
                width: "36%"
            }
            
        }
    
    ];
    return columns;
}

actionsFormatterForHouseByVoters = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            {
               <span style={{textAlign:"left",marginLeft:"0px"}} className='cursor_pointer fw-bold text-decoration-underline' onClick={() => this.getVotersInAHouseForFV(row.house_id,row.house_no)}>{row.house_no}</span>
            }
            
        </>
          
     );
}

actionsFormatterForCoordinates = (cell, row, rowIndex, formatExtraData) => {
   
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}>lat : {(row.latitude)} 
            <><br/><span className='f-10' style={{textAlign:"left",marginLeft:"0px"}}>long : {(row.longitude)}</span></>
            <><br/><a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${row.latitude},${row.longitude}`}><Button variant="outline-danger" className='f-10  mt-2 pad_3'> <i class="fa-solid fa-map-location-dot"></i> Get Direction</Button></a> </>
            
        </span>
     );
    
}


boothNearestLocationsData = () => {
    let locationsData = [];
    let noOfHouseDisplayData=[];
    const currentLatitude =  this.state.userLatitude;
    const currentLongitude  = this.state.userLongitude;
    const distanceValue = parseInt(this.state.distanceValue);
    const noOfHouseDisplay = parseInt(this.state.fetchHouseValue);

    if(this.state.houseLocationArr.length>0){

        this.state.houseLocationArr.forEach( (data) => {
                if((this.state.userLatitude == "" || this.state.userLatitude == 0) || (this.state.userLongitude == "" || this.state.userLongitude == 0)){
                    const obj = {
                        House_No:(data.houseNo),
                        Location: data.street,
                        Distance: 0,
                        latitude:data.latitude,
                        longitude:data.longitude,
                        house_no : data.houseNo,
                        house_id : data.boothHouseId
                    
                    }
                    
                    if(noOfHouseDisplay == 0){
                        locationsData.push(obj);
                    }else{
                        noOfHouseDisplayData.push(obj);
                        locationsData = noOfHouseDisplayData.slice(0, noOfHouseDisplay)
                    }
            }else{
                const distance = haversine(currentLatitude,currentLongitude,data.latitude,data.longitude);
                    if(distance !=0 && distance <= distanceValue){
                        const obj = {
                            House_No:(data.houseNo),
                            Location: data.street,
                            Distance: distance.toFixed(2),
                            latitude:data.latitude,
                            longitude:data.longitude,
                            house_no : data.houseNo,
                            house_id : data.boothHouseId
                        
                        }
                        
                        if(noOfHouseDisplay == 0){
                            locationsData.push(obj);
                        }else{
                            noOfHouseDisplayData.push(obj);
                            locationsData = noOfHouseDisplayData.slice(0, noOfHouseDisplay)
                        }
                    }
            }
            
        });


        if(locationsData.length == 0){
            this.setState({houseLocationArr:[],houseLocationDataCheck:1});
        }

        return locationsData;
    }
}

getCurrentLocation = () => {
    if(this.state.chatID != "1048868711"){
        if(navigator.geolocation){
            this.setState({useCurrentLocationSymbol:"show"})
            navigator.geolocation.getCurrentPosition(this.getCurrentLocationCoordinates,this.checkingLocationServiceEnabledError)
        }else{
          /* <EnabledLocationPopup 
                show={() => this.setState({ checkingEnabledLocationModal: true })}
                onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                closeEnabledModal={this.closeEnabledModal}
            />*/
        }
        setTimeout(() => this.setState({delaylocationModal:true}), 2000);
    }
   
}

getCurrentLocationCoordinates = (position) => {
    const getCoordinates =  
        { 
        "latitude" : position.coords.latitude,
        "longitude" : position.coords.longitude,
        "accuracy":position.coords.accuracy
        }
        
    this.setState({getCurrentLocationCoordinatesObj:getCoordinates});
    this.setState({userLatitude:position.coords.latitude,userLongitude:position.coords.longitude});
    this.setState({useCurrentLocationSymbol:"hide"})
    
}

checkingLocationServiceEnabledError = (error) => {
    return(
      <EnabledLocationPopup 
              show={() => this.setState({ checkingEnabledLocationModal: true })}
              onHide={() => this.setState({ checkingEnabledLocationModal: false })}
              closeEnabledModal={this.closeEnabledModal}
       />
    )
      
  }

  houseVisitCaptureClick = (data) => {
    console.log(data)
    this.setState({houseVisitedImageCaptureModal:true,selectedHouseVisitVoterId:data[0].voterId})
}

  captureForHouseVisit = () => {
    this.setState({showImgCapture:true,visitedSuccessMsg:""});
      this.setState({visitedSuccessMsg:"Visited House SuccessFully..."});
      setTimeout(() => {
        this.setState({visitedSuccessMsg:"",houseVisitedImageCaptureModal:false});
        this.change_status_for_house_capture(this.state.houseWiseVoterListForFVArr) 
    }, 2000);
};

switchCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "environment"}});
  }
  frontCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "user"}});
  }

  change_status_for_house_capture = (_data) => {
    
    for(var key in _data){
        if(_data[key].voterId == this.state.selectedHouseVisitVoterId){
            _data[key].isEnrolled = 'Y'
        }
    }
    this.setState({houseWiseVoterListForFVArr:_data});

    
}

    render() {
        console.log("houseWiseVoterListForFVArr",this.state.houseWiseVoterListForFVArr)
        return (
               <Container>
                    <Row>
                        <Col className='mt-3'>
                        <label className="form-label f-12 fw-bold">Constituency<span className='color_red f-14 ms-1'>*</span></label>
                        <SelectBox
                                stateData={this.state.constituencyValue}
                                handleChange={this.handleOnchangeSelectBoxForFamily}
                                name="constituencyId"
                                options={this.state.constituencyListArr}
                                placeholderName={'Constituency'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                    <Col className='mt-3'>
                        <label className="form-label f-12 fw-bold">Booths<span className='color_red f-14 ms-1'>*</span><span className='f-12 color_red'>{this.state.validateboothId}</span>
                            {
                                this.state.getBoothsLoadingSymbol == "show" ?
                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                :
                                ("")
                            }
                            
                        </label>
                        <SelectBox
                                stateData={this.state.boothValue}
                                handleChange={this.handleOnchangeSelectBoxForFamily}
                                name="boothId"
                                options={this.state.boothListArr}
                                placeholderName={'Booth'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h6 className='mt-3'>
                            <span className="form-label f-14 fw-bold ml-0">
                                Search By : 
                            </span>
                            <span className='me-4'>
                                <Form.Check
                                    inline
                                    label={"Voter"}
                                    name="searchParameterByVoters"
                                    type="radio"
                                    id="VoterSearchId"
                                    onChange={(e)=>this.checksearchParameterByValue(e,'voter')}
                                    className="color_black f-14 pos_name_align fw-bold"
                                    checked={this.state.voterCheckedCond}
                                />
                                <Form.Check
                                    inline
                                    label={"Location"}
                                    name="searchParameterByVoters"
                                    type="radio"
                                    id="locationSearchId"
                                    onChange={(e)=>this.checksearchParameterByValue(e,'location')}
                                    className="color_black f-14 pos_name_align fw-bold"
                                    checked={this.state.locationCheckedCond}
                                />
                            </span>
                        </h6>
                    </Col>
                </Row>

                    {
                        this.state.voterCheckedCond == true ?
                        (
                            <Row>
                                <Col>
                                <Form>
                                    <div className='text-center'>
                                    <Form.Check
                                        inline
                                        label={`All`}
                                        name="enrolledName"
                                        type="radio"
                                        id={`allenrolledId`}
                                        onChange={(e)=>this.checkEnrolled(e,"")}
                                        className="f-14 fw-bold color_black"
                                        value=""
                                    />
                                    <Form.Check
                                        inline
                                        label={`Enrolled`}
                                        name="enrolledName"
                                        type="radio"
                                        id={`enrolledId`}
                                        onChange={(e)=>this.checkEnrolled(e,"Y")}
                                        className="f-14 fw-bold color_black"
                                        value="Y"
                                    />
                                    <Form.Check
                                        inline
                                        label={`Not Enrolled`}
                                        name="enrolledName"
                                        type="radio"
                                        id={`notEnrolledId`}
                                        onChange={(e)=>this.checkEnrolled(e,"N")}
                                        className="f-14 fw-bold color_black"
                                        value="N"
                                        defaultChecked={true}
                                    />
                                    </div>
                                </Form>   
                                </Col>
                            </Row>
                        )
                        :
                        (
                            this.state.locationCheckedCond == true ? 
                            (
                                <>
                                <fieldset class="mt-2"><legend>Location Filters</legend>
                                <Row>
                                    <Col className='w_60'>
                                        <InputGroup className="">
                                            <InputGroup.Text id="basic-addon1" className='f-12 pad_5' style={{textAlign:"left",marginLeft:"0px"}}>Distance</InputGroup.Text>
                                            <Form.Control type="text" name="distanceValue" className='form-control-sm' value={this.state.distanceValue}  placeholder="Distance" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                        </InputGroup>
                                    </Col>
                                    <Col className='w_40'>
                                        <InputGroup className="">
                                            <InputGroup.Text id="basic-addon2" className='f-12 pad_5' style={{textAlign:"left",marginLeft:"0px"}}>Houses</InputGroup.Text>
                                            <select id="fetchHouseValueId" name="fetchHouseValue" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                                        <option value="">Select Count</option>
                                                        <option value="0" selected>All</option>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                        <option value="40">40</option>
                                                        <option value="50">50</option>
                                                    </select>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col>
                                        <Form.Group className="mt-2" >
                                            <Form.Label className='f-12 fw-bold'>Locations :<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateLocations}</span> </Form.Label>
                                            <Row>
                                                <Col>
                                                    <InputGroup className="">
                                                        <InputGroup.Text id="basic-addon3" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>lat</InputGroup.Text>
                                                        <Form.Control type="text" name="userLatitude" className='form-control-sm' value={this.state.userLatitude}  placeholder="Latitude" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup className="">
                                                        <InputGroup.Text id="basic-addon4" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>lang</InputGroup.Text>
                                                        <Form.Control type="text" name="userLongitude" className='form-control-sm' value={this.state.userLongitude}   placeholder="Longitude" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <h6 className='f-12 mt-2 fw-bold'>
                                            Note : <span className='ml_5 mr_2 color_red'>Distance In Meters,</span>
                                                    
                                        </h6>
                                    </Col>
                                    <Col>
                                        <Button variant="outline-danger" className='f-10  mt-2 pad_3 float-end fw-bold' onClick={() => this.getCurrentLocation()}> <i class="fa-solid fa-location-dot"></i> Use current location
                                            {
                                                this.state.useCurrentLocationSymbol == "show" ?
                                                (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                                                :
                                                ("") 
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                                </fieldset>           
                                </>
                            )
                            :
                            ("")
                        )
                    }        

                {
                    this.state.voterCheckedCond == true ? 
                    (
                        <>
                        <Row>
                            <Col>
                                <Button variant="dark" className='f-12 center-auto mt-4 mb-3' onClick={() => this.getHouseWiseVoters()}>GET HOUSE DETAILS</Button> 
                            </Col>
                        </Row>
                        {
                            this.state.boothWiseHouseListLoaderForFV == "show" ?
                            (
                                <Row>
                                    <Col sm={12}>
                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        }
                        {
                            this.state.boothWiseHouseListForFVArr.length>0 ?
                            (
                                
                                <>
                                <Row>
                                    <Col sm={12}>
                                    <h6 className="f-14 fw-bold mt-4">{this.state.boothValue.label} Voter Details : </h6>
                                    
                                    <div className='bg_light_blue mt-3'>
                                    <CardLessDataTableWithSearch 
                                        parentData={this.houseListDisplayDataForFV()}
                                        particlecolumns={this.houseListDisplayColumnsForFV()}
                                        defaultSorted={[{
                                            dataField: "house_no",
                                            order: "asc"
                                        }]}
                                        tableHeading=""
                                        />
                                        
                                            
                                    </div>
                                    
                                    </Col>
                                </Row>
                                </>
                            )
                            :
                            (<>
                                {
                                this.state.boothWiseHouseListCheckForFV == 1 ?
                                (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                :
                                ("")
                            }
                            </>)
                        }
                        </>
                    )
                    :
                    (
                        this.state.locationCheckedCond == true ? 
                        (
                            <>
                                <Row>
                                    <Col>
                                        <Button variant="dark" className='f-12 center-auto mt-4' onClick={() => this.getHousesLocations()}>GET HOUSE DETAILS</Button> 
                                    </Col>
                                </Row>

                                {
                                    this.state.houseLocationLoading == "show" ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    ("")
                                }
                                {
                                    this.state.houseLocationArr.length>0 ?
                                    (
                                        
                                        <>
                                        <Row>
                                            <Col>
                                            {
                                                ((this.state.boothId != 0) && (this.state.userLatitude != "" && this.state.userLongitude != ""))?
                                                (
                                                    <Link to={{
                                                        pathname: "/map_view_houseVisited" ,
                                                        params: {
                                                            boothId:this.state.boothId,
                                                            userLatitudeValue:this.state.userLatitude,
                                                            userLongitudeValue:this.state.userLongitude,
                                                            distanceCalLatLngDataArr:this.state.houseLocationArr,
                                                            userAccessList:this.state.userAccessList
                                                        }
                                                    }} >
                                                    <h6 className='f-12 float-end map_view_cls mt_1_9'>MAP VIEW <i class="fa-solid fa-map-location-dot"></i></h6>
                                                </Link>
                                                    
                                                )
                                                :
                                                ("")
                                            }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                        
                                            <div className='bg_light_blue'>
                                            <CardLessDataTableWithSearch 
                                                parentData={this.boothNearestLocationsData()}
                                                particlecolumns={this.boothNearestLocationsDataColumns()}
                                                defaultSorted={[{
                                                    dataField: "Distance",
                                                    order: "desc"
                                                }]}
                                                tableHeading={this.state.tableHeadingName}
                                                />
                                                
                                                    
                                            </div>
                                            
                                            </Col>
                                        </Row>
                                        
                                        </>
                                    )
                                    :
                                    (<>
                                        {
                                        this.state.houseLocationDataCheck == 1 ?
                                        (<h6 className='f-14 text-center mt-4'>No Data Availble...</h6>)
                                        :
                                        ("")
                                    }
                                    </>)
                                }
                            </>
                        )
                        :
                        ("")
                    )
                }

                

                <HouseWiseVoterList 
                        show={this.state.houseWiseVotersDisplayModal}
                        onHide={() => this.setState({ houseWiseVotersDisplayModal: false })}
                        stateData={this.state}
                        //getEnrollmentsCountByUserByDate={this.getEnrollmentsCountByUserByDate}
                        houseVisitCaptureClick={this.houseVisitCaptureClick}
                />
                <MaxLimitEnrollChecking 
                        show={this.state.maxEnrollModal}
                        stateData={this.state}
                        closeLimitModal={this.closeLimitModal}
                        modalViewType=""
                />
                <HouseVisitedImageCapture 
                        show={this.state.houseVisitedImageCaptureModal}
                        onHide={() => this.setState({ houseVisitedImageCaptureModal: false })}
                        stateData={this.state}
                        captureForHouseVisit={this.captureForHouseVisit}
                        switchCamaraForHouseVisit={this.switchCamaraForHouseVisit}
                        frontCamaraForHouseVisit={this.frontCamaraForHouseVisit}
                />
                
               </Container>
               
        )
    }
  }
  
  export default withRouter(FamilyVoterSearch);
  