import React, { useState, useEffect } from "react";
import { getAPICallData } from '../../services/MemberService';
import "../BoothAndHouseMapping/BoothAndHouseMapping.css"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";


const MapComponentData = withScriptjs(
  withGoogleMap(props => {
    const [selectedPark, setSelectedPark] = useState(null);
    const [users, setUsers] = useState([]);
    const [defaultLat, setDefaultLat] = useState(0);
    const [defaultLang, setDefaultLang] = useState(0); 
    const [votersList, setvotersList] = useState([]); 

    useEffect(() => {
      const mapObj =  
          JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
          "boothId" : props.booth_id.toString()
          }
          ); 

          getAPICallData(mapObj,"getMappedHousesLocationInABooth")
          .then((res) => res.json())
          .then((data) => {
            if(data !=null && data.length>0){
              setUsers(data);
              setDefaultLat(parseFloat(data[1].latitude));
              setDefaultLang(parseFloat(data[1].longitude));
            }else{
              setUsers([])
            }
            
          });
    }, []);


      useEffect(() => {
        const listener = (e) => {
          if (e.key === "Escape") {
            setSelectedPark(null);
          }
        };
        window.addEventListener("keydown", listener);

        return () => {
          window.removeEventListener("keydown", listener);
        };
      }, []);

      const getVotersList = (boothHouseId) => {
        const votersObj =  
              JSON.stringify({ 
                "username" : "bsa_api",
                "password" : "9D#Jcx!i5B$z@ym4d7K2f",
                "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                "boothHouseId" : boothHouseId
              }
              ); 
    
              getAPICallData(votersObj,"getVotersByBoothHouseId")
              .then((res) => res.json())
              .then((data) => {
                if(data !=null && data.status == "Success"){
                  if(data.votersList !=null && data.votersList.length>0){
                    setvotersList(data.votersList);
                  }else{
                    setvotersList([]);
                  }
                }else{
                  setvotersList([]);
                }
                
                
              });
      }
     
      const google = window.google;
      let mapOptions = {
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_CENTER,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT,
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: true,
      };
      

    if((users !=null && users.length>0) && (defaultLat !=0 && defaultLang !=0)){
      console.log(defaultLat,defaultLang)
      return (
        <GoogleMap
              defaultZoom={15} 
              defaultCenter={{ lat: defaultLat, lng: defaultLang}}
              options={mapOptions}

            >
              {users.map((park,index) => (
                <Marker
                  position={{
                    lat: parseFloat(park.latitude),
                    lng: parseFloat(park.longitude)
                  }}
                  onClick={() => {
                    setSelectedPark(park);getVotersList(park.boothHouseId);
                  }}
                  
                
                />
              ))}
        
              {selectedPark && (
                <InfoWindow
                  onCloseClick={() => {
                    setSelectedPark(null);
                  }}
                  position={{
                    lat: parseFloat(selectedPark.latitude),
                    lng: parseFloat(selectedPark.longitude)
                  }}
    
                  
                >
                  <div>
                    <h6 className="f-14">House No : {selectedPark.houseNo}</h6> 
                    <h6 className="f-14">Street : {selectedPark.street}</h6> 
                    <h6 className="f-14">Voters in house:</h6>
                    <table className='table table_custom mb-2 table-bordered'>
                        <thead>
                            <tr>
                              <th>Name</th>
                              <th>Gender</th>
                              <th>Age</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                              votersList.map((item,index) => {
                              return(
                                
                                <tr>
                                  <td >{item.voterName} </td>
                                  <td>{item.gender}</td>
                                  <td>{item.age}</td>
                                  
                                </tr>
                              )
                              
                            })
                          }
                          
                        </tbody>
                    </table>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
      );
    }
    
  })
);

export default MapComponentData;
