import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

class AddComments extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      
    }

    
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Add Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                        <Row>
                            <Col>
                                <Form.Label className='f-12 fw-bold mt-3'>Comment <span className='color_red f-9'>{this.props.stateData.validateIssueComment}</span></Form.Label>
                                <FloatingLabel
                                    controlId="commentId"
                                    label="Enter Comment"
                                    className="f-12"
                                    
                                >
                                    <Form.Control as="textarea" placeholder="Enter Comment" name="issueComment" className='height100px f-14' onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                      <Row>
                            <Col>
                                <Button type="button" variant="outline-success" className='fw-bold f-14 center-auto mt-3 mb-3' onClick={() => this.props.paddCommentForIssues()}>SUBMIT</Button>
                                    {
                                        this.props.stateData.issueCommentLoadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                    }
                            </Col>
                      </Row>
                      <Row>
                            <Col>
                                <h6 className='f-14 fw-bold text-center mt-2'>{this.props.stateData.issueCommentMessage}</h6>
                            </Col>
                      </Row>
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default AddComments;
  

