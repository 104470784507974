
import React ,{ Component } from 'react';
import {Container, Row, Col,Form,Button,Image,Card,Table,InputGroup} from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif'; 
import '../Volunteer/VolunteerSection.css';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService'
import LOKESH from '../../images/Lokesh.png';
import EnabledLocationPopup from "../Volunteer/EnabledLocationPopup"
import jwt_decode from "jwt-decode";
import Webcam from "react-webcam";
import MS_logo from '../../images/MahaShakti/MS_logo1.png';
import AD_logo from '../../images/AnnaData/AD_logo1.png';
import BC_logo from '../../images/BC/BC_logo1.png';
import HW_logo from '../../images/HouseWater/HW_logo1.png';
import PTR_logo from '../../images/PoorToRich/PTR_logo1.png';
import YG_logo from '../../images/Yuvagalam/YG_logo1.png';
import { scroller } from "react-scroll";
import SuccessMessagePublicOutReach from './SuccessMessagePublicOutReach';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };


class PublicOutreachSection extends Component {
    constructor(props) {
        super(props)
        this.webcamRef = React.createRef();
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            accessNameNavigation:localStorage.getItem('accessNameNavigation'),
            mandalValue:"",
            mandalName:"",
            mc_listArr:[],

            villageValue:"",
            villageName:"",
            vc_listArr:[],

            constituencyValue:"",
            constituencyName:"",
            ac_listArr:[],

            profession_ids_Arr:[{'value':1,'label':'Farmer'},{'value':2,'label':'Business'},{'value':3,'label':'Daily Labour'},{'value':4,'label':'Handicrafts'},{'value':5,'label':'House Wife'},
                                {'value':6,'label':'IT Industry'},{'value':7,'label':'Student'},{'value':8,'label':'Self-Employed'},{'value':9,'label':'Unemployed'},{'value':10,'label':'Dairy Industry'},
                                {'value':11,'label':'Fisherman'},{'value':12,'label':'Doctor'},{'value':13,'label':'Legal Sector'},{'value':14,'label':'Banking Sector'},{'value':15,'label':'Govt Teacher'},
                                {'value':16,'label':'Private Teacher'},{'value':17,'label':'Government Employee'},{'value':18,'label':'Private Employee'},{'value':19,'label':'Retd Employee'},
                                {'value':20,'label':'Media Person'},{'value':21,'label':'Pharma Sector'},{'value':22,'label':'Real Estate'},{'value':23,'label':'Politician'},{'value':24,'label':'Others'},{'value':25,'label':'Employed'}],
            professionStr:"",
            professionIdStrValue:"",

            mandalLoadingSymbol:"hide",
            villageLoadingSymbol:"hide",
            
            publicOutReachMobileNo:"",
            validatepublicOutReachMobileNo:"",
            mobileNoDisabled:false,
            OTPSentStatus:"",

            tehsilId:"",
            townId:"",
            panchayatId:"",
            wardId:"",
            voterName:"",
            voterAge:"",
            voterGender:"",
            benefitAmount:0,
            familyMembers:2,

            validateMandalName:"",
            validateVillageName:"",
            validatebenefitAmount:"",
            validatefamilyMembers:"",



            getPublicOutReachLatitudeLongitudeObj:{},
            checkingEnabledLocationModal:false,
            delaylocationModal:false,
            
            professionNamesAppend:{'1':'Farmer','2':'Business','3':'Daily Labour','4':'Handicrafts','5':'House Wife',
            '6':'IT Industry','7':'Student','8':'Self-Employed','9':'Unemployed','10':'Dairy Industry',
            '11':'Fisherman','12':'Doctor','13':'Legal Sector','14':'Banking Sector','15':'Govt Teacher',
            '16':'Private Teacher','17':'Government Employee','18':'Private Employee','19':'Retd Employee',
            '20':'Media Person','21':'Pharma Sector','22':'Real Estate','23':'Politician','24':'Others','25':'Employed'},

            volunteerOTPNO:"",
            validateVolunteerOTP:"",
            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",
            
            minutes:1,
            seconds:0,

            photoCaptureArr:[],
            showImgCapturePublicOutReach:true,
            imageAddedMsg:"",
            videoConstraints:{facingMode: "environment"},
            validateCapturePhoto:"",

            successMessageModal:false,
            rateOfHouseId:"",
            validateRatingHouseId:"",
            disabledPB:false,

            bookDetails: [
                {
                  indexVal: Math.random(),
                  memberName: "",
                  age: "",
                  gender: "",
                  professionId: "",
                },
                {
                    indexVal: Math.random(),
                    memberName: "",
                    age: "",
                    gender: "",
                    professionId: "",
                }
              ]
            
        }
        
    }
  
    componentDidMount() {
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{

            setTimeout(() => {
                
                if(this.state.chatID != "1048868711"){
                    if(navigator.geolocation){
                        navigator.geolocation.getCurrentPosition(this.getPublicOutReachGeoLocation,this.checkingLocationServiceEnabledError)
                    }else{
                        <EnabledLocationPopup 
                            show={() => this.setState({ checkingEnabledLocationModal: true })}
                            onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                            closeEnabledModal={this.closeEnabledModal}
                        />
                    }
                    setTimeout(() => this.setState({delaylocationModal:true}), 2000);
                 }
                
            }, 5000);

           

            var consArr=[];
                for(var i in  this.state.userAccessList.constituencyList){
                    var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                    consArr.push(obj);
                }
            
                this.setState({ ap_listArr:consArr });
                this.setState({
                    constituencyValue: {
                    value: this.state.userAccessList.constituencyList[0].id,
                    label: this.state.userAccessList.constituencyList[0].name
                },constituencyName:this.state.userAccessList.constituencyList[0].id
            });

            this.getMandalsAndTownsByConstituencyId(this.state.userAccessList.constituencyList[0].id);
        }
    }

    	
    getPublicOutReachGeoLocation = (position) => {
    const getCoordinates =  
        { 
        "latitude" : position.coords.latitude,
        "longitude" : position.coords.longitude,
        "accuracy":position.coords.accuracy
        }
        
    this.setState({getPublicOutReachLatitudeLongitudeObj:getCoordinates});
    
}

checkingLocationServiceEnabledError = (error) => {
    return(
      <EnabledLocationPopup 
              show={() => this.setState({ checkingEnabledLocationModal: true })}
              onHide={() => this.setState({ checkingEnabledLocationModal: false })}
              closeEnabledModal={this.closeEnabledModal}
       />
    )
      
  }

  
  closeEnabledModal = () => {
    this.props.history.push({
        pathname: "/bsbg_publicOutreach",
        params: {
            navbarDisplay:"hide",
            userAccessList:this.state.userAccessList,
        
        },
    });
    
}


    getMandalsAndTownsByConstituencyId = (locationId) => {
        this.setState({mandalLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            "locationId" : locationId,
        }); 

        getAPICallDataForOnlyBSA(obj,"getMandalsAndTownsByConstituencyId")
        .then((res) => res.json())
        .then((data) => {
            this.setState({mandalLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                    typeId:item.typeId
                }
                parlArr.push(obj);
                
            });
            this.setState({ mc_listArr: parlArr });

          }else{
            this.setState({ mc_listArr:[]});
          }
        });
    }

    getPanchayatsByTehsilId = (locationId) => {
        this.setState({villageLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            "locationId" : locationId,
        }); 

        getAPICallDataForOnlyBSA(obj,"getPanchayatsByTehsilId")
        .then((res) => res.json())
        .then((data) => {
            this.setState({villageLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ vc_listArr: parlArr });

          }else{
            this.setState({ vc_listArr:[]});
          }
        });
    }

    getWardsByTownAndConstituencyId = (locationId,constituencyId) => {
        this.setState({villageLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            "locationId" : locationId,
            "constituencyId" : constituencyId
        }); 

        getAPICallDataForOnlyBSA(obj,"getWardsByTownAndConstituencyId")
        .then((res) => res.json())
        .then((data) => {
            this.setState({villageLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ vc_listArr: parlArr });

          }else{
            this.setState({ vc_listArr:[]});
          }
        });
    }

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({validatefamilyMembers:"",validateVoterName:"",validateVoterGender:"",validateVoterAge:"",validatepublicOutReachMobileNo:"",validateVolunteerOTP:""});
        if(name == "familyMembers"){
           if(value >= 2){
                // this.setState({bookDetails:[]}); 
                var arrr=[];
                for(var a=1;a<=value;a++){
                var obj={
                    indexVal: Math.random(),
                    memberName: "",
                    age: "",
                    gender: "",
                    professionId: "",
                }
                arrr.push(obj);
                setTimeout(() => {
                    this.setState({bookDetails:arrr});
                    }, 600); 

                }
            }
           
        }else if(name == "volunteerOTPNO"){
            this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
            if(value.length >= 6){
                this.validateMobileOtp(value);
                
            }
            
        }else if(name == "publicOutReachMobileNo"){
            if(value.length >= 10){
                if(value == "9948029998" || value == "8106184388" || value == "9652244900" || value == "9966542524" || value == "9666519677" || value == "8919278211" || value == "9948998953" || value== "9949601998"){
                    this.setState({mobileNoSuccessMsg:"SUCCESS"});
                }else{
                    this.checkMobileNumberAlreadyExistForPublicOutreach(value);
                }
                
            }
        }
        
        this.setState({ [name]: value });
        
    }
    
    checkMobileNumberAlreadyExistForPublicOutreach = (value) => {
        this.setState({mobileNoLoadingSymbol:"show",mobileNoSuccessMsg:""});
    
        const mapObj =  
        JSON.stringify({ 
            "chatId" : this.state.chatID,
            "mobileNo" : value
        }); 
            
    
        getAPICallDataForOnlyBSA(mapObj,"checkMobileNumberAlreadyExistForPublicOutreach")
          .then((response) => {
            this.setState({mobileNoLoadingSymbol:"hide"});
            // 1. check response.ok
            if (response.ok) {
              return response.json();
            }
            return Promise.reject(response); // 2. reject instead of throw
          })
          .then((json) => {
            // all good, token is ready
            if(json.status == "Success"){
                this.setState({mobileNoSuccessMsg:"SUCCESS"});
            }else{
                this.setState({mobileNoSuccessMsg:"FAIL"});
            }
          })
          .catch((response) => {
            this.setState({mobileNoSuccessMsg:"Something Wrong,Please Try Again..."});
            response.json().then((json) => {
                this.setState({mobileNoSuccessMsg:"Something Wrong,Please Try Again..."});
            })
          });
    
       
      }

    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
        
        this.setState({validateMandalName:"",validateVillageName:""});
        if(name == "mandalName"){
            this.setState({villageValue:"",villageName:""});

            let getManIdName = this.state.mc_listArr.find((a) => a.value == value);
            console.log("getManIdName",getManIdName)
                this.setState({
                    mandalValue: {
                    value: getManIdName.value,
                    label: getManIdName.label,
                    typeId:getManIdName.typeId
                }
            });
            
            this.setState({tehsilId:"",townId:""})
            if(getManIdName.typeId == 5){
                this.setState({tehsilId:value})
                this.getPanchayatsByTehsilId(value);
            }else{
                this.setState({townId:value})
                this.getWardsByTownAndConstituencyId(value,this.state.constituencyValue.value);
            }
            
        }else if(name == "villageName"){
            let getVillIdName = this.state.vc_listArr.find((a) => a.value == value);
                this.setState({
                    villageValue: {
                    value: getVillIdName.value,
                    label: getVillIdName.label
                }
            });
            this.setState({panchayatId:"",wardId:""})
            if(this.state.tehsilId != ""){
                this.setState({panchayatId:value})
            }else{
                this.setState({wardId:value})
            }

            

        }else if(name == "professionId"){
            this.setState({validateVoterProfession:""});
            let getProfessIdName = this.state.profession_ids_Arr.find((a) => a.value == value);
            this.setState({
                professionIdStrValue: {
                value: getProfessIdName.value,
                label: getProfessIdName.label
            }
        });
    
           
        }
          
    
        this.setState({ [name]: value});
      }

      handleOnchangeAllowNumbersInputBox = (e) => {
        this.setState({validatebenefitAmount:"",validatefamilyMembers:""});
        const name = e.target.name;
        const value = e.target.value;
        const re = /^[0-9\b]+$/;

        if(name == "benefitAmount"){
            if (!re.test(e.target.value)) {
                this.setState({validatebenefitAmount:"Enter Numbers Only..",benefitAmount:""});
                return;
            }else{
                this.setState({validatebenefitAmount:""});
            }
        }else if(name == "familyMembers"){
            if (!re.test(e.target.value)) {
                this.setState({validatefamilyMembers:"Enter Numbers Only..",familyMembers:""});
                return;
            }else{
                this.setState({validatefamilyMembers:""});
            }

            if(e.target.value <= 1){
                this.setState({validatefamilyMembers:"Atleast 2 Persons Required"});
            }

        }else if(name == "voterAge"){
            if (!re.test(e.target.value)) {
                this.setState({validateVoterAge:"Enter Numbers Only..",voterAge:""});
                return;
            }else{
                this.setState({validateVoterAge:""});
            }
        }
      }

      

      resendOTPTimer = () => {
        this.setState({minutes:1});
        this.setState({seconds:0});

        this.OTPTimerFunction();
      };

    OTPTimerFunction = () => {
        const interval = setInterval(() => {
            if (this.state.seconds > 0) {
              this.setState({seconds:this.state.seconds - 1});
            }
      
            if (this.state.seconds === 0) {
              if (this.state.minutes === 0) {
                clearInterval(interval);
              } else {
                this.setState({seconds:59});
                this.setState({minutes:this.state.minutes - 1});

              }
            }
          }, 1000);
      
          return () => {
            clearInterval(interval);
          };
    }

    resendOTPNew = () => {
        this.setState({validatepublicOutReachMobileNo:"" });
        if(this.state.publicOutReachMobileNo == ""){
            this.setState({validatepublicOutReachMobileNo:"Please Enter Mobile No" });
            return;
        }
        var expr = /^(0|91)?[6-9][0-9]{9}$/;
        if(!expr.test(this.state.publicOutReachMobileNo)){
            this.setState({validatepublicOutReachMobileNo:"Please Enter Valid Mobile No" });
            return;
          }

        

        if(this.state.publicOutReachMobileNo.length < 10){
            this.setState({validatepublicOutReachMobileNo:"Please Enter Valid Mobile No" });
            return;
        }

        this.setState({resendOTPLoadingSymbol:"show",otpVerifiedValue:0});
       
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.publicOutReachMobileNo,
            "campaignId":3
            
            }); 

            getAPICallDataForOnlyBSA(obj,"sendOTPToMobileNumberForCampaignEnrollment")
            .then((res) => res.json())
            .then((data) => {
              this.setState({resendOTPLoadingSymbol:"hide"});
              if(data !=null && data.status == "Success"){
                console.log(jwt_decode(data.reference).sub)
                this.setState({otpVerifiedValue:jwt_decode(data.reference).sub});
                this.resendOTPTimer();
              }else{
                this.setState({otpVerifiedValue:0});
              }
            });
        
    }
  
    getMobileNoOTP = () => {
        this.setState({validatepublicOutReachMobileNo:"" });
        var expr = /^(0|91)?[6-9][0-9]{9}$/;

        if(this.state.publicOutReachMobileNo == ""){
            this.setState({validatepublicOutReachMobileNo:"Please Enter Mobile No" });
            return;
        }

        if(!expr.test(this.state.publicOutReachMobileNo)){
            this.setState({validatepublicOutReachMobileNo:"Please Enter Valid Mobile No" });
            return;
          }

        if(this.state.publicOutReachMobileNo.length < 10){
            this.setState({validatepublicOutReachMobileNo:"Please Enter Valid Mobile No" });
            return;
        }


        this.setState({smsSentMessage:"",getOTPLoadingSymbol:"show",OTPSentStatus:"",otpVerifiedValue:0});
        
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.publicOutReachMobileNo,
            "campaignId":3
            
            }); 

            getAPICallDataForOnlyBSA(obj,"sendOTPToMobileNumberForCampaignEnrollment")
            .then((res) => res.json())
            .then((data) => {
              this.setState({getOTPLoadingSymbol:"hide"});
              if(data !=null && data.status == "Success"){
                console.log(jwt_decode(data.reference).sub)
                this.setState({smsSentMessage:"OTP Sent Your Mobile No",OTPSentStatus:data.status,otpVerifiedValue:jwt_decode(data.reference).sub});
                this.setState({mobileNoDisabled:true})
                this.OTPTimerFunction();
              }else{
                this.setState({smsSentMessage:"Some Thing Went Wrong,Please Try Again...",OTPSentStatus:"Fail",otpVerifiedValue:0});
                this.setState({mobileNoDisabled:false})
              }
            });
    }

    


    validateMobileOtp = (otp) => {
        this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            "mobileNo" : this.state.publicOutReachMobileNo,
            "otp" : otp
            

            }); 

            getAPICallDataForOnlyBSA(obj,"validateMobileOtp")
            .then((res) => res.json())
            .then((data) => {
               this.setState({otpLoadingSymbol:"hide"});
               if(data !=null && data.status == "Success"){
                this.setState({otpVerifiedMessageStatus:"Verified"});
              }else{
                this.setState({otpVerifiedMessageStatus:"NotVerified"});
              }
            });
    }

    checkRateOfHouseId = (e,value) => {
        this.setState({validateRatingHouseId:""})
        this.setState({rateOfHouseId:value})
    }


    saveOutreachMember = () => {
        if(this.state.mandalName == "" || this.state.mandalName == null){
            scroller.scrollTo("mandal_scroll", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateMandalName:"Select Mandal" });
            return;
        }
        if(this.state.villageName == "" || this.state.villageName == null){
            scroller.scrollTo("mandal_scroll", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateVillageName:"Select Village" });
            return;
        }
       
        if(this.state.familyMembers == "" || this.state.familyMembers == 0){
            this.setState({validatefamilyMembers:"Enter Members" });
            return;
        }
        
        var membersList = this.state.bookDetails;
        if(membersList.length > 0){
            for(var i in membersList){
                var personId = "errorMsgDisplay"+membersList[i].indexVal+"";
                document.getElementById(personId).innerHTML = "";
                if(membersList[i].memberName == "" || membersList[i].age == "" || membersList[i].gender == "" || membersList[i].professionId == ""){
                    scroller.scrollTo(personId, { 
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                    });
                    document.getElementById(personId).innerHTML = "Enter Person Details";
                    return;
                }
            }
        }

        if(this.state.rateOfHouseId == "" || this.state.rateOfHouseId == 0){
            scroller.scrollTo("rating_Scroll", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateRatingHouseId:"Please Check Atleast One Check" });
            return;
        }

        if(this.state.publicOutReachMobileNo == ""){
            scroller.scrollTo("OTP_Scroll", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validatepublicOutReachMobileNo:"Enter Mobile No"});
            return;
        }
        if(!this.state.publicOutReachMobileNo.match(/^[0-9]+$/)){
            scroller.scrollTo("OTP_Scroll", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validatepublicOutReachMobileNo:"Enter Valid Mobile No"});
            return;
        }
    
        if(this.state.publicOutReachMobileNo.length < 10){
            scroller.scrollTo("OTP_Scroll", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validatepublicOutReachMobileNo:"Enter Valid Mobile No"});
            return;
        }

        if(this.state.publicOutReachMobileNo != ""){
            if(this.state.volunteerOTPNO == ""){
                scroller.scrollTo("OTP_Scroll", { 
                    duration: 800,
                    delay: 0,
                    smooth: "easeInOutQuart",
                });
                this.setState({validateVolunteerOTP:"Please Enter OTP"});
                return;
            }
    
            if(this.state.volunteerOTPNO.length < 6){
                scroller.scrollTo("OTP_Scroll", { 
                    duration: 800,
                    delay: 0,
                    smooth: "easeInOutQuart",
                });
                this.setState({validateVolunteerOTP:"Please Enter Valid OTP"});
                return;
            }
    
            if(this.state.otpVerifiedMessageStatus == "NotVerified"){
                scroller.scrollTo("OTP_Scroll", { 
                    duration: 800,
                    delay: 0,
                    smooth: "easeInOutQuart",
                });
                this.setState({validateVolunteerOTP:"Please Enter Valid OTP" });
                return;
            }
        }

        var base64ImgListArr=[];
        if(this.state.photoCaptureArr.length > 0){
            for(var i in this.state.photoCaptureArr){
                base64ImgListArr.push(this.state.photoCaptureArr[i].data.replace("data:image/jpeg;base64,",""));
            }
        }else{
            //this.setState({disabledUploadButton:false});
            scroller.scrollTo("photo_scroll", { 
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this.setState({validateCapturePhoto:"Please Take A Photo Atleast One..."});
            return;
        }

        this.setState({disabledPB:true})
        this.setState({saveOutreachMemberLoadingSymbol:"show"});


        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.saveOutreachMemberWithLocation,this.checkingLocationServiceEnabledError)
        }else{
            <EnabledLocationPopup 
            show={() => this.setState({ checkingEnabledLocationModal: true })}
            onHide={() => this.setState({ checkingEnabledLocationModal: false })}
            closeEnabledModal={this.closeEnabledModal}
        />
        }
    }

    saveOutreachMemberWithLocation = (position) => {
        
        
        var base64ImgListArr1=[];
        if(this.state.photoCaptureArr.length > 0){
            for(var i in this.state.photoCaptureArr){
                base64ImgListArr1.push(this.state.photoCaptureArr[i].data.replace("data:image/jpeg;base64,",""));
            }
        }

    setTimeout(() => {
        const obj =  
        JSON.stringify({ 
            "chatId" : this.state.chatID,
            "constituencyId" : this.state.constituencyValue.value,
            "tehsilId" : this.state.tehsilId,
            "townId" : this.state.townId, 
            "panchayatId" : this.state.panchayatId, 
            "wardId" : this.state.wardId, 
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude,
            "benefitAmount" : parseInt(this.state.benefitAmount),
            "familyMembers" : parseInt(this.state.familyMembers),
            "mobileNo" : this.state.publicOutReachMobileNo,
            "membersList" : this.state.bookDetails,
            "base64StrList" : base64ImgListArr1,
            "ratingId":this.state.rateOfHouseId
        }); 

        getAPICallDataForOnlyBSA(obj,"saveOutreachMember")
        .then((res) => res.json())
        .then((data) => {
            this.setState({saveOutreachMemberLoadingSymbol:"hide"});
            this.setState({successMessageModal:true})
            if(data !=null && data.status == "Success"){
            
                this.setState({ successMessagePB: "Saved SuccessFully..." });

                setTimeout(() => {
                    this.setState({successMessageModal:false})
                    this.setState({ successMessagePB: "",
                                    mandalValue:"",
                                    mandalName:"",

                                    villageValue:"",
                                    villageName:"",

                                    mandalLoadingSymbol:"hide",
                                    villageLoadingSymbol:"hide",
                                    
                                    publicOutReachMobileNo:"",
                                    validatepublicOutReachMobileNo:"",
                                    mobileNoDisabled:false,
                                    OTPSentStatus:"",

                                    tehsilId:"",
                                    townId:"",
                                    panchayatId:"",
                                    wardId:"",
                                    voterName:"",
                                    voterAge:"",
                                    voterGender:"",
                                    benefitAmount:0,
                                    familyMembers:2,

                                    validateMandalName:"",
                                    validateVillageName:"",
                                    validatebenefitAmount:"",
                                    validatefamilyMembers:"",



                                    
                                    volunteerOTPNO:"",
                                    validateVolunteerOTP:"",
                                    smsSentMessage:"",
                                    otpVerifiedMessageStatus:"",
                                    otpLoadingSymbol:"hide",
                                    getOTPLoadingSymbol:"hide",
                                    OTPSentStatus:"",
                                    resendOTPLoadingSymbol:"hide",
                                    resendSmsSentMessage:"",

                                    mobileNoLoadingSymbol:"hide",
                                    mobileNoSuccessMsg:"",
                                    
                                    minutes:1,
                                    seconds:0,

                                    photoCaptureArr:[],
                                    showImgCapturePublicOutReach:true,
                                    imageAddedMsg:"",
                                    videoConstraints:{facingMode: "environment"},
                                    disabledPB:false,

                                    bookDetails: [
                                        {
                                        indexVal: Math.random(),
                                        memberName: "",
                                        age: "",
                                        gender: "",
                                        professionId: "",
                                        },
                                        {
                                            indexVal: Math.random(),
                                            memberName: "",
                                            age: "",
                                            gender: "",
                                            professionId: "",
                                        }
                                    ] });
                 
    
                }, 2000);

            }else if(data !=null && data.status == "Fail"){
                this.setState({successMessagePB:data.message});
                this.setState({disabledPB:false})
        
            }else{
                this.setState({ successMessagePB: "SomeThing Went Wrong,Please Try Agian..."});
                this.setState({disabledPB:false})
            }
        });
        }, 1000);
      }

closeSuccessModalForPublicOutReach = () => {
    this.setState({successMessageModal:false})
}
switchCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "environment"}});
}
frontCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "user"}});
}
removeCapturePhotoFromPublicOutReach = (fileId) => {
    var tempArr = this.state.photoCaptureArr;
    const objWithIdIndex = tempArr.findIndex((obj) => obj.fileId == fileId);

    if (objWithIdIndex > -1) {
        tempArr.splice(objWithIdIndex, 1);
    }

    this.setState({photoCaptureArr:tempArr})

    }
    
reloadCam = () => {
    const constraints = {
        audio: false,
        video: true,
      };
      
    this.setState({showImgCapturePublicOutReach:true})
    this.setState({videoConstraints:{facingMode: "environment"}});
    navigator.mediaDevices
    .getUserMedia(constraints)
    .then((mediaStream) => {
        const video = document.querySelector("video");
        video.srcObject = mediaStream;
        video.onloadedmetadata = () => {
        video.play();
        };
    })
    .catch((err) => {
        // always check for errors at the end.
        console.error(`${err.name}: ${err.message}`);
    });
      
}
captureForPublicOutReach = () => {
    this.setState({showImgCapturePublicOutReach:true,imageAddedMsg:""});

    const getUID = (length=8) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    var obj={
        fileId : getUID(),
        data : this.webcamRef.current.getScreenshot(),
      }

      this.setState({imageAddedMsg:"Capture Photo added successfully in below..."});
      setTimeout(() => {
        this.setState({photoCaptureArr:[...this.state.photoCaptureArr, obj]}); 
        this.setState({imageAddedMsg:"",validateCapturePhoto:""});
    }, 1000);
};
     
handleChange = e => {
    console.log("ssssssssssss",e)
    if (
      ["memberName", "age", "gender", "professionId"].includes(
        e.target.name
      )
    ) {
      let bookDetails = [...this.state.bookDetails];
      bookDetails[e.target.dataset.id][e.target.name] = e.target.value;
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }

    console.log("bookDetails",this.state.bookDetails)
  };

  clickOnDelete(record) {
    this.setState({
      bookDetails: this.state.bookDetails.filter(r => r !== record)
    });

    setTimeout(() => {
        this.setState({familyMembers:this.state.bookDetails.length})
    }, 1000);
    
  }


    render() {
        
        return (
            <section className='mb-4'>
                 <div className="header_img_p">
                  <div className="flex-header mandal_scroll">
                    <Link to={{pathname: "/dashboard"}}> 
                        <Button variant="outline-light pad5_14 back_button1 mt-45 ms-2" className='f-12  fw-bold'> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                    </Link>
                      
                     <h5 className="logo_font_size1 color_white f-18">Public OutReach</h5>
                      
                      <img className="center-auto" src={LOKESH} />
                    </div>
                    
                </div>
                <div className="boder_pad_yellow fw-bold f-14">{this.state.accessNameNavigation} : </div>
                <Container fluid className=''>
                <div className=''>
               {/* <Row>
                    <Col sm={12}>
                    <h6 className='f-12 mt-3 fw-bold'>Select <b className='color_green'>"Babu Super Six"</b> Options which are eligible to Voter Family:</h6>
                    <Form className="check_box_size">
                            <Form.Check
                                inline
                                label={<img className="center-auto w_90_R" src={MS_logo} />}
                                name="interestsId"
                                type="checkbox"
                                id="maha_shakti_id"
                                onChange={(e)=>this.checkInterestValue(e,"1","MAHA_SHAKTI")}
                                className="fw-bold color_black input_size_MS"
                                defaultChecked={false}
                            />

                            <Form.Check
                                inline
                                label={<img className="center-auto w_60_R" src={YG_logo} />}
                                name="interestsId"
                                type="checkbox"
                                id="yuva_galam_id"
                                onChange={(e)=>this.checkInterestValue(e,"2","YUVA_GALAM")}
                                className="fw-bold color_black input_size"
                                defaultChecked={false}
                            />
                            <Form.Check
                                inline
                                label={<img className="center-auto w_60_R" src={AD_logo} />}
                                name="interestsId"
                                type="checkbox"
                                id="ad_id"
                                onChange={(e)=>this.checkInterestValue(e,"3","RYTHU_NESTAM")}
                                className="fw-bold color_black input_size_AD"
                                defaultChecked={false}
                            />
                    </Form>
                </Col>
                    <Col sm={12}>
                    <Form className="check_box_size">
                        <Form.Check
                            inline
                            label={<img className="center-auto w_90_R" src={HW_logo} />}
                            name="interestsId"
                            type="checkbox"
                            id="house_water_id"
                            onChange={(e)=>this.checkInterestValue(e,"4","INTINTKI_WATER")}
                            className="fw-bold color_black input_size"
                            defaultChecked={false}
                        />
                        <Form.Check
                            inline
                            label={<img className="center-auto w_90_R" src={PTR_logo} />}
                            name="interestsId"
                            type="checkbox"
                            id="ptr_id"
                            onChange={(e)=>this.checkInterestValue(e,"5","POOR_TO_RICH")}
                            className="fw-bold color_black input_size"
                            defaultChecked={false}
                        />
                        <Form.Check
                            inline
                            label={<img className="center-auto w_90_R" src={BC_logo} />}
                            name="interestsId"
                            type="checkbox"
                            id="bc_id"
                            onChange={(e)=>this.checkInterestValue(e,"6","BC_PROTECTION")}
                            className="fw-bold color_black input_size"
                            defaultChecked={false}
                        />
                        
                        </Form>
                    </Col>
        </Row>*/}

                    {
                        this.state.chatID != "1048868711" ?
                        (
                            this.state.delaylocationModal == true && Object.keys(this.state.getPublicOutReachLatitudeLongitudeObj).length == 0 ?
                            (
                                <EnabledLocationPopup 
                                    show={() => this.setState({ checkingEnabledLocationModal: true })}
                                    onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                                    closeEnabledModal={this.closeEnabledModal}
                                />
                             )
                            :
                            ("")
                        )
                        :
                        ("")
                    }
                
                <Row>
                    <Col className='mt-3' >
                        <label className="form-label f-12 fw-bold">Mandal/Town <span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9 fw-bold'>{this.state.validateMandalName}</span>
                            {
                                this.state.mandalLoadingSymbol == "show" ?
                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                :
                                ("")
                            }
                        </label>
                        
                        <SelectBox
                                stateData={this.state.mandalValue}
                                handleChange={this.handleOnchangeSelectBoxNew}
                                name="mandalName"
                                options={this.state.mc_listArr}
                                placeholderName={'Mandal/Town'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                            
                    </Col>
                    <Col className='mt-3' >
                        <label className="form-label f-12 fw-bold">Village/Ward <span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9 fw-bold'>{this.state.validateVillageName}</span>
                            {
                                this.state.villageLoadingSymbol == "show" ?
                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                :
                                ("")
                            }
                        </label>
                        <SelectBox
                                stateData={this.state.villageValue}
                                handleChange={this.handleOnchangeSelectBoxNew}
                                name="villageName"
                                options={this.state.vc_listArr}
                                placeholderName={'Village'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                            
                    </Col>
                </Row>
                </div>
                <Row>
                   {/* <Col className='mt-2'>
                        <Form.Group  controlId="formbenefitAmountId">
                            <Form.Label className='f-12 fw-bold'>Benefit Amount:<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9 fw-bold'>{this.state.validatebenefitAmount}</span></Form.Label>
                            <Form.Control name="benefitAmount" className='form-control-sm'  type="text" value={this.state.benefitAmount} placeholder="Enter Amount" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                            
                        </Form.Group>
                     </Col>*/}
                    <Col className='mt-3 mb-2'>
                        <Form.Group className="" >
                            <InputGroup className="">
                                    <InputGroup.Text id="basic-addon1" className='f-12 fw-bold' style={{textAlign:"left",marginLeft:"0px"}}>No of Family Members<span className='color_red f-12 ms-1'>*</span> </InputGroup.Text>
                                    <Form.Control type="text" name="familyMembers" value={this.state.familyMembers}  className='form-control-sm'  placeholder="No of Family Members" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                            </InputGroup>
                            <span className='color_red f-12 fw-bold'>{this.state.validatefamilyMembers}</span>
                        </Form.Group>
                        
                    </Col>
                </Row>
                
                

                <Row>
                    <Col>
                        <fieldset class=""><legend>Person Details</legend>
                            {
                                this.state.bookDetails.map((item,index) => {
                                    let memberName = `memberName-${index}`,
                                        age = `age-${index}`,
                                        gender = `gender-${index}`,
                                        professionId = `professionId-${index}`;
                                        
                                    return(
                                        <form  onChange={this.handleChange}>
                                            <h6 className='f-14 mb-0 mt-3 fw-bold'>Person - {index+1} : <span className='color_red f-12 fw-bold' id={`errorMsgDisplay${item.indexVal}`}></span> </h6>
                                        <div key={item.indexVal} className='mt-2 block_pad_border'>
                                            <Row>
                                                <Col className=''>
                                                    <Form.Group className="" >
                                                        <InputGroup className="mt-1">
                                                                <InputGroup.Text id="basic-addon1" className='f-12 fw-bold' style={{textAlign:"left",marginLeft:"0px"}}>Name<span className='color_red f-12 ms-1'>*</span> </InputGroup.Text>
                                                                <Form.Control type="text" name="memberName" data-id={index} id={memberName} className='form-control-sm'  placeholder="Enter Name" />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                {/*<Col className='width_10_form'>
                                                    {
                                                        index >= 2 ?
                                                        (<span className='f-18  float-right fa-solid fa-circle-xmark remove_mark_pos cursor_pointer color_red' onClick={() => this.clickOnDelete(item)}></span>)
                                                        :
                                                        ("")
                                                    }
                                                    
                                                </Col>*/}
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Form.Group className="mt-2" >
                                                        <Form.Label className='f-12 fw-bold'>Gender: <span className='color_red f-12 ms-1'>*</span> <span className='color_red f-12 fw-bold'>{this.state.validateVoterGender}</span></Form.Label>
                                                            <select  name="gender" data-id={index} id={gender} className='form-select form-select-sm' >
                                                                <option value="">Select Gender</option>
                                                                <option value="M" >Male</option>
                                                                <option value="F">Female</option>
                                                            
                                                            </select>
                                                            
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className='mt-2'>
                                                        <Form.Label className='f-12 fw-bold'>Age:  <span className='color_red f-12 ms-1'>*</span> </Form.Label>
                                                        <Form.Control name="age" className='form-control-sm' data-id={index} id={age}  type="text" placeholder="Enter Age" maxLength={2} />
                                                        
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='mt-3'>
                                                        <InputGroup className="">
                                                                <InputGroup.Text id="basic-addon1" className='f-12 fw-bold' style={{textAlign:"left",marginLeft:"0px"}}>Profession<span className='color_red f-12 ms-1'>*</span> </InputGroup.Text>
                                                                <select  name="professionId" data-id={index} id={professionId} className='form-select form-select-sm' >
                                                                    <option value="">Select Profession</option>
                                                                    {
                                                                        this.state.profession_ids_Arr.map(val => {
                                                                            return(
                                                                                <option value={val.value} >{val.label}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                
                                                                </select>
                                                        </InputGroup>
                                                    </Col>
                                            </Row>
                                            
                                        </div>
                                        </form>
                                    )
                                })
                            }
                            
                        </fieldset>
                    </Col>
                </Row>

                <div className='rating_Scroll'>
                    <Row>
                        <Col className='mt-2'>
                            <fieldset class=""><legend>Rate the House</legend>
                            <Row>
                                <Col>
                                <h6 className='f-12 mb-0 color_red fw-bold'>{this.state.validateRatingHouseId}</h6>
                                    <Form>
                                        <div className=''>
                                        <Form.Check
                                            inline
                                            label={`A`}
                                            name="rateOfHouse"
                                            type="radio"
                                            id={`rateOfHouseGoodBehId`}
                                            onChange={(e)=>this.checkRateOfHouseId(e,"1")}
                                            className="f-13 fw-bold color_black"
                                            value="1"
                                        />
                                        <Form.Check
                                            inline
                                            label={`B`}
                                            name="rateOfHouse"
                                            type="radio"
                                            id={`rateOfHouseMpdeBehId`}
                                            onChange={(e)=>this.checkRateOfHouseId(e,"2")}
                                            className="f-13 fw-bold color_black"
                                            value="2"
                                        />
                                        <Form.Check
                                            inline
                                            label={`C`}
                                            name="rateOfHouse"
                                            type="radio"
                                            id={`rateOfHouseRudeBehId`}
                                            onChange={(e)=>this.checkRateOfHouseId(e,"3")}
                                            className="f-13 fw-bold color_black"
                                            value="3"
                                        />
                                        </div>
                                    </Form>   
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                </div>
                
                <div className='OTP_Scroll'>
                <Row>
                    <Col className='mt-2'>
                    <fieldset class=""><legend>OTP Verification</legend>
                        <Form>
                            <Form.Group className="" controlId="formMobileId">
                                <Form.Label className='f-12 fw-bold'>Mobile No:<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validatepublicOutReachMobileNo}</span>
                                    {
                                        this.state.mobileNoLoadingSymbol == "show" ?
                                        (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls1'></Image>)
                                        :
                                        (
                                            ""
                                        )
                                    }
                                    {
                                        this.state.mobileNoSuccessMsg == "SUCCESS"  ?
                                        (<i class="fa-regular fa-circle-check color_green f-14"></i>)
                                        :
                                        (
                                            this.state.mobileNoSuccessMsg == "FAIL" || this.state.mobileNoSuccessMsg == "Something Wrong,Please Try Again..." ?
                                            (<i class="fa-regular fa-circle-xmark color_red f-14"></i>)
                                            :
                                            ("")
                                            
                                        )
                                    }
                                    
                                </Form.Label>
                                <Row>
                                    <Col className='width_90_static'> 
                                        <Form.Control type="text" name="publicOutReachMobileNo" disabled={this.state.mobileNoDisabled} className='form-control-sm' maxLength={10} value={this.state.publicOutReachMobileNo}  placeholder="Enter Mobile No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                    </Col>
                                    <Col className='width_10_static'>
                                        {
                                            this.state.mobileNoSuccessMsg == "SUCCESS" || this.state.mobileNoSuccessMsg == ""  ? 
                                            (
                                                <>
                                                 {
                                                    this.state.OTPSentStatus == "Fail" || this.state.OTPSentStatus == "" ?
                                                    (
                                                        <>
                                                        <span className='f-12 color_red'>{this.state.smsSentMessage}</span>
                                                            <Button variant="dark" className='f-12 float-right  btn-sm' onClick={() => this.getMobileNoOTP()}>GET OTP
                                                                {
                                                                    this.state.getOTPLoadingSymbol == "show" ?
                                                                    (<Image src={loader} alt="loader" className='get_otp_loading_symbol_cls22'></Image>)
                                                                    :
                                                                    ("")
                                                                }  
                                                            </Button> 
                                                        </>
                                                    )
                                                    :
                                                    ("")
                                                }
                                                </>
                                            )
                                            :
                                            ("")
                                        }
                                       
                                    </Col>
                                </Row>
                                {
                                    this.state.mobileNoSuccessMsg == "SUCCESS" || this.state.mobileNoSuccessMsg == ""  ?
                                    (
                                        ""
                                    )
                                    :
                                    (
                                        <Row>
                                            <Col>
                                                <span className='mt-3 color_red fw-bold f-12 pos_top_m'>This mobile no already registered, use another mobile no</span>
                                            </Col>
                                        </Row>
                                        
                                    )
                                }
                                    
                            </Form.Group>  
                        </Form>
                        <Form>
                            <Row>
                                <Col className=''>
                                    <Form.Group className="mt-2" controlId="formOTPId">
                                        <Form.Label className='f-12 fw-bold'>OTP No<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateVolunteerOTP}</span></Form.Label>
                                        <Form.Control type="text" name="volunteerOTPNO" maxLength={6} className='form-control-sm' value={this.state.volunteerOTPNO}  placeholder="Enter OTP No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                    </Form.Group> 
                                </Col>
                                
                            </Row>
                            
                                <Row>
                                    <Col>
                                        {
                                            this.state.OTPSentStatus == "Success"?
                                            (
                                                <>
                                                    <h6 className='mt-2 f-12 color_green line_height_20'>{this.state.smsSentMessage} : <b >{this.state.publicOutReachMobileNo}</b></h6>
                                                    
                                                    {
                                                        this.state.otpVerifiedMessageStatus != "Verified" || this.state.otpVerifiedMessageStatus == "" ?
                                                        (
                                                            <div className="countdown-text">
                                                                {this.state.seconds > 0 || this.state.minutes > 0 ? (
                                                                <h6 className='mt-2 f-14'>
                                                                    Time Remaining: {this.state.minutes < 10 ? `0${this.state.minutes}` : this.state.minutes}:
                                                                    {this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}
                                                                </h6>
                                                                ) : (
                                                                <h6 className='mt-2 f-12'>Didn't recieve code?</h6>
                                                                )}
                                                                {
                                                                    this.state.resendOTPLoadingSymbol == "show" ?
                                                                    (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                    :
                                                                    ("")
                                                                } 
                                                                <Button variant="dark" className='f-12 float-right mt-2' 
                                                                disabled={this.state.seconds > 0 || this.state.minutes > 0}
                                                                style={{
                                                                    color: this.state.seconds > 0 || this.state.minutes > 0 ? "#DFE3E8" : "#fff",
                                                                }} onClick={this.resendOTPNew}  >Resend OTP</Button>
                                                                
                                                            </div>
                                                        )
                                                        :
                                                        ("")
                                                    }
                                                
                                                    
                                                </>
                                            )
                                            :
                                            ("")
                                        }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        {
                                                this.state.otpLoadingSymbol == "show" ?
                                                (<Image src={loader} alt="loader" className='loader_cls_otp ms-2'></Image>)
                                                :
                                                (
                                                    this.state.otpVerifiedMessageStatus == "Verified" ?
                                                    (

                                                        <h6 class="color_green fw-bold f-14  text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>OTP verified successfully</h6>
                                                        
                                                    )
                                                    :
                                                    (
                                                        this.state.otpVerifiedMessageStatus == "NotVerified" ?
                                                        (<h6 class="color_red fw-bold f-14 text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>Not Verified,Please Enter Valid OTP</h6>)
                                                        :
                                                        ("")
                                                        
                                                    
                                                    )
                                            )
                                        }
                                    </Col>
                                </Row>
                            
                        </Form> 
                        </fieldset>   
                    </Col>
                </Row>
                </div>
                
                <div className='photo_scroll'>
                <Row>
                    <Col>
                        <fieldset class=""><legend>Photo Capture</legend>
                            <div className="showImgCaptureDivBSBG">{this.state.showImgCapturePublicOutReach && (
                            <> 
                            
                            <Row>
                                <Col>
                                    <h6 className='f-12 color_red'>Note : Camara is Not Available,Please Click on Reload Button</h6>
                                    <Button variant="danger" className='f-12 float-right float-right btn-sm' onClick={() => this.reloadCam()}> Reload Webcam</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className='color_red f-12'>{this.state.validateCapturePhoto}</span>
                                </Col>
                            </Row>
                            <Webcam
                                audio={false}
                                ref={this.webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={this.state.videoConstraints}
                                className='img-thumbnail'
                            /> 
                            
                            
                            <div className="row mt-2">
                                <div className="col">
                                    <h6 onClick={() => this.captureForPublicOutReach()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                </div>
                                <div className="col">
                                    {
                                    this.state.videoConstraints.facingMode == "user" ?
                                    (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                    :
                                    ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                    }
                                </div>
                            </div>

                            {
                                this.state.imageAddedMsg != "" ?
                                (<h6 className="f-14 fw-bold text-center color_green">{this.state.imageAddedMsg}</h6>)
                                :
                                ("")
                            }
                            
                            {
                                this.state.photoCaptureArr.length>0 ?
                                (
                                <>
                                    <Row>
                                    <Col sm={12} className="mt-2">
                                    <h6>Capture Live Photos : </h6>
                                        
                                    </Col>
                                    <Col sm={12} className="">
                                        <div className='flex_img'>
                                            {(this.state.photoCaptureArr || []).map((url,index) => (
                                                <div className='ms-1 border_img_pad'>
                                                    <i class="fa-solid fa-circle-xmark" onClick={() => this.removeCapturePhotoFromPublicOutReach(url.fileId)}></i>
                                                    <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                </div>
                                            ))} 
                                        </div>
                                    </Col>
                                </Row>
                                </>
                                
                                
                                )
                                :
                                ("")
                            }

                            </>
                            
                            )}
                            </div>
                        </fieldset>
                    </Col>
                </Row>
                </div>


                <Row>
                    <Col>
                        <Button variant="success" className='f-14 center-auto fw-bold mt-3' disabled={this.state.disabledPB} onClick={() => this.saveOutreachMember()}> SUBMIT 
                        {
                            this.state.saveOutreachMemberLoadingSymbol == "show" ?
                            (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                            :
                            ("")
                        }
                        </Button> 
                    </Col>
                    
                </Row>

                {
                    this.state.successMessagePB != "" ?
                    (
                        <Row>
                            <Col>
                                <h6 className='text-center f-18 fw-bold'>{this.state.successMessagePB}</h6>
                            </Col>
                        </Row>
                    )
                    :
                    ("")
                }

                <SuccessMessagePublicOutReach 
                        show={this.state.successMessageModal}
                        onHide={() => this.setState({ successMessageModal: false })}
                        stateData={this.state}
                        closeSuccessModalForPublicOutReach={this.closeSuccessModalForPublicOutReach}
                        
                />                                       
                    
                </Container>
            </section>
        )
    }
  }
  
  export default withRouter(PublicOutreachSection);
  