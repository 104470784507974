
import React ,{ Component } from 'react';
import {Container, Row, Col,Card,Button,Image  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService'
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CardLessDataTableWithSearchLimit10 from '../datatables/CardLessDataTableWithSearchLimit10';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class AttendenceDashboard extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,

            constituencyValue:"",
            constituencyId:"",
            constituencyListArr:[],

            allConstituencyValue:"",
            allConstituencyName:"",
            ac_listArr:[],

            
            overAllEventBlock:true,
            userWiseEventBlock:false,

            eventFromDate:"2024-02-01",
            eventToDate:moment().format("YYYY-MM-DD"),

            overAllAttendedTotalCount:null,
            overAllRoleAttendedTotalCount:null,

            userAttendedTotalCount:null,
            userRoleAttendedTotalCount:null,
            

            eventNamesLoadingSymbol:"hide",
            event_listArr:[],
            eventValue:"",
            eventName:"",
            validateEvent:""
            
        }
    }

    componentDidMount() {
        console.log("userAccessListdddd",this.state.chatID)
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
            }
           
            this.setState({ constituencyListArr:consArr });
            this.setState({
                constituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },constituencyId:this.state.userAccessList.constituencyList[0].id
        });
          
            this.getActiveEventsForAttendance();
            this.getAssemblyConstituenciesForAttendance();
        
        }
    }

    getActiveEventsForAttendance = () => {
        this.setState({eventNamesLoadingSymbol:"show"});
        const obj =  {}

        getAPICallDataForOnlyBSA(obj,"Event/getActiveEventsForAttendance")
        .then((res) => res.json())
        .then((data) => {
            this.setState({eventNamesLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let arr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                arr.push(obj);
                
            });
            this.setState({ event_listArr: arr});
            this.setState({
                    eventValue: {
                    value: data[0].id,
                    label: data[0].name
                },eventName:data[0].id
            });

            this.getEventAttendeeCount(this.state.constituencyValue.value);
            this.getRoleWiseEventAttendeeCount(this.state.constituencyValue.value);

            //user
            this.getEventAttendeeCountByBotUser(this.state.eventFromDate,this.state.eventToDate);
            this.getRoleWiseEventAttendeeCountByBotUser(this.state.eventFromDate,this.state.eventToDate);


          }else{
            this.setState({ event_listArr:[]});
          }
        });
    }

   

    getEventAttendeeCount = (constituencyId) => {
        const obj =  
            JSON.stringify({ 
                "eventId" : this.state.eventName,
                "fromDate" : "2024-02-01",
                "toDate" : moment().format("YYYY-MM-DD"),
                "constituencyId" : constituencyId
                
            }
        ); 
        getAPICallDataForOnlyBSA(obj,"Event/getEventAttendeeCount")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null ){
                    this.setState({overAllAttendedTotalCount:data})
                }else{
                    this.setState({overAllAttendedTotalCount:""})
                }
            });
    }

    getRoleWiseEventAttendeeCount = (constituencyId) => {
        const obj =  
            JSON.stringify({ 
                "eventId" : this.state.eventName,
                "fromDate" : "2024-02-01",
                "toDate" : moment().format("YYYY-MM-DD"),
                "constituencyId" : constituencyId
                
            }
        ); 
        getAPICallDataForOnlyBSA(obj,"Event/getRoleWiseEventAttendeeCount")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null ){
                    this.setState({overAllRoleAttendedTotalCount:data})
                }else{
                    this.setState({overAllRoleAttendedTotalCount:""})
                }
            });
    }

    //user Wise
    getEventAttendeeCountByBotUser = (fromDate,toDate) => {
        const obj =  
            JSON.stringify({ 
                "chatId" : this.state.chatID,
                "eventId" : this.state.eventName,
                "fromDate" : fromDate,
                "toDate" : toDate,
                "constituencyId" : this.state.constituencyValue.value
                
            }
        ); 
        getAPICallDataForOnlyBSA(obj,"Event/getEventAttendeeCountByBotUser")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null ){
                    this.setState({userAttendedTotalCount:data})
                }else{
                    this.setState({userAttendedTotalCount:""})
                }
            });
    }

    getRoleWiseEventAttendeeCountByBotUser = (fromDate,toDate) => {
        const obj =  
            JSON.stringify({ 
                "chatId" : this.state.chatID,
                "eventId" : this.state.eventName,
                "fromDate" : fromDate,
                "toDate" : toDate,
                "constituencyId" : this.state.constituencyValue.value
                
            }
        ); 
        getAPICallDataForOnlyBSA(obj,"Event/getRoleWiseEventAttendeeCountByBotUser")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null ){
                    this.setState({userRoleAttendedTotalCount:data})
                }else{
                    this.setState({userRoleAttendedTotalCount:""})
                }
            });
    }

    userWiseOnchangeEvent = (event, picker) => {
        this.setState({pickerChosenLabel:picker.chosenLabel,userAttendedTotalCount:null})

        console.log(picker)
        console.log("start: ",  moment(picker.startDate._d).format('YYYY-MM-DD'));
        console.log("end: ",  moment(picker.endDate._d).format('YYYY-MM-DD'));

        if(picker.chosenLabel == "OverAll"){
            this.setState({ eventFromDate: "2024-02-01",eventToDate:moment().format('YYYY-MM-DD')});
            this.getEventAttendeeCountByBotUser("2024-02-01",moment().format('YYYY-MM-DD'));
            this.getRoleWiseEventAttendeeCountByBotUser("2024-02-01",moment().format('YYYY-MM-DD'));

        }else{
            this.setState({ eventFromDate: moment(picker.startDate._d).format('YYYY-MM-DD'),eventToDate:moment(picker.endDate._d).format('YYYY-MM-DD')});
            this.getEventAttendeeCountByBotUser(moment(picker.startDate._d).format('YYYY-MM-DD'),moment(picker.endDate._d).format('YYYY-MM-DD'));
            this.getRoleWiseEventAttendeeCountByBotUser(moment(picker.startDate._d).format('YYYY-MM-DD'),moment(picker.endDate._d).format('YYYY-MM-DD'));
        }
       
        

      }
    
    rangeForAttended =  {
        "Today": [moment(), moment()],
        "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "OverAll": [moment("20240201"), moment()],
      };

      getAssemblyConstituenciesForAttendance = () => {
        this.setState({consLoadingSymbol:"show"});
        const obj =  
        JSON.stringify({ 
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            
        }); 

        getAPICallDataForOnlyBSA(obj,"getAssemblyConstituencies")
        .then((res) => res.json())
        .then((data) => {
            this.setState({consLoadingSymbol:"hide"});
          if(data !=null && data.length > 0){
            let parlArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                parlArr.push(obj);
                
            });
            this.setState({ ac_listArr: parlArr });

            let getConsIdName = this.state.ac_listArr.find((a) => a.value == this.state.constituencyValue.value);
                this.setState({
                    allConstituencyValue: {
                    value: getConsIdName.value,
                    label: getConsIdName.label
                },allConstituencyName:getConsIdName.value
            });

          }else{
            this.setState({ ac_listArr:[]});
          }
        });
    }
   
   
      handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;

        if(name == "allConstituencyName"){
            this.setState({overAllAttendedTotalCount:null})
            let getConsIdName = this.state.ac_listArr.find((a) => a.value == value);
                this.setState({
                    allConstituencyValue: {
                    value: getConsIdName.value,
                    label: getConsIdName.label
                }
            });

            this.getEventAttendeeCount(value);
            this.getRoleWiseEventAttendeeCount(value);
        }else if(name == "eventName"){
            let getEventIdName = this.state.event_listArr.find((a) => a.value == value);
            this.setState({
                eventValue: {
                value: getEventIdName.value,
                label: getEventIdName.label
            }
        });
        }
          
    
        this.setState({ [name]: value});
      }

      showAttendedBlockType = (type) => {
        if(type == "overAll"){
            this.setState({overAllEventBlock:true,userWiseEventBlock:false});
           

        }else{
            this.setState({overAllEventBlock:false,userWiseEventBlock:true,userAttendedTotalCount:null})
            this.setState({ eventFromDate: "2024-02-01",eventToDate:moment().format('YYYY-MM-DD')});

            setTimeout(() => { 
                this.getEventAttendeeCountByBotUser("2024-02-01",moment().format('YYYY-MM-DD'));
                this.getRoleWiseEventAttendeeCountByBotUser("2024-02-01",moment().format('YYYY-MM-DD'));
            }, 500);
        }
      }

    render() {
        return (
            <section>
               <Container>
                    <Row>
                        <Col>
                            <h6 className='text-center fw-bold ln-28 f-14'>Attendence Dashboard Report </h6>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='mt-1' >
                            <label className="form-label f-12 fw-bold">Event <span className='color_red f-14 ms-1'>*</span> <span className='color_red f-12 fw-bold'>{this.state.validateEvent}</span>
                                {
                                    this.state.eventNamesLoadingSymbol == "show" ?
                                    (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                    :
                                    ("")
                                }
                            </label>
                            
                            <SelectBox
                                    stateData={this.state.eventValue}
                                    handleChange={this.handleOnchangeSelectBoxNew}
                                    name="eventName"
                                    options={this.state.event_listArr}
                                    placeholderName={'Event'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                />
                                
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} className='mt-3'>
                            <div className='flex_programs_block'>
                                <div className={this.state.overAllEventBlock == true ? "border_bottom_Public cusor_pointer" : "cusor_pointer"} onClick={() => this.showAttendedBlockType("overAll")}><h6 className='fw-bold  text-center f-12'>Constituency Attendance</h6></div>
                                <div className={this.state.userWiseEventBlock == true ? "border_bottom_Public cusor_pointer" : "cusor_pointer"} onClick={() => this.showAttendedBlockType("user")}><h6 className='fw-bold text-center f-12'>Attendance Taken By Me</h6></div>
                            </div>
                        </Col>
                    </Row>

                    {
                        this.state.userWiseEventBlock == true ? 
                        (
                            <Row>
                                <Col>
                                    <h6 className="form-label f-12 fw-bold mt-3">Date Range :</h6>
                                        <DateRangePicker
                                            alwaysShowCalendars={true}
                                            ranges = {this.rangeForAttended}
                                            onEvent={this.userWiseOnchangeEvent}
                                            className="w-100"
                                        >   
                                        <div class="input-group">
                                            <div class="input-group-prepend bgColorYash">
                                                <i class="fa fa-calendar pad_5_15"></i>
                                            </div>
                                            <input type="text" className="form-control" id="dateRangeFormsId1" value={moment(this.state.dateWiseFromDate).format("YYYY-MM-DD") +" to "+ moment(this.state.dateWiseToDate).format("YYYY-MM-DD")} />
                                        </div>
                                        </DateRangePicker>
                                    </Col>
                            </Row>
                        )
                        :
                        (
                            <Row className='justify-content-center'>
                               <Col className='mt-1' >
                                        <label className="form-label f-12 fw-bold ">Constituency 
                                            {
                                                this.state.consLoadingSymbol == "show" ?
                                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                :
                                                ("")
                                            }
                                        </label>
                                        <SelectBox
                                                stateData={this.state.allConstituencyValue}
                                                handleChange={this.handleOnchangeSelectBoxNew}
                                                name="allConstituencyName"
                                                options={this.state.ac_listArr}
                                                placeholderName={'Constituency'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                    </Col>
                            </Row>
                        )
                    }

                    {
                        this.state.overAllEventBlock == true ? 
                        (
                            <>
                                {
                                    this.state.overAllAttendedTotalCount == null ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    (
                                        this.state.overAllAttendedTotalCount == "" ?
                                        (
                                            <Row>
                                                <Col>
                                                    <h6 className='f-14 text-center mt-4'>No Data Available...</h6>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            <>
                                            <Row>
                                                <Col>
                                                    <h6 className='mt-3 f-14'>Total Attendance Count : <b className='f-18'>{this.state.overAllAttendedTotalCount.totalCount}</b></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-3' style={{backgroundColor:"#f39c12"}}>
                                                        <h6 className='f-14'>Constituency Incharge</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.overAllRoleAttendedTotalCount !=null ? this.state.overAllRoleAttendedTotalCount.constituencyInchageCount : 0}</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-3' style={{backgroundColor:"#2ecc71"}}>
                                                        <h6 className='f-14'>Booth Incharge</h6>
                                                        <h6 className='f-18 fw-bold mt-123'>{this.state.overAllRoleAttendedTotalCount !=null ? this.state.overAllRoleAttendedTotalCount.boothInchageCount : 0}</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-2' style={{backgroundColor:"#e74c3c"}}>
                                                        <h6 className='f-14'>Cluster Incharge</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.overAllRoleAttendedTotalCount !=null ? this.state.overAllRoleAttendedTotalCount.clusterInchageCount : 0}</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-2' style={{backgroundColor:"#2980b9 "}}>
                                                        <h6 className='f-14'>Unit Incharge</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.overAllRoleAttendedTotalCount !=null ? this.state.overAllRoleAttendedTotalCount.unitInchageCount : 0}</h6>
                                                    </div>
                                                </Col>
                                                
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-2' style={{backgroundColor:"#d35400"}}>
                                                        <h6 className='f-14'>KSS</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.overAllRoleAttendedTotalCount !=null ? this.state.overAllRoleAttendedTotalCount.kssCount : 0}</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-2' style={{backgroundColor:"#c0392b"}}>
                                                        <h6 className='f-14'>Others</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.overAllRoleAttendedTotalCount !=null ? this.state.overAllRoleAttendedTotalCount.othersCount : 0}</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </>
                                        )
                                    )
                                }
                            </>
                        )
                        :
                        (
                            <>
                                {
                                    this.state.userAttendedTotalCount == null ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    (
                                        this.state.userAttendedTotalCount == "" ?
                                        (
                                            <Row>
                                                <Col>
                                                    <h6 className='f-14 text-center mt-4'>No Data Available...</h6>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            <>
                                            <Row>
                                                <Col>
                                                    <h6 className='mt-3 f-14'>Total Attendance Count : <b className='f-18'>{this.state.userAttendedTotalCount.totalCount}</b></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-3' style={{backgroundColor:"#f39c12"}}>
                                                        <h6 className='f-14'>Constituency Incharge</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.userRoleAttendedTotalCount !=null ? this.state.userRoleAttendedTotalCount.constituencyInchageCount : 0}</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-3' style={{backgroundColor:"#2ecc71"}}>
                                                        <h6 className='f-14'>Booth Incharge</h6>
                                                        <h6 className='f-18 fw-bold mt-123'>{this.state.userRoleAttendedTotalCount !=null ? this.state.userRoleAttendedTotalCount.boothInchageCount : 0}</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-2' style={{backgroundColor:"#e74c3c"}}>
                                                        <h6 className='f-14'>Cluster Incharge</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.userRoleAttendedTotalCount !=null ? this.state.userRoleAttendedTotalCount.clusterInchageCount : 0}</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-2' style={{backgroundColor:"#2980b9 "}}>
                                                        <h6 className='f-14'>Unit Incharge</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.userRoleAttendedTotalCount !=null ? this.state.userRoleAttendedTotalCount.unitInchageCount : 0}</h6>
                                                    </div>
                                                </Col>
                                                
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-2' style={{backgroundColor:"#d35400"}}>
                                                        <h6 className='f-14'>KSS</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.userRoleAttendedTotalCount !=null ? this.state.userRoleAttendedTotalCount.kssCount : 0}</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className='block_white_pad_shadow mt-2' style={{backgroundColor:"#c0392b"}}>
                                                        <h6 className='f-14'>Others</h6>
                                                        <h6 className='f-18 fw-bold mt-3'>{this.state.userRoleAttendedTotalCount !=null ? this.state.userRoleAttendedTotalCount.othersCount : 0}</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </>
                                        )
                                    )
                                }
                            </>
                        )
                    }
                    
                    
                    
               </Container>
               </section>
               
        )
    }
  }
  
  export default withRouter(AttendenceDashboard);
  