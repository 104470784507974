
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import { getAPICallData } from '../../services/MemberService';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearchLimit10 from '../datatables/CardLessDataTableWithSearchLimit10';
import ViewIssueDescription from '../RaiseAnIssues/ViewIssueDescription';
import ShowUploadFiles from '../RaiseAnIssues/ShowUploadFiles';
import moment from 'moment';

class SharingActivity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            campaignShareCountCheck: 0,
            campaignShareCountArr:[],
            campaignShareDetailsCheck: 0,
            campaignShareDetailsArr:[],
            showIssueDescriptionModal:false,
            showFullIssueDes:"",
            issueFileDetailsArr:[],
            uploadFilesShowModal:false
        }
        
    }

    componentDidMount() {
        

       this.getUserCampaignShareCnt();
       this.getCampaignShareDetails();

    }

    getUserCampaignShareCnt = () => {
        this.setState({ campaignShareCountCheck: 0,campaignShareCountArr:[]});
        const obj =  
            JSON.stringify({ 
            "chatId" :this.state.chatID,
            }
        ); 

        getAPICallData(obj,"getUserCampaignShareCnt")
            .then((res) => res.json())
            .then((data) => {
                if(data !=null){
                    this.setState({ campaignShareCountCheck: 0,campaignShareCountArr:data});
                }else{
                    this.setState({ campaignShareCountCheck: 1,campaignShareCountArr:[]});
                }
            });
        }

    
        getCampaignShareDetails = () => {
            this.setState({ campaignShareDetailsCheck: 0,campaignShareDetailsArr:[]});
            const obj =  
                JSON.stringify({ 
                "chatId" :this.state.chatID,
                }
            ); 
    
            getAPICallData(obj,"getCampaignShareDetails")
                .then((res) => res.json())
                .then((data) => {
                    if(data !=null && data.length>0){
                        this.setState({ campaignShareDetailsCheck: 0,campaignShareDetailsArr:data});
                    }else{
                        this.setState({ campaignShareDetailsCheck: 1,campaignShareDetailsArr:[]});
                    }
                });
            }

    
    
sharingActivityColumns() {

    let columns = [
        {
            dataField: "campaignMsg",
            text: "Campaign",
            sort: true,
            formatter: this.actionsFormatterForCampaignName.bind(this),
            headerStyle: {
                width: "75%"
            }
            
        },
        {
            dataField: "fbShares",
            text: "F",
            sort: true,
            
        },
        {
            dataField: "twShares",
            text: "T",
            sort: true,
            
        },
        {
            dataField: "waShares",
            text: "W",
            sort: true
            
        }
    ];
    return columns;
}
    
showIssueDescription = (desc) => {
    this.setState({showIssueDescriptionModal:true,showFullIssueDes:desc});
} 

showImage = (imageUrl) => {
    var imageArr=[];
    var obj={
        "filePath":imageUrl,
        "fileType":"Image"
    }
    imageArr.push(obj)
    this.setState({uploadFilesShowModal:true,issueFileDetailsArr:imageArr});
    
}

actionsFormatterForCampaignName =  (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
        {
            row.campaignMsg !=null && row.campaignMsg.length > 20 ?
            (
                <>
                <h6  className='f-12 fw-normal mt-2'>
                    {row.campaignMsg.substring(0,20)}...     
                    <span style={{textAlign:"left",marginLeft:"0px"}} className='f-10 cursor_pointer  text-decoration-underline color_red' onClick={() => this.showIssueDescription(row.campaignMsg)}> View More</span>
                </h6>
                <h6 className='f-12 fw-normal color_red mt-3' >Time : {moment(row.sentTime).format('MMMM Do YYYY, h:mm a') }</h6>
                {
                    row.imageUrl != "" ?
                    (<h6 className='f-12 fw-normal color_red mt-3' >Image : <span style={{textAlign:"left",marginLeft:"0px"}} className='f-10 cursor_pointer  text-decoration-underline color_red' onClick={() => this.showImage(row.imageUrl)}>View Image</span></h6>)
                    :
                    ("")
                }
                
                </>
                
            )
            :
            (<span style={{textAlign:"left",marginLeft:"0px"}}  className='f-12 fw-normal' >{row.campaignMsg}</span>)
        }
        </>
     );

        
        
    
  
}

actionsFormatterForTimeImage = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            <span className='f-12 color_red ' style={{textAlign:"left",marginLeft:"0px"}}>
                {row.sentTime}
            </span>
           
         </>

        
     );
}

sharingActivityData =  () => {
    let sharingDtsData = [];
    var ddd="";
    const vidurl = 'https://www.youtube.com/watch?v=I_izvAbhExY';

    fetch(`https://noembed.com/embed?dataType=json&url=${vidurl}`)
    .then(res => res.json())
    .then(data => ddd = data.title)

    if(this.state.campaignShareDetailsArr.length>0){
        
        for (let i = 0; i < this.state.campaignShareDetailsArr.length; i++) {

            const campaignMsg = (this.state.campaignShareDetailsArr[i].campaignMsg);
            const imageUrl = (this.state.campaignShareDetailsArr[i].imageUrl);
            const sentTime = (this.state.campaignShareDetailsArr[i].sentTime);
            const fbShares = (this.state.campaignShareDetailsArr[i].fbShares);
            const twShares = (this.state.campaignShareDetailsArr[i].twShares);
            const waShares = (this.state.campaignShareDetailsArr[i].waShares);
            
            const obj = {
                campaignMsg:campaignMsg,
                sentTime: sentTime,
                imageUrl:imageUrl,
                fbShares:fbShares,
                twShares:twShares,
                waShares:waShares
            }
            sharingDtsData.push(obj);
           
        } 
        return sharingDtsData;
    }
}


    render() {

        return (
            <section className=''>
               <Container className=''>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center text-decoration-underline'>Sharing Activity</h6>
                        </Col>
                    </Row>

                    {
                        this.state.campaignShareCountArr == "" ?
                        (
                            this.state.campaignShareCountCheck == 1 ?
                            (<Col className='f-14 text-center mt-3'>No Data Available</Col>)
                            :
                            (<Col sm={12}>
                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                            </Col>)
                        )
                        :
                        (
                            this.state.campaignShareCountArr != "" ? 
                            (
                                <Row>
                                    <Col sm={12}>
                                        <div className='bg_light_blue mt-2'>
                                        <h6 className='f-12 mt-3 fw-bold'>
                                                Note : <span className='ml_5 mr_2'>F - facebook shares,</span>
                                                        <span className='ml_5 mr_2'>T - twitter shares</span>
                                                        <span className='ml_5 mr_2'>W - whatsApp shares</span>
                                            </h6>
                                            <table className='table table-bordered table_custom'>
                                                <thead>
                                                    <tr>
                                                        <th>Total Camapigns</th>
                                                        <th>Total Shares</th>
                                                        <th>F</th>
                                                        <th>T</th>
                                                        <th>W</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.state.campaignShareCountArr.sentCampaigns}</td>
                                                        <td>{this.state.campaignShareCountArr.totalShares}</td>
                                                        <td>{this.state.campaignShareCountArr.fbShares}</td>
                                                        <td>{this.state.campaignShareCountArr.twShares}</td>
                                                        <td>{this.state.campaignShareCountArr.waShares}</td>
                                                    </tr>
                                                </tbody>
                                           </table>
                                           
                                        </div>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        )
                        
                        }

                    {
                        this.state.campaignShareDetailsArr.length == 0 ?
                        (
                            this.state.campaignShareDetailsCheck == 1 ?
                            (<Col className='f-14 text-center mt-3'>No Data Available</Col>)
                            :
                            (<Col sm={12}>
                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                            </Col>)
                        )
                        :
                        (
                            this.state.campaignShareDetailsArr.length>0 ? 
                            (
                                <Row>
                                    <Col sm={12}>
                                        <div className='bg_light_blue mt-2'>
                                        
                                        <CardLessDataTableWithSearchLimit10 
                                                parentData={this.sharingActivityData()}
                                                particlecolumns={this.sharingActivityColumns()}
                                               tableHeading="Campaign Sharing Activity Details"
                                            />
                                           
                                        </div>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        )
                        
                        }
                    
                </Container>
                <ViewIssueDescription 
                        show={this.state.showIssueDescriptionModal}
                        onHide={() => this.setState({ showIssueDescriptionModal: false })}
                        stateData={this.state}
                        title="Camapign Name"
                       
                />
                <ShowUploadFiles 
                        show={this.state.uploadFilesShowModal}
                        onHide={() => this.setState({ uploadFilesShowModal: false })}
                        stateData={this.state}
                        type="sharingActivity"
                />
            </section>
        )
    }
  }
  
  export default withRouter(SharingActivity);
  