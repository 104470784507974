
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,InputGroup  } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import "../RaiseAnIssues/raiseAnIssue.css";
import "../EventsAndActivities/EventsAndActivities.css"
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService'
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';
import EnabledLocationPopup from "../../components/Volunteer/EnabledLocationPopup"

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };
class YuvagalamVehicleTracking extends Component {
    constructor(props) {
        super(props)
        this.fileupload = React.createRef()
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            constituencyName:"",
            constituencyValue:"",
            ap_listArr:[],
            vehicleTypeArr:[],
            vehicleTypeValue:"",
            vehicleType:"",
            validatevehicleType:"",
            noofPeoplesValue:"",
            validatenoofPeoplesValue:"",
            validateFilesUploadImage:"",
            uploadVehicleImageArr:[],
            vehicleLoadingSymbol:"hide",
            vehicleSucessMessage:"",
            validatevehicleNo:"",
            vehicleStateTypeValue:"",
            vehicleStateNumber:"",
            vehicleStateAlphabitic:"",
            vehiclefourNumber:"",
            checkingEnabledLocationModal:false,
            fileuploadValue:""
        }
        this.uploadMultipleFilesForVehicle = this.uploadMultipleFilesForVehicle.bind(this);
    }

    componentDidMount() {
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            var consArr=[];
            for(var i in  this.state.userAccessList.constituencyList){
                var obj={'value':this.state.userAccessList.constituencyList[i].id,'label':this.state.userAccessList.constituencyList[i].name};
                consArr.push(obj);
                
                
            }
           
            this.setState({ ap_listArr:consArr });
            this.setState({
                constituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            },constituencyName:this.state.userAccessList.constituencyList[0].id
        });

        this.getVehicleTypes();
        }
        

    }

    closeEnabledModal = () => {
        this.props.history.push({
            pathname: "/yuvagalam_vehicle_tracking",
            params: {
                userAccessList:this.state.userAccessList,
            
            },
        });
    }

    
    currentLatLangValuesError = (error) => {
        return(
          <EnabledLocationPopup 
                  show={() => this.setState({ checkingEnabledLocationModal: true })}
                  onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                  closeEnabledModal={this.closeEnabledModal}
           />
        )
          
      }

    getVehicleTypes = () => {
        const obj =  
        JSON.stringify({ 
            "chatId" :this.state.chatID,
        }); 

        getAPICallData(obj,"getVehicleTypes")
        .then((res) => res.json())
        .then((data) => {
          if(data !=null && data.length > 0){
            let vehicleArr=[];
            data.map(item => {
                const obj = {
                    value: item.id,
                    label: item.name,
                }
                vehicleArr.push(obj);
                
            });
            this.setState({ vehicleTypeArr: vehicleArr });
          }else{
            this.setState({ vehicleTypeArr:[]});
          }
        });
    }

    handleOnchangeSelectBoxNew = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
        if(name == "constituencyName"){
          let getConsIdName = this.state.ap_listArr.find((a) => a.value == value);
            this.setState({
                constituencyValue: {
                  value: getConsIdName.value,
                  label: getConsIdName.label
              }
          });
        }else if(name == "vehicleType"){
            let getIdName = this.state.vehicleTypeArr.find((a) => a.value == value);
            this.setState({
                vehicleTypeValue: {
                  value: getIdName.value,
                  label: getIdName.label
              }
          });
        }
    
        this.setState({ [name]: value});
      }
      
      handleOnchangeAllowNumbersInputBox = (e) => {
        this.setState({validatevehicleNo:"",validatenoofPeoplesValue:""});
        const name = e.target.name;
        const value = e.target.value;
        const re = /^[0-9\b]+$/;

        if(name == "noofPeoplesValue"){
            if (!re.test(e.target.value)) {
                this.setState({validatenoofPeoplesValue:"Please Enter Numbers Only...  ",noofPeoplesValue:""});
                return;
            }else{
                this.setState({validatenoofPeoplesValue:""});
            }
        }else if(name == "vehicleStateNumber"){
            if (!re.test(e.target.value)) {
                this.setState({validatevehicleNo:"Please Enter Numbers Only...  ",vehicleStateNumber:""});
                return;
            }else{
                this.setState({validatevehicleNo:""});
            }
        }else if(name == "vehiclefourNumber"){
            if (!re.test(e.target.value)) {
                this.setState({validatevehicleNo:"Please Enter Numbers Only...  ",vehiclefourNumber:""});
                return;
            }else{
                this.setState({validatevehicleNo:""});
            }
        }
        else{
            this.setState({validatenoofPeoplesValue:"",validatevehicleNo:"" });
        }
      }

      handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });
        
      }

      toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))
    
      uploadMultipleFilesForVehicle(e) { 
        this.setState({validateFilesUploadImage:""});
        let fileObj=[];
        fileObj.push(e.target.files);
        if(fileObj[0][0].type == "image/jpeg" || fileObj[0][0].type == "image/png"){
            for (let i = 0; i < fileObj[0].length; i++) {
                this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                .then(dataUrl => {
                    var obj ={
                        fileId:i,
                        fileName:fileObj[0][i].name,
                        fileType:fileObj[0][i].type,
                        data:dataUrl,
                    }
                    this.setState({uploadVehicleImageArr:[...this.state.uploadVehicleImageArr, obj]});
                });
            }
        }else{
            this.setState({validateFilesUploadImage:"Please Upload Image Files Only..."});
            
        }
      }

      saveEventVehicleTrack = () => {
            let uploadArr="",vehicleNo="";
            if(this.state.vehicleType == "" || this.state.vehicleType == 0){
                this.setState({validatevehicleType:"Please Select Vehicle Type" });
                return;
            }

            
            if((this.state.vehicleStateTypeValue == "" || this.state.vehicleStateTypeValue == 0) && (this.state.vehicleStateNumber == "" || this.state.vehicleStateNumber == 0) && (this.state.vehicleStateAlphabitic == "" || this.state.vehicleStateAlphabitic == 0) && (this.state.vehiclefourNumber == "" || this.state.vehiclefourNumber == 0)){
                this.setState({validatevehicleNo:"Please Enter Valid Vehicle No" });
                return;
            }

            if(this.state.noofPeoplesValue == "" || this.state.noofPeoplesValue == 0){
                this.setState({validatenoofPeoplesValue:"Please Enter NoOf Peoples" });
                return;
            }

            if(this.state.uploadVehicleImageArr.length == 0){
                this.setState({validateFilesUploadImage:"Please Upload Image" });
                return;
            }
            
            for (let i = 0; i < this.state.uploadVehicleImageArr.length; i++) {
                var imageBase64 = this.state.uploadVehicleImageArr[i].data.replace("data:"+this.state.uploadVehicleImageArr[i].fileType+";base64,","");
                uploadArr = imageBase64;
            
            }

            vehicleNo = this.state.vehicleStateTypeValue+""+this.state.vehicleStateNumber+""+this.state.vehicleStateAlphabitic+""+this.state.vehiclefourNumber
            this.setState({vehicleLoadingSymbol:"show"});

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition( (position) => {
                    const obj =  
                        JSON.stringify({ 
                        "chatId" :this.state.chatID,
                        "constituencyId" : this.state.constituencyValue.value,
                        "vehicleTypeId" : this.state.vehicleTypeValue.value,
                        "vehicleNumber" : vehicleNo, 
                        "noOfPeople" : this.state.noofPeoplesValue,
                        "latitude" : position.coords.latitude,
                        "longitude" : position.coords.longitude,
                        "base64Str" : uploadArr
                        }); 
    
                        getAPICallData(obj,"saveEventVehicleTrack")
                        .then((res) => res.json())
                        .then((data) => {
                        this.setState({vehicleLoadingSymbol:"hide"});  
                            if(data !=null && data.status == "Success"){
                                this.setState({vehicleSucessMessage:"Submit SuccessFully..."}); 
                                setTimeout(() => { 
                                    this.setState({
                                        vehicleTypeValue:"",
                                        vehicleType:"",
                                        validatevehicleType:"",
                                        noofPeoplesValue:"",
                                        validatenoofPeoplesValue:"",
                                        validateFilesUploadImage:"",
                                        uploadVehicleImageArr:[],
                                        vehicleSucessMessage:"",
                                        vehicleNo:"",
                                        validatevehicleNo:"",
                                        vehicleStateTypeValue:"",
                                        vehicleStateNumber:"",
                                        vehicleStateAlphabitic:"",
                                        vehiclefourNumber:"",
                                        fileuploadValue:""
                                    }); 
                                }, 1500);
                                
                            }else{
                                this.setState({vehicleSucessMessage:"Some Thing Wrong,Please Check once"});  
                            
                            }
                        });  
                },this.currentLatLangValuesError);
            } else {
                <EnabledLocationPopup 
                    show={() => this.setState({ checkingEnabledLocationModal: true })}
                    onHide={() => this.setState({ checkingEnabledLocationModal: false })}
                    closeEnabledModal={this.closeEnabledModal}
                />
            }

        }
      

    render() {
        return (
            <section>
                <Container fluid>
                    <Row>
                        <Col>
                            <h6 className='fw-bold text-center'> YuvaGalam Vehicle Tracking</h6>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <Form.Group className="mt-3" controlId="formConstituencyNameId">
                                <Form.Label className='f-12 fw-bold'>Constituency/నియోజకవర్గం:</Form.Label>
                                <SelectBox
                                    stateData={this.state.constituencyValue}
                                    handleChange={this.handleOnchangeSelectBoxNew}
                                    name="constituencyName"
                                    options={this.state.ap_listArr}
                                    placeholderName={'Constituency'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mt-3">
                                <InputGroup.Text id="basic-addon1" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>Vehicle Type: <span className='color_red f-14 ms-1'>*</span></InputGroup.Text>
                                <select id="vehicleTypeId" name="vehicleType" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBox(e)}>
                                            <option value="">Select Type</option>
                                            {
                                                this.state.vehicleTypeArr.length>0 && this.state.vehicleTypeArr.map((item,index) => {
                                                    return(
                                                        <option value={item.value}>{item.label}</option>
                                                    )
                                                })
                                            }
                                            
                                    </select>
                                    <span className='color_red f-9'>{this.state.validatevehicleType}</span>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mt-3" controlId="formConstituencyNameId">
                                <Form.Label className='f-12 fw-bold'>Vehicle No :<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validatevehicleNo}</span></Form.Label>
                                <Row>
                                    <Col className='w_40 separatehashCls'>
                                        <select id="vehicleStateType" name="vehicleStateTypeValue" className='form-select form-select-sm ' onChange={(e) => this.handleOnchangeInputBox(e)}>
                                            <option value="">State</option>
                                            <option value="AP" selected>AP</option>
                                            <option value="TS">TS</option>
                                        </select>
                                    </Col>
                                    <Col className='w_20 separatehashCls1'>
                                        <Form.Control type="text" name="vehicleStateNumber" maxLength={2} className='form-control-sm' value={this.state.vehicleStateNumber}  placeholder="" onChange={(e) => this.handleOnchangeInputBox(e)} onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)}/>
                                    </Col>
                                    <Col className='w_20 separatehashCls2'>
                                        <Form.Control type="text" name="vehicleStateAlphabitic" maxLength={2} className='form-control-sm' value={this.state.vehicleStateAlphabitic}  placeholder="" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                    </Col>
                                    <Col className='w_30'>
                                        <Form.Control type="text" name="vehiclefourNumber" maxLength={4} className='form-control-sm' value={this.state.vehiclefourNumber}  placeholder="" onChange={(e) => this.handleOnchangeInputBox(e)} onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)}/>
                                    </Col>
                                </Row>
                                
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <InputGroup className="mt-3">
                                <InputGroup.Text id="basic-addon2" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>No Of Peoples Came : <span className='color_red f-14 ms-1'>*</span></InputGroup.Text>
                                <Form.Control type="text" name="noofPeoplesValue" maxLength={2} className='form-control-sm' value={this.state.noofPeoplesValue}  placeholder="Enter No Of Peoples" onKeyUp={(e) => this.handleOnchangeAllowNumbersInputBox(e)} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                <span className='color_red f-9'>{this.state.validatenoofPeoplesValue}</span>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="">
                        <div className="form-group">
                            <h6 className='f-12 fw-bold mt-3'>Vehicle Number Plate  Upload <span className='color_red f-14 ms-1'>*</span></h6>
                            {/*<h6 className='f-10 fw-bold color_red'>Note : Please Upload Image Files only.</h6>*/}
                            <label for="uploadfilesForVehicle" class="drop-container">
                                <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                <input type="file" ref={this.fileupload} id="uploadfilesForVehicle"  className='f-14' value={this.state.fileuploadValue}   onChange={this.uploadMultipleFilesForVehicle}/>
                            </label>
                            <span className='color_red f-12 fw-bold'>{this.state.validateFilesUploadImage}</span>
                        </div>
                        </Col>
                    </Row>
                    {/*
                        this.state.uploadVehicleImageArr.length>0 ?
                        (
                            <Row>
                                <Col sm={12} className="mt-2">
                                <h6>Show Number Plate Image: </h6>
                                    
                                </Col>
                                <Col sm={12} className="">
                                        {(this.state.uploadVehicleImageArr || []).map((url,index) => (
                                                
                                                 <img className='img-thumbnail w-100 height100' id='base64image' src={url.data} />
                                               
                                               
                                                    
                                        ))} 
                                    
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    */ }

                    <Row>
                        <Col>
                            <Button variant="success" className='mt-1 f-14 fw-bold center-auto' onClick={() => this.saveEventVehicleTrack()}>SUBMIT</Button>
                            {
                                this.state.vehicleLoadingSymbol === "show" ? (<Image src={loader} alt="loader" className='loader_cls_issue ms-2'></Image>) : ("")
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className='f-14 fw-bold text-center mt-2'>{this.state.vehicleSucessMessage}</h6>
                        </Col>
                    </Row>
                   
                </Container>
                
                
            </section>   
        )
    }
  }
  
  export default withRouter(YuvagalamVehicleTracking);
  