import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import { withRouter,Link } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import ShowAllPhotos from '../EventsAndActivities/ShowAllPhotos';


class ShowUploadImages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ShowAllIssuesModal:false
        }
      
    }

    showAllIssuesPhotosBlock = () => {
        this.setState({ShowAllIssuesModal:true});
    }
    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Upload Files</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Row>
                        <Col>
                        {
                                this.props.stateData.issueFileDetailsArr.length == 0 ?
                                (
                                    this.props.stateData.issueFileDetailsCheck == 1 ?
                                    (<Col className='f-14 text-center'>No Comments Available</Col>)
                                    :
                                    (<Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                    </Col>)
                                )
                                :
                                (
                                    this.props.stateData.issueFileDetailsArr.length>0 ?
                                    (
                                        <Col sm={12}>
                                            <div className='photos_gallery_flex'>
                                                {
                                                    this.props.stateData.issueFileDetailsArr.slice(0, 6).map((item,index) => {
                                                        if(item.fileType == "Pdf"){
                                                            return(
                                                                     <iframe src={item.filePath}  className='pdf_width_height mt-2 bordered' frameborder="0" allowfullscreen/>
                                                                )
                                                        }else{
                                                            return(
                                                                    <div className="cursor_pointer"> <Image src={item.filePath} alt={item.fileType} className={this.props.type=="sharingActivity" ? "w-100" : "pdf_width_height mt-2"} ></Image></div>
                                                                )
                                                        }
                                                    
                                                    })
                                                    
                                                }
                                            </div>
                                            {
                                                this.props.stateData.issueFileDetailsArr.length>6 ?
                                                (<div className='mt-1 f-14 fw-bold cursor_pointer float-end' onClick={() => this.showAllIssuesPhotosBlock()}>View More...</div>)
                                                :
                                                ("")
                                            }
                                        </Col>
                                    )
                                    :
                                    ("")
                                )
                            }
                        </Col>
                    </Row>
                    {
                        this.props.stateData.issueFileDetailsArr.length>0 ?
                        (
                            <ShowAllPhotos 
                                show={this.state.ShowAllIssuesModal}
                                onHide={() => this.setState({ ShowAllIssuesModal: false })}
                                getAllPhotosList={this.props.stateData.issueFileDetailsArr}
                                modalTitle="Attended"
                            />
                        )
                        :
                        ("")
                    }
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default withRouter(ShowUploadImages);
  

