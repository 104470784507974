import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import { getAPICallData } from '../../services/MemberService'
import { withRouter,Link } from "react-router-dom";
import ShowAllPhotos from '../EventsAndActivities/ShowAllPhotos';

class ViewBoothLocationStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ShowAllIssuesModal:false
        }
      
    }

    showAllIssuesPhotosBlock = () => {
        this.setState({ShowAllIssuesModal:true});
    }

    
    render() {
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Booth - {this.props.stateData.selectdBoothLocationData.Booth} Details:</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Row>
                        <Col>
                            {
                                this.props.stateData.selectdBoothLocationData.Status == "Pending" ?
                                (<h6 className='f-14'>Status : <b className='color_red'>{this.props.stateData.selectdBoothLocationData.Status}</b></h6>)
                                :
                                (<h6 className='f-14'>Status : <b className='color_green'>{this.props.stateData.selectdBoothLocationData.Status}</b></h6>)
                            }

                            <h6 className='f-14 mt-3'>Mandal/Town : <b className=''>{this.props.stateData.selectdBoothLocationData.mandalTown}</b></h6>
                            <h6 className='f-14 mt-3'>Panchayat : <b className=''>{this.props.stateData.selectdBoothLocationData.panchayat}</b></h6>
                            <h6 className='f-14 mt-3'>Booth Location : <b className=''>{this.props.stateData.selectdBoothLocationData.locationName}</b></h6>
                            <h6 className='f-14 mt-3'>Villages Covered : <b className=''>{this.props.stateData.selectdBoothLocationData.villagesCovered}</b></h6>
                            <h6 className='f-14 mt-3'>Locations : </h6>
                            <h6 className='f-14'>latitude - <b className=''>{this.props.stateData.selectdBoothLocationData.latitude}</b></h6>
                            <h6 className='f-14'>longitude - <b className=''>{this.props.stateData.selectdBoothLocationData.longitude}</b></h6>
                            <h6 className='f-14 mt-3'>Images : </h6>
                            
                            <Row>
                                <Col>
                                    <div className='photos_flex'>
                                            {
                                                this.props.stateData.selectdBoothLocationData.imgList !=undefined &&  this.props.stateData.selectdBoothLocationData.imgList.slice(0, 4).map((item,index) => {
                                                    return(
                                                        <div className=""> <Image src={item} alt="Image" className="w-100 mt-2 img-thumbnail" ></Image></div>
                                                    )
                                                
                                                })
                                                
                                            }
                                        </div>
                                        {
                                            this.props.stateData.selectdBoothLocationData.imgList !=undefined &&  this.props.stateData.selectdBoothLocationData.imgList.length>4 ?
                                            (<div className='mt-1 f-14 fw-bold cursor_pointer float-end' onClick={() => this.showAllIssuesPhotosBlock()}>More...</div>)
                                            :
                                            ("")
                                        }
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    {
                        this.props.stateData.selectdBoothLocationData.imgList !=undefined && this.props.stateData.selectdBoothLocationData.imgList.length>0 ?
                        (
                            <ShowAllPhotos 
                                show={this.state.ShowAllIssuesModal}
                                onHide={() => this.setState({ ShowAllIssuesModal: false })}
                                getAllPhotosList={this.props.stateData.selectdBoothLocationData.imgList}
                                modalTitle="Booth Location Images"
                            />
                        )
                        :
                        ("")
                    }
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default withRouter(ViewBoothLocationStatus);
  

